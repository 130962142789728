import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AdditionalMatching from 'components/special-offer/AdditionalMatching'

const useStyles = makeStyles(() => ({
  infoText: {
    fontSize: 14,
    color: '#1A1667',
    textAlign: 'center'
  },
  link: {
    fontWeight: 600,
    color: '#1A1667',
    textDecoration: 'underline',
    textDecorationColor: '#1A1667',
    '& :visited': {
      color: '#1A1667'
    }
  },
}))

export default function PurchaseSpecialOffer (props) {
  const { offers } = props
  const classes = useStyles()

  const createOfferBox = (offer) => {
    if (offer.offer_type === 'additional_matching') {
      return <AdditionalMatching key={offer.id} {...props} offer={offer} />
    }
    return null
  }

  return (
    <>
      <Typography className={classes.infoText}>現在ご利用いただける</Typography>
      <Typography className={classes.infoText}>オプションの一覧です。</Typography>
      {offers.map(offer => createOfferBox(offer))}
    </>
  )
}

PurchaseSpecialOffer.propTypes = {
  offers: PropTypes.array,
}
