import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import {
  expiredUrl,
  httpNotFound,
  httpNotModified,
} from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoFilter from 'components/parts/PhotoFilter'
import 'react-circular-progressbar/dist/styles.css'

const cellContainerStyle = (backgroundColor) => ({
  width: '100%',
  backgroundColor,
})

const circleStyle = (theme, background, sizeFactor) => ({
  width: theme.spacing(sizeFactor),
  height: theme.spacing(sizeFactor),
  background,
  textAlign: 'center',
  borderRadius: '50%',
})

const nameStyle = () => ({
  fontWeight: 'bold',
  overflow: 'hidden',
})

const styles = theme => ({
  faceAvatar: {
    width: 56,
    height: 56,
  },
  name: {
    ...nameStyle(),
    fontSize: 16,
    maxHeight: 56,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    marginRight: theme.spacing(1),
  },
  age: {
    fontSize: 11,
    fontWeight: 'bold',
  },
  cell: {
    borderStyle: 'none',
  },
  nameContainer: {
    width: '100%',
  },
  cellContainer: {
    ...cellContainerStyle(theme.palette.primary.main),
    borderWidth: 1,
    borderColor: '#EFF0FF',
    borderBottomStyle: 'solid',
  },
  itemContainer: {
    width: '100%',
    flexGrow: 1,
  },
  rightContainer: {
    width: 'calc(100% - 224px)',
  },
  applyCircle: {
    ...circleStyle(theme, '#6666ff', 9),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  whiteCircle: circleStyle(theme, '#FFF', 8),
  emptyCircle: {
    ...circleStyle(theme, '#FFF', 9.375),
    marginLeft: theme.spacing(0.5625),
  },
  applyText: {
    fontSize: 10,
    color: '#6666ff',
  },
  cancelButtonContainer: {
    width: 126,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    fontSize: 10,
    fontWeight: 'bold',
    width: 126,
    height: 32,
    background: '#616AFA',
  },
})

export class FriendCellApplying extends BaseComponent {
  constructor (props) {
    super(props)
    this.clickFriend = this.clickFriend.bind(this)
    this.handleOnMouseOut = this.handleOnMouseOut.bind(this)
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this)
    this.clickCancel = this.clickCancel.bind(this)

    let photoUrl = props.friend.photo_icon
    this.shouldForceReload = expiredUrl(photoUrl)
    this._isMounted = false

    this.state = {
      tipOpen: false,
      timerId: null,
      friend: props.friend,
      photoUrl: this.shouldForceReload ? null : photoUrl,
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    if (!isNullOrUndefined(this.state.timerId)) {
      clearTimeout(this.state.timerId)
      this.setState({ timerId: null })
    }
  }

  componentWillMount () {
    this.loadFriend()
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.friend) !== JSON.stringify(prevProps.friend)) {
      this.loadFriend()
    }
  }

  async loadFriend () {
    try {
      let friend = await this.loadFriendAndUpdateFriends(this.props.friend.id)
      this.setStateIfMounted({
        friend: friend,
        photoUrl: friend.photo_icon,
      })
    } catch (error) {
      if (httpNotModified(error)) {
        // noop
      } else if (httpNotFound(error)) {
        this.setStateIfMounted({ friend: null })
        await this.loadFriends(true)
      } else {
        this.handleApiError(error)
      }
    }
  }

  async clickFriend () {
    this.props.setFriend(this.state.friend)
    this.props.setScreen('Profile')
  }

  handleOnMouseOut () {
    this.setState({tipOpen: false})
  }

  handleOnMouseOver () {
    if (this.state.tipOpen === false) {
      this.setState({tipOpen: true})
      if (isNullOrUndefined(this.state.timerId)) {
        this.setState({
          timerId: setTimeout(
            () => {
              this.setState({
                tipOpen: false,
                timerId: null,
              })
            }
            ,2000
          )
        })
      }
    }
  }

  createAvatar () {
    const { classes } = this.props
    const { friend, photoUrl } = this.state
    if (photoUrl) {
      return (
        <PhotoFilter brightness={friend.photo_filter_brightness}>
          <Avatar src={photoUrl} className={classes.faceAvatar} />
        </PhotoFilter>
      )
    } else {
      return (
        <CircularProgress color="secondary" />
      )
    }
  }

  clickCancel () {
    this.props.onCancel(this.state.friend)
  }

  render () {
    if (!this.state.friend) return null

    const { classes } = this.props

    return (
      <TableRow
        hover
        onMouseOut={this.handleOnMouseOut}
        onMouseOver={this.handleOnMouseOver}
      >
        <TableCell component="td" style={{ padding: 0 }} className={classes.cell}>
          <Grid container
            alignItems="center"
            justifyContent="space-between"
            className={classes.cellContainer}
          >
            <Grid item onClick={() => this.clickFriend()}>
              {
                this.state.friend.type === 'lover_applying' ? (
                  <Grid container justifyContent="center" alignItems="center" className={classes.applyCircle}>
                    <Grid container justifyContent="center" alignItems="center" className={classes.whiteCircle}>
                      { this.createAvatar() }
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justifyContent="center" alignItems="center" className={classes.emptyCircle}>
                    { this.createAvatar() }
                  </Grid>
                )
              }
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" className={classes.rightContainer} onClick={() => this.clickFriend()}>
              <Grid item className={classes.itemContainer}>
                {
                  this.state.friend.type === 'lover_applying' ? (
                    <Typography className={classes.applyText}>
                      お付き合いリクエスト送信中
                    </Typography>
                  ) : <React.Fragment/>
                }
                <Grid
                  container direction="column" alignItems="flex-start"
                  className={classes.nameContainer}
                >
                  <Typography className={classes.name}>
                    {this.state.friend.nick_name}
                  </Typography>
                  <Typography className={classes.age}>
                    {this.state.friend.age}歳
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.cancelButtonContainer}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.cancelButton}
                onClick={this.clickCancel}
              >
                {this.state.friend.type === 'lover_applying' ? 'リクエスト取り消し' : '「いいね！」取り消し'}
              </Button>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    )
  }
}

FriendCellApplying.propTypes = {
  classes: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
}

export default withStyles(styles)(FriendCellApplying)