import SubImg1 from 'images/tutorial/sample_sub_1.png'
import SubImg2 from 'images/tutorial/sample_sub_2.png'
import SubImg3 from 'images/tutorial/sample_sub_3.png'
import SubImg4 from 'images/tutorial/sample_sub_4.png'
import SubImg5 from 'images/tutorial/sample_sub_5.png'
import SubImg6 from 'images/tutorial/sample_sub_6.png'
import SubImg7 from 'images/tutorial/sample_sub_7.png'
import UserImg1 from 'images/tutorial/sample_user_1.png'
import UserImg2 from 'images/tutorial/sample_user_2.png'
import UserImg3 from 'images/tutorial/sample_user_3.png'
import UserImg4 from 'images/tutorial/sample_user_4.png'
import UserImg5 from 'images/tutorial/sample_user_5.png'
import UserImg6 from 'images/tutorial/sample_user_6.png'
import UserImg7 from 'images/tutorial/sample_user_7.png'
import UserImg8 from 'images/tutorial/sample_user_8.png'

export const males = [
  {
    nick_name: 'S.M',
    age: 26,
    address1: '東京都',
    birthplace: 'fukuoka',
    how_to_spend_the_holidays: '映画鑑賞、漫画、散歩、音楽フェス、お酒ゲーム',
    matching: 90,
    to_message: 'はじめまして。S.Mです。出身は長野県で、今は東京都に住んでいます。子供の頃の夢は仮面ライダーで、今は食品メーカーで経理の仕事をしてます（笑）\n漫画（ジャンプ）が好きで、特にNARUTOが好きです。子供の頃から読んでいて、全巻持っています。\nよろしくお願いします。',
    working_industry: 'food',
    photo_icon: UserImg1,
    type: 'friend_receiving',
    entry_request_message: 'Manaさん、はじめまして！SMっていいます！プロフィールを読んで、価値観が同じだなと思いメッセージしました。自分も次に付き合う人とはしっかりと…',
    gallery_item_1: SubImg3,
    gallery_item_2: SubImg4,
    gallery_item_3: SubImg5,
    gallery_item_4: SubImg6,
    gallery_item_5: SubImg7,
  },
  {
    nick_name: 'Kotaro',
    age: 31,
    address1: '東京都',
    birthplace: 'nagano',
    how_to_spend_the_holidays: '読書、カフェ巡り、海外旅行、サウナ',
    matching: 71,
    to_message: 'Kotaroです。現在は機械系の仕事を8年間しています。今まで仕事一筋で取り組んできたのですが、転勤を機に寂しさを感じて、真剣なお付き合いをしてく…',
    working_industry: 'healthcare_medical',
    photo_icon: UserImg2,
    type: 'friend_receiving',
    entry_request_message: 'Manaさん、はじめまして！Kotaroと申します。\n猫ちゃんの写真が可愛くて惹かれました。(^ ^)\nお話できたら嬉しいです。よろしくお願いいたします…',
    gallery_item_1: SubImg3,
    gallery_item_2: SubImg4,
    gallery_item_3: SubImg5,
    gallery_item_4: SubImg6,
    gallery_item_5: SubImg7,
  },
  {
    nick_name: 'Takuya',
    age: 27,
    address1: '神奈川県 ',
    birthplace: 'tokyo',
    how_to_spend_the_holidays: '買い物、音楽鑑賞、ホームパーティ、海外旅行、犬と戯れ',
    matching: 81,
    to_message: 'はじめまして！現在自動車メーカーの営業の仕事をしています。新卒から勤めていて、5年目です。\n【好きなこと】',
    working_industry: 'automobile_machine',
    photo_icon: UserImg3,
    type: 'friend_receiving',
    entry_request_message: 'はじめまして。プロフィールが丁寧に書かれていて、お話してみたいなと思いメッセージさせて頂きました。どんなスポーツがお好きですか？よろしく…',
    gallery_item_1: SubImg2,
    gallery_item_2: SubImg4,
    gallery_item_3: SubImg6,
  },
  {
    nick_name: 'とーる',
    age: 27,
    address1: '千葉県',
    birthplace: 'gunma',
    how_to_spend_the_holidays: 'サウナ、筋トレ、糖質ダイエット、絵を描くこと、映画鑑賞',
    matching: 90,
    to_message: 'はじめまして！とーるです！最近サウナと筋トレにハマっています！健康に関することに興味があればお話しできると嬉しいなって思います！現在鉄道の仕事をしています。5年この仕事をしてます！友人からはよく優しい・天然って言われます（笑）よろしくお願いします！',
    working_industry: 'logistics_shipping',
    photo_icon: UserImg4,
    type: 'friend_receiving',
    entry_request_message: 'Manaさん、はじめまして！とーるです！自分も旅行が趣味なので、色々とお話できたらと思っていいね！させていただきました。よろしくお願いします！！',
    gallery_item_1: SubImg7,
    gallery_item_2: SubImg5,
    gallery_item_3: SubImg3,
    gallery_item_4: SubImg1,
    gallery_item_5: SubImg6,
  },
]

export const females = [
  {
    nick_name: 'S',
    age: 26,
    address1: '東京都',
    birthplace: 'nagano',
    how_to_spend_the_holidays: '趣味：映画鑑賞、漫画、散歩、音楽フェス、お酒、ゲーム',
    matching: 90,
    to_message: 'はじめまして。Sです。出身は長野県で、今は東京都に住んでいます。お酒が好きなので、休日は友人と美味しいお店探しをしています。美味しいごはんやお…',
    working_industry: 'it',
    photo_icon: UserImg5,
    type: 'friend_receiving',
    entry_request_message: 'Kotaさん、はじめまして！Sっていいます！プロフィールを読んで、価値観が同じだなと思いメッセージしました。私も次に付き合う人とはしっかりと…',
    gallery_item_1: SubImg1,
    gallery_item_2: SubImg2,
    gallery_item_3: SubImg3,
    gallery_item_4: SubImg4,
    gallery_item_5: SubImg5,
  },
  {
    nick_name: 'Mana',
    age: 31,
    address1: '東京都',
    birthplace: 'hyogo',
    how_to_spend_the_holidays: '読書、ボードゲーム、脱出ゲーム',
    matching: 71,
    to_message: 'Manaです。現在は不動産デベロッパーで秘書の仕事を7年間しています。今まで仕事一筋で取り組んできたのですが、転勤を機に寂しさを感じて、真剣なお…',
    working_industry: 'healthcare_medical',
    photo_icon: UserImg6,
    type: 'friend_receiving',
    entry_request_message: 'Kotaさん、はじめまして！Manaと申します。\n猫ちゃんの写真が可愛くて惹かれました。(^ ^)\nお話できたら嬉しいです。よろしくお願いいたします…',
    gallery_item_1: SubImg3,
    gallery_item_2: SubImg4,
    gallery_item_3: SubImg5,
    gallery_item_4: SubImg6,
    gallery_item_5: SubImg7,
  },
  {
    nick_name: 'かなこ',
    age: 28,
    address1: '神奈川県',
    birthplace: 'gunma',
    how_to_spend_the_holidays: '音楽鑑賞、スポーツ観戦、旅行',
    matching: 81,
    to_message: 'はじめましはじめまして！かなこです。\n【好きなこと】\n音楽鑑賞、スポーツ観戦、旅行（国内・国外）',
    working_industry: 'art_entertainment',
    photo_icon: UserImg7,
    type: 'friend_receiving',
    entry_request_message: 'はじめまして。プロフィールが丁寧に書かれていて、お話してみたいなと思いメッセージさせて頂きました。どんなスポーツがお好きですか？よろしく…',
    gallery_item_1: SubImg2,
    gallery_item_2: SubImg4,
    gallery_item_3: SubImg6,
  },
  {
    nick_name: 'H.M',
    age: 32,
    address1: '千葉県',
    birthplace: '大阪',
    how_to_spend_the_holidays: '旅行、ジム、カメラ・写真、絵を描くこと、映画鑑賞',
    matching: 90,
    to_message: 'はじめまして！H.Mです。関西出身で今は東京寄りの千葉に住んでいます。休みの日は友人と出かけたりすることが多いです。友人からはおっちょこちょいな…',
    working_industry: 'media',
    photo_icon: UserImg8,
    type: 'friend_receiving',
    entry_request_message: 'Kotaさん、はじめまして！HMです！私も旅行が趣味なので、色々とお話できたらと思っていいね！させていただきました。よろしくお願いします！！',
    gallery_item_1: SubImg7,
    gallery_item_2: SubImg5,
    gallery_item_3: SubImg3,
    gallery_item_4: SubImg1,
    gallery_item_5: SubImg6,
  },
]
