import React from 'react'
import PropTypes from 'prop-types'
import Api from 'commons/api'
import Const from 'commons/constant'
import Storage from 'commons/storage'
import { addDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import FreePlanSuggestDialog from 'components/plan/FreePlanSuggestDialog'
import PlanTermExpired from 'components/plan/PlanTermExpired'
import SpecialFreePlanSuggestDialog from 'components/plan/SpecialFreePlanSuggestDialog'

/*
 * 加入しているご利用プランの期限チェック
 * 入会後の無料トライアル期間(standard_initial): 終了3日前からポップアップ, 24時間おき
 * スタンダード(standard): 月次自動更新で失敗した場合は期限が切れた状態になる
 */
export default class PlanTermCheck extends React.Component {
  state = {
    subscriptionExpired: false,
    openFreePlanSuggestDialog: false,
    openSpecialFreePlanSuggestDialog: false,
  }

  render () {
    const { children, ...others } = this.props
    return (
      <React.Fragment>
        {
          this.state.subscriptionExpired ? (
            <PlanTermExpired subscription={this.props.subscription} />
          ) : children
        }
        <FreePlanSuggestDialog
          open={this.state.openFreePlanSuggestDialog}
          onClose={() => this.setState({ openFreePlanSuggestDialog: false })}
          subscription={this.props.subscription}
          {...others}
        />
        <SpecialFreePlanSuggestDialog
          open={this.state.openSpecialFreePlanSuggestDialog}
          onClose={() => {
            BaseComponent.createReadStateDialogDisplayed(this.props, 'Info.SpecialFreePlan')
            this.setState({ openSpecialFreePlanSuggestDialog: false })
          }}
          subscription={this.props.subscription}
        />
      </React.Fragment>
    )
  }

  componentDidMount () {
    this.checkTerm()
  }

  async checkTerm () {
    const { subscription } = this.props
    // ローディングやエラーポップアップは出さない
    try {
      if (!subscription) { return }
      if (!subscription.expired_at) { return }
      const matchingState = await Api.getMatchingState()
      const myPref = await Api.getMyPref()

      switch (subscription.content) {
        case Const.planTypes.STANDARD_INITIAL:
          this.checkStandardInitial(subscription, matchingState, myPref)
          break
        case Const.planTypes.STANDARD:
        case Const.planTypes.STANDARD_ORG_PAID:
        case Const.planTypes.PREMIUM:
          this.checkPaidPlan(subscription, matchingState)
          break
      }
    } catch (error) {
      if (error.response) {
        Api.addErrorLog(error)
      }
    }
  }

  // 入会後の無料トライアル期間
  async checkStandardInitial (subscription, matchingState, myPref) {
    const now = new Date()
    const expDate = new Date(subscription.expired_at)
    if (expDate < now) {
      this.setState({ subscriptionExpired: true })
      return
    }

    // 終了3日前からポップアップ表示
    const threshold = addDate(expDate, -3)
    if (now < threshold) { return }

    // フリープラン選択済みの場合は表示しない
    if (subscription.next_plan_id === Const.planTypes.FREE) { return }

    if (BaseComponent.canUseSpecialFreePlan(myPref, subscription)) {
      this.suggestSpecialFreePlan()
    } else {
      this.suggestFreePlan(matchingState)
    }
  }

  // 有料プランの利用期限
  checkPaidPlan (subscription, matchingState) {
    const now = new Date()
    const expDate = new Date(subscription.expired_at)
    if (expDate < now) {
      this.setState({ subscriptionExpired: true })
      return
    }

    // 終了3日前からポップアップ表示
    const threshold = addDate(expDate, -3)
    if (now < threshold) { return }

    // フリープラン選択済みの場合は表示しない
    if (subscription.next_plan_id === Const.planTypes.FREE) { return }

    this.suggestFreePlan(matchingState)
  }

  // フリープランのご案内
  suggestFreePlan (matchingState) {
    const now = new Date()
    const beforeDateVal = Storage.freePlanSuggestDialogDate.value
    if (beforeDateVal) {
      const beforeDate = new Date(beforeDateVal)
      // 3日前, 2日前, 1日前 で最大3回表示
      if (now < addDate(beforeDate, +1)) { return }
    }

    const count = matchingState?.good_remaining_target_count
    if (count && count < 20) {
      this.setState({ openFreePlanSuggestDialog: true })
      Storage.freePlanSuggestDialogDate.value = now
    }
  }

  // 特別フリープランのご案内
  async suggestSpecialFreePlan () {
    const dailogDisyplayed = await BaseComponent.isReadStateDialogDisplayed(this.props, 'Info.SpecialFreePlan')
    this.setState({ openSpecialFreePlanSuggestDialog: !dailogDisyplayed })
  }
}

PlanTermCheck.propTypes = {
  children: PropTypes.any.isRequired,
  user: PropTypes.object,
  subscription: PropTypes.object,
}
