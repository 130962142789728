import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import firstNaviKomainu2 from 'images/Komainu/firstNaviKomainu2.png'

const useStyles = makeStyles(theme => ({
  slideTitle: {
    fontSize: 25,
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: theme.spacing(7),
  },
  slideMessage: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(3.75),
  },
  infoBox: {
    width: 289,
    background: '#B0B4FC',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    paddingTop: theme.spacing(2.75),
    paddingBottom: theme.spacing(3.5),
    marginRight: 'auto',
    marginBottom: theme.spacing(8.5),
    marginLeft: 'auto',
  },
  infoText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  infoMiniText: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2.25),
  },
  infoSubText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  komainu: {
    display: 'block',
    margin: 'auto',
  },
}))

export default function Tutorial3 (props) {
  const { subscription } = props
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.slideTitle} color="primary">
        試しに「いいね！」を<br />
        送ってみよう
      </Typography>
      <div className={classes.infoBox}>
        {subscription?.content !== Const.planTypes.STANDARD_ORG_PAID && (
          <>
            <Typography className={classes.infoText} color="secondary">
              7日間無料体験
            </Typography>
            <Typography className={classes.infoMiniText} color="secondary">
              （最大10人と同時にトークが可能）
            </Typography>
          </>
        )}
        <Typography className={classes.infoSubText} color="secondary">
          色々な異性とトークをしてみよう！<br />
          トークで、相手の魅力を見つけよう<br />
          友達解消も簡単にできるよ
        </Typography>
      </div>
      <img className={classes.komainu} src={firstNaviKomainu2} />
    </>
  )
}

Tutorial3.propTypes = {
  subscription: PropTypes.object,
}