import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Api from 'commons/api'
import FoodReportForm from 'components/foodreport/FoodReportForm'
import FoodReportListItem from 'components/foodreport/FoodReportListItem'
import FoodReportThemeProvider from 'components/foodreport/FoodReportThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoFilter from 'components/parts/PhotoFilter'

const HEADER_HEIGHT = 54

const useStyles = makeStyles(theme => ({
  appBar: {
    height: HEADER_HEIGHT,
    boxShadow: '0 0 7px #0000002E',
  },
  backButton: {
    padding: 0,
    color: theme.palette.secondary.main,
  },
  avatarContainer: {
    marginLeft: theme.spacing(2),
  },
  faceAvatar: {
    width: 36,
    height: 36,
  },
  friendName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    width: 184,
    overflow: 'hidden',
  },
  contents: {
    paddingTop: HEADER_HEIGHT,
    textAlign: 'center',
    position: 'relative',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1667',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.75),
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    bottom: 7,
  },
}))

export default function FoodReportListDialog (props) {
  const { open, onClose, roomId, ...others } = props
  const classes = useStyles()
  const [foodReports, setFoodReports] = useState([])
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [shops, setShops] = useState([])

  useEffect(() => {
    if (open && roomId) { loadFoodReports() }
  }, [open, roomId])

  const loadFoodReports = async () => {
    props.setLoading(true)
    try {
      const data = await Api.getFoodReports(roomId)
      setFoodReports(data)
    } catch (error) {
      BaseComponent.handlApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onClickItem = async (item) => {
    try {
      const _shops = await Api.getShopsOfCampaign(item.campaign_type)
      setShops(_shops)

      onClose && onClose()
      setSelectedItem(item)
      setOpenFormDialog(true)
    } catch (error) {
      BaseComponent.handlApiError(props, error)
    }
  }

  return (
    <>
      <Dialog open={open} fullScreen>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton className={classes.backButton} onClick={() => props.setScreen('Friends')}>
              <LeftIcon />
            </IconButton>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item className={classes.avatarContainer}>
                <PhotoFilter brightness={100}>
                  <Avatar className={classes.faceAvatar} src={props.friend.photo_icon} />
                </PhotoFilter>
              </Grid>
              <Typography className={classes.friendName}>
                {props.friend.nick_name}
              </Typography>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contents}>
          <Typography className={classes.title}>
            食レポ履歴
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <List>
          {foodReports.map(foodReport => (
            <FoodReportListItem
              key={foodReport.id}
              foodReport={foodReport}
              onClick={onClickItem}
              {...props}
            />
          ))}
        </List>
      </Dialog>
      <FoodReportThemeProvider>
        <FoodReportForm
          openFormDialog={openFormDialog}
          foodReportId={selectedItem.id}
          shopList={shops}
          onClose={() => setOpenFormDialog(false)}
          {...others}
        />
      </FoodReportThemeProvider>
    </>
  )
}

FoodReportListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  roomId: PropTypes.string.isRequired,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
  friend: PropTypes.object,
}
