import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

const useStyles = makeStyles(theme => ({
  progressBar: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(3.375),
    height: '9px !important',
    borderRadius: 5,
    [`& .${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#DBDCFE'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#423BC7'
    },
  },
  box: {
    background: '#fff',
    borderRadius: 8,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    '& label': {
      marginLeft: 0,
      justifyContent: 'space-between',
    }
  },
  type: {
    color: '#707070',
    fontWeight: 'normal'
  },
}))

export default function SelectWordType (props) {
  const { wordType, onChange, onNext, onClose } = props
  const classes = useStyles()

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        自己紹介文を作成
        <LinearProgress value={10} variant='determinate' className={classes.progressBar} />
        <Typography>まずは自己紹介文の<br />文体を選択しましょう。</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>文体を選択</Typography>
        <FormControl component='fieldset' fullWidth className={classes.box}>
          <RadioGroup name='type' value={wordType} onChange={handleChange}>
            <FormControlLabel
              value='casual'
              control={<Radio />}
              labelPlacement='start'
              label={
                <span
                  className={classes.type}
                  style={{ color: wordType === 'casual' ? '#423BC7' : '', fontWeight: wordType==='casual' ? 'bold' : '' }}
                >
                  親しみやすい口調
                </span>
              }
            />
            <FormControlLabel
              value='serious'
              control={<Radio />}
              labelPlacement='start'
              label={
                <span
                  className={classes.type}
                  style={{ color: wordType === 'serious' ? '#423BC7' : '', fontWeight: wordType === 'serious' ? 'bold' : '' }}
                >
                  真面目な口調
                </span>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button variant='contained' onClick={onNext} disabled={!wordType}>次へ</Button>
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </React.Fragment>
  )
}

SelectWordType.propTypes = {
  wordType: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
}
