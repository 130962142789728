import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as Offer from 'components/special-offer/index'

const useStyles = makeStyles(() => {
  const soTheme = Offer.theme()
  return {
    container: {
      backgroundColor: soTheme.mainColor,
      border: `3px solid ${soTheme.mainColor}`,
      borderRadius: '10px 10px 13px 13px',
      backgroundImage: `url(${soTheme.friendCellImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
      boxShadow: '0px 1px 8px #00000033',
      '& #friendCard': {
        boxShadow: 'none',
      }
    },
    komanavi: {
      height: 43,
    },
    text: {
      fontSize: 12,
      fontWeight: 600,
      color: '#ffffff',
    },
  }
})

export default function SpecialOfferNavi (props) {
  const { children, boostTitle } = props
  const classes = useStyles()

  return (
    <Grid className={classes.container}>
      <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
        <Typography className={classes.text}>
          {boostTitle}
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}

SpecialOfferNavi.propTypes = {
  children: PropTypes.object,
  boostTitle: PropTypes.string.isRequired,
}
