import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CameraIcon from '@material-ui/icons/PhotoCamera'
import Api from 'commons/api'
import Const from 'commons/constant'
import { isEmpty, calculateAge } from 'commons/utility'
import CropDialog from 'components/parts/CropDialog'
import PhotoFilter from 'components/parts/PhotoFilter'
import PhotoStatus from 'components/parts/PhotoStatus'
import PhotoTutorialDialog from 'components/photo-tutorial/PhotoTutorialDialog'
import BaseComponent from './BaseComponent'

const styles = theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
  },
  faceAvatar: {
    width: 125,
    height: 125,
  },
  AvatarContainer: {
    margin: 'auto',
  },
  imageWidth: {
    ...theme.styles.profilePhotoWidth,
  },
  imageContainer: {
    ...theme.styles.photoContainer,
  },
  imageMain: {
    ...theme.styles.photoSize,
    objectFit: 'cover',
  },
  imageGallery: {
    ...theme.styles.photoSize,
    objectFit: 'contain',
  },
  gallery: {
    ...theme.styles.photoGallerySize,
    marginTop: theme.spacing(1),
  },
  ImageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  ImageListItem: {
    textAlign: 'center',
    width: '100%',
    padding: `0 ${theme.spacing(0.625)}px`,
  },
  gridSpace: {
    marginTop: theme.spacing(1),
  },
  imgContainer: {
    width: '100%',
    height: 0,
    background: '#F5F5F5',
    paddingBottom: '100%',
    position: 'relative',
  },
  imgContainerSelected: {
    width: '100%',
    height: 0,
    paddingBottom: 'calc(100% - 4px)',
    position: 'relative',
    boxSizing: 'border-box',
    border: '2px solid #3D45C2',
  },
  imgGallery: {
    height: '100%',
    minHeight: '100%',
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#F5F5F5',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  nameContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
    marginTop: theme.spacing(3.75),
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: 'calc(100% - 32px)',
    maxHeight: 56,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    textAlign: 'left',
  },
  age: {
    fontSize: 11,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  messageContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
    textAlign: 'left',
  },
  message: {
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  addAvatarArea: {
    width: '100%',
    marginLeft: 'calc(100% / 2)',
    marginRight: 'calc(100% / 2)',
    position: 'relative'
  },
  cameraIconArea: {
    width: 44,
    height: 44,
    padding: theme.spacing(1),
    top: -44,
    right: 44,
    bottom: 0,
    position: 'absolute',
    backgroundColor: '#e0e0e0',
    borderRadius: '50%',
    border: '2px solid white',
  },
  cameraIcon: {
    fontSize: 24,
  },
})

class MyProfileHeader extends BaseComponent {

  constructor (props) {
    super(props)

    const { user, simple } = props

    this.handleReadImportedFile = this.handleReadImportedFile.bind(this)
    this.uploadCroppedPhotoFile = this.uploadCroppedPhotoFile.bind(this)
    this.closeCropPhotoDialog = this.closeCropPhotoDialog.bind(this)

    this._isMounted = false

    this.state = {
      galleryItems: [],
      photo: simple ? (user?.photo_icon_to_change || user.photo_icon) : (user?.photo_large_to_change || user.photo_large),
      photoBrightness: user.photo_filter_brightness,
      photo_type: 'main',
      selectedGalleryIndex: 0,
      openCropPhotoDialog: false,
      originalFile: null,
      photoUrl: null,
      openTutorialDialog: false,
    }
  }

  componentWillMount () {
    this.loadGalleryPhotos()
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async loadGalleryPhotos () {
    const { user } = this.props
    try {
      // メイン写真
      const mainItem = {
        icon_url: user?.photo_icon_to_change || user.photo_icon,
        large_url: user?.photo_large_to_change || user.photo_large,
        photo_filter_brightness: user.photo_filter_brightness,
      }
      // ギャラリー写真
      const galleryItems = await Api.getMyGalleryItems()
      galleryItems.unshift(mainItem)
      this.setStateIfMounted({ galleryItems })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  openPhotoGallery (index) {
    let item = this.state.galleryItems[index]
    if (item) {
      let photo_type = 'main'
      if (index !== 0) {
        photo_type = 'gallery'
      }
      this.setState({
        selectedGalleryIndex: index,
        photo: item.large_url,
        photoBrightness: item.photo_filter_brightness,
        photo_type,
      })
    }
  }

  handleReadImportedFile (event) {
    let originalFile = event.target.files[0]
    if (!originalFile) { return }
    let createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    let imageUrl = createObjectURL(originalFile)
    this.setState({
      openCropPhotoDialog: true,
      originalFile,
      photoUrl: imageUrl,
      openTutorialDialog: false,
    })
  }

  async uploadCroppedPhotoFile (file) {
    this.props.setLoading(true)
    try {
      let user = await this.updatePhoto(file)
      this.setStateIfMounted({
        openCropPhotoDialog: false,
        photoUrl: null,
        photo: user.photo_icon_to_change,
        photoBrightness: user.photo_filter_brightness,
      })
    } catch (error) {
      this.setStateIfMounted({
        openCropPhotoDialog: false,
        photoUrl: null,
      })
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  closeCropPhotoDialog () {
    this.setState({
      openCropPhotoDialog: false,
      photoUrl: null
    })
  }

  createAvatar () {
    const { simple, classes } = this.props
    const { photo, photoBrightness, photo_type } = this.state
    if (simple) {
      return (
        <Grid className={classes.avatarContainer}>
          <PhotoFilter brightness={photoBrightness}>
            <Avatar src={photo} className={classes.faceAvatar} />
          </PhotoFilter>
          <Grid item className={classes.addAvatarArea}>
            <form noValidate autoComplete="off">
              <input
                id="file"
                type="file"
                accept="image/*"
                onClick={event => {
                  event.target.value=null
                  return false
                }}
                onChange={this.handleReadImportedFile}
                style={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  zIndex: 1,
                }}
              />
            </form>
            <Grid
              item className={classes.cameraIconArea}
              onClick={() => this.setState({ openTutorialDialog: true })}
              component="label"
            >
              <CameraIcon className={classes.cameraIcon}/>
            </Grid>
          </Grid>
          <PhotoTutorialDialog
            open={this.state.openTutorialDialog}
            onClose={() => this.setState({ openTutorialDialog: false })}
            type='main'
          />
        </Grid>
      )
    } else {
      return (
        <Grid item className={classes.imageWidth}>
          <PhotoFilter className={classes.imageContainer} brightness={photoBrightness}>
            <img
              src={photo}
              className={photo_type === 'main' ? classes.imageMain : classes.imageGallery}
              alt=""
            />
          </PhotoFilter>
        </Grid>
      )
    }
  }

  createImageListItem (index) {
    const { user, classes } = this.props
    const { galleryItems, selectedGalleryIndex } = this.state

    if (6 <= index && (galleryItems.length - 1)) { return null }

    const focus = index === selectedGalleryIndex
    const item = galleryItems[index]
    // サブ写真: GalleryItem.status, メイン写真: User.photo_status
    const status = item?.status || user?.photo_status

    return (
      <ImageListItem
        key={index}
        className={classes.ImageListItem}
        classes={{ item: classes.ImageListItem }}
        style={{ padding: 0, height: 'auto' }}
        onClick={() => this.openPhotoGallery(index)}
      >
        <div className={focus ? classes.imgContainerSelected : classes.imgContainer}>
          {index < galleryItems.length ? (
            <>
              <PhotoStatus status={status} />
              <PhotoFilter brightness={item.photo_filter_brightness}>
                <img src={item.icon_url} className={classes.imgGallery} alt="ギャラリー写真" />
              </PhotoFilter>
            </>
          ) : (
            <div className={classes.imgGallery} />
          )}
        </div>
      </ImageListItem>
    )
  }

  render () {
    const { user, simple, classes, ...other } = this.props
    const { galleryItems } = this.state
    if (!user) return null
    const galleryCols = [...Array(Const.maxGalleryItemCount).keys()]

    return (
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          {this.createAvatar()}
          {(!simple && galleryItems.length > 0) && (
            <Grid item className={classes.gallery}>
              <ImageList className={classes.ImageList} style={{margin: 0}} cols={6}>
                {galleryCols.map(index => this.createImageListItem(index))}
              </ImageList>
            </Grid>
          )}
          {!simple && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              className={classes.nameContainer}
            >
              <Typography className={classes.name}>
                {user.nick_name}
              </Typography>
              <Typography className={classes.age}>
                {calculateAge(user.birthday)}歳
              </Typography>
            </Grid>
          )}
          {(!simple && !isEmpty(user.to_message)) && (
            <Grid item className={classes.messageContainer}>
              <Typography className={Array.isArray(this.textConvertMultiline(user.to_message)) ? null : classes.message}>
                {this.textConvertMultiline(user.to_message)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <CropDialog
          {...other}
          open={this.state.openCropPhotoDialog}
          originalFile={this.state.originalFile}
          photoUrl={this.state.photoUrl}
          onUpload={this.uploadCroppedPhotoFile}
          onClose={this.closeCropPhotoDialog}
          aspectRatio={1 / 1}
        />
      </div>
    )
  }
}

export default withStyles(styles)(MyProfileHeader)
