import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputBase from '@material-ui/core/InputBase'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { httpBadRequest, fetchResponseErrorMessage } from 'commons/utility'
import ImgLeftPad from 'images/img_left_pad.png'
import Img from 'images/img_love_entry_dialog.png'
import ImgRightPad from 'images/img_right_pad.png'
import BaseComponent from './BaseComponent'
import ConfirmDialog from './ConfirmDialog'

const styles = theme => ({
  input: {
    ...theme.styles.dialogInput,
    padding: theme.spacing(1.5),
    width: '100%',
    borderRadius: 8,
    '& textarea': {
      height: 246,
    },
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    borderColor: 'red',
  },
  image: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
    height: '60px',
  },
  image_right_pad: {
    position: 'absolute',
    right: 60,
    filter: 'contrast(0.5)'
  },
  image_left_pad: {
    filter: 'contrast(0.5)'
  },
})

const maxMessageLength = 300

class LoverEntryDialog extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleChangeMessage = this.handleChangeMessage.bind(this)
    this.sendEntry = this.sendEntry.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleErrorClose = this.handleErrorClose.bind(this)

    this._isMounted = false

    this.state = {
      friend: props.friend || storage.getFriend(),
      message: '',
      errorInputMessage: false,
      errorOpen: false,
      errorMessage: '',
      sending: false,
      title: '',
    }
  }

  componentWillMount () {
    switch (this.props.lover_type) {
      case 'apply':
        this.setState({ title: 'お付き合いリクエストを送る' })
        break
      case 'approve':
        this.setState({ title: 'お付き合いリクエスト承認' })
        break
      case 'reject':
        this.setState({ title: 'お断り（友達解消）' })
        break
      default:
        break
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.friend !== nextProps.friend) {
      this.setState({ friend: nextProps.friend })
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  handleChangeMessage (event) {
    var txt = event.target.value
    this.setState({
      message: txt,
      errorInputMessage: false
    })
  }

  async sendEntry () {
    let valid = this.validate()
    if (!valid) { return }

    let entryType = null
    switch (this.props.lover_type) {
      case 'apply':
        entryType = 'apply_lover'
        break
      case 'approve':
        entryType = 'approve_lover'
        break
      case 'reject':
        entryType = 'reject_lover'
        break
      default:
        return
    }

    this.setState({ sending: true })
    try {
      let friendId = this.state.friend.id
      let opts = {
        to_message: this.state.message
      }
      await this.postEntry(friendId, entryType, opts)
      if (this.props.onSent) {
        this.props.onSent(this.state.message)
      }
    } catch (error) {
      this.handleEntryError(error)
    } finally {
      this.setStateIfMounted({ sending: false })
    }
  }

  async handleEntryError (error) {
    if (httpBadRequest(error)) {
      const errorMessage = await fetchResponseErrorMessage(error)
      if (errorMessage) {
        this.setState({
          errorInputMessage: true,
          errorOpen: true,
          errorMessage,
        })
      } else {
        this.handleApiError(error)
      }
    } else {
      this.handleApiError(error)
    }
  }

  validate () {
    if (!this.state.message.trim()) {
      this.setState({
        errorInputMessage: true,
        errorOpen: true,
        errorMessage: 'メッセージを入力してください'
      })
      return false
    } else if (maxMessageLength < this.state.message.length) {
      this.setState({
        errorInputMessage: true,
        errorOpen: true,
        errorMessage: `メッセージが${maxMessageLength}文字を超えています`
      })
      return false
    } else {
      return true
    }
  }

  handleCancel () {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  handleErrorClose () {
    this.setState({ errorOpen: false })
  }

  render () {
    if (!this.state.friend) { return null }

    const { classes, lover_type, open,  } = this.props
    return (
      <DialogThemeProvider color={lover_type === 'reject' ? 'default' : 'primary'}>
        <Dialog open={open}>
          <DialogTitle disableTypography>
            {this.state.title}
            {lover_type === 'approve' && (
              <div className={classes.image_right_pad}><img alt="" src={ImgRightPad} /></div>
            )}
          </DialogTitle>
          <DialogContent>
            {lover_type !== 'reject' && (
              <Typography className={classes.message} variant="body1">
                {lover_type === 'approve' && (
                  <div className={classes.image_left_pad}><img alt="" src={ImgLeftPad} /></div>
                )}
                {this.state.friend.nick_name}さんへ
                <br />
                メッセージで気持ちを伝えましょう！
                {lover_type === 'approve' && (
                  <div className={classes.image_left_pad}><img alt="" src={ImgLeftPad} /></div>
                )}
              </Typography>
            )}
            {lover_type === 'reject' && (
              <Typography variant="body1">
                お断りの返事をしますか？<br />お断り後は友達解消となります。<br />ご注意ください。
              </Typography>
            )}
            {lover_type === 'apply' && (
              <div className={classes.image}><img alt="" src={Img} /></div>
            )}
            <div className={classes.inputContainer}>
              <InputBase
                classes={{ error: classes.error }}
                value={this.state.message}
                onChange={this.handleChangeMessage}
                className={classes.input}
                error={this.state.errorInputMessage}
                placeholder="ここにメッセージを入力"
                multiline
                rows="6"
              />
            </div>
            {this.state.sending && (
              <LinearProgress color="secondary" />
            )}
          </DialogContent>
          {lover_type === 'reject' ? (
            <DialogActions disableSpacing>
              <Button
                variant="contained"
                onClick={this.sendEntry}
                disabled={this.state.sending}
              >
                送信
              </Button>
              <Button variant="text" onClick={this.handleCancel}>
                キャンセル
              </Button>
            </DialogActions>
          ) : (
            <DialogActions disableSpacing>
              <Button
                variant="contained"
                onClick={this.sendEntry}
                disabled={this.state.sending}
              >
                送信する
              </Button>
              <Button variant="text" onClick={this.handleCancel}>
                キャンセル
              </Button>
            </DialogActions>
          )}
          <ConfirmDialog
            open={this.state.errorOpen}
            onClose={this.handleErrorClose}
            onOk={this.handleErrorClose}
            onCancel={this.handleErrorClose}
            confirm="yes"
            title="送信エラー"
            message={this.state.errorMessage}
          />
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

LoverEntryDialog.propTypes = {
  open: PropTypes.bool,
  onSent: PropTypes.func,
  onCancel: PropTypes.func,
  friend: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  lover_type: PropTypes.string,
}

export default withStyles(styles)(LoverEntryDialog)
