import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import BaseComponent from 'components/parts/BaseComponent'
import CanselIcon from 'images/tutorial/firstNaviCanselImg.png'

const useStyles = makeStyles(theme => ({
  root: {
  },
  bottomArrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 22px 0 22px',
    borderColor: '#6666FF transparent transparent transparent',
    position: 'fixed',
    transform: 'translate(-50%, 0%)'
  },
  topArrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 22px 15px 22px',
    borderColor: 'transparent transparent #6666FF transparent',
    position: 'fixed',
    transform: 'translate(-50%, 100%)'
  },
  paper: {
    width: 289,
    backgroundColor: '#6666FF',
    borderRadius: 12,
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    position: 'relative',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#ffffff',
    marginTop: theme.spacing(2),
  },
  img: {
    maxWidth: 198,
    marginTop: theme.spacing(2),
  },
  button: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: 230,
    height: 44,
    position: 'fixed',
    bottom: 40,
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 9999,
  },
  canselButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

// ##################################################
// Backdrop クリック無効Modal
// ##################################################
export default function NaviPopover (props) {
  const classes = useStyles()
  const {
    id,
    open,
    anchorEl,
    title,
    text,
    img,
    child,
    position = 'top',
    onClose,
    okButton,
    buttonText,
    arrow,
    canselButton
  } = props
  const rootRef = useRef(null)
  const [arrowPosition, setArrowPosition] = useState(null)

  useEffect(() => {
    if (!anchorEl || !open) { return }
    const clientRect = anchorEl.getBoundingClientRect()
    setArrowPosition(position === 'top' ? clientRect.top : clientRect.bottom - 30)
  }, [anchorEl, open])

  useEffect(() => {
    if (open) {
      return () => BaseComponent.toNextTutorial(props, id)
    }
  }, [open])

  const anchorOrigin = position === 'top' ? { vertical: 'top' } : { vertical: 'bottom'}
  const transformOrigin = position === 'top' ? { vertical: 'bottom' } : { vertical: 'top'}

  return (
    <>
      <Popover
        classes={{ root: classes.root, paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={Object.assign(anchorOrigin, { horizontal: 'center' })}
        transformOrigin={Object.assign(transformOrigin, { horizontal: 'center' })}
        elevation={0}
      >
        <div ref={el => rootRef.current = el}>
          {canselButton && (
            <IconButton className={classes.canselButton} onClick={onClose}>
              <img src={CanselIcon} alt="" />
            </IconButton>
          )}
          {title && <Typography className={classes.title}>{title}</Typography>}
          {text && <Typography className={classes.text}>{text}</Typography>}
          {img && <img className={classes.img} src={img} alt="" />}
          {child && child}
        </div>
        {arrow && (
          <span
            className={position === 'top' ? classes.bottomArrow : classes.topArrow}
            style={{ top: arrowPosition }}
          />
        )}
      </Popover>
      {arrow && (
        <Grid style={{ textAlign: 'center', display: open ? '' : 'none' }}>
          <span
            className={position === 'top' ? classes.bottomArrow : classes.topArrow}
            style={{ top: arrowPosition }}
          />
        </Grid>
      )}
      {okButton && (
        <Grid style={{ textAlign: 'center', display: open ? '' : 'none' }}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            {buttonText ? buttonText : 'OK'}
          </Button>
        </Grid>
      )}
    </>
  )
}

NaviPopover.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  child: PropTypes.object,
  position: PropTypes.string,
  onClose: PropTypes.func,
  okButton: PropTypes.bool,
  buttonText: PropTypes.string,
  arrow: PropTypes.bool,
  canselButton: PropTypes.bool,
  setTutorialView: PropTypes.func,
  tutorialReadStates: PropTypes.array,
}
