import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Api from 'commons/api'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate } from 'commons/utility'
import CooperateDialog from 'components/notifications/CooperateDialog'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import HappinessSurveyDialog from 'components/parts/HappinessSurveyDialog'

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formText: {
    marginTop: theme.spacing(2),
    fontSize: 10,
    paddingLeft: theme.spacing(1.5),
    listStyle: '"＊"'
  },
  divider: {
    background: '#e0e0e0',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  reasonSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  select: {
    padding: '0 !important',
  },
  chip: {
    fontSize: 10,
    fontWeight: 600,
    color: '#ffffff',
    background: '#616AFA',
    maxWidth: 120,
    marginBottom: theme.spacing(0.2),
  },
  menuItem: {
    fontSize: 12,
    fontWeight: 600,
    color: '#707070',
  },
  selectedItem: {
    background: '#F4F7FE !important'
  },
  input: {
    width: '100%',
    border: '1px solid #999',
    background: '#FFFFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2.66),
  },
  inputError: {
    borderColor: 'red',
  },
}))

const maxReasonInputLength = 500

const reasonValues = {
  graduate: 'Aill goen で恋人ができた',
  quit: 'Aill goen 以外で恋人ができた',
  busy: '忙しくて時間が取れない',
  matching_system: '1日1人の紹介システムが使いにくい',
  matching_quality: '自分の求める異性が紹介されない',
  no_matching: 'マッチングしない',
  ai_assist_timing: 'AIアシストのタイミングが不満足',
  ai_assist_contents: 'AIアシストの内容が不満足',
  members_site: 'メンバーズサイトがわかりにくい',
  uiux: '操作がわかりにくい',
  my_profile_settings: 'プロフィール設定が面倒',
  others_profile: 'お相手のプロフィールが伝わらない',
  performance: '動作が遅い',
  popup: 'ポップアップが鬱陶しい',
  design: 'デザインが悪い',
  price_expensive: '料金が高い',
  price_type: '料金設定がよくわからない',
  others: 'その他',
}

export default function WithdrawalDialog (props) {
  const { open, onSent, onCancel, user, ...other } = props
  const classes = useStyles()
  const [reasons, setReasons] = useState([])
  const [reasonDetail, setReasonDetail] = useState('')
  const [openWithdrawalConfirmDialog, setOpenWithdrawalConfirmDialog] = useState(false)
  const [openHappinessSurveyDialog, setOpenHappinessSurveyDialog] = useState(false)
  const [openCoupleHearingDialog, setOpenCoupleHearingDialog] = useState(false)
  const [coupleHearingParams, setCoupleHearingParams] = useState(null)
  const [allowHearing, setAllowHearing] = useState(true)
  const [allowReEntry, setAllowReEntry] = useState(true)
  const [allowSms, setAllowSms] = useState(true)
  const [openAppReviewDialog, setOpenAppReviewDialog] = useState(false)
  // 「その他」を選択しているかどうか
  const reasonIsOthers = reasons.includes('others')

  useEffect(() => {
    if (!open) { return }
    BaseComponent.gaModalView('withdrawal')
  }, [open])

  const handleChangeReasons = (event) => {
    const value = event.target.value
    const last = value[value.length - 1]
    setReasons(last === 'others' ? ['others'] : value.filter(item => item !== 'others'))
  }

  const handleChangeReasonInput = (event) => {
    const txt = event.target.value
    setReasonDetail(txt.trim())
  }

  const closeHappinessSurveyDialog = async () => {
    props.setLoading(true)
    try {
      await Api.withdraw(reasons, reasonDetail, allowHearing, allowReEntry, allowSms)
      setOpenHappinessSurveyDialog(false)
      setOpenWithdrawalConfirmDialog(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleWithdrawalConfirmDialogClose = () => {
    setOpenWithdrawalConfirmDialog(false)
    onSent?.()
  }

  const renderValue = (selected) => (
    <Grid container>
      {selected.map(value => <Chip className={classes.chip} key={value} label={reasonValues[value]} /> )}
    </Grid>
  )

  const reasonDetailError = () => (reasonIsOthers && !reasonDetail) || reasonDetail.length > maxReasonInputLength

  const withdrawalValidate = () => reasons.length === 0 || (reasonIsOthers && reasonDetailError())

  const onWithdrawal = async () => {
    const isGraduate = reasons.includes('graduate')
    // 「Aill goenで恋人ができた」を選択している場合アプリ評価ポップアップを表示
    if (isGraduate) {
      setOpenAppReviewDialog(true)
    // 該当しない場合は幸福度アンケート表示
    } else {
      setOpenHappinessSurveyDialog(true)
      onCancel?.()
    }
  }

  const closeAppReviewDialog = async () => {
    setOpenAppReviewDialog(false)
    // 「Aill goenで恋人ができた」を選択しかつ「ヒアリングOKの人」のみにカップルヒアリング依頼ポップアップを表示
    if (allowHearing) {
      props.setLoading(true)
      try {
        const res = await Api.getDialodMaster('Ask.CoupleHearing')
        const detail = JSON.parse(res.detail)
        const params = {
          'type': res.name,
          'incentive_type': detail.incentive_type,
          'incentive_name': detail.incentive_name,
          'incentive_amount': detail.incentive_amount,
          'answer_date': dateFormat(addDate(new Date(), detail.answer_days), 'yyyy-mm-dd'),
          'form_url': detail.form_url,
        }
        setOpenCoupleHearingDialog(true)
        setCoupleHearingParams(params)
        onCancel?.()
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    // 該当しない場合は幸福度アンケート表示
    } else {
      setOpenHappinessSurveyDialog(true)
      onCancel?.()
    }
  }

  const onCloseCooperateDialog = () => {
    setOpenCoupleHearingDialog(false)
    setOpenHappinessSurveyDialog(true)
  }

  const createAllowHearing = () => (
    <FormGroup>
      <FormControlLabel
        disableTypography
        control={
          <Checkbox
            checked={allowHearing}
            onChange={() => setAllowHearing(!allowHearing)}
            style={{ color: '#423BC7' }}
          />
        }
        label="Aillサービス向上のためにヒアリングに協力してもよい"
        style={{ fontSize: 12 }}
      />
      <ul className={classes.formText}>
        <li>ご協力いただいた場合はスターバックス券1,000円以上プレゼント</li>
        <li>ヒアリングのご相談をさせていただく場合は、ご登録のメールアドレスにご連絡いたします</li>
      </ul>
    </FormGroup>
  )

  const createAllowReEntry = () => (
    <FormGroup>
      <FormControlLabel
        disableTypography
        control={
          <Checkbox
            checked={allowReEntry}
            onChange={() => setAllowReEntry(!allowReEntry)}
            style={{ color: '#423BC7' }}
          />
        }
        label="再入会する場合データの引き継ぎを許可する"
        style={{ fontSize: 12 }}
      />
    </FormGroup>
  )
  const createAllowPhoneNumber = () => (
    <FormGroup>
      <FormControlLabel
        disableTypography
        control={
          <Checkbox
            checked={allowSms}
            onChange={() => setAllowSms(!allowSms)}
            style={{ color: '#423BC7' }}
          />
        }
        label="SMS(ショートメッセージサービス)による連絡を許可する"
        style={{ fontSize: 12 }}
      />
    </FormGroup>
  )

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} data-testid="withdrawal-dialog">
        <DialogTitle disableTypography>
          退会の理由を教えてください<br />
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            Aill サービス向上の参考にさせていただきます。<br />
            下記質問への回答をお願いいたします。
          </DialogContentText>
          <div className={classes.subTitle}>
            退会理由（複数選択可）：
          </div>
          <Divider className={classes.divider} />
          <Select
            className={classes.reasonSelect}
            classes={{ root: classes.select }}
            multiple
            disableUnderline
            value={reasons}
            onChange={handleChangeReasons}
            input={<Input />}
            IconComponent={() => <KeyboardArrowDown />}
            renderValue={renderValue}
            MenuProps={{
              PaperProps: { style: { maxHeight: 300 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              getContentAnchorEl: null,
            }}
          >
            {Object.keys(reasonValues).map(key => (
              <MenuItem
                className={classes.menuItem}
                classes={{ selected: classes.selectedItem }}
                key={key}
                value={key}
              >
                {reasonValues[key]}
              </MenuItem>
            ))}
          </Select>
          <Divider className={classes.divider} />
          <div className={classes.subTitle}>
            理由が「その他」の場合：
          </div>
          <InputBase
            className={classes.input}
            classes={{ error: classes.inputError }}
            value={reasonDetail}
            onChange={handleChangeReasonInput}
            disabled={!reasonIsOthers}
            error={reasonDetailError()}
            placeholder="メッセージを入力"
            multiline
            rows="6"
          />
          {createAllowReEntry()}
          {createAllowPhoneNumber()}
          {createAllowHearing()}
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onWithdrawal} disabled={withdrawalValidate()}>
            退会
          </Button>
          <Button variant="text" onClick={onCancel}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <HappinessSurveyDialog
        open={openHappinessSurveyDialog}
        onClose={closeHappinessSurveyDialog}
        timingType="withdrawal"
        user={user}
        disableThanks
        {...other}
      />
      <ConfirmDialog
        open={openWithdrawalConfirmDialog}
        onClose={handleWithdrawalConfirmDialogClose}
        confirm="yes"
        title="退会申請受付完了"
        message={'退会申請の受付を完了しました。\n退会手続き完了後はログインができなくなります。\nこれまでAillをご愛用いただきありがとうございました。'}
      />
      <CooperateDialog
        open={openCoupleHearingDialog}
        params={coupleHearingParams}
        onClose={onCloseCooperateDialog}
        user={user}
      />
      <ConfirmDialog
        open={openAppReviewDialog}
        onCancel={closeAppReviewDialog}
        review
        title={'Aill goenは\nいかがでしたか？'}
        message={'アプリストアで評価してください。'}
        toAppStore={() => {
          window.open('https://apps.apple.com/us/app/aill/id1458844712?l=ja&ls=1')
          closeAppReviewDialog()
        }}
        toGooglePlay={() => {
          window.open('https://play.google.com/store/apps/details?id=jp.aill_corporation.aill')
          closeAppReviewDialog()
        }}
      />
    </DialogThemeProvider>
  )
}

WithdrawalDialog.propTypes = {
  open: PropTypes.bool,
  onSent: PropTypes.func,
  onCancel: PropTypes.func,
  setLoading: PropTypes.func,
  user: PropTypes.object,
}
