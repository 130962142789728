import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Api from 'commons/api'
import { isProfileIgnoredKeys } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  table: {
    width: '100%',
  },
  cell: {
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  cellTitle: {
    width: '50%',
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  itemTitle: {
    ...theme.styles.profileItem,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  itemValue: {
    ...theme.styles.profileItem,
    color: theme.palette.secondary.main,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    paddingRight: theme.spacing(1),
  },
  favoriteBorder: {
    color: '#AFB7F8',
    width: 18,
    height: 18,
    display: 'inline',
  },
  favoriteFill: {
    color: '#3D45C2',
    width: 18,
    height: 18,
    display: 'inline',
  },
  likeCell: {
    textAlign: 'right',
    borderStyle: 'none',
    padding: theme.spacing(0.5),
    verticalAlign: 'top',
  },
  likeCheckbox: {
    height: 32,
  },
})

export class LoveProfile extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleLikeChange = this.handleLikeChange.bind(this)

    this._isMounted = false

    this.state = {
      friend: null,
      likes: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.initProfileInputs()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  initProfileInputs () {
    const keys = this.getUserProfileDisplayKeys()
    const friend = {}
    const likes = {}
    keys.forEach(key => {
      if (Object.keys(this.props.friend).includes(key)) {
        friend[key] = this.getUserProfileItemLabel(this.props.friend, key) || ''
      }
      likes[key] = this.props.likes ? this.props.likes.my_likes[key] : false
    })
    this.setStateIfMounted({
      friend: friend,
      likes: likes,
    })
  }

  async handleLikeChange (event) {
    const { friend } = this.props
    const key = event.target.value
    const checked = event.target.checked
    try {
      const data = await Api.updateLikes(friend.id, { likes: { [key]: checked } })
      this.setStateIfMounted({ likes: data.my_likes })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  // テキスト複数行変換
  textConvertMultiline (text) {
    const row = text.split(/\r\n|\r|\n/).length
    if (row === 1) { return text }
    return (
      text.split(/\r\n|\r|\n/).map(
        (line, key) => <span key={key}>{line}<br /></span>
      )
    )
  }

  render () {
    if (!this.state.friend) return null
    const { classes, master, isLikes } = this.props
    const { friend, likes } = this.state
    return (
      <Grid container direction="column">
        <Table className={classes.table}>
          <tbody>
            {Object.keys(friend).map(key => {
              if (key === 'to_message') { return null }
              if (!isProfileIgnoredKeys(key)) {
                return (
                  <TableRow className={classes.row} key={key}>
                    <TableCell className={classes.cellTitle}>
                      <Typography className={classes.itemTitle}>{master.profile[key].title}</Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography className={classes.itemValue}>{friend[key]}</Typography>
                    </TableCell>
                    {isLikes && (
                      <TableCell className={classes.likeCell}>
                        <Checkbox
                          onChange={this.handleLikeChange}
                          icon={<FavoriteBorderIcon className={classes.favoriteBorder} />}
                          checkedIcon={<FavoriteIcon className={classes.favoriteFill} />}
                          value={key}
                          checked={likes[key]}
                          className={classes.likeCheckbox}
                          inputProps={{ 'data-testid': `checkbox-${key}` }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              }
            })}
          </tbody>
        </Table>
      </Grid>
    )
  }
}

LoveProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LoveProfile)
