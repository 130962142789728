import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/HelpOutline'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Api from 'commons/api'
import Const from 'commons/constant'
import { calculateAge, isCompletedLoveSheet, isEmpty } from 'commons/utility'
import InterestedThingsProfile from 'components/interested-things/InterestedThingsProfile'
import BaseComponent from 'components/parts/BaseComponent'
import CropDialog from 'components/parts/CropDialog'
import MyBasicProfile from 'components/parts/MyBasicProfile.jsx'
import MyLoveProfile from 'components/parts/MyLoveProfile.jsx'
import PhotoDialog from 'components/parts/PhotoDialog'
import PhotoFilter from 'components/parts/PhotoFilter'
import ProfilePublicTutorial from 'components/parts/ProfilePublicTutorial.jsx'
import PhotoTutorialDialog from 'components/photo-tutorial/PhotoTutorialDialog'
import ProfileEditGallery from './profile-edit-gallery/ProfileEditGallery'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  backButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: 64,
    height: 51,
    padding: 0,
    zIndex: '999'
  },
  backIcon: {
    color: theme.palette.secondary.main,
  },
  profileContainer: {
    width: '100%',
  },
  imageWidth: {
    ...theme.styles.profilePhotoWidth,
  },
  imageContainer: {
    ...theme.styles.photoContainer,
  },
  imageMain: {
    ...theme.styles.photoSize,
    objectFit: 'cover',
  },
  imageGallery: {
    ...theme.styles.photoSize,
    objectFit: 'contain',
  },
  gridSpace: {
    marginTop: theme.spacing(1),
  },
  photoAutoEditContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  photoAutoEditFormControlLabel: {
    justifyContent: 'flex-end',
    marginRight: `${theme.spacing(2.125)}px !important`,
    '& .MuiCheckbox-root': {
      width: 14,
      height: 14,
      marginRight: 4,
    },
    '& .MuiSvgIcon-root': {
      transform: 'scale(0.7777777778)', // 14px / 18px
    }
  },
  photoAutoEditLabel: {
    fontSize: 12,
  },
  nameContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
    marginTop: theme.spacing(2),
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: 'calc(100% - 32px)',
    maxHeight: theme.spacing(7),
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    textAlign: 'left',
  },
  age: {
    fontSize: 11,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  messageContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
    textAlign: 'left',
  },
  message: {
    width: '100%',
    fontSize: 12,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '8',
  },
  messageAddMargin: {
    width: '100%',
    fontSize: 12,
    marginTop: theme.spacingHeight(2),
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '7',
  },
  editButton: {
    fontSize: 12,
    flexBasis: theme.spacing(3),
  },
  basicProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  loveProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  profileTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.375),
    textAlign: 'left',
  },
  helpIcon: {
    fontSize: 24,
    opacity: 0.5,
  },
  IconContainer: {
    padding: 0,
    position: 'absolute',
    right: 24,
  },
  loveBututon: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: 268,
    height: 44,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

// MyPage ->「基本情報編集」「恋愛情報編集」の選択
class ProfileEdit extends BaseComponent {
  constructor (props) {
    super(props)

    const { user } = props

    this.handleReadImportedFile = this.handleReadImportedFile.bind(this)
    this.handleReadImportedGallery = this.handleReadImportedGallery.bind(this)
    this.clickBack = this.clickBack.bind(this)
    this.openMessageEdit = this.openMessageEdit.bind(this)
    this.showTutorial = this.showTutorial.bind(this)
    this.closeTutorial = this.closeTutorial.bind(this)
    this.clickLoveInput = this.clickLoveInput.bind(this)

    this.selectedGalleryItem = null
    this._isMounted = false
    this.state = {
      photoIcon: (user?.photo_large_to_change || user.photo_large),
      nick_name: user.nick_name || '',
      openCropPhotoDialog: false,
      openCropGalleryDialog: false,
      openPhotoDialogGallery: false,
      galleryItems: [],
      originalFile: null,
      photoUrl: null,
      imageFileUrl: null,
      selectedGalleryItemLargeUrl: null,
      selectedGalleryItemBrightness: user.photo_filter_brightness,
      openTutorialDialog: false,
      openTutorialDialogGallery: false,
      photo_type: 'main',
      selectedGalleryIndex: 0,
      profilePublicSetting: null,
      openProfilePublicTutorial: false,
      allowAutoPhotoAdjustment: !!user.allow_auto_photo_adjustment,
    }
  }

  componentWillMount () {
    this.reloadPhotos()
    this.getProfilePublicSetting(this.props.user.id)
  }

  componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  handleReadImportedFile (event) {
    let originalFile = event.target.files[0]
    if (!originalFile) { return }
    let createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    let imageUrl = createObjectURL(originalFile)
    this.setState({
      openCropPhotoDialog: true,
      originalFile,
      photoUrl: imageUrl,
      openTutorialDialog: false,
    })
  }

  uploadCroppedPhotoFile = async (file, imgFilters) => {
    this.props.setLoading(true)
    try {
      let user = await this.updatePhoto(file, imgFilters)
      await this.reloadPhotos()
      this.setStateIfMounted({
        openPhotoDialogGallery: false,
        openCropPhotoDialog: false,
        photoUrl: null,
        photoIcon: (user?.photo_large_to_change || user.photo_large),
      })
    } catch (error) {
      this.setStateIfMounted({
        openPhotoDialogGallery: false,
        openCropPhotoDialog: false,
        photoUrl: null,
      })
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleReadImportedGallery (event) {
    let originalFile = event.target.files[0]
    if (!originalFile) { return }
    let createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    let imageUrl = createObjectURL(originalFile)
    this.setState({
      openCropGalleryDialog: true,
      originalFile,
      imageFileUrl: imageUrl,
      openTutorialDialogGallery: false,
    })
  }

  uploadCroppedGalleryFile = async (file, imgFilters) => {
    this.props.setLoading(true)
    try {
      // 最大数に達している場合は最初の画像を削除してからアップロード
      if (Const.maxGalleryItemCount <= this.state.galleryItems.length) {
        let itemToDel = this.state.galleryItems[0]
        await Api.deleteMyGalleryItem(itemToDel)
      }
      await this.addGalleryItem(file, imgFilters)
      await this.reloadGalleryPhotos()
      let index = this.state.galleryItems.length - 1
      this.setStateIfMounted({
        selectedGalleryIndex: index,
        photoIcon: this.state.galleryItems[index].large_url,
        photo_type: 'gallery',
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.closeCropGalleryDialog()
      this.props.setLoading(false)
    }
  }

  async updateGalleryDisplayOrder (itemId, displayOrder) {
    try {
      await Api.updateMyGalleryItemDisplayOrder(itemId, displayOrder)
    } catch (error) {
      this.handleApiError(error)
    }
  }

  openCropGalleryDialog (url) {
    this.setState({
      openCropGalleryDialog: true,
      imageFileUrl: url
    })
  }

  closeCropGalleryDialog = () => {
    this.setState({
      openCropGalleryDialog: false,
      imageFileUrl: null
    })
  }

  openCropPhotoDialog (url) {
    this.setState({
      openCropPhotoDialog: true,
      photoUrl: url
    })
  }

  closeCropPhotoDialog = () => {
    this.setState({
      openCropPhotoDialog: false,
      photoUrl: null
    })
  }

  openPhotoDialogGallery (index) {
    let item = this.state.galleryItems[index]
    if (item) {
      let photo_type = 'main'
      if (index !== 0) {
        photo_type = 'gallery'
      }
      this.selectedGalleryItem = item
      this.setState({
        openPhotoDialogGallery: true,
        selectedGalleryItemLargeUrl: item.large_url,
        selectedGalleryItemBrightness: item.photo_filter_brightness,
        selectedGalleryIndex: index,
        photoIcon: item.large_url,
        photo_type,
      })
    }
  }

  closePhotoDialogGallery () {
    this.selectedGalleryItem = null
    this.setState({
      openPhotoDialogGallery: false,
      selectedGalleryItemLargeUrl: null,
    })
  }

  async reloadPhotos () {
    this.props.setLoading(true)
    await this.reloadUserPhotos()
    await this.reloadGalleryPhotos()
    this.props.setLoading(false)
  }

  async reloadUserPhotos () {
    let user = await this.loadUser()
    this.setStateIfMounted({ photoIcon: (user?.photo_large_to_change || user.photo_large) })
  }

  async reloadGalleryPhotos () {
    const { user } = this.props
    try {
      // メイン写真
      const mainItem = {
        icon_url: (user?.photo_icon_to_change || user.photo_icon),
        large_url: (user?.photo_large_to_change || user.photo_large),
        photo_filter_brightness: user.photo_filter_brightness
      }
      // ギャラリー写真
      const galleryItems = await this.reloadMyGalleryItems()
      galleryItems.unshift(mainItem)
      this.setStateIfMounted({ galleryItems })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async deletePhoto () {
    this.props.setLoading(true)
    let item = this.selectedGalleryItem
    try {
      await Api.deleteMyGalleryItem(item)
      await this.reloadGalleryPhotos()
      this.selectedGalleryItem = null
      this.setStateIfMounted({
        openPhotoDialogGallery: false,
        selectedGalleryItemLargeUrl: null,
        selectedGalleryItemBrightness: null,
        selectedGalleryIndex: 0,
        photoIcon: this.state.galleryItems[0].large_url,
        photo_type: 'main',
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  clickBack () {
    BaseComponent.goBack(this.props)
  }

  openMessageEdit () {
    this.props.setScreen('MessageEdit')
  }

  async getProfilePublicSetting (user_id) {
    try {
      let setting = await Api.getProfilePublicSetting(user_id)
      this.setStateIfMounted({
        profilePublicSetting: setting,
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  showTutorial () {
    this.setState({ openProfilePublicTutorial: true })
  }

  closeTutorial () {
    this.setState({ openProfilePublicTutorial: false })
  }

  clickLoveInput () {
    this.props.setScreen('Love')
  }

  handleChangeAutoPhotoAdjustment = async (event, newValue) => {
    try {
      await this.updateUser({ allow_auto_photo_adjustment: newValue })
      this.setState({ allowAutoPhotoAdjustment: newValue })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  render () {
    const { classes, ...other } = this.props
    const { galleryItems, selectedGalleryIndex, selectedGalleryItemBrightness } = this.state
    return (
      <div className={classes.root}>
        <CropDialog
          open={this.state.openCropPhotoDialog}
          originalFile={this.state.originalFile}
          photoUrl={this.state.photoUrl}
          showFilters={true}
          onUpload={this.uploadCroppedPhotoFile}
          onClose={this.closeCropPhotoDialog}
          aspectRatio={1 / 1}
          {...other}
        />
        <CropDialog
          open={this.state.openCropGalleryDialog}
          originalFile={this.state.originalFile}
          photoUrl={this.state.imageFileUrl}
          showFilters={true}
          onUpload={this.uploadCroppedGalleryFile}
          onClose={this.closeCropGalleryDialog}
          aspectRatio={1 / 1}
          {...other}
        />
        <PhotoDialog
          contentId={'ProfileEdit_PhotoDialog_Gallery'}
          open={this.state.openPhotoDialogGallery}
          imageUrl={this.state.selectedGalleryItemLargeUrl}
          brightness={selectedGalleryItemBrightness}
          onClose={this.closePhotoDialogGallery.bind(this)}
          deletebutton={selectedGalleryIndex === 0 ? false : true}
          changebutton={selectedGalleryIndex === 0 ? true : false}
          onChange={() => this.setState({ openTutorialDialog: true })}
          onDelete={this.deletePhoto.bind(this)}
          slider
        />
        <PhotoTutorialDialog
          open={this.state.openTutorialDialog}
          onClose={() => this.setState({ openTutorialDialog: false })}
          type="main"
        />
        <PhotoTutorialDialog
          open={this.state.openTutorialDialogGallery}
          onClose={() => this.setState({ openTutorialDialogGallery: false })}
          type="gallery"
        />
        <ProfilePublicTutorial
          open={this.state.openProfilePublicTutorial}
          onClose={this.closeTutorial}
        />
        <IconButton aria-label="add" className={classes.backButton} onClick={this.clickBack} data-testid="back-button">
          <LeftIcon className={classes.backIcon}/>
        </IconButton>
        <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.profileContainer}>
          <Grid container alignItems="center" justifyContent="center" className={classes.imageWidth}>
            <PhotoFilter brightness={selectedGalleryItemBrightness} className={classes.imageContainer}>
              <img
                src={this.state.photoIcon}
                className={this.state.photo_type === 'main' ? classes.imageMain : classes.imageGallery}
                alt=""
              />
            </PhotoFilter>
          </Grid>
          <ProfileEditGallery
            user={this.props.user}
            galleryItems={galleryItems}
            selectedGalleryIndex={selectedGalleryIndex}
            handleOpenTutorialDialogGallery={() => this.setState({ openTutorialDialogGallery: true })}
            handleOpenPhotoDialogGallery={this.openPhotoDialogGallery.bind(this)}
            handleUpdateDisplayOrder={this.updateGalleryDisplayOrder.bind(this)}
            setGalleryItems={(func) => this.setState((prev) => ({ galleryItems: func(prev.galleryItems) }))}
          />
          <form noValidate autoComplete="off">
            <input
              id="file"
              type="file"
              accept="image/*"
              onClick={event => {
                event.target.value = null
                return false
              }}
              onChange={this.handleReadImportedFile}
              style={{
                width: 0,
                height: 0,
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute',
                zIndex: 1,
              }}
            />
          </form>
          <form noValidate autoComplete="off">
            <input
              id="fileGallery"
              type="file"
              accept="image/*"
              onClick={event => {
                event.target.value = null
                return false
              }}
              onChange={this.handleReadImportedGallery}
              style={{
                width: 0,
                height: 0,
                opacity: 0,
                overflow: 'hidden',
                position: 'absolute',
                zIndex: 1,
              }}
            />
          </form>
          <FormGroup className={classes.photoAutoEditContainer}>
            <FormControlLabel
              className={classes.photoAutoEditFormControlLabel}
              control={
                <Checkbox
                  checked={this.state.allowAutoPhotoAdjustment}
                  onChange={this.handleChangeAutoPhotoAdjustment}
                />
              }
              label={
                <Typography className={classes.photoAutoEditLabel}>写真を自動調整する</Typography>
              }
            />
          </FormGroup>
          <Grid
            container direction="row" justifyContent="flex-start" alignItems="flex-end"
            className={classes.nameContainer}
          >
            <Typography className={classes.name}>
              {this.props.user.nick_name}
            </Typography>
            <Typography className={classes.age}>
              {calculateAge(this.props.user.birthday)}歳
            </Typography>
          </Grid>
          <Grid container direction="column" alignItems="flex-end" className={classes.messageContainer}>
            {
              !isEmpty(this.props.user.to_message) &&
              <Typography className={Array.isArray(this.textConvertMultiline(this.props.user.to_message)) ? classes.message : classes.messageAddMargin}>
                {this.textConvertMultiline(this.props.user.to_message)}
              </Typography>
            }
            <Button
              variant="text"
              color="secondary"
              className={classes.editButton}
              onClick={this.openMessageEdit}
            >
              編集する
            </Button>
          </Grid>
          <div className={classes.basicProfileContainer}>
            <Typography className={classes.profileTitle}>
              興味のあること
            </Typography>
            <InterestedThingsProfile {...this.other} user={this.props.user}/>
            <Grid container direction="column" alignItems="flex-end">
              <Button
                variant="text"
                color="secondary"
                className={classes.editButton}
                onClick={() => this.props.setScreen('InterestedThings')}
              >
                編集する
              </Button>
            </Grid>
          </div>
          {
            this.state.profilePublicSetting &&
            <div className={classes.basicProfileContainer}>
              <Typography className={classes.profileTitle}>
                基本情報
              </Typography>
              <MyBasicProfile isEdit={true} isPublicProfile={true} publics={this.state.profilePublicSetting} {...other} />
            </div>
          }
          {
            this.state.profilePublicSetting &&
            <div className={classes.loveProfileContainer}>
              <Typography className={classes.profileTitle}>
                恋愛情報
                <IconButton className={classes.IconContainer} onClick={this.showTutorial}>
                  <HelpIcon className={classes.helpIcon} color="secondary"/>
                </IconButton>
              </Typography>
              {
                isCompletedLoveSheet(this.props.user) ? (
                  <MyLoveProfile isEdit={true} isPublicProfile={true} publics={this.state.profilePublicSetting} {...other}  />
                ) : (
                  <Grid container alignItems="center" justifyContent="space-around">
                    <Button variant="contained" color="secondary" className={classes.loveBututon} onClick={this.clickLoveInput}>
                      恋愛情報を登録する
                    </Button>
                  </Grid>
                )
              }
            </div>
          }
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ProfileEdit)
