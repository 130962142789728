import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate, addSeconds } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import GiftCard from 'images/gift_card_null.png'
import SerifLeft from 'images/komainu_serif_left.png'
import SerifRight from 'images/komainu_serif_right.png'

const useStyles = makeStyles(theme => ({
  pinkBadge: {
    background: '#F55083',
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 11,
    marginTop: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  serif: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
    position: 'relative',
    marginBottom: theme.spacing(1.5),
  },
  giftcard: {
    position: 'relative',
    marginBottom: theme.spacing(-2),
  },
  giftcardText: {
    width: '100%',
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    position: 'absolute',
    bottom: 32,
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  challenge: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  missionBox: {
    width: 216,
    background: '#ffffff',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
  },
  achievedFilter: {
    width: '100%',
    height: '100%',
    background: 'rgba(176, 180, 252, 0.8)', // #B0B4FC
    borderRadius: 12,
    position: 'absolute',
    zIndex: 1,
  },
  achievedText: {
    width: 64,
    height: 64,
    fontSize: 18,
    fontWeight: 600,
    color: '#423BC7',
    border: '3px solid #423BC7',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  missionText:{
    fontSize: 14,
    fontWeight: 600,
    color: '#423BC7',
    marginBottom: theme.spacing(1.5),
  },
  missionStep: {
    width: 32 ,
    height: 32,
    fontSize: 8,
    fontWeight: 600,
    color: '#707070',
    border: '1px dotted #656565',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  achievedStep: {
    width: 32,
    height: 32,
    fontSize: 9,
    fontWeight: 600,
    color: '#ffffff',
    background: '#423BC7',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(-20deg)',
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  note: {
    marginTop: theme.spacing(1),
  },
  detail: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  detailBox: {
    width: 216,
    background: '#ffffff',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
  },
  detailTitle: {
    color: '#423BC7',
    textAlign: 'left',
    margin: 0,
  },
  detailText: {
    fontWeight: 300,
    color: '#423BC7',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  achievedNote: {
    fontSize: 12,
    fontWeight: 300,
    marginTop: theme.spacing(1.5),
    marginBottom: 0,
  },
}))

export default function FreeTermExtensionChallengeDialog (props) {
  const { open, onClose, friendApplyMission, chatOpenMission } = props
  const { matchingState } = props
  const classes = useStyles()

  if (!friendApplyMission) { return null }
  if (!chatOpenMission) { return null }
  if (!matchingState) { return null }
  if (!matchingState.first_boost_end_at) { return null }

  // 期間
  const startAt = new Date(matchingState.first_boost_end_at)
  const endAt = new Date(friendApplyMission.end_at)
  // 進捗
  const friendApplyProgress = friendApplyMission.progress
  const chatOpenProgress = chatOpenMission.progress

  const createAchievedFilter = () => (
    <Grid className={classes.achievedFilter} container justifyContent="center" alignItems="center">
      <Typography className={classes.achievedText}>達成!</Typography>
    </Grid>
  )

  const createMissionBox = (type, achievedStep = 0) => {
    if (!(type === 'friendApply' || type === 'chatOpen')) { return }
    const missionLength = type === 'friendApply' ? 5 : 2
    const text = type === 'friendApply' ? '「いいね！」5人送信で\n無料期間が7日延長！' : '2人以上とトーク開通*で\n無料期間が7日延長！'
    const IntArray = [...Array(missionLength)].map((_, i) => i + 1)
    return (
      <Grid className={classes.missionBox} container justifyContent="center" alignItems="center">
        {missionLength === achievedStep && createAchievedFilter()}
        <Typography className={classes.missionText}>{text}</Typography>
        <Grid container justifyContent="center" alignItems="center">
          {IntArray.map(num => (
            <Typography key={num} className={`${num <= achievedStep ? classes.achievedStep : classes.missionStep}`}>
              {num <= achievedStep ? '達成!' : <><span style={{ fontSize: 11 }}>{num}</span>人目</>}
            </Typography>
          ))}
        </Grid>
      </Grid>
    )
  }

  const handleClose = () => {
    if (!onClose) { return }
    const totalProgress = friendApplyMission.progress + chatOpenMission.progress
    onClose(totalProgress)
  }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={handleClose} />
        <DialogTitle disableTypography>
          無料トライアル期間<br />
          延長チャレンジ
          <Typography className={classes.pinkBadge}>
            {dateFormat(addSeconds(endAt, -1), 'yyyy/mm/dd HH:MM')} まで
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container justifyContent="space-around" alignItems="flex-start">
            <img src={SerifLeft} width={24} />
            <Typography className={classes.serif} variant="body1">
              チャレンジ達成で<br />
              無料期間が最大14日延長！
            </Typography>
            <img src={SerifRight} width={24} />
          </Grid>
          <div className={classes.giftcard}>
            <img src={GiftCard} width={216} />
            <Typography className={classes.giftcardText}>
              <span style={{ fontSize: 15, fontWeight: 700 }}>2つのチャレンジ</span>を<br />
              クリアしよう！
            </Typography>
          </div>
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ1</Typography>
          {createMissionBox('friendApply', friendApplyProgress)}
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ2</Typography>
          {createMissionBox('chatOpen', chatOpenProgress)}
          <Typography className={classes.note} variant="body2">
            *いいね！送信・いいね！承認どちらも対象
          </Typography>
          <Typography className={classes.detail} variant="subtitle1">
            チャレンジ詳細
          </Typography>
          <div className={classes.detailBox}>
            <Typography className={classes.detailTitle} variant="body1">対象期間</Typography>
            <Typography className={classes.detailText} variant="body1">
              {dateFormat(startAt, 'yyyy/mm/dd')} ~ {dateFormat(addDate(endAt, -1), 'yyyy/mm/dd')}
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">チャレンジ内容</Typography>
            <Typography className={classes.detailText} variant="body1">
              1.対象期間内に2人以上とトーク開通<br />
              2.対象期間内に「いいね！」を5人以上に送信<br />
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">特典内容</Typography>
            <Typography className={classes.detailText} variant="body1">
              チャレンジの達成状況に合わせ、A・Bどちらかの特典が付与されます。<br />
              A.チャレンジ1、2のどちらか達成の場合、無料トライアル期間が7日間延長<br />
              B.チャレンジ1、2どちらも達成の場合、無料トライアル期間が14日間延長
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">特典適用時期</Typography>
            <Typography className={classes.detailText} variant="body1">
              無料トライアル期間終了後から
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">注意事項</Typography>
            <Typography className={classes.detailText} variant="body1">
              下記に該当する場合、当無料延長チャレンジ特典の期間終了後に各プランへ変更・チケット消費が開始されます。<br />
              1.無料トライアル期間中にスタンダートプランに変更した場合<br />
              2.無料トライアル期間中にフリープラン変更した場合<br />
              3.無料トライアル期間中に企業特典チケットを取得した場合（該当企業のみ）
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtensionChallengeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  friendApplyMission: PropTypes.object,
  chatOpenMission: PropTypes.object,
  matchingState: PropTypes.object,
}
