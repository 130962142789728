import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Icon1 from 'images/img_remind_dialog_1.png'
import Icon2 from 'images/img_remind_dialog_2.png'

const useStyles = makeStyles(theme => ({
  serifBox: {
    marginTop: theme.spacing(2),
  },
  img: {
    height: 22,
  },
  serif: {
    fontSize: 10,
    fontWeight: 700,
    color: '#ffffff',
    lineHeight: 1.5,
    paddingLeft: theme.spacing(1),
    margin: 0,
    whiteSpace: 'pre-wrap',
  },
  komaImg: {
    height: 44,
    margin: theme.spacing(1),
  },
}))

export default function KomainuAdvice (props) {
  const { text, img01, img02 } = props
  const classes = useStyles()
  return (
    <React.Fragment>
      <Grid className={classes.serifBox} container justifyContent="center" alignItems="center">
        <img src={Icon1} className={classes.img} />
        <Typography className={classes.serif}>
          {text}
        </Typography>
        <img src={Icon2} className={classes.img} />
      </Grid>
      <Grid container justifyContent="center">
        {img01 && <img src={img01} className={classes.komaImg} />}
        {img02 && <img src={img02} className={classes.komaImg} />}
      </Grid>
    </React.Fragment>
  )
}
KomainuAdvice.propTypes = {
  text: PropTypes.string,
  img01: PropTypes.string,
  img02: PropTypes.string,
}