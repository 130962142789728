import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import { canUseCafeteriaPoint } from 'commons/utility'
import PlanInfo from 'components/members/PlanInfo'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import PlanConfirmDialog from 'components/plan/PlanConfirmDialog'

const useStyles = makeStyles(() => ({
  infoText: {
    fontSize: 14,
    color: '#1A1667',
    textAlign: 'center'
  },
  link: {
    fontWeight: 600,
    color: '#1A1667',
    textDecoration: 'underline',
    textDecorationColor: '#1A1667',
    '& :visited': {
      color: '#1A1667'
    }
  },
}))

export default function SelectPlan (props) {
  const {
    subscription, organization, myPref, monthlyFees, nextMonthlyFees,
    payment, matchingState, scroll, refs, ...others
  } = props
  const classes = useStyles()
  const [nextBilling, setNextBilling] = useState(null)
  const [openPlanConfirmDialog, setOpenPlanConfirmDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  if (!subscription) {
    return (
      <Typography>読み込み中…</Typography>
    )
  }

  const onClickSelectPlan = async (planId, months) => {
    props.setLoading(true)
    try {
      const nextBilling = await Api.getMyNextBilling(planId, months)
      if (0 < nextBilling.amount && !payment) {
        setOpenConfirmDialog(true)
        return
      }
      setNextBilling(nextBilling)
      setOpenPlanConfirmDialog(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onConfirmOK = async (keepFriendUserId) => {
    setOpenPlanConfirmDialog(false)
    props.setLoading(true)
    try {
      const params = {
        content: nextBilling.plan_id,
        payment_months: nextBilling.payment_months,
        keep_friend_user_id: keepFriendUserId,
      }
      await Api.updateMySubscription(params)
      window.location.reload()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  // 特別フリープランが利用できる場合
  if (BaseComponent.canUseSpecialFreePlan(myPref, subscription)) {
    return (
      <>
        <Typography className={classes.infoText}>現在お住まいの地域では</Typography>
        <Typography className={classes.infoText}>特別フリープランのみご選択いただけます。</Typography>
        <PlanInfo
          planType={Const.planTypes.SPECIAL_FREE}
          subscription={subscription}
          organization={organization}
          monthlyFees={monthlyFees}
          onClickSelectPlan={onClickSelectPlan}
          refs={refs}
        />
      </>
    )
  }

  if (!monthlyFees) {
    return <Typography>読み込み中…</Typography>
  }

  return (
    <>
      {canUseCafeteriaPoint(props.user) ? (
        <>
          <Typography className={classes.infoText}>2つのプランをご用意しています。</Typography>
          <Typography component="div" className={classes.infoText}>カフェテリアポイントを利用しての支払いも可能です。</Typography>
          <Typography className={classes.infoText}>
            カフェテリアポイントについては
            <a className={classes.link} onClick={() => scroll('cafeteriaRef')}>こちら</a>
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.infoText}>スタンダードプランとフリープランの</Typography>
          <Typography className={classes.infoText}>2つのプランをご用意しています。</Typography>
        </>
      )}
      <PlanInfo
        planType={Const.planTypes.STANDARD}
        subscription={subscription}
        organization={organization}
        monthlyFees={organization.full_paid_enabled ? nextMonthlyFees : monthlyFees}
        matchingState={matchingState}
        onClickSelectPlan={onClickSelectPlan}
        refs={refs}
      />
      <PlanInfo
        planType={Const.planTypes.FREE}
        subscription={subscription}
        organization={organization}
        monthlyFees={monthlyFees}
        onClickSelectPlan={onClickSelectPlan}
        refs={refs}
      />
      <PlanConfirmDialog
        open={openPlanConfirmDialog}
        subscription={subscription}
        organization={organization}
        nextBilling={nextBilling}
        matchingState={matchingState}
        onConfirmOK={onConfirmOK}
        onClose={() => setOpenPlanConfirmDialog(false)}
        {...others}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        close="yes"
        title="エラー"
        message="お支払い方法を登録してください。"
      />
    </>
  )
}

SelectPlan.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  organization: PropTypes.object,
  myPref: PropTypes.object,
  monthlyFees: PropTypes.array,
  nextMonthlyFees: PropTypes.array,
  payment: PropTypes.object,
  matchingState: PropTypes.object,
  scroll: PropTypes.func,
  refs: PropTypes.object,
  setLoading: PropTypes.func,
}
