import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LineImg from 'images/sns-icon/line_icon.png'

const useStyles = makeStyles(() => ({
  root: {
    width: sp => sp ? 105 : 215,
    height: sp => sp ? 105 : 215,
    background: '#ffffff',
    borderRadius: '50%',
  },
  link: {
    display: 'inline-block',
    margin: 'auto',
  },
}))

export default function LineAddFriendIcon () {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  const width = spMatches ? 41 : 86
  const height = spMatches ? 41 : 86

  return (
    <Grid className={classes.root} container justifyContent="center" alignItems="center">
      <a
        className={classes.link}
        href="https://line.me/R/ti/p/@408cfcnr?oat_content=url"
        target="_blank"
        rel="noreferrer"
        data-testid="icon-line"
      >
        <img src={LineImg} width={width} height={height} alt="" />
      </a>
    </Grid>
  )
}
