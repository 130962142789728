import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import AImage from 'images/img_character_a.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import BackgroundImage from 'images/tutorial/img_message_background.png'
import exGallery from 'images/tutorial/img_photo_gallery.png'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#E4D9F4',
  },
  bar: {
    ...theme.styles.header,
  },
  toolbar: {
    backgroundColor: '#B0B4FC',
    minHeight: theme.styles.header.height,
    position: 'relative',
  },
  barTitle: {
    width: '75%',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  backButton: {
    padding: 0,
    position: 'absolute',
    left: 16,
    color: theme.palette.secondary.main,
  },
  imageFootA: {
    width: 22,
    marginRight: theme.spacing(1),
  },
  imageFootUn: {
    width: 22,
    marginLeft: theme.spacing(1),
  },
  titleText: {
    fontSize: 11,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
  },
  point1container: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: 80,
    marginTop: `calc(${theme.styles.header.height}px - 20px + ${theme.spacing(3)}px)`,
    textAlign: 'center',
    position: 'relative',
  },
  avatarContainer: {
    marginLeft: -theme.spacing(3.75),
    height: 32,
  },
  goodTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#8636D6',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: theme.spacing(2),
  },
  goodAvatar: {
    width: 36,
    height: 36,
    objectFit: 'contain',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  exampleTitle: {
    color: '#8636D6',
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 8,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  imageContainer: {
    marginTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  iconGallery: {
    width: '80%',
    maxWidth: 400,
  },
  space: {
    height: 16,
  },
}))

export default function GalleryPage (props) {
  const { open } = props
  const classes = useStyles()

  return (
    <Dialog classes={{ paper: classes.paper }} fullScreen open={open}>
      <DialogContent id='scroll-tutorial' className={classes.content}>
        <AppBar position="fixed" className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <IconButton aria-label="add" className={classes.backButton} onClick={props.clickBack}>
              <LeftIcon />
            </IconButton>
            <Grid container justifyContent="center" alignItems="center" className={classes.barTitle}>
              <img src={FootAImage} className={classes.imageFootA} alt="" />
              <Typography className={classes.titleText}>
                カグラとサカキのちょっとアドバイス
              </Typography>
              <img src={FootUnImage} className={classes.imageFootUn} alt="" />
            </Grid>
          </Toolbar>
        </AppBar>
        <div>
          <div className={classes.point1container}>
            <Grid container justifyContent="center" alignItems="center" className={classes.avatarContainer}>
              <img src={AImage} className={classes.goodAvatar} alt="" />
              <Typography className={classes.goodTitle}>
                複数の写真を登録しよう！
              </Typography>
            </Grid>
            <Typography className={classes.exampleTitle}>
              Point.趣味の写真を登録すると好印象だよ！
            </Typography>
          </div>
          <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
            <img src={exGallery} className={classes.iconGallery} alt="" />
          </Grid>
          <div className={classes.space} />
        </div>
      </DialogContent>
    </Dialog>
  )
}

GalleryPage.propTypes = {
  open: PropTypes.bool,
  clickBack: PropTypes.func,
}