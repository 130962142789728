import { createTheme } from '@material-ui/core/styles'
import { typography } from 'commons/theme'

export const HEADER_HEIGHT = 64

export default createTheme({
  palette: {
    primary: {
      main: '#616AFA',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#616AFA',
    }
  },
  typography,
  overrides: {
    MuiButton: {
      contained: {
        width: 240,
        height: 46,
        borderRadius: 23,
      }
    },
    MuiTypography: {
      root: {
        color: '#707070',
      }
    },
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(5px) brightness(70%)',
        // Muiデフォルト色を打ち消し（transparent or 0 => safari可, chrome不可）
        backgroundColor: 'rgba(0, 0, 0, 0.003)',
      },
    },
  }
})
