import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import TutorialDialog from 'components/parts/TutorialDialog.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    padding: 0,
    right: 16,
    height: 24,
  },
  helpIcon: {
    color: theme.palette.secondary.contrastText,
  },
}))

export default function TutorialIcon (props) {
  const { user } = props
  const classes = useStyles()
  const [openTutorialDialog, setOpenTutorialDialog] = useState(false)

  return (
    <div className={classes.root}>
      <SvgIcon className={classes.helpIcon} onClick={() => setOpenTutorialDialog(true)}>
        <path
          d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
          stroke="#1A1667"
          strokeWidth={0.7}
        />
      </SvgIcon>
      <TutorialDialog
        {...props}
        open={openTutorialDialog}
        onOpen={() => setOpenTutorialDialog(true)}
        onClose={() => setOpenTutorialDialog(false)}
        sex={user.sex}
        name={user.nick_name}
      />
    </div>
  )
}

TutorialIcon.propTypes = {
  user: PropTypes.object,
}
