import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import Img1 from 'images/tutorial/friend_entry_tutorial_dialog_img_1.png'
import Img2 from 'images/tutorial/friend_entry_tutorial_dialog_img_2.png'

const styles = theme => ({
  slideIconsContainer: {
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: 8,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slide: {
    position: 'relative',
    height: '100%',
    textAlign: 'left',
    maxWidth: 400,
    margin: 'auto',
  },
  faceAvatar: {
    marginRight: theme.spacing(0.5),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground3),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 48px)',
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  speachUn: {
    ...theme.styles.naviSpeach(Const.colors.devilBackground3),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 48px)',
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  speachTextA: {
    fontSize: 12,
    fontWeight: 400,
    color: Const.colors.angel,
    wordBreak: 'break-all',
    textAlign: 'left',
  },
  speachTextUn: {
    fontSize: 12,
    fontWeight: 400,
    color: Const.colors.devil,
    wordBreak: 'break-all',
    textAlign: 'left',
  },
  image: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    objectFit: 'contain',
  },
  imageContainer: {
    marginTop: theme.spacing(2),
  },
})

export class FriendEntryTutorialDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      slideIndex: 0,
    }
  }

  onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      this.setState({slideIndex: index})
    }
  }

  render () {
    const { classes, ...other } = this.props
    return(
      <DialogThemeProvider color="secondary">
        <Dialog {...other}>
          <DialogTitle disableTypography>
            {this.state.slideIndex === 0 ? '第一印象をつけよう！' : '「いいね！」を送ろう！'}
          </DialogTitle>
          <DialogContent>
            <SwipeableViews
              index={this.state.slideIndex}
              onChangeIndex={this.onChangeSlideIndex}
            >
              <div style={Object.assign({})} className={classes.slide}>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="flex-end">
                  <img src={AImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachA}>
                    <Typography className={classes.speachTextA} variant="body1">
                      第一印象は僕たちの学習の重要指標にもなってるよ！
                    </Typography>
                  </div>
                </Grid>
                <Grid container wrap="nowrap" alignItems="flex-end">
                  <img src={UnImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachUn}>
                    <Typography className={classes.speachTextUn} variant="body1">
                      お相手も好感度が高い方が喜ぶよね。
                    </Typography>
                  </div>
                </Grid>
                <Grid container className={classes.imageContainer} justifyContent="center">
                  <img src={Img1} className={classes.image} alt=""/>
                </Grid>
              </div>
              <div style={Object.assign({})} className={classes.slide}>
                <Grid container justifyContent="center" alignItems="flex-end">
                  <Grid container wrap="nowrap" alignItems="flex-end">
                    <img src={AImage} className={classes.faceAvatar} alt=""/>
                    <div className={classes.speachA}>
                      <Typography className={classes.speachTextA} variant="body1">
                        相手も「いいね！」をするとトークが開設するよ
                      </Typography>
                    </div>
                  </Grid>
                  <Grid container wrap="nowrap" alignItems="flex-end">
                    <img src={UnImage} className={classes.faceAvatar} alt=""/>
                    <div className={classes.speachUn}>
                      <Typography className={classes.speachTextUn} variant="body1">
                        相手を知ることも、友達を解消するときも僕達に任せてね。
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className={classes.imageContainer} justifyContent="center">
                  <img src={Img2} className={classes.image} alt=""/>
                </Grid>
              </div>
            </SwipeableViews>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <div className={classes.slideIconsContainer}>
                {this.state.slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              </div>
              <Button
                variant="contained"
                onClick={() => this.state.slideIndex === 1 ? this.props.onClose() : this.setState({slideIndex: this.state.slideIndex + 1})}
              >
                {this.state.slideIndex === 1 ? '閉じる' : '次へ'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

FriendEntryTutorialDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(FriendEntryTutorialDialog)
