import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  note: {
    textAlign: 'center',
    color: '#F55083',
  },
  contact: {
    textAlign: 'center',
  },
  actions: {
    paddingTop: theme.spacing(4),
  },
  skip: {
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
}))

export default function SmsAuthSettingRequestDialog (props) {
  const { open, onClick, onClose, canSkip } = props
  const classes = useStyles()

  const title = canSkip ? '電話番号認証の登録が必須になりました' : '電話番号認証が必要です'
  const note =
    props.disableSmsVerification ?
      '＊登録が完了するまで、その他の操作は行えません。' :
      canSkip ?
        '＊2024年10月28日までに登録されない場合、ログインができなくなります。' :
        '＊移行期限を過ぎたため、登録が完了するまで、その他の操作は行えません。'

  return (
    <DialogThemeProvider color={'default'}>
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="body1">
            不正利用を防ぎ、安心安全なご利用のために電話番号のSMS（ショートメッセージ）を利用した認証が
            {canSkip ? <>必須になりました</> : <>必要です</>}。
          </Typography>
          <Typography className={classes.note} variant="body2">
            {note}
          </Typography>
          <Typography className={classes.contact} variant="body2">
            ＊問い合わせ： <a href="mailto:support@aill.ai">support@aill.ai</a>
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button variant="contained" onClick={async () => {
            onClick()
          }}>
            登録画面へ進む
          </Button>
          {canSkip && (
            <Typography className={classes.skip} onClick={onClose}>
              あとで
            </Typography>
          )}
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SmsAuthSettingRequestDialog.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  canSkip: PropTypes.bool,
  disableSmsVerification: PropTypes.bool
}
