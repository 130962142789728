import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Const from 'commons/constant'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import BackgroundImage from 'images/tutorial/img_message_background.png'
import TutorialImage2 from 'images/tutorial/img_message_tutorial2.png'

const useStyles = makeStyles(theme => ({
  tutorialImage: {
    width: '100%',
  },
  imageContainer: {
    position: 'relative',
  },
  messageA: {
    backgroundColor: '#8636D6',
    borderRadius: 21,
    position: 'absolute',
    top: '40%',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '68%',
    height: 34,
  },
  messageAText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: '34px',
  },
  imageA: {
    position: 'absolute',
    top: '42%',
    left: '4%',
    width: 31,
  },
  tutorialTitle: {
    width: '90%',
    position: 'absolute',
    top: 16,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  bar: {
    ...theme.styles.header,
  },
  toolbar: {
    backgroundColor: '#B0B4FC',
    minHeight: theme.styles.header.height,
    position: 'relative',
  },
  title: {
    width: '75%',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  titleText: {
    fontSize: 11,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  backButton: {
    padding: 0,
    position: 'absolute',
    left: 16,
    color: theme.palette.secondary.main,
  },
  ex1container: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: 80,
    marginTop: theme.spacing(2),
    textAlign: 'center',
    position: 'relative',
  },
  ex2container: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: 80,
    textAlign: 'center',
    position: 'relative',
  },
  avatarContainer: {
    paddingLeft: theme.spacing(2.375),
    height: 32,
  },
  faceAvatar: {
    marginRight: theme.spacing(1.375),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(1),
  },
  speachContainer: {
    paddingLeft: theme.spacing(2.375),
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 256,
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  speachUn: {
    ...theme.styles.naviSpeach(Const.colors.devilBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 256,
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  speach: {
    fontSize: 12,
  },
  exampleTitle: {
    color: '#006EDF',
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 8,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  exampleText: {
    width: '100%',
    padding: theme.spacing(2),
    borderTop: '1px solid #ABCDFD',
    borderBottom: '1px solid #ABCDFD',
  },
  tutorial2Container: {
    marginTop: theme.spacing(4),
    width: '100%',
    maxWidth: 400,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
}))

export default function SelfIntroductionTutorial (props) {
  const classes = useStyles()
  const { closeTutorial } = props
  let elementKey = 0

  const nextKey = () => {
    elementKey += 1
    return elementKey
  }

  const createChatMessageElements = (msg, color, fontSize) => {
    let allElems = []
    let lines = msg.split(/\r\n|\r|\n/)
    for (let line of lines) {
      let style = { wordBreak: 'break-all', color: color }
      let elems = [<Typography className={fontSize} style={style} key={nextKey()}>{line}</Typography>]
      for (let elem of elems) {
        allElems.push(elem)
      }
    }
    return allElems
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <AppBar position="fixed" className={classes.bar}>
        <Toolbar className={classes.toolbar}>
          <IconButton aria-label="add" className={classes.backButton} onClick={closeTutorial} >
            <LeftIcon />
          </IconButton>
          <Grid container justifyContent="center" alignItems="center" className={classes.title}>
            <img src={FootAImage} width={22} alt="" />
            <Typography className={classes.titleText}>
              カグラとサカキのちょっとアドバイス
            </Typography>
            <img src={FootUnImage} width={22} alt="" />
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.ex1container}>
        <Grid container justifyContent="flex-start" alignItems="flex-end" className={classes.avatarContainer}>
          <img src={UnImage} className={classes.faceAvatar} alt="" />
          <div className={classes.speachUn}>
            {createChatMessageElements('好印象な自己紹介例を見てみよう', Const.colors.devil, classes.speach)}
          </div>
        </Grid>
        <Typography className={classes.exampleTitle}>
          自己紹介の例１
        </Typography>
      </div>
      <Typography className={classes.exampleText}>
        はじめまして、タクヤです。<br />
        東京・丸の内で、エンジニアをやっています。<br />
        休日は近くの河原沿いをランニングしたり、映画鑑賞やお気に入りのカフェなどで過ごしています。
        ランニング以外にもテニスやフットサルなども好きで、年に１回はフルマラソンを走るようにしています。
        自然に触れ合うのが大好きで、よく地方の温泉などに旅行に出かけています。
      </Typography>
      <Grid container justifyContent="flex-start" alignItems="flex-end" className={classes.speachContainer}>
        <img src={UnImage} className={classes.faceAvatar} alt="" />
        <div className={classes.speachUn}>
          {createChatMessageElements('仕事の話だけじゃなく、趣味の話や、\nどんなライフスタイルかに触れているね。', Const.colors.devil, classes.speach)}
        </div>
      </Grid>
      <div className={classes.ex2container}>
        <Typography className={classes.exampleTitle}>
          自己紹介の例２
        </Typography>
      </div>
      <Typography className={classes.exampleText}>
        こんにちは、タクヤです。<br />
        大阪でマーケティングプランナーをやっています。<br />
        美味しいものを食べるのが大好きです。平日は仕事終わりに隠れ家的な美味しいスポット探したり、
        週末は京都・奈良方面へドライブがてら名店巡りをするのが趣味です。
        もちろん、食べるだけでなく作ることも大好きで、得意分野はイタリアンです。
      </Typography>
      <Grid container justifyContent="flex-start" alignItems="flex-end" className={classes.speachContainer}>
        <img src={UnImage} className={classes.faceAvatar} alt="" />
        <div className={classes.speachUn}>
          {createChatMessageElements('どんな性格だと友人や同僚から言われているか書いてあるとわかりやすいかも。', Const.colors.devil, classes.speach)}
        </div>
      </Grid>
      <Grid container justifyContent="flex-start" alignItems="flex-end" className={classes.speachContainer}>
        <img src={AImage} className={classes.faceAvatar} alt="" />
        <div className={classes.speachA}>
          {createChatMessageElements('他の人の良い自己紹介を見て、\n良いところを真似してみよう！', Const.colors.angel, classes.speach)}
        </div>
      </Grid>
      <Grid container justifyContent="flex-start" alignItems="flex-end" className={classes.speachContainer}>
        <img src={UnImage} className={classes.faceAvatar} alt="" />
        <div className={classes.speachUn}>
          {createChatMessageElements('良い自己紹介のポイントを抑えることオススメ。友達になる確率上がるよ。', Const.colors.devil, classes.speach)}
        </div>
      </Grid>
      <div className={classes.tutorial2Container}>
        <div className={classes.imageContainer}>
          <img src={TutorialImage2} className={classes.tutorialImage} alt="" />
          <Grid container justifyContent="center" alignItems="center" className={classes.tutorialTitle}>
            <img src={FootAImage} width={22} alt="" />
            <Typography className={classes.titleText}>
              カグラとサカキのちょっとアドバイス
            </Typography>
            <img src={FootUnImage} width={22} alt="" />
          </Grid>
          <div className={classes.messageA}>
            <Typography className={classes.messageAText}>
              自分らしさを伝える自己紹介とは？
            </Typography>
          </div>
          <img src={AImage} className={classes.imageA} alt="" />
        </div>
      </div>
    </Grid>
  )
}

SelfIntroductionTutorial.propTypes = {
  closeTutorial: PropTypes.func,
}
