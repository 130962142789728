import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BaseComponent from 'components/parts/BaseComponent'
import GalleryPage from 'components/photo-tutorial/GalleryPage'
import PhotoTutorialMainDialog from 'components/photo-tutorial/PhotoTutorialMainDialog'
import PhotoTutorialNomalDialog from 'components/photo-tutorial/PhotoTutorialNomalDialog'
import TutorialPage from 'components/photo-tutorial/TutorialPage'

export default function PhotoTutorialDialog (props) {
  const { open, type, ...others } = props
  const [tutorial, setTutorial] = useState(false)
  const [tutorialIsBad, setTutorialIsBad] = useState(false)
  const [gallery, setGallery] = useState(false)

  useEffect(() => {
    if (!open) { return }
    if (type !== 'dialog' && type !== 'tutorial') {
      BaseComponent.gaModalView('photo')
    }
  }, [open])

  const clickTutorial = () => {
    setTutorial(true)
    setTutorialIsBad(false)
  }

  const clickTutorialBad = () => {
    setTutorial(true)
    setTutorialIsBad(true)
  }

  const clickGalleryTutorial = () => {
    setTutorial(false)
    setTutorialIsBad(false)
    setGallery(true)
  }

  const clickBack = () => {
    setTutorial(false)
    setGallery(false)
  }

  const clickCancel = () => {
    props.onClose()
  }

  return (
    <>
      {(type === 'dialog' || type === 'tutorial') ? (
        <PhotoTutorialNomalDialog
          open={open}
          clickTutorial={clickTutorial}
          clickTutorialBad={clickTutorialBad}
          clickGalleryTutorial={clickGalleryTutorial}
          clickCancel={clickCancel}
          {...props}
          {...others}
        />
      ) : (
        <PhotoTutorialMainDialog
          open={open}
          clickTutorial={clickTutorial}
          clickTutorialBad={clickTutorialBad}
          clickCancel={clickCancel}
          {...props}
          {...others}
        />
      )}
      <TutorialPage open={tutorial} clickBack={clickBack} tutorialIsBad={tutorialIsBad} />
      <GalleryPage open={gallery} clickBack={clickBack} />
    </>
  )
}

PhotoTutorialDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}