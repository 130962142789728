import React from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#616AFA',
    zIndex: 1,
    borderRadius: '50%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 2px rgb(0 0 0 / 30%)',
    cursor: 'pointer',
  },
  iconButton: {
    color: '#ffffff'
  },
  icon: {
    width: 20,
    height: 20,
    transform: 'rotate(90deg)',
    marginTop: theme.spacing(1),
  },
}))

export default function ScrollTopButton () {
  const classes = useStyles()

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Box
      className={classes.root}
      sx={{
        width: { xs: 48, md: 60 },
        height: { xs: 48, md: 60 },
        right: { xs: 20, md: 40 },
        bottom: { xs: 90, md: 40 },
      }}
    >
      <IconButton className={classes.iconButton} onClick={scrollTop}>
        <ArrowBackIosIcon className={classes.icon} />
      </IconButton>
    </Box>
  )
}
