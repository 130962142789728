import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.styles.header.height,
  },
  container: {
    marginTop: theme.spacing(4),
  },
  inquiryText: {
    marginTop: theme.spacing(2),
  },
  inquiryRemarks: {
    marginTop: theme.spacing(2),
    opacity: 0.54,
  },
  mailAddress: {
    fontSize: 16,
    textDecoration: 'underline',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3.33)
  },
})

export class Inquiry extends BaseComponent {
  componentDidMount () {
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  render () {
    const { classes, ...other } = this.props

    return (
      <div>
        <div className={classes.root}>
          <AppHeader title="お問い合わせ" backWhite={true} {...other} />
          <div className={classes.container}>
            <Typography>
              お問い合わせ先
            </Typography>
            <Typography className={classes.mailAddress}>
              <a href="mailto:support@aill.ai?subject=問い合わせ">
                support@aill.ai
              </a>
            </Typography>
            <Typography className={classes.inquiryText}>
              3営業日ほど返信にお時間をいただいています。ご了承ください。
            </Typography>
            <Typography className={classes.inquiryRemarks}>
              ※機能やサービス改善については「ご意見・ご要望」からお願いいたします。
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Inquiry)
