import React from 'react'
import ja from 'date-fns/locale/ja'
import DatePicker, { registerLocale } from 'react-datepicker'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import 'react-datepicker/dist/react-datepicker.css'
import Api from 'commons/api'
import { getMediumLabel, formatJapaneseDateMD } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import PartnerPage from 'components/parts/PartnerPage'

registerLocale('ja', ja)

const styles = theme => ({
  table: {
    wordBreak: 'break-all',
    wordWrap: 'break-all',
    marginBottom: theme.spacing(3),
  },
  tableHead: {
    ...theme.styles.creditTableColor,
  },
  tableCell: {
    fontSize: 10,
  },
  container: {
    marginBottom: theme.spacing(3),
  },
  download: {
    fontSize: 10,
    fontWeight: 'bold',
    width: theme.spacing(14),
    height: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  message: {
    marginTop: theme.spacing(2),
    ...theme.styles.redHighlightColor,
    fontSize: 12,
  },
  formControl: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
    width: '30%',
    maxWidth: theme.spacing(30),
    minWidth: theme.spacing(25),
  },
  picker: {
    fontSize: 16,
    width: theme.spacing(10),
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderStyle: 'groove',
  },
  period: {
    marginLeft: theme.spacing(2),
  }
})

class PartnerResults extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this._isMounted = false
    this.state = {
      startDate: null,
      endDate: null,
      month: null,
      lastMonth: null,
      partnerResults: [],
      entry_total: 0,
      withdrawal_total: 0,
      user_total: 0,
      partnerId: this.props.user ? this.props.user.partner_id : '',
      partnerIdError: false,
      organizations: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()
    this.getOrganizations()

    if (this.props.admin) {
      this.loadPartners()
    } else {
      let today = new Date()
      let lastMonth = new Date(today.getFullYear(), today.getMonth()-1, today.getDate())
      this.loadPartnerResults(this.state.partnerId, lastMonth, lastMonth)
      this.setStateIfMounted({
        startDate: lastMonth,
        endDate: lastMonth,
        lastMonth: lastMonth,
      })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  async getOrganizations () {
    try {
      let organizations = await Api.getOrganizations()
      this.setStateIfMounted({ organizations: organizations })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async loadPartnerResults (id, startDate, endDate) {
    this.props.setLoading(true)
    try {
      let start_year = startDate.getFullYear()
      let start_month = startDate.getMonth() + 1
      let end_year = endDate.getFullYear()
      let end_month = endDate.getMonth() + 1
      let params = `?partner_id=${id}&`
      params += `start_year=${start_year}&`
      params += `start_month=${start_month}&`
      params += `end_year=${end_year}&`
      params += `end_month=${end_month}`
      let partnerResults = await Api.getPartnerResults(params)
      // 並べ替え（年、月、メディア、キャンペーン）
      partnerResults.sort(function (a, b) {
        if (a.year > b.year) return 1
        if (a.year < b.year) return -1
        if (a.month > b.month) return 1
        if (a.month < b.month) return -1
        if (a.medium < b.medium) return 1
        if (a.medium > b.medium) return -1
        if (a.campaign < b.campaign) return 1
        if (a.campaign > b.campaign) return -1
        return 0
      })
      // 集計
      let entry_total = 0
      let withdrawal_total = 0
      let user_total = 0
      partnerResults.forEach(result => {
        entry_total += result.entry_count
        withdrawal_total += result.withdrawal_count
        user_total += result.user_count
      })
      this.setStateIfMounted({
        partnerResults: partnerResults,
        entry_total: entry_total,
        withdrawal_total: withdrawal_total,
        user_total: user_total,
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleChange (event) {
    this.setStateIfMounted({
      [event.target.name]: event.target.value,
      [event.target.name + 'Error']: false,
    })
    if (event.target.name === 'partnerId') {
      if (event.target.value.length > 0) {
        let today = new Date()
        let lastMonth = new Date(today.getFullYear(), today.getMonth()-1, today.getDate())
        this.loadPartnerResults(event.target.value, lastMonth, lastMonth)
        this.setStateIfMounted({
          startDate: lastMonth,
          endDate: lastMonth,
          lastMonth: lastMonth,
        })
      } else {
        this.setStateIfMounted({
          month: null,
          lastMonth: null,
          partnerResults: [],
        })
      }
    }
  }

  handleStartDateChange (date) {
    let startDate = new Date(date)
    this.loadPartnerResults(this.state.partnerId, startDate, this.state.endDate)
    this.setStateIfMounted({
      startDate: date
    })
  }

  handleEndDateChange (date) {
    let endDate = new Date(date)
    this.loadPartnerResults(this.state.partnerId, this.state.startDate, endDate)
    this.setStateIfMounted({
      endDate: date
    })
  }

  async handleDownload () {
    let bom = new Uint8Array([0xEF, 0xBB, 0xBF])
    let pid = this.state.partnerResults[0].partner_id
    let source = pid.replace('.', '_')
    let year = this.state.startDate.getFullYear()
    let month = this.state.startDate.getMonth() + 1
    let fileName = source + '_' + year + ('00' + month).slice(-2) + '.csv'

    // 集計データ
    let dataCsv = ''
    let table = document.getElementById('result_table')
    for (let i = 0;  i < table.rows.length; i++) {
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        dataCsv += table.rows[i].cells[j].innerText
        if (j === table.rows[i].cells.length - 1) {
          dataCsv += '\n'
        } else {
          dataCsv += ','
        }
      }
    }
    dataCsv += '\n'
    // 明細ヘッダー
    let headerDetails = ['年月', 'メディア', 'キャンペーン', 'ID', '貴社会員ID','ステータス', '会員種別', '入会日', '卒業・退会日', '年齢', '性別', '企業']
    for (let i = 0; i < headerDetails.length; i++) {
      dataCsv += headerDetails[i]
      if (i === headerDetails.length - 1) {
        dataCsv += '\n'
      } else {
        dataCsv += ','
      }
    }
    // 明細データ
    this.state.partnerResults.forEach(result => {
      let ym = this.formatYearMonth(result.year, result.month)
      let medium = getMediumLabel(result.medium)
      let campaign = result.campaign === 'none' ? '' : result.campaign
      let details = result.details
      // 並べ替え（ステータス）
      details.sort(function (a, b) {
        if (a.status === b.status) {
          if (a.status === 'withdrawal') {
            if (a.withdrawal_at > b.withdrawal_at) return 1
            if (a.withdrawal_at < b.withdrawal_at) return -1
          } else {
            if (a.entry_at > b.entry_at) return 1
            if (a.entry_at < b.entry_at) return -1
          }
        } else {
          if (a.status === 'entry') return -1
          if (b.status === 'entry') return 1
          if (a.status === 'withdrawal') return -1
          if (b.status === 'withdrawal') return 1
        }
        return 0
      })
      details.forEach(detail => {
        let seq_number = detail.seq_number
        let welfare_account_id = detail.welfare_account_id ? detail.welfare_account_id : ''
        let status = this.getStatusName(detail.status)
        let membership_type = this.getMembershipTypeName(detail.membership_type)
        let entry_at = detail.entry_at ? formatJapaneseDateMD(detail.entry_at) : ''
        let withdrawal_at = detail.withdrawal_at ? formatJapaneseDateMD(detail.withdrawal_at) : ''
        let age = detail.age
        let sex = detail.sex === 'male' ? '男' : '女'
        let orgName = this.getOrganizationName(detail.organization_id)
        let rec = [
          ym, medium, campaign, seq_number, welfare_account_id, status,
          membership_type,entry_at, withdrawal_at, age, sex, orgName
        ]
        for (let i = 0; i < rec.length; i++) {
          dataCsv += rec[i]
          if (i === rec.length - 1) {
            dataCsv += '\n'
          } else {
            dataCsv += ','
          }
        }
      })
    })

    let blob = new Blob([bom, dataCsv], {'type' : 'text/csv'})
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName)
    } else {
      var urlUtil = window.URL || window.webkitURL
      var csvUrl = urlUtil.createObjectURL(blob)
      var link = document.createElement('a')
      link.href = csvUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  formatYearMonth (year, month) {
    return `${year}年${month}月`
  }

  getOrganizationName (orgId) {
    let orgs = this.state.organizations
    let org = orgs.filter(org => {
      if (org.id === orgId) { return true }
      return false
    })
    if (org[0]) {
      return org[0].canonical_name
    }
    return ''
  }

  getStatusName (status) {
    switch (status) {
      case 'entry':
        return '入会'
      case 'withdrawal':
        return '退会'
      default:
        return '利用中'
    }
  }

  getMembershipTypeName (membership_type) {
    switch (membership_type) {
      case 'paid':
        return '有料'
      default:
        return '無料'
    }
  }

  createPartnerResultsTable () {
    const { classes } = this.props
    return (
      <Table id="result_table" className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">年月</TableCell>
            <TableCell className={classes.tableCell} align="left">メディア</TableCell>
            <TableCell className={classes.tableCell} align="left">キャンペーン</TableCell>
            <TableCell className={classes.tableCell} align="right">入会者数</TableCell>
            <TableCell className={classes.tableCell} align="right">卒業・退会者数</TableCell>
            <TableCell className={classes.tableCell} align="right">利用者数（入会者数含む）</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.partnerResults.map((result) => (
            <TableRow key={result.id}>
              <TableCell className={classes.tableCell} align="center">
                {this.formatYearMonth(result.year, result.month)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {getMediumLabel(result.medium)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {result.campaign === 'none' ? '' : result.campaign}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {result.entry_count}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {result.withdrawal_count}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {result.user_count}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              合計
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              {this.state.entry_total}
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              {this.state.withdrawal_total}
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              {this.state.user_total}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <PartnerPage title="入会実績" {...others}>
        {
          this.props.admin &&
          this.createPartnerSelect()
        }
        {
          this.state.partnerId.length > 0 &&
          <Grid container
            direction='row'
            alignItems="flex-end"
            className={classes.container}
          >
            <Typography>
              抽出期間
            </Typography>
            <DatePicker
              selectsStart
              selected={this.state.startDate}
              maxDate={this.state.endDate}
              onChange={date => this.handleStartDateChange(date)}
              dateFormat="yyyy/MM"
              showMonthYearPicker
              className={classes.picker}
              locale='ja'
            />
            <Typography className={classes.period}>
              〜
            </Typography>
            <DatePicker
              selectsEnd
              selected={this.state.endDate}
              minDate={this.state.startDate}
              maxDate={this.state.lastMonth}
              onChange={date => this.handleEndDateChange(date)}
              dateFormat="yyyy/MM"
              showMonthYearPicker
              className={classes.picker}
              locale='ja'
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.download}
              disabled={this.state.partnerResults.length === 0}
              onClick={this.handleDownload}
            >
              CSVダウンロード
            </Button>
            <Typography className={classes.message}>
              ※前月までの実績を確認できます。
            </Typography>
          </Grid>
        }
        {
          this.state.partnerResults.length > 0 ? (
            this.createPartnerResultsTable()
          ) : (
            this.state.startDate &&
            <Typography>
              入会実績がありません。
            </Typography>
          )
        }
      </PartnerPage>
    )
  }
}

export default withStyles(styles)(PartnerResults)
