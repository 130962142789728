import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'

const useStyles = makeStyles(theme => ({
  entryRoot: {
    fontSize: 16,
    fontWeight: 600,
    color: '#707070',
    width: '95%',
    maxWidth: 664,
    border: 'solid 2px #423BC7',
    borderRadius: 5,
    position: 'relative',
    marginTop: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2.5),
    '&:before': {
      content: '"お友達紹介キャンペーン"',
      fontSize: 18,
      fontWeight: 600,
      color: '#423BC7',
      textAlign: 'center',
      width: 250,
      background: '#ffffff',
      display: 'inline-block',
      position: 'absolute',
      top: -15,
      left: '50%',
      transform: 'translateX(-50%)',
    }
  },
  campaignTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#1A1667',
  },
  membersRoot: {
    width: '100%',
    minHeight: 112,
    border: '1px solid #707070',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
  },
  entryText: {
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  membersCampaignText: {
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    color: '#1A1667',
  },
  blue: {
    color: '#423BC7',
  },
  notice: {
    fontSize: 12,
    fontWeight: 300,
  },
  note: {
    fontSize: 11.5,
    margin: 0,
    paddingLeft: theme.spacing(2),
    listStyle: '"＊"'
  },
}))

const EntrySite = Config.deployMode === 'entry'

export default function InvitationCampaignBox (props) {
  const { scroll } = props
  const classes = useStyles()

  if (EntrySite) {
    return (
      <Box className={classes.entryRoot} sx={{ px: { xs: 4, sm: 8 } }}>
        <Typography className={classes.entryText}>
          お友達1人紹介ごとに14日間「スタンダードプラン」をご利用いただけます！
        </Typography>
        <Typography className={classes.notice} component="div">
          <ul className={classes.note}>
            <li>詳しくはアプリ内、下部メニューの「キャンペーン」タブをタップしてご確認ください。</li>
            <li>ご入会後、いつでもご利用いただけます。</li>
            <li>一部の地域はキャンペーン対象外となりますのでご了承ください。</li>
          </ul>
        </Typography>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <Typography className={classes.campaignTitle}>お友達紹介キャンペーン</Typography>
      <Box className={classes.membersRoot} sx={{ px: { xs: 4, sm: 8 } }}>
        <Typography className={classes.membersCampaignText}>
          <u onClick={() => scroll('invitationCampaignRef')}>お友達1人紹介ごとに14日間「スタンダードプラン」をご利用いただけます！</u>
        </Typography>
      </Box>
    </React.Fragment>
  )
}

InvitationCampaignBox.propTypes = {
  scroll: PropTypes.func,
}
