import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import PartnerPage from 'components/parts/PartnerPage'

const styles = theme => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    ...theme.styles.signupTextField,
    marginBottom: theme.spacing(2),
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    borderRadius: theme.spacing(10),
    border: '1px solid',
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    flexBasis: 'auto',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  message: theme.styles.signupDescription,
  messageContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
})

export class PartnerPasswordChange extends BaseComponent {
  constructor (props) {
    super(props)

    this.changePassword = this.changePassword.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.onChangeCurrentPassword = this.onChangeCurrentPassword.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this)
    this.clickCancel = this.clickCancel.bind(this)

    this._isMounted = false

    this.state = {
      isError: false,
      currentPasswordError: '',
      passwordError: null,
      passwordConfirmError: null,
    }
  }

  componentWillMount () {
    if (this.props.authId == null) {
      this.props.setScreen('/')
    }
    if (isEmpty(this.props.mailAddress)) {
      this.props.setScreen('/')
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onChangeCurrentPassword () {
    this.setState({ currentPasswordError: null })
  }

  onChangePassword () {
    this.setState({ passwordError: null })
  }

  onChangePasswordConfirm () {
    this.setState({ passwordConfirmError: null })
  }

  dialogClose () {
    this.setState({ isError: false })
  }

  resetError () {
    this.setState({
      currentPasswordError: null,
      passwordError: null,
      passwordConfirmError: null,
    })
  }

  async changePassword () {
    this.resetError()

    let currentPassword = document.getElementById('currentPassword')
    let password = document.getElementById('password')
    let passwordConf = document.getElementById('passwordConf')
    let errorStates = {}

    // 現在のパスワードのチェック
    if (isEmpty(currentPassword.value)) {
      errorStates.currentPasswordError = '現在のパスワードを入力してください。'
    }

    // 新しいパスワードのチェック
    if (isEmpty(password.value)) {
      errorStates.passwordError = '新しいパスワードを入力してください。'
    } else if (!isUsableCharacters(password.value)) {
      errorStates.passwordError = '使用できない文字が含まれています。'
    } else if (password.value.length < 8) {
      errorStates.passwordError = '最小文字長は8文字です。'
    } else if (password.value.length > 32) {
      errorStates.passwordError = '最大文字長は32文字です。'
    }

    // 新しいパスワード（確認用）のチェック
    if (isEmpty(passwordConf.value)) {
      errorStates.passwordConfirmError = '新しいパスワード（確認）を入力してください。'
    } else if (!isUsableCharacters(passwordConf.value)) {
      errorStates.passwordConfirmError = '使用できない文字が含まれています。'
    } else if (passwordConf.value.length < 8) {
      errorStates.passwordConfirmError = '最小文字長は8文字です。'
    } else if (passwordConf.value.length > 32) {
      errorStates.passwordConfirmError = '最大文字長は32文字です。'
    }

    // パスワード確認
    if (password.value !== passwordConf.value) {
      errorStates.passwordConfirmError = '新しいパスワードが一致していません。'
    }

    if (0 < Object.keys(errorStates).length) {
      this.setState(errorStates)
      return
    }

    // ローディング表示
    this.props.setLoading(true)

    // パスワード変更
    try {
      let auth = await Api.changePassword(this.props.authId, currentPassword.value, password.value)
      this.props.setAuthId(auth.id)
      this.props.setPassword(password)
      this.props.setMailAddress(auth.mail_address)
      this.props.setScreen('PartnerHome')
    } catch (error) {
      this.setStateIfMounted({ isError: true })
      if (error.response && error.response.status === 409) {
        this.setStateIfMounted({ currentPasswordError: '現在のパスワードが一致していません。' })
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  clickCancel () {
    this.props.setScreen('PartnerHome')
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes"
          title="パスワード登録"
          message="パスワード登録できませんでした。"
        />
        <PartnerPage title="パスワード変更" {...others}>
          <Typography className={classes.label}>
            現在のパスワード
          </Typography>
          <TextField
            id="currentPassword"
            type="password"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.currentPasswordError)}
            placeholder="パスワード"
            helperText={isEmpty(this.state.currentPasswordError) ? '' : this.state.currentPasswordError}
            className={classes.textField}
            color="secondary"
            onChange={this.onChangeCurrentPassword}
          />
          <Typography className={classes.label}>
            新しいパスワード
          </Typography>
          <TextField
            id="password"
            type="password"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.passwordError)}
            placeholder="パスワード"
            helperText={isEmpty(this.state.passwordError) ? '' : this.state.passwordError}
            className={classes.textField}
            color="secondary"
            onChange={this.onChangePassword}
          />
          <Typography className={classes.label}>
            新しいパスワード（確認）
          </Typography>
          <TextField
            id="passwordConf"
            type="password"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.passwordConfirmError)}
            placeholder="パスワード"
            helperText={isEmpty(this.state.passwordConfirmError) ? '' : this.state.passwordConfirmError}
            className={classes.textField}
            color="secondary"
            onChange={this.onChangePasswordConfirm}
          />
          <Grid container direction="column" alignItems="center" justifyContent="space-around" className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.entry}
              onClick={this.changePassword}
            >
              送信
            </Button>
            <Button
              variant="text"
              color="secondary"
              className={classes.cancel}
              onClick={this.clickCancel}
            >
              キャンセル
            </Button>
          </Grid>
        </PartnerPage>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(PartnerPasswordChange)
