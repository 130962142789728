import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Config from 'commons/config'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { calculateAge } from 'commons/utility'
import NaviPopper from 'components/first-navigation/NaviPopper'
import BaseComponent from 'components/parts/BaseComponent'
import FriendCell from 'components/parts/FriendCell'
import { males, females } from 'components/photo-tutorial/sample_friends'
import photoIssueDialogImg from 'images/tutorial/photoIssueDialogImg.png'

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '24px 24px 0 0',
  },
  title: {
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: 0,
    paddingLeft: theme.spacing(2.5),
  },
  backButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(10),
    paddingLeft: 0,
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
  cell: {
    borderStyle: 'none',
    padding: 0,
    paddingBottom: theme.spacing(1.5),
  },
  button: {
    width: 230,
    minHeight: 44,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ffffff',
    background: '#423BC7',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      color: '#ffffff',
      background: '#423BC7',
    },
  },
  contentButton: {
    marginTop: theme.spacing(3),
  },
  popperButton: {
    marginTop: theme.spacing(0.5),
  },
}))

export default function PhotoIssueDialog (props) {
  const classes = useStyles()
  const { open, onClose, user, type, message, ...other } = props
  const [openNavi, setOpenNavi] = useState(false)
  // ポップアップ表示対象Element
  const targetRef = useCallback(el => { if (el) { setTargetEl(el) } })
  const [targetEl, setTargetEl] = useState(null)
  // Dialog内コンテンツスクロール検知用Element
  const dialogContentRef = useCallback(el => { if (el) { setDialogContentEl(el) } })
  const [dialogContentEl, setDialogContentEl] = useState(null)
  const [sampleFriends, setSampleFriends] = useState([])
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  // 異性から見たいいね！一覧画面での見え方フラグ
  const friendRequestPage = type === 'FriendRequest'

  // DialogContentのスクロール検知 スクロールされたらナビPoppperをclose
  useEffect(() => {
    if (dialogContentEl) {
      dialogContentEl.addEventListener('scroll', () => setOpenNavi(false))
      return () => {
        dialogContentEl.removeEventListener('scroll', () => setOpenNavi(false))
      }
    }
  }, [dialogContentEl])

  // Dialog本体が表示されるたびに自分のデータを挿入
  // ナビPoppper: /Chatのみ
  useEffect(() => {
    if (open) {
      BaseComponent.gaModalView('photoissuepopup')
      if (window.location.pathname === '/Chat') { setOpenNavi(true) }
      if (males.length !== 5) {
        const addParams = {
          age: user.age || calculateAge(user.birthday),
          matching: 88,
          entry_request_message: message,
          type: 'friend_receiving',
        }
        const samples = user.sex === 'male' ? males : females
        setSampleFriends([Object.assign(user, addParams), ...samples])
      }
    }
  }, [open])

  const createButton = () => {
    return (
      <Button
        className={`${classes.button} ${classes.contentButton}`}
        variant="contained"
        onClick={() => {
          if (friendRequestPage) {
            setOpenConfirmDialog(true)
          } else {
            const url = Config.serviceSiteUrl + 'photo-tutorial.html'
            window.open(url)
          }
        }}
      >
        {friendRequestPage ? 'プロフィール編集に進む' : '良い写真の撮り方を見る'}
      </Button>
    )
  }

  return (
    <>
      <Dialog
        open={open}
        fullScreen
        PaperProps={{ className: classes.paper }}
      >
        <DialogTitle className={classes.title}>
          <Grid container justifyContent="flex-end">
            <IconButton className={classes.backButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Typography className={classes.titleText}>
            異性から見た{friendRequestPage ? 'いいね！一覧画面です' : 'プロフィール画面だよ'}<br />
            見え方を確認しよう！
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content} ref={dialogContentRef}>
          <Table className={classes.table}>
            <tbody>
              {sampleFriends.map((value, index) => (
                <FriendCell
                  key={index}
                  {...other}
                  // komanaviを表示しないため
                  index={index+1}
                  user={user}
                  friend={value}
                  tutorial
                  displayProfileItems={['address1', 'birthplace', 'how_to_spend_the_holidays', 'working_industry']}
                  friendRequestPage={friendRequestPage}
                />
              ))}
            </tbody>
          </Table>
          {createButton()}
        </DialogContent>
      </Dialog>
      <NaviPopper
        open={(open && openNavi && Boolean(targetEl))}
        anchorEl={targetEl}
        title={'プロフィール写真は\nマッチング率を高める重要情報！'}
        img={photoIssueDialogImg}
        child={createButton()}
        placement={'bottom'}
        onClose={() => setOpenNavi(false)}
        arrow
        canselButton
      />
      <div
        style={{
          position: 'fixed',
          top: '60%',
          left: '50%',
        }}
        ref={targetRef}
      />
      <DialogThemeProvider color="default">
        <Dialog open={openConfirmDialog}>
          <DialogTitle disableTypography>プロフィール編集に<br />進みますか？</DialogTitle>
          <DialogContent>
            <div style={{ fontSize: 13, marginBottom: 12, textAlign: 'center' }}>
              入力済みのメッセージは<br />
              保存されています。
            </div>
            <div style={{ fontSize: 11, fontWeight: 300, color: '#F55083', textAlign: 'center' }}>
              ＊紹介されてから7日が経過すると<br />
              いいね！送信ができなくなりますので<br />
              ご注意ください。
            </div>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={() => {
              setOpenConfirmDialog(false)
              props.setScreen('ProfileEdit')
            }}>
              プロフィール編集に進む
            </Button>
            <Button variant="text" onClick={() => setOpenConfirmDialog(false)}>キャンセル</Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

PhotoIssueDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  setScreen: PropTypes.func,
}
