import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import ChatMemoDemoDialog from 'components/parts/ChatMemoDemoDialog'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import KomainuAdvice from 'components/parts/KomainuAdvice'
import FootPrintBlue from 'images/Komainu/footprint_blue.png'
import FootPrintPink from 'images/Komainu/footprint_pink.png'
import FootPrintPurple from 'images/Komainu/footprint_purple.png'
import KaguraSakaki from 'images/Komainu/komainu.png'

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(3),

  },
  text: {
    marginTop: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  pinkButton: {
    height: 34,
    fontSize: 12,
    fontWeight: 400,
    color: '#3C0077',
    background: '#D6BCEF !important',
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  blueButton: {
    height: 34,
    fontSize: 12,
    fontWeight: 400,
    color: '#060179',
    background: '#ABCDFD !important',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}))

export default function ChatMemoTutorialDialog (props) {
  const { open, onClose, user, ...other } = props
  const classes = useStyles()
  const [openDemoDialog, setOpenDemoDialog] = useState(false)
  const [demoType, setDemoType] = useState(0)

  const handleClickDemo = (type) => {
    setDemoType(type)
    setOpenDemoDialog(true)
  }

  return (
    <>
      <DialogThemeProvider color="secondary">
        <Dialog open={open}>
          <DialogCloseButton onClick={onClose} />
          <DialogTitle disableTypography>
            お相手メモについて
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.subtitle} variant="subtitle1">大切な情報を保存できます</Typography>
            <Typography variant="body1">1.お相手から届いたメッセージ</Typography>
            <Typography variant="body1">2.デート等で聞いたお相手情報</Typography>
            <KomainuAdvice
              text={'忙しい日々でも安心だね'}
              img01={KaguraSakaki}
            />
            <Grid container justifyContent="center" alignItems="baseline">
              <img src={FootPrintPink} width={18} height={18} alt="" />
              <Typography className={classes.text} variant="body1">お相手メモを動画で確認</Typography>
              <img src={FootPrintBlue} width={18} height={18} alt="" />
            </Grid>
            <Button className={classes.pinkButton} variant="contained" onClick={(event) => handleClickDemo(2, event)}>
              メッセージの保存方法を見る
            </Button>
            <Button className={classes.blueButton} variant="contained" onClick={(event) => handleClickDemo(1, event)}>
              メモの登録方法を見る
            </Button>
          </DialogContent>
          <img
            src={FootPrintPurple}
            width={18}
            height={18}
            style={{ position: 'absolute', bottom: 64, left: 10 }}
            alt=""
          />
          <img
            src={FootPrintPurple}
            width={18}
            height={18}
            style={{ position: 'absolute', bottom: 58, right: 8 }}
            alt=""
          />
        </Dialog>
      </DialogThemeProvider>
      <ChatMemoDemoDialog
        open={openDemoDialog}
        onDemoClose={() => setOpenDemoDialog(false)}
        type={demoType}
        user={user}
        {...other}
      />
    </>
  )
}

ChatMemoTutorialDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object,
}
