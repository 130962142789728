import React from 'react'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Api from 'commons/api'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.styles.header.height,
  },
  container: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  label: {
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    fontWeight: 'bold',
  },
  fullTextField: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    border: '1px solid #999999',
    width: '100%',
    height: theme.spacing(5.5),
  },
  errorMessage: {
    height: theme.spacing(5.5),
    marginBottom: theme.spacing(1.33),
  },
  cancelButton: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
  fixedBotton: {
    bottom: theme.spacing(4.375),
    position: 'fixed',
  },
  save: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginBottom: theme.spacing(1),
  },
})

export class MyPassword extends BaseComponent {
  constructor (props) {
    super(props)
    this.changePassword = this.changePassword.bind(this)
    this.onChangeCurrentPassword = this.onChangeCurrentPassword.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.clickCancel = this.clickCancel.bind(this)
    this.onFocusTextField = this.onFocusTextField.bind(this)
    this.onBlurTextField = this.onBlurTextField.bind(this)

    this._isMounted = false

    this.state = {
      isError: false,
      currentPassword: null,
      password: null,
      passwordConfirm: '',
      currentPasswordError: '',
      passwordError: '',
      passwordConfirmError: null,
      appearSoftKeyboard: false,
      focusPassword: false,
      focusPasswordConf: false,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  dialogClose () {
    this.setState({isError: false})
  }

  onChangeCurrentPassword () {
    this.setState({ currentPasswordError: null })
  }

  onChangePassword () {
    this.setState({ passwordError: null })
  }

  onChangePasswordConfirm () {
    this.setState({ passwordConfirmError: null })
  }

  async changePassword () {
    this.setState({
      passwordError: null,
      passwordConfirmError: null,
    })

    let currentPassword = document.getElementById('currentPassword')
    let password = document.getElementById('password')
    let passwordConf = document.getElementById('passwordConf')
    let errorStates = {}

    // 現在のパスワードのチェック
    if (isEmpty(currentPassword.value)) {
      errorStates.currentPasswordError = '現在のパスワードを入力してください。'
    }

    // 新しいパスワードのチェック
    if (isEmpty(password.value)) {
      errorStates.passwordError = '新しいパスワードを入力してください。'
    } else if (!isUsableCharacters(password.value)) {
      errorStates.passwordError = '使用できない文字が含まれています。'
    } else if (password.value.length < 8) {
      errorStates.passwordError = '最小文字長は8文字です。'
    } else if (password.value.length > 32) {
      errorStates.passwordError = '最大文字長は32文字です。'
    }

    // 新しいパスワード（確認用）のチェック
    if (isEmpty(passwordConf.value)) {
      errorStates.passwordConfirmError = '新しいパスワード（確認）を入力してください。'
    } else if (!isUsableCharacters(passwordConf.value)) {
      errorStates.passwordConfirmError = '使用できない文字が含まれています。'
    } else if (passwordConf.value.length < 8) {
      errorStates.passwordConfirmError = '最小文字長は8文字です。'
    } else if (passwordConf.value.length > 32) {
      errorStates.passwordConfirmError = '最大文字長は32文字です。'
    }

    // パスワード確認
    if (password.value !== passwordConf.value) {
      errorStates.passwordConfirmError = '新しいパスワードが一致していません。'
    }

    if (0 < Object.keys(errorStates).length) {
      this.setState(errorStates)
      return
    }

    this.props.setLoading(true)
    try {
      let auth = await Api.changePassword(this.props.authId, currentPassword.value, password.value)
      this.props.setAuthId(auth.id)
      this.props.setPassword(password)
      this.props.setMailAddress(auth.mail_address)
      BaseComponent.goBack(this.props)
    } catch (error) {
      this.setStateIfMounted({ isError: true })
      if (error.response && error.response.status === 409) {
        this.setState({ currentPasswordError: '現在のパスワードが一致していません。' })
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  clickCancel () {
    BaseComponent.goBack(this.props)
  }

  onFocusTextField (event) {
    let innerHeight = window.innerHeight
    let screenHeight = window.screen.height
    if (innerHeight !== screenHeight) {
      this.setState({ appearSoftKeyboard: true })
    }
    if (event.target.id === 'password') {
      this.setState({ focusPassword: true })
    } else {
      this.setState({ focusPasswordConf: true })
    }
    window.setTimeout(() => {
      this.isAppearSoftKeyboard()
    }, 100)
  }

  onBlurTextField (event) {
    if (event.target.id === 'password') {
      this.setState({ focusPassword: false })
    } else {
      this.setState({ focusPasswordConf: false })
    }
    window.setTimeout(() => {
      this.isAppearSoftKeyboard()
    }, 100)
  }

  isAppearSoftKeyboard () {
    if (this.state.focusPassword || this.state.focusPasswordConf) {
      let innerHeight = window.innerHeight
      let screenHeight = window.screen.height
      if (innerHeight !== screenHeight) {
        this.setState({ appearSoftKeyboard: true })
      }
    } else {
      this.setState({ appearSoftKeyboard: false })
    }
  }

  render () {
    const { classes, ...other } = this.props

    return (
      <div>
        <div className={classes.root}>
          <AppHeader title="パスワード変更" backWhite={true} hideBack={true} {...other} />
          <ConfirmDialog
            open={this.state.isError}
            onClose={this.dialogClose}
            onOk={this.dialogClose}
            onCancel={this.dialogClose}
            confirm="yes"
            title="パスワード変更"
            message="パスワードの変更ができませんでした。"
          />
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <Typography className={classes.label}>現在のパスワード</Typography>
            <TextField
              id="currentPassword"
              type="password"
              error={!isEmpty(this.state.currentPasswordError)}
              className={classes.fullTextField}
              onChange={this.onChangeCurrentPassword}
              onFocus={this.onFocusTextField}
              onBlur={this.onBlurTextField}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
            <Typography color="error" style={{whiteSpace: 'pre-line'}} className={classes.errorMessage}>
              {this.state.currentPasswordError}
            </Typography>
            <Typography className={classes.label}>新しいパスワード</Typography>
            <TextField
              id="password"
              type="password"
              error={!isEmpty(this.state.passwordError)}
              className={classes.fullTextField}
              onChange={this.onChangePassword}
              onFocus={this.onFocusTextField}
              onBlur={this.onBlurTextField}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
            <Typography color="error" style={{whiteSpace: 'pre-line'}} className={classes.errorMessage}>
              {this.state.passwordError}
            </Typography>
            <Typography className={classes.label}>新しいパスワード（確認）</Typography>
            <TextField
              id="passwordConf"
              error={!isEmpty(this.state.passwordConfirmError)}
              type="password"
              className={classes.fullTextField}
              onChange={this.onChangePasswordConfirm}
              onFocus={this.onFocusTextField}
              onBlur={this.onBlurTextField}
              fullWidth
              InputProps={{ disableUnderline: true }}
            />
            <Typography color="error" style={{whiteSpace: 'pre-line'}} className={classes.errorMessage}>
              {this.state.passwordConfirmError}
            </Typography>
            {
              !this.state.appearSoftKeyboard &&
              <Grid container direction="column" alignItems="center" justifyContent="space-around">
                <Grid container direction="column" alignItems="center" justifyContent="space-around" className={classes.fixedBotton} >
                  <Button variant="contained" color="secondary" className={classes.save} onClick={this.changePassword}>
                    保存
                  </Button>
                  <Button variant="text" className={classes.cancelButton} onClick={this.clickCancel}>
                    キャンセル
                  </Button>
                </Grid>
              </Grid>
            }
          </form>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(MyPassword)
