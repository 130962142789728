import React from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { baseThemeProps } from 'commons/theme'

export default function FoodReportThemeProvider (props) {
  return (
    <ThemeProvider theme={createNewTheme()}>
      {props.children}
    </ThemeProvider>
  )
}

FoodReportThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

function createNewTheme () {
  const overrides = {
    MuiBackdrop: {
      root: {
        backgroundColor: '#29282899',
        backdropFilter: 'blur(5px)',
      },
    },
    MuiFormHelperText: {
      root: {
        display: 'none',
      },
    },
    MuiInput: {
      root: {
        fontSize: 12,
        color: '#767676',
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover': {
          borderBottom: 'none',
        },
      },
    },
    MuiSelect: {
      root: {
        fontSize: 12,
        color: '#767676',
        paddingLeft: 16,
      },
      icon: {
        color: '#423BC7',
        width: 18,
      }
    },
    MuiButton: {
      root: {
        fontSize: 14,
        fontWeight: 'bold',
        width: 230,
        height: 40,
        boxShadow: '0 1px 6px #00000029 !important',
      },
      contained: {
        color: '#ffffff',
        backgroundColor: '#616AFA',
        borderRadius: 25,
        '&:hover': {
          color: '#ffffff',
          backgroundColor: '#616AFA !important',
        }
      },
      outlined: {
        color: '#ffffff',
        backgroundColor: '#423BC7',
        borderRadius: 25,
        '&:hover': {
          color: '#ffffff',
          backgroundColor: '#423BC7 !important',
        },
        '&.Mui-disabled': {
          color: '#ffffff',
          backgroundColor: '#E0E0E0'
        }
      },
    },
    MuiCard: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCardActions: {
      root: {
        paddingTop: 0,
        paddingBottom: 16,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: '#2E2279',
        backgroundColor: '#B0B4FC',
        '&:hover': {
          color: '#2E2279',
          backgroundColor: '#B0B4FC !important',
        },
      },
      current: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    }
  }
  return createTheme(
    Object.assign(baseThemeProps(), { overrides })
  )
}
