import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import Komainu from 'images/Komainu/help.png'

const useStyles = makeStyles(theme => ({
  body: {
    height: `calc(${window.innerHeight}px - 83px - 114px - 42.25px)`,
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  message: {
    fontSize: 16,
    fontWeight: 600,
    color: '#707070'
  },
  button: {
    width: 230,
    height: 44,
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 55,
    color: '#423BC7',
    border: '1px solid rgba(66, 59, 199, 0.5)',
  },
}))

export default function DisableCookieScreen () {
  const classes = useStyles()
  return (
    <>
      <EntryHeader />
      <Grid
        className={classes.body}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.message}>
          お使いのWebブラウザの設定よりCookieを有効にしてください。
        </Typography>
        <img src={Komainu} width={300} height={300} />
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={() => window.location.reload()}
        >
          再読み込み
        </Button>
      </Grid>
      <EntryFooter />
    </>
  )
}
