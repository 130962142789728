import { createTheme } from '@material-ui/core/styles'

const primaryColor = '#ffffff'
const primaryTextColor = '#707070'
export const secondaryColor = '#423BC7'
const secondaryTextColor = '#ffffff'
const backgroundColor = '#ffffff'
const dialogDividerColor = '#e0e0e0'
const highlightColor = '#616AFA'
export const headerColor = '#1A1667'
export const entryFooterColor = '#D9DAFC'
const secretRoomColor = '#DBDCFE'
const shopListColor = '#DBDCFE'
const redHighlightColor = '#F55083'

// デザイン上の想定画面幅(px): Android
const screenWidthOnDesign = 360
// デザイン上の想定画面高さ(px): Android
const screenHeightOnDesign = 736

// 間隔の単位(px)
const spacingUnit = 8

export const sizeRatio = () => {
  let ratio = window.screen.width / screenWidthOnDesign
  return Math.min(ratio, 1.2)
}

const sizeHeightRatio = () => {
  let ratio = window.screen.height / screenHeightOnDesign
  return Math.min(ratio, 1.2)
}

export const spacing = factor => (factor * spacingUnit * sizeRatio())
export const spacingHeight = factor => (factor * spacingUnit * sizeHeightRatio())

// FriendCellのメッセージ表示行数計算用
const aspectRatio = () => {
  return window.screen.width / window.screen.height
}
const messageline = () => {
  if (aspectRatio() < 0.5) {
    return 3
  } else {
    return 2
  }
}

// ヘッダー高さ(px)
const headerHight = spacingHeight(6.5)
// フッダー高さ(px)
// TODO タイポ修正: Height
const footerHight = spacingHeight(10.375)
// 会社検索、入会登録フッター高さ（px）
// TODO タイポ修正: Height
export const entryFooterHight = spacingHeight(24)

const theme = createTheme({
  breakpoints: {
    keys: [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
    ],
    values: {
      'xs': 320,
      'sm': 360,
      'md': 768,
      'lg': 1024,
      'xl': 1400,
    },
  },
})

export const typography = {
  fontSize: 14 * sizeRatio(),
  fontFamily: [
    '"Noto Sans Japanese"',
    '"游ゴシック体"',
    '"YuGothic"',
    '"游ゴシック Medium"',
    '"Yu Gothic Medium"',
    '"sans-seri"',
  ].join(','),
}

export const baseThemeProps = () => ({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: primaryTextColor,
    },
    secondary: {
      main: secondaryColor,
      contrastText: secondaryTextColor,
    },
    background: {
      default: backgroundColor,
      tutorial: highlightColor,
    }
  },
  spacingHeight,
  typography,
  styles: {
    header: {
      height: headerHight,
      backgroundColor: headerColor,
    },
    footer: {
      height: footerHight,
    },
    entryFooter: {
      height: entryFooterHight,
      backgroundColor: entryFooterColor,
      width: '100%',
    },
    fontSize: size => ({
      fontSize: size * sizeRatio(),
    }),
    textIndent: size => ({
      textIndent: -1 * size * sizeRatio(),
    }),
    title: {
      fontSize: 18 * sizeRatio(),
    },
    underlineTextButton: {
      color: primaryTextColor,
      fontSize: 14 * sizeRatio(),
      textDecoration: 'underline',
      flexBasis: 'auto',
    },
    dialogDivider: {
      backgroundColor: dialogDividerColor,
    },
    dialogInput: {
      width: '100%',
      border: '1px solid #999',
      background: '#FFFFFFFF',
    },
    dialogActions2Buttons: {
    },
    dialogActions3Buttons: {
    },
    dialogOkButton: {
      fontWeight: 'bold',
    },
    highlight: {
      color: '#F06292',
    },
    signupTextField: {
      marginTop: 0,
      width: '100%',
    },
    signupDescription: {
      color: '#878787',
      fontSize: 12.5 * sizeRatio(),
      marginTop: 0,
      lineHeight: 1.75,
    },
    entryButton: {
      fontSize: 14 * sizeRatio(),
    },
    profileItem: {
      fontSize: 12 * sizeRatio(),
    },
    profileItemStatus: {
      fontSize: 12 * sizeRatio(),
      color: secondaryColor,
    },
    organizationLabel: {
      marginTop: spacingHeight(2),
      marginBottom: spacingHeight(2),
      fontSize: 18 * sizeRatio(),
    },
    profilePhotoWidth: {
      width: '100%',
    },
    photoContainer: {
      width: '100%',
      height: 0,
      paddingBottom: '100%',
      position: 'relative',
    },
    photoSize: {
      width: '100%',
      backgroundColor: '#F5F5F5',
      height: '100%',
      minHeight: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    },
    photoGallerySize: {
      width: `calc(100% - ${theme.spacing(3)}px)`,
      maxWidth: spacing(60),
    },
    highlightColor: {
      color: highlightColor,
    },
    redHighlightColor: {
      color: redHighlightColor,
    },
    remainBackgroundColor: {
      backgroundColor: redHighlightColor,
    },
    messageColor: {
      color: headerColor,
    },
    naviSpeach: color => ({
      borderRadius: '25px 25px 25px 5px',
      backgroundColor: color,
      padding: spacing(2),
      marginBottom: spacing(1),
      marginRight: spacing(1),
      maxWidth: `calc(100% - ${spacing(11.5)}px)`,
    }),
    naviSpeachSecret: color => ({
      backgroundColor,
      border: `2px dotted ${color}`,
      borderRadius: '25px 25px 25px 5px',
      padding: spacing(2),
      marginBottom: spacing(1),
      marginRight: spacing(1),
      maxWidth: `calc(100% - ${spacing(11.5)}px)`,
    }),
    toMessageLine: {
      '-webkit-line-clamp': messageline(),
    },
    secretRoomColor: {
      backgroundColor: secretRoomColor,
    },
    starIcon: {
      fontSize: (25 * sizeRatio()),
      color: '#ffd700',
    },
    shopRatingText: {
      fontSize: (25 * sizeRatio()),
      textAlign: 'center',
    },
    shopListColor: {
      backgroundColor: shopListColor,
    },
    creditTableColor: {
      backgroundColor: entryFooterColor,
    },
    headerIconColor: {
      color: primaryTextColor,
    },
  },
})

export const defaultOverrides = () => ({
  // Buttonはvariantで使い分けたいので、root と label は定義しないでください。
  // https://material-ui.com/api/button/#css
  MuiButton: {
    contained: {
      padding: 4,
      borderRadius: 26,
      boxShadow: 'none !important',
    },
    // DialogActions内のButton
    text: {
      fontSize: 18 * sizeRatio(),
      color: '#007aff',
      flexGrow: 1,
      flexBasis: 106 * sizeRatio(),
    },
  },
  MuiOutlinedInput: {
    input: {
      paddingTop: 7.5,
      paddingBottom: 7.5,
      paddingLeft: 12.5,
      paddingRight: 12.5,
    },
    notchedOutline: {
      borderRadius: 8,
      border: '2px solid #999',
    },
  },
  MuiBottomNavigation: {
    root: {
      height: footerHight,
    },
  },
  MuiBottomNavigationAction: {
    root: {
      '&$selected': {
        color: secondaryColor,
      },
    },
    label: {
      marginTop: spacingHeight(1),
      fontSize: 9 * sizeRatio(),
      '&$selected': {
        fontSize: 9 * sizeRatio(),
      },
    },
    selected: {},
  },
  MuiBadge: {
    badge: {
      color: '#FFFFFF',
      backgroundColor: '#FF0000',
    }
  },
  MuiTypography: {
    body1: {
      fontSize: 14 * sizeRatio(),
      color: primaryTextColor,
    },
  },
  MuiDialog: {
    paper: {
      borderRadius: 13,
      background: 'rgba(255, 255, 255)',
    }
  },
  MuiDialogTitle: {
    root: {
      textAlign: 'center',
      color: primaryTextColor,
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'center',
      paddingTop: 4,
      paddingBottom: 4,
    }
  },
  MuiFormLabel: {
    root: {
      color: primaryTextColor,
      '&$focused': {
        color: '#b2b2b2'
      }
    },
  },
  MuiInput: {
    underline: {
      '&$focused:after': {
        borderBottom: '2px solid #b2b2b2',
      }
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: '0px 0px 7px #0000002E',
    }
  },
  MuiDrawer: {
    paper: {
      boxShadow: '0px 3px 6px #00000029',
      '& ::-webkit-scrollbar': {
        display: 'none',
      },
    }
  },
  MuiCard: {
    root: {
      boxShadow: '0px 1px 8px #00000033',
    }
  },
})

export default createTheme(
  Object.assign(baseThemeProps(), {
    overrides: defaultOverrides()
  })
)