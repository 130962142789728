import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    color: '#1A1667',
  },
  subtitle: {
    width: '100%',
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    background: '#F4F7FE',
    lineHeight: 1,
    textAlign: 'left',
    borderRadius: 4,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  text: {
    width: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    textAlign: 'left',
    letterSpacing: 0.7,
  },
  containedButton: {
    background: '#616AFA !important',
  },
  textButton: {
    color: '#616AFA !important',
  },
}))

export default function CouponConfirmDialog (props) {
  const classes = useStyles()
  const { open, onConfirm, onClose, data } = props

  if (!data) { return null }

  const createColumn = (text) => (
    <Typography className={classes.subtitle} variant="subtitle1">{text}</Typography>
  )

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>
          クーポン内容確認
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" justyfyContent="center" alignItems="center">
            {createColumn('クーポン名')}
            <Typography className={classes.text} variant="body1">
              {data.title}
            </Typography>
            {createColumn('クーポン内容')}
            <Typography className={classes.text} variant="body1">
              {data.description}
            </Typography>
            {createColumn('利用方法')}
            <Typography className={classes.text} variant="body1">
              {data.incentive_name.startsWith('plan_discount_') ? (
                <>
                  1.本ポップアップ下部の「登録する」ボタンを押してクーポンを登録<br />
                  2.「ご登録済みのクーポン一覧」にて、次回の月額ご利用料金決済時に使用したいクーポンの「選択する」ボタンをタップ<br />
                  3.「選択中」のクーポンが次回の月額ご利用料金決済時に適用
                </>
              ) : (
                <>
                  本ポップアップ下部の「登録する」ボタンを押してクーポンを登録してください。
                </>
              )}
            </Typography>
            {createColumn('注意事項')}
            <Typography className={classes.text} variant="body1">
              {data.incentive_name.startsWith('plan_discount_') ? (
                <>
                  ・本ポップアップにて「登録する」を押しただけではクーポンは適用されません<br />
                  ・必ずメンバーズサイト「ご登録済みのクーポン一覧」より次回の月額ご利用料金決済時に利用したいクーポンの「選択する」ボタンをタップしてください<br />
                  ・ご登録後に、クーポンを「選択中」にしなかった場合、決済時にクーポンは適用できません<br />
                  ・利用前にクーポンが期限切れとなった場合、払い戻しはございません
                </>
              ) : (
                <>
                  クーポンが適用されるまで最大60分程度かかりますのでご了承ください。
                </>
              )}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.containedButton} variant="contained" onClick={onConfirm}>
            クーポンを登録する
          </Button>
          <Button className={classes.textButton} variant="text" onClick={onClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

CouponConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
}
