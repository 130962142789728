import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemText, ListItemSecondaryAction , Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import HelpIcon from '@material-ui/icons/HelpOutline'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Api from 'commons/api'
import Const from 'commons/constant'
import * as strage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import {
  httpBadRequest,
  httpForbidden,
  httpConflict,
  fetchResponseErrorMessage
} from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import BasicProfile from 'components/parts/BasicProfile'
import CommonProfile from 'components/parts/CommonProfile'
import FriendEntryTutorialDialog from 'components/parts/FriendEntryTutorialDialog'
import FriendEstablishedDialog from 'components/parts/FriendEstablishedDialog'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import LoveProfile from 'components/parts/LoveProfile'
import Rating from 'components/parts/Rating'

const styles = theme => ({
  root: {
    backgroundColor: '#DBDCFE',
    paddingBottom: theme.spacing(15),
  },
  rootSendPage: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 12,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textSend: {
    fontSize: 14,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    marginTop: theme.spacing(1.25),
  },
  title: {
    fontSize: 19,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 16,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
  },
  textButton: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
  },
  cancelButton: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginTop: theme.spacing(0.5),
  },
  fixedBotton: {
    bottom: theme.spacing(4.375),
    position: 'fixed',
  },
  send: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: theme.spacing(33.5),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(1.5),
  },
  profileContainer: {
    paddingTop: theme.spacing(5.75),
    width: '100%',
    textAlign: 'center',
  },
  backButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: theme.spacing(8),
    height: theme.spacing(6.375),
    padding: 0,
    zIndex: '999'
  },
  backIcon: {
    color: theme.palette.secondary.main,
  },
  stars: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7.5),
  },
  starFill: {
    color: '#3D45C2',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    display: 'inline',
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
  },
  starBorder: {
    color: '#AFB7F8',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    display: 'inline',
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
  },
  starCheckbox: {
    height: theme.spacing(2.5),
    padding: 0,
  },
  basicProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  loveProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  profileTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.375),
    textAlign: 'left',
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  messageContainer: {
    width: theme.spacing(37.5),
  },
  message: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
    border: 'solid 1px #AFB7F8',
    borderRadius: 8,
  },
  error: {
    borderColor: 'red',
  },
  buttonContainer: {
    marginTop: theme.spacing(1.5),
  },
  fixedMessagesDialog: {
    padding: 0,
  },
  fixedMessagesText: {
    padding: 0,
  },
  ratingContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  helpIcon: {
    paddingTop: theme.spacing(0.5),
    fontSize: 22,
    opacity: 0.5,
  },
  countText: {
    textAlign: 'right',
    marginTop: theme.spacing(1),
  },
  textError: {
    color: 'red',
  },
})

function entryTitleFromType (type) {
  switch (type) {
    case 'apply_friend':
      return '「いいね！」'
    case 'apply_lover':
      return 'お付き合いリクエスト'
    case 'approve_friend':
      return '「いいね！」の承認'
    case 'approve_lover':
      return 'お付き合いリクエストの承認'
    default:
      return ''
  }
}

export class FriendEntry extends BaseComponent {
  constructor (props) {
    super(props)
    this.sendEntries = this.sendEntries.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleErrorClose = this.handleErrorClose.bind(this)
    this.handleLoveEntryClose = this.handleLoveEntryClose.bind(this)
    this.handleApprovalLoveEntryClose = this.handleApprovalLoveEntryClose.bind(this)
    this.closeTutorial = this.closeTutorial.bind(this)
    this.closeFriendEstablishedDialog = this.closeFriendEstablishedDialog.bind(this)
    this.clickBack = this.clickBack.bind(this)
    this.clickCancel = this.clickCancel.bind(this)
    this.clickNext = this.clickNext.bind(this)
    this.onChangeMessage = this.onChangeMessage.bind(this)
    this.saveDraft = this.saveDraft.bind(this)
    this.openFixedMessagesDialog = this.openFixedMessagesDialog.bind(this)
    this.openFixedReplyMessagesDialog = this.openFixedReplyMessagesDialog.bind(this)
    this.onSelectFixedMessage = this.onSelectFixedMessage.bind(this)
    this.closeFixedMessagesDialog = this.closeFixedMessagesDialog.bind(this)
    this.showFavSuggest = this.showFavSuggest.bind(this)
    this.closeFavSuggest = this.closeFavSuggest.bind(this)

    this.draft = null
    this._isMounted = false

    this.state = {
      confirmOpen: false,
      errorOpen: false,
      errorMessage: '',
      entryTitle: entryTitleFromType(props.entryType),
      impression: 0,
      friend: null,
      isLoveEntryDialog: false,
      isApprovalLoveEntryDialog: false,
      tutorialOpen: false,
      likes: null,
      openFriendEstablished: false,
      openSendPage: false,
      message: '',
      fixedMessagesOpen: false,
      fixedMessages: [],
      errorInputMessage: false,
      favSuggestOpen: false,
      errorCount: false,
    }
  }

  componentWillMount () {
    let friend = this.props.friend
    if (friend == null) {
      friend = strage.getFriend()
    }
    this.setState({
      impression: isNullOrUndefined(friend.impression) ? 0 : friend.impression,
      friend: friend,
      isLoveEntryDialog: (friend.type === 'friend') && friend.first_dated ? true : false,
      isApprovalLoveEntryDialog: (friend.type === 'lover_receiving') && friend.first_dated ? true : false,
      openSendPage: this.isLoverEntry() ? true : false,
    })
    this.props.setFriend(friend)
    this.getLikes(friend.id)
    this.loadDraft(friend.id)
  }

  componentDidMount () {
    this._isMounted = true
    this.props.setFriend(this.state.friend)
    this.addVisitPageLog({ friend_user_id: this.state.friend.id })
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog({ friend_user_id: this.state.friend.id })
  }

  handleClose () {
    this.setState({ confirmOpen: false })
    this.props.setScreen('/Home')
  }

  handleLoveEntryClose () {
    this.setState({ isLoveEntryDialog: false })
  }

  handleApprovalLoveEntryClose () {
    this.setState({ isApprovalLoveEntryDialog: false })
  }

  handleErrorClose () {
    this.setState({ errorOpen: false })
  }

  validateFirstImpression () {
    if (this.state.impression === 0) {
      this.setState({errorMessage: '第一印象を設定してください'})
      this.setState({errorOpen: true})
      return false
    }
    return true
  }

  validateMessage () {
    if (!this.state.message.trim()) {
      this.setState({
        errorInputMessage: true,
        errorCount: false,
        errorOpen: true,
        errorMessage: 'メッセージを入力してください'
      })
      return false
    } else if (Const.maxChatMessageLength < this.state.message.length) {
      this.setState({
        errorInputMessage: true,
        errorCount: true,
        errorOpen: true,
        errorMessage: `メッセージが${Const.maxChatMessageLength}文字を超えています`
      })
      return false
    }
    return true
  }

  isFriendEntry () {
    return this.props.entryType === 'apply_friend'
  }

  isApprovalFriendEntry () {
    return this.props.entryType === 'approve_friend'
  }

  // 保存済みの下書きがあれば読み込み
  async loadDraft (friendId) {
    this.props.setLoading(true)
    try {
      let draft = await Api.findEntryDraft(friendId)
      if (!isNullOrUndefined(draft) && draft.entry_type === this.props.entryType) {
        this.draft = draft
        this.setStateIfMounted({ message: draft.message })
        if (draft.impression) {
          this.setStateIfMounted({ impression: draft.impression })
        }
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  // 下書き保存
  async saveDraft () {
    this.props.setLoading(true)
    let params = {
      entry_type: this.props.entryType,
      impression: this.state.impression,
      message: this.state.message,
    }
    try {
      if (this.draft) {
        await Api.updateEntryDraft(this.state.friend.id, params)
      } else {
        await Api.createEntryDraft(this.state.friend.id, params)
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  // 下書き削除
  async deleteDraft () {
    if (this.draft) {
      await Api.deleteEntryDraft(this.state.friend.id)
    }
  }

  async sendEntries () {
    const { entryType } = this.props

    if (entryType === 'apply_lover') {
      if (this.isExceededLoverApplyingCount()) return
    }
    if (!this.validateMessage()) return

    this.props.setLoading(true)
    try {
      let friendId = this.props.friend.id
      let opts = {
        to_message: this.state.message
      }
      if (entryType === 'apply_friend' || entryType === 'approve_friend') {
        opts.impression = this.state.impression
      }
      await this.postEntry(friendId, entryType, opts)
      await this.deleteDraft()
      await this.loadFriends()
      if (entryType === 'approve_friend') {
        this.setStateIfMounted({ openFriendEstablished: true })
      } else {
        this.showRequestSuccessMessage('お付き合いリクエストを送信しました')
        this.props.setScreen('/Home')
      }
    } catch (error) {
      this.setStateIfMounted({
        errorMessage: await this.getEntryErrorMessage(error),
        errorOpen: true
      })
    } finally {
      this.props.setLoading(false)
    }
  }

  // 申請失敗時のエラーメッセージ
  async getEntryErrorMessage (error) {
    let entryTitle = this.state.entryTitle
    if (httpConflict(error)) {
      const maxFriendCount = BaseComponent.getMaxFriendCount(this.props.subscription)
      const nowFriendCount = BaseComponent.getNowFriendCount(this.props.friends)
      if (maxFriendCount <= nowFriendCount) {
        return `メッセージ中の友達人数が上限に達しているため${entryTitle}できません。`
      } else {
        return `相手の友達人数が上限に達しているため${entryTitle}できません。`
      }
    } else if (httpForbidden(error)) {
      return `${entryTitle}はすでに送信済みです。`
    } else if (httpBadRequest(error)) {
      return (await fetchResponseErrorMessage(error))
    } else {
      return `${entryTitle}に失敗しました。`
    }
  }

  // お付き合い申請中の数が上限か
  isExceededLoverApplyingCount () {
    if (this.getLoverApplyingCount() > 0) {
      this.setState({
        errorMessage: '他の異性の方にお付き合いリクエスト中のためお付き合いリクエストできません。',
        errorOpen: true
      })
      return true
    }
    return false
  }

  // お付き合い申請中の数
  getLoverApplyingCount () {
    return this.props.friends.filter(friend => {
      if (friend.type === 'lover_applying') { return true }
      return false
    }).length
  }

  async closeTutorial () {
    try {
      this.setStateIfMounted({ tutorialOpen: false })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async getLikes (friendId) {
    try {
      let likes = await Api.getLikes(friendId)
      this.setStateIfMounted({ likes })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  clickNext () {
    const { entryType } = this.props
    switch (entryType) {
      case 'apply_friend':
        if (!this.validateFirstImpression()) return
        break
      case 'approve_friend':
        if (!this.validateFirstImpression()) return
        break
      default:
        break
    }
    this.setState({
      likes: null,
      openSendPage: true
    })
  }

  closeFriendEstablishedDialog () {
    this.setState({ openFriendEstablished: false })
    this.props.setScreen('FriendRequest')
  }

  clickBack () {
    if (this.state.openSendPage) {
      this.getLikes(this.state.friend.id)
      this.setState({ openSendPage: false })
    } else {
      BaseComponent.goBack(this.props)
    }
  }

  clickCancel () {
    BaseComponent.goBack(this.props)
  }

  getStars () {
    const { classes } = this.props
    return [1, 2, 3, 4, 5].map(impression => (
      <Checkbox
        key={impression}
        onChange={() => this.setState({ impression })}
        icon={<StarBorderIcon className={classes.starBorder} />}
        checkedIcon={<StarIcon className={classes.starFill} />}
        checked={this.state.impression >= impression}
        className={classes.starCheckbox}
      />)
    )
  }

  onChangeMessage (event) {
    let error = false
    if (event.target.value.length > Const.maxChatMessageLength) {
      error = true
    }
    this.setState({
      message: event.target.value,
      errorInputMessage: false,
      errorCount: error,
    })
  }

  // お付き合い申請/承認判定
  isLoverEntry () {
    return (this.props.entryType === 'apply_lover' || this.props.entryType === 'approve_lover')
  }

  createFixedMessages () {
    let myName = this.props.user.nick_name
    let yourName = this.props.friend.nick_name
    return [
      `初めまして、${myName}です。\nよかったらメッセージ交換しませんか？`,
      `初めまして${myName}です。\nぜひ、お話ししてみたいです。よろしくお願いします(*^-^*)`,
      `初めまして、${myName}です。\nプロフィールを見てお話が合いそうだと思って連絡しました。\n是非、色々お話しできたら嬉しいです！`,
      `${yourName}さん、初めまして。${myName}です。\nプロフィールを拝見し、是非お話ししてみたいと思い、連絡いたしました。\nよかったらメッセージ交換しませんか(*^-^*)？？`
    ]
  }

  createFixedReplyMessages () {
    let myName = this.props.user.nick_name
    let yourName = this.props.friend.nick_name
    return [
      'はい、喜んで(*^-^*)\nよろしくお願いいたします。',
      `初めまして、${myName}です。\n是非よろしくお願いします！`,
      '「いいね！」ありがとうございます。\nはい、よろしくお願いします(*^-^*)',
      `「いいね！」嬉しいです！\nこれから${yourName}さんと色々お話しできればと思います。よろしくお願いいたします。`,
      `${yourName}さんと楽しくお話しできれば嬉しいです！よろしくお願いいたします。`,
    ]
  }

  openFixedMessagesDialog () {
    this.setState({
      fixedMessages: this.createFixedMessages(),
      fixedMessagesOpen: true,
    })
  }

  openFixedReplyMessagesDialog () {
    this.setState({
      fixedMessages: this.createFixedReplyMessages(),
      fixedMessagesOpen: true,
    })
  }

  onSelectFixedMessage (msg) {
    this.setState({
      message: msg,
      fixedMessagesOpen: false,
    })
  }

  closeFixedMessagesDialog () {
    this.setState({fixedMessagesOpen: false})
  }

  showFavSuggest () {
    this.setState({ favSuggestOpen: true })
  }

  closeFavSuggest () {
    this.setState({ favSuggestOpen: false })
  }

  displayFavPoint (fav_point_to_me) {
    if (isNullOrUndefined(fav_point_to_me)) return false
    if (fav_point_to_me <= 50) return false
    return true
  }

  render () {
    const { classes, ...other } = this.props

    let confirmLoveEntryMessage = []
    confirmLoveEntryMessage.push(<span key="entry_msg1">{'■お付合いが成立した場合、他のトーク中の異性は必然的に友達解消となりますのでご注意ください。'}<br /><br /></span>)
    confirmLoveEntryMessage.push(<span key="entry_msg2">{'■10日以内に返事がない場合は友達解消となりますのでご注意ください。'}</span>)

    let confirmApprovalLoveEntryMessage = []
    confirmApprovalLoveEntryMessage.push(<span key="approval_msg1">{'■お付合いが成立した場合、他のトーク中の異性は必然的に友達解消となりますのでご注意ください。'}<br /><br /></span>)
    confirmApprovalLoveEntryMessage.push(<span key="approval_msg2">{'■10日以内に返事を行わない場合、友達解消となりますのでご注意ください。'}</span>)

    let tutorialMessage = []
    tutorialMessage.push(<span key="tutorial_msg1">{'第一印象の情報は直接お相手には伝わりません。'}<br /><br /></span>)
    tutorialMessage.push(<span key="tutorial_msg2">{'好感度ナビの参考情報となります。'}<br /><br /></span>)
    tutorialMessage.push(<span key="tutorial_msg3">{'好感が持てたプロフィール情報へ“好印象”をつけると'}<br /><br /></span>)
    tutorialMessage.push(<span key="tutorial_msg4">{'あなたの好みをAIが学習します'}</span>)

    if (this.state.friend == null) {
      return null
    }
    return (
      <React.Fragment>
        <ImportantConfirmDialog
          open={this.state.isLoveEntryDialog}
          onClose={this.handleLoveEntryClose}
          onOk={this.handleLoveEntryClose}
          onCancel={this.handleLoveEntryClose}
          confirm="yes" title={'お付合いリクエストの注意事項'}
          message={confirmLoveEntryMessage} />
        <ImportantConfirmDialog
          open={this.state.isApprovalLoveEntryDialog}
          onClose={this.handleApprovalLoveEntryClose}
          onOk={this.handleApprovalLoveEntryClose}
          onCancel={this.handleApprovalLoveEntryClose}
          confirm="yes" title={'お付合いリクエストへの返信時の注意事項'}
          message={confirmApprovalLoveEntryMessage} />
        <FriendEstablishedDialog
          {...other}
          open={this.state.openFriendEstablished}
          onClose={this.closeFriendEstablishedDialog}
          onOk={this.closeFriendEstablishedDialog}
          onCancel={this.closeFriendEstablishedDialog}
        />
        <ImportantConfirmDialog
          open={this.state.errorOpen}
          onClose={this.handleErrorClose}
          onOk={this.handleErrorClose}
          onCancel={this.handleErrorClose}
          confirm="yes" title={this.state.entryTitle} message={this.state.errorMessage} />
        {
          this.props.user.nick_name &&
          <FriendEntryTutorialDialog
            open={this.state.tutorialOpen}
            onClose={this.closeTutorial}
            name={this.props.user.nick_name}
          />
        }
        <ImportantConfirmDialog
          open={this.state.favSuggestOpen}
          onClose={this.closeFavSuggest}
          onOk={this.closeFavSuggest}
          onCancel={this.closeFavSuggest}
          tutorial="yes"
          title="好感度"
          message={this.getFavSuggestMessage()}
        />
        {!this.state.openSendPage ? (
          <div className={classes.root}>
            <IconButton aria-label="add" className={classes.backButton} onClick={this.clickBack} >
              <LeftIcon className={classes.backIcon}/>
            </IconButton>
            <div className={classes.profileContainer}>
              <div>
                <Typography className={classes.title}>
                  送信する前に、印象アンケート
                </Typography>
                <Typography className={classes.text}>
                  あなたに合う相手を見つけるために<br />
                  Aillにあなたが感じた印象を送ってください。<br />
                  （相手には送信されません）
                </Typography>
              </div>
              <CommonProfile
                user={this.props.user}
                component={this}
                simple={true}
                {...other}
                friend={this.state.friend}
              />
              {
                this.isApprovalFriendEntry() && this.displayFavPoint(this.state.friend.fav_point_to_me) &&
                <Grid container alignItems="center" justifyContent="center" className={classes.ratingContainer}>
                  <Typography>
                    好感度：
                  </Typography>
                  <Rating point={this.state.friend.fav_point_to_me} />
                  <IconButton onClick={this.showFavSuggest} >
                    <HelpIcon className={classes.helpIcon} color="secondary" />
                  </IconButton>
                </Grid>
              }
              <div className={classes.container}>
                <Typography className={classes.subtitle}>
                  {this.state.friend.nick_name}さんに対する第一印象は？
                </Typography>
                <Grid container className={classes.stars} justifyContent="center" alignItems="center" >
                  {this.getStars()}
                </Grid>
                <Typography className={classes.subtitle}>
                  好感が持てたプロフィール情報に<br/>
                  「好印象」（ハート）をつけよう！
                </Typography>
              </div>
              {
                this.state.likes &&
                <div>
                  <div className={classes.basicProfileContainer}>
                    <Typography  className={classes.profileTitle}>
                      基本情報
                    </Typography>
                    <BasicProfile {...other} isLikes={true} likes={this.state.likes} />
                  </div>
                  <div className={classes.loveProfileContainer}>
                    <Typography  className={classes.profileTitle}>
                      恋愛情報
                    </Typography>
                    <LoveProfile {...other} isLikes={true} likes={this.state.likes} />
                  </div>
                </div>
              }
            </div>
            <Grid container direction="column" alignItems="center" justifyContent="space-around" >
              <Grid container className={classes.fixedBotton} justifyContent="center" >
                <Button variant="contained" color="secondary" className={classes.send} onClick={this.clickNext}>
                  次へ
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div className={classes.rootSendPage}>
            {!this.isLoverEntry() &&
              <IconButton aria-label="add" className={classes.backButton} onClick={this.clickBack} >
                <LeftIcon className={classes.backIcon}/>
              </IconButton>
            }
            <div className={classes.profileContainer}>
              <CommonProfile
                user={this.props.user}
                component={this}
                simple={true}
                onlyAvatar={true}
                {...other}
                friend={this.state.friend}
              />
            </div>
            <div>
              <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                  {this.state.entryTitle}を送る
                </Typography>
                <Typography className={classes.textSend}>
                  一言メッセージを{this.props.entryType === 'apply_friend' && '添えて'}送ろう！
                </Typography>
              </div>
            </div>
            <Grid container direction="column" alignItems="center" justifyContent="center" >
              <Grid item className={classes.messageContainer}>
                <InputBase
                  className={classes.message}
                  id="message"
                  placeholder="メッセージを入力"
                  value={this.state.message}
                  onChange={this.onChangeMessage}
                  error={this.state.errorInputMessage}
                  classes={{ error: classes.error }}
                  multiline
                  rows="10"
                />
              </Grid>
              <Typography className={classes.countText + ' ' + (this.state.errorCount ? classes.textError : '')}>
                {this.state.message.length}/{Const.maxChatMessageLength}
              </Typography>
              <Grid container direction="column" alignItems="center" className={classes.buttonContainer}>
                {
                  !this.isLoverEntry() &&
                  <Button
                    variant="text"
                    className={classes.textButton}
                    onClick={this.isFriendEntry() ? this.openFixedMessagesDialog : this.openFixedReplyMessagesDialog}
                  >
                    定番メッセージを選択
                  </Button>
                }
                <Button
                  variant="text"
                  className={classes.textButton}
                  disabled={this.state.message.trim().length === 0 || this.state.errorCount}
                  onClick={this.saveDraft}
                >
                  下書き保存
                </Button>
              </Grid>
            </Grid>
            <DialogThemeProvider color="default">
              <Dialog open={this.state.fixedMessagesOpen}>
                <DialogTitle>
                  定番メッセージを選択
                </DialogTitle>
                <DialogContent className={classes.fixedMessagesDialog}>
                  <Divider />
                  <List>
                    {this.state.fixedMessages.map((msg,i) => {
                      return (
                        <React.Fragment key={msg}>
                          <ListItem
                            key={msg}
                            button
                            onClick={() => this.onSelectFixedMessage(msg)}
                          >
                            <ListItemText primary={msg} className={classes.fixedMessagesText} />
                            <ListItemSecondaryAction>
                              <ChevronRightIcon />
                            </ListItemSecondaryAction>
                          </ListItem>
                          {
                            i < this.state.fixedMessages.length-1 && <Divider />
                          }
                        </React.Fragment>
                      )
                    })}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button variant="text" onClick={this.closeFixedMessagesDialog}>
                    キャンセル
                  </Button>
                </DialogActions>
              </Dialog>
            </DialogThemeProvider>
            <Grid container direction="column" alignItems="center" justifyContent="space-around" >
              <Grid container direction="column" alignItems="center" >
                <Button variant="text" className={classes.cancelButton} onClick={this.clickCancel}>
                  キャンセル
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.send}
                  onClick={this.sendEntries}
                  disabled={this.state.message.trim().length === 0 || this.state.errorCount}
                >
                  送信する
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </React.Fragment>
    )
  }
}

FriendEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  entryType: PropTypes.string.isRequired,
}

export default withStyles(styles)(FriendEntry)
