import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Api from 'commons/api'
import Storage from 'commons/storage'
import BaseComponent from 'components/parts/BaseComponent'
import InputCategory from 'components/template-dialog/InputCategory'
import Preview from 'components/template-dialog/Preview'
import SelectCategory from 'components/template-dialog/SelectCategory'
import SelectPattern from 'components/template-dialog/SelectPattern'
import SelfIntroductionThemeProvider from 'components/template-dialog/TemplateDialogThemeProvider'
import FemaleTemplate from 'components/to-message/female'
import MaleTemplate from 'components/to-message/male'

class SelfIntroductionItem {
  static restore (props) {
    const item = new SelfIntroductionItem()
    item.category = props.category || ''
    item.title = props.title || ''
    item.pattern = props.pattern || ''
    item.values = props.values || []
    return item
  }

  constructor (category = '', title = '', pattern= '') {
    this.category = category
    this.title = title
    this.pattern = pattern
    this.values = []
  }

  isEmpty () {
    return !this.title
  }

  valueAt (index) {
    if (!this.values) { return '' }
    if (index < 0) { return '' }
    if (this.values.length <= index) { return '' }
    return this.values[index] || ''
  }
}

export default function ToMessageTemplateDialog (props) {
  const { open, onClose, onChange, user, friend, master } = props

  const [dialogPageNum, setDialogPageNum] = useState(-1)
  const [template, setTemplate] = useState(null)
  const wordType = 'casual'
  const [selectedItems, setSelectedItems] = useState([new SelfIntroductionItem()])
  const [galleryItems, setGalleryItems] = useState([])

  const inputItems = selectedItems.filter(item => !item.isEmpty())

  const restoreStates = () => {
    const states = Storage.toMessageTemplateDialogStates.value
    if (states) {
      if (states.selectedItems) {
        setSelectedItems(
          states.selectedItems.map(obj => SelfIntroductionItem.restore(obj))
        )
      }
    }
  }

  const saveStates = () => {
    Storage.toMessageTemplateDialogStates.value = {
      wordType,
      selectedItems,
    }
  }

  useEffect(() => {
    if (open) {
      const temp = user.sex === 'male' ? MaleTemplate : FemaleTemplate
      setTemplate(temp)
      // 性別を切り替えることで存在しない項目を参照してしまうのでlocalStorageの値をclear
      const states = Storage.toMessageTemplateDialogStates.value
      if (!states?.selectedItems) { return }
      const result = states.selectedItems.every(obj => (!!temp.sentence[obj.title] || !obj.title))
      if (result) {
        restoreStates()
      } else {
        Storage.toMessageTemplateDialogStates.clear()
      }
    }
    if (!open && wordType) { saveStates() }
  }, [open])

  useEffect(() => {
    (async () => {
      try {
        const mainItem = { icon_url: friend.photo_icon, large_url: friend.photo_large }
        const galleryItems = await Api.getGalleryItems(friend.id)
        setGalleryItems([mainItem, ...galleryItems])
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    })()
  }, [])

  const next = () => {
    if (dialogPageNum <= inputItems.length) {
      setDialogPageNum(dialogPageNum + 1)
    }
  }

  const prev = () => {
    setDialogPageNum(dialogPageNum - 1)
  }

  const close = () => {
    onClose()
    setDialogPageNum(-1)
  }

  const onChangeTitle = (index, category, title) => {
    const newItem = new SelfIntroductionItem(category, title)
    const copyItems = selectedItems.concat()
    copyItems.splice(index, 1, newItem)
    setSelectedItems(copyItems)
  }

  const onChangePattern = (index, title) => {
    selectedItems[index].pattern = title
    setSelectedItems(selectedItems.concat())
  }

  const onChangeValues = (index, values) => {
    selectedItems[index].values = values
    setSelectedItems(selectedItems.concat())
  }

  const onTextGenerated = text => {
    onChange && onChange(text)
    close()
  }

  if (!template) { return null }

  return (
    <SelfIntroductionThemeProvider>
      <Dialog open={open} disableEnforceFocus>
        {dialogPageNum === -1 && (
          <SelectCategory
            type="to-message"
            selectedItems={selectedItems}
            onChangeTitle={onChangeTitle}
            onNext={next}
            onClose={close}
            template={template}
            friend={Object.assign(friend, { images: galleryItems })}
            master={master}
          />
        )}
        {dialogPageNum === 0 && (
          <SelectPattern
            type="to-message"
            selectedItems={selectedItems}
            wordType={wordType}
            onChange={onChangePattern}
            onNext={next}
            onPrev={prev}
            onClose={close}
            template={template}
          />
        )}
        {0 < dialogPageNum && dialogPageNum <= inputItems.length && (
          <InputCategory
            type="to-message"
            index={dialogPageNum - 1}
            selectedItems={inputItems}
            wordType={wordType}
            onChange={onChangeValues}
            onNext={next}
            onPrev={prev}
            onClose={close}
            template={template}
            friend={Object.assign(friend, { images: galleryItems })}
            master={master}
          />
        )}
        {inputItems.length < dialogPageNum && (
          <Preview
            type="to-message"
            wordType={wordType}
            selectedItems={inputItems}
            onNext={onTextGenerated}
            onPrev={prev}
            onClose={close}
            template={template}
            friend={friend}
          />
        )}
      </Dialog>
    </SelfIntroductionThemeProvider>
  )
}

ToMessageTemplateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  user: PropTypes.object,
  friend: PropTypes.object,
  master: PropTypes.object,
}
