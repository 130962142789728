import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Config from 'commons/config'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import EntryRejectDialog from 'components/parts/EntryRejectDialog'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import ReasonDialog from 'components/parts/ReasonDialog'

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsContainer: {
    flexGrow: 1,
  },
  entryButton: {
    ...theme.styles.entryButton,
    fontSize: 14,
    fontWeight: 'bold',
    width: theme.spacing(33.5),
    height: theme.spacing(5.5),
    marginBottom: theme.spacing(1),
  },
  cancelButton: theme.styles.underlineTextButton,
  messageContainer: {
    ...theme.styles.entryButton,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: theme.spacing(22),
    color: '#FFFFFF',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 13,
    textAlign: 'center',
    position: 'relative',
  },
  remain: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    opacity: 0.5,
    position: 'absolute',
    left: `calc(50% + ${theme.spacing(12)}px)`,
  },
  linkButton: {
    width: 160,
    height: 42,
    maxHeight: 42,
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
  },
  disabledButton: {
    color: '#ffffff !important',
    backgroundColor: '#9D9D9E !important',
  },
})

class EntryButtons extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleQuestionOpen = this.handleQuestionOpen.bind(this)
    this.handleQuestionOk = this.handleQuestionOk.bind(this)
    this.handleQuestionCancel = this.handleQuestionCancel.bind(this)

    this.handleReasonOpen = this.handleReasonOpen.bind(this)
    this.handleReasonSend = this.handleReasonSend.bind(this)
    this.handleReasonClose = this.handleReasonClose.bind(this)

    this.handleCancelClose = this.handleCancelClose.bind(this)
    this.handleConfirmClose = this.handleConfirmClose.bind(this)

    this.handleQuestionCancelEntryOpen = this.handleQuestionCancelEntryOpen.bind(this)
    this.handleQuestionCancelEntryOk = this.handleQuestionCancelEntryOk.bind(this)
    this.handleQuestionCancelEntryCancel = this.handleQuestionCancelEntryCancel.bind(this)

    this.handleCancelLoverEntryClose = this.handleCancelLoverEntryClose.bind(this)
    this.handleQuestionCancelLoverEntryOpen = this.handleQuestionCancelLoverEntryOpen.bind(this)
    this.handleQuestionCancelLoverEntryOk = this.handleQuestionCancelLoverEntryOk.bind(this)
    this.handleQuestionCancelLoverEntryCancel = this.handleQuestionCancelLoverEntryCancel.bind(this)

    this._isMounted = false

    this.state = {
      openEntryRejectDialog: false,
      questionOpen: false,
      confirmOpen: false,
      reasonOpen: false,
      cancelOpen: false,
      questionCancelEntryOpen: false,
      cancelLoverEntryOpen: false,
      questionCancelLoverEntryOpen: false,
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // お付き合い申請可能判定
  canApplyLover () {
    let beFriend = (this.props.friend.type === 'friend')
    let firstDated = this.props.friend.first_dated
    let canApplying =  (this.getLoverApplyingCount() === 0)
    return beFriend && firstDated && canApplying
  }

  goNext (screen) {
    if (screen === 'RejectionFriendEntry') {
      this.setState({ openEntryRejectDialog: true })
    } else if (screen === 'RejectionLoverEntry') {
      this.handleQuestionOpen()
    } else {
      this.props.setScreen(screen)
    }
  }

  // 友達申請中の数
  getFriendApplyingCount () {
    let count = 0
    for (let friend of this.props.friends) {
      if (friend.type === 'friend_applying') {
        count += 1
      }
    }
    return count
  }

  // 成立済み友達数
  getFriendCount () {
    return this.props.friends.filter(friend => {
      if (friend.type.startsWith('lover')) { return true }
      if (friend.type === 'friend') { return true }
      return false
    }).length
  }

  // お付き合い申請中の数
  getLoverApplyingCount () {
    return this.props.friends.filter(friend => {
      if (friend.type === 'lover_applying') { return true }
      return false
    }).length
  }

  handleQuestionOpen () {
    this.setState({ questionOpen: true })
  }

  handleQuestionCancel () {
    this.setState({ questionOpen: false })
  }

  async handleQuestionOk () {
    let entryType = null
    if (this.props.friend.type === 'lover_receiving') {
      entryType = 'reject_lover'
    } else if (this.props.friend.type === 'friend_pending') {
      entryType = 'cancel_pending_friend'
    } else {
      return
    }

    this.setState({ questionOpen: false })
    this.props.setLoading(true)
    try {
      await this.postEntry(this.props.friend.id, entryType)
      this.setStateIfMounted({ confirmOpen: true })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleReasonOpen () {
    this.setState({ reasonOpen: true })
  }

  handleReasonClose () {
    this.setState({ reasonOpen: false })
  }

  handleReasonSend () {
    this.setState({ reasonOpen: false })
    this.setState({ cancelOpen: true })
  }

  handleCancelClose () {
    this.setState({ cancelOpen: false })
    this.props.setScreen('/Home')
  }

  handleConfirmClose () {
    this.setState({ reasonOpen: false })
    this.props.setScreen('/Home')
  }

  async handleQuestionCancelEntryOk () {
    this.props.setLoading(true)
    try {
      await this.cancelEntries(this.props.friend.id)
      this.showRequestSuccessMessage('「いいね！」を取り消しました')
      this.props.setScreen('/Home')
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleQuestionCancelEntryOpen () {
    this.setState({ questionCancelEntryOpen: true })
  }

  handleQuestionCancelEntryCancel () {
    this.setState({ questionCancelEntryOpen: false })
  }

  async handleQuestionCancelLoverEntryOk () {
    this.props.setLoading(true)
    try {
      await this.cancelEntries(this.props.friend.id)
      this.setStateIfMounted({ cancelLoverEntryOpen: true })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleCancelLoverEntryClose () {
    this.setState({ cancelLoverEntryOpen: false })
    this.props.setScreen('/Home')
  }

  handleQuestionCancelLoverEntryOpen () {
    this.setState({ questionCancelLoverEntryOpen: true })
  }

  handleQuestionCancelLoverEntryCancel () {
    this.setState({ questionCancelLoverEntryOpen: false })
  }

  render () {
    if (!this.props.friend) { return null }

    const { classes, ...other } = this.props
    const usingStandard = this.isUsingStandardPlan()
    const canMakeFriend = (0 < this.getRestFriendCount())
    return (
      <div className={classes.root}>
        <ConfirmDialog
          open={this.state.questionOpen}
          onClose={this.handleQuestionCancel}
          onOk={this.handleQuestionOk}
          onCancel={this.handleQuestionCancel}
          question="yes" title="お断り" message="削除します。よろしいですか？" />
        <EntryRejectDialog
          {...this.props}
          open={this.state.openEntryRejectDialog}
          onClose={() => this.setState({ openEntryRejectDialog: false })}
        />
        <ConfirmDialog
          open={this.state.confirmOpen}
          onClose={this.handleConfirmClose}
          onOk={this.handleConfirmClose}
          onCancel={this.handleConfirmClose}
          confirm="yes" title="お断り" message="削除しました。" />
        <ConfirmDialog
          open={this.state.cancelOpen}
          onClose={this.handleCancelClose}
          onOk={this.handleCancelClose}
          onCancel={this.handleCancelClose}
          confirm="yes" title="友達解消" message="友達解消しました。" />
        <ImportantConfirmDialog
          open={this.state.questionCancelEntryOpen}
          onClose={this.handleQuestionCancelEntryCancel}
          onOk={this.handleQuestionCancelEntryOk}
          onCancel={this.handleQuestionCancelEntryCancel}
          question="yes"
          title="「いいね！」を取り消しますか？"
        />
        <ConfirmDialog
          open={this.state.questionCancelLoverEntryOpen}
          onClose={this.handleQuestionCancelLoverEntryCancel}
          onOk={this.handleQuestionCancelLoverEntryOk}
          onCancel={this.handleQuestionCancelLoverEntryCancel}
          question="yes" title="お付き合いリクエストキャンセル" message="お付き合いリクエストをキャンセルします。よろしいですか？" />
        <ConfirmDialog
          open={this.state.cancelLoverEntryOpen}
          onClose={this.handleCancelLoverEntryClose}
          onOk={this.handleCancelLoverEntryClose}
          onCancel={this.handleCancelLoverEntryClose}
          confirm="yes" title="お付き合いリクエストキャンセル" message="お付き合いリクエストをキャンセルしました。" />
        <ReasonDialog
          {...other}
          open={this.state.reasonOpen}
          onClose={this.handleReasonClose}
          onSend={this.handleReasonSend}
        />
        <Grid container direction="column" alignItems="center" className={classes.buttonsContainer}>
          {this.props.friend.type === 'daily' && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.entryButton}
              onClick={() => this.props.sendEntries()}
              disabled={this.props.disabled}
              classes={{ disabled: classes.disabledButton }}
            >
              <span>「いいね！」を送る</span>
            </Button>
          )}
          {this.canApplyLover() && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.entryButton}
              onClick={this.goNext.bind(this, 'LoverEntry')}
            >
              <span>お付き合いリクエストする</span>
            </Button>
          )}
          {this.props.friend.type === 'friend' && (
            <Button
              className={classes.linkButton}
              onClick={this.handleReasonOpen}
            >
              友達解消
            </Button>
          )}
          {this.props.friend.type === 'friend_receiving' && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.entryButton}
                onClick={() => this.props.sendEntries()}
                disabled={this.props.disabled}
              >
                トークを開始する
              </Button>
              <Button
                className={classes.linkButton}
                onClick={this.goNext.bind(this, 'RejectionFriendEntry')}
              >
                お断りする
              </Button>
            </>
          )}
          {this.props.friend.type === 'friend_pending' &&  (
            <>
              {canMakeFriend && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.entryButton}
                  onClick={() => this.startTalk()}
                >
                  トークを開始する
                </Button>
              )}
              {(!canMakeFriend && usingStandard) && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.entryButton}
                  onClick={() => this.props.setScreen('Friends')}
                >
                  トーク一覧へ
                </Button>
              )}
              {(!canMakeFriend && !usingStandard) && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.entryButton}
                  onClick={() => window.open(Config.membersSiteUrl)}
                >
                  メンバーズサイトへ
                </Button>
              )}
              <Button
                className={classes.linkButton}
                onClick={this.goNext.bind(this, 'RejectionFriendEntry')}
              >
                お断りする
              </Button>
            </>
          )}
          {this.props.friend.type === 'lover_receiving' &&  (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.entryButton}
                onClick={this.goNext.bind(this, 'ApprovalLoverEntry')}
              >
                <span>お付き合いする</span>
              </Button>
              <Button
                className={classes.linkButton}
                onClick={this.goNext.bind(this, 'RejectionLoverEntry')}
              >
                お断りする
              </Button>
            </>
          )}
          {this.props.friend.type === 'friend_applying' &&  (
            <Button
              variant="contained"
              color="secondary"
              className={classes.entryButton}
              onClick={this.handleQuestionCancelEntryOpen}
            >
              <span>「いいね！」取り消し</span>
            </Button>
          )}
          {this.props.friend.type === 'lover_applying' &&  (
            <Button
              variant="contained"
              color="secondary"
              className={classes.entryButton}
              onClick={this.handleQuestionCancelLoverEntryOpen}
            >
              <span>リクエスト取り消し</span>
            </Button>
          )}
        </Grid>
      </div>
    )
  }
}

EntryButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  friends: PropTypes.array.isRequired,
  setScreen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sendEntries: PropTypes.func.isRequired,
}

export default withStyles(styles)(EntryButtons)
