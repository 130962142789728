import React, { useState, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
// https://github.com/cookpete/react-player
import ReactPlayer from 'react-player'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Api from 'commons/api'
import Storage from 'commons/storage'
import { findSetOrientationFunc } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(() => ({
  contents: {
    width: '100%',
    height: '100%',
    background: '#000000',
  },
  backButton: {
    color: '#ffffff',
    position: 'fixed',
    zIndex: 99,
  },
  playButton: {
    width: 64,
    height: 64,
    color: '#ffffff',
    background: 'radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  playIcon: {
    fontSize: 30,
  },
}))

export default function TheaterRoom (props) {
  const classes = useStyles()
  const movieId = Storage.MovieEventId.value
  const videoUrl = Storage.VideoURL.value
  const setOrientation = findSetOrientationFunc()
  const [playing, setPlaying] = useState(false)
  const [playedSeconds, setPlayedSeconds] = useState(0)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  useLayoutEffect(() => {
    if (!movieId || !videoUrl) { BaseComponent.goBack(props) }

    setOrientation?.('landscape')

    return () => {
      setOrientation?.('portrait')
      Storage.MovieEventId.clear()
      Storage.VideoURL.clear()
    }
  }, [])

  const leavePage = () => {
    BaseComponent.gaEvent('シアターイベント', 'video_stop', movieId, playedSeconds)
    BaseComponent.goBack(props)
  }

  const play = () => {
    setPlaying(true)
  }

  const pause = () => {
    setPlaying(false)
  }

  const onStart = () => {
    BaseComponent.gaEvent('シアターイベント', 'video_play', movieId)
  }

  const onProgress = ({ playedSeconds }) => {
    const s = Math.round(playedSeconds)
    setPlayedSeconds(s)
  }

  const onEnded = async () => {
    props.setLoading(true)
    try {
      const params = { watched: true }
      await Api.updateMovieSurveys(movieId, params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      leavePage()
    }
  }

  if (!movieId || !videoUrl) { return null }

  return (
    <div className={classes.contents}>
      <IconButton className={classes.backButton} onClick={leavePage} >
        <LeftIcon />
      </IconButton>
      {!playing && (
        <IconButton
          className={classes.playButton}
          classes={{ label: classes.playIcon }}
          onClick={play}
        >
          <PlayArrowIcon style={{ fontSize: 58 }} />
        </IconButton>
      )}
      {(movieId && videoUrl) && (
        <div onClick={() => setPlaying(!playing)}>
          <ReactPlayer
            // controls
            width={window.innerWidth}
            height={window.innerHeight}
            url={videoUrl}
            playing={playing}
            onReady={play}
            onStart={onStart}
            onProgress={onProgress}
            onEnded={onEnded}
            onError={pause}
            playsinline
          />
        </div>
      )}
    </div>
  )
}

TheaterRoom.propTypes = {
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
