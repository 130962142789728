import React, { useState } from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { getNewMessageCount, getNewSecretMessageCount, calculateRemainingDays } from 'commons/utility'
import CloseConfirmDialog from 'components/notifications/CloseConfirmDialog'
import EstablishFriendDialog from 'components/notifications/EstablishFriendDialog'
import FriendCountExceededDialog from 'components/notifications/FriendCountExceededDialog'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoFilter from 'components/parts/PhotoFilter'
import 'react-circular-progressbar/dist/styles.css'
import KeyIcon from 'images/Icon_key_solid.png'

const circleStyle = (theme, background, sizeFactor) => ({
  width: theme.spacing(sizeFactor),
  height: theme.spacing(sizeFactor),
  background,
  textAlign: 'center',
  borderRadius: '50%',
})

const nameStyle = () => ({
  fontWeight: 'bold',
  overflow: 'hidden',
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#EFF0FF',
    borderBottomStyle: 'solid',
    position: 'relative',
  },
  container: {

  },
  blur: {
    filter: 'blur(1px)',
  },
  faceAvatar: {
    width: 56,
    height: 56,
  },
  name: {
    ...nameStyle(),
    fontSize: 12,
    marginTop: theme.spacing(0.875),
    paddingLeft: theme.spacing(1),
  },
  recentMessage: {
    fontSize: 10,
    width: '100%',
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell: {
    borderStyle: 'none',
  },
  nameContainer: {
    width: '100%',
  },
  favContainer: {
    fontSize: 11,
    fontWeight: 900,
    color: '#589BFB',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.875),
    marginLeft: theme.spacing(1),
  },
  itemContainer: {
    width: '100%',
    flexGrow: 1,
  },
  rightContainer: {
    width: 'calc(100% - 136px)',
  },
  applyCircle: {
    ...circleStyle(theme, '#6666ff', 9),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  whiteCircle: circleStyle(theme, '#FFF', 8),
  emptyCircle: {
    ...circleStyle(theme, '#FFF', 9.375),
    marginLeft: theme.spacing(0.5625),
  },
  applyText: {
    fontSize: 10,
    color: '#6666ff',
  },
  textMargin: {
    marginLeft: theme.spacing(1),
  },
  dateContainer: {
    width: 44,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.875),
  },
  date: {
    fontSize: 8,
  },
  favBar: {
    height: 5,
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
    marginLeft: theme.spacing(0.625),
  },
  badge: {
    top: 8,
    right: 8,
  },
  secretBadge: {
    top: 48,
    right: 8,
  },
  unread: {
    color: '#F55056',
    fontWeight: 700,
  },
  secret: {
    ...theme.styles.highlightColor,
    fontWeight: 700,
  },
  noreply: {
    color: '#423BC7',
    fontWeight: 700,
  },
  icon: {
    position: 'absolute',
    bottom: 36,
    right: 34,
  },
  remain: {
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 600,
    position: 'absolute',
    bottom: 13,
    right: 8,
    lineHeight: 1,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(1.75),
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    ...theme.styles.remainBackgroundColor,
    borderRadius: 11,
  },
}))

export default function FriendCellTalk (props) {
  const { user, friends, friend, subscription, naviFocused, deleteFriend } = props
  const classes = useStyles()
  const [openCloseConfirmDialog, setOpenCloseConfirmDialog] = useState(false)
  const [openEstablishFriendDialog, setOpenEstablishFriendDialog] = useState(false)
  const [openFriendCountExceededDialog, setOpenFriendCountExceededDialog] = useState(false)

  if (!friend) { return }

  const favPoint = friend.fav_point_to_me < 20 ? 20 : friend.fav_point_to_me

  const clickFriend = () => {
    if (naviFocused) { BaseComponent.gaEvent('初回ナビ', 'click', 'トークルーム案内') }
    props.setFriend(friend)
    const messageCount = getNewMessageCount(friend)
    const secretMessageCount = getNewSecretMessageCount(friend)
    if (friend.type === 'closed' && (messageCount + secretMessageCount) > 0) {
      props.setTutorialView(null)
      setOpenCloseConfirmDialog(true)
    } else if (friend.type === 'friend_pending') {
      checkFriendCount()
    } else {
      toChatRoom()
    }
  }

  const checkFriendCount = async () => {
    props.setLoading(true)
    try {
      const subscription = await BaseComponent.loadSubscription(props)
      if (!subscription) { return }
      const maxFriendCount = BaseComponent.getMaxFriendCount(subscription)
      const nowFriendCount = BaseComponent.getNowFriendCount(friends)
      if (maxFriendCount <= nowFriendCount) {
        props.setTutorialView(null)
        setOpenFriendCountExceededDialog(true)
      } else {
        props.setTutorialView(null)
        setOpenEstablishFriendDialog(true)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const toChatRoom = async () => {
    if (getNewMessageCount(friend) === 0 && getNewSecretMessageCount(friend) > 0) {
      if (!isNullOrUndefined(friend.secret_message)) {
        try {
          await BaseComponent.loadFriendDetailWithId(props, friend.id)
          props.setNewMessage(friend.secret_message)
        } catch (error) {
          BaseComponent.handleApiError(props, error)
        }
      }
    }
    props.setScreen('Chat')
  }

  const closeChatRoom = () => {
    setOpenCloseConfirmDialog(false)
    deleteFriend()
  }

  const createAvatar = () => {
    const messageCount = getNewMessageCount(friend)
    const secretMessageCount = getNewSecretMessageCount(friend)
    const icon = friend.type === 'closed' ? null : friend.photo_icon
    return (
      <Badge
        classes={{ badge: classes.secretBadge }}
        color="secondary"
        badgeContent={secretMessageCount}
        invisible={secretMessageCount === 0}
        overlap="rectangular"
      >
        <Badge
          classes={{ badge: classes.badge }}
          badgeContent={messageCount}
          invisible={messageCount === 0}
          overlap="rectangular"
        >
          <PhotoFilter brightness={friend.photo_filter_brightness}>
            <Avatar src={icon} className={classes.faceAvatar} data-testid="cell-icon" />
          </PhotoFilter>
        </Badge>
      </Badge>
    )
  }

  const getDateText = (date) => {
    if (isNullOrUndefined(date)) { return }
    const dt = new Date(date)
    const today = new Date()
    const year = dt.getFullYear()
    const month = dt.getMonth() + 1
    const day = dt.getDate()
    const weekChars = ['日', '月', '火', '水', '木', '金', '土']
    if (year === today.getFullYear()) {
      return month + '/' + day + '(' + weekChars[dt.getDay()] + ')'
    } else {
      return year + '/' + month + '/' + day + '(' + weekChars[dt.getDay()] + ')'
    }
  }

  const createRecentMessageTag = () => {
    if (getNewMessageCount(friend) > 0) {
      return <span className={classes.unread}>未読　</span>
    } else if (getNewSecretMessageCount(friend) > 0) {
      return <span className={classes.secret}>秘密の質問コーナー　</span>
    } else if (friend.last_messag_from && friend.last_messag_from !== user.id) {
      return <span className={classes.noreply}>未返信　</span>
    }
  }

  const createRecentMessage = () => {
    if (getNewMessageCount(friend) === 0 && getNewSecretMessageCount(friend) > 0) {
      return friend.secret_title
    } else {
      return friend.recent_message
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          className={`${friend.type === 'friend_pending' && classes.blur}`}
          container
          alignItems="flex-start"
          justifyContent="space-between"
          onClick={clickFriend}
        >
          {friend.type === 'lover_applying' ? (
            <Grid className={classes.applyCircle} container justifyContent="center" alignItems="center">
              <Grid className={classes.whiteCircle} container justifyContent="center" alignItems="center">
                {createAvatar()}
              </Grid>
            </Grid>
          ) : (
            <Grid className={classes.emptyCircle} container justifyContent="center" alignItems="center">
              {createAvatar()}
            </Grid>
          )}
          <Grid className={classes.rightContainer} container direction="row" justifyContent="flex-start">
            <Grid item className={classes.itemContainer}>
              {friend.type === 'lover_applying' && (
                <Typography className={classes.applyText + ' ' + classes.textMargin}>
                  お付き合いリクエスト送信中
                </Typography>
              )}
              <Grid
                className={classes.nameContainer}
                container
                direction="column"
                alignItems="flex-start"
              >
                <Typography className={classes.name} data-testid="cell-name">
                  {friend.nick_name}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.recentMessage} data-testid="cell-msg">
              {createRecentMessageTag()}
              {createRecentMessage()}
            </Typography>
            {(favPoint !== 0 && friend.type !== 'ai') && (
              <Grid
                container
                wrap="nowrap"
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.favContainer}
                data-testid="cell-fav-bar"
              >
                ♡<span className={classes.favBar} style={{ width: `${favPoint}%` }} />
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.dateContainer}>
            <Typography className={classes.date} data-testid="cell-date">
              {getDateText(props.sortType === 'new_exclude_ai' ? friend.user_chat_updated_at : friend.chat_updated_at)}
            </Typography>
          </Grid>
        </Grid>
        {friend.type === 'friend_pending' && (
          <>
            <img className={classes.icon} src={KeyIcon} width={31} height={31} />
            <Typography className={classes.remain}>
              {calculateRemainingDays(friend?.pending_expired_at, 'friend_pending')}
            </Typography>
          </>
        )}
      </div>
      <CloseConfirmDialog
        open={openCloseConfirmDialog}
        friend={friend}
        onConfirm={toChatRoom}
        onClose={closeChatRoom}
      />
      <EstablishFriendDialog
        {...props}
        open={openEstablishFriendDialog}
        onClose={() => setOpenEstablishFriendDialog(false)}
        friend={friend}
      />
      <FriendCountExceededDialog
        {...props}
        open={openFriendCountExceededDialog}
        onClose={() => setOpenFriendCountExceededDialog(false)}
        friend={friend}
        subscription={subscription}
      />
    </>
  )
}

FriendCellTalk.propTypes = {
  user: PropTypes.object,
  friends: PropTypes.array,
  friend: PropTypes.object,
  subscription: PropTypes.object,
  sortType: PropTypes.string,
  naviFocused: PropTypes.bool,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
  setNewMessage: PropTypes.func,
  setLoading: PropTypes.func,
  deleteFriend: PropTypes.func,
  setTutorialView: PropTypes.func,
}
