import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

export default function PhotoFilter (props) {
  const { brightness, children, ...others } = props
  return (
    <Box
      style={{ filter: `brightness(${brightness || 100}%)` }}
      {...others}
    >
      {children}
    </Box>
  )
}

PhotoFilter.propTypes = {
  children: PropTypes.object,
  brightness: PropTypes.number,
}
