import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

export default function MessageDeleteConfirmDialog (props) {
  const { open, message, onOK, onCancel } = props

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle disableTypography>
          {message.message_type !== 'image' ? 'このメッセージを削除しますか？' : 'この画像を削除しますか？'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            相手のトーク画面からも削除されます。
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onOK}>
            削除する
          </Button>
          <Button variant="text" onClick={onCancel}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

MessageDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired
}
