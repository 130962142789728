import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import InterestedSelectDialog from 'components/interested-things/InterestedSelectDialog'

const useStyles = makeStyles(theme => ({
  // entry
  box: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    marginTop: theme.spacing(1.5),
  },
  label: {
    width: '100%',
    fontSize: 10.5,
    color: '#707070',
    lineHeight: 1,
    marginBottom: theme.spacing(1.75),
  },
  defaultText: {
    width: '100%',
    fontSize: 16,
    fontWeight: 600,
    color: '#B0B4FC',
    lineHeight: 1,
    marginBottom: theme.spacing(1.25),
  },
  note: {
    fontSize: 14,
    color: '#707070',
  },
  // app
  text: {
    fontSize: 16,
    fontWeight: 600,
    color: '#B0B4FC',
    lineHeight: '30px',
    letterSpacing: '0.4px',
    textAlign: 'center',
  },
  item: {
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.3px',
    color: '#423BC7',
    background: '#DBDCFE',
    borderRadius: 21,
    paddingTop: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}))

export default function InterestedThingsProfile (props) {
  const { user, selectedItems, setSelectedItems } = props
  const classes = useStyles()
  const [openInterestedSelectDialog, setOpenInterestedSelectDialog] = useState(false)
  const items = user?.interested_things || []

  if (Config.deployMode === 'entry') {
    const items = selectedItems || []
    return (
      <>
        <Grid className={classes.box} direction="column" container alignContent="flex-start" alignItems="center" onClick={() => setOpenInterestedSelectDialog(true)}>
          <Typography className={classes.label}>興味のあること</Typography>
          {items.length === 0 ? (
            <Typography className={classes.defaultText}>興味のあることは登録されていません。</Typography>
          ) : (
            <Grid container>
              {items.map(item => <div key={item} className={classes.item}>{item}</div>)}
            </Grid>
          )}
        </Grid>
        <Typography className={classes.note}>異性に表示される詳細プロフィールに表示されます。記載すると紹介精度・マッチング率が向上します。（最大10個）</Typography>
        <InterestedSelectDialog
          {...props}
          open={openInterestedSelectDialog}
          onClose={() => setOpenInterestedSelectDialog(false)}
          selectedItems={items}
          setSelectedItems={setSelectedItems}
        />
      </>
    )
  }

  return (
    <div>
      {items.length === 0 ? (
        <Typography className={classes.text}>
          興味のあることは<br />まだ登録されていません。
        </Typography>
      ) : (
        <Grid container>
          {items.map((item, i) => (
            <div key={i} className={classes.item}>{item}</div>
          ))}
        </Grid>
      )}
    </div>
  )
}

InterestedThingsProfile.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
}
