import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#707070',
    paddingBottom: theme.spacing(3.75),
  },
  item: {
    height: 46,
  },
  name: {
    fontSize: 13,
    fontWeight: 300,
    color: '#414141',
    margin: 0,
    textAlign: 'left'
  },
}))

export default function MatchingFilterCompany (props) {
  const { companies, open, selectedIds, save, onClose } = props
  const classes = useStyles()
  const [rejectIds, setRejectIds] = useState([])

  useEffect(() => {
    if (!open) { return }
    setRejectIds(selectedIds)
  }, [open])

  const addRejectList = (companyId) => {
    setRejectIds((prevState) => [...prevState, companyId])
  }

  const removeRejectList = (companyId) => {
    setRejectIds(rejectIds.filter((id) => id !== companyId))
  }

  const disabled = () => {
    if (companies.length === 0) { return true }
    if (rejectIds.length > 3) { return true }
    return false
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>
          {companies.length}件の検索結果
        </DialogTitle>
        <DialogContent>
          {companies.map((company) => (
            <Grid key={company.id} className={classes.item} container wrap="nowrap" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" className={classes.name}>
                {company.name}
              </Typography>
              <Checkbox
                className={classes.starCheckbox}
                onChange={() => rejectIds.includes(company.id) ? removeRejectList(company.id) : addRejectList(company.id)}
                checked={rejectIds.includes(company.id)}
                inputProps={{ 'data-testid': 'checkbox' }}
              />
            </Grid>
          ))}
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={() => save(rejectIds)} disabled={disabled()}>
            保存する
          </Button>
          <Button variant="text" onClick={onClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

MatchingFilterCompany.propTypes = {
  companies: PropTypes.array,
  open: PropTypes.bool,
  selectedIds: PropTypes.array,
  save: PropTypes.func,
  onClose: PropTypes.func,
}
