const categoryAndItem = {
  '趣味': [
    '最近ハマっていること',
    '休日の過ごし方',
    '継続している趣味',
  ],
  '仕事': [
    '子供の頃の夢と今の仕事',
    '今の仕事',
    '将来の展望',
  ],
  '性格': [
    '友人からよく言われること',
    '自分が思う性格',
    '特技',
  ],
  '会いたい異性': [
    'こんな異性と出会いたい',
    '築きたい家庭',
    'デートで行ってみたい場所',
  ],
  '好きなこと': [
    '言葉',
    '映画',
    '本・漫画',
    'スポーツ',
    '旅行',
    '音楽・楽器',
    '食べ物',
    '動物',
  ],
  '学生時代': [
    '学生時代の部活（運動部）',
    '学生時代の部活（文化部）',
    '学部・専門',
  ],
  '基本情報': [
    '出身',
    '家族構成',
    'ペット',
    'メッセージ頻度',
    '血液型',
  ],
}

// Inputが一つの項目リスト
const oneInputFieldContent = [
  '友人からよく言われること',
  '自分が思う性格',
  '特技',
  'こんな異性と出会いたい',
  '築きたい家庭',
  'デートで行ってみたい場所',
  '学生時代の部活（文化部）',
  '学部・専門',
  '家族構成',
  'ペット',
  '血液型'
]

// Inputが無い項目リスト
const zeroInputFieldContent = []

// 文章のテンプレートオブジェクト
// 1: 1つ目のTextFieldに置き換え
// 2: 2つ目のTextFieldに置き換え
// \n : marginを持つboxに置き換え
// 文字列: Typoでそのまま出力
const sentence = {
  '最近ハマっていること': {
    'casual': ['最近', 1, 'にハマっています！', '\n', 2, 'に興味があればお話しできると嬉しいなって思います！'],
    'serious': ['最近', 1, 'にハマっています。', '\n', 2, 'に興味があればお話しできると嬉しいです。'],
  },
  '休日の過ごし方': {
    'casual': ['休みの日はよく', 1, 'ます！', '\n', 2, '興味あればお話しできると嬉しいです！'],
    'serious': ['休みの日は', 1, 'して過ごすことが多いです。', '\n', 2, 'の話題もお話しできると嬉しいです。'],
  },
  '継続している趣味': {
    'casual': ['趣味は', 1, 'で、', '\n', 2, '年やってます！'],
    'serious': ['趣味は', 1, 'で、', '\n', 2, '年続けています。'],
  },
  '子供の頃の夢と今の仕事': {
    'casual': ['子供の頃の夢は', 1, 'で、', '\n', '今は', 2, 'の仕事をしてます！'],
    'serious': ['子供の頃の夢は', 1, 'で、', '\n', '今は', 2, 'の仕事をしてます。'],
  },
  '今の仕事': {
    'casual': ['現在', 1, 'の仕事をしています。', '\n', 2, '年この仕事をしてます！'],
    'serious': ['現在の仕事は', 1, 'です。', '\n', 2, '年この仕事をしてます！'],
  },
  '将来の展望': {
    'casual': ['現在', 1, 'の仕事をしています。', '\n', '将来は', 2, '目指したいです！'],
    'serious': ['現在の仕事は', 1, 'です。', '\n', '将来は', 2, '目指したいです！'],
  },
  '友人からよく言われること': {
    'casual': ['友人からはよく', 1, 'って言われます！'],
    'serious': ['友人からはよく', 1, 'って言われることが多いです。'],
  },
  '自分が思う性格': {
    'casual': ['自分では', 1, '性格だと思ってます！'],
    'serious': ['自分では', 1, '性格だと思ってます。'],
  },
  '特技': {
    'casual': ['特技は', 1, 'かな。'],
    'serious': ['特技は', 1, 'です。'],
  },
  'こんな異性と出会いたい': {
    'casual': [1, 'なヒトとご縁があれば嬉しいです！！'],
    'serious': [1, 'なヒトと出会えると嬉しいです。'],
  },
  '築きたい家庭': {
    'casual': ['将来は', 1, '家庭を築きたいです！'],
    'serious': ['将来は', 1, '家庭を築きたいです。'],
  },
  'デートで行ってみたい場所': {
    'casual': [1, 'デート憧れてます（笑）'],
    'serious': ['ご縁があれば、', 1, 'にも行ってみたいです。'],
  },
  '言葉': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '映画': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '本・漫画': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  'スポーツ': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '旅行': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '音楽・楽器': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '食べ物': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '動物': {
    'casual': [1, 'が好きで、', '\n', '特に', 2, 'が好きです！'],
    'serious': [1, 'が好きで、', '\n', '特に', 2, 'が好きです。'],
  },
  '学生時代の部活（運動部）': {
    'casual': ['学生時代は', 1, '部でした。', '\n', 'ポジションは', 2, 'です。'],
    'serious': ['学生時代は', 1, '部でした。', '\n', 'ポジションは', 2, 'でした。'],
  },
  '学生時代の部活（文化部）': {
    'casual': ['学生時代は', 1, '部でした！'],
    'serious': ['学生時代は', 1, '部でした。'],
  },
  '学部・専門': {
    'casual': ['学生時代は', 1, 'を勉強していました！'],
    'serious': ['学生時代は', 1, 'を勉強していました。'],
  },
  '出身': {
    'casual': ['出身は', 1, 'で、', '\n', '今は', 2, 'に住んでます！'],
    'serious': ['出身は', 1, 'で、', '\n', '今は', 2, 'に住んでいます。'],
  },
  '家族構成': {
    'casual': ['家族構成は', 1, 'です！'],
    'serious': ['家族構成は', 1, 'です。'],
  },
  'ペット': {
    'casual': [1, 'と住んでます！'],
    'serious': [1, 'と一緒に暮らしてます。'],
  },
  'メッセージ頻度': {
    'casual': ['普段メッセージは', 1, '日に', 2, '回のペースで送ることが多いです！'],
    'serious': ['普段メッセージは', 1, '日に', 2, '回のペースで送ることが多いです。'],
  },
  '血液型': {
    'casual': ['血液型は', 1, 'です。'],
    'serious': ['血液型は', 1, 'です。'],
  },
}

export default {
  categoryAndItem,
  oneInputFieldContent,
  zeroInputFieldContent,
  sentence,
}