import React from 'react'
import Api from 'commons/api'
import * as storage from 'commons/storage'
import DisableCookieScreen from 'components/DisableCookieScreen'
import MembersPage from 'components/members/MembersPage'
import BaseComponent from 'components/parts/BaseComponent'
export default class MembersHome extends BaseComponent {
  state = {
    plan: null,
    payment: null,
    organization: null,
    freeTerm: null,
    campMaster: null,
    myPref: null,
    monthlyFees: null,
    specialOffers: null,
  }
  componentDidMount () {
    this.addVisitPageLog()
    if (this.props.user.identification_status === 'checked') {
      this.loadData()
    }
  }
  componentWillUnmount () {
    this.addLeavePageLog()
  }

  async loadData () {
    this.props.setLoading(true)
    try {
      const plan = await Api.getMyPlan()
      const payment = await Api.getMyPayment()
      const organization = await Api.getMyOrganization()
      const freeTerm = await Api.getFreeTerm(organization.id)
      const billings = await Api.getMyBillings()
      const params = { campaign_type: 'plan_ticket', organization_id: organization.id }
      const campMaster = await Api.getCampaignMaster(params)
      const myPref = await Api.getMyPref()
      const monthlyFees = await Api.getMonthlyFees()
      const nextMonthlyFees = await Api.getNextMonthlyFees()
      const specialOffers = await Api.getSpecialOffers()
      this.setState({
        plan,
        payment,
        organization,
        freeTerm,
        billings,
        campMaster,
        myPref,
        monthlyFees,
        nextMonthlyFees,
        specialOffers,
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    if (!storage.default.enabled) {
      return <DisableCookieScreen />
    }

    return (
      <MembersPage
        {...this.props}
        {...this.state}
        logout={() => this.logout()}
      />
    )
  }
}
