import React from 'react'
import path from 'path'
import { isNullOrUndefined } from 'util'
import Badge from '@material-ui/core/Badge'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { withStyles } from '@material-ui/core/styles'
import Api from 'commons/api'
import Const from 'commons/constant'
import CampaignOffIcon from 'components/icons/CampaignOffIcon'
import CampaignOnIcon from 'components/icons/CampaignOnIcon'
import FemaleOffIcon from 'components/icons/FemaleOffIcon'
import FemaleOnIcon from 'components/icons/FemaleOnIcon'
import MaleOffIcon from 'components/icons/MaleOffIcon'
import MaleOnIcon from 'components/icons/MaleOnIcon'
import RequestOffIcon from 'components/icons/RequestOffIcon'
import RequestOnIcon from 'components/icons/RequestOnIcon'
import TalkOffIcon from 'components/icons/TalkOffIcon'
import TalkOnIcon from 'components/icons/TalkOnIcon'
import BaseComponent from 'components/parts/BaseComponent'

const styles = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 99,
    '-webkit-user-select': 'none',
  },
  badge: {},
  button: {
    minWidth: 64,
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export class AppBottomNavigation extends BaseComponent {

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this._isMounted = false
    let friends = this.props.friends ? JSON.parse(JSON.stringify(this.props.friends)) : null
    let matchings = this.props.matchings ? JSON.parse(JSON.stringify(this.props.matchings)) : null
    this.state = {
      newMessageCount: 0,
      newFriendsCount: 0,
      newRequestsCount: 0,
      isLover: false,
      select: 0,
      friends: friends,
      matchings: matchings,
      campaignMaster: null,
    }
  }

  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(this.state.friends) !== JSON.stringify(nextProps.friends)) {
      this.graduationConfirmation(nextProps.friends)
      this.initNewMessageCount(nextProps.friends, nextProps.matchings)
    }
    if (JSON.stringify(this.state.matchings) !== JSON.stringify(nextProps.matchings)) {
      this.initNewMessageCount(nextProps.friends, nextProps.matchings)
    }
    if (this.props.screen !== nextProps.screen) {
      this.setSelectTab(nextProps.screen)
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.getCampaignMaster()
    this.graduationConfirmation(this.props.friends)
    this.setSelectTab(this.props.screen)
    this.initNewMessageCount(this.props.friends, this.props.matchings)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async initNewMessageCount (friends, matchings) {
    if (isNullOrUndefined(friends)) return
    // 新着メッセージ件数
    let counts = friends.map(friend => friend.new_message_count + friend.new_secret_message_count)
    let total = counts.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    // 友達新着件数
    let matchingUserIds = null
    if (matchings && matchings.length > 0) {
      matchingUserIds = matchings.map(matching => matching.target_user_id)
      let included = friends.some(friend => matchingUserIds.includes(friend.id))
      if (!included) {
        friends = await this.loadFriends()
      }
    }
    let newFriendsCount = friends.filter(friend => {
      if (friend.type === 'daily') {
        if (isNullOrUndefined(friend.daily_expired_at)) return false
        let today = new Date()
        let expiredDay = new Date(friend.daily_expired_at)
        if (today > expiredDay) return false
        if (matchingUserIds && matchingUserIds.includes(friend.id)) {
          return !friend.profile_displayed
        } else {
          return false
        }
      } else {
        return false
      }
    }).length
    let newRequestsCount = friends.filter(friend => {
      if (friend.type === 'friend_receiving' || friend.type === 'lover_receiving') {
        return !friend.profile_displayed
      } else {
        return false
      }
    }).length
    this.setStateIfMounted({
      friends: JSON.parse(JSON.stringify(friends)),
      matchings: matchings ? JSON.parse(JSON.stringify(matchings)) : null,
      newMessageCount: total,
      newFriendsCount: newFriendsCount,
      newRequestsCount: newRequestsCount,
    })
  }

  graduationConfirmation (friends) {
    if (isNullOrUndefined(friends)) return
    let isLover = this.isGraduated(friends)
    this.setStateIfMounted({isLover: isLover})
  }

  isGraduated (friends) {
    return friends.some(friend => (friend.type === 'lover'))
  }

  canShowCampaign () {
    const { user, subscription, specialOffers } = this.props
    if (!user) { return false }
    if (!subscription) { return false }
    if (!specialOffers) { return false }
    // アプリ審査用アカウントを除く
    if (user.invitation_campaign_disabled) { return false }
    const campaigns = [...specialOffers, this.canInvitationCampaign()].filter(v => !!v)
    // キャンペーンがない場合は非表示
    if (campaigns.length === 0) { return false }
    return true
  }

  canInvitationCampaign () {
    const { subscription } = this.props
    // スタンダードプラン（全額企業払い）適用中ユーザーを除く
    if (subscription.content === Const.planTypes.STANDARD_ORG_PAID) { return false }
    // ベーシックプラン（全額企業払の特別フリープラン）適用中ユーザーを除く
    if (subscription.content === Const.planTypes.BASIC) { return false }
    // 特別フリープラン適用中ユーザーを除く
    if (subscription.content === Const.planTypes.SPECIAL_FREE) { return false }

    return true
  }

  async getCampaignMaster () {
    try {
      const master = await Api.getCampaignMaster({ campaign_type: 'invitation' })
      if (master) {
        this.setStateIfMounted({ campaignMaster: master })
      }
      return master
    } catch (error) {
      return null
    }
  }

  handleChange (event, value) {
    this.setState({ value })
    switch(value) {
      case 0:
        this.props.setScreen('Home')
        break
      case 1:
        this.props.setScreen('FriendRequest')
        break
      case 2:
        this.props.setScreen('Friends')
        break
      case 3:
        this.props.setScreen('Campaigns')
        break
      default:
        this.props.setScreen('Home')
    }
  }

  setSelectTab (screen) {
    let tab = 0
    switch(path.basename(screen)) {
      case 'Home':
        tab = 0
        break
      case 'FriendRequest':
        tab = 1
        break
      case 'Friends':
        tab = 2
        break
      case 'Campaigns':
        tab = 3
        break
      default:
        tab = 0
    }
    this.setStateIfMounted({ select: tab })
  }

  render () {
    const { classes, screen } = this.props
    if (!['Home', 'FriendRequest', 'Friends', 'Campaigns', 'Share'].includes(screen)) { return null }
    if (this.props.hideAppNavigation) { return null }
    if(this.state.isLover) { return null }
    return (
      <BottomNavigation
        value={this.state.select}
        onChange={this.handleChange}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          className={classes.button}
          label="紹介"
          icon={
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={this.state.newFriendsCount}
              invisible={this.state.newFriendsCount === 0}
              overlap="rectangular"
            >
              {this.props.user?.sex === 'female' ? (
                this.state.select === 0 ? <MaleOnIcon /> : <MaleOffIcon />
              ) : (
                this.state.select === 0 ? <FemaleOnIcon /> : <FemaleOffIcon />
              )}
            </Badge>
          }
          data-testid="tab-home"
        />
        <BottomNavigationAction
          className={classes.button}
          label="いいね！"
          icon={
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={this.state.newRequestsCount}
              invisible={this.state.newRequestsCount === 0}
              overlap="rectangular"
            >
              {this.state.select === 1 ? <RequestOnIcon /> : <RequestOffIcon />}
            </Badge>
          }
          data-testid="tab-requests"
        />
        <BottomNavigationAction
          className={classes.button}
          label="トーク"
          icon={
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={this.state.newMessageCount}
              invisible={this.state.newMessageCount === 0}
              overlap="rectangular"
            >
              {this.state.select === 2 ? <TalkOnIcon /> : <TalkOffIcon />}
            </Badge>
          }
          data-testid="tab-friends"
        />
        {this.canShowCampaign() && (
          <BottomNavigationAction
            className={classes.button}
            label="キャンペーン"
            icon={this.state.select === 3 ? <CampaignOnIcon /> : <CampaignOffIcon />}
            data-testid="tab-campaign"
          />
        )}
      </BottomNavigation>
    )
  }
}

export default withStyles(styles)(AppBottomNavigation)
