import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PaymentInput from './PaymentInput'

function PaymentInputDialog (props) {
  const { open, onCancel, onUpdate, ...others } = props

  return (
    <Dialog open={open} data-testid="payment-input-dialog">
      <DialogTitle>
        お支払い方法登録
      </DialogTitle>
      <DialogContent>
        <PaymentInput
          onUpdate={newPayment => onUpdate && onUpdate(newPayment)}
          {...others}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => onCancel && onCancel()}
        >
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  )
}
PaymentInputDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
}
export default PaymentInputDialog