import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SearchIcon from '@material-ui/icons/Search'
import Api from 'commons/api'
import MatchingFilterAddress from 'components/MatchingFilterAddress'
import MatchingFilterAge from 'components/MatchingFilterAge'
import MatchingFilterCompany from 'components/MatchingFilterCompany'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.styles.footer.height,
  },
  description: {
    width: '100%',
    height: 59.5,
    fontWeight: 600,
    color: '#1A1667',
    background: '#EFF0FF',
    borderTop: '1px solid #00000029',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    width: '100%',
    height: 59.5,
    borderTop: '1px solid #00000029',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  rejectItem: {
    width: '100%',
    height: 59.5,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  itemLabel: {
    width: 'fit-content',
    fontWeight: 300,
    color: '#414141',
    whiteSpace: 'pre-wrap',
  },
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(3),
    width: '100%',
  },
  formAddress: {
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  formSameOrg: {
    width: '100%',
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
  },
  selectContainer: {
    marginTop: theme.spacing(2),
  },
  selectbox: {
    '&:before': {
      content: 'none',
    },
    '&:after': {
      content: 'none',
    },
  },
  select: {
    fontSize: 14,
    fontWeight: 300,
    color: '#414141',
    paddingRight: `${theme.spacing(1)}px !important`,
    '&:focus': {
      backgroundColor: '#ffffff',
    }
  },
  sameOrgSelect: {
    textAlign: 'right',
  },
  arrowdown: {
    fontSize: 16,
    color: '#423BC7',
  },
  plus: {
    fontSize: 16,
  },
  iconButton: {
    color: '#423BC7',
    height: '100%',
    padding: 0,
  },
  iconDisabled: {
    color: 'rgb(157, 157, 158) !important',
  },
  searchbox: {
    width: 293,
    height: 43,
    border: 'solid 1px #999999',
    borderRadius: 10,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  searchIcon: {
    width: 15,
    height: 15,
    opacity: 0.4,
    position: 'absolute',
    top: '50%',
    left: 16,
    transform: 'translate(0%, -50%)',
    pointerEvents: 'none',
  },
  inputRoot: {
    width: '100%',
    height: '100%',
  },
  inputInput: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(5),
  },
}))

export default function MatchingFilter (props) {
  const { user, master, myOrganization, ...others } = props
  const classes = useStyles()
  // 希望する相手の将来の働き方
  const [futureWorkWayOfPartner, setFutureWorkWayOfPartner] = useState(user?.future_work_way_of_partner)
  // 相手に求める結婚歴
  const [acceptableHouseworkAndChildcare, setAcceptableHouseworkAndChildcare] = useState(user?.acceptable_housework_and_childcare)
  // 相手に求める結婚歴
  const [maritalStatusOfPartner, setMaritalStatusOfPartner] = useState(user?.marital_status_of_partner)
  // （将来）子供の有無・人数
  const [children, setChildren] = useState(user?.children)
  // 相手の喫煙に関して
  const [heterosexualSmoking, setHeterosexualSmoking] = useState(user?.heterosexual_smoking)
  // グループ会社のマッチング
  const [sameOrgMatching, setSameOrgMatching] = useState(user?.same_organization_matching || false)
  // その他の紹介しない企業（上限3社）
  const [rejectCompanyIds, setRejectCompanyIds] = useState([])
  const [rejectCompanies, setRejectCompanies] = useState([])
  const [openSearchBox, setOpenSearchBox] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [openSelectCompanyDialog, setOpenSelectCompanyDialog] = useState(false)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  useEffect(() => {
    if (!user) { return }
    getMatchngConfig()
  }, [user])

  const getMatchngConfig = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getMatchingConfig()
      setRejectCompanyIds(res.reject_company_ids || [])
      setRejectCompanies(res.reject_companies || [])
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const updateSetting = async (params) => {
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const updateMatchingConfig = async (params) => {
    props.setLoading(true)
    try {
      const res = await Api.updateMatchingConfig(params)
      setRejectCompanyIds(res.reject_company_ids || [])
      setRejectCompanies(res.reject_companies || [])
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeFutureWorkWayOfPartner = async (event) => {
    const value = event.target.value
    if (!value) { return }
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, { future_work_way_of_partner: value })
      setFutureWorkWayOfPartner(value)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeAcceptableHouseworkAndChildcare = async (event) => {
    const value = event.target.value
    if (!value) { return }
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, { acceptable_housework_and_childcare: value })
      setAcceptableHouseworkAndChildcare(value)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeMaritalStatusOfPartner = async (event) => {
    const value = event.target.value
    if (!value) { return }
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, { marital_status_of_partner: value })
      setMaritalStatusOfPartner(value)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeChildren = async (event) => {
    const value = event.target.value
    if (!value) { return }
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, { children: value })
      setChildren(value)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeHeterosexualSmoking = async (event) => {
    const value = event.target.value
    if (!value) { return }
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, { heterosexual_smoking: value })
      setHeterosexualSmoking(value)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeSameOrgMatching = (event) => {
    const value = event.target.value
    setSameOrgMatching(value)
    updateSetting({ same_organization_matching: value })
  }

  const createItem = (label, children) => (
    <Grid className={classes.item} container wrap="nowrap" justifyContent="space-between" alignItems="center">
      <Typography variant="body1" className={classes.itemLabel}>
        {label}
      </Typography>
      {children}
    </Grid>
  )

  const searchCompany = async () => {
    if (!searchWord) { return }
    props.setLoading(true)
    try {
      // 会社名検索
      const res = await Api.searchCompany(searchWord)
      setSearchResult(res)
      setOpenSelectCompanyDialog(true)
      setOpenSearchBox(false)
      setSearchWord('')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const createRejectItem = (company) => (
    <Grid key={company.id} className={classes.rejectItem} container wrap="nowrap" justifyContent="space-between" alignItems="center">
      <Typography variant="body1" className={classes.itemLabel}>
        {company?.name}
      </Typography>
      <IconButton className={classes.iconButton} onClick={() => deleteCompany(company.id)}>
        <DeleteIcon className={classes.arrowdown} />
      </IconButton>
    </Grid>
  )

  const deleteCompany = async (companyId) => {
    try {
      // rejectCompaniesから該当のレコードを消したIDのみの配列で更新
      const value = rejectCompanies.map((company) => {
        if (company.id !== companyId) { return company.id }
      }).filter(Boolean)
      await updateMatchingConfig({ reject_company_ids: value })
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const saveRejectCompanies = async (rejectIds) => {
    props.setLoading(true)
    try {
      // 引数に渡された配列で更新
      await updateMatchingConfig({ reject_company_ids: rejectIds })
      setOpenSelectCompanyDialog(false)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <AppHeader title="紹介条件の設定" hideHome {...others} />
      <Grid container justifyContent="center">
        <Typography variant="body1" className={classes.description}>
          優先したい出会いの設定
        </Typography>
        <MatchingFilterAge {...props} />
        <MatchingFilterAddress {...props} />
        {createItem('相手の将来の働き方', (
          <Select
            className={classes.selectbox}
            classes={{ select: classes.select }}
            value={futureWorkWayOfPartner}
            onChange={onChangeFutureWorkWayOfPartner}
            IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
            data-testid="MatchingFilter-future-work-way-of-partner"
          >
            {master?.profile.future_work_way_of_partner.detail.common.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        ))}
        {createItem('許容する自分が担う\n家事育児の役割', (
          <Select
            className={classes.selectbox}
            classes={{ select: classes.select }}
            value={acceptableHouseworkAndChildcare}
            onChange={onChangeAcceptableHouseworkAndChildcare}
            IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
            data-testid="MatchingFilter-acceptable-housework-and-childcare"
          >
            {master?.profile.acceptable_housework_and_childcare.detail.common.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        ))}
        {createItem('相手に求める結婚歴', (
          <Select
            className={classes.selectbox}
            classes={{ select: classes.select }}
            value={maritalStatusOfPartner}
            onChange={onChangeMaritalStatusOfPartner}
            IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
            data-testid="MatchingFilter-marital-status-of-partner"
          >
            {master?.profile.marital_status_of_partner.detail.common.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        ))}
        {createItem('将来の子供の\n有無・人数', (
          <Select
            className={classes.selectbox}
            classes={{ select: classes.select }}
            value={children}
            onChange={onChangeChildren}
            IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
            data-testid="MatchingFilter-children"
          >
            {master?.profile.children.detail.common.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        ))}
        {createItem('相手の喫煙に関して', (
          <Select
            className={classes.selectbox}
            classes={{ select: classes.select }}
            value={heterosexualSmoking}
            onChange={onChangeHeterosexualSmoking}
            IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
            data-testid="MatchingFilter-heterosexual-smoking"
            renderValue={() => {
              const item = master?.profile.heterosexual_smoking.detail.common.find(item => item.value === heterosexualSmoking)
              return item?.label.replace('非喫煙〜', '') || ''
            }}
          >
            {master?.profile.heterosexual_smoking.detail.common.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        ))}
        <Typography variant="body1" className={classes.description}>
          紹介を希望しない会社の設定
        </Typography>
        {!myOrganization?.same_organization_matching_disabled && createItem('グループ会社のマッチング', (
          <Select
            className={classes.selectbox}
            classes={{ select: classes.select }}
            value={sameOrgMatching}
            onChange={onChangeSameOrgMatching}
            IconComponent={() => <KeyboardArrowDown className={classes.arrowdown} />}
            data-testid="MatchingFilter-sameOrg"
          >
            <MenuItem value="yes">する</MenuItem>
            <MenuItem value="no">しない</MenuItem>
          </Select>
        ))}
        {createItem('その他の紹介しない企業（上限3社）', (
          <IconButton
            className={classes.iconButton}
            onClick={() => setOpenSearchBox(true)}
            data-testid="MatchingFilter-rejectComps"
            disabled={rejectCompanies.length === 3}
            classes={{ disabled: classes.iconDisabled }}
          >
            <AddIcon className={classes.plus} />
          </IconButton>
        ))}
        {openSearchBox && (
          <div className={classes.searchbox}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              placeholder="会社検索"
              disableUnderline
              classes={{ root: classes.inputRoot, input: classes.inputInput }}
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              inputProps={{
                onKeyDown: (e) => {if (e.keyCode === 13) { e.target.blur()}},
                onBlur: searchCompany,
                'data-testid': 'searchbox',
              }}
            />
          </div>
        )}
        {rejectCompanies.map((company) => createRejectItem(company))}
        {createItem('')}
      </Grid>
      <MatchingFilterCompany
        open={openSelectCompanyDialog}
        companies={searchResult}
        selectedIds={rejectCompanyIds}
        save={saveRejectCompanies}
        onClose={() => setOpenSelectCompanyDialog(false)}
      />
    </div>
  )
}

MatchingFilter.propTypes = {
  user: PropTypes.object,
  master: PropTypes.object,
  myOrganization: PropTypes.object,
  setLoading: PropTypes.func,
}
