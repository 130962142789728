import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Kagura01 from 'images/komanavi/kagura01.png'
import Kagura02 from 'images/komanavi/kagura02.png'
import Kagura03 from 'images/komanavi/kagura03.png'
import Kagura04 from 'images/komanavi/kagura04.png'
import Sakaki01 from 'images/komanavi/sakaki01.png'
import Sakaki02 from 'images/komanavi/sakaki02.png'
import Sakaki03 from 'images/komanavi/sakaki03.png'
import Sakaki04 from 'images/komanavi/sakaki04.png'

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: fr => fr ? '#DBDCFE' : '#423BC7',
    border: fr => fr ? '3px solid #423CC7' : '3px solid #616AFA',
    borderRadius: '10px 10px 13px 13px',
    boxShadow: '0px 1px 8px #00000033',
    '& #friendCard': {
      boxShadow: fr => fr ? '0px 1px 8px #00000033' : 'none',
    }
  },
  komanavi: {
    height: 70,
    position: 'relative',
  },
  kagura: {
    width: 64,
    objectFit: 'cover',
    objectPosition: '50% 0',
    position: 'absolute',
    top: fr => fr ? 26 : 22,
    left: 8,
  },
  sakaki: {
    width: 64,
    objectFit: 'cover',
    objectPosition: '50% 0',
    position: 'absolute',
    top: fr => fr ? 26 : 30,
    right: 8,
  },
  text: {
    fontSize: 12,
    maxWidth: 192,
    color: fr => fr ? '#423BC7' : '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}))

export default function KomaNavi (props) {
  const { friend, index, children, friendRequestPage } = props
  const classes = useStyles(Boolean(friendRequestPage))
  if (!friend) { return children }
  const defaultText = friend.type === 'friend_receiving' ? '' : '今日の紹介\nプロフィールを要チェック！'
  const message = friend?.recommend_message || defaultText
  const navis = document.querySelectorAll('[id^="komanavi-"]')

  // 何番目にKomanaviかによって表示する狛犬の画像を判定
  const getKomainuImg = () => {
    const i = Array.from(navis).map(n => n.id).indexOf(`komanavi-${index}`)
    const imgs = [Kagura02, Sakaki02, Kagura03, Sakaki03, Kagura04, Sakaki04]
    return imgs[i > 6 ? i - 6 : i]
  }

  const createKagura = () => {
    if (friend?.type === 'friend_receiving') {
      const img = getKomainuImg()
      if (!img || !img.includes('kagura')) { return null }
      return <img src={img} className={classes.kagura} />
    } else {
      return <img src={Kagura01} className={classes.kagura} />
    }
  }

  const createSakaki = () => {
    if (friend?.type === 'friend_receiving') {
      const img = getKomainuImg()
      if (!img || !img.includes('sakaki')) { return null }
      return <img src={img} className={classes.sakaki} />
    } else {
      return <img src={Sakaki01} className={classes.sakaki} />
    }
  }

  if (!friendRequestPage && index !== 0) {
    return children
  }

  if (message) {
    return (
      <Grid id={`komanavi-${index}`} className={classes.container} data-testid='komanavi'>
        <Grid className={classes.komanavi} container justifyContent="center" alignItems="center">
          {createKagura()}
          <div className={classes.text}><span>{friend?.recommend_message || defaultText}</span></div>
          {createSakaki()}
        </Grid>
        {children}
      </Grid>
    )
  }

  return children
}

KomaNavi.propTypes = {
  friend: PropTypes.object,
  index: PropTypes.number,
  children: PropTypes.object,
  friendRequestPage: PropTypes.bool,
}
