import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import FriendCell from 'components/parts/FriendCell.jsx'
import FriendCellApplying from 'components/parts/FriendCellApplying.jsx'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import PlanTermCheck from 'components/plan/PlanTermCheck'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height + 32,
  },
  emptyComment: {
    opacity: 0.34,
  },
  table: {
    overflow: 'auto',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  emptyTable: {
    marginTop: 10,
    height: 80,
  },
  centerBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  slide: {
    height: `calc(${window.innerHeight}px - ${theme.styles.header.height}px - 32px - ${theme.styles.footer.height}px)`,
    textAlign: 'center',
  },
  appBar: {
    top: theme.styles.header.height,
  },
  tabs: {
    backgroundColor: theme.styles.header.backgroundColor,
    height: 32,
    minHeight: 32,
  },
  tab: {
    color: theme.palette.primary.main,
    padding: 0,
    height: 32,
    minHeight: 32,
    width: '50%',
    textTransform: 'none',
    '&$selected': {
      color: '#b0b4fc',
    }
  },
  selected: {},
  indicator: {
    backgroundColor: '#b0b4fc',
  },
}))

export default function FriendRequest (props) {
  const { friends } = props
  const classes = useStyles()
  const [receiveFriend, setReceiveFriend] = useState([])
  const [applyingFriend, setApplyingFriend] = useState([])
  const [slideIndex, setSlideIndex] = useState(0)
  const [questionCancelOpen, setQuestionCancelOpen] = useState(false)
  const [cancelCompleteOpen, setCancelCompleteOpen] = useState(false)
  const [cancelFriend, setCancelFriend] = useState(null)
  const [readIds, setReadIds] = useState(null)
  const [displayProfileItems, setDisplayProfileItems] = useState([])

  useEffect(() => {
    (async () => {
      props.setScreen('FriendRequest')
      fetchFriends()
      const res = await Api.getReadIds('Entry')
      setReadIds(res)
      getUserConfig()
      BaseComponent.addVisitPageLog()
    })()

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const getUserConfig = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getUserConfig()
      setDisplayProfileItems(res?.display_profile_items || [])
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  useEffect(() => {
    sortFriends(friends)
  }, [friends])

  const fetchFriends = async () => {
    try {
      const entries = await BaseComponent.loadAppliedEntries(props)
      if (entries.received && entries.received.length > 0) {
        const loadFriends = await BaseComponent.loadFriends(props)
        sortFriends(loadFriends)
      }
      BaseComponent.loadNotifications(props)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const sortFriends = (friends) => {
    if (!Array.isArray(friends)) { return }
    const receiveFriendList = []
    const applyingFriendList = []
    friends.forEach(friend => {
      // 相手から
      if (friend.type === 'friend_receiving' || friend.type === 'lover_receiving') {
        receiveFriendList.push(friend)
      }
      // 自分から
      if (friend.type === 'friend_applying' || friend.type === 'lover_applying') {
        applyingFriendList.push(friend)
      }
      // 保留中
      if (friend.type === 'friend_pending') {
        receiveFriendList.push(friend)
      }
    })

    // 相手から一覧並べ替え（お付き合い申請受信中→友達申請受信中）
    receiveFriendList.sort(function (a, b) {
      if (a.type !== 'lover_receiving' && b.type === 'lover_receiving') return 1
      if (a.type === 'lover_receiving' && b.type !== 'lover_receiving') return -1
      if (a.entry_applied_at < b.entry_applied_at) return 1
      if (a.entry_applied_at > b.entry_applied_at) return -1
      return 0
    })

    // 自分から一覧並べ替え（お付き合い申請送信中→友達申請送信中）
    applyingFriendList.sort(function (a, b) {
      if (a.type !== 'friend_pending' && b.type === 'friend_pending') return 1
      if (a.type === 'friend_pending' && b.type !== 'friend_pending') return -1
      if (a.type !== 'lover_applying' && b.type === 'lover_applying') return 1
      if (a.type === 'lover_applying' && b.type !== 'lover_applying') return -1
      if (a.entry_applied_at < b.entry_applied_at) return 1
      if (a.entry_applied_at > b.entry_applied_at) return -1
      return 0
    })

    setReceiveFriend(receiveFriendList)
    setApplyingFriend(applyingFriendList)
  }

  const handleChangeTab = (event, value) => {
    setSlideIndex(value)
  }

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  const handleQuestionCancelOk = async () => {
    props.setLoading(true)
    try {
      await BaseComponent.cancelEntries(props, cancelFriend.id)
      setQuestionCancelOpen(false)

      if (cancelFriend.type === 'friend_applying') {
        BaseComponent.showRequestSuccessMessage(props, '「いいね！」を取り消しました')
        fetchFriends()
      } else {
        setCancelCompleteOpen(true)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleCancelCompleteClose = () => {
    fetchFriends()
    setCancelCompleteOpen(false)
  }

  const handleQuestionCancelOpen = (friend) => {
    setQuestionCancelOpen(true)
    setCancelFriend(friend)
  }

  const createEmptyComment = (text) => (
    <div className={classes.centerBlock}>
      <div className={classes.emptyTable} />
      <Grid container alignItems="center" justifyContent="space-around" >
        <Typography className={classes.emptyComment}>
          {text}
        </Typography>
      </Grid>
    </div>
  )

  return (
    <>
      <div className={classes.root}>
        <AppHeader
          title="いいね！"
          hideBack
          hideHome
          showMypage
          showTutorialIcon
          showFeedback
          {...props}
        />
        <PlanTermCheck {...props}>
          <AppBar className={classes.appBar} position="fixed">
            <Tabs
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
              value={slideIndex}
              onChange={handleChangeTab}
              variant="fullWidth"
              textColor="primary"
            >
              <Tab className={classes.tab} classes={{ selected: classes.selected }} label="相手から" />
              <Tab className={classes.tab} classes={{ selected: classes.selected }} label="自分から" />
            </Tabs>
          </AppBar>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            <div className={classes.slide} style={Object.assign({})}>
              {receiveFriend.length > 0 ? (
                <Table className={classes.table}>
                  <tbody>
                    {receiveFriend.map((friend, index) => {
                      if (friend.type === 'friend_receiving' || friend.type === 'lover_receiving' || friend.type === 'friend_pending') {
                        return <FriendCell
                          {...props}
                          key={index}
                          index={index + 1}
                          friend={friend}
                          readIds={readIds}
                          displayProfileItems={displayProfileItems}
                          friendRequestPage
                        />
                      }
                    })}
                  </tbody>
                </Table>
              ) : (
                <>{createEmptyComment('相手からの「いいね！」が表示されます。')}</>
              )}
            </div>
            <div className={classes.slide} style={Object.assign({})}>
              {(applyingFriend.length > 0 ? (
                <Table className={classes.table}>
                  <tbody>
                    {applyingFriend.map((friend, key) => (
                      <FriendCellApplying
                        key={key}
                        {...props}
                        friend={friend}
                        onCancel={handleQuestionCancelOpen}
                      />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <>{createEmptyComment('「いいね！」中の異性が表示されます。')}</>
              ))}
            </div>
          </SwipeableViews>
        </PlanTermCheck>
      </div>
      {cancelFriend && (
        <ImportantConfirmDialog
          open={questionCancelOpen}
          onOk={handleQuestionCancelOk}
          onCancel={() => setQuestionCancelOpen(false)}
          question="yes"
          title={`${cancelFriend.type === 'friend_applying' ? '「いいね！」' : 'リクエスト'}を\n取り消しますか？`}
        />
      )}
      <ConfirmDialog
        open={cancelCompleteOpen}
        onClose={handleCancelCompleteClose}
        confirm="yes"
        title="リクエスト取り消し"
        message="お付き合いリクエストを取り消しました。"
      />
    </>
  )
}

FriendRequest.propTypes = {
  friends: PropTypes.array,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
