import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Komainu from 'images/Komainu/firstNaviKomainu.png'
import KomainuSerif from 'images/Komainu/firstNaviKomainuSerif.png'

const useStyles = makeStyles(theme => ({
  slideTitle: {
    fontSize: 25,
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: theme.spacing(3.25),
  },
  stepBox: {
    width: 289,
    height: 77,
    background: '#B0B4FC',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    position: 'relative',
  },
  step: {
    fontSize: 22,
    fontWeight: 'bold',
    marginRight: theme.spacing(2.25),
    position: 'absolute',
    right: 235,
  },
  stepText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  serif: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(1.75),
  },
  komainu: {
    width: 170,
    height: 67,
    display: 'block',
    margin: 'auto',
    objectFit: 'none',
    objectPosition: 'center top',
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 9.5px 0 9.5px',
    borderColor: '#423BC7 transparent transparent transparent',
    margin: 'auto',
  },
}))

export default function Tutorial2 () {
  const classes = useStyles()

  const createStepBox = (step, text) => {
    return (
      <>
        <Grid
          className={classes.stepBox}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.step} color="secondary">{step}</Typography>
          <Typography className={classes.stepText} color="secondary">{text}</Typography>
        </Grid>
        {step !== 5 && <div className={classes.triangle} />}
      </>
    )
  }

  return (
    <>
      <Typography className={classes.slideTitle} color="primary">
        Aill goenの流れ
      </Typography>
      <img className={classes.serif} src={KomainuSerif} alt="" />
      <img className={classes.komainu} src={Komainu} alt="" />
      {createStepBox(1, '紹介者に「いいね！」申請')}
      {createStepBox(2, 'マッチング')}
      {createStepBox(3, 'トーク')}
      {createStepBox(4, 'リアルデート')}
      {createStepBox(5, 'お付き合い')}
    </>
  )
}
