import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import PhotoDialog from 'components/parts/PhotoDialog'
import ShopCell from 'components/parts/ShopCell.jsx'

const styles = theme => ({
  root: {
    paddingTop: theme.styles.header.height + theme.spacing(1.5),
    paddingBottom: theme.spacing(3),
    minHeight: window.innerHeight + 'px',
    ...theme.styles.shopListColor,
  },
  table: {
    overflow: 'auto',
    width: '100%',
    padding: 0,
  },
  ratingContainer: {
    margin: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
    width: `calc(100% - ${theme.spacing(3)}px)`,
  },
  ratingItem: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(13),
    height: theme.spacing(16),
  },
  text: {
    marginTop: theme.spacing(1.5),
    textAlign: 'center',
  },
  rating: {
    marginTop: theme.spacing(1),
    ...theme.styles.shopRatingText,
  },
  linkContainer: {
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    width: `calc(100% - ${theme.spacing(6)}px)`,
  },
  link: {
    wordBreak: 'break-all',
  },
  closedButton: {
    ...theme.styles.entryButton,
    backgroundColor: '#F0F0F0',
    width: theme.spacing(20),
    height: theme.spacing(6),
  },
})

export class ShopDetail extends BaseComponent {
  constructor (props) {
    super(props)

    this.onClosed = this.onClosed.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)

    this.state = {
      openPhotoDialog: false,
      selectedPhotoUrl: null,
      openConfirmDialog: false,
    }
  }

  componentWillMount () {
    if (!this.props.shopDetail) {
      this.props.setScreen('/ShopSearch')
    }
  }

  openPhotoDialog (url) {
    if (url) {
      this.setState({
        openPhotoDialog: true,
        selectedPhotoUrl: url
      })
    }
  }

  closePhotoDialog () {
    this.setState({
      openPhotoDialog: false,
      selectedPhotoUrl: null
    })
  }

  async onClosed () {
    this.props.setLoading(true)
    try {
      await Api.updateShop(this.props.shopDetail.id, { closed: true })
      this.setState({ openConfirmDialog: true })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  closeConfirmDialog () {
    this.setState({ openConfirmDialog: false })
  }

  render () {
    const { classes, ...other } = this.props
    if (!this.props.shopDetail) { return null }

    return(
      <React.Fragment>
        <PhotoDialog
          contentId={'Shop_PhotoDialog'}
          open={this.state.openPhotoDialog}
          imageUrl={this.state.selectedPhotoUrl}
          onClose={() => this.closePhotoDialog()}
          deletebutton={false}
        />
        <ConfirmDialog
          open={this.state.openConfirmDialog}
          onClose={this.closeConfirmDialog}
          onOk={this.closeConfirmDialog}
          onCancel={this.closeConfirmDialog}
          tutorial="yes"
          title="閉店の報告"
          message="ご協力ありがとうございます。"
        />
        <AppHeader title={'レストラン詳細'} hideHome={true} {...other} />
        <div className={classes.root}>
          <Table className={classes.table}>
            <tbody>
              <ShopCell
                shop={this.props.shopDetail}
                showDetail={true}
                onClickPhoto={(url) => this.openPhotoDialog(url)}
                {...other}
              />
            </tbody>
          </Table>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.ratingContainer} >
            <Grid item className={classes.ratingItem}>
              <Typography className={classes.text} >
                食べログ<br />
                評価
              </Typography>
              <Typography className={classes.rating} >
                {this.props.shopDetail.tabelog_rating ? this.props.shopDetail.tabelog_rating : '-' }
              </Typography>
            </Grid>
            <Grid item className={classes.ratingItem}>
              <Typography className={classes.text} >
                Google<br />
                評価
              </Typography>
              <Typography className={classes.rating} >
                {this.props.shopDetail.google_rating ? this.props.shopDetail.google_rating : '-' }
              </Typography>
            </Grid>
            <Grid item className={classes.ratingItem}>
              <Typography className={classes.text} >
                ヒトサラ<br />
                掲載
              </Typography>
              <Typography className={classes.rating} >
                {this.props.shopDetail.hitosara_published ? '○' : '-' }
              </Typography>
            </Grid>
          </Grid>
          {
            !isNullOrUndefined(this.props.shopDetail.url) &&
            <div className={classes.linkContainer} >
              <Typography>
                お店詳細情報
              </Typography>
              <a
                className={classes.link}
                href={this.props.shopDetail.url}
                target="_blank"
                rel="noreferrer"
              >
                {this.props.shopDetail.url}
              </a>
            </div>
          }
          <Grid container justifyContent="center">
            <Button variant="contained" className={classes.closedButton} onClick={this.onClosed}>
              閉店の報告
            </Button>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

ShopDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ShopDetail)