import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import AImage from 'images/img_character_a.png'
import AIcon from 'images/img_character_a_talk.png'
import UnImage from 'images/img_character_un.png'
import UnIcon from 'images/img_character_un_talk.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
  avatarContainer: {
    width: 66,
  },
  spaceContainer: {
    marginRight: theme.spacing(2),
  },
  faceAvatar: {
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginLeft: theme.spacing(2.375),
    marginRight: theme.spacing(1.375),
    marginBottom: theme.spacing(1),
  },
  angelSecretSpeach: theme.styles.naviSpeachSecret(Const.colors.angelSecretBorder),
  kaguraText: {
    width: 275,
    minWidth: 275,
    fontSize: 12,
    fontWeight: 400,
    color: '#8636D6',
    letterSpacing: 0.3,
    lineHeight: '20px',
    borderRadius: 25,
    margin: 0,
  },
  devilSecretSpeach: theme.styles.naviSpeachSecret(Const.colors.devilSecretBorder),
  sakakiText: {
    width: 275,
    minWidth: 275,
    fontSize: 12,
    fontWeight: 400,
    color: '#006EDF',
    letterSpacing: 0.3,
    lineHeight: 1,
    borderRadius: 25,
    whiteSpace: 'pre',
    margin: 0,
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: Const.colors.devilSecret,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  button: {
    width: 172,
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  paper: {
    borderRadius: '24px 24px 0 0',
    marginTop: theme.spacing(2.25),
  },
  dialogTitle: {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: 1,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2.5),
  },
  dialogContent: {
    padding: 0,
    paddingBottom: theme.spacing(8),
  },
  dialogText: {
    fontSize: 12,
    fontWeight: 700,
    color: '#1A1667',
    maxWidth: 'none',
    marginBottom: theme.spacing(2.5),
  },
  kagura: {
    position: 'absolute',
    top: 42,
    left: 28,
  },
  sakaki: {
    position: 'absolute',
    top: 46,
    right: 20,
  },
  question: {
    width: 320,
    background: '#ffffff',
    borderRadius: 25,
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: theme.spacing(1.5),
  },
  questionTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#414141',
    maxWidth: 'none',
  },
  questionSubTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#414141',
    maxWidth: 'none',
    lineHeight: 1,
    marginTop: theme.spacing(1),
  },
  exampleButton: {
    fontSize: 11,
    fontWeight: 600,
    color: '#8636D6',
    lineHeight: 1,
    letterSpacing: 0.22,
    textAlign: 'right',
    marginTop: theme.spacing(1.5),
    marginRight: 0,
  },
  example: {
    fontSize: 12,
    fontWeight: 600,
    color: '#3C0077',
    lineHeight: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  exampleText: {
    fontSize: 12,
    fontWeight: 400,
    color: '#414141',
    letterSpacing: 0.3,
  },
  divider: {
    width: 319,
    height: 2,
    background: '#8636D6',
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginBottom: theme.spacing(3.75),
    marginLeft: 'auto',
  },
  advice: {
    marginBottom: theme.spacing(3),
  },
  adviceText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
    margin: 0,
  },
  imageFootA: {
    width: 22,
    marginRight: theme.spacing(1),
  },
  imageFootUn: {
    width: 22,
    marginLeft: theme.spacing(3),
  },
  kaguraIcon: {
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginRight: theme.spacing(1.5),
  },
  sakakiIcon: {
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginRight: theme.spacing(1.5),
  },
}))

export default function DatingRecommendedQuestions (props) {
  const { friend, message, handleTap } = props
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const [openExample, setOpenExample] = useState([])

  const naviType = message.navi_type
  const color = naviType === 'devil' ? Const.colors.devilSecret : Const.colors.angelSecret
  const questions = message?.dating_recommended_questions
  if (!questions || questions.length === 0) { return null }

  const createMessage = () => {
    const msg = message.message
    if (msg.includes('これだけ')) {
      const [head, tail] = msg.split('これだけ', 2)
      return (
        <>{head}<span style={{ color: '#F55083' }}>これだけ</span>{tail}</>
      )
    } else {
      return msg
    }
  }

  const createExample = (q) => {
    const [mainText, example] = q.split(/\n\n/)
    const subTitlePattern = /(\(|（).+?(\)|）)/
    const title = (mainText || '').replace(subTitlePattern, '')
    const subTitle = (mainText || '').match(subTitlePattern)?.[0]
    return (
      <div className={classes.question} key={title}>
        <Typography className={classes.questionTitle}>{title}</Typography>
        {subTitle && (
          <Typography className={classes.questionSubTitle}>{subTitle}</Typography>
        )}
        {example && (
          <>
            {openExample.includes(title) ? (
              <>
                <Typography className={classes.example}>
                  こんな風に聞いてみてね
                </Typography>
                <Typography className={classes.exampleText}>
                  {example}
                </Typography>
                <Typography className={classes.exampleButton} onClick={() => setOpenExample(openExample.filter(d => d !== title))}>
                  閉じる∧
                </Typography>
              </>
            ) : (
              <Typography className={classes.exampleButton} onClick={() => setOpenExample([...openExample, title])}>
                聞き方の例を見る∨
              </Typography>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <Grid className={classes.root} container wrap="nowrap">
        <Grid className={classes.avatarContainer} container direction="column" justifyContent="flex-end">
          <img
            src={naviType === 'devil' ? UnIcon : AIcon}
            className={classes.faceAvatar}
            onClick={handleTap}
            alt=""
          />
        </Grid>
        <Grid
          className={naviType === 'devil' ? classes.devilSecretSpeach : classes.angelSecretSpeach}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ background: naviType === 'devil' ? '#589BFB34' : '#8636D633' }}
        >
          <Typography className={classes.title} style={{ color: color }}>
            相手に喜ばれる質問は？
          </Typography>
          <Typography className={classes.text} style={{ color: color }}>
            デートの間に聞いて好印象を与えよう！
          </Typography>
          <Button
            className={classes.button}
            style={{ background: color }}
            variant="contained"
            onClick={() => setOpenDialog(true)}
          >
            おすすめ質問
          </Button>
        </Grid>
        <div className={classes.spaceContainer} />
      </Grid>
      <DialogThemeProvider color={naviType}>
        <Dialog open={openDialog} fullScreen PaperProps={{ className: classes.paper }}>
          <DialogCloseButton onClick={() => setOpenDialog(false)} />
          <DialogTitle className={classes.dialogTitle} style={{ color: color }} disableTypography>
            デート中に質問してみよう！
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <img className={classes.kagura} src={AImage} width={32} alt="" />
            <img className={classes.sakaki} src={UnImage} height={33} alt="" />
            <Typography className={classes.dialogText}>
              { createMessage() }
            </Typography>
            {questions.map(q => createExample(q))}
            <div className={classes.divider} />
            <Grid container justifyContent="center" alignItems="center" className={classes.advice}>
              <img src={FootAImage} className={classes.imageFootA} alt="" />
              <Typography className={classes.adviceText}>
                狛犬のちょっとアドバイス
              </Typography>
              <img src={FootUnImage} className={classes.imageFootUn} alt="" />
            </Grid>
            <Grid container justifyContent="center" alignItems="flex-end">
              <img src={AIcon} className={classes.kaguraIcon} alt="" />
              <div className={`${classes.angelSecretSpeach} ${classes.kaguraText}`}>
                {friend.nick_name}さんは、上の話題について興味があるらしいよ！
              </div>
            </Grid>
            <Grid container justifyContent="center" alignItems="flex-end">
              <img src={UnIcon} className={classes.sakakiIcon} alt="" />
              <div className={`${classes.devilSecretSpeach} ${classes.sakakiText}`}>
                お相手の回答には、ポジティブに返そう！
              </div>
            </Grid>
          </DialogContent>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

DatingRecommendedQuestions.propTypes = {
  friend: PropTypes.object,
  message: PropTypes.object.isRequired,
  handleTap: PropTypes.func,
}
