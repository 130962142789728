import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const ScheduledChatDeleteConfirmDialog = (props) => {
  const { open, onOK, onCancel } = props

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle disableTypography>
          送信予約中のメッセージを削除しますか？
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onOK}>
            削除する
          </Button>
          <Button variant="text" onClick={onCancel}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

export default ScheduledChatDeleteConfirmDialog

ScheduledChatDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOK: PropTypes.func.isRequired
}
