import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import * as Storage from 'commons/storage'
import { formatJapaneseDateMD } from 'commons/utility'
import SendConfirmDialog from 'components/chats/SendConfirmDialog'
import PhotoIcon from 'components/icons/PhotoIcon'
import SendIcon from 'components/icons/SendIcon'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import CropDialog from 'components/parts/CropDialog'
import DialogCloseButton from 'components/parts/DialogCloseButton'

const minimum = screen.height <= 568 //105
const small = screen.height <= 667 // 168
const maxRows = minimum ? 5 : small ? 8 : 10
const maxHeight = minimum ? 90 : small ? 162 : 232
const type_message = 'message'
const type_image = 'image'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 999,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    '-webkit-user-select': 'none',
  },
  reply: {
    fontSize: 11,
    width: '100%',
    height: 54,
    background: '#ffffff',
    boxShadow: '0px -1px 0px #F5F5F5',
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2.5),
    position: 'relative',
  },
  replyTo: {
    fontSize: 11,
    fontWeight: 400,
    color: '#414141',
    lineHeight: 1,
    marginBottom: theme.spacing(1),
  },
  replyText: {
    maxWidth: '90%',
    fontSize: 11,
    fontWeight: 300,
    color: '#9D9D9E',
    lineHeight: 1,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
  },
  input: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '-webkit-user-select': 'auto',
  },
  cameraAvatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  textFieldContainer: {
    padding: 0,
    flexGrow: 1,
  },
  textField: {
    '& #message': {
      maxHeight: maxHeight,
    },
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    width: 40,
    padding: 0,
    margin: `0 ${theme.spacing(1)}px`,
  },
  button: {
    minWidth: 40,
  },
  scheduledMessage: {
    width: '100%',
    height: 62,
    borderRadius: '12px 12px 0 0',
    backgroundColor: '#F5F5F5',
    padding: theme.spacing(2),
    position: 'relative'
  },
  scheduledMessageInfo: {
    fontSize: 12,
    fontWeight: 400,
    color: '#414141',
  },
  scheduledMessageLink: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
  },
}))

export default function ChatInput (props) {
  const {
    inputId, onSend, onSendScheduledMessage, friend,
    secretQuestionRoomOpen, secretQuestionRoomId,
    user, replyToMessage, templateMessage,
    changeInputRows, chatRoomId, setScreen, ...other
  } = props
  const classes = useStyles()
  const [sending, setSending] = useState(false)
  const [openCropDialog, setOpenCropDialog] = useState(false)
  const [originalFile, setOriginalFile] = useState(null)
  const [imageFileUrl, setImageFileUrl] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [openUpdateTextConfirmDialog, setOpenUpdateTextConfirmDialog] = useState(false)
  // clear時にレンダリングできないのでstateで管理
  const [replyToMessageState, setReplyToMessageState] = useState(null)
  const [openSendConfirmDialog, setOpenSendConfirmDialog] = useState(false)
  const [sendImageDialogConfig, setSendImageDialogConfig] = useState(undefined)
  const [scheduledChatMessages, setScheduledChatMessages] = useState([])
  const inputRef = useRef(null)

  // トークルーム: friendが切り替わるたびに最終入力値をセット
  useEffect(() => {
    if (inputId !== 'message') { return }
    if (!friend) { return }
    onChange(Storage.getChatMessage(friend.id))
  }, [friend])

  // 秘密の質問部屋ルーム: openするたびに最終入力値をセット
  useEffect(() => {
    if (inputId !== 'secretMessage') { return }
    if (!secretQuestionRoomOpen) { return }
    if (!secretQuestionRoomId) { return }
    onChange(Storage.getSecretQuestionRoomChatMessage(secretQuestionRoomId))
  }, [secretQuestionRoomOpen, secretQuestionRoomId])

  useEffect(() => {
    (async () => {
      // state更新
      await setReplyToMessageState(replyToMessage)
      // chat-scrollのbottom調整
      await changeInputRows?.()
      if (!replyToMessage) { return }
      inputRef.current.focus()
    })()
  }, [replyToMessage?.id])

  useEffect(() => {
    if (!templateMessage || !friend) { return }
    const text = templateMessage.quote_template.replace('{{friend}}', friend.nick_name)
    if (!inputValue) {
      onChange(text)
    } else {
      setOpenUpdateTextConfirmDialog(true)
    }
  }, [templateMessage])

  const fetchScheduledMessages = async () => {
    const messages = await Api.getMyScheduledChatMessages(friend.id)
    setScheduledChatMessages(messages)
    await changeInputRows?.()
  }

  useEffect(() => {
    if (!chatRoomId) { return }
    fetchScheduledMessages()
  }, [chatRoomId, sending])

  const onChange = (v) => {
    if (v === null || v === undefined || !friend) { return }
    const msg = v.slice(0, Const.maxChatMessageLength)
    saveMessage(msg)
    new Promise((resolve) => {
      setInputValue(msg)
      resolve()
    }).then(() => changeInputRows?.())
    if (!msg) {
      Storage.clearChatMessage(friend.id)
    }
    const text = templateMessage?.quote_template.replace('{{friend}}', friend.nick_name)
    if (!msg.includes(text)) {
      Storage.clearTemplateMessage()
    }
  }

  const saveMessage = _.throttle(msg => {
    if (inputId === 'message') { Storage.setChatMessage(friend.id, msg) }
    if (inputId === 'secretMessage') { Storage.setSecretQuestionRoomChatMessage(secretQuestionRoomId, msg) }
  }, 1000)

  const onFocusTextField = () => {
    Api.addActionLog('start_input_message', null, { friend_user_id: friend.id })
  }

  const onBlurTextField = () => {
    Api.addActionLog('end_input_message', null, { friend_user_id: friend.id })
  }

  const sendMessage = async () => {
    if (!inputValue) { return }
    setOpenSendConfirmDialog(false)
    const temp = Storage.getTemplateMessage()
    setSending(true)
    const sent = await onSend(inputValue, type_message, temp?.id, replyToMessageState?.id)
    if (sent) {
      await clearMessage()
    }
    setSending(false)
  }

  const clearMessage = async () => {
    onChange('')
    if (inputId === 'message') {
      Storage.clearChatMessage(friend.id)
      Storage.clearTemplateMessage()
    }
    if (inputId === 'secretMessage') { Storage.clearSecretQuestionRoomChatMessage(secretQuestionRoomId) }
    await clearReplyMessage()
  }

  const handleReadImportedFile = (event) => {
    const file = event.target.files[0]
    if (!file) { return }
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    const imageUrl = createObjectURL(file)
    setOpenCropDialog(true)
    setOriginalFile(file)
    setImageFileUrl(imageUrl)
    document.getElementById('file_photo').value = ''
  }

  const uploadCroppedFile = async (file) => {
    const ret = await new Promise((resolve) => {
      setSendImageDialogConfig({
        open: true,
        onCancel: () => resolve('close'),
        onSendNow: () => resolve('now'),
        onSendScheduled: (scheduled_at) => resolve(scheduled_at),
      })
    })
    setSendImageDialogConfig(undefined)
    if (ret === 'close') { return }
    props.setLoading(true)
    try {
      const roomId = friend.chat_room_id
      const imageItem = await BaseComponent.addChatImage(roomId, file)
      if (ret === 'now') {
        await onSend(imageItem, type_image)
        return
      }
      await onSendScheduledMessage(imageItem, type_image, ret, replyToMessageState?.id)
      await fetchScheduledMessages()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      closeCropDialog()
      props.setLoading(false)
    }
  }

  const closeCropDialog = () => {
    setOpenCropDialog(false)
    setOriginalFile(null)
    setImageFileUrl(null)
  }

  const clearReplyMessage = async () => {
    Storage.clearReplyMessage(chatRoomId)
    await setReplyToMessageState(null)
    await changeInputRows?.()
  }

  const createReplyBox = () => {
    if (!replyToMessageState) { return null }
    return (
      <Grid className={classes.reply} data-testid="reply-box">
        <DialogCloseButton
          onClick={clearReplyMessage}
          style={{ color: '#423BC7', top: 28, transform: 'translate(0%, -50%)' }}
        />
        <div className={classes.replyTo}>
          {replyToMessageState.user_id === user.id ? '自分に' : `${friend.nick_name}さんに`}返信
        </div>
        <div className={classes.replyText}>
          {replyToMessageState.message}
        </div>
      </Grid>
    )
  }

  const handleSendButtonClick = () => {
    if (inputValue) {
      setOpenSendConfirmDialog(true)
    }
  }

  const sendScheduledMessage = async (scheduled_at) => {
    if (!inputValue) { return }
    setOpenSendConfirmDialog(false)
    setSending(true)
    try {
      const sent = await onSendScheduledMessage(inputValue, type_message, scheduled_at, replyToMessageState?.id)
      if (sent) {
        await clearMessage()
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      await setSending(false)
    }
  }

  return (
    <>
      <Grid id="chat-bar" className={classes.root}>
        {scheduledChatMessages.length > 0 && (
          <div className={classes.scheduledMessage}>
            <Typography className={classes.scheduledMessageInfo}>
              {scheduledChatMessages.length > 1 ?
                `${scheduledChatMessages.length}件のメッセージが送信予約されています。` :
                `メッセージは${formatJapaneseDateMD(scheduledChatMessages[0].scheduled_at)}に送信されます。`}
            </Typography>
            <Typography className={classes.scheduledMessageLink} onClick={() => setScreen('/ScheduledChatMessages')}>
              送信予約中のメッセージを見る
            </Typography>
          </div>
        )}
        {createReplyBox()}
        <Grid className={classes.input} container wrap="nowrap" justifyContent="center" alignItems="flex-end">
          <Avatar className={classes.cameraAvatar} htmlFor="file_photo" component="label">
            <PhotoIcon />
          </Avatar>
          <div className={classes.textFieldContainer}>
            <TextField
              id={inputId}
              className={classes.textField}
              placeholder="メッセージ"
              value={inputValue}
              onChange={(event) => onChange(event.target.value)}
              onFocus={onFocusTextField}
              onBlur={onBlurTextField}
              fullWidth
              multiline
              maxRows={maxRows}
              InputProps={{ disableUnderline: true }}
              disabled={sending}
              inputRef={inputRef}
            />
          </div>
          <Grid className={classes.buttonContainer} container justifyContent="center">
            {sending ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                color="primary"
                className={classes.button}
                onClick={handleSendButtonClick}
                disabled={sending}
                data-testid='sendMessageButton'
              >
                <SendIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <form noValidate autoComplete="off">
        <input
          id="file_photo"
          data-testid='file_photo'
          type="file"
          accept="image/*"
          onChange={handleReadImportedFile}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
          }}
        />
      </form>
      <CropDialog
        {...other}
        open={openCropDialog}
        originalFile={originalFile}
        photoUrl={imageFileUrl}
        onUpload={uploadCroppedFile}
        onClose={closeCropDialog}
        aspectRatio={null}
        isSend={true}
      />
      <ConfirmDialog
        open={openUpdateTextConfirmDialog}
        onOk={() => {
          setOpenUpdateTextConfirmDialog(false)
          const text = templateMessage.quote_template.replace('{{friend}}', friend.nick_name)
          setInputValue(text)
        }}
        onCancel={() => setOpenUpdateTextConfirmDialog(false)}
        question="yes"
        title={'テンプレート文挿入'}
        message={'入力欄を上書きしてもよろしいですか？'}
      />
      <SendConfirmDialog
        {...props}
        open={openSendConfirmDialog}
        onCancel={() => setOpenSendConfirmDialog(false)}
        onSendNow={sendMessage}
        onSendScheduled={sendScheduledMessage}
      />
      {
        sendImageDialogConfig && (
          <SendConfirmDialog
            {...sendImageDialogConfig}
          />
        )
      }
    </>
  )
}

ChatInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  friend: PropTypes.object,
  onSend: PropTypes.func,
  onSendScheduledMessage: PropTypes.func,
  setLoading: PropTypes.func,
  secretQuestionRoomOpen: PropTypes.bool,
  secretQuestionRoomId: PropTypes.string,
  user: PropTypes.object,
  replyToMessage: PropTypes.object,
  templateMessage: PropTypes.object,
  changeInputRows: PropTypes.func,
  chatRoomId: PropTypes.string,
  setScreen: PropTypes.func,
}