import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import Kagura from 'images/Komainu/firstNaviKagura.png'
import Sakaki from 'images/Komainu/firstNaviSakaki.png'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    height: theme.spacing(26),
  },
  slideTitle: {
    fontSize: 25,
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  slideMessage: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  messageContainer: {
    width: 276,
    margin: 'auto',
  },
  leftMessage: {
    width: 209,
    height: 84,
    backgroundColor: '#D6BCEF',
    borderRadius: '25px 25px 25px 5px',
    marginBottom: theme.spacing(2.25),
  },
  rightMessage: {
    width: 222,
    height: 84,
    backgroundColor: '#ABCDFD',
    borderRadius: '25px 25px 5px 25px',
    marginBottom: theme.spacing(6.5),
  },
  message: {
    color: Const.colors.devil,
    fontSize: 15,
    fontWeight: 'bold',
  },
  imgBox: {
    maxWidth: 265,
    margin: 'auto',
  },
  nameKagura: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#D6BCEF',
    textAlign: 'center',
    paddingRight: theme.spacing(3),
  },
  nameSakaki: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#ABCDFD',
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
  },
}))

export default function Tutorial1 () {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.slideTitle} color="primary">
        Aill goenにようこそ！
      </Typography>
      <Typography className={classes.slideMessage} color="primary">
        「縁を結ぶ」<br />
        榊と神楽をよろしく
      </Typography>
      <div className={classes.messageContainer}>
        <Grid
          className={classes.leftMessage}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.message}>
            神楽(カグラ)が<br />
            背中を押すよ！
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid
            className={classes.rightMessage}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.message}>
              榊(サカキ)も<br />
              的確なアドバイスするね。
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid
        className={classes.imgBox}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <img src={Kagura} alt="" />
          <Typography className={classes.nameKagura}>神楽</Typography>
        </div>
        <div>
          <img src={Sakaki} alt="" />
          <Typography className={classes.nameSakaki}>榊</Typography>
        </div>
      </Grid>
    </>
  )
}
