import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import exIconX from 'images/img_photo_exX.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import TutorialImage from 'images/tutorial/img_message_tutorial.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    margin: 0,
    height: window.innerHeight,
    maxHeight: '100%',
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  content: {
    padding: 0,
  },
  title: {
    fontSize: 16,
    color: '#414141',
    fontWeight: 600,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  imageContainer: {
    marginTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ngContainer: {
    backgroundColor: '#F5F5F5',
    borderRadius: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: 376,
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: 12,
    color: '#707070',
    fontWeight: 600,
  },
  imageItem: {
    width: 224,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconX: {
    width: 18,
    height: 18,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  text: {
    fontSize: 14,
    color: '#707070',
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  tutorialContainer: {
    marginTop: theme.spacing(5),
    width: '100%',
    maxWidth: 400,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    position: 'relative',
    '& img': {
      maxWidth: 'none',
    },
  },
  tutorialTitle: {
    width: '75%',
    position: 'absolute',
    top: 16,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  titleText: {
    ...theme.styles.messageColor,
    fontSize: 11,
    fontWeight: 600,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  message: {
    fontSize: 12,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    height: 34,
  },
  messageA: {
    backgroundColor: '#8636D6 !important',
    top: '30%',
  },
  messageUN: {
    backgroundColor: '#006EDF !important',
    top: '60%',
  },
  imageA: {
    position: 'absolute',
    top: '30%',
    left: '4%',
    width: 32,
  },
  imageUn: {
    position: 'absolute',
    top: '62%',
    right: '4%',
    width: 39,
  },
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: theme.spacing(2),
  },
  library: {
    marginBottom: theme.spacing(1),
  },
}))

export default function PhotoTutorialMainDialog (props) {
  const { open, type } = props
  const classes = useStyles()

  const createItem = (text) => {
    return (
      <Grid item className={classes.imageItem}>
        <img src={exIconX} className={classes.iconX} alt="" />
        <Typography className={classes.text}>{text}</Typography>
      </Grid>
    )
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog classes={{ paper: classes.root }} open={open}>
        <DialogContent className={classes.content}>
          <div>
            <Grid container justifyContent="center" alignItems="center">
              <img src={AImage} width={32} alt="" />
              <Typography className={classes.title}>
                自分らしい写真を追加しよう！！
              </Typography>
              <img src={UnImage} width={40} alt="" />
            </Grid>
            <Grid
              className={classes.imageContainer}
              container
              direction="row"
              justifyContent="center"
            >
              <Grid
                className={classes.ngContainer}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.subTitle}>
                  審査NGな写真があるので、気をつけて！
                </Typography>
                {createItem('個人情報が入っている写真')}
                {createItem('芸能人の写真')}
                {createItem('他人の顔が分かる写真')}
                {createItem('著作物が写り込んでいる写真')}
                {createItem('過剰な加工がされている写真')}
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center">
              <div className={classes.tutorialContainer}>
                <img src={TutorialImage} width={'100%'} alt="" />
                <Grid
                  className={classes.tutorialTitle}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={FootAImage} width={22} alt="" />
                  <Typography className={classes.titleText}>
                    カグラとサカキのちょっとアドバイス
                  </Typography>
                  <img src={FootUnImage} width={22} alt="" />
                </Grid>
                <Button
                  className={`${classes.message} ${classes.messageA}`}
                  variant="contained"
                  onClick={props.clickTutorial}
                >
                  好印象の写真の法則を見てみよう！
                </Button>
                <Button
                  className={`${classes.message} ${classes.messageUN}`}
                  variant="contained"
                  onClick={props.clickTutorialBad}
                >
                  こんな写真はオススメしないよ。
                </Button>
                <img src={AImage} className={classes.imageA} alt="" />
                <img src={UnImage} className={classes.imageUn} alt="" />
              </div>
            </Grid>
          </div>
          <Grid
            className={classes.buttonContainer}
            container
            direction='column'
            alignItems="center"
          >
            <Button
              className={classes.library}
              variant="contained"
              color="secondary"
              htmlFor={type === 'gallery' ? 'fileGallery' : 'file'}
              component="label"
            >
              フォトライブラリ
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={props.clickCancel}
            >
              キャンセル
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}
PhotoTutorialMainDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  clickTutorial: PropTypes.func,
  clickTutorialBad: PropTypes.func,
  clickCancel: PropTypes.func,
}