import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // paddingTop: theme.styles.header.height,
    paddingTop: theme.spacing(9),
  },
  column: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
  },
  disabled: {
    filter: 'opacity(0.5)'
  },
  formControlLabelRoot: {
    margin: 0,
  },
  switch: {
    width: 40,
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: `translateX(${theme.spacing(2.5)}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.secondary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  divider: {
    height: 1,
    background: '#E0E0E0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function MyNotifications (props) {
  const { ...other } = props
  const classes = useStyles()
  const [userConfig, setUserConfig] = useState({})
  const [globalPushEnabled, setGlobalPushEnabled] = useState(false)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    getUserConfig()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const getUserConfig = async () => {
    props.setLoading(true)
    try {
      const res = await Api.getUserConfig()
      // 一つでもTrueのconigがある場合、全体設定のボタンをONにする
      setGlobalPushEnabled(Object.values(res).includes(true))
      setUserConfig(res)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeAll = async (event) => {
    try {
      const value = event.target.value
      const params = {
        new_matching_push_enabled: value,
        new_entry_push_enabled: value,
        new_user_message_push_enabled: value,
        new_navi_message_push_enabled: value,
        new_notification_push_enabled: value,
      }
      const res = await Api.updateUserConfig(params)
      setGlobalPushEnabled(!globalPushEnabled)
      setUserConfig(res)
    }catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const onChange = async (event) => {
    try {
      const name = event.target.name
      const value = event.target.value
      const params = { [name]: value }
      const res = await Api.updateUserConfig(params)
      setUserConfig(res)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const createOnOffButton = (type, label) => {
    const disabled = (!globalPushEnabled && type !== 'global')
    const checked = type === 'global' ? globalPushEnabled : userConfig[type]
    if (checked === undefined) { return }
    return (
      <Grid
        className={`${classes.column} ${disabled ? classes.disabled : ''}`}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.label}>{label}</Typography>
        <Switch
          classes={{
            root: classes.switch,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          value={!checked}
          name={type}
          checked={checked}
          onChange={type === 'global' ? onChangeAll : onChange}
          disabled={disabled}
          disableRipple
          inputProps={{ 'data-testid': type }}
        />
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <AppHeader title="通知設定" backWhite {...other} />
      {createOnOffButton('global', 'プッシュ通知')}
      <div className={classes.divider} />
      {createOnOffButton('new_matching_push_enabled', '「本日の出逢い」更新')}
      {createOnOffButton('new_entry_push_enabled', '「いいね！」受信')}
      {createOnOffButton('new_user_message_push_enabled', 'お相手から新着メッセージ受信')}
      {createOnOffButton('new_navi_message_push_enabled', '狛犬から新着メッセージ受信')}
      {createOnOffButton('new_notification_push_enabled', 'お知らせ受信')}
    </div>
  )
}

MyNotifications.propTypes = {
  setLoading: PropTypes.func,
}
