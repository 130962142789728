import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import PhotoFilter from 'components/parts/PhotoFilter'

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  content: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  photoImg: {
    width: window.innerWidth,
    maxWidth: '100% !important',
    maxHeight: '100%',
  },
  actions: {
    padding: 0,
  },
  deleteButton: {
    position: 'fixed',
    bottom: 40,
  },
  changeButton: {
    position: 'fixed',
    bottom: 40,
  },
  photo: {
    width: '100%'
  },
}))

export default function PhotoDialog (props) {
  const {
    open, imageUrl, contentId, brightness,
    deletebutton, onChange, onDelete, changebutton, onClose
  } = props
  const classes = useStyles()

  // エントリーサイト(メイン写真)
  if (contentId === 'PhotoDialog') {
    return (
      <DialogThemeProvider color="default">
        <Dialog open={open}>
          <DialogContent>
            <PhotoFilter id={contentId} brightness={brightness}>
              <img className={classes.photoImg} src={imageUrl} />
            </PhotoFilter>
          </DialogContent>
          <DialogActions className={classes.actions} disableSpacing>
            <Button onClick={onClose}>
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }

  const handleChange = () => {
    onChange && onChange()
    onClose && onClose()
  }

  // アプリ
  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} classes={{ paper: classes.paper }} fullScreen>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.content}>
          <PhotoFilter id={contentId} brightness={brightness}>
            <img className={classes.photoImg} src={imageUrl} width={'100%'} />
          </PhotoFilter>
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          {deletebutton && (
            <Button
              className={classes.deleteButton}
              variant="outlined"
              onClick={onDelete}
            >
              削除する
            </Button>
          )}
          {changebutton && (
            <Button
              className={classes.changeButton}
              variant="contained"
              onClick={handleChange}
            >
              変更する
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

PhotoDialog.propTypes = {
  contentId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  imageUrl: PropTypes.string,
  brightness: PropTypes.number,
  deletebutton: PropTypes.bool,
  changebutton: PropTypes.bool,
}
