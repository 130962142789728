import React, { useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Config from 'commons/config'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import TrialPlanBox from 'components/parts/TrialPlanBox'
import Komainu from 'images/Komainu/firstNaviKomainu.png'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  komainu: {
    width: 170,
    height: 67,
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(2),
    objectFit: 'none',
    objectPosition: 'center top',
  },
}))

export default function FreePlanSuggestDialog (props) {
  const { open, onClose, subscription, dialogType, user } = props
  const classes = useStyles()
  const userNickName = user ? user.nick_name : ''

  useEffect(() => {
    if (!open) { return }
    BaseComponent.gaModalView('freeplansuggest')
  }, [open])

  const formatNextBillingDateTitle = () => {
    if (!subscription) { return null }
    if (subscription.content === Const.planTypes.STANDARD_INITIAL) {
      return 'ご入会後の無料トライアル期間'
    }
    return '次回のお支払い日'
  }

  // 次回請求日
  const formatNextBillingDate = () => {
    if (!subscription) { return null }
    if (subscription.content === Const.planTypes.STANDARD_INITIAL) {
      const endDate = addDate(new Date(subscription.next_billing_date), -1)
      return `${dateFormat(endDate, 'yyyy年m月d日')}まで`
    }
    return dateFormat(subscription.next_billing_date, 'yyyy年m月d日')
  }

  const handleClose = () => {
    if (onClose) {
      if (dialogType) {
        onClose(dialogType)
      } else {
        onClose()
      }
    }
  }

  const openMembersSite = () => {
    window.open(Config.membersSiteUrl)
    handleClose()
  }

  // TODO DEV-1168 トライアルプラン -> フリープラン
  return (
    <>
      <DialogThemeProvider color="primary">
        <Dialog open={open}>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DialogTitle disableTypography>
            フリープランのご案内
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {formatNextBillingDateTitle()}は<br />
              {formatNextBillingDate()}<br />
              になります。<br />
              <br />
              {userNickName}様のご登録条件<br />
              （エリアや年齢等）により、<br />
              ご紹介できる異性の人数が<br />
              少ない可能性がございます。<br />
              無料でご利用いただける<br />
              「フリープラン」もございますので、ぜひご活用ください。<br />
              <br />
              ＊フリープラン中、任意のタイミングでスタンダードプランを選択することも可能です。<br />
              <br />
              より多くのエリアや年齢層の方々にもご満足いただけるサービスとなるよう、Aill一同取り組んで参ります。
            </Typography>
            <img className={classes.komainu} src={Komainu} alt="" />
            <TrialPlanBox />
            <Typography variant="body1">
              ＊フリープランへの変更は、メンバーズサイトから可能です。<br />
              ＊ご変更がない場合、スタンダードプラン（有料）が継続となります。
            </Typography>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={openMembersSite}>
              メンバーズサイトを開く
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

FreePlanSuggestDialog.propTypes = {
  user: PropTypes.object,
  subscription: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  // NotifyDialog から表示する場合
  dialogType: PropTypes.string,
}