import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#423bc7',
    strokeMiterlimit: 10,
    strokeWidth: 1.3,
  },
  cls2: {
    stroke: '#423bc7',
    strokeMiterlimit: 10,
    strokeWidth: 1.3,
    fill: '#423bc7',
  },
}

class TalkOnIcon extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <SvgIcon viewBox="0 0 23.73 18.31">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="23.73" height="18.31" viewBox="0 0 23.73 18.31">
          <g id="グループ_743" data-name="グループ 743" transform="translate(-288.36 -8.35)">
            <path id="パス_182" data-name="パス 182" className={classes.cls1} d="M155.61,95.385h2.877a6.666,6.666,0,0,1,6.543,6.784,7.049,7.049,0,0,1-.123,1.3h0a16.971,16.971,0,0,0-.213,2.3,3.676,3.676,0,0,0,.873,2.186c.468.662.81,1.006-.539,1.006h-9.42a6.789,6.789,0,0,1,0-13.569Z" transform="translate(145.467 -86.385)"/>
            <path id="パス_183" data-name="パス 183" className={classes.cls2} d="M152.029,99.605h-2.966a6.873,6.873,0,0,0-6.745,6.994,7.278,7.278,0,0,0,.127,1.338h0a17.538,17.538,0,0,1,.22,2.368,3.786,3.786,0,0,1-.9,2.253c-.482.683-.835,1.037.555,1.037h9.711a7,7,0,0,0,0-13.989Z" transform="translate(147.664 -87.584)"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

TalkOnIcon.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(TalkOnIcon)