import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import CheckIcon from 'images/invitation-campaign/check_icon.png'
import SmartphoneIcon from 'images/invitation-campaign/smartphone_icon.png'
import ToggleIcon from 'images/invitation-campaign/toggle_icon.png'

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#1A1667',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    background: 'linear-gradient(transparent 50%, #DBDCFE 50%)'
  },
  text: {
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
  },
  img: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
}))

export default function HowToDiscountCouponDialog (props) {
  const classes = useStyles()
  const { open, onClose } = props

  const createStep = (text) => (
    <Typography className={classes.subtitle} variant="subtitle1">{text}</Typography>
  )

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} style={{ color: '#17185B' }} />
        <DialogTitle className={classes.title} disableTypography>
          割引クーポンの<br />
          使い方
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" justyfyContent="center" alignItems="center">
            {createStep(' ステップ1 ')}
            <Typography className={classes.text} variant="body1">
              「クーポン登録」欄より<br />
              クーポンコードを登録
            </Typography>
            <img className={classes.img} src={SmartphoneIcon} width={60} height={60} />
            {createStep(' ステップ2 ')}
            <Typography className={classes.text} variant="body1">
              「登録済みクーポンの利用選択」<br />
              欄より、次回の月額ご利用料金決済時に利用したいクーポンを<br />
              「選択中」にする
            </Typography>
            <img className={classes.img} src={ToggleIcon} width={60} height={60} />
            {createStep(' ステップ3 ')}
            <Typography className={classes.text} variant="body1">
              利用登録は完了！<br />
              次回の月額ご利用料金決済時に「選択中」のクーポンが<br />
              適用されます
            </Typography>
            <img className={classes.img} src={CheckIcon} width={60} height={60} />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions} />
      </Dialog>
    </DialogThemeProvider>
  )
}

HowToDiscountCouponDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
