import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Const from 'commons/constant'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    fontSize: 10,
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginRight: 10,
    '&:after': {
      content: '""',
      width: 126,
      height: 44,
      position: 'absolute',
    },
  },
  fullWidth: {
    minWidth: 128,
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block',
  },
  outlined: {
    border: '1px solid',
    borderRadius: 26,
  },
  contained: {
    color: Const.colors.angel,
    backgroundColor: Const.colors.angelBackground,
    border: '1px solid'
  },
  disabled: {
    color: 'grey',
    border: '1px solid gray',
  }
}))

export default function NaviButton (props) {
  const { naviType, onClick, selected, disabled, fullWidth, children } = props
  const classes = useStyles()
  const color = disabled ? '' : naviType === 'devil' ? Const.colors.devilSecret : Const.colors.angelSecret
  const bgColor = selected ? naviType === 'devil' ? Const.colors.devilBackground2 : Const.colors.angelBackground2 : ''
  return (
    <Button
      className={`${classes.root} ${fullWidth ? classes.fullWidth : null}`}
      classes={{
        outlined: classes.outlined,
        contained: classes.contained,
        disabled: classes.disabled,
      }}
      style={{ color: color, backgroundColor: bgColor }}
      variant={selected ? 'contained' : 'outlined'}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

NaviButton.propTypes = {
  naviType: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.string,
}
