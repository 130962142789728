import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const maxWidth = 1000
const useStyles = makeStyles(theme => ({
  container: {
    maxWidth,
    paddingTop: theme.spacing(3),
    marginLeft: Math.max((window.innerWidth - maxWidth) / 2, theme.spacing(2)),
    marginRight: Math.max((window.innerWidth - maxWidth) / 2, theme.spacing(2)),
  },
}))
export default function PartnerContent (props) {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

PartnerContent.propTypes = {
  children: PropTypes.object,
}
