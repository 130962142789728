import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import ValentineEventBox from 'components/valentine-event/ValentineEventBox'
import BackImageLeftBottom from 'images/valentine-event/back_image_left_buttom.png'
import BackImageLeftTop from 'images/valentine-event/back_image_left_top.png'
import BackImageRightBottom from 'images/valentine-event/back_image_right_bottom.png'
import BackImageRightTop from 'images/valentine-event/back_image_right_top.png'
import KomainuImg from 'images/valentine-event/komainu02.png'
import TitleRibbon from 'images/valentine-event/title_ribbon.png'
import EventImg from 'images/winter-event/event.png'

const useStyles = makeStyles(theme => ({
  paper: {
    width: 328,
    margin: 0,
    backgroundImage: `url(${BackImageLeftTop}), url(${BackImageRightTop}), url(${BackImageLeftBottom}), url(${BackImageRightBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '46px auto',
    backgroundPositionX: '0%, 100%, -2%, 100%',
    backgroundPositionY: '8px, 8px, bottom, bottom',
    '& button': {
      zIndex: 1,
    }
  },
  title: {
    position: 'relative',
    paddingBottom: 0,
  },
  titleText: {
    fontSize: 12,
    color: '#E8476E',
    position: 'absolute',
    top: 38,
    left: '50%',
    transform: 'translate(-50%, -0%)'
  },
  subtitle: {
    marginTop: theme.spacing(3),
  },
  imgA: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  imgB: {
    display: 'block',
    margin: 'auto',
  },
  actions: {
    paddingTop: theme.spacing(0.5),
  },
}))

export default function AnnounceDialog (props) {
  const { open, onConfirm, onClose, missions } = props
  const classes = useStyles()

  if (!missions?.length) { return null }

  // いいね！申請ミッション抽出
  const friendApplyMission = missions.find(mission => mission.mission_type === 'friend_apply')

  const onClick = () => {
    onClose()
    onConfirm()
  }

  return (
    <DialogThemeProvider color="valentine-event">
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle className={classes.title} disableTypography>
          <img src={TitleRibbon} width={216} height={38} />
          <Typography className={classes.titleText} variant="subtitle1">
            イベントのご招待
          </Typography>
        </DialogTitle>
        <DialogContent>
          <ValentineEventBox mission={friendApplyMission} bgimg />
          <Typography className={classes.subtitle} variant="subtitle1">
            「いいね！」送信･返信ごとに<br />
            次回+1名紹介！
          </Typography>
          <img className={classes.imgA} src={EventImg} width={184} />
          <img className={classes.imgB} src={KomainuImg} width={244} />
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button variant="contained" onClick={onClick}>
            イベントページを見る
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

AnnounceDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  missions: PropTypes.array,
}
