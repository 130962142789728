import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import ChatMemoTutorialDialog from 'components/parts/ChatMemoTutorialDialog.jsx'
import FavTutorialDialog from 'components/parts/FavTutorialDialog.jsx'
import FollowAfterDatingDialog from 'components/parts/FollowAfterDatingDialog'
import FriendEntryTutorialDialog from 'components/parts/FriendEntryTutorialDialog.jsx'
import LikesTutorial from 'components/parts/LikesTutorial.jsx'
import NaviIntroDialog from 'components/parts/NaviIntroDialog'
import ProfilePublicTutorial from 'components/parts/ProfilePublicTutorial.jsx'
import SecretQuestionRoomSuggestDialog from 'components/parts/SecretQuestionRoomSuggestDialog'
import WelcomeDialog from 'components/parts/WelcomeDialog'
import PhotoTutorialDialog from 'components/photo-tutorial/PhotoTutorialDialog'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: `0 ${theme.spacing(3.75)}px`,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  komaImg: {
    height: 32,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    height: 44,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(5),
  },
  listItemText: {
    '& span': {
      fontSize: 14,
      fontWeight: 'bold',
    }
  },
  icon: {
    paddingLeft: theme.spacing(1),
    width: 24,
    height: 24,
    '& svg': {
      color: '#423BC7',
    }
  },
  actions: {
    paddingTop: theme.spacing(3),
  },
}))

export default function TutorialDialog (props) {
  const { open, onOpen, onClose, sex, name, user, ...other } = props
  const classes = useStyles()
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false)
  const [openPhotoTutorialDialog, setOpenPhotoTutorialDialog] = useState(false)
  const [openProfilePublicTutorial, setOpenProfilePublicTutorial] = useState(false)
  const [openLikesTutorial, setOpenLikesTutorial] = useState(false)
  const [openFriendEntryTutorial, setOpenFriendEntryTutorial] = useState(false)
  const [openNaviIntroDialog, setOpenNaviIntroDialog] = useState(false)
  const [openDemoDialog, setOpenDemoDialog] = useState(false)
  const [openFavTutorial, setOpenFavTutorial] = useState(false)
  const [openSecretTutorial, setOpenSecretTutorial] = useState(false)
  const [openMemoTutorial, setOpenMemoTutorial] = useState(false)
  const [openFollowAfterDatingDialog, setOpenFollowAfterDatingDialog] = useState(false)

  const createListItem = (label, onClick) => {
    return (
      <ListItem button onClick={onClick} className={classes.listItem}>
        <ListItemText className={classes.listItemText}>{label}</ListItemText>
        <ListItemSecondaryAction className={classes.icon}>
          <ChevronRightIcon style={{ color: '#fff' }} />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  const handleOpenDialog = (target) => {
    if (!target) { return }
    onClose()
    switch (target) {
      case 'welcome':
        setOpenWelcomeDialog(true)
        return
      case 'photo':
        setOpenPhotoTutorialDialog(true)
        return
      case 'profilePublic':
        setOpenProfilePublicTutorial(true)
        return
      case 'likes':
        setOpenLikesTutorial(true)
        return
      case 'friendEntry':
        setOpenFriendEntryTutorial(true)
        return
      case 'naviIntro':
        setOpenNaviIntroDialog(true)
        return
      case 'demo':
        setOpenDemoDialog(true)
        return
      case 'fav':
        setOpenFavTutorial(true)
        return
      case 'secret':
        setOpenSecretTutorial(true)
        return
      case 'memo':
        setOpenMemoTutorial(true)
        return
      case 'followAfterDating':
        setOpenFollowAfterDatingDialog(true)
        return
      default:
        return
    }
  }

  const handleCloseDialog = (target) => {
    if (!target) { return }
    onOpen()
    switch (target) {
      case 'welcome':
        setOpenWelcomeDialog(false)
        return
      case 'photo':
        setOpenPhotoTutorialDialog(false)
        return
      case 'profilePublic':
        setOpenProfilePublicTutorial(false)
        return
      case 'likes':
        setOpenLikesTutorial(false)
        return
      case 'friendEntry':
        setOpenFriendEntryTutorial(false)
        return
      case 'naviIntro':
        setOpenNaviIntroDialog(false)
        return
      case 'demo':
        setOpenDemoDialog(false)
        return
      case 'fav':
        setOpenFavTutorial(false)
        return
      case 'secret':
        setOpenSecretTutorial(false)
        return
      case 'memo':
        setOpenMemoTutorial(false)
        return
      case 'followAfterDating':
        setOpenFollowAfterDatingDialog(false)
        return
      default:
        return
    }
  }

  return (
    <>
      <DialogThemeProvider color="secondary">
        <Dialog open={open}>
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <img src={AImage} className={classes.komaImg} />
              <Typography className={classes.titleText}>チュートリアル</Typography>
              <img src={UnImage} className={classes.komaImg} />
            </Grid>
          </DialogTitle>
          <DialogContent>
            <List className={classes.list}>
              {createListItem('本日の出逢い', () => handleOpenDialog('welcome'))}
              {createListItem('プロフィール写真', () => handleOpenDialog('photo'))}
              {createListItem('プロフィール公開設定', () => handleOpenDialog('profilePublic'))}
              {createListItem('プロフィール「好印象」', () => handleOpenDialog('likes'))}
              {createListItem('「いいね！」を送ろう', () => handleOpenDialog('friendEntry'))}
              {createListItem('会話ナビ', () => handleOpenDialog('naviIntro'))}
              {createListItem('会話ナビ 狛犬アシスト', () => handleOpenDialog('demo'))}
              {createListItem('好感度ナビ', () => handleOpenDialog('fav'))}
              {createListItem('秘密の質問部屋', () => handleOpenDialog('secret'))}
              {createListItem('お相手メモ', () => handleOpenDialog('memo'))}
              {createListItem('デート後フォロー', () => handleOpenDialog('followAfterDating'))}
            </List>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button variant="contained" onClick={() => onClose()}>
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
      <WelcomeDialog
        open={openWelcomeDialog}
        onClose={() => handleCloseDialog('welcome')}
        {...other}
      />
      <PhotoTutorialDialog
        {...other}
        open={openPhotoTutorialDialog}
        onClose={() => handleCloseDialog('photo')}
        type='tutorial'
      />
      <ProfilePublicTutorial
        open={openProfilePublicTutorial}
        onClose={() => handleCloseDialog('profilePublic')}
      />
      <LikesTutorial
        open={openLikesTutorial}
        onClose={() => handleCloseDialog('likes')}
        sex={sex}
      />
      <FriendEntryTutorialDialog
        open={openFriendEntryTutorial}
        onClose={() => handleCloseDialog('friendEntry')}
        name={name}
      />
      <NaviIntroDialog
        open={openNaviIntroDialog}
        onClose={() => handleCloseDialog('naviIntro')}
        sex={sex}
        name={name}
      />
      <NaviIntroDialog
        open={openDemoDialog}
        onClose={() => handleCloseDialog('demo')}
        demoOnly
        sex={sex}
        name={name}
      />
      <FavTutorialDialog
        open={openFavTutorial}
        onClose={() => handleCloseDialog('fav')}
        sex={sex}
      />
      <SecretQuestionRoomSuggestDialog
        open={openSecretTutorial}
        onClose={() => handleCloseDialog('secret')}
      />
      <ChatMemoTutorialDialog
        {...other}
        open={openMemoTutorial}
        onClose={() => handleCloseDialog('memo')}
        showType='tutorial'
        user={user}
      />
      <FollowAfterDatingDialog
        open={openFollowAfterDatingDialog}
        onClose={() => handleCloseDialog('followAfterDating')}
        sex={user.sex}
      />
    </>
  )
}

TutorialDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  sex: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  user: PropTypes.object,
}
