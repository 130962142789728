import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import Const from 'commons/constant'

const useStyles = makeStyles(theme => ({
  progressBar: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(3.375),
    height: '9px !important',
    borderRadius: 5,
    [`& .${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#DBDCFE'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#423BC7'
    },
  },
  previewBox: {
    minHeight: 334,
    width: 198,
    margin: 'auto',
    background: '#fff',
    color: '#414141',
    fontWeight: 'normal',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(1),
    borderRadius: 8,
    fontSize: 14,
  },
  countText: {
    textAlign: 'right',
    height: 35,
    marginTop: theme.spacing(0.75),
  },
}))

export default function Preview (props) {
  const { type, wordType, selectedItems, onNext, onPrev, onClose, template, friend } = props
  const classes = useStyles()
  const [previewText, setPreviewText] = useState('')

  useEffect(() => {
    const pText = [
      wordType === 'casual' ? 'はじめまして！' : 'はじめまして。',
      ...selectedItems.map(item => createText(item)),
      type === 'self-introduction' ? wordType === 'casual' ? 'よろしくお願いします！' : 'よろしくお願いします。' : '',
    ]
    // previewに表示
    setPreviewText(pText.join('\n'))
  }, [])

  const createText = item => {
    const sentence = template.sentence[item?.pattern || item.title]
    if (!sentence) { return '' }

    const sentenceValues = sentence[wordType]
    return sentenceValues.map(value => {
      if (value === 1) { return item.values[0] || '' }
      if (value === 2) { return item.values[1] || '' }
      if (value) { return value.replace('{friend.nick_name}', friend?.nick_name) }
      return ''
    }).filter(t => !!t).join('')
  }

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        {type === 'self-introduction' ? '自己紹介文を作成' : '一言メッセージを作成'}
        <LinearProgress value={95} variant="determinate" className={classes.progressBar} />
        <Typography>プレビュー</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.previewBox}>
          {previewText}
        </Box>
        <Typography className={classes.countText}>
          {previewText.length}/{type === 'self-introduction' ? Const.maxToMessageLength : Const.maxChatMessageLength}
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="contained"
          disabled={!previewText}
          onClick={() => onNext && onNext(previewText)}
        >
          次へ
        </Button>
        <Button onClick={onPrev}>戻る</Button>
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </React.Fragment>
  )
}
Preview.propTypes = {
  type: PropTypes.string.isRequired,
  wordType: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onClose: PropTypes.func,
  template: PropTypes.object,
  friend: PropTypes.object,
}
