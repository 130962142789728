import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 35,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(1)
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(4.5)
  },
  detail: {
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(4)
  },
}))

export default function TheaterTitle (props) {
  const { detail } = props
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.title}>
        Aill シアター
      </Typography>
      <Typography className={classes.subTitle}>
        映画de運命の出会いを
      </Typography>
      {detail && (
        <Typography className={classes.detail}>
          映画予告視聴後に<br />
          アンケートに答えるだけで、<br />
          あなたにぴったりの相手が<br />
          紹介される！<br />
          Aillシアターイベント開催中！
        </Typography>
      )}
    </>
  )
}

TheaterTitle.propTypes = {
  detail: PropTypes.bool
}
