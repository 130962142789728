import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import ExceedMaxFileSizeDialog from 'components/parts/ExceedMaxFileSizeDialog'
import PhotoFilter from 'components/parts/PhotoFilter'
import PhotoFilterEdit from 'components/parts/PhotoFilterEdit'
import 'cropperjs/dist/cropper.css'

const useStyles = makeStyles(theme => ({
  cropper: {
    width: '100%',
    height: 350,
    maxWidth: 500,
  },
  container: {
    fontSize: 14,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textAlign: 'center',
  },
  buttons: {
    flexDirection: 'row',
    padding: theme.spacing(1),
    '& button': {
      margin: '0 !important',
    },
  },
  link: {
    color: '#423BC7',
  },
}))

export default function CropDialog (props) {
  const { open, aspectRatio, photoUrl, showFilters, onUpload, onClose, isSend, originalFile } = props
  const classes = useStyles()
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(false)
  const [brightness, setBrightness] = useState(100)
  const cropperRef = useRef(null)

  useEffect(() => {
    if (!open) { return }
    setSending(false)
    setLoading(true)
  }, [open])

  const rotate = () => {
    cropperRef.current.setAspectRatio(1)
    cropperRef.current.rotate(270)
    cropperRef.current.setAspectRatio(aspectRatio)
  }

  const mimeType = () => {
    return originalFile ? originalFile.type : 'image/png'
  }

  const croppedFileName = () => {
    return originalFile ? `cropped_${originalFile.name}` : 'cropped_photo.png'
  }

  const save = () => {
    if (!cropperRef?.current) { return }
    const cropper = cropperRef.current.cropper
    const canvas = cropper.getCroppedCanvas({
      minWidth: 256,
      minHeight: 256,
      maxWidth: 4096,
      maxHeight: 4096,
    })
    setSending(true)
    props.setLoading(true)
    try {
      // Jestで処理を行わないため
      if (process.env.NODE_ENV === 'test') {
        const file = {
          user_id: 'hoge',
          chat_room_id: 'hoge',
          id: 'hoge',
          preview_url: null,
          large_url: null,
        }
        onUpload(file, { brightness })
      }
      if (canvas.toBlob) {
        const quality = 1
        canvas.toBlob(upload, mimeType(), quality)
      }
    } catch (error) {
      BaseComponent.handleError(props, error)
    } finally {
      setSending(false)
      props.setLoading(false)
    }
  }

  const upload = (blob) => {
    const fileName = croppedFileName()
    const opts = { type: mimeType() }
    const file = new File([blob], fileName, opts)
    onUpload(file, { brightness })
  }

  const disabled = () => {
    if (process.env.NODE_ENV === 'test') { return false }
    if (sending) { return true }
    if (loading) { return true }
    return false
  }

  if (!originalFile) { return null }
  if (Const.maxFileSize < originalFile.size) {
    return (
      <ExceedMaxFileSizeDialog
        open={open}
        onClose={onClose}
      />
    )
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogContent className={classes.container}>
          <PhotoFilter brightness={brightness}>
            <Cropper
              className={classes.cropper}
              viewMode={1}
              aspectRatio={aspectRatio}
              guides={false}
              src={photoUrl}
              ref={cropperRef}
              minCropBoxWidth={100}
              minCropBoxHeight={100}
              ready={() => setLoading(false)}
            />
          </PhotoFilter>
          {showFilters && (
            <PhotoFilterEdit
              brightness={brightness}
              onChangeBrightness={(value) => setBrightness(value)}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button variant="text" disabled={disabled()} onClick={onClose}>
            キャンセル
          </Button>
          <Button className={classes.button} variant="text" disabled={disabled()} onClick={rotate}>
            回転
          </Button>
          <Button variant="text" disabled={disabled()} onClick={save}>
            {isSend ? '送信する' : '保存する'}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

CropDialog.propTypes = {
  open: PropTypes.bool,
  aspectRatio: PropTypes.number,
  photoUrl: PropTypes.string,
  showFilters: PropTypes.bool,
  onUpload: PropTypes.func,
  onClose: PropTypes.func,
  isSend: PropTypes.bool,
  originalFile: PropTypes.object,
  setLoading: PropTypes.func,
}
