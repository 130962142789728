import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Img1 from 'images/img_new_profile_item_dialog_1.png'
import Img2 from 'images/img_new_profile_item_dialog_2.png'
import Img3 from 'images/img_new_profile_item_dialog_3.png'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  message: {
    // marginBottom: theme.spacing(3),
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
}))
export default function NewProfileItemDialog (props) {
  const { open, id, item, text, handleInput, handleClose } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          お知らせ
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.message} variant="body1">
            {text || `プロフィール項目に\n「${item}」が\n追加されました。`}
          </Typography>
          {!text && (
            <Grid>
              <Grid className={classes.flex}>
                <img src={Img1} alt="" />
                <Typography variant="body2">
                  登録すると「いいね！」の<br />返信率が上がるよ！
                </Typography>
                <img src={Img2} alt="" />
              </Grid>
              <Grid className={classes.flex}>
                <img src={Img3} className={classes.icon} alt="" />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions disableSpacing>
          {handleInput && (
            <Button variant="contained" onClick={handleInput}>
              登録する
            </Button>
          )}
          <Button variant="contained" onClick={() => handleClose(id)}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

NewProfileItemDialog.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  item: PropTypes.string,
  text: PropTypes.string,
  handleInput: PropTypes.func,
  handleClose: PropTypes.func,
}
