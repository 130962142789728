import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import CongratulationsImage from 'images/img_congratulations.png'

const styles = theme => ({
  talkButton: {
    marginBottom: theme.spacing(1),
  },
  congratulationsImage:{
    width: 226,
    marginTop: theme.spacing(1.5),
  },
})

export class FriendEstablishedDialog extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.handleTalk = this.handleTalk.bind(this)

    this.state = {
      checkeConsent: false,
      isDisabled: true,
    }
  }

  handleClose () {
    this.props.onClose()
  }

  async handleTalk () {
    await this.loadCurrentFriendDetail()
    this.props.setScreen('Chat')
  }

  render () {
    const { classes, open } = this.props
    return (
      <DialogThemeProvider color="primary">
        <Dialog onClose={this.handleClose} open={open}>
          <DialogTitle disableTypography>トーク開通！</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">
              おめでとう！<br/>
              早速メッセージを送ろう！
            </Typography>
            <Grid container direction='column' alignItems="center">
              <img
                src={CongratulationsImage}
                alt="congratulations"
                className={classes.congratulationsImage}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container direction='column' alignItems="center">
              <Grid item className={classes.button}>
                <Button variant="contained" className={classes.talkButton} onClick={this.handleTalk}>
                  トーク開始
                </Button>
              </Grid>
              <Grid item className={classes.button}>
                <Button variant="text" onClick={this.handleClose}>
                  あとで
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}
FriendEstablishedDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}
export default withStyles(styles)(FriendEstablishedDialog)