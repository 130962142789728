import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import Const from 'commons/constant'
import * as storage from 'commons/storage'
import Storage from 'commons/storage'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntrySteps from 'components/entry/EntrySteps'
import SubPhotos from 'components/entry/SubPhotos'
import SubPhotosAdvice from 'components/entry/SubPhotosAdvice'
import NewUpdateDialog from 'components/notifications/NewUpdateDialog'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import ExceedMaxFileSizeDialog from 'components/parts/ExceedMaxFileSizeDialog'
import PhotoDialog from 'components/parts/PhotoDialog'
import TutorialPage from 'components/photo-tutorial/TutorialPage'
import Kagura from 'images/img_character_a.png'
import Sakaki from 'images/img_character_un.png'
import exIconX from 'images/img_photo_exX.png'
import PhotoUpdateImg from 'images/Komainu/photoSelectDialogImg.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import TutorialImage from 'images/tutorial/img_message_tutorial.png'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(15),
    maxWidth: 700,
    margin: 'auto',
    overflowX: 'hidden',
  },
  komainuImg: {
    maxWidth: 38,
    maxHeight: 32,
  },
  titleContainer: {
    paddingTop: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(1.25),
  },
  ngContainer: {
    maxWidth: 300,
    backgroundColor: '#F5F5F5',
    borderRadius: 12,
    paddingTop: theme.spacing(3.25),
    paddingBottom: theme.spacing(3.5),
    marginTop: theme.spacing(2),
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
  },
  subTitle: {
    fontSize: 12,
    color: '#707070',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  imageItem: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4.75),
  },
  iconX: {
    width: 18,
    height: 18,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  ngText: {
    fontSize: 14,
    color: '#707070',
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  adviceContainer: {
    maxWidth: 300,
    backgroundColor: '#DBDCFE',
    backgroundImage: `url(${TutorialImage})`,
    backgroundSize: 'contain',
    borderRadius: 12,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(8),
    marginRight: 'auto',
    marginLeft: 'auto',
    boxShadow: '0 1px 6px #00000029',
  },
  footImg: {
    width: 18,
    height: 18,
  },
  adviceTitle: {
    fontSize: 12,
    color: '#1A1667',
    fontWeight: 'bold',
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  tutorialButton: {
    width: 230,
    height: 34,
    fontSize: 12,
    borderRadius: 21,
    display: 'block',
    padding: 0,
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  purpleButton: {
    color: '#3C0077',
    background: 'rgba(162,105,218,0.44)',
    boxShadow: '0 1px 6px #00000029',
    '&:hover': {
      background: 'rgba(162,105,218,0.44)',
    }
  },
  blueButton: {
    color: '#060179',
    background: 'rgba(88,155,251,0.44)',
    boxShadow: '0 1px 6px #00000029',
  },
  textButton: {
    height: 'fit-content',
    color: '#060179',
    textDecoration: 'underline',
  },
  buttons: {
    maxWidth: 300,
    position: 'fixed',
    bottom: 28,
    left: '50%',
    transform: 'translate(-50%, -0%)',
  },
  selectButton: {
    ...theme.styles.entryButton,
    fontSize: 14,
    fontWeight: 'bold',
    width: 230,
    height: 44,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  backOnly: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: 160,
    maxHeight: 44,
    marginTop: theme.spacing(-2),
  },
  back: {
    fontSize: 16,
    fontWeight: 600,
    width: 100,
    height: 44,
  },
  next: {
    fontSize: 16,
    fontWeight: 600,
    width: 100,
    height: 44,
  },
  avatarArea: {
    marginTop: theme.spacing(2.5),
    '& img': {
      display: 'block',
    },
  },
  naviText: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'block',
  }
}))

export default function Photo (props) {
  const { idText, ...other } = props
  const {
    identification_status,
    pending_reasons,
    photo_icon_to_change,
    photo_large_to_change,
    sex
  } = props.user
  const classes = useStyles()
  const [isPending, setIsPending] = useState(false)
  const [pendingReason, setPendingReason] = useState('')
  const [photoIcon, setPhotoIcon] = useState(null)
  const [photoLarge, setPhotoLarge] = useState(null)
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)
  const [tutorial, setTutorial] = useState(false)
  const [tutorialIsBad, setTutorialIsBad] = useState(false)
  const [photoUpdateFlag, setPhotoUpdateFlag] = useState(false)
  const [openExceedFileSizeDialog, setOpenExceedFileSizeDialog] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  useEffect(() => {
    if (identification_status === 'pending' && !pending_reasons.includes('profile') && !storage.getShowPendingDialog()) {
      const reason = pending_reasons.map(val => {
        switch (val) {
          case 'photo':
            return '本人写真'
          case 'gallery_item':
            return 'サブ写真'
          case 'identification':
            return idText?.label
          default:
            return null
        }
      }).filter(v => !!v).join('・')
      setIsPending(true)
      setPendingReason(reason)
    }
    setPhotoIcon(photo_icon_to_change)
    setPhotoLarge(photo_large_to_change)
    // PhotoConfirmから遷移した場合(写真トリミング確定後)のみポップアップ表示
    if (Storage.entrySiteSubmitPhotoFlag.value) {
      setPhotoUpdateFlag(true)
    }
  }, [])

  const handleReadImportedFile = (event) => {
    const file = event.target.files[0]
    if (!file) { return }
    if (Const.maxFileSize < file.size) {
      setOpenExceedFileSizeDialog(true)
      return
    }
    props.setUserImage(file)
    props.setScreen('PhotoConfirm')
  }

  const dialogClose = () => {
    storage.setShowPendingDialog(true)
    setIsPending(false)
    setPendingReason('')
  }

  const clickTutorial = () => {
    setTutorial(true)
    setTutorialIsBad(false)
  }

  const clickTutorialBad = () => {
    setTutorial(true)
    setTutorialIsBad(true)
  }
  const clickBack = () => {
    setTutorial(false)
  }

  const selectPhoto = () => {
    storage.setPhotoTutorialDisplayed(true)
    Api.addActionLog('look_tutorial')
  }

  // 「次へ」押下時
  const handleNext = async () => {
    // サブ写真が理由で審査保留中の場合
    if (pending_reasons?.includes('gallery_item')) {
      const updatedUser = await BaseComponent.updateUser(props, {
        gallery_item_fixed: true,
      })
      BaseComponent.toEntryNextStep(props, updatedUser)
    } else {
      BaseComponent.toEntryNextStep(props, props.user)
    }
  }

  const createItem = (text) => {
    return (
      <Grid item className={classes.imageItem}>
        <img src={exIconX} className={classes.iconX} alt="" />
        <Typography className={classes.ngText}>{text}</Typography>
      </Grid>
    )
  }

  const createAdvice1 = () => {
    return (
      <>
        <Grid container justifyContent="center">
          <img src={FootAImage} className={classes.footImg} alt="" />
          <Typography className={classes.adviceTitle}>カグラとサカキのちょっとアドバイス</Typography>
          <img src={FootUnImage} className={classes.footImg} alt="" />
        </Grid>
        <Button
          className={`${classes.tutorialButton} ${sex === 'female' ? classes.purpleButton : classes.textButton}`}
          onClick={clickTutorial}
        >
          好印象の写真の法則を見てみよう！
        </Button>
        <Button
          className={`${classes.tutorialButton} ${sex === 'female' ? classes.blueButton : classes.textButton}`}
          onClick={clickTutorialBad}
        >
          こんな写真はオススメしないよ。
        </Button>
      </>
    )
  }

  const openTutorialPage = () => {
    const url = Config.serviceSiteUrl + 'photo-tutorial.html'
    window.open(url)
  }

  const createAdvice2 = () => {
    return (
      <>
        <Grid container justifyContent="center">
          <img src={FootAImage} className={classes.footImg} alt="" />
          <Typography className={classes.adviceTitle}>写真がない人におすすめ</Typography>
          <img src={FootUnImage} className={classes.footImg} alt="" />
        </Grid>
        <Button
          variant="contained"
          className={`${classes.tutorialButton} ${classes.purpleButton}`}
          onClick={openTutorialPage}
        >
          好印象な写真を簡単に撮る方法
        </Button>
      </>
    )
  }

  if (!storage.default.enabled) {
    return <DisableCookieScreen />
  }

  return(
    <>
      <div className={classes.root}>
        <AppHeader title="写真登録" hideBack={true} {...other} />
        <EntrySteps step={2} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
        <Grid container justifyContent="center" alignItems="center" className={classes.titleContainer}>
          <img className={classes.komainuImg} src={Kagura} />
          <Typography className={classes.title}>
            マッチングしやすい<br />
            メイン写真を登録しよう
          </Typography>
          <img className={classes.komainuImg} src={Sakaki} />
        </Grid>
        {photoIcon ? (
          <>
            <Grid container direction='column' alignItems="center" justifyContent="center">
              <Grid item className={classes.avatarArea}>
                <img src={photoIcon} width={164} height={164} onClick={() => setOpenPhotoDialog(true)} />
              </Grid>
              <Button
                variant="contained"
                color="secondary"
                className={classes.selectButton}
                htmlFor="file"
                component="label"
                onClick={selectPhoto}
              >
                メイン写真を登録する
              </Button>
            </Grid>
            <Typography className={classes.title}>
              マッチング率アップ！<br />
              サブ写真を登録しよう！
            </Typography>
            <SubPhotos {...props} />
            <SubPhotosAdvice {...props} />
            <Grid className={classes.buttons} container alignItems="center" justifyContent="space-between">
              <Button
                variant="contained"
                color="secondary"
                className={classes.back}
                onClick={() => props.setScreen('Love')}
              >
                戻る
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.next}
                onClick={handleNext}
              >
                次へ
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Typography className={classes.text}>入会後も変更は可能です。</Typography>
            <div className={classes.ngContainer}>
              <Typography className={classes.subTitle}>審査NGな写真例</Typography>
              {createItem('個人情報が入っている')}
              {createItem('本人以外（芸能人など）')}
              {createItem('他人の顔が分かる')}
              {createItem('著作物が写り込んでいる')}
              {createItem('顔がわからない（後ろ姿など）')}
            </div>
            <div className={classes.adviceContainer}>
              {sex === 'female' ? (
                <>
                  {createAdvice1()}
                  {createAdvice2()}
                </>
              ) : (
                <>
                  {createAdvice2()}
                  {createAdvice1()}
                </>
              )}
            </div>
            <TutorialPage open={tutorial} clickBack={clickBack} tutorialIsBad={tutorialIsBad} />
            <Grid className={classes.buttons} container direction='column' alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                className={classes.selectButton}
                htmlFor="file"
                component="label"
                onClick={selectPhoto}
              >
                メイン写真を登録する
              </Button>
              <Button
                variant="text"
                color="secondary"
                className={classes.backOnly}
                onClick={() => props.setScreen('Love')}
              >
                戻る
              </Button>
            </Grid>
          </>
        )}
      </div>
      <form noValidate autoComplete="off">
        <input
          id="file"
          type="file"
          accept="image/*"
          onChange={handleReadImportedFile}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
            display: 'none',
          }}
          data-testid="photo_icon"
        />
      </form>
      <ConfirmDialog
        open={isPending}
        onClose={dialogClose}
        onOk={dialogClose}
        onCancel={dialogClose}
        confirm="yes"
        title="入会審査保留"
        message={`この度はAillの入会登録、ありがとうございます。\nご登録いただいた${pendingReason}に不備がございました。再度添付をお願いいたします。`}
      />
      <PhotoDialog
        contentId={'PhotoDialog'}
        open={openPhotoDialog}
        imageUrl={photoLarge}
        onClose={() => setOpenPhotoDialog(false)}
      />
      <NewUpdateDialog
        open={photoUpdateFlag}
        title={'プロフィール写真を\n登録しました'}
        imageSrc={PhotoUpdateImg}
        imgSrcSize={182}
        onClose={() => {
          setPhotoUpdateFlag(false)
          Storage.entrySiteSubmitPhotoFlag.clear()
        }}
      >
        <span className={classes.naviText}>
          サブ写真に複数写真を登録しよう！<br />
          写真を掲載するほどマッチング率UP
        </span>
      </NewUpdateDialog>
      <ExceedMaxFileSizeDialog
        open={openExceedFileSizeDialog}
        onClose={() => setOpenExceedFileSizeDialog(false)}
      />
    </>
  )
}

Photo.propTypes = {
  user: PropTypes.object,
  idText: PropTypes.object,
  setUserImage: PropTypes.func,
  setScreen: PropTypes.func,
}
