import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: `calc(${theme.styles.header.height}px + ${theme.spacing(3)}px)`,
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
})

export class Terms extends BaseComponent {
  componentDidMount () {
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  render () {
    const { classes, ...other } = this.props
    return (
      <React.Fragment>
        <AppHeader title="利用規約" backWhite={true} {...other} />
        <div className={classes.root}>
          <Typography>
            {this.getTermsMessage()}
          </Typography>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Terms)
