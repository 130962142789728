import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import Api from 'commons/api'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { generateFuzzyRegExp } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import FireImg from 'images/icon_fire.png'

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 500,
    borderRadius: '20px 20px 0 0',
    marginTop: theme.spacing(2.25),
  },
  title: {
    fontSize: 19,
    color: '#1A1667',
    lineHeight: 1,
    paddingTop: theme.spacing(4.5),
  },
  content: {
    height: '100%',
    margin: 'auto',
    paddingRight: theme.spacing(8.25),
    paddingLeft: theme.spacing(8.25),
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1A1667',
    marginBottom: theme.spacing(2.5),
  },
  blueText: {
    '& p': {
      fontSize: 16,
      fontWeight: 600,
      color: '#616AFA',
      lineHeight: 1,
      letterSpacing: '0.35px',
    },
    marginBottom: theme.spacing(3.25),
  },

  box: {
    maxWidth: sp => sp ? 230 : 400,
  },
  accordion: {
    boxShadow: 'none',
    marginTop: '0 !important',
    marginBottom: `${theme.spacing(2.5)}px !important`,
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  accordionSummary: {
    height: 32,
    minHeight: '32px !important',
    padding: 0,
  },
  accordionDetails: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: 0,
    flexWrap: 'wrap',
  },
  categoryLabel: {
    width: '100%',
    maxWidth: 'unset',
    fontSize: 16,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: 1,
    letterSpacing: '0.35px',
    textAlign: 'left'
  },
  subtitle: {
    fontSize: 18,
    color: '#1A1667',
    lineHeight: 1,
    letterSpacing: '0.45px',
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(2.25),
    whiteSpace: 'nowrap',
  },
  searchbox: {
    width: 214,
    height: 43,
    border: 'solid 1px #B0B4FC',
    borderRadius: 8,
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    position: 'relative',
  },
  searchIcon: {
    width: 15,
    height: 15,
    opacity: 0.4,
    position: 'absolute',
    top: '50%',
    left: 16,
    transform: 'translate(0%, -50%)',
    pointerEvents: 'none',
  },
  inputRoot: {
    width: '100%',
    height: '100%',
    color: '#B0B4FC',
  },
  inputInput: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(5),
  },
  chip: {
    fontSize: 12,
    fontWeight: 400,
    border: '1px solid #616AFA',
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    '-webkit-user-select': 'none',
  },
  selected: {
    color: '#ffffff !important',
    background: '#616AFA !important',
    '&:hover': {
      color: '#ffffff !important',
      background: '#616AFA  !important',
    },
  },
  notSelected: {
    color: '#616AFA !important',
    background: '#ffffff !important',
    '&:hover': {
      color: '#616AFA !important',
      background: '#ffffff !important',
    },
  },
}))

export default function InterestedSelectDialog (props) {
  const { open, onClose, selectedItems, setSelectedItems } = props
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)
  const [searchWord, setSearchWord] = useState('')
  const [top20, setTop20] = useState([])
  const [categories, setCategories] = useState([])
  const [allInterestedThings, setAllInterestedThings] = useState([])

  useEffect(() => {
    (async () => {
      props.setLoading(true)
      try {
        const res = await Api.getInterestedThings()
        setAllInterestedThings(res)
        // 上位20位の抽出
        setTop20(res.sort((a, b) => b.user_count - a.user_count).slice(0, 20))
        // カテゴリのリスト作成
        const set = new Set()
        res.map(data => data.categories.map(d => set.add(d)))
        set.add('その他')
        setCategories(Array.from(set))
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
  }, [])

  const handleSelect = (value) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter(item => item !== value))
    } else {
      if (selectedItems.length >= 10) { return }
      setSelectedItems([...selectedItems, value])
    }
  }

  const filterItems = (event) => {
    const value = event.target.value
    setSearchWord(value)
  }

  const createSeachBox = () => (
    <div className={classes.searchbox}>
      <SearchIcon className={classes.searchIcon} />
      <Input
        placeholder="検索"
        disableUnderline
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        value={searchWord}
        onChange={filterItems}
        inputProps={{ 'data-testid': 'searchbox' }}
      />
    </div>
  )

  const createChip = (label) => {
    const selected = selectedItems.includes(label)
    return (
      <Chip
        className={`${classes.chip} ${selected ? classes.selected : classes.notSelected}`}
        key={label}
        label={label}
        onClick={() => handleSelect(label)}
        data-testid={`chip-${label}`}
      />
    )
  }

  const createCategory = (label) => {
    if (allInterestedThings.length === 0) { return null }
    const items = allInterestedThings.filter(d => {
      if (label === 'その他') { return d.categories.length === 0 }
      return d.categories.includes(label)
    })
    return (
      <Accordion className={classes.accordion} TransitionProps={{ timeout: 500 }} data-testid={`category-${label}`}>
        <AccordionSummary className={classes.accordionSummary} id="label" expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.categoryLabel}>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {items.map(item => createChip(item.value))}
        </AccordionDetails>
      </Accordion>
    )
  }

  const createFilteredChips = () => {
    const regExp = generateFuzzyRegExp(searchWord)
    const items = allInterestedThings.filter(d => {
      if (d.value.match(regExp)) { return true }
      return false
    })
    return items.map(i => createChip(i.value))
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} fullScreen PaperProps={{ className: classes.paper }} data-testid="interested-select-dialog">
        <DialogCloseButton onClick={onClose} />
        <DialogTitle className={classes.title} disableTypography>
          興味のあることを登録しよう！
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography className={classes.text}>
            趣味が似ている同士の紹介、<br />
            会話のきっかけにも！（最大10個）
          </Typography>
          {top20.length !== 0 && (
            <>
              <Grid className={classes.blueText} container justifyContent="center" alignItems="center">
                <Typography>今人気！ 人気のタグ20位</Typography>
                <img src={FireImg} width={26} height={26} />
              </Grid>
              <div className={classes.box} data-testid="top20">
                {top20.map(d => createChip(d.value))}
              </div>
            </>
          )}
          {(allInterestedThings.length !== 0 && categories.length !== 0) && (
            <>
              <Typography className={classes.subtitle}>
                他の興味タグはこちらから!
              </Typography>
              {createSeachBox()}
              {searchWord ? (
                <div className={classes.box}>
                  {createFilteredChips()}
                </div>
              ) : (
                <div className={classes.box}>
                  {categories.map(label => createCategory(label))}
                </div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onClose} data-testid="next-button">
            次へ
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

InterestedSelectDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  setLoading: PropTypes.func,
}
