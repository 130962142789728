import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as Storage from 'commons/storage'
import { copyToClipboard } from 'commons/utility'
import CopyImg from 'images/icon_copy.png'
import MemoImg from 'images/icon_memo.png'
import PartCopyImg from 'images/icon_part_copy.png'
import ReplyImg from 'images/icon_reply.png'
import TrashImg from 'images/icon_trash.png'
import BaseComponent from './BaseComponent'

const styles = theme => ({
  icon : {
    color: '#423BC7',
  },
  text: {
    fontSize: 12,
    color: '#1A1667',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  listMemo: {
    backgroundColor: 'rgba(162,105,218,0.2)',
  },
  menuStyle: {
    '& .MuiPaper-root': {
      width: theme.spacing(24.5),
      backgroundColor: '#DBDCFE',
      boxShadow: 'none',
      borderRadius: 16,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(3),
    }
  },
  blurOff: {
    '& div.MuiBackdrop-root': {
      backgroundColor: 'transparent',
    }
  },
  backdrop: {
    zIndex: 1101, // ヘッダーより上
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  partCopy: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1102, // Backdropより上
    width: 'max-content',
    maxWidth: 273,
    maxHeight: window.innerHeight - 80,
    overflow: 'scroll',
    '& div': {
      width: '100%',
      maxWidth: 'none',
      backgroundColor: '#ffffff',
      margin: 0,
      filter: 'none !important',
      border: 'none !important',
      '-webkit-user-select': 'auto !important',
      '& p': {
        color: 'rgb(65, 65, 65) !important',
      },
      '& span': {
        '-webkit-user-select': 'auto  !important',
        '& p': {
          color: 'rgb(65, 65, 65) !important',
        },
      },
    },
  },
})

// トークメッセージのコンテキストメニューとして表示するダイアログ
class MessageMenuDialog extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = {
      firstTouch: true,
      openBackdrop: false,
    }
  }

  async reply () {
    Storage.setReplyMessage(this.props.message.chat_room_id, this.props.message)
    this.props.onClose()
  }

  async copy () {
    try {
      const text = this.props.message.message
      await copyToClipboard(text)
      BaseComponent.showRequestSuccessMessage(this.props, 'コピーしました')
    } catch (error) {
      BaseComponent.showErrorMessage(this.props, error)
    }
    this.props.onClose()
  }

  partCopy () {
    this.props.onClose()
    const el = this.props.anchorEl.cloneNode(true)
    const div = document.getElementById('part-copy')
    if (div.hasChildNodes()) { return }
    div.appendChild(el)
    this.setState({ openBackdrop: true })

    console.log('div.firstChild: ', div.firstChild)

    const selection = window.getSelection()
    selection.removeAllRanges()

    const range = document.createRange()
    range.selectNodeContents(div.firstChild)
    selection.addRange(range)
  }

  deleteMessage () {
    if (this.props.onDeleteMessage) {
      this.props.onDeleteMessage(this.props.message)
    }
    this.props.onClose()
  }

  copyToMemo () {
    let elem = document.getElementById(`message-text-${this.props.message.id}`)
    if (elem) {
      let tmpElem = document.createElement('div')
      let html = elem.innerHTML
      html = html.substring(1, html.length - 1)
      let message = ''
      let arr = html.split(/></g)
      for(let i = 0; i < arr.length; i++){
        arr[i] = '<' + arr[i] + '>'
        tmpElem.innerHTML = arr[i]
        if (tmpElem.innerText.length > 0) {
          if (message.length > 0) {
            message += '\n' + tmpElem.innerText
          } else {
            message = tmpElem.innerText
          }
        }
      }
      this.props.onChatMemo(message)
    }
    this.props.onClose()
  }

  onBackdropClick () {
    if (navigator.userAgent.indexOf('iPhone') > 0) {
      if (this.state.firstTouch) {
        this.setState({ firstTouch: false })
      } else {
        this.setState({ firstTouch: true })
        this.props.onClose()
      }
    } else {
      this.props.onClose()
    }
  }

  render () {
    const { user, message, classes } = this.props

    return (
      <>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={this.props.anchorEl}
          open={this.props.open}
          onClose={() => this.props.onClose()}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          className={classes.menuStyle}
          disableAutoFocusItem
        >
          {message.user_id && (
            <MenuItem onClick={() => this.reply()} data-testid="msg-menu-reply">
              <img src={ReplyImg} width={30} height={30} alt="" />
              <Typography className={classes.text}>リプライ</Typography>
            </MenuItem>
          )}
          {message.message_type !== 'image' && (
            <MenuItem onClick={() => this.copy()} data-testid="msg-menu-copy">
              <img src={CopyImg} width={30} height={30} alt="" />
              <Typography className={classes.text}>コピー</Typography>
            </MenuItem>
          )}
          {message.message_type !== 'image' && (
            <MenuItem onClick={() => this.partCopy()} data-testid="msg-menu-part-copy">
              <img src={PartCopyImg} width={30} height={30} alt="" />
              <Typography className={classes.text}>部分コピー</Typography>
            </MenuItem>
          )}
          {message.message_type !== 'image' && (
            <MenuItem onClick={() => this.copyToMemo()} data-testid="msg-menu-save">
              <img src={MemoImg} width={30} height={30} alt="" />
              <Typography className={classes.text}>お相手メモに保存</Typography>
            </MenuItem>
          )}
          {user.id === message.user_id && (
            <MenuItem onClick={() => this.deleteMessage()} data-testid="msg-menu-delete">
              <img src={TrashImg} width={30} height={30} alt="" />
              <Typography className={classes.text}>削除</Typography>
            </MenuItem>
          )}
        </Menu>
        <Backdrop
          className={classes.backdrop}
          open={this.state.openBackdrop}
          onClick={() => {
            const div = document.getElementById('part-copy')
            if (!div.hasChildNodes()) { return }
            div.removeChild(div.firstChild)
            this.setState({ openBackdrop: false })
          }}
        />
        <div id="part-copy" className={classes.partCopy} />
      </>
    )
  }
}

MessageMenuDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.object.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChatMemo: PropTypes.func.isRequired,
}

export default withStyles(styles)(MessageMenuDialog)
