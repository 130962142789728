import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InvitationCampaignComponent from 'components/invitation-campaign/InvitationCampaign'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75),
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.styles.footer.height,
    backgroundColor: '#EFF0FF',
    minHeight: window.innerHeight,
  },
}))

export default function InvitationCampaign (props) {
  const { ...other } = props
  const classes = useStyles()

  useEffect(() => {
    BaseComponent.addVisitPageLog()

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  return (
    <div className={classes.root}>
      <AppHeader title="キャンペーン詳細" {...other} />
      <InvitationCampaignComponent {...props} />
    </div>
  )
}

InvitationCampaign.propTypes = {
  setScreen: PropTypes.func,
}
