import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as Offer from 'components/special-offer/index'

const useStyles = makeStyles(() => {
  const soTheme = Offer.theme()
  const banTxtColor = soTheme.bannerTextColor
  return {
    root: {
      height: 80,
      backgroundImage: `url(${soTheme.topBannerImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPositionX: 'center',
      position: 'relative',
      boxSizing: 'border-box',
    },
    subTitle: {
      fontSize: 12,
      fontWeight: 600,
      color: '#ffffff',
      lineHeight: 1,
      marginBottom: 6,
      textShadow: `1px 1px 0 ${banTxtColor}, -1px -1px 0 ${banTxtColor}, -1px 1px 0 ${banTxtColor}, 1px -1px 0 ${banTxtColor}, 1px 0 0 ${banTxtColor}, -1px 0 0 ${banTxtColor}, 0 1px 0 ${banTxtColor}, 0 -1px 0 ${banTxtColor}`
    },
    title: {
      fontSize: 17,
      fontWeight: 600,
      color: '#ffffff',
      lineHeight: 1,
      marginBottom: 11,
      textShadow: `1px 1px 0 ${banTxtColor}, -1px -1px 0 ${banTxtColor}, -1px 1px 0 ${banTxtColor}, 1px -1px 0 ${banTxtColor}, 1px 0 0 ${banTxtColor}, -1px 0 0 ${banTxtColor}, 0 1px 0 ${banTxtColor}, 0 -1px 0 ${banTxtColor}`
    },
    text: {
      fontSize: 13,
      fontWeight: 600,
      color: '#ffffff',
      lineHeight: 1,
      textShadow: `1px 1px 0 ${banTxtColor}, -1px -1px 0 ${banTxtColor}, -1px 1px 0 ${banTxtColor}, 1px -1px 0 ${banTxtColor}, 1px 0 0 ${banTxtColor}, -1px 0 0 ${banTxtColor}, 0 1px 0 ${banTxtColor}, 0 -1px 0 ${banTxtColor}`
    },
  }
})

export default function SpecialOfferBanner (props) {
  const { offer } = props
  const classes = useStyles()

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          props.setCampaign(offer)
          props.setScreen('SpecialOffer')
        }}
      >
        <Typography className={classes.subTitle}>あなただけの</Typography>
        <Typography className={classes.title}>スペシャルオファー</Typography>
        <Typography className={classes.text}>出会いを増やそう！</Typography>
      </Grid>

    </>
  )
}

SpecialOfferBanner.propTypes = {
  offer: PropTypes.object,
  setCampaign: PropTypes.func,
  setScreen: PropTypes.func,
}
