import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'
import { IconButton, ImageListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import PhotoFilter from 'components/parts/PhotoFilter'
import PhotoStatus from 'components/parts/PhotoStatus'

const styleImgGallery = {
  height: '100%',
  minHeight: '100%',
  width: '100%',
  objectFit: 'cover',
  backgroundColor: '#C6C6C6',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
}

const ueStyles = makeStyles(theme => ({
  ImageListItem: {
    textAlign: 'center',
    width: '100%',
    padding: `0 ${theme.spacing(0.625)}px`,
  },
  imgContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    position: 'relative',
  },
  imgContainerSelected: {
    width: '100%',
    height: 0,
    paddingBottom: 'calc(100% - 4px)',
    position: 'relative',
    boxSizing: 'border-box',
    border: '2px solid #3D45C2',
  },
  imgGallery: styleImgGallery,
  imgGalleryAdd: {
    ...styleImgGallery,
    position: 'absolute',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  addIconButton: {
    width: '100%',
    height: '100%',
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 40,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
}))
export const SortableGalleryItem = (props) => {
  const {
    user,
    isActive,
    galleryItem,
    handleOpenPhotoDialogGallery,
    handleOpenTutorialDialogGallery,
    style: imageListStyle
  } = props
  const classes = ueStyles()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({
    id: galleryItem?.id,
    disabled: !galleryItem?.id
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  // サブ写真: GalleryItem.status, メイン写真: User.photo_status
  const status = galleryItem?.status || user?.photo_status

  return (
    <ImageListItem
      id={galleryItem?.id && `sortable-gallery-${galleryItem.id}`}
      className={classes.ImageListItem}
      classes={{ item: classes.ImageListItem }}
      style={{
        ...imageListStyle,
        padding: 0,
        height: 'auto', ...style
      }}
      onClick={handleOpenPhotoDialogGallery}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <div className={isActive ? classes.imgContainerSelected : classes.imgContainer}>
        {
          galleryItem ? (
            <>
              <PhotoStatus status={status} />
              <PhotoFilter brightness={galleryItem.photo_filter_brightness}>
                <img
                  src={galleryItem.icon_url}
                  className={classes.imgGallery}
                  alt="ギャラリー写真"
                />
              </PhotoFilter>
            </>
          ) : (
            <div className={classes.imgGalleryAdd} onContextMenu={(e) => e.preventDefault()}>
              <IconButton className={classes.addIconButton} aria-label="add" onClick={handleOpenTutorialDialogGallery}>
                <AddIcon className={classes.addIcon}/>
              </IconButton>
            </div>
          )
        }
      </div>
    </ImageListItem>
  )
}

export default SortableGalleryItem

SortableGalleryItem.propTypes = {
  user: PropTypes.object,
  isActive: PropTypes.bool,
  galleryItem: PropTypes.object,
  handleOpenPhotoDialogGallery: PropTypes.func,
  handleOpenTutorialDialogGallery: PropTypes.func,
  style: PropTypes.object,
}