import React from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  infoText: {
    fontSize: 14,
    color: '#1A1667',
    textAlign: 'center'
  },
  planContainer: {
    width: '100%',
    maxWidth: theme.spacing(80),
    background: '#F4F7FE',
    whiteSpace: 'pre-wrap',
    margin: 'auto',
    marginBottom:theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  planTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: '#EFF0FF',
    transform: 'translateY(-40px)',
  },
  planText: {
    fontSize: 14,
    color: '#1A1667',
    marginBottom: theme.spacing(4),
  },
  planInfo: {
    fontSize: 14,
    fontWeight: 'bold',
    width: '100%',
    marginBottom: theme.spacing(0),
    paddingLeft: 16,
    paddingRight: 16,
  },
  planDetailColumn: {
    width: '50%',
    color: '#707070',
  },
  planDetailValue: {
    width: '50%',
    color: '#1A1667',
    textAlign: 'center',
  },
}))

export default function TrialPlanBox () {
  const classes = useStyles()

  const createPlanInfoItem = (column, value) => {
    return (
      <>
        <Grid container>
          <div className={classes.planDetailColumn}>{column}</div>
          <div className={classes.planDetailValue}>{value}</div>
        </Grid>
        <hr size='1' className={classes.divider} />
      </>
    )
  }

  return (
    <>
      <Box className={classes.planContainer} sx={{ px: { xs: 1, sm: 8.375 }, py: 6 }}>
        <div className={classes.planTitle}>フリープラン<br />無料</div>
        <Box className={classes.planInfo} sx={{ px: { xs: 3.5, sm: 7 } }}>
          {createPlanInfoItem('紹介人数', '1人/週')}
          {createPlanInfoItem('トーク人数', '1人')}
          {createPlanInfoItem('会話ナビ', '◯')}
          {createPlanInfoItem('好感度ナビ', '◯')}
        </Box>
      </Box>
    </>
  )
}
