import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import closeRoomDialogImg from 'images/Komainu/closeRoomDialogImg.png'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 13,
    marginTop: theme.spacing(1),
  },
  img: {
    display: 'block',
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  closeButton: {
    color: '#ffffff',
    backgroundColor: '#423BC7 !important',
    border: '2px solid #ffffff',
    marginTop: `${theme.spacing(2)}px !important`,
    marginBottom: `${0}px !important`,
  },
  note: {
    fontSize: 11,
    fontWeight: 300,
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
}))

export default function CloseConfirmDialog (props) {
  const { open, friend, onConfirm, onClose } = props
  const classes = useStyles()

  if (friend.type !== 'closed') { return null }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open} data-testid="cooperate-dialog">
        <DialogTitle disableTypography>
          {friend.nick_name}さんは<br />
          卒業しました
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="subtitle1">
            メッセージが届いているよ。<br />
            確認する？
          </Typography>
          <img className={classes.img} src={closeRoomDialogImg} width={214} alt="" />
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onConfirm}>
            最後のメッセージを見る
          </Button>
          <Button className={classes.closeButton} variant="contained" onClick={onClose}>
            確認せず終了する
          </Button>
          <Typography className={classes.note} variant="subtitle1">
            ＊終了した場合、{friend.nick_name}さんとのトークルームは削除されます。
          </Typography>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

CloseConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  friend: PropTypes.object,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}
