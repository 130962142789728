import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'

const useStyles = makeStyles(theme =>({
  subtitle: {
    fontSize: 15,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  notice: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
}))

// キャリアメールアドレスだった場合に表示するダイアログ
function CarrierMailAddressAttentionDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()

  const openDocomoSite = () => {
    window.open('https://www.docomo.ne.jp/info/spam_mail/spmode/domain/index.html')
  }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          メールアドレスに関する<br />
          注意事項
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            本登録メールが届かない場合は<br />
            以下についてご確認ください。
          </Typography>
          <Typography className={classes.subtitle} variant="body1">
            確認事項
          </Typography>
          <Typography className={classes.notice} variant="body2">
            1. 迷惑メールなど別のフォルダに振り分けられていないか<br />
            2. PCメールアドレスの受信拒否や、高いセキュリティ設定がされてないか<br />
            →「support@aill.ai」を受信許可してください<br />
          </Typography>
          <Typography variant="body2">
            ※特にドコモのメールアドレスで送信エラーが多く発生しています。
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={openDocomoSite}>
            ドコモをご利用の方
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}
CarrierMailAddressAttentionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
export default CarrierMailAddressAttentionDialog