import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import ValentineEventBox from 'components/valentine-event/ValentineEventBox'
import ApplyImg from 'images/valentine-event/apply.png'
import ApproveImg from 'images/valentine-event/approve.png'
import BackImageFull from 'images/valentine-event/back_image_full.png'
import GoodImg from 'images/valentine-event/good_pink.png'
import KomainuImg from 'images/valentine-event/komainu01.png'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundImage: `url(${BackImageFull})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    '& button': {
      zIndex: 1,
    }
  },
  title: {
    padding: theme.spacing(2.5),
  },
  actions: {
    padding: theme.spacing(2),
  },
  img: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(-2.5),
  },
  challenge: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  missionBox: {
    background: '#ffffff',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    padding: theme.spacing(2),
  },
  missionText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#5D0519',
    marginBottom: theme.spacing(1),
  },
  missionStep: {
    width: 48,
    height: 48,
    fontSize: 11,
    fontWeight: 600,
    color: '#FFAABE',
    border: '1px dashed #FFAABE',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(0.75),
    marginLeft: theme.spacing(0.75),
    position: 'relative',
  },
  achievedStep: {
    width: 48,
    height: 48,
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '13px',
    color: '#ffffff',
    background: '#E55E7E',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(0.75),
    marginLeft: theme.spacing(0.75),
  },
  applyIcon: {
    position: 'absolute',
    top: -15,
    left: -12,
  },
  approveIcon: {
    position: 'absolute',
    top: -15,
    right: -14,
  },
  detailBox: {
    background: '#ffffff',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
  },
  detailTitle: {
    color: '#5D0519',
    textAlign: 'left',
    margin: 0,
  },
  detailText: {
    fontWeight: 300,
    color: '#5D0519',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
}))

export default function ValentineEventChallengeDialog (props) {
  const { open, onClose, missions } = props
  const classes = useStyles()

  if (!missions?.length) { return null }

  // いいね！申請ミッション抽出
  const friendApplyMission = missions.find(mission => mission.mission_type === 'friend_apply')
  // いいね！承認ミッション抽出
  const friendApproveMission = missions.find(mission => mission.mission_type === 'friend_approve')

  // 期間
  const startAt = new Date(friendApplyMission?.start_at || '2022-11-22T00:00:00.000+09:00')
  const endAt = addDate(new Date(friendApplyMission.end_at), -1)

  // 進捗
  const friendApplyProgress = friendApplyMission.progress
  const friendApproveProgress = friendApproveMission.progress

  const createMissionBox = (type, achievedStep = 0) => {
    if (!(type === 'friendApply' || type === 'friendApprove')) { return }
    const text = `「いいね！」${type === 'friendApply' ? '送信' : '返信'}ごとに\n次回紹介者+1名`
    const IntArray = [...Array(5)].map((_, i) => i + 1)
    return (
      <Grid className={classes.missionBox} container justifyContent="center" alignItems="center">
        <Typography className={classes.missionText}>{text}</Typography>
        <Grid container justifyContent="center" alignItems="center">
          {IntArray.map(num => (
            <React.Fragment key={num}>
              {num <= achievedStep ? (
                <Typography className={classes.achievedStep}>
                  達成!<br />
                  +1名
                </Typography>
              ) : (
                <Typography className={classes.missionStep}>
                  {type === 'friendApply' && <img className={classes.applyIcon} src={ApplyImg} width={43} />}
                  {type === 'friendApprove' && <img className={classes.approveIcon} src={ApproveImg} width={43} />}
                  <img src={GoodImg} width={16} />
                  {num}人目
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <DialogThemeProvider color="valentine-event">
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle className={classes.title} />
        <DialogContent>
          <ValentineEventBox mission={friendApplyMission} />
          <img className={classes.img} src={KomainuImg} width={230} />
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ1</Typography>
          {createMissionBox('friendApply', friendApplyProgress)}
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ2</Typography>
          {createMissionBox('friendApprove', friendApproveProgress)}
          <Typography className={classes.challenge} variant="subtitle1">チャレンジ詳細</Typography>
          <div className={classes.detailBox}>
            <Typography className={classes.detailTitle} variant="body1">期間</Typography>
            <Typography className={classes.detailText} variant="body1">
              {dateFormat(startAt, 'yyyy/mm/dd')} ~ {dateFormat(endAt, 'yyyy/mm/dd')}
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">イベント内容</Typography>
            <Typography className={classes.detailText} variant="body1">
              1.対象期間内に1人に「いいね！」送信するごとに、次回紹介者が+1名。（最大5人まで）<br />
              2.対象期間内に1人に「いいね！」返信するごとに、次回紹介者が+1名。（最大5人まで）<br />
            </Typography>
            <Typography className={classes.detailTitle} variant="body1">注意事項</Typography>
            <Typography className={classes.detailText} variant="body1">
              1.ご利用のプラン内容や、イベントの発生タイミングや、「いいね！」の受信数によって、全てのチャレンジを完了できない場合があります。<br />
              2.追加紹介は、「いいね！」送信もしくは「いいね！」返信が完了した次の紹介タイミングとなります。プラン毎に次回紹介タイミングが異なりますので、ご注意ください。
            </Typography>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions} />
      </Dialog>
    </DialogThemeProvider>
  )
}

ValentineEventChallengeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  missions: PropTypes.array,
}
