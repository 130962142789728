import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import AillGiftCardImage from 'images/gift_card_aill_x2.png'
import StarBucksGiftCardImage from 'images/gift_card_starbucks.png'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  text: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  link: {
    fontSize: 11,
  },
  textButton: {
    marginTop: theme.spacing(1),
  },
}))

export default function CooperateDialog (props) {
  const { open, params, onClose, user } = props
  const classes = useStyles()

  if (!params) { return null }

  const incentiveType = params.incentive_type
  const answerDate = new Date(params.answer_date)
  const googleFormUrl = params.form_url
  const gift = `${params.incentive_name} ${params.incentive_amount.toLocaleString()}`
  const endDate = dateFormat(answerDate, 'm月d日')
  const infoMap = {
    jcb_gift: {
      title: `カップル向けヒアリング\n${params.incentive_amount.toLocaleString()}円分\nギフト券プレゼント！`,
      giftImg: AillGiftCardImage,
      forCouple: true,
      time: 60,
    },
    starbucks_gift: {
      title: `${gift}円分をプレゼント！`,
      giftImg: StarBucksGiftCardImage,
      forCouple: false,
      time: 40,
    }
  }
  const title = infoMap[incentiveType].title
  const giftImg = infoMap[incentiveType].giftImg
  const forCouple = infoMap[incentiveType].forCouple
  const time = infoMap[incentiveType].time

  const openGoogleForm = () => {
    window.open(googleFormUrl)
    onClose()
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} data-testid="cooperate-dialog">
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle disableTypography>
          ヒアリングご協力のお願い
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.subTitle} variant="subtitle1">{title}</Typography>
          <Typography className={classes.text} variant="body1">
            {forCouple && <>この度はおめでとうございます！<br /></>}
            ぜひ {user.nick_name} 様のご意見をAill goenの品質向上に反映させていただけませんか？<br />
            オンラインによるヒアリングをお願いできましたら幸いです。<br />
            ご協力いただけます場合は{gift}円分をプレゼントいたします！<br />
            <br />
            {endDate}までに下記URLより返信ください。<br />
            <u className={classes.link} onClick={openGoogleForm}>{googleFormUrl}</u>
          </Typography>
          <img src={giftImg} width="100%" alt="" />
          <Typography variant="body2">
            ＊所要時間は{time}分程度です。<br />
            {forCouple && (
              <>
                ＊お二人一緒にご参加をお願いいたします。お二人で日程調整いただき、代表者一名がご返信ください。<br />
                ＊ヒアリングの詳細は返信いただいた方にお送りいたします。<br />
              </>
            )}
            ＊本ヒアリング結果はサービス向上にのみ利用させていただきます。<br />
            ＊本ヒアリング結果は匿名化し公表する場合がございます。<br />
            ＊お知らせ一覧からもご確認いただけます。
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={openGoogleForm}>
            申し込む
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

CooperateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  params: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}
