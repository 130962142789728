import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Api from 'commons/api'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  link: {
    fontSize: 12,
    width: 'fit-content',
    display: 'block',
    textDecoration: 'underline',
    marginBottom: theme.spacing(0.5),
  },
}))

export default function ShopLinks  (props) {
  const classes = useStyles()
  const { shopIds, textColor } = props
  const [shopList, setShopList] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const params = '?campaign_type=jr_west_poc'
        const data = await Api.getShops(params)
        setShopList(data.shops)
      } catch (e) {
        BaseComponent.handleApiError(props, e)
      }
    })()
  }, [])

  const handleClick = (shopData) => {
    props.setShopDetail(shopData)
    props.setScreen('ShopDetail')
  }

  return (
    <div className={classes.container}>
      {shopList.length > 0 && shopList.map((shopData, index) => {
        if (shopIds.includes(String(shopData.id))) {
          return (
            <a
              className={classes.link}
              key={index}
              onClick={() => handleClick(shopData)}
              style={{
                color: textColor,
              }}
            >
              {shopData.shop_name}
            </a>
          )
        }
      })}
    </div>
  )
}

ShopLinks.propTypes = {
  shopIds: PropTypes.array,
  textColor: PropTypes.string,
  setShopDetail: PropTypes.func,
  setScreen: PropTypes.func,
}
