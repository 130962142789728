import React from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from 'react-swipeable-list'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Api from 'commons/api'
import Const from 'commons/constant'
import { isEmpty } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import IconX from 'images/img_photo_exX.png'
import 'react-swipeable-list/dist/styles.css'

const urlRegexp = /https?:\/\/\S+/g

const styles = theme => ({
  paper: {
    width: '100%',
    maxWidth: '100%',
    margin: 0,
    height: `calc(${window.innerHeight}px - ${theme.styles.header.height}px + 20px)`,
    maxHeight: '100%',
    borderRadius: 0,
    position: 'fixed',
    bottom: 0,
  },
  scroll: {
    padding: 0,
  },
  titleContainer: {
    position: 'fixed',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    height: theme.spacingHeight(7),
    backgroundColor: theme.palette.primary.main,
    zIndex: 1000,
  },
  iconX: {
    position: 'absolute',
    right: theme.spacing(3),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  title: {
    ...theme.styles.fontSize(17),
    fontWeight: 'bold',
    ...theme.styles.messageColor,
    textAlign: 'center',
  },
  buttonContainer: {
    bottom: theme.spacingHeight(2.625),
    position: 'fixed',
    '& .MuiButton-contained.Mui-disabled': {
      color: theme.palette.primary.main,
      backgroundColor: '#E0E0E0',
    }
  },
  addButton: {
    ...theme.styles.fontSize(16),
    backgroundColor: '#0C6EDF',
    fontWeight: 'bold',
    width: theme.spacing(42.25),
    height: theme.spacingHeight(5.625),
  },
  memoContainer: {
    marginTop: theme.spacingHeight(7),
    marginBottom: theme.spacingHeight(10),
  },
  addContainer: {
    marginBottom: theme.spacingHeight(1.25),
  },
  addCell: {
    position: 'relative',
    width: theme.spacing(42.25),
    minHeight: theme.spacingHeight(24.75),
    backgroundColor: 'rgba(171, 205, 253, 0.3)',
    borderRadius: theme.spacing(2.75),
    paddingTop: theme.spacingHeight(4),
    paddingBottom: theme.spacingHeight(4),
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
  },
  cell: {
    position: 'relative',
    width: theme.spacing(42.25),
    backgroundColor: 'rgba(171, 205, 253, 0.3)',
    borderRadius: theme.spacing(2.75),
    paddingTop: theme.spacingHeight(4),
    paddingBottom: theme.spacingHeight(4),
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    '& .MuiInputBase-root.Mui-disabled': {
      color: Const.colors.devil,
    }
  },
  cellTitle: {
    color: Const.colors.devil,
    fontWeight: 'bold',
    ...theme.styles.fontSize(16),
    lineHeight: 1.75,
    '& p': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  cellText: {
    color: Const.colors.devil,
    ...theme.styles.fontSize(14),
    lineHeight: 1.75,
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      backgroundImage: `repeating-linear-gradient(
        transparent,
        transparent calc(1.5rem - 1px),
        rgba(98, 106, 247, 0.34) calc(1.5rem - 1px),
        rgba(98, 106, 247, 0.34) 1.5rem,
        transparent 1.5rem
      )`,
      backgroundOrigin: 'content-box',
      backgroundClip: 'content-box',
      backgroundAttachment: 'local',
      lineHeight: '1.5rem',
      display: 'block',
    }
  },
  addText: {
    color: Const.colors.devil,
    ...theme.styles.fontSize(14),
    lineHeight: 1.75,
    '& textarea': {
      backgroundImage: `repeating-linear-gradient(
        transparent,
        transparent calc(1.5rem - 1px),
        rgba(98, 106, 247, 0.34) calc(1.5rem - 1px),
        rgba(98, 106, 247, 0.34) 1.5rem,
        transparent 1.5rem
      )`,
      backgroundOrigin: 'content-box',
      backgroundClip: 'content-box',
      backgroundAttachment: 'local',
      lineHeight: '1.5rem',
    }
  },
  divider: {
    backgroundColor: 'rgba(98, 106, 247, 0.6)',
  },
  close: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  check: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  iconButton: {
    color: Const.colors.devil,
  },
  iconButtonDisabled: {
    color: 'inherit',
  },
  confirmButtons: theme.styles.dialogActions2Buttons,
  confirmOkButton: theme.styles.dialogOkButton,
  iconContainer: {
    width: theme.spacing(8),
  },
})

const maxTitleLength = 100
const maxTextLength = 1000

export class ChatMemo extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleAddMemo = this.handleAddMemo.bind(this)
    this.handleEditMemo = this.handleEditMemo.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.onChangeTitle = this.onChangeTitle.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this)
    this.handleDeleteOk = this.handleDeleteOk.bind(this)
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
    this._isMounted = false

    this.inputRef = React.createRef()
    this.elementKey = 0

    this.state = {
      addMemo: false,
      memoId: null,
      memoTitle: '',
      memoText: '',
      memos: [],
      deleteOpen: false,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.loadMemos()
    Api.addActionLog('visit_page', '/ChatMemo', {
      friend_user_id: this.props.friend.id
    })
    BaseComponent.gaModalView('ChatMemo')
  }

  async componentWillUnmount () {
    this._isMounted = false
    Api.addActionLog('leave_page', '/ChatMemo', {
      friend_user_id: this.props.friend.id
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.props.text) !== JSON.stringify(prevProps.text)) {
      if (this.props.text) {
        this.setStateIfMounted({
          memoText: this.props.text,
          addMemo: true
        })
      } else {
        this.setStateIfMounted({
          memoText: '',
          addMemo: false
        })
      }
    }
    if (JSON.stringify(this.props.open) !== JSON.stringify(prevProps.open)) {
      if (this.props.open) {
        this.loadMemos()
      }
    }
    if (JSON.stringify(this.state.addMemo) !== JSON.stringify(prevState.addMemo)) {
      if (this.state.addMemo) {
        let input = this.inputRef.current
        input && input.focus()
      }
    }
    if (JSON.stringify(this.props.demoStatus) !== JSON.stringify(prevProps.demoStatus)) {
      this.displayDemo(prevProps.demoStatus, this.props.demoStatus)
    }
  }

  handleClose () {
    this.props.onClose()
  }

  handleAddMemo () {
    if (Number.isInteger(this.props.demoStatus)) return
    this.setStateIfMounted({
      memoTitle: '',
      memoText: '',
      memoId: null,
      addMemo: true
    })
    this.scrollToTop()
  }

  handleEditMemo (memo) {
    this.setStateIfMounted({
      memoTitle: memo.title,
      memoText: memo.text,
      memoId: memo.id,
      addMemo: true
    })
    this.scrollToTop()
  }

  handleCancel () {
    this.setStateIfMounted({
      memoTitle: '',
      memoText: '',
      memoId: null,
      addMemo: false
    })
  }

  async handleAdd () {
    if (Number.isInteger(this.props.demoStatus)) return
    let title = this.state.memoTitle
    let text = this.state.memoText
    let Id = this.state.memoId
    if (isEmpty(title)) return
    if (isEmpty(text)) return

    try {
      if (isEmpty(Id)) {
        let params = {
          chat_room_id: this.props.friend.chat_room_id,
          user_id: this.props.user.id,
          title: title,
          text: text,
        }
        await Api.createChatMemo(params)
      } else {
        let params = {
          title: title,
          text: text,
        }
        await Api.updateChatMemo(Id, params)
      }
      this.loadMemos()
      this.setStateIfMounted({
        memoTitle: '',
        memoText: '',
        memoId: null,
        addMemo: false
      })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  async loadMemos () {
    if (Number.isInteger(this.props.demoStatus)) return
    try {
      let chat_room_id = this.props.friend.chat_room_id
      let memos = await Api.getChatMemos(chat_room_id)
      this.setStateIfMounted({
        memos: memos
      })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  handleDeleteOpen (id) {
    if (Number.isInteger(this.props.demoStatus)) return
    this.setStateIfMounted({
      deleteOpen: true,
      memoId: id,
    })
  }

  handleDeleteCancel () {
    this.setStateIfMounted({
      deleteOpen: false,
      memoId: null,
    })
  }

  async handleDeleteOk () {
    if (isEmpty(this.state.memoId)) return

    try {
      let params = {
        deleted: true,
      }
      await Api.updateChatMemo(this.state.memoId, params)
      this.setStateIfMounted({
        deleteOpen: false,
        memoId: null,
      })
      this.loadMemos()
    } catch (error) {
      this.handleApiError(error)
    }
  }

  onChangeTitle (event) {
    let title = event.target.value
    if (maxTitleLength < title.length) {
      title = title.slice(0, maxTitleLength)
    }
    if(title.length === 0){
      this.setStateIfMounted({ memoTitle: '' })
    } else {
      this.setStateIfMounted({ memoTitle: title })
    }
  }

  onChangeText (event) {
    let text = event.target.value
    if (maxTextLength < text.length) {
      text = text.slice(0, maxTextLength)
    }
    if(text.length === 0){
      this.setStateIfMounted({ memoText: '' })
    } else {
      this.setStateIfMounted({ memoText: text })
    }
  }

  scrollToTop () {
    let scroll = $('#scroll-dialog')
    scroll.scrollTop(0)
  }

  displayDemo (prevStatus, newStatus) {
    if (prevStatus === 0 && newStatus === 1) {
      this.setStateIfMounted({ addMemo: true })
    }
    if (prevStatus === 1 && newStatus === 2) {
      this.setStateIfMounted({
        memoTitle: '好きな食べ物',
        memoText: this.state.memoText ? this.state.memoText : '洋食は好きだが、今はダイエット中。'
      })
    }
    if (prevStatus === 2 && newStatus === 3) {
      let memos = [{
        chat_room_id: this.props.friend.chat_room_id,
        deleted: false,
        id: 'demo1',
        title: this.state.memoTitle,
        text: this.state.memoText,
        updated_at: Date.now(),
        user_id: this.props.user.id,
      }]
      this.setStateIfMounted({
        memos: memos,
        memoTitle: '',
        memoText: '',
        memoId: null,
        addMemo: false,
      })
    }
    if (prevStatus === 3 && newStatus === 4) {
      this.handleClose()
    }
  }

  // テキスト複数行変換
  textMultiline (text) {
    return (
      text.split(/\r\n|\r|\n/).map(
        (line, key) => <p key={key}>{this.prepareLinks(line)}</p>
      )
    )
  }

  prepareLinks (msg) {
    let style = { wordBreak: 'break-all' }

    let urls = msg.match(urlRegexp)
    if (!urls) return [<span style={style} key={this.nextKey()}>{msg}</span>]

    let msgElems = []
    let currentIndex = 0
    for (let url of urls) {
      let urlIndex = msg.indexOf(url)
      let beforeText = msg.substring(currentIndex, urlIndex)
      if (0 < beforeText.length) {
        msgElems.push(<span style={style} key={this.nextKey()}>{beforeText}</span>)
      }
      msgElems.push(
        <span style={style} key={this.nextKey()}>
          <a href={url} target="_blank" rel="noreferrer">{url}</a>
        </span>
      )
      currentIndex = urlIndex + url.length
    }

    if (currentIndex < msg.length) {
      let afterText = msg.substring(currentIndex, msg.length)
      if (0 < afterText.length) {
        msgElems.push(<span style={style} key={this.nextKey()}>{afterText}</span>)
      }
    }

    return msgElems
  }

  nextKey () {
    this.elementKey += 1
    return this.elementKey
  }

  createAddCell () {
    const { classes } = this.props

    let disabled = isEmpty(this.state.memoTitle) || isEmpty(this.state.memoText)
    return (
      <Grid container
        direction="column" justifyContent="center" alignItems="center"
        className={classes.addContainer}
      >
        <div className={classes.addCell}>
          <IconButton
            key="close"
            color="inherit"
            className={classes.close}
            onClick={this.handleCancel} >
            <CloseIcon className={classes.iconButton}/>
          </IconButton>
          <IconButton
            key="check"
            color="inherit"
            className={classes.check}
            disabled={disabled}
            onClick={this.handleAdd} >
            <CheckIcon className={disabled ? classes.iconButtonDisabled : classes.iconButton}/>
          </IconButton>
          <TextField
            id="title"
            inputRef={this.inputRef}
            placeholder="好きな○○"
            value={this.state.memoTitle}
            onChange={this.onChangeTitle}
            fullWidth
            multiline
            maxRows="2"
            InputProps={{
              disableUnderline: true,
              className: classes.cellTitle
            }}
          />
          <Divider className={disabled ? '' : classes.divider}/>
          <TextField
            id="text"
            placeholder="お相手と話したことをここにメモできます。"
            className={classes.cellText}
            value={this.state.memoText}
            onChange={this.onChangeText}
            fullWidth
            multiline
            InputProps={{
              disableUnderline: true,
              className: classes.addText
            }}
          />
        </div>
      </Grid>
    )
  }

  createMemoCell (memo) {
    const { classes } = this.props

    let disabled = this.state.addMemo || Number.isInteger(this.props.demoStatus)
    return (
      <SwipeableList key={memo.id} type={ListType.IOS}>
        <SwipeableListItem
          blockSwipe={disabled}
          trailingActions={this.trailingActions(memo)}
        >
          <Grid container
            key={memo.id}
            direction="row" justifyContent="center" alignItems="center"
            className={classes.addContainer}
          >
            <div className={classes.cell}>
              <IconButton
                key="check"
                color="inherit"
                className={classes.check}
                disabled={disabled}
                onClick={() => this.handleEditMemo(memo)} >
                <EditIcon className={disabled ? classes.iconButtonDisabled : classes.iconButton}/>
              </IconButton>
              <div className={classes.cellTitle}>
                {this.textMultiline(memo.title)}
              </div>
              <Divider className={classes.divider}/>
              <div className={classes.cellText}>
                {this.textMultiline(memo.text)}
              </div>
            </div>
          </Grid>
        </SwipeableListItem>
      </SwipeableList>
    )
  }

  trailingActions = (memo) => {
    const { classes } = this.props
    return (
      <TrailingActions>
        <SwipeAction
          onClick={() => this.handleDeleteOpen(memo.id)}
        >
          <Grid container justifyContent="center" alignItems="center" className={classes.iconContainer}>
            <DeleteIcon className={classes.iconButton}/>
          </Grid>
        </SwipeAction>
      </TrailingActions>
    )
  }

  render () {
    const { classes } = this.props

    return(
      <Dialog
        style={{ zIndex: this.props.secret ? 1400 : 1000 }}
        classes={{ paper: classes.paper }}
        open={this.props.memoOpen}
      >
        <DialogContent id='scroll-dialog' className={classes.scroll} >
          <Grid container
            direction="column" justifyContent="center" alignItems="center"
            className={classes.titleContainer}
          >
            <Typography className={classes.title}>
              お相手メモ
            </Typography>
            <img src={IconX} className={classes.iconX} alt="" onClick={this.handleClose}/>
          </Grid>
          <Grid container direction="column" className={classes.memoContainer}>
            {
              this.state.addMemo &&
              this.createAddCell()
            }
            {
              this.state.memos.map((memo) => this.createMemoCell(memo))
            }
          </Grid>
          <Grid container justifyContent="center" className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              onClick={this.handleAddMemo}
              disabled={this.state.addMemo}
            >
              メモを追加する
            </Button>
          </Grid>
        </DialogContent>
        <ConfirmDialog
          open={this.state.deleteOpen}
          onClose={this.handleDeleteCancel}
          onOk={this.handleDeleteOk}
          onCancel={this.handleDeleteCancel}
          question="yes" title="削除" message="削除します。よろしいですか？"
        />
      </Dialog>
    )
  }
}

ChatMemo.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  secret: PropTypes.bool.isRequired,
  demoStatus: PropTypes.number,
}

export default withStyles(styles)(ChatMemo)