import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  titleBar: {
    height: theme.spacing(0.625),
    width: theme.spacing(10),
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
  },
}))

export default function PartnerTitle (props) {
  const { children } = props
  const classes = useStyles()

  return (
    <Grid container direction='column' alignItems="center">
      <Typography className={classes.title}>
        {children}
      </Typography>
      <div className={classes.titleBar} />
    </Grid>
  )
}

PartnerTitle.propTypes = {
  children: PropTypes.object,
}
