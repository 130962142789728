import React, { useState } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Komainu from 'images/survey/komainu.png'
import Serif from 'images/survey/serif.png'
import WhiteBoard from 'images/survey/white_board.png'

const useStyles = makeStyles(theme => ({
  time: {
    fontSize: 14,
    lineHeight: 1,
    marginBottom: theme.spacing(3),
  },
  red: {
    color: '#F55083',
  },
  whiteboard: {
    width: 212,
    height: 148,
    backgroundImage: `url(${WhiteBoard})`,
    backgroundSize: 'cover',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    position: 'relative',
  },
  komainu: {
    position: 'absolute',
    bottom: -38,
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  text: {
    textAlign: 'left',
    marginBottom: theme.spacing(2.25),
  },
  img: {
    display: 'block',
    margin: 'auto',
  },
}))

export default function SurveyTopDialog (props) {
  const { myOrganization, onClose } = props
  const classes = useStyles()
  const [clickedOnNext, setClickedOnNext] = useState(false)

  if (!myOrganization) { return null }

  const today = new Date()
  const next_month = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())

  return (
    <DialogThemeProvider color="default">
      <Dialog open={true} id="survey-tokyu-cnst_2024-02" style={{ zIndex: 9999, display: clickedOnNext && 'none' }}>
        <DialogTitle disableTypography>
          【{myOrganization.canonical_name} 従業員様へ】<br />
          アンケートご協力のお願い
        </DialogTitle>
        <DialogContent>
          <Typography className={`${classes.time} ${classes.red}`} variant="body1">約3分</Typography>
          <div className={classes.whiteboard}>
            <Typography variant="body1">
              {myOrganization.canonical_name}様より<br />
              {dateFormat(next_month, 'yyyy年m月')}以降の<br />
              Aill goen利用 継続調査として<br />
              アンケート依頼が届きました。
            </Typography>
            <img className={classes.komainu} src={Komainu} width={136} height={80} />
          </div>
          <Typography className={classes.text} variant="body1">
            <span className={classes.red}>企業にご利用者情報は一切共有いたしません。</span><br />
            報告は回答結果の数値分析のみです。<br />
            ご安心ください。
          </Typography>
          <img className={classes.img} src={Serif} width={164} height={83} />
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={() => setClickedOnNext(true)}>
            回答する
          </Button>
          <Button variant="text" onClick={onClose}>
            あとで
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SurveyTopDialog.propTypes = {
  myOrganization: PropTypes.object,
  onClose: PropTypes.func,
}
