import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Features from 'commons/features'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'

const useStyles = makeStyles(theme => ({
  img: {
    marginBottom: theme.spacing(2.5),
  },
}))

export default function NewFeatureDialog (props) {
  const { open, id, onConfirm, onClose } = props
  const classes = useStyles()

  if (!id) { return null }

  const feature = Features[id]

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogCloseButton onClick={() => onClose(id)} />
        <DialogTitle disableTypography>
          機能追加のお知らせ
        </DialogTitle>
        <DialogContent>
          {feature?.img && <img className={classes.img} src={feature.img} width={'100%'} />}
          {feature?.text && <Typography variant="body1">{feature.text}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => onConfirm(id)}>
            {feature?.buttonText || '確認する'}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

NewFeatureDialog.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}
