import React from 'react'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/HelpOutline'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import { httpNotFound } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import SecretQuestionRoom from 'components/parts/SecretQuestionRoom'
import SecretQuestionRoomTutorial from 'components/parts/SecretQuestionRoomTutorial.jsx'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import BackgroundImage from 'images/img_secret_message.png'

const styles = theme => ({
  root: {
    paddingTop: theme.styles.header.height,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    ...theme.styles.messageColor,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  secretRoot: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
    display: 'flex',
    '-webkit-touch-callout': 'none',
  },
  secretContainer: {
    width: theme.spacing(42.25),
    minHeight: theme.spacing(22),
    ...theme.styles.secretRoomColor,
    borderRadius: theme.spacing(3),
    position: 'relative',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
  },
  secretTitle: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  secretSubTitle: {
    marginTop: theme.spacing(2),
    fontSize: 20,
    fontWeight: 'bold',
    color: '#555555',
    textAlign: 'center',
    maxWidth: theme.spacing(30),
  },
  secretOpen: {
    fontSize: 14,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5),
    backgroundColor: '#616AFA',
    color: theme.palette.secondary.contrastText,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  imageA: {
    position: 'absolute',
    top: theme.spacing(5),
    right: theme.spacing(2),
    width: theme.spacing(3.75),
    transform: 'rotate(-15deg)',
  },
  imageUn: {
    position: 'absolute',
    top: theme.spacing(13),
    left: theme.spacing(2),
    width: theme.spacing(4.63),
    transform: 'rotate(15deg)',
  },
  badge: {
    top: theme.spacing(3),
    right: theme.spacing(1),
  },
  helpIcon: {
    fontSize: theme.spacing(3),
    opacity: 0.5,
  },
  IconContainer: {
    padding: 0,
    position: 'absolute',
    right: `calc(50% - ${theme.spacing(12)}px)`,
  },
})

export class SecretRooms extends BaseComponent {
  constructor (props) {
    super(props)

    this.createSecretQuestion = this.createSecretQuestion.bind(this)
    this.showTutorial = this.showTutorial.bind(this)
    this.closeTutorial = this.closeTutorial.bind(this)

    this._isMounted = false

    this.state = {
      friend: props.friend || Storage.getFriend(),
      secretRooms: [],
      openSecretRoom: false,
      secretMessage: null,
      openSecretTutorial: false,
    }
  }

  async componentWillMount () {
    this.props.setLoading(true)
    try {
      await this.getSecretRooms(this.state.friend?.chat_room_id)
      this.addVisitPageLog({ friend_user_id: this.state.friend.id })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog({ friend_user_id: this.state.friend.id })
  }

  async getSecretRooms (roomId) {
    let messages = await Api.getSecretRooms(roomId)
    if (!messages) return
    if (messages.length === 0) return

    this.setStateIfMounted({ secretRooms: messages })
  }

  handleClickSecretButton (msg) {
    this.setState({
      openSecretRoom: true,
      secretMessage: msg
    })
  }

  async handleCloseSecretRoom (msg) {
    if (msg.new_secret_message_count > 0) {
      this.props.setLoading(true)
      try {
        await this.getSecretRooms(this.state.friend.chat_room_id)
        let friend = await this.loadFriendAndUpdateFriends(this.state.friend.id)
        this.props.setFriend(friend)
        this.setStateIfMounted({ friend: friend })
      } catch (error) {
        if (httpNotFound(error)) {
          // noop
        } else {
          this.handleApiError(error)
        }
      } finally {
        this.props.setLoading(false)
      }
    }
    this.setStateIfMounted({
      openSecretRoom: false,
      secretMessage: null,
    })
  }

  showTutorial () {
    this.setState({ openSecretTutorial: true })
  }

  closeTutorial () {
    this.setState({ openSecretTutorial: false })
  }

  createSecretQuestion (msg, index) {
    const { classes } = this.props

    return (
      <div key={`secret-${index}`} className={classes.secretRoot}>
        <Grid container justifyContent="center" alignItems="center">
          <div className={classes.secretContainer}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography className={classes.secretTitle}>
                秘密の質問コーナーだよ！
              </Typography>
              <Typography className={classes.secretSubTitle}>
                {msg.secret_question_title}
              </Typography>
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={msg.new_secret_message_count}
                invisible={msg.new_secret_message_count === 0}
                overlap="rectangular"
              >
                <Button variant="contained" className={classes.secretOpen} onClick={() => this.handleClickSecretButton(msg)}>
                  二人の相性チェック！
                </Button>
              </Badge>
            </Grid>
            <img src={AImage} className={classes.imageA} alt=""/>
            <img src={UnImage} className={classes.imageUn} alt=""/>
          </div>
        </Grid>
      </div>
    )
  }

  render () {
    const { classes, ...other } = this.props
    return (
      <div className={classes.root}>
        <AppHeader
          title={this.state.friend?.nick_name || ''}
          toggleMenu={false}
          user={this.props.user}
          target={this.state.friend}
          showFriendIcon={true}
          onBack={() => this.props.setScreen('Chat')}
          backWhite={true}
          {...other}
        />
        <Typography className={classes.title}>
          秘密の質問一覧
          <IconButton className={classes.IconContainer} onClick={this.showTutorial} >
            <HelpIcon className={classes.helpIcon} color="secondary" />
          </IconButton>
        </Typography>
        {this.state.secretRooms.map(this.createSecretQuestion)}
        {
          this.state.openSecretRoom &&
          <SecretQuestionRoom
            {...other}
            message={this.state.secretMessage}
            friend={this.state.friend}
            chat_room_id={this.state.secretMessage.secret_room_id}
            open={this.state.openSecretRoom}
            onClose={() => this.handleCloseSecretRoom(this.state.secretMessage)}
          />
        }
        <SecretQuestionRoomTutorial
          open={this.state.openSecretTutorial}
          onClose={this.closeTutorial}
          friend={this.state.friend}
        />
      </div>
    )
  }
}

SecretRooms.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SecretRooms)