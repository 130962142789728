import React from 'react'
import PropTypes from 'prop-types'

export default class AppAuth extends React.Component {
  componentDidMount () {
    if (this.props.location.pathname === '/') {
      caches.keys().then(keyList => {
        return Promise.all(keyList.map(key => {
          let ret = caches.delete(key)
          window.location.reload(true)
          return ret
        }))
      })
    }
  }

  render () {
    if (this.props.isLogin) {
      return this.props.children
    } else {
      window.location.replace('/')
      return <div />
    }
  }
}

AppAuth.propTypes = {
  location: PropTypes.object,
  isLogin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
