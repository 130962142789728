import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import VideoLinkImg from 'images/follow_after_dating_video.png'
import KomainuWhiteBord from 'images/Komainu/firstTalkNaviImg05.png'
import Img1 from 'images/tutorial/follow_after_dating_dialog_img_1.png'
import Img2 from 'images/tutorial/follow_after_dating_dialog_img_2.png'
import Img3 from 'images/tutorial/follow_after_dating_dialog_img_3.png'

const useStyles = makeStyles(theme => ({
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  slideIcon: {
    fontSize: 8,
    color: '#ffffff',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slideIconsContainer: {
    width: '100%',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slide: {
    textAlign: 'center',
    position: 'relative',
    lineHeight: 0.5,
  },
  whitebord: {
    width: 216,
    margin: 'auto',
    position: 'relative',
    paddingTop: theme.spacing(2),
  },
  textLink: {
    fontSize: 12,
    color: '#423BC7',
    position: 'absolute',
    bottom: 198,
    left: 18,
  },
  videoLink: {
    position: 'absolute',
    bottom: 116,
    left: 52,
  },
}))

export default function FollowAfterDatingDialog (props) {
  const { open, onClose, sex } = props
  const classes = useStyles()
  const [slideIndex, setSlideIndex] = useState(0)

  useEffect(() => {
    if (!open) { return }
    setSlideIndex(0)
  }, [open])

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  const openVideoPage = () => {
    const maleVideoURL = 'https://www.youtube.com/watch?v=gx_tsK5IuIo'
    const femaleVideoURL = 'https://www.youtube.com/watch?v=xsIqf3grwJo'
    window.open(sex === 'male' ? maleVideoURL: femaleVideoURL)
  }

  const createPage1 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.subTitle} variant="subtitle1">
        2人の間を仲立ち！
      </Typography>
      <Typography className={classes.text} variant="body1">
        {sex === 'male' ? '相手のデートの反響' : 'デートの感想'}<br />
        次回デートのタイミングなど<br />
        聞きづらいことは僕たちにお任せ！<br />
      </Typography>
      <div className={classes.whitebord}>
        <img src={KomainuWhiteBord} width={216} />
        <u className={classes.textLink} onClick={openVideoPage}>
          今日のデートはどうだった！？編
        </u>
        <img
          className={classes.videoLink}
          src={VideoLinkImg}
          width={108}
          onClick={openVideoPage}
        />
      </div>
    </div>
  )

  const createMalePage2 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.subTitle} variant="subtitle1">
        次回のデート確率アップ！
      </Typography>
      <img src={Img1} width={230} />
    </div>
  )

  const createMalePage3 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.subTitle} variant="subtitle1">
        次のデートもアシスト！
      </Typography>
      <Typography className={classes.subTitle} variant="body1">
        デートのお誘い方法も相談してね
      </Typography>
      <img src={Img2} width={230} />
    </div>
  )

  const createFemalePage2 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.subTitle} variant="subtitle1">
        次回のデート確率アップ！
      </Typography>
      <Typography className={classes.subTitle} variant="body1">
        デートの感想を僕たちに教えてね<br />
        上手く立ち回るよ
      </Typography>
      <img src={Img3} width={230} />
    </div>
  )

  const lastPage = () => {
    const lastPageNum = sex === 'male' ? 2 : 1
    return slideIndex === lastPageNum
  }

  return (
    <DialogThemeProvider color="secondary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          デート後のフォロー
        </DialogTitle>
        <DialogContent>
          {sex === 'male' ? (
            <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
              {createPage1()}
              {createMalePage2()}
              {createMalePage3()}
            </SwipeableViews>
          ) : (
            <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
              {createPage1()}
              {createFemalePage2()}
            </SwipeableViews>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            {sex === 'male' ? (
              <div className={classes.slideIconsContainer}>
                {slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {slideIndex === 2 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              </div>
            ) : (
              <div className={classes.slideIconsContainer}>
                {slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              </div>
            )}
            <Button
              variant="contained"
              onClick={() => lastPage() ? onClose() : setSlideIndex(slideIndex + 1)}
            >
              {lastPage() ? '閉じる' : '次へ'}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

FollowAfterDatingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sex: PropTypes.string.isRequired,
}
