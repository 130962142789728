import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Image1 from 'images/img_dating_1.png'
import Image2 from 'images/img_dating_2.png'
import Image3 from 'images/img_dating_3.png'
import ImageMsg from 'images/img_dating_msg.png'

const styles = theme => ({
  content: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  actions: {
    paddingTop: 0,
  },
  message: {
    ...theme.styles.messageColor,
    ...theme.styles.fontSize(14),
    lineHeight: 1.75,
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  messageContainer: {
    backgroundImage: `url(${ImageMsg})`,
    backgroundSize: '100% 100%',
    display: 'table',
    width: '100%',
    height: theme.spacing(32),
  },
  messageSmall: {
    ...theme.styles.fontSize(12),
  },
  slideIconsContainer: {
    width: '100%',
    color: theme.palette.secondary.contrastText,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slide: {
    position: 'relative',
    height: theme.spacing(50),
    textAlign: 'left',
    maxWidth: 400,
    margin: 'auto',
  },
  image: {
    width: theme.spacing(28),
    height: theme.spacing(12.133),
    marginTop: theme.spacing(2),
    objectFit: 'contain',
  },
})

export class DatingImpressionTutorialDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      slideIndex: 0,
    }
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.open) !== JSON.stringify(prevProps.open)) {
      if (this.props.open) {
        this.setState({ slideIndex: 0 })
      }
    }
  }

  onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      this.setState({slideIndex: index})
    }
  }

  getTitle (index) {
    const { count, sex } = this.props
    let title = []
    switch (index) {
      case 0:
        switch (count) {
          case 1:
            title.push(<span key="title1">{'また会いたいと思った時'}</span>)
            break
          case 2:
            title.push(<span key="title1_1">{'相手を恋人候補として'}<br /></span>)
            title.push(<span key="title1_2">{'意識できそうな時'}</span>)
            break
          case 3:
            title.push(<span key="title1_1">{'真剣に相手を'}<br /></span>)
            title.push(<span key="title1_2">{'恋人候補と思える時'}</span>)
            break
          default:
            break
        }
        break
      case 1:
        switch (count) {
          case 1:
            title.push(<span key="title2_1">{'他の異性を見てから'}<br /></span>)
            title.push(<span key="title2_2">{'決めたい時'}</span>)
            break
          case 2:
            if (sex === 'female') {
              title.push(<span key="title2_1">{'やっぱり'}<br /></span>)
            }
            title.push(<span key="title2_2">{'他の異性を見てから'}<br /></span>)
            title.push(<span key="title2_3">{'決めたい時'}</span>)
            break
          case 3:
            title.push(<span key="title2_1">{'やっぱり'}<br /></span>)
            title.push(<span key="title2_2">{'他の異性を見てから'}<br /></span>)
            title.push(<span key="title2_3">{'決めたい時'}</span>)
            break
          default:
            break
        }
        break
      case 2:
        title.push(<span key="title3_1">{'お断りしたい'}<br /></span>)
        title.push(<span key="title3_2">{'でも'}<br /></span>)
        title.push(<span key="title3_3">{'相手を傷つけたくない時'}</span>)
        break
      default:
        break
    }
    return title
  }

  getMessage (index) {
    const { count, sex, classes } = this.props
    let msg = []
    switch (index) {
      case 0:
        switch (count) {
          case 1:
            if (sex === 'female') {
              msg.push(<span key="msg1_1"><b>{'相手から２回目デートの'}</b><br /></span>)
              msg.push(<span key="msg1_2"><b>{'誘いがあるように'}</b><br /></span>)
              msg.push(<span key="msg1_3"><b>{'間を取り持つよ！'}</b>{'任せて！！'}<br /></span>)
              msg.push(<span key="msg1_4">{'より２人を親密にする'}<br /></span>)
              msg.push(<span key="msg1_5">{'秘密のイベントも発生するかも♪'}</span>)
            } else {
              msg.push(<span key="msg1_1">{'２回目デートも自然に'}<br /></span>)
              msg.push(<span key="msg1_2">{'誘えるよう'}<b>{'大義名分作りも'}</b><br /></span>)
              msg.push(<span key="msg1_3"><b>{'アシストするよ！'}</b><br /></span>)
              msg.push(<span key="msg1_4">{'誘うタイミングの合図も任せて！！'}<br /></span>)
              msg.push(<span key="msg1_5">{'２人が上手くいくように'}<br /></span>)
              msg.push(<span key="msg1_6">{'間を取り持つよ♪'}</span>)
            }
            break
          case 2:
            if (sex === 'female') {
              msg.push(<span key="msg1_1">{'相手から3回目デートのお誘い'}<br /></span>)
              msg.push(<span key="msg1_2">{'はもちろんのこと、'}<br /></span>)
              msg.push(<span key="msg1_3"><b>{'ハッピーエンドを目指して'}</b><br /></span>)
              msg.push(<span key="msg1_4"><b>{'間を取り持つよ！'}</b><br /></span>)
              msg.push(<span key="msg1_5">{'次の秘密の質問は、'}<b>{'付き合っていて'}</b><br /></span>)
              msg.push(<span key="msg1_6"><b>{'も聞けない深い質問になるよ！'}</b><br /></span>)
              msg.push(<span key="msg1_7">{'乞うご期待♪'}</span>)
            } else {
              msg.push(<span key="msg1_1"><b>{'3回目デートも成功するよう'}</b><br /></span>)
              msg.push(<span key="msg1_2"><b>{'アシストするよ！'}</b><br /></span>)
              msg.push(<span key="msg1_3">{'誘うタイミングの合図も任せて！！'}<br /></span>)
              msg.push(<span key="msg1_4">{'ここからの関係が超重要だよ！'}<br /></span>)
              msg.push(<span key="msg1_5">{'ハッピーエンドを目指して'}<br /></span>)
              msg.push(<span key="msg1_6">{'間を取り持つよ♪'}</span>)
            }
            break
          case 3:
            if (sex === 'female') {
              msg.push(<span key="msg1_1"><b>{'相手から告白してもらおう！'}</b><br /></span>)
              msg.push(<span key="msg1_2">{'相手に告白の勇気が持てるよう、'}<br /></span>)
              msg.push(<span key="msg1_3">{'ボクたち頑張るよ！'}<br /></span>)
              msg.push(<span key="msg1_4">{'ハッピーエンドを目指して'}<br /></span>)
              msg.push(<span key="msg1_5">{'最後まで一緒に頑張ろう♪'}</span>)
            } else {
              msg.push(<span key="msg1_1"><b>{'告白の成功確率を上げるよ！'}</b><br /></span>)
              msg.push(<span key="msg1_2">{'告白が失敗しないように'}<br /></span>)
              msg.push(<span key="msg1_3">{'間を取り持つよ！'}<br /></span>)
              msg.push(<span key="msg1_4">{'ハッピーエンドを目指して'}<br /></span>)
              msg.push(<span key="msg1_5">{'最後まで一緒に頑張ろう♪'}</span>)
            }
            break
          default:
            break
        }
        break
      case 1:
        switch (count) {
          case 1:
            msg.push(<span key="msg2_1">{'付かず離れず'}<br /></span>)
            msg.push(<span key="msg2_2">{'でも関係が切れないように'}<br /></span>)
            msg.push(<span key="msg2_3"><b>{'相手にフォロー入れるよ！'}</b></span>)
            break
          case 2:
            msg.push(<span key="msg2_1">{'付かず離れず'}<br /></span>)
            msg.push(<span key="msg2_2">{'でも関係が切れないように'}<br /></span>)
            msg.push(<span key="msg2_3"><b>{'相手にフォロー入れるよ！'}</b><br /></span>)
            msg.push(<span key="msg2_4">{'ご縁が切れないためには'}<br /></span>)
            msg.push(<span key="msg2_5">{'ここの関係維持は大事だよ。'}</span>)
            break
          case 3:
            msg.push(<span key="msg2_1">{'関係が切れないように'}<br /></span>)
            msg.push(<span key="msg2_2"><b>{'相手にフォロー入れるよ！'}</b><br /></span>)
            msg.push(<span key="msg2_3">{'ご縁が切れないためには'}<br /></span>)
            msg.push(<span key="msg2_4">{'ここの関係維持はとても大切。'}<br /></span>)
            msg.push(<span key="msg2_5">{'デリケートなフェーズだし、'}<br /></span>)
            msg.push(<span key="msg2_6">{'ボクたち頑張るよ。'}</span>)
            break
          default:
            break
        }
        break
      case 2:
        msg.push(<span key="msg3_1">{'相手を傷つけず、自然に'}<br /></span>)
        msg.push(<span key="msg3_2">{'フェードアウトとなるよう'}<br /></span>)
        msg.push(<span key="msg3_3"><b>{'上手くご縁を終息させるよ。'}</b><br /></span>)
        msg.push(<span key="msg3_4">{'こればっかりは仕方ない！'}<br /></span>)
        msg.push(<span key="msg3_5" className={classes.messageSmall}>{'※トークアシストも終了となるよ。'}</span>)
        break
      default:
        break
    }
    return msg
  }

  render () {
    const { classes, ...other } = this.props
    return(
      <DialogThemeProvider color="secondary">
        <Dialog {...other}>
          <DialogTitle disableTypography>
            {this.getTitle(this.state.slideIndex)}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <SwipeableViews
              index={this.state.slideIndex}
              onChangeIndex={this.onChangeSlideIndex}
            >
              <div style={Object.assign({})} className={classes.slide}>
                <Grid container className={classes.messageContainer} justifyContent="center" alignItems="flex-end">
                  <Typography className={classes.message} >
                    {this.getMessage(this.state.slideIndex)}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <img src={Image1} className={classes.image} alt=""/>
                </Grid>
              </div>
              <div style={Object.assign({})} className={classes.slide}>
                <Grid container className={classes.messageContainer} justifyContent="center" alignItems="flex-end">
                  <Typography className={classes.message} >
                    {this.getMessage(this.state.slideIndex)}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <img src={Image2} className={classes.image} alt=""/>
                </Grid>
              </div>
              <div style={Object.assign({})} className={classes.slide}>
                <Grid container className={classes.messageContainer} justifyContent="center" alignItems="flex-end">
                  <Typography className={classes.message} >
                    {this.getMessage(this.state.slideIndex)}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <img src={Image3} className={classes.image} alt=""/>
                </Grid>
              </div>
            </SwipeableViews>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <div className={classes.slideIconsContainer}>
                {this.state.slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 2 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              </div>
              <Button
                variant="contained"
                onClick={() => this.state.slideIndex === 2 ? this.props.onClose() : this.setState({slideIndex: this.state.slideIndex + 1})}
              >
                {this.state.slideIndex === 2 ? '閉じる' : '次へ'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

DatingImpressionTutorialDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  sex: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
}

export default withStyles(styles)(DatingImpressionTutorialDialog)