import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class MemoIcon extends React.Component {
  render () {
    const { color } = this.props
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 25 28" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_868" data-name="グループ 868" transform="translate(-168 -124)">
            <g id="長方形_445" data-name="長方形 445" transform="translate(168 124)" fill="none" stroke={ color ? color : '#989898' } strokeWidth="1.8">
              <rect width="25" height="28" rx="1" stroke="none"/>
              <rect x="0.9" y="0.9" width="23.2" height="26.2" rx="0.1" fill="none"/>
            </g>
            <g id="グループ_867" data-name="グループ 867" transform="translate(-0.5 0.5)">
              <line id="線_31" data-name="線 31" x2="15" transform="translate(173.5 130.5)" fill="none" stroke={color ? color : '#989898'} strokeWidth="1"/>
              <line id="線_31-2" data-name="線 31" x2="15" transform="translate(173.5 135.5)" fill="none" stroke={color ? color : '#989898'} strokeWidth="1"/>
              <line id="線_31-3" data-name="線 31" x2="15" transform="translate(173.5 140.5)" fill="none" stroke={color ? color : '#989898'} strokeWidth="1"/>
              <line id="線_31-4" data-name="線 31" x2="15" transform="translate(173.5 145.5)" fill="none" stroke={color ? color : '#989898'} strokeWidth="1"/>
            </g>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

MemoIcon.propTypes = {
  color: PropTypes.string,
}

export default withStyles(styles)(MemoIcon)