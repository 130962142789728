import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import Komainu from 'images/Komainu/firstNaviKomainu.png'
import SerifLeft from 'images/komainu_serif_left.png'
import SerifRight from 'images/komainu_serif_right.png'
import MailIcon from 'images/mail_icon.png'

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
    paddingTop: sp => theme.spacing(sp ? 4.5 : 8.25),
  },
  title: {
    fontSize: sp => sp ? 19 : 32,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.9px' : '3.2px',
    textAlign: 'center',
  },
  titleBar: {
    height: sp => sp ? 2 : 4,
    width: sp => sp ? 145 : 234,
    background: '#616AFA',
    margin: 'auto',
    marginTop: sp => theme.spacing(sp ? 1.5 : 2),
  },
  container: {
    paddingTop: sp => theme.spacing(sp ? 3.25 : 5.25),
    paddingBottom: theme.spacing(10),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  subtitle: {
    fontSize: sp => sp ? 16 : 28,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: sp => sp ? 1.5 : 1,
    letterSpacing: sp => sp ? '1.6px' : '2.8px',
    textAlign: 'center',
    marginBottom: sp => theme.spacing(sp ? 3 : 3.75),
  },
  text: {
    fontSize: sp => sp ? 14 : 16,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.4px' : '1.6px',
    textAlign: 'center',
    marginBottom: sp => theme.spacing(sp ? 3 : 6),
  },
  serif: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.2px' : '1.4px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  box: {
    width: 'fit-content',
    background: '#ffffff',
    border: '4px solid #B0B4FC',
    borderRadius: 10,
    paddingTop: sp => theme.spacing(sp ? 2.25 : 4.25),
    paddingBottom: sp => theme.spacing(sp ? 2.5 : 4.5),
    paddingRight: sp => theme.spacing(sp ? 3 : 5),
    paddingLeft: sp => theme.spacing(sp ? 3 : 5),
    margin: 'auto',
    marginBottom: sp => theme.spacing(sp ? 7 : 13),
    position: 'relative',
  },
  mail: {
    fontSize: sp => sp ? 16 : 20,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: sp => sp ? 1.5 : 1,
    letterSpacing: sp => sp ? '1.6px' : '2.0px',
    textAlign: 'center',
  },
  komainu: {
    display: 'block',
    margin: 'auto',
    marginBottom: sp => theme.spacing(sp ? -2.5 : -3.5),
  },
  mailIcon: {
    position: 'absolute',
    top: 'calc(100% - 10px)',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  serifLeft: {
    position: 'absolute',
    top: -60,
    left: sp => sp ? 50 : 200,
    transform: 'translate(-50%, 100%)',
  },
  serifRight: {
    position: 'absolute',
    top: -60,
    right: sp => sp ? 30 : 200,
    transform: 'translate(-50%, 100%)',
  },
  note: {
    fontSize: sp => sp ? 12 : 16,
    fontWeight: 600,
    color: '#423BC7',
    letterSpacing: sp => sp ? '1.2px' : '1.6px',
    textAlign: sp => sp ? 'left' : 'center',
  },
}))

export default function SignupCheck () {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <EntryHeader />
      <div className={classes.body}>
        <Typography className={classes.title}>ENTRY</Typography>
        <div className={classes.titleBar} />
        <div className={classes.container}>
          <Typography className={classes.subtitle}>
            受信メールより、{spMatches && <br />}本登録へお進みください。
          </Typography>
          <Typography className={classes.text}>仮登録ありがとうございます！</Typography>
          <Typography className={classes.serif}>この件名でメールが届くよ！</Typography>
          <img className={classes.komainu} src={Komainu} width={spMatches ? 120 : 167}  />
          <div className={classes.box}>
            <Typography className={classes.mail}>
              {!spMatches && '件名：'}【本登録のお願い】AI縁結びナビゲーションアプリAill goen
            </Typography>
            <img className={classes.serifLeft} src={SerifLeft} width={24} height={26} />
            <img className={classes.serifRight} src={SerifRight} width={24} height={26} />
            <img className={classes.mailIcon} src={MailIcon} width={69.15} height={45} />
          </div>
          <Typography className={classes.note}>
            ＊5分以内に本登録メールが届かない場合、次の可能性がございますのでご確認ください。<br />
            1. 迷惑メールなど別のフォルダに振り分けられていないか<br />
            2. PCメールアドレスの受信拒否や高いセキュリティ設定がされている<br />
            →「support@aill.ai」を受信許可してください<br />
            <br />
            設定を変更されましたら再度メールアドレスの登録をお願いします。<br />
            以上を確認しても解決しない場合は「support@aill.ai」までご連絡ください。
          </Typography>
        </div>
      </div>
      <EntryFooter />
    </>
  )
}
