import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 440,
  },
  consent: {
    fontSize: 12,
    marginTop: theme.spacing(1.5),
  },
  consentMessage: {
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 10,
  },
  consenCheckBox: {
    '& svg': {
      color: '#ffffff'
    }
  },
  message: {
    marginBottom: 10,
    textAlign: 'left',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  rejectButton: {
    marginBottom: '0 !important',
  },
}))

export default function ImportantConfirmDialog (props) {
  const {
    open,
    title,
    consent,
    message,
    close,
    question,
    confirm,
    attention,
    loveconfirm,
    apply_lover_count,
    apply_friend_count,
    approve_friend_cont
  } = props
  const classes = useStyles()
  const [isChecked,setIsChecked] = useState(false)

  const handleClose = () => {
    props.onClose()
  }

  const handleOk = () => {
    props.onOk()
  }

  const handleCancel = () => {
    props.onCancel()
  }

  const handleComfirm = () => {
    props.onClose()
  }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open} classes={{ paper: classes.root }}>
        <DialogTitle disableTypography>
          {title}
          {consent && (
            <div className={classes.consent}>
              一番下の同意するにチェックを<br />してください。
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Typography className={consent ? classes.consentMessage : classes.message} variant="body1">
              {message}
              {consent && (
                <Grid container alignItems="center" justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.consenCheckBox}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        color="primary"
                      />
                    }
                    label="同意する"
                  />
                </Grid>
              )}
            </Typography>
          )}
          {apply_lover_count > 0 && (
            <Grid className={classes.flex}>
              <Typography variant="body1">お付き合いリクエスト</Typography>
              <Typography variant="body1">{apply_lover_count}件</Typography>
            </Grid>
          )}
          {apply_friend_count > 0 && (
            <Grid className={classes.flex}>
              <Typography variant="body1">「いいね！」受信</Typography>
              <Typography variant="body1">{apply_friend_count}件</Typography>
            </Grid>
          )}
          {approve_friend_cont > 0 && (
            <Grid className={classes.flex}>
              <Typography variant="body1">「いいね！」承認</Typography>
              <Typography variant="body1">{approve_friend_cont}件</Typography>
            </Grid>
          )}
        </DialogContent>
        {close && (
          <DialogActions>
            <Button variant="contained" onClick={handleComfirm}>
              閉じる
            </Button>
          </DialogActions>
        )}
        {question && (
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={handleOk}>
              取り消す
            </Button>
            <Button variant="text" onClick={handleCancel}>
              キャンセル
            </Button>
          </DialogActions>
        )}
        {confirm && (
          <DialogActions>
            <Button variant="contained" onClick={handleComfirm}>
              確認する
            </Button>
          </DialogActions>
        )}
        {/* お付き合いリクエスト送信時注意事項 */}
        {attention && (
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={handleComfirm}>
              確認しました
            </Button>
            <Button variant="text" onClick={handleCancel}>
              キャンセル
            </Button>
          </DialogActions>
        )}
        {/* お付き合いリクエスト承認時注意事項 */}
        {loveconfirm && (
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={props.handleApprove}>
              承認する
            </Button>
            <Button className={classes.rejectButton} variant="outlined" onClick={props.handleReject}>
              お断り
            </Button>
            <Button variant="outlined" onClick={props.handleHold}>
              保留
            </Button>
          </DialogActions>
        )}
        {consent && (
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleClose}
              disabled={!isChecked}
            >
              閉じる
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </DialogThemeProvider>
  )
}

ImportantConfirmDialog.propTypes = {
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  consent: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  close: PropTypes.string,
  question: PropTypes.string,
  confirm: PropTypes.string,
  attention: PropTypes.string,
  loveconfirm: PropTypes.string,
  apply_lover_count: PropTypes.number,
  apply_friend_count: PropTypes.number,
  approve_friend_cont: PropTypes.number,
  handleApprove: PropTypes.func,
  handleReject: PropTypes.func,
  handleHold: PropTypes.func,
}
