import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Const from 'commons/constant'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import * as Offer from 'components/special-offer/index'
import BannerIC from 'images/special-offer/banner_invitation_campaign.png'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.styles.footer.height,
    backgroundColor: '#EFF0FF',
    minHeight: window.innerHeight,
  },
  container: {
    marginTop: theme.spacing(4),
    gap: theme.spacing(3),
  },
  title: {
    color: '#616AFA',
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 600,
  },
  card: {
    width: 338,
    backgroundColor: '#FFFFFF',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    position: 'relative',
  },
  cardImg: {
    width: '100%',
    objectFit: 'cover',
    verticalAlign: 'top',
  },
  amTitle: {
    fontSize: 8.5,
    fontWeight: 700,
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 38,
    left: '50%',
  },
  marker: {
    background: 'linear-gradient(transparent 55%, #FFF24D 0%)',
    padding: '0 4px',
  },
  incentive: {
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 84,
    left: '50%',
    '& span:nth-of-type(1)': {
      fontSize: 6.92,
      writingMode: 'vertical-rl',
      marginRight: theme.spacing(1),
    },
    '& span:nth-of-type(2)': {
      fontSize: 16.71,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
    '& span:nth-of-type(3)': {
      fontSize: 6.92,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        bottom: 2,
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  cardText: {
    color: '#414141',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '15px',
    marginTop: 13,
    marginLeft: 7,
  }
}))

export default function Campaigns (props) {
  const { specialOffers, subscription, ...other } = props
  const classes = useStyles()
  const [campaigns, setCampaigns] = useState([])

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    props.setScreen('Campaigns')
    setCampaigns([...(specialOffers || []), 'invitation_campaign'])

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const createBanner = (campaign) => {
    const soTheme = Offer.theme(campaign)
    if (campaign?.offer_type === 'additional_matching') {
      const textShadow = `
        2px 2px 2px ${soTheme.textShadowColor}, -2px -2px 1px ${soTheme.textShadowColor},
        -2px 2px 1px ${soTheme.textShadowColor}, 2px -2px 1px ${soTheme.textShadowColor},
        2px 0 1px ${soTheme.textShadowColor}, -2px  0 1px ${soTheme.textShadowColor},
        0 2px 1px ${soTheme.textShadowColor}, 0 -2px 1px ${soTheme.textShadowColor}
      `
      return (
        <Grid container className={classes.card} onClick={() => {
          props.setCampaign(campaign)
          props.setScreen('SpecialOffer')
        }}>
          <img className={classes.cardImg} src={soTheme.bannerImg} />
          <Typography className={`${classes.amTitle} ${classes.marker}`} variant="body1" style={{ color: soTheme.mainColor }}>
            あなただけのスペシャルオファー
          </Typography>
          <Typography className={`${classes.incentive} ${classes.marker}`} variant="body1" style={{ color: soTheme.mainColor }}>
            <span>最大</span>
            <span data-text={3 * campaign.max_count} style={{ textShadow: textShadow }}>{3 * campaign.max_count}</span>
            <span data-text="人ご紹介" style={{ textShadow: textShadow }}>人ご紹介</span>
          </Typography>
          <Typography className={classes.cardText}>
            開催期間：{dateFormat(campaign.start_at, 'yyyy/mm/dd')}〜{dateFormat(campaign.end_at, 'yyyy/mm/dd')}
          </Typography>
        </Grid>
      )
    }
    if (campaign === 'invitation_campaign') {
      // スタンダードプラン（全額企業払い）適用中ユーザーを除く
      if (subscription.content === Const.planTypes.STANDARD_ORG_PAID) { return null }
      // ベーシックプラン（全額企業払の特別フリープラン）適用中ユーザーを除く
      if (subscription.content === Const.planTypes.BASIC) { return null }
      // 特別フリープラン適用中ユーザーを除く
      if (subscription.content === Const.planTypes.SPECIAL_FREE) { return null }
      return (
        <Grid container className={classes.card} onClick={() => props.setScreen('InvitationCampaign')}>
          <img className={classes.cardImg} src={BannerIC} alt={'キャンペーンバナー'} />
          <Typography className={classes.cardText}>お友達もあなたもお得！お友達紹介キャンペーン</Typography>
        </Grid>
      )
    }
  }

  return (
    <div className={classes.root}>
      <AppHeader
        title="キャンペーン"
        hideBack
        hideHome
        showMypage
        showTutorialIcon
        showFeedback
        {...other}
      />
      <Grid className={classes.container} container direction={'column'} alignItems={'center'}>
        <Grid item>
          <Typography className={classes.title}>開催中のキャンペーン</Typography>
        </Grid>
        {campaigns.map(c => createBanner(c))}
      </Grid>
    </div>
  )
}

Campaigns.propTypes = {
  specialOffers: PropTypes.array,
  subscription: PropTypes.object,
  setCampaign: PropTypes.func,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
