import React, { useState, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
// https://material-ui-pickers.dev/
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import Api from 'commons/api'
import UploadPhotoForm from 'components/foodreport/UploadPhotoForm'
import BaseComponent from 'components/parts/BaseComponent'
import Koma from 'images/foodreport/koma_form.png'

const HEADER_HEIGHT = 54

const useStyles = makeStyles(theme => ({
  appBar: {
    height: HEADER_HEIGHT,
    boxShadow: '0 0 7px #0000002E',
  },
  backButton: {
    padding: 0,
    color: theme.palette.secondary.main,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#707070',
    width: '100%',
    textAlign: 'center',
  },
  reloadButton: {
    width: 62,
    height: 36,
  },
  container: {
    marginTop: HEADER_HEIGHT + theme.spacing(2.5),
  },
  subTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#2E2279',
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  koma: {
    width: 226,
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(3),
  },
  mission: {
    fontSize: 14,
    fontWeight: 500,
    color: '#ffffff',
    background: theme.palette.secondary.main,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
  },
  missionTextBox: {
    fontSize: 14,
    fontWeight: 500,
    color: '#2E2279',
    width: 'fit-content',
    marginTop: theme.spacing(2.5),
    marginRight: 'auto',
    marginBottom: theme.spacing(2.5),
    marginLeft: 'auto',
  },
  missionText: {
    width: 'fit-content',
    marginRight: 'auto',
    marginBottom: theme.spacing(1.25),
    marginLeft: 'auto',
  },
  basicInfo: {
    fontSize: 14,
    fontWeight: 500,
    color: '#ffffff',
    background: '#707070',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
  },
  columnGrey: {
    fontSize: 14,
    fontWeight: 500,
    color: '#252526',
    background: '#E0E0E0',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
  },
  column: {
    fontSize: 14,
    fontWeight: 500,
    color: '#2E2279',
    background: '#B0B4FC',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
  },
  datePicker: {
    width: 272,
    height: 48,
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
  },
  arrowDownIcon: {
    width: 18,
    color: theme.palette.secondary.main,
    position: 'absolute',
    left: 254,
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  selectBox: {
    width: 272,
    height: 48,
    marginBottom: theme.spacing(2.5),
  },
  addPhotoText: {
    fontSize: 12,
    color: '#767676',
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(2.25),
    marginLeft: theme.spacing(2),
  },
  count: {
    fontSize: 12,
    color: '#414141',
    marginLeft: theme.spacing(3.75),
  },
  textField: {
    width: `calc(100% - ${theme.spacing(4)}px)`,
    minHeight: 90,
    marginTop: theme.spacing(2.25),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2.25),
    marginLeft: theme.spacing(2),
    '& textarea': {
      '&::placeholder': {
        opacity: 1,
      }
    }
  },
  postButton:{
    fontSize: 16,
    width: 230,
    height: 44,
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(7.5),
  },
}))

export default function FoodReportForm (props) {
  const classes = useStyles()
  const { openFormDialog, foodReportId, onClose, shopList } = props
  const [foodReport, setFoodReport] = useState({})
  const [openCalendar, setOpenCalendar] = useState(false)

  const friendName = props.friend.nick_name
  const myName = props.user.nick_name
  const defaultText = 'ふたりで一緒に感想を考えて、レストランへ送ろう！\n2人どちらかが入力してね。\n\n＊注意＊\n・最終投稿の内容が反映されるよ。\n・メッセージが記入されていない場合はギフト券プレゼントの対象外となるよ。'

  const visitedAt = foodReport.visited_at
  const shopId = foodReport.shop_id
  const impression = foodReport.impression
  const malePhotoUrl = foodReport.male_photo_url
  const femalePhotoUrl = foodReport.female_photo_url

  // ダイアログを開くたびに最新の食レポデータを取得
  useEffect(() => {
    if (foodReportId && openFormDialog) { loadFoodReport() }
  }, [openFormDialog])

  // 最新の食レポ取得
  const loadFoodReport = async () => {
    props.setLoading(true)
    try {
      const data = await Api.getFoodReport(foodReportId)
      setFoodReport(data)
    } catch (e) {
      BaseComponent.handleApiError(props, e)
    } finally {
      props.setLoading(false)
    }
  }

  // 食レポ投稿
  const sendFoodReport = async () => {
    props.setLoading(true)
    try {
      const params= {
        'food_report': {
          'visited_at': visitedAt,
          'shop_id': shopId,
          'impression': impression,
          'submitted': true
        }
      }
      const data = await Api.updateFoodReport(props.foodReportId, params)
      setFoodReport(data)
    } catch (e) {
      BaseComponent.handleApiError(props, e)
    } finally {
      props.setLoading(false)
      onClose()
    }
  }

  const shopName = () => {
    return shopList.map(shopData => shopData.id === Number(shopId) && shopData.shop_name)
  }

  const handleChangeDate = (value) => {
    setFoodReport({ ...foodReport, visited_at: value})
    setOpenCalendar(false)
  }

  const handleChangeShopId = (event) => {
    const value = event.target.value
    setFoodReport({ ...foodReport, shop_id: value})
  }

  const handleChangeImpression = (event) => {
    const value = event.target.value
    setFoodReport({ ...foodReport, impression: value})
  }

  const validationImpression = (text) => {
    if (!text) { return true }
    return text.length <= 1000
  }

  // 写真読み込み&アップロード
  const handleReadImportedPhoto = async (event, target) => {
    const originalFile = event.target.files[0]
    if (!originalFile) { return }
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    const imageUrl = createObjectURL(originalFile)
    if (target === 'malePhoto') { setFoodReport({ ...foodReport, male_photo_url: imageUrl }) }
    if (target === 'femalePhoto') { setFoodReport({ ...foodReport, female_photo_url: imageUrl }) }
    props.setLoading(true)
    try {
      // アップロードした時点で一度全てのフィールドを入力されてる値で更新
      const data = await BaseComponent.updateFoodReportPhoto(foodReport, target, originalFile)
      setFoodReport(data)
    } catch (e) {
      BaseComponent.handleApiError(props, e)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <Dialog
      open={openFormDialog}
      fullScreen
      PaperProps={{ className: classes.paper }}
    >
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <IconButton className={classes.backButton} onClick={onClose}>
            <LeftIcon />
          </IconButton>
          <div className={classes.title}>食レポ投稿フォーム</div>
          <Button
            className={classes.reloadButton}
            variant="outlined"
            onClick={loadFoodReport}
          >
            更新
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <div className={classes.subTitle}>
          レストランはどうだった？<br />
          写真と感想を投稿して<br />
          Misson2つをクリアしよう！
        </div>
        <img className={classes.koma} src={Koma} alt="" />
        {/* ############################## Mission ############################## */}
        <div className={classes.missionTextBox}>
          <div className={classes.missionText}>Mission</div>
          1.双方の写真をアップロード<br />
          2.ふたりで考えてどちらかが投稿
        </div>
        <div className={classes.basicInfo}>基本情報</div>
        {/* ############################## 訪問日選択 ############################## */}
        <div className={classes.columnGrey}>訪問日</div>
        <Grid container alignItems="center">
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <DatePicker
              className={classes.datePicker}
              open={openCalendar}
              emptyLabel="YYYY/MM/DD"
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd"
              value={foodReport.visited_at ? foodReport.visited_at : null}
              onChange={handleChangeDate}
              onOpen={() => setOpenCalendar(true)}
            />
          </MuiPickersUtilsProvider>
          <KeyboardArrowDownIcon className={`${classes.arrowDownIcon} ${openCalendar && classes.rotate}`} />
        </Grid>
        {/* ############################## 訪問したお店選択 ############################## */}
        <div className={classes.columnGrey}>訪問したお店</div>
        <Select
          className={classes.selectBox}
          onChange={handleChangeShopId}
          displayEmpty
          value={foodReport.shop_id || ''}
          renderValue={() => (<>{foodReport.shop_id ? shopName() : 'プルダウンで選択'}</>)}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
          }}
        >
          {shopList.map((shopData, index) => {
            return <MenuItem key={index} value={shopData.id}>{shopData.shop_name}</MenuItem>
          })}
        </Select>
        {/* ############################## Mission1 ############################## */}
        <div className={classes.mission}>Mission1 双方の写真をアップロード</div>
        {/* ############################## 写真入力１ ############################## */}
        <div className={classes.column}>{myName}さんのお気に入り</div>
        <div className={classes.addPhotoText}>
          {myName}さんが1番印象に残った料理の写真をアップしてね<br />
          「更新ボタン」を押すと、相手の投稿写真が反映されるよ！
        </div>
        <UploadPhotoForm
          foodReport={foodReport}
          handleReadImportedPhoto={handleReadImportedPhoto}
          {...props}
        />
        {/* ############################## 写真入力2 ############################## */}
        <div className={classes.column}>{friendName}さんのお気に入り</div>
        <div className={classes.addPhotoText}>
          {friendName}さんが1番印象に残った料理の写真をアップしてね<br />
          「更新ボタン」を押すと、相手の投稿写真が反映されるよ！
        </div>
        <UploadPhotoForm
          formType={'friend'}
          foodReport={foodReport}
          handleReadImportedPhoto={handleReadImportedPhoto}
          {...props}
        />
        {/* ############################## Mission2 ############################## */}
        <div className={classes.mission}>Mission2 ふたりで考えてどちらかが投稿</div>
        {/* ############################## お店へのメッセージ ############################## */}
        <div className={classes.column}>
          お店へのメッセージ<span className={classes.count}>最大1,000文字</span>
        </div>
        <Input
          className={classes.textField}
          placeholder={defaultText}
          value={impression}
          onChange={handleChangeImpression}
          multiline
          disableUnderline
          maxRows={10}
        />
        {/* ############################## 投稿ボタン ############################## */}
        <Button
          className={classes.postButton}
          variant="outlined"
          onClick={sendFoodReport}
          disabled={
            // 全項目が空
            (!visitedAt && !shopId && !impression && !malePhotoUrl && !femalePhotoUrl)
            // impressionが入力されてる場合1000文字制限
            || (!validationImpression(impression))
          }
        >
          投稿する
        </Button>
      </div>
    </Dialog>
  )
}

FoodReportForm.propTypes = {
  openFormDialog: PropTypes.bool,
  foodReportId: PropTypes.string,
  onClose: PropTypes.func,
  shopList: PropTypes.array,
  friend: PropTypes.object,
  user: PropTypes.object,
  setLoading: PropTypes.func,
}
