import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BoostBadge from 'components/movie/BoostBadge'

const useStyles = makeStyles(theme => ({
  badgeItem: {
    width: 'fit-content',
  },
  badgeItemText: {
    fontSize: 12,
    marginTop: theme.spacing(1.5),
  },
}))

export default function SendConfirmDialog (props) {
  const { open, sendAnswer, onClose } = props
  const classes = useStyles()

  const createItem = (child, text) => (
    <Grid
      className={classes.badgeItem}
      container
      direction="column"
      alignItems="center"
    >
      {child}
      <span className={classes.badgeItemText}>
        {text}
      </span>
    </Grid>
  )

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          感想が未回答です。
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            翌日紹介者数が1名のみになります。<br />
            <br />
            感想の記入を行うと<br />
            次回紹介時＋１名紹介されます。<br />
            記入せず、このまま送信しますか？<br />
            <br />
          </Typography>
          <Grid container justifyContent="space-around">
            {createItem(<BoostBadge subText="ご紹介" />, 'アンケート回答')}
            {createItem(<BoostBadge plus subText="ご紹介" disabled />, '感想の記入')}
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={sendAnswer}>
            送信する
          </Button>
          <Button variant="text" onClick={onClose}>
            編集する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SendConfirmDialog.propTypes = {
  open: PropTypes.bool,
  sendAnswer: PropTypes.func,
  onClose: PropTypes.func,
}
