import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import TrailerList from 'components/movie/TrailerList'
import BaseComponent from 'components/parts/BaseComponent'
import LongCurtain from 'images/movie/theater_curtain_long.png'
import ShortCurtain from 'images/movie/theater_curtain_short.png'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `${window.innerHeight}px`,
    background: '#1A1667',
    textAlign: 'center',
    paddingTop: theme.spacing(13),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    paddingLeft: theme.spacing(3),
    backgroundImage: `url(${ShortCurtain}), url(${LongCurtain})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto, 200% 1400px',
    backgroundPositionX: 'center',
    backgroundPositionY: 'top, -910px',
    position: 'relative',
  },
  text: {
    fontSize: 22,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(5.5),
  },
  topButton: {
    width: 230,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    background: '#423BC7',
  },
  exitButton: {
    width: 230,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    color: '#423BC7',
    background: '#ffffff',
    position: 'absolute',
    bottom: 40,
  },
}))

export default function TheaterExit (props) {
  const { match } = props
  const classes = useStyles()
  const [movieEvents, setMovieEvents] = useState([])
  const movieId = match.params?.id

  useEffect(() => {
    (async () => {
      BaseComponent.addVisitPageLog()
      props.setLoading(true)
      try {
        const res = await Api.getMovieEvents('trailer')
        setMovieEvents(res.filter(obj => obj?.id !== movieId))
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  return (
    <>
      <Grid className={classes.root} container direction="column" alignItems="center">
        <Typography className={classes.text}>
          送信完了！<br />
          <br />
          紹介者を楽しみに<br />
          していてね！
        </Typography>
        <Button
          className={classes.topButton}
          variant="contained"
          onClick={() => props.setScreen('/Theater')}
        >
          シアターTOPへ
        </Button>
        <TrailerList movieEvents={movieEvents} exitPage {...props} />
        <Button
          className={classes.exitButton}
          variant="contained"
          onClick={() => props.setScreen('/Home')}
        >
          シアターを退場する
        </Button>
      </Grid>
    </>
  )
}

TheaterExit.propTypes = {
  match: PropTypes.object,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
