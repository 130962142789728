import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/KeyboardArrowDown'
import Api from 'commons/api'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { isProfilePublicFieldName, isProfilePrivateFieldName, isProfileIgnoredKeys, isEmpty } from 'commons/utility'
import BaseComponent from './BaseComponent'
import ImportantConfirmDialog from './ImportantConfirmDialog'

const styles = theme => ({
  table: {
    width: '100%',
  },
  cell: {
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  cellTitle: {
    width: '50%',
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  cellTitleEdit: {
    width: '42%',
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  itemTitle: {
    ...theme.styles.profileItem,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  itemTitleEdit: {
    ...theme.styles.profileItem,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  warningNoItem: {
    color: '#8B0000'
  },
  itemValue: {
    ...theme.styles.profileItem,
    color: theme.palette.secondary.main,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    paddingRight: theme.spacing(1),
  },
  itemValuePublic: {
    ...theme.styles.profileItem,
    color: theme.palette.secondary.main,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    paddingRight: theme.spacing(1),
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
  },
  itemStatus: {
    ...theme.styles.profileItemStatus,
    marginTop: theme.spacing(1.375),
    textAlign: 'right',
  },
  cellStatus: {
    verticalAlign: 'top',
    textAlign: 'center',
    borderStyle: 'none',
    width: theme.spacing(7.375),
  },
  buttonPublic: {
    ...theme.styles.fontSize(10),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 'bold',
    borderRadius: 9,
    minWidth: theme.spacing(7.375),
    marginTop: theme.spacing(1.375),
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    }
  },
  buttonPrivate: {
    ...theme.styles.fontSize(10),
    color: theme.palette.secondary.main,
    backgroundColor: '#EFF0FF',
    fontWeight: 'bold',
    borderRadius: 9,
    minWidth: theme.spacing(7.375),
    marginTop: theme.spacing(1.375),
    '&:hover': {
      backgroundColor: '#EFF0FF !important',
    }
  },
  cellEdit: {
    verticalAlign: 'top',
    textAlign: 'center',
    borderStyle: 'none',
    width: theme.spacing(3),
    padding: `${theme.spacing(1.375)}px 0`,
  },
  editButton: {
    padding: 0,
  },
  editIcon: {
    height: theme.spacing(2.25),
    color: theme.palette.secondary.main,
  },
  fullTextField: {
    width: '100%',
  },
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  trigger: {
    color: '#ffffff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

export class MyLoveProfile extends BaseComponent {
  constructor (props) {
    super(props)

    this.handlePublicChange = this.handlePublicChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openTermsDialog = this.openTermsDialog.bind(this)
    this.closeTermsDialog = this.closeTermsDialog.bind(this)
    this.closeSmokingConfirmDialog = this.closeSmokingConfirmDialog.bind(this)

    this._isMounted = false

    this.state = {
      user: null,
      publics: props.publics,
      editOpen: false,
      editKey: '',
      isHousewife: false,
      isTerms: false,
      isSmokingHeavy: false,
    }
  }

  componentWillMount () {
    this._isMounted = true
    this.initProfileInputs()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  initProfileInputs () {
    let keys = this.getUserProfileDisplayKeys()
    let user = {}
    keys.forEach(key => {
      user[key] = this.props.user[key] || ''
    })
    this.setStateIfMounted({ user })
  }

  componentWillReceiveProps (newProps) {
    if (this.props.publics !== newProps.publics) {
      this.setState({ publics: newProps.publics })
    }
  }

  async handlePublicChange (key) {
    if (!this.state.publics.target_user_id) { return }

    this.props.setLoading(true)
    let params = { [key]: !this.state.publics[key] }

    let targetUserId = this.state.publics.target_user_id
    try {
      let setting = await Api.updateProfilePublicSetting(targetUserId, params)
      this.setStateIfMounted({
        publics: setting,
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  getEditLabelOf (profileName) {
    switch (profileName) {
      case '' : return ''
      default:
        return this.props.master.profile[profileName].title
    }
  }

  isNotEdit (profileName) {
    switch (profileName) {
      case 'blood_type':
        if (isNullOrUndefined(this.props.user.blood_type)) {
          return false
        } else {
          return true
        }
      default:
        return false
    }
  }

  useState (editKey) {
    return !['how_to_spend_the_holidays'].includes(editKey)
  }

  async clickEdit (key) {
    const editKey = key
    const keyValue = this.state.user[key]
    await this.setState({ editOpen: true, editKey: editKey, [editKey]: keyValue })
    if (!this.useState(editKey)) {
      BaseComponent.setInputValue(editKey, keyValue)
    }
  }

  handleClose () {
    this.setState({ editOpen: false })
  }

  async handleSave () {
    const { editKey } = this.state
    const newValue = this.useState(editKey) ? this.state[editKey] : BaseComponent.getInputValue(editKey)
    if (isEmpty(newValue)) {
      this.setState({[editKey + '_error']: true})
      return
    }
    if ((editKey === 'marital_status' || editKey === 'marital_status_of_partner') && this.state[editKey] === 'over_3_times') {
      this.setState({ [editKey + '_error']: true, [editKey + '_over3_error']: true })
      return
    }

    const params = {}
    params[editKey] = newValue

    this.props.setLoading(true)
    try {
      await this.updateUser(params)
      this.initProfileInputs()
      this.setStateIfMounted({ editOpen: false })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleChange (event) {
    const { editKey } = this.props
    const value = event.target.value
    const name = event.target.name
    if ((name === 'future_work_way_of_partner' || name === 'future_work_way') && value === 'housewife') {
      this.setState({
        isHousewife: true,
        [name]: value,
        [name + '_error']: false,
      })
    } else if ((name === 'marital_status' || name === 'marital_status_of_partner') && value === 'over_3_times') {
      this.setState({
        [name]: value,
        [name + '_error']: true,
        [name + '_over3_error']: true,
      })
    } else if (name === 'smoking_status' && value === 'heavy') {
      this.setState({
        isSmokingHeavy: true,
        [name]: value,
        [name + '_error']: false,
      })
    } else if (!this.useState(editKey)) {
      BaseComponent.setInputValue(editKey, value)
      if (this.state[name + '_error']) { this.setState({ [name + '_error']: false }) }
    } else {
      this.setState({
        [name]: value,
        [name + '_error']: false,
        [name + '_over3_error']: false,
      })
    }
  }

  closeConfirmDialog () {
    this.setState({ isHousewife: false })
  }

  openTermsDialog () {
    this.setState({ isTerms: true })
  }

  closeTermsDialog () {
    this.setState({ isTerms: false })
  }

  closeSmokingConfirmDialog () {
    this.setState({ isSmokingHeavy: false })
  }

  createContent (key) {
    const { classes } = this.props
    switch (key) {
      case 'holiday':
      case 'future_work_way':
      case 'future_work_way_of_partner':
      case 'ideal_housework_and_childcare':
      case 'acceptable_housework_and_childcare':
      case 'current_children':
      case 'children':
      case 'sibling':
      case 'annual_income':
      case 'birthplace':
      case 'u_turn_intention':
      case 'blood_type':
      case 'liquor':
      case 'smoking_status':
      case 'heterosexual_smoking':
      case 'navi1':
      case 'navi2':
      case 'navi3':
      case 'date_timing':
      case 'marriage_intention':
      case 'height':
        return this.createUserProfileSelect(key, { noTitle: true })
      case 'how_to_spend_the_holidays':
        return this.createUserProfileTextField(key, { noTitle: true })
      case 'marital_status':
      case 'marital_status_of_partner':
        return (
          <FormControl className={classes.formControl}>
            <Select
              error={this.state[`${key}_error`]}
              value={this.state[key]}
              onChange={this.handleChange}
              name={key}
              IconComponent={() => (<EditIcon />)}
            >
              <MenuItem value=""><em>未選択</em></MenuItem>
              {
                this.props.master.profile[key].detail.common.map((val, i) => {
                  return (<MenuItem key={i} value={val.value}>{val.label}</MenuItem>)
                })
              }
            </Select>
            <FormHelperText
              style={{display: this.state[`${key}_over3_error`] ? '' : 'none'}}
              error={this.state[`${key}_over3_error`]}>
              {key === 'marital_status' ? '結婚歴3回以上の場合は入会できません' : '相手に求める結婚歴が3回以上の場合は入会できません'}
            </FormHelperText>
          </FormControl>
        )
      default:
        return null
    }
  }

  render () {
    const { classes } = this.props
    const profileMaster = this.props.master.profile
    return (
      <Grid container direction="column">
        <Table className={classes.table}>
          <tbody>
            {Object.keys(this.state.user).map(key => {
              if ((!this.props.isPublicProfile || this.props.isEdit) && key === 'to_message') {
                return null
              }
              if (!isProfileIgnoredKeys(key)) {
                const userProfileItemLabel = this.getUserProfileItemLabel(this.props.user, key)
                return (
                  (this.props.isPublicProfile || isProfilePublicFieldName(key) || this.state.publics[key]) ? (
                    <TableRow className={classes.row} key={key}>
                      <TableCell className={this.props.isEdit ? classes.cellTitleEdit : classes.cellTitle}>
                        <Typography className={this.props.isEdit ? `${classes.itemTitleEdit} ${!userProfileItemLabel && classes.warningNoItem}` : classes.itemTitle}>
                          {profileMaster[key].title}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell} onClick={() => this.props.isEdit && !this.isNotEdit(key) ? this.clickEdit(key) : null}>
                        <Typography className={this.props.isPublicProfile ? classes.itemValuePublic : classes.itemValue}>
                          {userProfileItemLabel}
                        </Typography>
                      </TableCell>
                      {
                        this.props.isEdit &&
                        <TableCell padding={'none'} className={classes.cellEdit} onClick={() => !this.isNotEdit(key) ? this.clickEdit(key) : null}>
                          {
                            !this.isNotEdit(key) &&
                              <IconButton aria-label="edit" className={classes.editButton}>
                                <EditIcon className={classes.editIcon}/>
                              </IconButton>
                          }
                        </TableCell>
                      }
                      {this.props.isPublicProfile ? (
                        <TableCell padding={'none'} className={classes.cellStatus}>
                          {isProfilePublicFieldName(key, this.state.publics[key]) ? (
                            <Typography className={classes.itemStatus}>公開</Typography>
                          ) : isProfilePrivateFieldName(key) ? (
                            <Typography className={classes.itemStatus}>非公開</Typography>
                          ): (
                            <Button
                              variant="contained"
                              className={this.state.publics[key] ? classes.buttonPublic : classes.buttonPrivate}
                              onClick={() => this.handlePublicChange(key)}
                            >
                              {this.state.publics[key] ? '公開' : '非公開'}
                            </Button>
                          )}
                        </TableCell>
                      ) : null
                      }
                    </TableRow>
                  ) : null
                )
              } else {
                return null
              }
            })}
          </tbody>
        </Table>
        <DialogThemeProvider color="default">
          <Dialog onClose={this.handleClose} open={this.state.editOpen}>
            <DialogTitle disableTypography>{this.getEditLabelOf(this.state.editKey)}</DialogTitle>
            <DialogContent>
              {this.createContent(this.state.editKey)}
            </DialogContent>
            <DialogActions>
              <Grid container direction='column' alignItems="center">
                <Button variant="contained" onClick={this.handleSave}>
                  決定する
                </Button>
                <Button variant="text" onClick={this.handleClose}>
                  キャンセル
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </DialogThemeProvider>
        <ImportantConfirmDialog
          open={this.state.isHousewife}
          onClose={this.closeConfirmDialog}
          close="yes"
          title="注意事項"
          message={this.getHousewifeConfirmMessage()}
        />
        <ImportantConfirmDialog
          open={this.state.isTerms}
          onClose={this.closeTermsDialog}
          close="yes"
          title="第5条（利用資格）"
          message={this.getHousewifeTermsMessage()}
        />
        <ImportantConfirmDialog
          open={this.state.isSmokingHeavy}
          onClose={this.closeSmokingConfirmDialog}
          close="yes"
          title="注意事項"
          message={this.getSmokingConfirmMessage()}
        />
      </Grid>
    )
  }
}

MyLoveProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  publics: PropTypes.object.isRequired,
}

export default withStyles(styles)(MyLoveProfile)