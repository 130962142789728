import React, { useState, useEffect } from 'react'
import PropTypes, { string } from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  message: {
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function ImportantErrorDialog (props) {
  const { open, importantErrorMessage, onCloseImportantErrorMessage } = props
  const [title, setTitle] = useState('')
  const classes = useStyles()

  const closeImportantErrorDialog = () => {
    let handler = onCloseImportantErrorMessage
    props.setImportantErrorMessage(null, null)
    if (handler) { handler() }
  }

  useEffect(() => {
    if (!importantErrorMessage) { return }
    const notSubmitted = !importantErrorMessage.indexOf('本登録が完了していません。')
    const checking = !importantErrorMessage.indexOf('ただいま入会審査中です。')
    const title = notSubmitted ? '本登録が未完了です' : checking ? '入会審査中です' : ''
    setTitle(title)
  }, [importantErrorMessage])

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.message} variant="body1">
            {importantErrorMessage}
          </Typography>
          <Typography className={classes.message} variant="body1">
            ＊お問い合わせ先：<u>support@aill.ai</u>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeImportantErrorDialog}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

ImportantErrorDialog.propTypes = {
  open: PropTypes.bool,
  importantErrorMessage: string,
  onCloseImportantErrorMessage: PropTypes.func,
  setImportantErrorMessage: PropTypes.func,
}
