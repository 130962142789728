import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  imgContainer: {
    width: '100%',
    height: 315,
    background: '#F5F5F5',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  photoButton: {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(2.5),
    marginRight: 'auto',
    marginBottom: theme.spacing(3.5),
    marginLeft: 'auto',
  },
  addIconButton: {
    color: '#ffffff',
    background: '#C6C6C6',
    width: 46,
    height: 46,
    borderRadius: 0,
    marginBottom: theme.spacing(3.5),
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: '#ffffff',
      background: '#C6C6C6 !important',
    }
  },
}))

export default function UploadPhotoForm (props) {
  const classes = useStyles()
  const { formType, foodReport } = props

  // 対象を選択（自分 or フレンド）
  const focus = formType === 'friend' ? props.friend : props.user
  // 対象の性別からフォームID決定
  const inputId = focus.sex === 'male' ? 'malePhoto' : 'femalePhoto'
  // 対象の性別から参照する写真URL決定
  const photoUrl = focus.sex === 'male' ? foodReport.male_photo_url : foodReport.female_photo_url

  return (
    <>
      {photoUrl ? (
        <>
          <Grid
            className={classes.imgContainer}
            container
            justifyContent="center"
            alignItems="center"
          >
            <img
              className={classes.img}
              src={photoUrl}
              alt="食レポ投稿写真"
            />
          </Grid>
          <Button
            className={classes.photoButton}
            variant="outlined"
            htmlFor={inputId}
            component="label"
          >
            写真を変更する
          </Button>
        </>
      ) : (
        <IconButton className={classes.addIconButton} htmlFor={inputId} component="label">
          <AddIcon />
        </IconButton>
      )}
      <form noValidate autoComplete="off">
        <input
          id={inputId}
          type="file"
          accept="image/*"
          onClick={event => {
            event.target.value = null
            return false
          }}
          onChange={(event) => props.handleReadImportedPhoto(event, inputId )}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
          }}
        />
      </form>
    </>
  )
}

UploadPhotoForm.propTypes = {
  friend: PropTypes.object,
  user: PropTypes.object,
  formType: PropTypes.string,
  foodReport: PropTypes.object,
  handleReadImportedPhoto: PropTypes.func,
}
