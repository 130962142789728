import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import ImageMsg from 'images/img_dating_msg.png'
import Image from 'images/img_free_period.png'

const styles = theme => ({
  text: {
    marginBottom: theme.spacing(1.5),
  },
  message: {
    fontSize: 12,
    ...theme.styles.messageColor,
    lineHeight: 1.75,
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  messageContainer: {
    backgroundImage: `url(${ImageMsg})`,
    backgroundSize: '100% 100%',
    display: 'table',
    width: '100%',
    height: theme.spacing(25),
  },
  image: {
    width: theme.spacing(28),
    height: theme.spacing(14.274),
    objectFit: 'contain',
  },
})

export class DemoNotificationDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    const { classes, open } = this.props
    return(
      <DialogThemeProvider color="secondary">
        <Dialog style={{ zIndex: 10000 }} open={open}>
          <DialogTitle disableTypography>
            トークアシストについて
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.text} variant="body1">
              狛犬トークルームでトークアシストのDEMOが確認できます。<br/>
              ぜひチェックしてみてください！
            </Typography>
            <Grid container className={classes.messageContainer} justifyContent="center" alignItems="flex-end">
              <Typography className={classes.message} variant="body1">
                ボクたちのトークアシスト、<br/>
                どんなものがあるか確認してみてね！<br/>
                役に立てるよう頑張るよ！！<br/>
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <img src={Image} className={classes.image} alt=""/>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={() => this.props.onOk()}>
                狛犬トークルームへ
              </Button>
              <Button variant="text" onClick={() => this.props.onClose()}>
                閉じる
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

DemoNotificationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
}

export default withStyles(styles)(DemoNotificationDialog)