import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import exampleImg from 'images/foodreport/example_image.png'

const useStyles = makeStyles(() => ({
  text: {
    color: '#1A1667',
  },
  img: {
    display: 'block',
    margin: 'auto',
  },
}))

export default function FoodReportExample (props) {
  const classes = useStyles()
  const { open, onClose } = props

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.text} disableTypography>
          食レポイメージ
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="body1">
            当日送る食レポは<br />
            下記のイメージだよ♪
          </Typography>
          <img className={classes.img} src={exampleImg} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

FoodReportExample.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
