import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IncentiveXmas from 'images/xmas-campaign/incentive_xmas.png'
import IncentiveXmasForFree from 'images/xmas-campaign/incentive_xmas_for_free.png'
import PinkSnowLeftBottom from 'images/xmas-campaign/pink_snow_left_bottom.png'
import PinkSnowLeftTop from 'images/xmas-campaign/pink_snow_left_top.png'
import PinkSnowRightBottom from 'images/xmas-campaign/pink_snow_right_bottom.png'
import PinkSnowRightTop from 'images/xmas-campaign/pink_snow_right_top.png'
import Title from 'images/xmas-campaign/title.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 268,
    minHeight: 156,
    background: '#ffffff',
    borderRadius: 12,
    boxShadow: '0px 1px 6px #00000029',
    paddingTop: for_free => theme.spacing(for_free ? 3.25 : 3.5),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(3),
    backgroundImage: `url(${PinkSnowLeftTop}), url(${PinkSnowRightTop}), url(${PinkSnowLeftBottom}), url(${PinkSnowRightBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '28px 28px',
    backgroundPositionX: '4%, 96%, 4%, 96%',
    backgroundPositionY: '4%, 4%, 95%, 95%',
  },
  title: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: for_free => for_free ? 13 : 16,
    lineHeight: 1,
    color: '#C73C51',
    marginBottom: for_free => theme.spacing(for_free ? 0 : 1),
  },
  incentiveText: {
    fontSize: 20,
    fontWeight: 700,
    color: '#C73C51',
    lineHeight: 1,
    marginBottom: theme.spacing(0.75),
  },
  terms: {
    fontSize: 12,
    lineHeight: 1,
    color: '#C73C51',
    marginTop: theme.spacing(1.25),
  },
}))

export default function XmasCampaignBox (props) {
  const { type } = props
  const classes = useStyles(type === 'xmas_for_free')

  return (
    <div className={classes.root}>
      <img className={classes.title} src={Title} width={220} />
      {type === 'xmas_for_free' ? (
        <>
          <Typography className={classes.text} variant="subtitle1">
            スタンダードプラン
          </Typography>
          <img src={IncentiveXmasForFree} width={222} height={49} />
          <Typography className={classes.terms} variant="body1">
            クーポン期限：{dateFormat('2024-01-31T00:00:00.000+09:00', 'yyyy年mm月dd日まで')}
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.text} variant="subtitle1">
            本日の紹介人数が
          </Typography>
          <Grid container justifyContent="center" alignItems="flex-end">
            <img src={IncentiveXmas} width={90} height={43} />
            <span className={classes.incentiveText}>に！</span>
          </Grid>
        </>
      )}
    </div>
  )
}

XmasCampaignBox.propTypes = {
  type: PropTypes.string.isRequired,
}
