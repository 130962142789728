import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Api from 'commons/api'
import BoostBadge from 'components/movie/BoostBadge'
import SendConfirmDialog from 'components/movie/SendConfirmDialog'
import BaseComponent from 'components/parts/BaseComponent'
import Kagura from 'images/img_character_a.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 338,
    backgroundColor: '#616AFA',
    borderRadius: 22,
    textAlign: 'left',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(2.5),
    position: 'relative',
  },
  cover: {
    width: '100%',
    height: '100%',
    fontSize: 30,
    fontWeight: 600,
    color: '#ffffff',
    borderRadius: 22,
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
  },
  coverA: {
    backgroundColor: 'rgba(104, 102, 157, 0.8)',  // #68669D
  },
  coverB: {
    backgroundColor: 'rgba(97, 106, 250, 0.8)', // #616AFA
  },
  questions: {
    margin: 0,
    padding: 0,
  },
  question: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    whiteSpace: 'pre-wrap',
  },
  // =============== Q1 ===============
  starBorder: {
    color: '#ffffff',
  },
  starFill: {
    color: '#EFCD3F',
  },
  // =============== Q2 ===============
  q2Chip: {
    fontSize: 12,
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: '#616AFA',
    border: '1px solid #ffffff',
    margin: theme.spacing(0.25),
    '&:hover': {
      backgroundColor: '#616AFA',
    },
    '&:focus': {
      backgroundColor: '#616AFA',
    },
  },
  q2ChipChecked: {
    fontSize: 12,
    fontWeight: 400,
    color: '#616AFA',
    backgroundColor: '#ffffff',
    border: '1px solid #ffffff',
    margin: theme.spacing(0.25),
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  // =============== Q3 ===============
  q3CheckboxLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
  },
  q3Checkbox: {
    height: 32,
    color: '#ffffff',
  },
  squareBorder: {
    color: '#ffffff',
  },
  squareFill: {
    color: '#ffffff',
  },
  // =============== Q4 ===============
  q4CheckboxLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
  },
  q4Checkbox: {
    height: 32,
    color: '#ffffff',
  },
  circleBorder: {
    color: '#ffffff',
  },
  circleFill: {
    color: '#ffffff',
  },
  // =============== Q5 ===============
  message: {
    fontSize: 12,
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: '#8636D6',
    borderRadius: 21,
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    transform: 'translate(-50%, -60%)',
    right: 0,
  },
  q5Input: {
    fontSize: 14,
    width: '100%',
    maxWidth: 300,
    height: 176,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(5),
  },
  q5InputError: {
    border: '1px solid red',
  },
  // =============== 参加同意 ===============
  consentCheckBox: {
    height: 32,
    color: '#ffffff',
    marginBottom: theme.spacing(4),
  },
  consentLabel: {
    fontSize: 10,
    fontWeight: 600,
    color: '#ffffff',
  },
  button: {
    width: 230,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    backgroundColor: '#423BC7',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#423BC7',
    },
  },
  disabledButton: {
    color: '#ffffff !important',
    backgroundColor: '#B0B4FC !important',
  },
}))

export default function TheaterForm (props) {
  const { movieInfo, watched, answered } = props
  const classes = useStyles()
  const [q1Value, setQ1Value] = useState(0)
  const [q2Value, setQ2Value] = useState([])
  const [q3Value, setQ3Value] = useState([])
  const [q4Value, setQ4Value] = useState(null)
  const [q5Value, setQ5Value] = useState('')
  const [consent, setConsent] = useState(true)
  const [openSendConfirmDialog, setOpenSendConfirmDialog] = useState(false)

  const movieId = movieInfo?.id
  const movieTitle = movieInfo?.movie_title
  const impressionItems = movieInfo?.impression_items

  const genres = {
    action: 'アクション',
    animation: 'アニメ',
    sf: 'SF',
    fantasy: 'ファンタジー',
    romance: '恋愛',
    youth: '青春',
    comedy: 'コメディ',
    mystery: 'ミステリー',
    documentary: 'ドキュメンタリー',
    suspense: 'サスペンス',
    horror: 'ホラー',
    historical: '歴史物',
  }
  const theaterFrequency = {
    once_a_year: '１年に１回',
    more_than_twice_a_year: '１年に２回以上',
    once_a_month: '月に１回',
    once_a_week: '週に１回',
    more_than_twice_a_week: '週に２回以上',
  }
  const maxMessageLength = 500

  const createCover = () => (
    <>
      {answered && (
        <Typography className={`${classes.cover} ${classes.coverA}`}>
          すでに回答済みの<br />
          イベントです。<br />
          次のイベントの開催を<br />
          お待ちください。
        </Typography>
      )}
      {!watched && (
        <Typography className={`${classes.cover} ${classes.coverB}`}>
          予告編を最後まで<br />
          ご視聴いただくと<br />
          回答できます
        </Typography>
      )}
    </>
  )

  const createQuestion = (text) => (
    <li className={classes.question}>
      {text}
    </li>
  )

  const sendDisable = () => (
    !(q1Value && q2Value.length && q3Value.length && q4Value && consent)
  )

  const q2Checked = (label) => q2Value.includes(label)

  const handleChangeQ2 = (label) => {
    if (!q2Checked(label)) {
      if (q2Value.length === 5) { return }
      setQ2Value([...q2Value, label])
    } else {
      setQ2Value(q2Value.filter(val => val !== label))
    }
  }

  const handleChangeQ3 = (event) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setQ3Value([...q3Value, value])
    } else {
      setQ3Value(q3Value.filter(val => val !== value))
    }
  }

  const handleChangeQ4 = (event) => {
    const checked = event.target.checked
    const value = event.target.value
    setQ4Value(checked ? value : null)
  }

  const onChangeQ5 = (event) => {
    const value = event.target.value
    setQ5Value(value)
  }

  const sendAnswer = async () => {
    props.setLoading(true)
    try {
      const params = {
        rating: q1Value,
        impressions: q2Value,
        favorite_genres: q3Value,
        theater_frequency: q4Value,
        impression_text: q5Value,
      }
      await Api.updateMovieSurveys(movieId, params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      props.setScreen(`/TheaterExit/${movieId}`)
    }
  }

  return (
    <>
      <div className={classes.root}>
        {createCover()}
        <ol className={classes.questions}>
          {createQuestion('この映画に対する期待値を\n5段階評価で答えてね！')}
          <Grid container justifyContent="center" alignItems="center">
            {[1, 2, 3, 4, 5].map(imp =>(
              <Checkbox
                key={imp}
                onChange={() => setQ1Value(imp)}
                checked={q1Value >= imp}
                icon={<StarBorderIcon className={classes.starBorder} />}
                checkedIcon={<StarIcon className={classes.starFill} />}
              />
            ))}
          </Grid>
          {createQuestion('この映画予告を見て感じたことを\n5個選択してね！')}
          {impressionItems?.map(label => (
            <Chip
              key={label}
              className={q2Checked(label) ? classes.q2ChipChecked : classes.q2Chip}
              label={label}
              onClick={() => handleChangeQ2(label)}
            />
          ))}
          {createQuestion('好きな映画のジャンルは？（複数可）')}
          <Grid container direction="column">
            {Object.entries(genres).map(([key, val]) => (
              <FormControlLabel
                key={key}
                classes={{
                  root: classes.q3Checkbox,
                  label: classes.q3CheckboxLabel,
                }}
                label={val}
                control={(
                  <Checkbox
                    value={key}
                    onChange={handleChangeQ3}
                    checked={q3Value.includes(key)}
                    icon={<CheckBoxOutlineBlankIcon className={classes.squareBorder} />}
                    checkedIcon={<CheckBoxIcon className={classes.squareFill} />}
                  />
                )}
              />
            ))}
          </Grid>
          {createQuestion('映画館へ行く頻度は？')}
          <Grid container direction="column">
            {Object.entries(theaterFrequency).map(([key, val]) => (
              <FormControlLabel
                key={key}
                classes={{
                  root: classes.q4Checkbox,
                  label: classes.q4CheckboxLabel,
                }}
                label={val}
                control={(
                  <Checkbox
                    value={key}
                    onChange={handleChangeQ4}
                    checked={q4Value === key}
                    icon={<RadioButtonUncheckedIcon className={classes.circleBorder} />}
                    checkedIcon={<RadioButtonCheckedIcon className={classes.circleFill} />}
                  />
                )}
              />
            ))}
          </Grid>
          {createQuestion(`感想・${movieTitle}の映画に期待することなど（自由記載）`)}
          <Grid container justifyContent="flex-end">
            <img src={Kagura} width={34} height={36} />
            <Typography className={classes.message}>
              チャンス！感想の記入で<br />
              次回紹介時＋１名紹介されるよ！<br />
              感想内容も見て、紹介するね！
            </Typography>
            <span className={classes.badge}>
              <BoostBadge plus subText="チャンス" />
            </span>
          </Grid>
          <InputBase
            className={classes.q5Input}
            classes={{ error: classes.q5InputError }}
            value={q5Value}
            onChange={onChangeQ5}
            error={q5Value > maxMessageLength}
            multiline
            rows="8"
          />
        </ol>
        <FormControlLabel
          classes={{
            root: classes.consentCheckBox,
            label: classes.consentLabel,
          }}
          label="本アンケート情報は、特定の個人を識別できないように加工し、Aillならびに松竹がサービス改善利用に役立つデータ等の還元を目的として、集計等を行うために利用させていただきます。"
          control={(
            <Checkbox
              onChange={(event) => setConsent(event.target.checked)}
              checked={consent}
              icon={<CheckBoxOutlineBlankIcon className={classes.squareBorder} />}
              checkedIcon={<CheckBoxIcon className={classes.squareFill} />}
            />
          )}
        />
        <Button
          className={classes.button}
          classes={{ disabled: classes.disabledButton }}
          variant="contained"
          onClick={() => q5Value ? sendAnswer() : setOpenSendConfirmDialog(true)}
          disabled={sendDisable()}
        >
          {sendDisable() ? '回答後に送信できます' : '送信する'}
        </Button>
      </div>
      <SendConfirmDialog
        open={openSendConfirmDialog}
        sendAnswer={sendAnswer}
        onClose={() => setOpenSendConfirmDialog(false)}
      />
    </>
  )
}

TheaterForm.propTypes = {
  movieInfo: PropTypes.object,
  watched: PropTypes.bool,
  answered: PropTypes.bool,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
