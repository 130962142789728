import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { isEmpty } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(3),
    minHeight: window.innerHeight + 'px',
    ...theme.styles.shopListColor,
  },
  subTitle: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    ...theme.styles.messageColor,
    fontSize: 14,
    fontWeight: 'bold',
  },
  message: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    display: 'inline-block',
    ...theme.styles.messageColor,
    fontSize: 12,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    width: '100%',
  },
  searchButton: {
    ...theme.styles.entryButton,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    width: '100%',
    height: theme.spacing(6),
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
})

class ShopSearch extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.goShopList = this.goShopList.bind(this)

    this.state = {
      pref: '',
      station: '',
      category: '',
      open_time: '',
      take_out: '',
      pref_items: [],
      station_items: [],
      category_items: [],
      open_time_items: [],
      pref_error: false,
      station_error: false,
    }
  }

  componentWillMount () {
    this.createPrefItems()
    this.createCategoryItems()
    this.createOpenTimeItems()
    this.createPrefecturesStations()
  }

  async createPrefecturesStations () {
    this.props.setLoading(true)
    try {
      const prefs = await Api.getPrefs()
      prefs.map((pref) => ({
        value: pref.id,
        label: pref.display_name,
        display_order: pref.display_order,
      }))
    } catch (error){
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })

    if (event.target.name === 'pref') {
      if (event.target.value === '') {
        this.setState({ station_items: [] })
      } else {
        this.createStationItems(event.target.value)
      }
      this.setState({
        station: '',
        pref_error: false,
        station_error: false,
      })
    } else if (event.target.name === 'station') {
      this.setState({ station_error: false })
    }
  }

  goShopList () {
    if (isEmpty(this.state.pref)) {
      this.setState({ pref_error: true })
      return
    }
    if (isEmpty(this.state.station)) {
      this.setState({ station_error: true })
      return
    }
    let search = {
      pref: this.state.pref,
      station: this.state.station,
      category: this.state.category,
      open_time: this.state.open_time,
      take_out: this.state.take_out,
    }
    this.props.setShopSearch(search)
    this.props.setScreen('Shops')
  }

  render () {
    const { classes, ...other } = this.props

    return(
      <React.Fragment>
        <AppHeader title={'レストラン検索'} hideHome={true} {...other} />
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <Typography className={classes.subTitle} >
              失敗しないお店をテーマに掲載しています
            </Typography>
            <Typography className={classes.message} >
              男性のみに表示している機能です。
            </Typography>
            <Typography className={classes.message} >
              ※新型コロナウイルスの影響のため、お店の営業状況の確認にご協力お願いします。
              ご来店時は事前に店舗にご確認ください。
            </Typography>
          </Grid>
          <div className={classes.container}>
            <Card className={classes.card}>
              { this.createSearchSelect('pref', '都道府県', this.state.pref_items) }
              { this.createSearchSelect('station', '最寄駅', this.state.station_items, this.state.pref === '' ? true : false) }
              { this.createSearchSelect('category', 'カテゴリ', this.state.category_items) }
              { this.createSearchSelect('open_time', '時間帯', this.state.open_time_items) }
              { this.createSearchSelect('take_out', 'テイクアウト', [{value: true, label: 'あり'}]) }
              <div className={classes.buttonContainer}>
                <Button variant="contained" className={classes.searchButton} onClick={this.goShopList}>
                  検索
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

ShopSearch.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ShopSearch)
