import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { addDate } from 'commons/utility'
import DecorationLeft from 'images/valentine-event/decoration_left.png'
import DecorationRight from 'images/valentine-event/decoration_right.png'
import EventTitle from 'images/valentine-event/event_title.png'
import GoodImg from 'images/valentine-event/good.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#ffffff',
    borderRadius: 12,
    boxShadow: '0px 1px 6px #00000029',
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(2.5),
  },
  bgimg: {
    backgroundImage: `url(${DecorationLeft}), url(${DecorationRight})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '32px auto',
    backgroundPositionX: '3%, 97%',
    backgroundPositionY: '50%',
  },
  title: {
    display: 'block',
    margin: 'auto',
  },
  text: {
    fontSize: 13,
    lineHeight: 1,
    color: '#5D0519',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  good: {
    marginLeft: theme.spacing(0.25),
  },
  incentive: {
    width: 166,
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '37px',
    color: '#5D0519',
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(transparent 65%, #FFDBE7 0)',
  },
  terms: {
    lineHeight: 1,
    color: '#5D0519',
  },
}))

export default function ValentineEventBox (props) {
  const { mission, bgimg } = props
  const classes = useStyles()

  if (!mission) { return null }

  // 期間
  const startAt = new Date(mission?.start_at || '2023-01-17T00:00:00.000+09:00')
  const endAt = addDate(new Date(mission.end_at), -1)

  return (
    <div className={`${classes.root} ${bgimg ? classes.bgimg : ''}`}>
      <img className={classes.title} src={EventTitle} width={208} />
      <Typography className={classes.text} variant="subtitle1">
        「いいね！」送信・返信で
        <img className={classes.good} src={GoodImg} width={16} height={16} />
      </Typography>
      <Typography className={classes.incentive}>
        <span style={{
          width: 11,
          fontSize: 11,
          writingMode: 'vertical-rl',
          marginRight: -11,
        }}>最大</span>
        <span style={{ fontSize: 16 }}>+</span>
        10
        <span style={{ fontSize: 16 }}>名</span>
        ご紹介
      </Typography>
      <Typography className={classes.terms} variant="body1">
        {dateFormat(startAt, 'yyyy.mm.dd')}-{dateFormat(endAt, 'yyyy.mm.dd')}
      </Typography>
    </div>
  )
}

ValentineEventBox.propTypes = {
  mission: PropTypes.object,
  bgimg: PropTypes.bool,
}
