import React from 'react'
import { isNullOrUndefined } from 'util'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import WarningIcon from '@material-ui/icons/Warning'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import * as utility from 'commons/utility'
import DisableCookieScreen from 'components/DisableCookieScreen'
import ConfirmFewUsersDialog from 'components/entry/ConfirmFewUsersDialog'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import campaignLabel from 'images/campaignLabel2.png'

const styles = theme => ({
  rootEntry: {
    maxWidth: 700,
    margin: 'auto',
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(3),
  },
  subTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: '#FF0000',
    opacity: 0.54,
  },
  name: {
    marginTop: -theme.spacing(3),
  },
  leftTextField: {
    marginBottom: theme.spacing(2),
    width: '45%',
  },
  rightTextField: {
    marginBottom: theme.spacing(2),
    width: '45%',
  },
  fullTextField: {
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  couponTextField: {
    width: '100%',
    maxWidth: 412,
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
  },
  buttonBox: {
    width: 160,
    height: 48,
    textAlign: 'center',
  },
  couponButton: {
    width: 160,
    height: 48,
    fontSize: 16,
    fontWeight: 600,
  },
  codeOK: {
    fontSize: 12,
    fontWeight: 300,
    color: '#616AFA',
  },
  codeNG: {
    fontSize: 12,
    fontWeight: 300,
    color: '#F55083',
  },
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
    width: '100%',
  },
  entry: {
    ...theme.styles.entryButton,
    ...theme.styles.fontSize(16),
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  helperText: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  publicContainer: {
    position: 'relative',
  },
  itemStatus: {
    ...theme.styles.profileItemStatus,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'absolute',
    right: 0,
  },
  root: {
    width: theme.spacing(5),
    height: theme.spacing(2.5),
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: `translateX(${theme.spacing(2.5)}px)`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.secondary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
  },
  track: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  switchLabel: theme.styles.profileItemStatus,
  switchRoot: {
    margin: 0,
    position: 'absolute',
    right: 0,
  },
  back: {
    ...theme.styles.dialogOkButton,
    ...theme.styles.fontSize(12),
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
  textTitle: {
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  buttonContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  birthdayContainer: {
    marginTop: theme.spacing(1),
  },
  gridBirthday: {
    marginBottom: theme.spacing(1),
  },
  formYear: {
    paddingTop: theme.spacing(0.5),
    width: '30%',
    maxWidth: theme.spacing(15),
  },
  formMonthDay: {
    paddingTop: theme.spacing(0.5),
    width: '25%',
    maxWidth: theme.spacing(15),
  },
  selectBirthday: {
    width: '100%',
    textAlign: 'right',
  },
  labelBirthday: {
    transform: 'scale(0.75)',
    width: '133%',
  },
  inputError: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: '#FF0000',
  },
  labelImg: {
    width: '90%',
    display: 'block',
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginBottom: theme.spacing(6),
    marginLeft: 'auto',
  },
  progress: {
    color: '#616AFA',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  note: {
    fontSize: 12,
    marginTop: theme.spacing(1.5),
  },
  textPublicProfileSwitch: {
    position: 'relative',
    top: '-36px',
  }
})

export class EntrySheet extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.submitEntry = this.submitEntry.bind(this)
    this.createHeader = this.createHeader.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this)
    this.onChangeCouponCode = this.onChangeCouponCode.bind(this)
    this.onSubmitCouponCode = this.onSubmitCouponCode.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.closePendingConfirmDialog = this.closePendingConfirmDialog.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)

    this._isMounted = false

    // 現在までに成人している人の誕生日の最大値
    const today = new Date()
    const y = today.getFullYear() - 18
    const m = today.getMonth() + 1
    const d = today.getDate()
    const month= ('00' + m).slice(-2)
    const day = ('00' + d).slice(-2)

    this.confirmFewUsersDialogOpened = false

    this.state = {
      // 入力必須項目
      credit_saison_member: '',
      sex: '',
      nationality: '',
      nationality_other: '',
      birthday: `${y}-${month}-${day}`,
      last_name: '',
      first_name: '',
      last_kana_name: '',
      first_kana_name: '',
      nick_name: '',
      validateErrorText: '',
      postal_code: '',
      final_education: '',
      working_industry: '',
      working_occupation: '',
      other_working_occupation: '',
      main_working_company_name: '',
      same_organization_matching: '',
      job_transfer: '',
      work_location_flexibility: '',

      // エラー判定用
      credit_saison_member_error: false,
      sex_error: false,
      nationality_error: false,
      nationality_other_error: false,
      birthday_error: false,
      last_name_error: false,
      first_name_error: false,
      last_kana_name_error: false,
      first_kana_name_error: false,
      nick_name_error: false,
      postal_code_error: false,
      address1_error: false,
      final_education_error: false,
      working_industry_error: false,
      main_working_company_name_error: false,
      same_organization_matching_error: false,
      job_transfer_error: false,
      work_location_flexibility_error: false,

      password_error: null,
      passwordConfirm_error: null,

      couponCode: null,
      couponCode_error: null,
      checkingCouponCode: false,

      userOrganization: null,
      organizationId: null,
      companies: [],

      openConfirmFewUsersDialog: false,
      areaId: '',

      // 審査保留
      isPending: false,
      pendingReason: '',
      pendingReasonDetail: '',

      // 生年月日
      birthday_year: y,
      birthday_month: m,
      birthday_day: d,

      publics: {
        job_transfer: false,
        work_location_flexibility: false,
        public_foreign_nationality: false,
      },

      inputError: false,

      // キャンペーン
      firstIncentiveNames: []
    }
  }

  componentDidMount () {
    this._isMounted = true
    let authId = utility.getUrlParam('auth_id')
    if (authId && !this.props.authId) {
      Storage.clearToken()
      Storage.clearFriend()
      this.initByUrlAuthId(authId)
    } else if (this.props.user) {
      this.initByLoginUser()
    } else {
      this.initBySavedAuthId()
    }
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  // URL引数のauth_idで初期化 (会員登録時)
  async initByUrlAuthId (authId) {
    this.props.setLoading(true)
    try {
      const res = await Api.confirmAuth(authId)
      const orgId = res.auth.organization_id
      this.props.setAuthId(authId)
      this.props.setMailAddress(res.auth.mail_address)
      this.props.setOrganizationId(orgId)
      const { companies } = await this.loadOrganizationAndCompanies(orgId, authId)
      if (res.auth.sex) {
        this.setStateIfMounted({ sex: res.auth.sex })
      }
      const companyName = res.auth.main_working_company_name
      if (companyName) {
        // DEV-1755 parent ではない会社名をユーザーに選択させたい
        const parentCompany = companies.find(company => company.parent)
        const parentSelected = parentCompany && (parentCompany.name === companyName)
        this.setStateIfMounted({
          main_working_company_name: (parentSelected ? '' : companyName),
        })
      }
      if (Array.isArray(res.auth.first_incentive_names)) {
        this.setStateIfMounted({ firstIncentiveNames: res.auth.first_incentive_names })
      }
      if (res.auth.area_id) {
        this.setStateIfMounted({ areaId: res.auth.area_id })
      }
      await this.loadMasters(authId)

      this.initProfileInputs(res.user)
      // 入会時の入力を途中から再開する場合
      if (res.user) {
        this.props.setUser(res.user)
        this.setPendingReason(res.user)
      } else {
        this.initCouponCodeInput()
      }
    } catch (error) {
      this.handleApiError(error)
      this.props.setScreen('/')
    } finally {
      this.props.setLoading(false)
    }
  }

  // 保存済みauth_idで初期化 (会員登録時)
  async initBySavedAuthId () {
    this.props.setLoading(true)
    try {
      const orgId = this.props.organizationId
      const authId = this.props.authId
      await this.loadOrganizationAndCompanies(orgId, authId)
      this.initCouponCodeInput()
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  // ログイン済みユーザー情報で初期化
  async initByLoginUser () {
    this.props.setLoading(true)
    this.initProfileInputs()
    try {
      const orgId = this.props.user.organization_id
      await this.loadOrganizationAndCompanies(orgId)
      this.setPendingReason(this.props.user)
      if (this.isEnteredEntrySheet(this.props.user)) {
        this.getProfilePublicSetting(this.props.user.id)
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  loadOrganizationAndCompanies = async (orgId, authId = null) => {
    const userOrganization = await Api.getUserOrganization(orgId, authId)
    this.props.setMyOrganization(userOrganization)
    const companies = await Api.getCompanies(userOrganization.id)
    const stateToUpdate = {
      userOrganization,
      organizationId: orgId,
      // parent=trueを下に
      companies: companies.sort((a, b) => a.parent - b.parent),
    }
    this.setStateIfMounted(stateToUpdate)
    return stateToUpdate
  }

  async initProfileInputs (user = this.props.user) {
    const storageValue = Storage.default.editingUserProfile.value
    if (!user && !storageValue) { return }
    await this.setStateIfMounted({
      // 入力必須項目
      credit_saison_member: user?.credit_saison_member || '',
      sex: user?.sex || '',
      nationality: user?.nationality || '',
      nationality_other: user?.nationality || storageValue?.nationality || '',
      // last_name: utility.decodeSanitaize(user.last_name) || '',
      // first_name: utility.decodeSanitaize(user.first_name) || '',
      // last_kana_name: utility.decodeSanitaize(user.last_kana_name) || '',
      // first_kana_name: utility.decodeSanitaize(user.first_kana_name) || '',
      // nick_name: user.nick_name || '',
      // postal_code: utility.deleteHyphenToPostcode(user.postal_code) || '',
      final_education: utility.decodeSanitaize(user?.final_education) || '',
      working_industry: user?.working_industry || '',
      working_occupation: user?.working_occupation || '',
      main_working_company_name: user?.main_working_company_name || '',
      same_organization_matching: user?.same_organization_matching || '',
      job_transfer: user?.job_transfer || '',
      work_location_flexibility: user?.work_location_flexibility || '',
    })
    BaseComponent.setInputValue('last_name', utility.decodeSanitaize(user?.last_name) || utility.decodeSanitaize(storageValue?.last_name) || '')
    BaseComponent.setInputValue('first_name', utility.decodeSanitaize(user?.first_name) || utility.decodeSanitaize(storageValue?.first_name) || '')
    BaseComponent.setInputValue('last_kana_name', utility.decodeSanitaize(user?.last_kana_name) || utility.decodeSanitaize(storageValue?.last_kana_name) || '')
    BaseComponent.setInputValue('first_kana_name', utility.decodeSanitaize(user?.first_kana_name) || utility.decodeSanitaize(storageValue?.first_kana_name) || '')
    BaseComponent.setInputValue('nick_name', user?.nick_name || storageValue?.nick_name || '')
    BaseComponent.setInputValue('postal_code', utility.deleteHyphenToPostcode(user?.postal_code) || utility.decodeSanitaize(storageValue?.postal_code) || '')
    BaseComponent.setInputValue('other_working_occupation', user?.other_working_occupation || storageValue?.other_working_occupation || '')
    this.initCouponCodeInput()
    // 生年月日
    const birthday = user?.birthday || this.getMaxBirthday()
    const date = new Date(birthday)
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()
    this.setStateIfMounted({
      birthday: birthday,
      birthday_year: y,
      birthday_month: m,
      birthday_day: d,
    })
  }

  initCouponCodeInput () {
    const savedVal = this.findSavedCouponCode()
    if (savedVal) {
      BaseComponent.setInputValue('code', savedVal)
    }
  }

  findSavedCouponCode () {
    const { user } = this.props
    if (user && user.invitation_campaign_code) {
      return utility.decodeSanitaize(user.invitation_campaign_code)
    }
    return null
  }

  async handleBlur (event) {
    if (event.target.name === 'postal_code') {
      this.handleBlurPostalCode(event)
    }
  }

  useState (editKey) {
    return ![
      'last_name',
      'first_name',
      'last_kana_name',
      'first_kana_name',
      'nick_name',
      'postal_code',
      'other_working_occupation'
    ].includes(editKey)
  }

  async handleChange (event) {
    const value = event.target.value
    const name = event.target.name

    const storageValue = Storage.default.editingUserProfile.value
    Storage.default.editingUserProfile.value = Object.assign(
      (storageValue || this.props.user) || {}, { [name]: value }
    )

    if (name === 'main_working_company_name') {
      if (this.state.main_working_company_name !== value) {
        this.setState({
          main_working_company_name: value,
          main_working_company_name_error: false,
        })
      }
    } else if (!this.useState(name)) {
      BaseComponent.setInputValue(name, value)
      if (name === 'nick_name') {
        const text1 = '使用できない文字が含まれています。'
        const text2 = 'ひらがな・カタカナ・漢字・英字のいずれかを1文字以上含めてください。'
        this.setState({
          nick_name_error: !utility.isUsableCharacters(value) || !utility.validateStringCode(value),
          validateErrorText: !utility.isUsableCharacters(value) ? text1 : !utility.validateStringCode(value) ? text2 : ''
        })
      } else {
        if (this.state[name + '_error']) { this.setState({ [name + '_error']: false }) }
      }
    } else {
      this.setState({
        [name]: value,
        [name + '_error']: false,
        postal_code_error: false,
        address1_error: false,
      })
    }
    if (this.state.inputError) {
      this.setStateIfMounted({ inputError: false })
    }
  }

  handleBlurPostalCode (event) {
    let value = event.target.value
    if (!utility.isPostcode(value)) {
      this.setState({
        postal_code_error: true,
        address1_error: false,
      })
    }
  }

  async searchAddress (postal_code) {
    try {
      const authId = utility.getUrlParam('auth_id') || this.props.authId
      const data = await Api.searchAddress(postal_code, authId)
      if (data) {
        this.setStateIfMounted({
          postal_code_error: false,
          address1_error: false,
        })
        const { address1, address2, address3, prefcode, zipcode } = data
        return { address1, address2, address3, prefcode, 'postal_code': zipcode }
      } else {
        this.setStateIfMounted({
          postal_code_error: false,
          address1_error: true,
        })
        return false
      }
    } catch (error) {
      return false
    }
  }

  // 必須項目名
  getRequiredFieldNames () {
    let allNames = [
      'first_name',
      'first_kana_name',
      'last_name',
      'last_kana_name',
      'nationality',
      'sex',
      'birthday',
      'postal_code',
      'nick_name',
      'final_education',
      'working_industry',
      'working_occupation',
      'main_working_company_name',
      'job_transfer',
      'work_location_flexibility',
    ]
    if (this.state.companies.length > 1) {
      allNames.push('same_organization_matching')
    }
    if (this.state.working_occupation === 'others') {
      allNames.push('other_working_occupation')
    }
    return allNames
  }

  getIgnoredKeys () {
    let ignoredKeys = [
      'areaId',
      'userOrganization',
      'organizationId',
      'companies',
      'openConfirmFewUsersDialog',
      'nationality_other',
      'isPending',
      'pendingReason',
      'birthday_year',
      'birthday_month',
      'birthday_day',
      'inputError',
      'publics',
      'firstIncentiveNames',
      'couponCode',
      'couponCode_error',
      'checkingCouponCode',
    ]
    Array.prototype.push.apply(ignoredKeys, utility.getProfileIgnoredKeys())
    return ignoredKeys
  }

  async submitEntry (event) {
    event.preventDefault()

    // DEV-1262 グループ内マッチング非表示の場合
    if (!this.state.same_organization_matching) {
      await this.setStateIfMounted({ same_organization_matching: 'no' })
    }

    if (!this.validateProfileInput()) {
      this.setStateIfMounted({ inputError: true })
      return
    }

    this.props.setLoading(true)
    try {
      const addressData = await this.searchAddress(BaseComponent.getInputValue('postal_code'))
      if (!addressData) {
        this.setStateIfMounted({ inputError: true })
        return
      }

      this.resetPasswordError()
      if (!this.validatePasswordInput()) {
        this.setStateIfMounted({ inputError: true })
        return
      }
      this.setStateIfMounted({ inputError: false })

      await this.onSubmitCouponCode()
      if (this.state.couponCode_error) { return }

      const couponData = { invitation_campaign_code: this.getInputCouponCode() }

      const params = { ...this.getInputProfiles(), ...addressData, ...couponData }

      await this.updatePassword()
      const user = await this.updateUser(params)
      const publics = Object.keys(this.state.publics).reduce((o, key) => {
        const v = this.state.publics[key]
        if (key == 'public_foreign_nationality') {
          o['mask_foreign_nationality'] = !v
        } else {
          o[key] = v
        }
        return o
      }, {})
      await Api.updateProfilePublicSetting(user.id, publics)
      await this.loadIdTexts(this.state.userOrganization)
      this.props.setLoading(false)
      this.toEntryNextStep(user)
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  validateProfileInput () {
    const requriedKeys = this.getRequiredFieldNames()
    const ignoredKeys = this.getIgnoredKeys()
    let goPost = true
    Object.keys(this.state).forEach(key => {
      if (key.indexOf('_error') !== -1 || ignoredKeys.includes(key)) {
        return
      }
      const val = this.useState(key) ? this.state[key] : BaseComponent.getInputValue(key)
      if (requriedKeys.includes(key)) {
        if (utility.isEmpty(val)) {
          this.setState({[key + '_error']: true})
          goPost = false
        } else {
          if (key === 'nick_name') {
            const validNickName = (
              val.length <= 20
              && utility.isUsableCharacters(val)
              && utility.validateStringCode(val)
            )
            if (!validNickName) {
              this.setState({[key + '_error']: true})
              goPost = false
            }
          } else if (key === 'postal_code' && !utility.isPostcode(BaseComponent.getInputValue('postal_code'))) {
            this.setState({ postal_code_error: true, address1_error: false })
            goPost = false
          } else if (key === 'birthday' && this.state[key + '_error']) {
            goPost = false
          } else if (key === 'birthday' && val > this.getMaxBirthday()) {
            this.setState({[key + '_error']: true})
            goPost = false
          } else if ((key === 'last_kana_name' || key === 'first_kana_name') && !utility.isHiragana(val)) {
            this.setState({[key + '_error']: true})
            goPost = false
          } else if (key === 'nationality' && this.state.nationality !== '日本' && !this.state.nationality_other) {
            this.setState({ nationality_other_error: true })
            goPost = false
          } else if (key === 'other_working_occupation' && val.length > 20) {
            this.setState({ other_working_occupation_error: true })
            goPost = false
          }
        }
      }
    })
    return goPost
  }

  validatePasswordInput () {
    let password = this.getInputPassword()
    let passwordConf = this.getInputPasswordConf()

    // パスワードのチェック
    if (utility.isEmpty(password)) {
      this.setState({ password_error: 'パスワードを入力してください。' })
      return false
    }
    if (!utility.isUsableCharacters(password)) {
      this.setState({ password_error: '使用できない文字が含まれています。' })
      return false
    }
    if (password.length < 8) {
      this.setState({ password_error: '最小文字長は8文字です。' })
      return false
    }
    if (password.length > 32) {
      this.setState({ password_error: '最大文字長は32文字です。' })
      return false
    }

    // パスワード（確認用）のチェック
    if (utility.isEmpty(passwordConf)) {
      this.setState({ passwordConfirm_error: 'パスワード（確認）を入力してください。' })
      return false
    }
    if (!utility.isUsableCharacters(passwordConf)) {
      this.setState({ passwordConfirm_error: '使用できない文字が含まれています。' })
      return false
    }
    if (passwordConf.length < 8) {
      this.setState({ passwordConfirm_error: '最小文字長は8文字です。' })
      return false
    }
    if (passwordConf.length > 32) {
      this.setState({ passwordConfirm_error: '最大文字長は32文字です。' })
      return false
    }
    if (password !== passwordConf) {
      this.setState({ passwordConfirm_error: 'パスワードが一致していません。' })
      return false
    }

    return true
  }

  getInputProfiles () {
    const ignoredKeys = this.getIgnoredKeys()
    const params = {}
    Object.keys(this.state).forEach(key => {
      if (key.indexOf('_error') !== -1 || ignoredKeys.includes(key)) { return }

      const val = this.useState(key) ? this.state[key] : BaseComponent.getInputValue(key)
      switch (key) {
        case 'postal_code':
          break
        case 'nationality': {
          const nationality = this.state.nationality === '日本' ? this.state.nationality : this.state.nationality_other
          params[key] = utility.encodeSanitaize(nationality)
          break
        }
        case 'working_occupation': {
          if (this.state.working_occupation === 'others') {
            params['other_working_occupation'] = val
          }
          params[key] = utility.encodeSanitaize(val)
          break
        }
        default:
          params[key] = utility.encodeSanitaize(val)
      }
    })
    return params
  }

  getInputPassword () {
    return document.getElementById('password').value
  }

  getInputPasswordConf () {
    return document.getElementById('passwordConf').value
  }

  getInputCouponCode () {
    return document.getElementById('code')?.value
  }

  getInputHelperText () {
    const { classes } = this.props
    if (this.state.checkingCouponCode) { return '' }
    if (this.state.couponCode) {
      return (
        <Grid className={classes.codeOK} container alignItems="center">
          <CheckCircleIcon style={{ width: 20, height: 20 }} />
          &nbsp;&nbsp;利用できます
        </Grid>
      )
    }
    if (this.state.couponCode_error) {
      return (
        <Grid className={classes.codeNG} container alignItems="center">
          <WarningIcon style={{ width: 20, height: 20 }} />
          &nbsp;&nbsp;{this.state.couponCode_error}
        </Grid>
      )
    }
    return ' '
  }

  onChangeCouponCode () {
    this.setState({ couponCode_error: false })
  }

  async onSubmitCouponCode () {
    try {
      const value = this.getInputCouponCode()
      if (!value) { return }
      await this.setState({
        checkingCouponCode: true,
        couponCode: null,
        couponCode_error: false,
      })
      await Api.getCampaignMaster({ campaign_code: value })
      this.setState({ couponCode: value })
    } catch (error) {
      this.setState({ couponCode: '' })
      const errorMessage = await utility.fetchResponseErrorMessage(error)
      if (errorMessage) {
        this.setState({ couponCode_error: errorMessage })
      }
    } finally {
      this.setState({ checkingCouponCode: false })
    }
  }

  async updatePassword () {
    let password = this.getInputPassword()
    if (this.props.password) {
      let auth = await Api.updatePassword(this.props.authId, password)
      this.props.setAuthId(auth.id)
      this.props.setPassword(password)
      this.props.setMailAddress(auth.mail_address)
    } else {
      let auth = await Api.updatePassword(this.props.authId, password, false)
      this.props.setAuthId(auth.id)
      await this.login(this.props.mailAddress, password, 'user', 'entry')
      await this.loadUser()
      this.props.setIsLogin(true)
    }
  }

  async getProfilePublicSetting (user_id) {
    this.props.setLoading(true)
    try {
      let setting = await Api.getProfilePublicSetting(user_id)
      if (setting && setting['mask_foreign_nationality'] !== undefined) {
        setting['public_foreign_nationality'] = !setting['mask_foreign_nationality']
        delete setting['mask_foreign_nationality']
      }
      this.setStateIfMounted({ publics: setting || {} })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async handlePublicChange (event) {
    let key = event.target.value
    let publics = this.state.publics
    publics[key] = event.target.checked
    this.setStateIfMounted({ publics: publics })
  }

  isEnteredEntrySheet (user) {
    if (utility.isEmpty(user.nick_name)) {
      return false
    }
    return true
  }

  createHeader () {
    const { classes, idText, ...other } = this.props

    return(
      <div>
        <AppHeader title="基本情報" hideBack={true} {...other} />
        <EntrySteps step={0} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
        <Grid container direction='column' alignItems="center" justifyContent="center" className={classes.rootEntry}>
          <Typography className={classes.subTitle}>
            本登録をお願いいたします。サービス利用には必須項目の入力を完了いただく必要があります。<br />
            ※あらかじめ登録するプロフィール写真と{idText?.label}（表）の写真をご用意ください。<br />
            ※登録内容に整合性が取れない場合や利用規約に反する場合は、入会ができない、ないしはアカウント停止となります。ご注意ください。
          </Typography>
        </Grid>
      </div>
    )
  }

  onChangePassword () {
    this.setState({ password_error: null })
  }

  onChangePasswordConfirm () {
    this.setState({ passwordConfirm_error: null })
  }

  resetPasswordError () {
    this.setState({ password_error: null })
    this.setState({ passwordConfirm_error: null })
  }

  setPendingReason (user) {
    const { identification_status, pending_reasons } = user
    if (identification_status === 'pending' && !Storage.getShowPendingDialog()) {
      const reasonNames = []
      if (pending_reasons.includes('profile')) {
        reasonNames.push('プロフィール')
      }
      if (pending_reasons.includes('photo')){
        reasonNames.push('本人写真')
      }
      if (pending_reasons.includes('identification')) {
        reasonNames.push('本人確認書類')
      }
      if (pending_reasons.includes('affiliation')) {
        reasonNames.push('所属確認書類')
      }
      if (reasonNames.length === 0) {
        reasonNames.push('プロフィール')
      }
      const reason = reasonNames.join('と')
      this.setState({
        isPending: true,
        pendingReason: reason,
        pendingReasonDetail: isNullOrUndefined(user.pending_reason_detail) ? '' : user.pending_reason_detail,
      })
    }
  }

  closePendingConfirmDialog () {
    Storage.setShowPendingDialog(true)
    this.setState({
      isPending: false,
      pendingReason: '',
      pendingReasonDetail: '',
    })
  }

  shouldOpenConfirmFewUsersDialog (birthday) {
    const { areaId, sex } = this.state
    const today = new Date()
    const m = ('00' + (today.getMonth() + 1)).slice(-2)
    const d = ('00' + today.getDate()).slice(-2)
    const areaGenderAgeSettings = {
      tohoku : {
        male: 20,
        female: 37,
      },
      kyusyu : {
        male: 39,
        female: 41,
      },
      kanto : {
        male: 45,
        female: 48,
      },
      kansai : {
        male: 41,
        female: 46,
      },
      others : {
        male: 20,
        female: 20,
      },
    }
    const areaSettings = areaGenderAgeSettings[areaId] || {
      male: 45,
      female: 40,
    }
    const y = today.getFullYear() - areaSettings[sex]
    return birthday <= `${y}-${m}-${d}`
  }

  getMaxBirthday () {
    // 現在までに成人している人の誕生日の最大値
    const today = new Date()
    const y = today.getFullYear() - 18
    const m = ('00' + (today.getMonth() + 1)).slice(-2)
    const d = ('00' + today.getDate()).slice(-2)
    const maxBirthday = `${y}-${m}-${d}`
    return maxBirthday
  }

  createBirthdayField () {
    const { classes } = this.props

    // 現在の年月日を取得
    const time = new Date()
    const year = time.getFullYear() - 18
    const month = time.getMonth() + 1
    const day = time.getDate()

    const valueYear = []
    for (var i = year; i >= 1900 ; i--) {
      valueYear.push(i)
    }
    const valueMonth = [...Array(12).keys()].map(i => ++i)
    const valueDay = [...Array(31).keys()].map(i => ++i)
    return (
      <div className={classes.birthdayContainer}>
        <InputLabel className={classes.labelBirthday} error={this.state.birthday_error} >
          生年月日（年齢でプロフィール公開となります）
        </InputLabel>
        <Grid container direction='row' justifyContent="flex-start" alignItems="center" className={classes.gridBirthday}>
          <FormControl className={classes.formYear}>
            <Select
              value={this.state.birthday_year || year}
              name={'birthday_year'}
              onChange={this.handleDateChange}
              className={classes.selectBirthday}
              error={this.state.birthday_error}
            >
              { this.createMenuItems(valueYear) }
            </Select>
          </FormControl>
          年&nbsp;
          <FormControl className={classes.formMonthDay}>
            <Select
              value={this.state.birthday_month || month}
              name={'birthday_month'}
              onChange={this.handleDateChange}
              className={classes.selectBirthday}
              error={this.state.birthday_error}
            >
              { this.createMenuItems(valueMonth) }
            </Select>
          </FormControl>
          月&nbsp;
          <FormControl className={classes.formMonthDay}>
            <Select
              value={this.state.birthday_day || day}
              name={'birthday_day'}
              onChange={this.handleDateChange}
              className={classes.selectBirthday}
              error={this.state.birthday_error}
            >
              { this.createMenuItems(valueDay) }
            </Select>
          </FormControl>
          日
        </Grid>
        <InputLabel className={classes.labelBirthday} error={this.state.birthday_error} >
          未成年の方は利用できません。
        </InputLabel>
      </div>
    )
  }

  createMenuItems (values) {
    return (
      values.map(value => {
        return (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        )
      })
    )
  }

  handleDateChange (event) {
    let error = false
    let openConfirmFewUsersDialog = false
    let year = event.target.name === 'birthday_year' ? event.target.value : this.state.birthday_year
    let month = event.target.name === 'birthday_month' ? event.target.value : this.state.birthday_month
    let day = event.target.name === 'birthday_day' ? event.target.value : this.state.birthday_day
    if (!utility.isDate(year, month, day)) {
      error = true
    }
    month= ('00' + month).slice(-2)
    day = ('00' + day).slice(-2)
    const birthday = `${year}-${month}-${day}`
    const maxBirthday = this.getMaxBirthday()
    if (birthday > maxBirthday) {
      error = true
    }
    if (!this.confirmFewUsersDialogOpened && this.shouldOpenConfirmFewUsersDialog(birthday)) {
      openConfirmFewUsersDialog = true
      this.confirmFewUsersDialogOpened = true
    }
    this.setState({
      birthday: birthday,
      birthday_error: error,
      openConfirmFewUsersDialog,
      [event.target.name]: event.target.value,
    })
  }

  render () {
    if (!Storage.default.enabled) {
      return <DisableCookieScreen />
    }

    if (!this.props.authId) return null
    if (!this.props.master) return null
    const { classes } = this.props
    const { companies, userOrganization, checkingCouponCode } = this.state
    const profileMaster = this.props.master.profile
    const sameOrgMatchingEnabled = (
      userOrganization && !userOrganization.same_organization_matching_disabled
    )

    return (
      <div className={classes.rootEntry}>
        <ConfirmFewUsersDialog
          open={this.state.openConfirmFewUsersDialog}
          onClose={() => this.setState({ openConfirmFewUsersDialog: false })}
        />
        <ConfirmDialog
          open={this.state.isPending}
          onClose={this.closePendingConfirmDialog}
          onOk={this.closePendingConfirmDialog}
          onCancel={this.closePendingConfirmDialog}
          confirm="yes"
          title="入会審査保留"
          message={
            `この度はAillの入会登録、ありがとうございます。\nご登録いただいた${this.state.pendingReason}を確認し、再度登録をお願いいたします。`
            + `\n${this.state.pendingReasonDetail}`
          }
        />
        {this.createHeader()}
        {this.state.firstIncentiveNames.includes('standard_plan_1m') && (
          <img className={classes.labelImg} src={campaignLabel} alt="" />
        )}
        <form
          noValidate
          autoComplete="off"
          ref={() => 'entrySheet'}
          onSubmit={this.submitEntry}
        >
          <Card className={classes.card}>
            <Typography className={classes.textTitle}>非公開情報</Typography>
            <Grid container direction='row' justifyContent="space-between">
              {/* 苗字 */}
              {this.createUserProfileTextField('last_name', {
                className: classes.leftTextField,
                noPublicSwitch: true,
              })}
              {/* 名前 */}
              {this.createUserProfileTextField('first_name', {
                className: classes.rightTextField,
                noPublicSwitch: true,
              })}
            </Grid>
            <Grid container className={classes.name} direction='row' justifyContent="space-between">
              {/* 苗字（かな） */}
              {this.createUserProfileTextField('last_kana_name', {
                className: classes.leftTextField,
                noPublicSwitch: true,
              })}
              {/* 名前（かな） */}
              {this.createUserProfileTextField('first_kana_name', {
                className: classes.rightTextField,
                noPublicSwitch: true,
              })}
            </Grid>
          </Card>
          <Card className={classes.card}>
            <Typography className={classes.textTitle}>公開情報</Typography>
            {/* ニックネーム */}
            {this.createUserProfileTextField('nick_name', { noPublicSwitch: true })}
            {/* 生年月日 */}
            {this.createBirthdayField()}
            {/* 性別 */}
            {this.createUserProfileSelect('sex', { noPublicSwitch: true })}
            {/* 国籍 */}
            <FormControl className={classes.formControl} key='nationality'>
              <InputLabel error={this.state.nationality_error}>
                国籍
              </InputLabel>
              <Select
                error={this.state.nationality_error}
                value={!this.state.nationality ? '' : this.state.nationality === '日本' ? '日本' : 'その他'}
                onChange={this.handleChange}
                name='nationality'
                IconComponent={() => (
                  <KeyboardArrowDown />
                )}
              >
                <MenuItem value=""><em>未選択</em></MenuItem>
                <MenuItem value="日本">日本</MenuItem>
                <MenuItem value="その他">その他</MenuItem>
              </Select>
            </FormControl>
            {/* 国籍（その他） */}
            {this.state.nationality && this.state.nationality !== '日本' && (
              <>
                <TextField
                  className={classes.fullTextField}
                  margin="normal"
                  type={'text'}
                  helperText={'国籍を入力してください'}
                  key={'nationality_other'}
                  name={'nationality_other'}
                  value={this.state.nationality_other}
                  error={this.state.nationality_other_error}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
                {!utility.nationalityIsJapan(this.state.nationality_other) && (
                  <div className={classes.textPublicProfileSwitch}>
                    {this.createPublicProfileSwitch('public_foreign_nationality')}
                  </div>
                )}
              </>
            )}
            {/* 郵便番号 */}
            <FormControl className={classes.formControl} key='postal_code'>
              <TextField
                className={classes.fullTextField}
                margin="normal"
                type="text"
                name="postal_code"
                label={profileMaster.postal_code.title}
                helperText={
                  this.state.address1_error ? '郵便番号の都道府県が存在しません' : profileMaster.postal_code.description
                }
                error={this.state.postal_code_error || this.state.address1_error}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                autoComplete='no'
                style={{ paddingTop: 15 }}
              />
            </FormControl>

            {/* あなたのお勤め先の業種 */}
            {this.createUserProfileSelect('working_industry', { noPublicSwitch: true })}
            {/* あなたのお勤め先での職種 */}
            {this.createUserProfileSelect('working_occupation', { noPublicSwitch: true })}
            {/* 職種 自由入力 */}
            {this.state.working_occupation === 'others' && (
              <TextField
                className={classes.fullTextField}
                label="職種 自由入力"
                helperText={
                  this.state.other_working_occupation_error ? BaseComponent.getInputValue('other_working_occupation') ? '最大20文字まで' : '１文字以上入力してください' : ''
                }
                margin="normal"
                type="text"
                name="other_working_occupation"
                error={this.state.other_working_occupation_error}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                autoComplete='no'
              />
            )}
            {/* 転勤の範囲 */}
            {this.createUserProfileSelect('job_transfer')}
            {/* 勤務地の柔軟性 */}
            {this.createUserProfileSelect('work_location_flexibility')}
          </Card>
          <Card className={classes.card}>
            {[
              <Typography key="no_public" className={classes.textTitle}>非公開情報</Typography>,
              /* 勤め先（本籍）会社名 */
              this.createUserOrganizationSelect('main_working_company_name'),
              /* グループ会社内の人とのマッチング */
              (companies.length > 1 && sameOrgMatchingEnabled) && (
                this.createUserProfileSelect('same_organization_matching', { noPublicSwitch: true })
              )
            ]}
          </Card>
          <Typography className={classes.helperText}>下記の情報はログイン時に使用するパスワードです。</Typography>
          <Card className={classes.card}>
            <Typography className={classes.textTitle}>マイページログインパスワード</Typography>
            <Typography className={classes.note}>
              8文字以上、32文字以下で新しいパスワードを入力してください。<br />
              スペース、特殊文字（/、&yen;、&amp;、&quot;、&#39;、&lt;、&gt;）は使用できません。
            </Typography>
            <TextField
              id="password"
              type="password"
              className={classes.fullTextField}
              error={!utility.isEmpty(this.state.password_error)}
              label={'パスワード'}
              helperText={utility.isEmpty(this.state.password_error) ? '' : this.state.password_error}
              onChange={this.onChangePassword}
            />
            <TextField
              id="passwordConf"
              type="password"
              className={classes.fullTextField}
              error={!utility.isEmpty(this.state.passwordConfirm_error)}
              label={'パスワード（確認）'}
              helperText={utility.isEmpty(this.state.passwordConfirm_error) ? '' : this.state.passwordConfirm_error}
              onChange={this.onChangePasswordConfirm}
            />
          </Card>
          {userOrganization && !userOrganization.full_paid_enabled && (
            <Card className={classes.card}>
              <Typography className={classes.textTitle}>紹介コード入力</Typography>
              <Grid container justifyContent="space-between" alignItems="center">
                <TextField
                  id="code"
                  className={classes.couponTextField}
                  error={this.state.couponCode_error}
                  label={'紹介コード'}
                  helperText={this.getInputHelperText()}
                  onChange={this.onChangeCouponCode}
                  InputProps={{ style: { borderBottom: this.state.couponCode && '2px solid #616AFA' } }}
                  name="code"
                />
                <div className={classes.buttonBox}>
                  <Button
                    className={classes.couponButton}
                    variant="contained"
                    color="secondary"
                    onClick={this.onSubmitCouponCode}
                    disabled={!this.getInputCouponCode() || checkingCouponCode}
                  >
                    クーポンを適用
                    { checkingCouponCode && <CircularProgress className={classes.progress} size={30} /> }
                  </Button>
                </div>
              </Grid>
            </Card>
          )}
          <Grid container direction='column' alignItems="center" className={classes.buttonContainer}>
            {this.state.inputError && (
              <Typography className={classes.inputError}>
                エラーの項目があります。<br/>赤字の箇所をご確認ください。
              </Typography>
            )}
            <Button variant="contained" color="secondary" className={classes.entry} type="submit">
              送信
            </Button>
          </Grid>
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(EntrySheet)
