import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { entryFooterHight } from 'commons/theme'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${entryFooterHight}px)`,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  titleBar: {
    height: theme.spacing(0.625),
    width: theme.spacing(20),
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
  },
  container: {
    width: '50%',
    minWidth: theme.spacing(35),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginTop: 0,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(10),
    border: '1px solid',
  },
  message: {
    color: '#878787',
    fontSize: 12.5,
    marginTop: 0,
    lineHeight: 1.75,
  },
  messageContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
})

export class MembersPassword extends BaseComponent {
  constructor (props) {
    super(props)

    this.submitPassword = this.submitPassword.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this)

    this._isMounted = false

    this.state = {
      isError: false,
      passwordError: null,
      passwordConfirmError: null,
    }
  }

  componentWillMount () {
    if (this.props.authId == null) {
      this.props.setScreen('/')
    }
    if (isEmpty(this.props.mailAddress)) {
      this.props.setScreen('/')
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onChangePassword () {
    this.setState({ passwordError: null })
  }

  onChangePasswordConfirm () {
    this.setState({ passwordConfirmError: null })
  }

  dialogClose () {
    this.setState({ isError: false })
  }

  resetError () {
    this.setState({ passwordError: null })
    this.setState({ passwordConfirmError: null })
  }

  async submitPassword () {
    this.resetError()

    // 入力のバリデーション
    let inputError = false

    let password = document.getElementById('password')
    let passwordConf = document.getElementById('passwordConf')

    // パスワードのチェック
    if (isEmpty(password.value)) {
      this.setState({ passwordError: 'パスワードを入力してください。' })
      inputError = true
    } else if (!isUsableCharacters(password.value)) {
      this.setState({ passwordError: '使用できない文字が含まれています。' })
      inputError = true
    } else if (password.value.length < 8) {
      this.setState({ passwordError: '最小文字長は８文字です。' })
      inputError = true
    } else if (password.value.length > 32) {
      this.setState({ passwordError: '最大文字長は３２文字です。' })
      inputError = true
    }

    // パスワード（確認用）のチェック
    if (isEmpty(passwordConf.value)) {
      this.setState({ passwordConfirmError: 'パスワード（確認）を入力してください。' })
      inputError = true
    } else if (!isUsableCharacters(passwordConf.value)) {
      this.setState({ passwordConfirmError: '使用できない文字が含まれています。' })
      inputError = true
    } else if (passwordConf.value.length < 8) {
      this.setState({ passwordConfirmError: '最小文字長は８文字です。' })
      inputError = true
    } else if (passwordConf.value.length > 32) {
      this.setState({ passwordConfirmError: '最大文字長は３２文字です。' })
      inputError = true
    }

    if (inputError) { return }

    // パスワード確認
    if (password.value !== passwordConf.value) {
      this.setState({ passwordConfirmError: 'パスワードが一致していません。' })
      return
    }

    // ローディング表示
    this.props.setLoading(true)

    // パスワード設定
    try {
      let auth = await Api.updatePassword(this.props.authId, password.value, false)
      this.props.setAuthId(auth.id)
      await this.login(this.props.mailAddress, password.value)
      await this.loadMasters()
      await this.loadUser()
      await BaseComponent.loadSubscription(this.props)

      this.props.setIsLogin(true)
      this.props.setScreen('/Home')
    } catch (error) {
      this.setStateIfMounted({ isError: true })
      this.handleApiError(error)
      this.clearSession()
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    const { classes } = this.props
    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes"
          title="パスワード登録"
          message="パスワード登録できませんでした。"
        />

        <div className={classes.root}>
          <div className={classes.body}>
            <EntryHeader/>
            <Grid container direction='column' alignItems="center">
              <Typography className={classes.title}>
                PASSCODE
              </Typography>
              <div className={classes.titleBar}/>
              <Grid item className={classes.container}>
                <Typography className={classes.subTitle}>
                  パスワード再発行
                </Typography>
                <Typography className={classes.label}>
                  ログインパスワード
                </Typography>
                <TextField
                  id="password"
                  type="password"
                  margin="normal"
                  variant="outlined"
                  error={!isEmpty(this.state.passwordError)}
                  placeholder="パスワード"
                  helperText={isEmpty(this.state.passwordError) ? '' : this.state.passwordError}
                  className={classes.textField}
                  color="primary"
                  onChange={this.onChangePasscode} />
                <Typography className={classes.label}>
                  ログインパスワード（確認）
                </Typography>
                <TextField
                  id="passwordConf"
                  type="password"
                  margin="normal"
                  variant="outlined"
                  error={!isEmpty(this.state.passwordConfirmError)}
                  placeholder="パスワード"
                  helperText={isEmpty(this.state.passwordConfirmError) ? '' : this.state.passwordConfirmError}
                  className={classes.textField}
                  color="primary"
                  onChange={this.onChangePasswordConfirm} />
              </Grid>
              <Button
                variant="outlined"
                color="primary"
                className={classes.entry}
                onClick={this.submitPassword}
              >
                送信
              </Button>
              <Grid item className={classes.messageContainer}>
                <Typography className={classes.message}>
                  ログイン時に使用するパスワードを入力してください。
                </Typography>
              </Grid>
            </Grid>
          </div>
          <EntryFooter/>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MembersPassword)
