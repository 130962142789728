import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Api from 'commons/api'
import Storage from 'commons/storage'
import { addDate } from 'commons/utility'
import TheaterForm from 'components/movie/TheaterForm'
import TheaterTitle from 'components/movie/TheaterTitle'
import BaseComponent from 'components/parts/BaseComponent'
import ShortCurtain from 'images/movie/theater_curtain_short.png'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#1A1667',
    textAlign: 'center',
    paddingTop: theme.spacing(13),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(13),
    paddingLeft: theme.spacing(2),
    backgroundImage: `url(${ShortCurtain})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPositionX: 'center',
    backgroundPositionY: 'top',
  },
  backButton: {
    color: '#ffffff',
    position: 'fixed',
  },
  movieTitle: {
    fontSize: 22,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(2),
  },
  eventTerm: {
    fontSize: 20,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(8),
  },
  stepNum: {
    fontSize: 35,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(2.5),
  },
  stepText: {
    fontSize: 22,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(2.5),
    whiteSpace: 'pre-wrap',
  },
  videoLink: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
  playButton: {
    width: 64,
    height: 64,
    color: '#ffffff',
    background: 'radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  playIcon: {
    fontSize: 30,
  },
}))

export default function Theater (props) {
  const { match } = props
  const classes = useStyles()
  const movieId = match.params?.id
  const [movieInfo, setMovieInfo] = useState(null)
  const [watched, setWatched] = useState(false)
  const [answered, setAnswered] = useState(false)

  useEffect(() => {
    (async () => {
      window.scrollTo({ top: 0 })

      if (!movieId) {
        props.setScreen('/Home')
        return
      }

      BaseComponent.addVisitPageLog()
      props.setLoading(true)
      try {
        const movieEvent = await Api.getMovieInfo(movieId)
        setMovieInfo(movieEvent)

        const movieSurveys = await Api.getMovieSurveys(movieId)
        setWatched(movieSurveys.watched)
        setAnswered(movieSurveys.answered)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const onClick = () => {
    if (!movieId || !videoUrl) { return }
    Storage.MovieEventId.value = movieId
    Storage.VideoURL.value = videoUrl
    props.setScreen('/TheaterRoom')
  }

  const createStep = (num, text) => (
    <>
      <Typography className={classes.stepNum}>{num}</Typography>
      <Typography className={classes.stepText}>{text}</Typography>
    </>
  )

  const movieTitle = movieInfo?.movie_title
  const startAt = movieInfo ? dateFormat(movieInfo.start_at, 'yyyy年m月d日') : ''
  const endAt = movieInfo ? dateFormat(addDate(movieInfo.end_at, -1), 'yyyy年m月d日') : ''
  const thumbnailUrl = movieInfo?.thumbnail_image_url
  const videoUrl = movieInfo?.video_url

  return (
    <>
      <IconButton className={classes.backButton} onClick={() => BaseComponent.goBack(props)} >
        <LeftIcon />
      </IconButton>
      <Grid className={classes.root} container direction="column" alignItems="center">
        <TheaterTitle />
        <Typography className={classes.movieTitle}>
          「{movieTitle}」
        </Typography>
        <Typography className={classes.eventTerm}>
          {startAt}〜{endAt}
        </Typography>
        {createStep(1, 'さっそく\n予告編を見てみよう！')}
        <div className={classes.videoLink}>
          <img src={thumbnailUrl} width={289} />
          <IconButton
            className={classes.playButton}
            classes={{ label: classes.playIcon }}
            onClick={onClick}
          >
            <PlayArrowIcon  style={{ fontSize: 58 }} />
          </IconButton>
        </div>
        <img />
        {createStep(2, 'アンケートに答えよう！')}
        <TheaterForm movieInfo={movieInfo} watched={watched} answered={answered} {...props} />
      </Grid>
    </>
  )
}

Theater.propTypes = {
  match: PropTypes.object,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
