import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import SpecialOfferComponent from 'components/special-offer/SpecialOffer'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
  },
}))

export default function SpecialOffer (props) {
  const { campaign, ...other } = props
  const classes = useStyles()

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    if (!campaign) {
      props.setScreen('Campaigns')
    }

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  return (
    <div className={classes.root}>
      <AppHeader title="キャンペーン詳細" {...other} />
      <SpecialOfferComponent {...props} />
    </div>
  )
}

SpecialOffer.propTypes = {
  campaign: PropTypes.object,
  setScreen: PropTypes.func,
}
