import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { isEmpty, isCarrierAddress } from 'commons/utility'
import CarrierMailAddressAttentionDialog from 'components/notifications/CarrierMailAddressAttentionDialog'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.styles.header.height,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    height: 44,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
    marginBottom: theme.spacing(3),
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  next: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
}))

export default function MyMail (props) {
  const { mailAddress, authId } = props
  const classes = useStyles()
  const [nowEmail, setNowEmail] = useState(mailAddress)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [emailConf, setEmailConf] = useState('')
  const [emailConfError, setEmailConfError] = useState(null)
  const [openCarrierMailAddressAttentionDialog, setOpenCarrierMailAddressAttentionDialog] = useState(false)

  useEffect(() => {
    (async () => {
      BaseComponent.addVisitPageLog()
      const res = await Api.getAuth()
      setNowEmail(res.mail_address)
    })()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const onChangeEmail = (event) => {
    setEmail(event.target.value)
    setEmailError(null)
  }

  const onChangeEmailConf = (event) => {
    setEmailConf(event.target.value)
    setEmailError(null)
  }

  const changeMailAddress = async () => {
    setEmailError(null)
    if (!email) {
      setEmailError('メールアドレスを入力してください。')
      return
    } else if (!email.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      setEmailError('メールアドレスの形式が正しくありません。')
      return
    } else if (email.length > 255) {
      setEmailError('最大文字長は255文字です。')
      return
    }

    if (!emailConf) {
      setEmailConfError('メールアドレスを入力してください。')
      return
    } else if (!emailConf.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      setEmailConfError('メールアドレスの形式が正しくありません。')
      return
    } else if (emailConf.length > 255) {
      setEmailConfError('最大文字長は255文字です。')
      return
    }

    if (email !== emailConf) {
      setEmailConfError('メールアドレスが一致していません。')
      return
    }

    props.setLoading(true)
    try {
      const auth = await Api.updateMailAddress(authId, email)
      props.setAuthId(auth.id)
      props.setMailAddress(auth.mail_address_to_change)
      props.setScreen('/AuthCode')
    } catch (error) {
      const msg = error.response?.status === 409 ? 'このメールアドレスは使用できません。' : 'メールアドレスの変更ができませんでした。'
      setIsError(true)
      setErrorMessage(msg)
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onBlur = () => {
    if (!isCarrierAddress(email)) { return }
    setOpenCarrierMailAddressAttentionDialog(true)
  }

  return (
    <>
      <AppHeader title="メールアドレス変更" backWhite hideBack {...props} />
      <div className={classes.container}>
        <Typography className={classes.text}>
          現在：{nowEmail}<br />
          新しいメールアドレスを入力後、<br />
          認証コードが送信されます。
        </Typography>
        <Typography className={classes.label}>新しいメールアドレス</Typography>
        <TextField
          className={classes.input}
          type='email'
          value={email}
          onChange={onChangeEmail}
          InputProps={{ disableUnderline: true, inputProps: { 'aria-label': 'email' } }}
          error={!isEmpty(emailError)}
          helperText={emailError}
          onBlur={onBlur}
        />
        <Typography className={classes.label}>新しいメールアドレス（確認）</Typography>
        <TextField
          className={classes.input}
          type='email'
          value={emailConf}
          onChange={onChangeEmailConf}
          InputProps={{ disableUnderline: true, inputProps: { 'aria-label': 'emailConf' } }}
          error={!isEmpty(emailConfError)}
          helperText={emailConfError}
        />
      </div>
      <Grid
        className={classes.buttons}
        container
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button
          className={classes.next}
          variant="contained"
          color="secondary"
          onClick={changeMailAddress}
          disabled={!email || !emailConf}
        >
          次へ
        </Button>
        <Button className={classes.cancel} variant="text" onClick={() => BaseComponent.goBack(props)}>
          キャンセル
        </Button>
      </Grid>
      <CarrierMailAddressAttentionDialog
        open={openCarrierMailAddressAttentionDialog}
        onClose={() => setOpenCarrierMailAddressAttentionDialog(false)}
      />
      <ConfirmDialog
        open={isError}
        onClose={() => setIsError(false)}
        confirm="yes"
        title="メールアドレス変更"
        message={errorMessage}
      />
    </>
  )
}

MyMail.propTypes = {
  mailAddress: PropTypes.string,
  authId: PropTypes.string,
  setLoading: PropTypes.func,
  setAuthId: PropTypes.func,
  setMailAddress: PropTypes.func,
  setScreen: PropTypes.func,
}
