import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { calcDateDiff } from 'commons/utility'
import firstBoostImg03 from 'images/first-boost/first_boost_komainu.png'
import firstBoostStepImg01 from 'images/first-boost/first_boost_step01.png'
import firstBoostStepImg02 from 'images/first-boost/first_boost_step02.png'

const useStyles = makeStyles(theme => ({
  firstBoostLabel: {
    fontWeight: 600,
    color: '#ffffff',
    background: '#F55083',
    textAlign: 'center',
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firstBoostTitle: {
    fontSize: 25,
  },
  firstBoostSubTitle: {
    fontSize: 14,
  },
  img03: {
    display: 'block',
    margin: 'auto',
  },
  stepBox: {
    width: 289,
    background: '#B0B4FC',
    boxShadow: '0 1px 6px #00000029',
    borderRadius: 12,
    margin: 'auto',
    position: 'relative',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
  },
  step: {
    width: 52,
    position: 'absolute',
    top: -26,
    left: 12,
  },
  boxText: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  boxSmallText: {
    fontSize: 10,
    fontWeight: 600,
  },
}))

export default function FirstBoostTutorial (props) {
  const { matchingState, now } = props
  const classes = useStyles()

  const tillDays = calcDateDiff(new Date(matchingState.first_boost_end_at), now)
  const boostDay = tillDays === 1 ? '翌日' : tillDays === 2 ? '2日後' : 'ブースト期間終了日より'

  const createStepBox = (imgSrc, children) => (
    <Grid className={classes.stepBox} container justifyContent="center" alignItems="center">
      <img className={classes.step} src={imgSrc} />
      {children}
    </Grid>
  )

  return (
    <>
      <div className={classes.firstBoostLabel}>
        <span className={classes.firstBoostTitle}>
          初回ブースト
        </span>
        <br />
        <span className={classes.firstBoostSubTitle}>
          今だけ紹介人数がアップ！
        </span>
      </div>
      {createStepBox(firstBoostStepImg01, (
        <Typography className={classes.boxText} color="secondary">
          初回紹介時のみ
          <span style={{ fontSize: 30, fontWeight: 700 }}>5</span>名紹介
        </Typography>
      ))}
      {createStepBox(firstBoostStepImg02, (
        <>
          <Typography className={classes.boxText} color="secondary">
            紹介者5人に<br />
            「いいね！」送信で最大<br />
            <span style={{ fontSize: 30, fontWeight: 700 }}>+5</span>名紹介
          </Typography>
          <Typography className={classes.boxSmallText} color="secondary">
            期間内に「いいね！」送信1人につき、<br />
            <span style={{ fontWeight: 700 }}>{boostDay}</span>1名追加でご紹介（最大5名まで）
          </Typography>
        </>
      ))}
      <img className={classes.img03} src={firstBoostImg03} width={269} />
    </>
  )
}

FirstBoostTutorial.propTypes = {
  matchingState: PropTypes.object,
  now: PropTypes.object,
}
