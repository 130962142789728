import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import TheaterKomainu from 'images/movie/theater_komainu.png'

const useStyles = makeStyles(theme => ({
  titleBold: {
    fontSize: 14,
    fontWeight: 800,
    marginBottom: theme.spacing(1),
    display: 'inline-block',
  },
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(1.5),
  },
}))

export default function AnnounceDialog (props) {
  const { open, params, onConfirm, handleHold, onClose } = props
  const classes = useStyles()

  if (!params) { return null }

  const startAt = dateFormat(params.start_at, 'm月d日')
  const endAt = dateFormat(addDate(params.end_at, -1), 'm月d日')

  const onClick = () => {
    onClose?.(params.id)
    onConfirm?.()
  }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={() => onClose?.(params.id)} />
        <DialogTitle disableTypography>
          <span className={classes.titleBold}>松竹 × Aill goen</span><br />
          Aillシアターイベント<br />
          開催決定！
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.subTitle} variant="subtitle1">映画de運命の出会い！？</Typography>
          <Typography className={classes.text} variant="body1">
            映画予告視聴後に<br />
            アンケート回答で<br />
            あなたにぴったりな相手をご紹介<br />
            <br />
            期間：{startAt}〜{endAt}
          </Typography>
          <img src={TheaterKomainu} width={'100%'} />
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onClick}>
            詳しく
          </Button>
          {handleHold && (
            <Button variant="text" onClick={handleHold}>
              あとで
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

AnnounceDialog.propTypes = {
  open: PropTypes.bool,
  params: PropTypes.object,
  onConfirm: PropTypes.func,
  handleHold: PropTypes.func,
  onClose: PropTypes.func,
}
