import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class PublicIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 27.636 27.472" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_853" data-name="グループ 853" transform="translate(-92.429 -33.428)">
            <circle id="楕円形_126" data-name="楕円形 126" cx="5.5" cy="5.5" r="5.5" transform="translate(100.747 49)" fill="none" stroke="#9d9d9e" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_453" data-name="パス 453" d="M93.27,53.165a13.99,13.99,0,0,1,25.953,0" fill="none" stroke="#9d9d9e" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_454" data-name="パス 454" d="M107.547,40h-2.6l-.4-6.2h3.4Z" fill="#989898" stroke="#989898" strokeLinejoin="round" strokeWidth="0.75"/>
            <path id="パス_455" data-name="パス 455" d="M100.122,40.937l-2.252,1.3-3.446-5.169,2.944-1.7Z" fill="#989898" stroke="#989898" strokeLinejoin="round" strokeWidth="0.75"/>
            <path id="パス_456" data-name="パス 456" d="M112.224,40.937l2.252,1.3,3.446-5.169-2.944-1.7Z" fill="#989898" stroke="#989898" strokeLinejoin="round" strokeWidth="0.75"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(PublicIcon)