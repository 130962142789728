const LOCALE = 'ja-JP'
const TAX_FACTOR = 1.1

export default class Currency {
  // 3桁ずつカンマ区切り
  static format (price) {
    if (price === null || price === undefined) { return '' }
    return Intl.NumberFormat(LOCALE).format(price)
  }

  static withTax (price) {
    if (price === null || price === undefined) { return -1 }
    return Math.floor(parseInt(price) * TAX_FACTOR)
  }

  static formatWithTax (price) {
    if (price === null || price === undefined) { return '' }
    return Currency.format(Currency.withTax(price))
  }
}