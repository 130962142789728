import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ValentineEventChallengeDialog from 'components/valentine-event/ValentineEventChallengeDialog'
import BackImg from 'images/valentine-event/back_image_banner.png'

const useStyles = makeStyles(() => ({
  root: {
    height: 80,
    backgroundColor: '#E55E7E',
    backgroundImage: `url(${BackImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPositionX: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
  },
}))

export default function ValentineEventBanner (props) {
  const { missions } = props
  const classes = useStyles()
  const [openValentineEventChallengeDialog, setOpenValentineEventChallengeDialog] = useState(false)

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => setOpenValentineEventChallengeDialog(true)}
      >
        <Typography className={classes.subTitle}>バレンタインイベント2023</Typography>
        <Typography className={classes.title}>「いいね！」で最大+10名ご紹介</Typography>
      </Grid>
      <ValentineEventChallengeDialog
        open={openValentineEventChallengeDialog}
        onClose={() => setOpenValentineEventChallengeDialog(false)}
        missions={missions}
      />
    </>
  )
}

ValentineEventBanner.propTypes = {
  missions: PropTypes.array,
}
