import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import LabelImg from 'images/suggestLabel.png'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 14,
    color: '#1A1667',
    lineHeight: 1,
    paddingTop: theme.spacing(6),
  },
  text: {
    fontSize: 12,
    fontWeight: 300,
    color: '#1A1667',
    marginBottom: theme.spacing(3),
  },
  checkBox: {
    width: '100%',
    background: '#F4F7FE',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  checkIcon: {
    color: '#1A1667',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  checkText: {
    fontSize: 12,
    color: '#1A1667',
    marginBottom: theme.spacing(1.5),
  },
  containedButton: {
    width: 200,
    height: 36,
    fontSize: 13,
    background: '#616AFA !important',
  },
  textButton: {
    fontSize: 13,
    color: '#616AFA !important',
  },
}))

export default function FreePlanSuggestDialog (props) {
  const { open, onClose, checkFreePlan, onWithdrawal } = props
  const classes = useStyles()

  const createCheckColumn = (text) => (
    <Grid container alignItems="center">
      <CheckIcon className={classes.checkIcon} />
      <DialogContentText className={classes.checkText}>
        {text}
      </DialogContentText>
    </Grid>
  )

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} data-testid="free-suggest-dialog">
        <DialogCloseButton onClick={onClose} />
        <DialogTitle className={classes.title} disableTypography>
          フリープランにしませんか？<br />
          <br />
          無料で継続できます
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.text}>
            変更方法は簡単！<br />
            １分で完了できます
          </DialogContentText>
          <img src={LabelImg} width={'100%'} />
          <Grid className={classes.checkBox}>
            {createCheckColumn('月額利用料が０円')}
            {createCheckColumn('再登録が不要')}
            {createCheckColumn('いつでもプラン変更可能')}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.containedButton}
            variant="contained"
            onClick={checkFreePlan}
          >
            フリープランを見る
          </Button>
          <Button
            className={classes.textButton}
            variant="text"
            onClick={onWithdrawal}
          >
            退会する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreePlanSuggestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  checkFreePlan: PropTypes.func,
  onWithdrawal: PropTypes.func,
}
