import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import MenuIcon from '@material-ui/icons/MoreVert'
import PushOffIcon from '@material-ui/icons/VolumeOff'
import Api from 'commons/api'
import * as storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import MyPage from 'components/MyPage'
import BaseComponent from 'components/parts/BaseComponent'
import ChatMenu from 'components/parts/ChatMenu'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import PhotoFilter from 'components/parts/PhotoFilter'
import ReasonDialog from 'components/parts/ReasonDialog'
import TutorialIcon from 'components/parts/TutorialIcon'
import AccountImage from 'images/img_account.png'
import logoImage from 'images/logo_aill_2021_negative.png'

const styles = theme => ({
  bar: {
    ...theme.styles.header,
    '-webkit-user-select': 'none',
  },
  barWhite: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    minHeight: theme.styles.header.height,
    position: 'relative'
  },
  title: {
    ...theme.styles.fontSize(16),
    width: '75%',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    color: theme.palette.secondary.contrastText,
  },
  titleContrast: {
    ...theme.styles.messageColor,
  },
  titleChat: {
    ...theme.styles.fontSize(16),
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
    width: theme.spacing(23),
    overflow: 'hidden',
  },
  titleChatContrast: {
    color: theme.palette.secondary.contrastText,
  },
  backButton: {
    padding: 0,
    position: 'absolute',
    left: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  backButtonContrast: {
    color: theme.palette.secondary.main,
  },
  menuIcon: {
    ...theme.styles.headerIconColor,
  },
  menuIconWhite: {
    color: '#ffffff',
  },
  buttonContainer: {
    width: theme.spacing(10),
    position: 'absolute',
    right: theme.spacing(1),
    textAlign: 'right',
  },
  bottonRoot: {
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
  },
  notificationOffIcon: {
    ...theme.styles.headerIconColor,
  },
  logo: {
    height: 40,
    margin: 'auto',
  },
  filterButton: {
    position: 'absolute',
    right: theme.spacing(8),
    color: theme.palette.secondary.contrastText,
    width: theme.spacing(7.25),
    height: theme.spacing(6.5),
  },
  myPageButton: {
    padding: 0,
    position: 'absolute',
    left: 16,
    color: theme.palette.primary.main,
  },
  badge: {
    top: 2,
    right: 2,
    width: 8,
    minWidth: 8,
    height: 8,
    padding: 0,
  },
  secretBadge: {
    top: 0,
    right: 0,
    width: theme.spacing(1),
    minWidth: theme.spacing(1),
    height: theme.spacing(1),
    padding: 0,
  },
  faceAvatar: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  avatarContainer: {
    marginLeft: theme.spacing(4.5),
  },
  dialogRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogText: {
    marginBottom: theme.spacing(2.66),
  },
  error: {
    borderColor: 'red',
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  input: {
    ...theme.styles.dialogInput,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 8,
  },
  drawerTop: {
    top: theme.styles.header.height,
  },
})

const maxMessageLength = 300

export class AppHeader extends BaseComponent {
  constructor (props) {
    super(props)

    this.clickBack = this.clickBack.bind(this)
    this.clickHome = this.clickHome.bind(this)
    this.clickToggleMenu = this.clickToggleMenu.bind(this)
    this.handleSelectMenuItem = this.handleSelectMenuItem.bind(this)
    this.closeMenuItemDialog = this.closeMenuItemDialog.bind(this)
    this.clickTitle = this.clickTitle.bind(this)
    this.clickMyPage = this.clickMyPage.bind(this)
    this.clickMatchingFilter = this.clickMatchingFilter.bind(this)
    this.clickNotifications = this.clickNotifications.bind(this)
    this.handleReasonSend = this.handleReasonSend.bind(this)
    this.handleReasonClose = this.handleReasonClose.bind(this)
    this.handleChangeMessage = this.handleChangeMessage.bind(this)
    this.handleQuestionOk = this.handleQuestionOk.bind(this)
    this.handleQuestionCancel = this.handleQuestionCancel.bind(this)
    this.handleMessageErrorClose = this.handleMessageErrorClose.bind(this)

    this._isMounted = false

    this.state = {
      openMenu: false,
      user: this.props.user,
      friend: this.props.target,
      openMyPage: false,
      newNotificationCount: 0,
      reasonOpen: false,
      questionOpen: false,
      message: '',
      errorInputMessage: false,
      errorOpen: false,
      errorMessage: '',
      isSecretRooms: false,
      newSecretMessageCount: 0,
    }
  }

  componentWillMount () {
    if (this.props.showTutorialIcon) {
      this.getNewNotificationCount()
    }
    if (this.props.target) {
      this.isSecretRooms()
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(this.props.target) !== JSON.stringify(nextProps.target)) {
      if (this.state.isSecretRooms) {
        let newMessageCount = this.getNewSecretMessageCount(nextProps.target)
        this.setState({ newSecretMessageCount: newMessageCount })
      }
      this.setState({ friend: nextProps.target })
    }
    if (JSON.stringify(this.props.openChatMenu) !== JSON.stringify(nextProps.openChatMenu)) {
      this.setState({ openMenu: nextProps.openChatMenu })
    }
  }

  clickBack () {
    if (this.props.onBack) {
      this.props.onBack()
    } else {
      BaseComponent.goBack(this.props)
    }
  }

  clickHome () {
    this.props.setScreen('Home')
  }

  clickToggleMenu () {
    this.setState({ openMenu: !this.state.openMenu })
  }

  handleSelectMenuItem (name) {
    switch (name) {
      case 'AccountSummary':
        if (this.props.isLover) {
          this.props.setScreen('Profile')
        } else {
          storage.setProfileSummaryIndex(0)
          this.props.setScreen('IntroductionProfile')
        }
        break
      case 'PublicProfile':
        storage.setProfileSummaryIndex(2)
        this.props.setScreen('IntroductionProfile')
        break
      case 'Favorability':
        storage.setProfileSummaryIndex(1)
        this.props.setScreen('IntroductionProfile')
        break
      case 'Notification':
        this.toggleNotificationEnabled()
        break
      case 'RejectFriend':
        this.setState({ reasonOpen: true })
        break
      case 'RejectLover':
        this.setState({ questionOpen: true })
        break
      case 'LoverEntry':
        this.props.setFriend(this.state.friend)
        this.props.setScreen('LoverEntry')
        break
      case 'ViolationReport':
        this.props.setFriend(this.state.friend)
        this.props.setScreen('ViolationReport')
        break
      case 'ShopSearch':
        this.props.setScreen('ShopSearch')
        break
      case 'SecretRooms':
        this.props.setFriend(this.state.friend)
        this.props.setScreen('SecretRooms')
        break
      case 'ChatMemo':
        this.props.onChatMemo()
        this.setState({
          openMenu: false,
        })
        break
      case 'SendReservation':
        this.props.setScreen('ScheduledChatMessages')
        break
      default: {
        let method = this[`open${name}Dialog`]
        if (method) {
          this.setState({ openMenu: false })
          method.bind(this)()
        }
      }
    }
  }

  // この友達に対するPush通知ON/OFF切り替え
  async toggleNotificationEnabled () {
    this.setState({ openMenu: false })

    this.props.setLoading(true)
    try {
      let friendId = this.state.friend.id
      let enabled = this.state.friend.push_notification_enabled
      let updatedFriend = await Api.updateFriend(friendId, {
        push_notification_enabled: !enabled
      })
      this.setStateIfMounted({ friend: updatedFriend })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  closeMenuItemDialog (name) {
    return () => {
      let newState = {}
      newState[`open${name}Dialog`] = false
      this.setState(newState)
    }
  }

  clickTitle () {
    storage.setProfileSummaryIndex(0)
    this.props.setScreen('IntroductionProfile')
  }

  clickMyPage () {
    this.setState({ openMyPage: !this.state.openMyPage })
  }

  clickMatchingFilter () {
    this.props.setScreen('MatchingFilter')
  }

  clickNotifications () {
    this.props.setScreen('Notifications')
  }

  async getNewNotificationCount () {
    try {
      let count = await Api.getUnreadNotificationCount()
      let global_count = await Api.getUnreadGlobalNotificationCount()
      this.setStateIfMounted({ newNotificationCount: count + global_count })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  handleReasonClose () {
    this.setState({ reasonOpen: false })
  }

  handleReasonSend () {
    this.setState({ reasonOpen: false })
    // this.setState({ cancelOpen: true })
    this.showRequestSuccessMessage('友達解消しました')
    this.props.onCancelFriend()
  }

  handleChangeMessage (event) {
    var txt = event.target.value
    this.setState({
      message: txt,
      errorInputMessage: false
    })
  }

  async handleQuestionOk () {
    let valid = this.validate()
    if (!valid) { return }

    this.props.setLoading(true)
    let friendId = this.state.friend.id
    let opts = {
      to_message: this.state.message
    }
    try {
      await this.postEntry(friendId, 'reject_lover', opts)
      this.setStateIfMounted({
        questionOpen: false,
        // confirmOpen: true,
      })
      this.showRequestSuccessMessage('お付き合いを解消しました')
      window.location.reload()
    } catch (error) {
      this.setStateIfMounted({
        questionOpen: false,
        errorOpen: true,
        errorMessage: 'お付き合い解消できませんでした。'
      })
    }
    finally {
      this.props.setLoading(false)
    }
  }

  handleQuestionCancel () {
    this.setState({ questionOpen: false })
  }

  handleMessageErrorClose () {
    this.setState({ errorOpen: false })
  }

  validate () {
    if (!this.state.message.trim()) {
      this.setState({
        errorInputMessage: true,
        errorOpen: true,
        errorMessage: 'メッセージを入力してください'
      })
      return false
    } else if (maxMessageLength < this.state.message.length) {
      this.setState({
        errorInputMessage: true,
        errorOpen: true,
        errorMessage: `メッセージが${maxMessageLength}文字を超えています`
      })
      return false
    } else {
      return true
    }
  }

  // 秘密の部屋開通確認
  async isSecretRooms () {
    if (this.props.onlyMemo) return
    try {
      let friend = this.props.target
      let room = await Api.getChatRoom(friend.chat_room_id)
      if (!room.secret_question_enabled) return
      let newMessageCount = this.getNewSecretMessageCount(friend)
      this.setStateIfMounted({
        isSecretRooms: true,
        newSecretMessageCount: newMessageCount,
      })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  getNewSecretMessageCount (friend) {
    let messageCount = 0
    if (!isNullOrUndefined(friend.new_secret_message_count)) {
      messageCount = friend.new_secret_message_count
    }
    return messageCount
  }

  createAvatar () {
    const { modeEdit, showFriendIcon, classes } = this.props
    const { user, friend } = this.state
    if (modeEdit) {
      return (
        <Grid item className={classes.avatarContainer}>
          <PhotoFilter brightness={user.photo_filter_brightness}>
            <Avatar src={user.photo_icon} className={classes.faceAvatar} />
          </PhotoFilter>
        </Grid>
      )
    }

    if (showFriendIcon) {
      const icon = friend.type === 'closed' ? null : friend.photo_icon
      return (
        <Grid item className={classes.avatarContainer}>
          <PhotoFilter brightness={friend.photo_filter_brightness}>
            <Avatar src={icon} className={classes.faceAvatar} />
          </PhotoFilter>
        </Grid>
      )
    }
  }

  render () {
    const {
      title, backWhite, showLogo, hideBack,
      showMypage, showFriendIcon, showTutorialIcon,
      modeEdit, toggleMenu, onlyMemo, isLover,
      classes, tutorialView, ...other
    } = this.props
    const {
      friend, newSecretMessageCount, openMenu, isSecretRooms, openMyPage,
      message, errorInputMessage, reasonOpen, questionOpen,
      errorOpen, errorMessage
    } = this.state

    return (
      <div>
        <ReasonDialog
          {...other}
          open={reasonOpen}
          onClose={this.handleReasonClose}
          onSend={this.handleReasonSend}
        />
        <DialogThemeProvider color="primary">
          <Dialog open={questionOpen}>
            <DialogTitle disableTypography>
              お付き合い解消
            </DialogTitle>
            <DialogContent>
              <Typography className={classes.text} variant="body1">
                お付き合いを本当に解消しますか？
              </Typography>
              <InputBase
                classes={{ error: classes.error }}
                value={message}
                onChange={this.handleChangeMessage}
                className={classes.input}
                error={errorInputMessage}
                placeholder="メッセージを入力"
                multiline
                rows="6"
              />
            </DialogContent>
            <DialogActions disableSpacing>
              <Button variant="contained" onClick={this.handleQuestionOk}>
                OK
              </Button>
              <Button variant="text" onClick={this.handleQuestionCancel}>
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
        </DialogThemeProvider>
        <ConfirmDialog
          open={errorOpen}
          onClose={this.handleMessageErrorClose}
          onOk={this.handleMessageErrorClose}
          onCancel={this.handleMessageErrorClose}
          confirm="yes"
          title="送信エラー"
          message={errorMessage}
        />
        <AppBar position="fixed" className={classes.bar + ' ' + (backWhite ? classes.barWhite : '')} elevation={0} >
          <Toolbar className={classes.toolbar}>
            {showLogo && <img className={classes.logo} src={logoImage} alt="" />}
            {hideBack ? null : (
              <IconButton aria-label="add" className={classes.backButton + ' ' + (backWhite ? classes.backButtonContrast : '')} onClick={this.clickBack} data-testid="back-button">
                <LeftIcon />
              </IconButton>
            )}
            {showMypage && (
              <IconButton className={classes.myPageButton} onClick={this.clickMyPage}>
                <Badge
                  classes={{ badge: classes.badge }}
                  invisible={this.state.newNotificationCount === 0}
                  overlap="rectangular"
                >
                  <img src={AccountImage} width={20} height={20} alt="" />
                </Badge>
              </IconButton>
            )}
            {(showFriendIcon || modeEdit) ? (
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {this.createAvatar()}
                <Typography className={classes.titleChat + ' ' + (!backWhite ? classes.titleChatContrast : '')} >
                  {title}
                </Typography>
              </Grid>
            ) : (
              <Typography className={classes.title + ' ' + (backWhite ? classes.titleContrast : '')}>
                {title}
              </Typography>
            )}
            {toggleMenu && (
              <Grid container justifyContent="flex-end" alignItems="center" className={classes.buttonContainer}>
                {(friend && friend.push_notification_enabled === false) && (
                  <PushOffIcon className={classes.notificationOffIcon}/>
                )}
                <IconButton
                  id="menu-icon"
                  onClick={() => {
                    this.clickToggleMenu()
                    if (tutorialView === 'fav-update-01') { this.props.setTutorialView(null) }
                  }}
                  style={{
                    padding: tutorialView === 'fav-update-01' && 8,
                    border: tutorialView === 'fav-update-01' && '4px solid #6666FC'
                  }}
                >
                  <Badge
                    classes={{ badge: classes.secretBadge }}
                    invisible={newSecretMessageCount === 0}
                    overlap="rectangular"
                  >
                    <MenuIcon className={backWhite ? classes.menuIcon : classes.menuIconWhite} />
                  </Badge>
                </IconButton>
              </Grid>
            )}
            {showTutorialIcon && (
              <TutorialIcon {...this.props} />
            )}
          </Toolbar>
        </AppBar>
        {friend && (
          <Drawer
            anchor="top"
            style={{ zIndex: onlyMemo ? 1401 : 1099 }}
            classes={{ paperAnchorTop: classes.drawerTop }}
            open={openMenu}
            onClose={this.clickToggleMenu}
            ModalProps={{ BackdropProps: { invisible: true } }}
          >
            <ChatMenu
              {...this.props}
              chatFriend={friend}
              onSelectItem={this.handleSelectMenuItem}
              isLover={isLover}
              isSecretRooms={isSecretRooms}
              isPushNotif={friend.push_notification_enabled}
              onlyMemo={onlyMemo}
              onClose={() => this.setState({ openMenu: false })}
            />
          </Drawer>
        )}
        <Drawer
          anchor="left"
          open={openMyPage}
          onClose={this.clickMyPage}
        >
          <MyPage {...other} newNotificationCount={this.state.newNotificationCount} />
        </Drawer>
      </div>
    )
  }
}

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  hideBack: PropTypes.bool,
  history: PropTypes.object.isRequired,
  setScreen: PropTypes.func.isRequired,
  toggleMenu: PropTypes.bool,
  showMypage: PropTypes.bool,
  showTutorialIcon: PropTypes.bool,
  showFriendIcon: PropTypes.bool,
  onBack: PropTypes.func,
  backWhite: PropTypes.bool,
  isLover: PropTypes.bool,
  modeEdit: PropTypes.bool,
  onCancelFriend: PropTypes.func,
  onChatMemo: PropTypes.func,
  onlyMemo: PropTypes.bool,
  openChatMenu: PropTypes.bool,
}

export default withStyles(styles)(AppHeader)