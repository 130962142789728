import React, { useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { addDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import SpecialFreePlanImg from 'images/specialFreePlan.png'

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: theme.spacing(4),
  },
  img: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function SpecialFreePlanSuggestDialog (props) {
  const { open, onClose, subscription } = props
  const classes = useStyles()

  useEffect(() => {
    if (!open) { return }
    BaseComponent.gaModalView('specialfreeplansuggest')
  }, [open])

  if (!subscription?.next_billing_date) { return null }
  const nextBillingDate = new Date(subscription.next_billing_date)
  const endDate = addDate(nextBillingDate, -1)
  if (Number.isNaN(nextBillingDate.getTime()) || Number.isNaN(endDate.getTime())) { return null }

  return (
    <>
      <DialogThemeProvider color="primary">
        <Dialog open={open}>
          <DialogCloseButton onClick={onClose} />
          <DialogTitle disableTypography>
            特別フリープランのご案内
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Typography variant="body1">
              無料期間は{dateFormat(endDate, 'yyyy年m月d日')}までとなります。<br />
              {dateFormat(nextBillingDate, 'yyyy年m月d日')}以降は、<br />
              「特別フリープラン」に<br />
              自動移行いたします。<br />
              無料体験後も、無料でサービスをお楽しみくださいませ。<br />
              <img className={classes.img} src={SpecialFreePlanImg} width={'100%'} />
              現在、関東・関西・福岡を中心に<br />
              サービスを開始しています。<br />
              異なるエリア「仮登録選択エリア」にお住まいの方を対象に<br />
              特別フリープランを<br />
              ご用意しています！<br />
              <br />
            </Typography>
            <Typography variant="body2">
              ＊当エリアのご利用企業数が集まり次第、スタンダードプランを開始いたします。<br />
              ＊スタンダードプラン開始時にはご連絡いたします。自動でスタンダードプランへの移行（有料化）は行われませんのでご安心ください。
            </Typography>
          </DialogContent>
        </Dialog>
      </DialogThemeProvider>
    </>
  )
}

SpecialFreePlanSuggestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  subscription: PropTypes.object,
}
