import NewFeatureScheduledChatImg from 'images/new_feature_scheduled_chat.png'
import InterestedThingsImg from 'images/notif_interested_things.png'

export default {
  ChatMemo: {
    releaseDate: '2021-05-10T00:00:00+09:00'
  },
  MatchingFilter: {
    releaseDate: '2021-11-01T00:00:00+09:00',
    text: '優先して紹介する異性の条件を\n設定できるようになりました。',
  },
  MatchingTutorial202111: {
    releaseDate: '2021-11-11T00:00:00+09:00'
  },
  AllowAutoPhotoAdjustment: {
    releaseDate: '2021-12-09T00:00:00+09:00'
  },
  Matching202201: {
    releaseDate: '2022-01-26T12:00:00+09:00'
  },
  PhotoTutorial202202: {
    releaseDate: '2022-02-21T12:00:00+09:00'
  },
  PushNotificationUserConfig: {
    releaseDate: '2022-09-13T00:00:00+09:00',
    text: '各種通知設定（Push通知）を\nカスタマイズできるようになりました。'
  },
  FixProfileItem202301: {
    releaseDate: '2023-01-12T00:00:00+09:00',
    text: '勤め先の業種に\n「製造・印刷」を追加しました。',
  },
  FixMatchingFilter202303: {
    releaseDate: '2023-03-23T00:00:00+09:00',
    text: [
      '紹介優先条件（年齢）の',
      '選択肢を変更しました。',
      '・1歳刻みに変更',
      '・「なし」を追加',
    ].join('\n'),
  },
  NewProfileValue202308: {
    releaseDate: '2023-08-08T00:00:00+09:00',
    text: [
      '「喫煙状況」の選択肢に',
      '「喫煙 電子たばこ」を追加しました。',
      '',
      '「相手の喫煙に関して」の選択肢に',
      '「非喫煙〜喫煙(電子たばこ)まで良い」を追加しました。'
    ].join('\n'),
  },
  FixProfileNationality202308: {
    releaseDate: '2023-08-16T00:00:00+09:00',
    text: '国籍が海外の方は\n国籍の公開設定が可能になりました。\n\n非公開に設定した場合は\n「海外」と表示されます。',
  },
  MessageReply: {
    releaseDate: '2023-11-10T10:00:00+09:00',
    text: 'トークメッセージの長押しメニューに\n「リプライ」が追加されました！\n是非ご活用ください。',
  },
  InterestedThings: {
    releaseDate: '2024-03-19T12:00:00+09:00',
    img: InterestedThingsImg,
    text: 'プロフィール編集ページから\n追加できます。',
    buttonText: '興味のあることを登録する'
  },
  MessagePartCopy: {
    releaseDate: '2024-06-03T12:40:00+09:00',
    text: 'トークメッセージの長押しメニューに\n「部分コピー」が追加されました！\n是非ご活用ください。',
  },
  ScheduledChat: {
    releaseDate: '2024-06-09T00:00:00+09:00',
    img: NewFeatureScheduledChatImg,
    text: 'メッセージや画像の\n送信ボタンを押すと\n送信時間の設定画面が表示されます。',
  },
  NewProfileValue202408: {
    releaseDate: '2024-08-08T12:30:00+09:00',
    text: [
      'プロフィール項目に',
      '以下の変更を行いました。',
      '',
      '「希望する自分（相手）の働き方」の選択肢について',
      '「管理職」を「キャリア」に変更',
      '「キャリアないしは正社員」を追加',
      '',
      '新項目「勤務地の柔軟性」を追加',
      '',
      'より良いマッチングのため',
      '是非ご入力ください！',
    ].join('\n'),
  },
}
