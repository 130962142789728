import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class ViolationIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 29.801 29.8" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_855" data-name="グループ 855" transform="translate(-91.1 -79.1)">
            <circle id="楕円形_127" data-name="楕円形 127" cx="14" cy="14" r="14" transform="translate(92 80)" fill="none" stroke="#9d9d9e" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_459" data-name="パス 459" d="M110.344,86.12" fill="none" stroke="#9d9d9e" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_460" data-name="パス 460" d="M101.656,101.88A9,9,0,0,0,113.88,89.656h0l-12.222,12.223Z" fill="none" stroke="#9d9d9e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8"/>
            <path id="パス_461" data-name="パス 461" d="M110.322,86.125A9,9,0,0,0,98.1,98.35h0l12.222-12.222Z" fill="none" stroke="#9d9d9e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(ViolationIcon)