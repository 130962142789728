import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MemoImg from 'images/icon_memo.png'
import SendIcon from 'images/icon_send.png'
import SendReservation from 'images/icon_send_reservation.png'
import TrashImg from 'images/icon_trash.png'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      width: theme.spacing(24.5),
      backgroundColor: '#DBDCFE',
      boxShadow: 'none',
      borderRadius: 16,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(3),
    }
  },
  text: {
    fontSize: 12,
    color: '#1A1667',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
}))

const ScheduledChatMenuDialog = (props) => {
  const {
    anchorEl,
    open,
    onClose,
    onEdit,
    onDelete,
    onSendNow,
    onReschedule,
  } = props
  const classes = useStyles()

  if (!anchorEl) return null

  return (
    <Menu
      className={classes.root}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      getContentAnchorEl={null}
      disableAutoFocusItem
    >
      <MenuItem onClick={onEdit}>
        <img src={MemoImg} width={30} height={30} alt="" />
        <Typography className={classes.text}>
          編集
        </Typography>
      </MenuItem>
      <MenuItem onClick={onSendNow}>
        <img src={SendIcon} width={30} height={30} alt="" />
        <Typography className={classes.text}>
          今すぐ送信
        </Typography>
      </MenuItem>
      <MenuItem onClick={onReschedule}>
        <img src={SendReservation} width={30} height={30} alt="" />
        <Typography className={classes.text}>
          スケジュールを再設定
        </Typography>
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <img src={TrashImg} width={30} height={30} alt="" />
        <Typography className={classes.text}>
          削除
        </Typography>
      </MenuItem>
    </Menu>
  )
}

export default ScheduledChatMenuDialog

ScheduledChatMenuDialog.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSendNow: PropTypes.func,
  onReschedule: PropTypes.func,
}
