import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class SendReservationIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
          <rect id="長方形_1311" data-name="長方形 1311" width="30" height="30" fill="none"/>
          <g id="グループ_17526" data-name="グループ 17526" transform="translate(2.25 2.25)">
            <g id="グループ_852" data-name="グループ 852">
              <ellipse id="楕円形_125" data-name="楕円形 125" cx="11.967" cy="12.446" rx="11.967" ry="12.446" transform="translate(0)" fill="none" stroke="#989898" strokeLinejoin="round" strokeWidth="1.8"/>
              <line id="線_123" data-name="線 123" y2="8.616" transform="translate(11.825 3.942)" fill="none" stroke="#989898" strokeLinecap="round" strokeWidth="1.8"/>
              <line id="線_127" data-name="線 127" y2="5.336" transform="translate(17.161 12.613) rotate(90)" fill="none" stroke="#989898" strokeLinecap="round" strokeWidth="1.8"/>
            </g>
            <g id="グループ_17525" data-name="グループ 17525" transform="translate(13.806 16.744)">
              <g id="長方形_1312" data-name="長方形 1312" transform="translate(0 0)" fill="#fff" stroke="#989898" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6">
                <rect width="13.194" height="9.506" stroke="none"/>
                <rect x="0.8" y="0.8" width="11.594" height="7.906" fill="none"/>
              </g>
              <path id="パス_3478" data-name="パス 3478" d="M311.142,878.224l5.337,4.487,5.163-4.487" transform="translate(-309.768 -877.286)" fill="none" stroke="#989898" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6"/>
            </g>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(SendReservationIcon)