import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import { copyToClipboard } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import KomainuImg from 'images/share/komainu.png'
import StarLeftImg from 'images/share/star_left.png'
import StarRightImg from 'images/share/star_right.png'
import TextImg from 'images/share/text.png'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75),
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.styles.footer.height,
    backgroundColor: '#EFF0FF',
    minHeight: window.innerHeight,
  },
  titleBox: {
    width: 180,
    height: 22,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1.5),
  },
  title: {
    fontSize: 22,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
  },
  // starLeft: {
  //   position: 'absolute',
  //   top: 0,
  //   left: -32,
  // },
  // starRight: {
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  // },
  subtitle: {
    fontSize: 15,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
    marginBottom: theme.spacing(4),
  },
  komainu: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(-4),
  },
  box: {
    width: 289,
    height: 77,
    background: '#ffffff',
    borderRadius: 12,
    boxShadow: '0px 1px 6px #00000029',
  },
  boxText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: '27px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  note: {
    fontSize: 11,
    fontWeight: 300,
    color: '#707070',
    lineHeight: 1,
    marginTop: theme.spacing(2),
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 9.5px 0 9.5px',
    borderColor: '#423BC7 transparent transparent transparent',
    margin: 'auto',
  },
  button: {
    width: 230,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    background: '#423BC7',
    marginTop: theme.spacing(1.5),
    '&:hover': {
      background: '#423BC7',
    }
  },
}))

export default function Share (props) {
  const { ...other } = props
  const classes = useStyles()

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    props.setScreen('Share')

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const createBox = (text) => (
    <Grid className={classes.box} container justifyContent="center" alignItems="center">
      <Typography className={classes.boxText}>{text}</Typography>
    </Grid>
  )

  const shareInvitationLink = async () => {
    try {
      BaseComponent.gaEvent('お友達紹介', 'click', 'URLをコピーする')
      await Api.addActionLog('click_share_button')
      const text = `一緒にAill goenを始めよう！

登録はこちらから！
${Config.entrySiteUrl}`

      // iOS
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.shareToOtherApps.postMessage(text)
        // Android
      } else if (window.android) {
        window.android.shareToOtherApps(text)
      } else {
        await copyToClipboard(text)
        BaseComponent.showRequestSuccessMessage(props, 'コピーしました')
      }
    } catch (error) {
      BaseComponent.showErrorMessage(props, error)
    }
  }

  return (
    <div className={classes.root}>
      <AppHeader
        title="お友達紹介"
        hideBack
        hideHome
        showMypage
        showTutorialIcon
        showFeedback
        {...other}
      />
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid className={classes.titleBox} container justifyContent="space-around" alignItems="center">
          <img src={StarLeftImg} width={30} height={22} />
          <Typography className={classes.title}>
            お友達紹介
          </Typography>
          <img src={StarRightImg} width={30} height={22} />
        </Grid>
        <Typography className={classes.subtitle}>一緒にAill goenをはじめよう！</Typography>
        <img src={TextImg} width={198} />
        <img className={classes.komainu} src={KomainuImg} width={198} />
        {createBox('Aill goenサービスサイトの\nURLをコピーして送信')}
        <div className={classes.triangle} />
        {createBox('お友達がURLから\nAill goenに登録')}
        <div className={classes.triangle} />
        {createBox('入会審査通過で利用スタート！')}
        <Typography className={classes.note}>＊お友達の所属企業によって、ご利用料金が異なります</Typography>
        <Button className={classes.button} variant="contained" onClick={shareInvitationLink}>URLをコピーする</Button>
      </Grid>
    </div>
  )
}

Share.propTypes = {
  setScreen: PropTypes.func,
}
