import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Contact from 'components/entry/Contact'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import SnsInfo from 'components/entry/SnsInfo'

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
    paddingTop: sp => theme.spacing(sp ? 4.5 : 8.25),
  },
  title: {
    fontSize: sp => sp ? 19 : 32,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.9px' : '3.2px',
    textAlign: 'center',
  },
  titleBar: {
    height: sp => sp ? 2 : 4,
    width: sp => sp ? 145 : 234,
    background: '#616AFA',
    margin: 'auto',
    marginTop: sp => theme.spacing(sp ? 1.5 : 2),
  },
  container: {
    paddingTop: sp => theme.spacing(sp ? 3.25 : 5.25),
    paddingBottom: sp => theme.spacing(sp ? 4 : 9.75),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  text: {
    fontSize: sp => sp ? 12 : 16,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : '26px',
    letterSpacing: sp => sp ? '1.2px' : '1.6px',
    textAlign: sp => sp ? 'left' : 'center',
  },
  subtitle: {
    fontSize: sp => sp ? 14 : 28,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : 1,
    letterSpacing: sp => sp ? '1.4px' : '2.8px',
    textAlign: 'center',
    marginBottom: theme.spacing(3.75),
  },
}))

export default function SearchCompanyAccepted () {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  return (
    <>
      <EntryHeader />
      <div className={classes.body}>
        <Typography className={classes.title}>ACCEPTED</Typography>
        <div className={classes.titleBar} />
        <div className={classes.container}>
          <Typography className={classes.subtitle}>
            メール登録が完了しました
          </Typography>
          <Typography className={classes.text}>
            ご登録ありがとうございます。<br />
            ご利用いただける環境になりましたら、ご連絡いたします。<br />
            今後ともなにとぞAill goen（エール ゴエン）をよろしくお願いいたします。
          </Typography>
        </div>
        <SnsInfo />
        <Contact />
      </div>
      <EntryFooter />
    </>
  )
}
