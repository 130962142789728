import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  },
  circle: {
    color: '#1A1667 !important'
  },
}))

export default function ProgressCircular (props) {
  const { loading } = props
  const classes = useStyles()

  return (
    <Modal open={loading || false}>
      <Box className={classes.box}>
        <CircularProgress className={classes.circle} />
      </Box>
    </Modal>
  )
}

ProgressCircular.propTypes = {
  loading: PropTypes.bool
}
