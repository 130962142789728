import React from 'react'
import path from 'path'
import {
  Grid,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Api from 'commons/api'
import {
  formatSlashDate,
  getMediumItems,
  getMediumLabel,
  getAreaItems,
  getAreaLabel,
  httpNotFound
} from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import PartnerPage from 'components/parts/PartnerPage'

const styles = theme => ({
  newInputContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    width: '30%',
    maxWidth: theme.spacing(30),
    minWidth: theme.spacing(25),
  },
  formControlMedium: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    width: '40%',
    maxWidth: theme.spacing(30),
  },
  fullTextField: {
    marginTop: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    width: '40%',
  },
  create: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: theme.spacing(12),
    height: theme.spacing(5.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    wordBreak: 'break-all',
    wordWrap: 'break-all',
    marginBottom: theme.spacing(3),
    display: 'block',
    overflowX: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
  tableHead: {
    ...theme.styles.creditTableColor,
  },
  tableCell: {
    ...theme.styles.fontSize(10),
    minWidth: theme.spacing(11),
  },
  tableLink: {
    ...theme.styles.fontSize(10),
    width: theme.spacing(11),
    height: theme.spacing(5.5),
  },
  tableDate: {
    ...theme.styles.fontSize(10),
    width: theme.spacing(10),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableMedium: {
    ...theme.styles.fontSize(10),
    width: theme.spacing(12),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableCampaign: {
    ...theme.styles.fontSize(10),
    width: theme.spacing(12),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tableUrl: {
    ...theme.styles.fontSize(10),
    minWidth: theme.spacing(20),
  },
})

class PartnerLink extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmOpen = this.handleConfirmOpen.bind(this)
    this.handleConfirmClose = this.handleConfirmClose.bind(this)
    this._isMounted = false
    this.state = {
      medium: '',
      mediumError: false,
      campaign: '',
      campaignError: false,
      partnerLinks: [],
      deleteLink: null,
      openConfirm: false,
      partnerId: this.props.user ? this.props.user.partner_id : '',
      partnerIdError: false,
      areaId: 'all',
      areaIdError: false,
      shortId: '',
      shortIdError: false,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()
    if (this.props.admin) {
      this.loadPartners()
    } else {
      this.loadPartnerLinks(this.state.partnerId)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  handleChange (event) {
    this.setStateIfMounted({
      [event.target.name]: event.target.value,
      [event.target.name + 'Error']: false,
    })
    if (event.target.name === 'partnerId') {
      if (event.target.value.length > 0) {
        this.loadPartnerLinks(event.target.value)
      } else {
        this.setStateIfMounted({ partnerLinks: [] })
      }
    }
  }

  handleBlur (event) {
    if (event.target.name === 'campaign') {
      if (!this.validateCampaignInput(event.target.value)) {
        this.setStateIfMounted({ campaignError: true })
      }
    }
    if (event.target.name === 'shortId') {
      if (!this.validateShortIdInput(event.target.value)) {
        this.setStateIfMounted({ shortIdError: true })
      }
    }
  }

  validateCampaignInput (text) {
    if (text.length === 0) {
      return true
    }
    if (text.length > 100) {
      return false
    }
    let regexp = new RegExp('^[0-9_A-Za-z-]+$', 'i')
    if (!regexp.test(text)) {
      return false
    }
    return true
  }

  validateShortIdInput (text) {
    if (text.length === 0) {
      return false
    }
    if (text.length > 50) {
      return false
    }
    let regexp = new RegExp('^[0-9_A-Za-z-]+$', 'i')
    if (!regexp.test(text)) {
      return false
    }
    return true
  }

  async handleCreate () {
    if (this.state.medium.length === 0) {
      this.setStateIfMounted({ mediumError: true })
      return
    }

    if (!this.validateCampaignInput(this.state.campaign)) {
      this.setStateIfMounted({ campaignError: true })
      return
    }

    if (!this.validateShortIdInput(this.state.shortId)) {
      this.setStateIfMounted({ shortIdError: true })
      return
    }

    this.props.setLoading(true)
    try {
      let params = {
        partner_id: this.state.partnerId,
        medium: this.state.medium,
        area_id: this.state.areaId === 'all' ? null : this.state.areaId,
      }
      if (this.state.campaign.length > 1) {
        params['campaign'] = this.state.campaign
      }
      if (this.state.shortId.length > 1) {
        params['short_id'] = this.state.shortId
      }
      await Api.createPartnerLink(params)
      this.loadPartnerLinks(this.state.partnerId)
      this.setStateIfMounted({
        medium: '',
        mediumError: false,
        campaign: '',
        campaignError: false,
        areaId: 'all',
        areaIdError: false,
        shortId: '',
        shortIdError: false,
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async loadPartnerLinks (id) {
    this.props.setLoading(true)
    try {
      let partnerLinks = await Api.getPartnerLinks(id)
      this.setStateIfMounted({ partnerLinks: partnerLinks })
    } catch (error) {
      if (httpNotFound(error)) {
        this.setStateIfMounted({ partnerLinks: [] })
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  async handleDownload (link) {
    this.props.setLoading(true)
    try {
      let qr = await Api.createPresignedUrl(link.qr_s3_key)
      this.downloadFile(qr.url, this.getFileName(link.qr_s3_key))
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  downloadFile (url, filename) {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if(this.status === 200){
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(this.response, filename)
        } else {
          let urlUtil = window.URL || window.webkitURL
          let imgUrl = urlUtil.createObjectURL(this.response)
          let link = document.createElement('a')
          link.href = imgUrl
          link.download = filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
    xhr.send()
  }

  handleConfirmOpen (link) {
    this.setStateIfMounted({
      deleteLink: link,
      openConfirm: true,
    })
  }

  handleConfirmClose () {
    this.setStateIfMounted({
      deleteLink: null,
      openConfirm: false,
    })
  }

  async handleDelete () {
    this.props.setLoading(true)
    try {
      await Api.deletePartnerLink(this.state.deleteLink.id)
      this.loadPartnerLinks(this.state.partnerId)
      this.handleConfirmClose()
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  getFileName (filePath) {
    let filename = path.basename(filePath)
    return filename
  }

  createMediumSelect () {
    const { classes } = this.props
    return (
      <FormControl
        className={classes.formControlMedium}
        key='medium'
      >
        <InputLabel className={classes.inputLabel} error={this.state.mediumError}>
          メディア
        </InputLabel>
        <Select
          error={this.state.mediumError}
          value={this.state.medium}
          onChange={this.handleChange}
          name='medium'
          IconComponent={() => (
            <KeyboardArrowDown />
          )}
        >
          <MenuItem value={''}>{<em>未選択</em>}</MenuItem>
          {
            getMediumItems().map((val, i) => {
              return <MenuItem key={i} value={val.value}>{val.label}</MenuItem>
            })
          }
        </Select>
        <FormHelperText>
          ※必須
        </FormHelperText>
      </FormControl>
    )
  }

  createCampaignTextField () {
    const { classes } = this.props
    return (
      <TextField
        className={classes.fullTextField}
        label="キャンペーン"
        margin="normal"
        type="text"
        helperText="※任意　半角英数字で入力して下さい"
        key="campaign"
        name="campaign"
        value={this.state.campaign}
        error={this.state.campaignError}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    )
  }

  createPartnerLinksTable () {
    const { classes } = this.props
    return (
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">作成日</TableCell>
            <TableCell className={classes.tableCell} align="center">地域</TableCell>
            <TableCell className={classes.tableCell} align="center">メディア</TableCell>
            <TableCell className={classes.tableCell} align="center">キャンペーン</TableCell>
            <TableCell className={classes.tableUrl} align="center">URL</TableCell>
            <TableCell className={classes.tableCell} align="center">QRコード</TableCell>
            <TableCell className={classes.tableCell} align="center">削除</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.partnerLinks.map((link) => (
            <TableRow key={link.id}>
              <TableCell className={classes.tableDate} align="center">
                {formatSlashDate(link.created_at)}
              </TableCell>
              <TableCell className={classes.tableMedium}>
                {getAreaLabel(link.area_id)}
              </TableCell>
              <TableCell className={classes.tableMedium}>
                {getMediumLabel(link.medium)}
              </TableCell>
              <TableCell className={classes.tableCampaign}>
                {link.campaign === 'none' ? '' : link.campaign}
              </TableCell>
              <TableCell className={classes.tableUrl}>
                {link.url}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.tableLink}
                  disabled={link.qr_s3_key.length === 0}
                  onClick={() => this.handleDownload(link)}
                >
                  <u>ダウンロード</u>
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.tableLink}
                  onClick={() => this.handleConfirmOpen(link)}
                >
                  <u>削除</u>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  createConfirmDialog () {
    return (
      <ConfirmDialog
        open={this.state.openConfirm}
        onOk={this.handleDelete}
        onClose={this.handleConfirmClose}
        onCancel={this.handleConfirmClose}
        question="yes"
        title="削除"
        message={
          'メディア：' + getMediumLabel(this.state.deleteLink.medium) + '、' +
          'キャンペーン：' + (this.state.deleteLink.campaign === 'none' ? 'なし' : this.state.deleteLink.campaign) + '\n' +
          'このリンクを削除しますか？'
        }
      />
    )
  }

  createAreaSelect () {
    if (!this.state.partners) { return null }
    const { classes } = this.props
    return (
      <FormControl
        className={classes.formControlMedium}
        key='AreaId'
      >
        <InputLabel className={classes.inputLabel} error={this.state.areaIdError}>
          地域
        </InputLabel>
        <Select
          error={this.state.areaIdError}
          value={this.state.areaId}
          onChange={this.handleChange}
          name='areaId'
          IconComponent={() => (
            <KeyboardArrowDown />
          )}
        >
          {
            getAreaItems().map((val, i) => {
              return <MenuItem key={i} value={val.value}>{val.label}</MenuItem>
            })
          }
        </Select>
        <FormHelperText>
          ※必須
        </FormHelperText>
      </FormControl>
    )
  }

  createShortIdTextField () {
    const { classes } = this.props
    return (
      <TextField
        className={classes.fullTextField}
        label="短縮URL用ID"
        margin="normal"
        type="text"
        helperText="※必須　半角英数字で入力して下さい（最大50文字）"
        key="shortId"
        name="shortId"
        value={this.state.shortId}
        error={this.state.shortIdError}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    )
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <PartnerPage title="リンク作成" {...others}>
        {
          this.props.admin &&
          this.createPartnerSelect()
        }
        {
          this.state.partnerId.length > 0 &&
          <div>
            <Grid container
              direction='row'
              alignItems="flex-start"
              className={classes.newInputContainer}
            >
              { this.createAreaSelect() }
              { this.createShortIdTextField() }
            </Grid>
            <Grid container
              direction='row'
              alignItems="flex-start"
              className={classes.newInputContainer}
            >
              { this.createMediumSelect() }
              { this.createCampaignTextField() }
              <Button
                variant="contained"
                color="secondary"
                className={classes.create}
                disabled={this.state.medium.length === 0}
                onClick={this.handleCreate}
              >
                新規作成
              </Button>
            </Grid>
          </div>
        }
        {
          this.state.partnerLinks.length > 0 &&
          this.createPartnerLinksTable()
        }
        {
          this.state.deleteLink &&
          this.createConfirmDialog()
        }
      </PartnerPage>
    )
  }
}

export default withStyles(styles)(PartnerLink)
