import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import GiftCard from 'images/gift_card_50_off.png'
import SerifForFree from 'images/gift_serif_for_free.png'
import SerifForStandard from 'images/gift_serif_for_standard.png'
import SerifForTrial from 'images/gift_serif_for_trial.png'

const useStyles = makeStyles(theme => ({
  foryou: {
    fontSize: 12,
    lineHeight: 1,
    display: 'block',
    marginBottom: theme.spacing(1.5),
  },
  content: {
    textAlign: 'center',
  },
  subtitle: {
    lineHeight: '25px',
    marginBottom: theme.spacing(3),
  },
  serif: {
    marginBottom: theme.spacing(0.5),
  },
  gift: {
    marginBottom: theme.spacing(4.5),
  },
  notice: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: theme.spacing(5.5),
  },
}))

const SerifImgMap = {
  // 無料トライアル期間：STANDARD_INITIAL
  [Const.planTypes.STANDARD_INITIAL]: SerifForTrial,
  // スタンダードプラン：STANDARD
  [Const.planTypes.STANDARD]: SerifForStandard,
  // フリープラン：TRIAL
  [Const.planTypes.TRIAL]: SerifForFree,
}

export default function FeeRevisionDialog (props) {
  const { open, onClose, plan } = props
  const classes = useStyles()

  const SerifImg = SerifImgMap[plan?.id]
  if (!SerifImg) { return null }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          <span className={classes.foryou}>for you!</span>
          特別ギフトが届きました
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography className={classes.subtitle} variant="subtitle1">
            スタンダードプランが<br />
            <span style={{ fontSize: 20 }}>ずっと50%OFF</span>で使える<br />
            とてもお得なギフト！
          </Typography>
          <img className={classes.serif} src={SerifImg} width={230} height={46} />
          <img className={classes.gift} src={GiftCard} width={215} height={157} />
          <Typography className={classes.notice} variant="body2">
            ＊1月31日時点でスタンダードプランをご利用中の方が対象となります<br />
            ＊フリープランへのプラン変更、ないしは退会された場合は、キャンペーン適用外となりますのでご注意ください
          </Typography>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FeeRevisionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  plan: PropTypes.object,
}
