import React from 'react'
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Api from 'commons/api'
import { isEmpty, httpConflict } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import PartnerPage from 'components/parts/PartnerPage'

const styles = theme => ({
  subTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  newInputContainer: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    width: '30%',
    maxWidth: theme.spacing(30),
    minWidth: theme.spacing(25),
  },
  formControlMaster: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    width: theme.spacing(12),
  },
  mailaddressTextField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: 0,
    width: '40%',
    minWidth: theme.spacing(35),
  },
  lastNameTextField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: 0,
    width: theme.spacing(10),
  },
  firstNameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: 0,
    width: theme.spacing(10),
  },
  create: {
    fontSize: 10,
    fontWeight: 'bold',
    width: theme.spacing(8),
    height: theme.spacing(5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  table: {
    wordBreak: 'break-all',
    wordWrap: 'break-all',
    marginBottom: theme.spacing(3),
    display: 'block',
    overflowX: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
  tableHead: {
    ...theme.styles.creditTableColor,
  },
  tableCell: {
    fontSize: 10,
    minWidth: theme.spacing(10),
  },
  tableCellMailAddress: {
    fontSize: 10,
    minWidth: theme.spacing(15),
  },
  tableLink: {
    fontSize: 10,
    minWidth: theme.spacing(8),
    height: theme.spacing(5.5),
  },
  message: {
    ...theme.styles.redHighlightColor,
  },
})

class PartnerUsers extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleDeleteConfirmOpen = this.handleDeleteConfirmOpen.bind(this)
    this.handleConfirmClose = this.handleConfirmClose.bind(this)
    this._isMounted = false
    this.state = {
      mailaddress: '',
      mailaddressError: false,
      lastName: '',
      lastNameError: false,
      firstName: '',
      firstNameError: false,
      master: false,
      masterError: false,
      partnerUsers: [],
      update: false,
      updateUser: null,
      deleteUser: null,
      createUser: null,
      openConfirm: false,
      partnerId: this.props.user ? this.props.user.partner_id : '',
      partnerIdError: false,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()
    if (this.props.admin) {
      this.loadPartners()
    } else {
      this.loadPartnerUsers(this.state.partnerId)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  handleChange (event) {
    this.setStateIfMounted({
      [event.target.name]: event.target.value,
      [event.target.name + 'Error']: false,
    })
    if (event.target.name === 'partnerId') {
      if (event.target.value.length > 0) {
        this.loadPartnerUsers(event.target.value)
      } else {
        this.setStateIfMounted({ partnerUsers: [] })
      }
      this.handleCancel()
    }
  }

  validateMailAddressInput (text) {
    if (isEmpty(text)) {
      return false
    }
    if (!text.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      return false
    }
    if (text.length > 255) {
      return false
    }
    return true
  }

  async handleCreate () {
    if (!this.validateMailAddressInput(this.state.mailaddress)) {
      this.setStateIfMounted({ mailaddressError: true })
      return
    }
    if (this.state.lastName.length === 0) {
      this.setStateIfMounted({ lastNameError: true })
      return
    }
    if (this.state.firstName.length === 0) {
      this.setStateIfMounted({ firstNameError: true })
      return
    }

    this.props.setLoading(true)
    try {
      let params = {
        partner_id: this.state.partnerId,
        mail_address: this.state.mailaddress,
        last_name: this.state.lastName,
        first_name: this.state.firstName,
        master: this.state.master,
      }
      let user = await Api.createPartnerUser(params)
      this.createConfirmOpen(user)
      this.loadPartnerUsers(this.state.partnerId)
      this.handleCancel()
    } catch (error) {
      if (httpConflict(error)) {
        this.showErrorMessage('このメールアドレスは登録済みです。')
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  createConfirmOpen (user) {
    this.setStateIfMounted({
      createUser: user,
      openConfirm: true,
    })
  }

  async loadPartnerUsers (id) {
    this.props.setLoading(true)
    try {
      let partnerUsers = await Api.getPartnerUsers(id)
      this.setStateIfMounted({ partnerUsers: partnerUsers })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleChangeName (user) {
    this.setStateIfMounted({
      update: true,
      updateUser: user,
      mailaddress: user.mail_address,
      lastName: user.last_name,
      firstName: user.first_name,
      master: user.master,
    })
  }

  async handleUpdate () {
    if (this.state.lastName.length === 0) {
      this.setStateIfMounted({ lastNameError: true })
      return
    }
    if (this.state.firstName.length === 0) {
      this.setStateIfMounted({ firstNameError: true })
      return
    }

    this.props.setLoading(true)
    try {
      let params = {
        last_name: this.state.lastName,
        first_name: this.state.firstName,
        master: this.state.master,
      }
      await Api.updatePartnerUser(this.state.updateUser.id, params)
      this.loadPartnerUsers(this.state.partnerId)
      this.handleCancel()
    } catch (error) {
      if (httpConflict(error)) {
        this.showErrorMessage('管理者を一般に変更はできません。')
      } else {
        this.handleApiError(error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  handleCancel () {
    this.setStateIfMounted({
      update: false,
      mailaddress: '',
      lastName: '',
      firstName: '',
      master: false,
    })
  }

  handleDeleteConfirmOpen (user) {
    this.setStateIfMounted({
      deleteUser: user,
      openConfirm: true,
    })
  }

  handleConfirmClose () {
    this.setStateIfMounted({
      deleteUser: null,
      createUser: null,
      openConfirm: false,
    })
  }

  async handleDelete () {
    this.props.setLoading(true)
    try {
      await Api.deletePartnerUser(this.state.deleteUser.id)
      this.loadPartnerUsers(this.state.partnerId)
      this.handleConfirmClose()
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  isNotCreate () {
    if (this.state.mailaddress.length === 0) { return true }
    if (this.state.lastName.length === 0) { return true }
    if (this.state.firstName.length === 0) { return true }
    return false
  }

  createMasterSelect () {
    const { classes } = this.props
    return (
      <FormControl
        className={classes.formControlMaster}
        key='master'
      >
        <InputLabel className={classes.inputLabel} error={this.state.masterError}>
          権限
        </InputLabel>
        <Select
          error={this.state.masterError}
          value={this.state.master}
          onChange={this.handleChange}
          name='master'
          IconComponent={() => (
            <KeyboardArrowDown />
          )}
        >
          <MenuItem key="master_true" value={true}>管理者</MenuItem>
          <MenuItem key="master_false" value={false}>一般</MenuItem>
        </Select>
      </FormControl>
    )
  }

  createTextField (name) {
    const { classes } = this.props
    let label = ''
    let type = 'text'
    let disabled = false
    switch (name) {
      case 'mailaddress':
        label = 'メールアドレス'
        type = 'email'
        if (this.state.update) {
          disabled = true
        }
        break
      case 'lastName':
        label = '苗字'
        break
      case 'firstName':
        label = '名前'
        break
      default:
        break
    }
    return (
      <TextField
        className={classes[`${name}TextField`]}
        label={label}
        margin="normal"
        type={type}
        key={name}
        name={name}
        value={this.state[name]}
        error={this.state[`${name}_error`]}
        disabled={disabled}
        onChange={this.handleChange}
      />
    )
  }

  createPartnerUsersTable () {
    const { classes } = this.props
    return (
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCellMailAddress} align="left">メールアドレス</TableCell>
            <TableCell className={classes.tableCell} align="left">苗字</TableCell>
            <TableCell className={classes.tableCell} align="left">名前</TableCell>
            <TableCell className={classes.tableCell} align="center">権限</TableCell>
            <TableCell className={classes.tableCell} align="center">変更</TableCell>
            <TableCell className={classes.tableCell} align="center">削除</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.partnerUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell className={classes.tableCellMailAddress} align="left">
                {user.mail_address}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {user.last_name}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {user.first_name}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {user.master ? '管理者' : '一般'}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.tableLink}
                  onClick={() => this.handleChangeName(user)}
                >
                  <u>変更</u>
                </Button>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.tableLink}
                  onClick={() => this.handleDeleteConfirmOpen(user)}
                >
                  <u>削除</u>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  createDeleteConfirmDialog () {
    return (
      <ConfirmDialog
        open={this.state.openConfirm}
        onOk={this.handleDelete}
        onClose={this.handleConfirmClose}
        onCancel={this.handleConfirmClose}
        question="yes"
        title="削除"
        message={
          '苗字：' + this.state.deleteUser.last_name + '、' +
          '名前：' + this.state.deleteUser.first_name + '\n' +
          'このユーザーを削除しますか？'
        }
      />
    )
  }

  createConfirmDialog () {
    const { classes } = this.props
    let msgs= []
    msgs.push(<span key="msg1">{'新しいユーザーを作成しました。'}<br /></span>)
    msgs.push(<span key="msg2">{'苗字：' + this.state.createUser.last_name}<br /></span>)
    msgs.push(<span key="msg3">{'名前：' + this.state.createUser.first_name}<br /></span>)
    msgs.push(<span key="msg4">{'ログインパスワード：' + this.state.createUser.password}<br /><br /></span>)
    msgs.push(<span key="msg5" className={classes.message}>{'ログインパスワードは必ずメモしてください。'}<br /></span>)
    msgs.push(<span key="msg6" className={classes.message}>{'ログイン後いつでも変更可能です。'}</span>)
    return (
      <ConfirmDialog
        open={this.state.openConfirm}
        onClose={this.handleConfirmClose}
        onCancel={this.handleConfirmClose}
        confirm="yes"
        title="ログインパスワード"
        message={msgs}
      />
    )
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <PartnerPage title="ユーザー管理" {...others}>
        {
          this.props.admin &&
          this.createPartnerSelect()
        }
        {
          this.state.partnerId.length > 0 &&
          <div>
            <Grid container
              direction='row'
              alignItems="flex-end"
              className={classes.newInputContainer}
            >
              { this.createTextField('mailaddress') }
              { this.createTextField('lastName') }
              { this.createTextField('firstName') }
              { this.createMasterSelect() }
            </Grid>
            <Grid container
              direction='row'
              alignItems="flex-end"
              className={classes.newInputContainer}
            >
              {
                !this.state.update ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.create}
                    disabled={this.isNotCreate()}
                    onClick={this.handleCreate}
                  >
                    新規作成
                  </Button>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.create}
                      onClick={this.handleUpdate}
                    >
                      更新
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.create}
                      onClick={this.handleCancel}
                    >
                      キャンセル
                    </Button>
                  </div>
                )
              }
            </Grid>
          </div>
        }
        {
          this.state.partnerUsers.length > 0 &&
          this.createPartnerUsersTable()
        }
        {
          this.state.deleteUser &&
          this.createDeleteConfirmDialog()
        }
        {
          this.state.createUser &&
          this.createConfirmDialog()
        }
      </PartnerPage>
    )
  }
}

export default withStyles(styles)(PartnerUsers)
