import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// 初回ナビ
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import firstEntryNaviFinImg from 'images/tutorial/firstEntryNaviFinImg.png'

const useStyles = makeStyles(theme => ({
  snackbar: {
    top: 'calc(50% - 28px)',
  },
  snackbarContent: {
    maxWidth: 289,
    backgroundColor: 'rgba(66, 59, 199, 0.55)',
    borderRadius: 29,
    backdropFilter: 'blur(10px)',
    boxShadow: 'none',
  },
  snackbarMessage: {
    textAlign: 'center',
    width: '100%',
    fontSize: 15,
    fontWeight: 600,
  },
  img: {
    width: 160,
    display: 'block',
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  okButton: {
    width: 218,
    height: 40,
    color: '#ffffff',
    background: '#423BC7',
  },
}))

export default function RequestSuccessBar (props) {
  const { requestSuccessMessage, onCloseRequestSuccessMessage, firstNaviFlag } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [firstNavi, setFirstNavi] = useState(null)

  const closeRequestSuccessMessage = () => {
    let handler = onCloseRequestSuccessMessage
    props.setRequestSuccessMessage(null, null, false)
    if (handler) { handler() }
  }

  useEffect(()=> {
    setFirstNavi(firstNaviFlag)
    setOpen(requestSuccessMessage ? true : false)
    if (requestSuccessMessage) {
      setText(requestSuccessMessage)
    }
  }, [requestSuccessMessage, firstNaviFlag])

  return (
    <>
      {firstNavi !== null && (
        firstNavi ? (
          <DialogThemeProvider color="secondary">
            <Dialog open={open}>
              <DialogTitle disableTypography>「いいね！」完了</DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  他の人も見てみよう！
                </Typography>
                <img className={classes.img} src={firstEntryNaviFinImg} alt="" />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={closeRequestSuccessMessage}>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </DialogThemeProvider>
        ) : (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={closeRequestSuccessMessage}
            autoHideDuration={3000}
            classes={{ anchorOriginTopCenter: classes.snackbar }}
          >
            <SnackbarContent
              className={classes.snackbarContent}
              classes={{ message: classes.snackbarMessage }}
              message={text}
            />
          </Snackbar>
        )
      )}
    </>
  )
}

RequestSuccessBar.propTypes = {
  requestSuccessMessage: PropTypes.string,
  onCloseRequestSuccessMessage: PropTypes.func,
  firstNaviFlag: PropTypes.bool,
  setRequestSuccessMessage: PropTypes.func,
}