import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import PhotoIssueDialog from 'components/photo-tutorial/PhotoIssueDialog'
import Rank1Img from 'images/advice_ranking_1.png'
import Rank2Img from 'images/advice_ranking_2.png'
import Rank3Img from 'images/advice_ranking_3.png'
import FemaleTitleImg from 'images/advice_title_for_female.png'
import MaleTitleImg from 'images/advice_title_for_male.png'
import WhiteBoard from 'images/survey/white_board.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import Komainu from 'images/tutorial/komainu.png'

const useStyles = makeStyles(theme => ({
  img: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 12,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: '0.3px',
    marginTop: theme.spacing(2.75),
    marginBottom: theme.spacing(2),
    '& img:nth-of-type(1)': {
      position: 'absolute',
      top: 260,
      left: 50,
    },
    '& img:nth-of-type(2)': {
      position: 'absolute',
      top: 260,
      right: 53,
    },
  },
  whiteboard: {
    paddingTop: theme.spacing(2),
    width: 230,
    height: 166,
    backgroundImage: `url(${WhiteBoard})`,
    backgroundSize: 'cover',
    '& p': {
      color: '#423BC7',
      textAlign: 'center',
    },
    '& p:nth-of-type(1)': {
      fontSize: 12,
      lineHeight: 1,
      marginBottom: theme.spacing(1),
    },
    '& p:nth-of-type(2)': {
      fontSize: 18,
      lineHeight: 1,
      marginBottom: theme.spacing(1),
    },
    '& p:nth-of-type(3)': {
      fontSize: 17,
      lineHeight: 1,
      marginBottom: theme.spacing(1),
      '& span': {
        fontSize: 34,
        fontWeight: 700,
        fontFamily: '"ヒラギノ角ゴシック"',
        letterSpacing: '-2.72px',
      },
    },
    '& p:nth-of-type(4)': {
      fontSize: 9,
      lineHeight: '13.5px',
      marginBottom: theme.spacing(1),
    },
  },
  komainu: {
    width: 158,
    height: 72,
    display: 'block',
    marginTop: theme.spacing(-3.25),
    marginRight: 'auto',
    marginLeft: 'auto',
    objectFit: 'cover',
    objectPosition: '50% 0',
  },
  actions: {
    paddingTop: 0,
  },
}))

export default function FocusPointAdviceDialog (props) {
  const { open, onClose, user } = props
  const classes = useStyles()
  const [openPhotoIssueDialog, setOpenPhotoIssueDialog] = useState(false)

  const title = user.sex === 'male' ? MaleTitleImg : FemaleTitleImg

  return (
    <>
      <DialogThemeProvider color="advice">
        <Dialog open={open}>
          <DialogCloseButton onClick={onClose} style={{ color: '#1A1667' }} />
          <DialogTitle disableTypography>
            <img src={title} width={230} height={45} />
          </DialogTitle>
          <DialogContent>
            <img className={classes.img} src={Rank1Img} width={230} height={42} />
            <img className={classes.img} src={Rank2Img} width={230} height={42} />
            <img className={classes.img} src={Rank3Img} width={230} height={42} />
            <Typography className={classes.text} variant="body1">
              <img src={FootAImage} width={16} height={16} alt="" />
              狛犬のちょっとアドバイス
              <img src={FootUnImage} width={16} height={16} alt="" />
            </Typography>
            <div className={classes.whiteboard}>
              <Typography variant="body1">メイン写真が笑顔の人は</Typography>
              <Typography variant="body1">チャット開通人数</Typography>
              <Typography variant="body1"><span>1.7</span>倍</Typography>
              <Typography variant="body2">
                ＊Aill goen内の写真評価で、<br />
                写真が無表情の人と比較した場合
              </Typography>
            </div>
            <img className={classes.komainu} src={Komainu} />
          </DialogContent>
          <DialogActions className={classes.actions} disableSpacing>
            <Button variant="contained" onClick={() => {
              onClose()
              setOpenPhotoIssueDialog(true)
            }}>
              自分の見え方を確認する
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
      <PhotoIssueDialog
        {...props}
        open={openPhotoIssueDialog}
        onClose={() => setOpenPhotoIssueDialog(false)}
        user={user}
        type="Home"
      />
    </>
  )
}

FocusPointAdviceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
}
