import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import BoostBadge from 'components/movie/BoostBadge'
import TheaterTitle from 'components/movie/TheaterTitle'
import TrailerList from 'components/movie/TrailerList'
import BaseComponent from 'components/parts/BaseComponent'
import Komainu from 'images/Komainu/firstNaviKomainu.png'
import LongCurtain from 'images/movie/theater_curtain_long.png'
import ShortCurtain from 'images/movie/theater_curtain_short.png'
import TheaterKomainu from 'images/movie/theater_komainu.png'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#1A1667',
    textAlign: 'center',
    paddingTop: theme.spacing(13),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    backgroundImage: `url(${ShortCurtain}), url(${LongCurtain})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto, 200% 1400px',
    backgroundPositionX: 'center',
    backgroundPositionY: 'top, -120px',
  },
  theaterImg: {
    marginBottom: theme.spacing(6),
  },
  komainuImg: {
    marginBottom: theme.spacing(-3.5),
  },
  stepText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(2.5),
  },
  stepBox: {
    width: '100%',
    maxWidth: 289,
    borderRadius: 12,
    background: '#423BC7',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative',
  },
  stepBoxText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    whiteSpace: 'pre-wrap',
  },
  badge: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: 280,
  },
  stepArrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 9.5px 0 9.5px',
    borderColor: '#DBDCFE transparent transparent transparent',
  },
  text: {
    fontSize: 22,
    fontWeight: 600,
    color: '#ffffff',
    marginTop: theme.spacing(5),
  },
  containedButton: {
    width: 230,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    color: '#423BC7',
    background: '#ffffff',
    marginTop: theme.spacing(2.5),
  },
}))

export default function TheaterTop (props) {
  const classes = useStyles()
  const [movieEvents, setMovieEvents] = useState([])

  useEffect(() => {
    (async () => {
      BaseComponent.addVisitPageLog()
      props.setLoading(true)
      try {
        const res = await Api.getMovieEvents('trailer')
        setMovieEvents(res)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const stepTextList = [
    '映画予告の視聴',
    'アンケートへの回答で\n価値観の近い方1名のご紹介',
    '感想の記入で、\n更に1名のご紹介',
  ]

  const createArrow = () => (
    <div className={classes.stepArrow} />
  )

  const createStepBox = (text, index) => (
    <React.Fragment key={index}>
      <div className={classes.stepBox}>
        <Typography className={classes.stepBoxText}>
          {text}
        </Typography>
        {index === 2 && (
          <span className={classes.badge}>
            <BoostBadge plus subText="ご紹介" />
          </span>
        )}
      </div>
      {index !== 2 && createArrow()}
    </React.Fragment>
  )

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <TheaterTitle detail />
      <img
        className={classes.theaterImg}
        src={TheaterKomainu}
        width={232}
      />
      <Typography className={classes.stepText}>
        イベント参加の3ステップ
      </Typography>
      <img className={classes.komainuImg} src={Komainu} />
      {stepTextList.map((text, index) => createStepBox(text, index))}
      <Typography className={classes.text}>
        映画を通じた<br />
        運命の出会いをしよう！
      </Typography>
      <TrailerList movieEvents={movieEvents} {...props} />
      <Button
        className={classes.containedButton}
        variant="contained"
        onClick={() => props.setScreen('Home')}
      >
        シアターを退場する
      </Button>
    </Grid>
  )
}

TheaterTop.propTypes = {
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
