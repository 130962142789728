import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#1A1667',
  },
  subtitle: {
    width: '100%',
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    background: '#F4F7FE',
    lineHeight: 1,
    textAlign: 'left',
    borderRadius: 4,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  text: {
    width: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    textAlign: 'left',
    letterSpacing: 0.7,
  },
  attention: {
    color: '#423BC7',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    '& svg': {
      width: 24,
    },
  },
  label: {
    color: '#616AFA',
  },
  radio: {
    '& svg': {
      width: 24,
    },
  },
  faceAvatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  nickName: {
    width: 100,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  containedButton: {
    background: '#616AFA !important',
  },
  textButton: {
    color: '#616AFA !important',
  },
}))

export default function PurchaseConfirmDialog (props) {
  const { open, offer, count, onClose, onConfirmOK } = props
  const classes = useStyles()

  if (!offer) { return }

  const map = {
    additional_matching: {
      title: '追加紹介',
      info: '購入完了後、アプリ内の紹介画面に、追加紹介者3名が表示されます。',
      note: '＊購入後に紹介異性が退会された場合は、通常紹介時と同様に一部条件を除き、追加の紹介はございません。詳しくはアプリ内Q&Aをご確認ください。',
    }
  }

  const title = map[offer.offer_type].title
  const info = map[offer.offer_type].info
  const note = map[offer.offer_type].note

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>購入内容の確認</DialogTitle>
        <DialogContent>
          <Typography className={classes.subtitle} variant="subtitle1">選択中のオプション</Typography>
          <Typography className={classes.text} variant="body1">{title}</Typography>
          {1 < count && (
            <>
              <Typography className={classes.subtitle} variant="subtitle1">回数</Typography>
              <Typography className={classes.text} variant="body1">{count}回</Typography>
            </>
          )}
          <Typography className={classes.subtitle} variant="subtitle1">料金</Typography>
          <Typography className={classes.text} variant="body1">
            {offer.price * count}円（税込）
            {1 < count && `\n（＊${offer.price}円×${count}）`}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">利用方法</Typography>
          <Typography className={classes.text} variant="body1">{info}</Typography>
          <Typography className={classes.subtitle} variant="subtitle1">注意事項</Typography>
          <Typography className={classes.text} variant="body1">{note}</Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button
            className={classes.containedButton}
            variant="contained"
            onClick={onConfirmOK}
          >
            購入する
          </Button>
          <Button className={classes.textButton} variant="text" onClick={onClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

PurchaseConfirmDialog.propTypes = {
  open: PropTypes.bool,
  offer: PropTypes.object,
  count: PropTypes.number,
  onConfirmOK: PropTypes.func,
  onClose: PropTypes.func,
}
