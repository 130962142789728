import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeWidth: 1.3,
    strokeMiterlimit: 10,
  },
  cls2: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeWidth: 1.3,
    strokeLinejoin: 'round',
  },
  cls3: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeWidth: 1.3,
    strokeMiterlimit: 10,
    strokeLinecap: 'round',
  },
}

class RequestOffIcon extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <SvgIcon viewBox="0 0 18.948 18.727">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="18.948" height="18.727" viewBox="0 0 18.948 18.727">
          <g id="グループ_745" data-name="グループ 745" transform="translate(-173.35 -7.35)">
            <circle id="楕円形_25" data-name="楕円形 25" className={classes.cls1} cx="3.417" cy="3.417" r="3.417" transform="translate(176.878 10.173)"/>
            <path id="パス_156" data-name="パス 156" className={classes.cls2} d="M110.394,239.049a6.3,6.3,0,0,0-12.591,0Z" transform="translate(76.197 -213.623)"/>
            <g id="グループ_382" data-name="グループ 382" transform="translate(185.67 8)">
              <line id="線_7" data-name="線 7" className={classes.cls3} y2="6.643" transform="translate(3.006)"/>
              <line id="線_8" data-name="線 8" className={classes.cls3} x1="5.978" transform="translate(0 3.321)"/>
            </g>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

RequestOffIcon.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(RequestOffIcon)