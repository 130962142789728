import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReplyIcon from 'images/icon_reply_2.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    maxWidth: (window.innerWidth || 375) - 117,
    minHeight: 48,
    maxHeight: 62,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    backgroundColor: '#F5F5F5',
    borderRadius: 25,
    padding: theme.spacing(2),
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(-1.25),
    marginLeft: theme.spacing(1),
    opacity: 0.7,
    position: 'relative',
    zIndex: -1,
  },
  progress: {
    color: '#616AFA',
  },
  leftLabel: {
    marginBottom: theme.spacing(0.5),
  },
  rightLabel: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
  },
  labelText: {
    fontSize: 11,
    fontWeight: 300,
    color: '#9D9D9E',
    letterSpacing: '0.28px',
    lineHeight: 1,
    textAlign: 'right',
    marginLeft: theme.spacing(0.5),
  },
  text: {
    fontSize: 11,
    fontWeight: 300,
    color: '#9D9D9E',
    letterSpacing: '0.28px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
}))

export default function ReplySpeech (props) {
  const { user, friend, message, speech } = props
  const classes = useStyles()

  if (!message?.reply_to_message) { return null }

  const replyToMessage = message.reply_to_message
  const deleted = replyToMessage.deleted || replyToMessage.invisible

  const getLabelText = () => {
    const myMessage = (message.user_id === user.id)
    const selfReply = (message.user_id === replyToMessage.user_id)
    if (deleted) {
      if (myMessage) {
        return `${selfReply ? '自分の' : ''}削除済みのメッセージに返信しました`
      } else {
        if (selfReply) {
          return `${friend.nick_name}さんが自分の削除済みのメッセージに返信しました`
        } else {
          return '削除済みのメッセージに返信がありました'
        }
      }
    }
    if (myMessage) {
      const toName = selfReply ? '自分' : `${friend.nick_name}さん`
      return `${toName}に返信しました`
    }
    if (selfReply) {
      return `${friend.nick_name}さんが自分に返信しました`
    } else {
      return `${friend.nick_name}さんから返信がありました`
    }
  }

  const createLabel = () => {
    return (
      <Grid className={(speech === 'left') ? classes.leftLabel : classes.rightLabel}
        container justifyContent="center" alignItems="flex-end" data-testid="reply-label"
      >
        <img src={ReplyIcon} width={14} height={14} />
        <Typography className={classes.labelText}>
          {getLabelText()}
        </Typography>
      </Grid>
    )
  }

  const scrollIntoView = () => {
    try {
      const el = document.getElementById(`message-${replyToMessage.id}`)
      if (el) { el.scrollIntoView({ behavior: 'smooth' }) }
    } catch {
      // ignore
    }
  }

  return (
    <div data-testid="reply-speech">
      {createLabel()}
      <Grid container justifyContent={speech === 'left' ? 'flex-start' : 'flex-end'} onClick={scrollIntoView}>
        <div className={classes.root}>
          <Typography className={classes.text}>
            {deleted ? '削除済みのメッセージです' : replyToMessage.message}
          </Typography>
        </div>
      </Grid>
    </div>
  )
}

ReplySpeech.propTypes = {
  user: PropTypes.object,
  friend: PropTypes.object,
  message: PropTypes.object,
  speech: PropTypes.string.isRequired,
}
