import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import * as Storage from 'commons/storage'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  messageText: {
    ffontSize: 16,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  message: {
    color: '#414141',
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
    border: 'solid 1px #AFB7F8',
    borderRadius: 8,
  },
  countText: {
    textAlign: 'right',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textError: {
    color: 'red',
  },
  error: {
    borderColor: 'red',
  },
}))

export default function RequestMessageInput (props) {
  const { friend, value, onChange, error } = props
  const classes = useStyles()
  const [errorCheck, setErrorCheck] = useState(false)

  useEffect(() => {
    const lastMessage = Storage.getFriendEntryMessage(friend.id)
    const sentMessage = friend?.entry_request_message
    const msg = friend.type === 'friend_applying' ? sentMessage : lastMessage
    onChange(msg || '')
    setErrorCheck(msg && friend.type !== 'friend_applying')
  }, [])

  const saveMessage = _.throttle(msg => Storage.setFriendEntryMessage(props.friend.id, msg), 1000)

  const onChangeMessage = (event) => {
    const msg = event.target.value
    saveMessage(msg)
    onChange(msg)
    setErrorCheck(msg.length === 0 ? false : true)
  }

  return (
    <div id="entry-tutorial-target" className={classes.root}>
      <Typography className={classes.messageText}>
        {friend.type === 'friend_applying' ? '送信したメッセージ' : '一言メッセージを添えて送ろう！'}
      </Typography>
      <InputBase
        className={classes.message}
        value={value}
        placeholder="メッセージを入力（10文字以上必須）"
        onChange={onChangeMessage}
        error={errorCheck && error}
        classes={{ error: classes.error }}
        multiline
        rows="10"
        disabled={friend.type === 'friend_applying'}
      />
      <Typography className={`${classes.countText} ${(errorCheck && error) && classes.textError}`}>
        {value?.length || 0}/{Const.maxChatMessageLength}
      </Typography>
    </div>
  )
}

RequestMessageInput.propTypes = {
  friend: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
}
