import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Typography from '@material-ui/core/Typography'
import PrevIcon from '@material-ui/icons/ArrowBackIos'
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import SortIcon from '@material-ui/icons/SwapVert'
import Api from 'commons/api'
import { isEmpty } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ShopCell from 'components/parts/ShopCell.jsx'

const styles = theme => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(3),
    minHeight: window.innerHeight + 'px',
    ...theme.styles.shopListColor,
  },
  condText: {
    width: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    textAlign: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    width: '100%',
    textAlign: 'left',
  },
  formControl: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    paddingTop: theme.spacing(0.5),
    width: '45%',
    fontSize: 5,
  },
  table: {
    overflow: 'auto',
    width: '100%',
    padding: 0,
  },
  sortContainer: {
    margin: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
    width: '100%',
  },
  sortItem: {
    width: theme.spacing(13),
    height: theme.spacing(4),
    textAlign: 'center',
  },
  sortButton: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    width: theme.spacing(13),
    height: theme.spacing(4),
    padding: 0,
  },
  sortTitle: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
  },
  buttonContainer: {
    textAlign: 'right',
    width: '100%',
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    padding: `0 ${theme.spacing(0.5)}px `,
    margin: `0 ${theme.spacing(1)}px`,
  },
  emptyText: {
    opacity: 0.34,
    width: '100%',
    textAlign: 'center',
  },
})

export class Shops extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.onClick = this.onClick.bind(this)
    this.clickSortButton = this.clickSortButton.bind(this)
    this.clickPrevButton = this.clickPrevButton.bind(this)
    this.clickNextButton = this.clickNextButton.bind(this)

    this._isMounted = false

    this.state = {
      pref: this.props.shopSearch?.pref || null,
      station: this.props.shopSearch?.station || null,
      category: this.props.shopSearch?.category || null,
      open_time: this.props.shopSearch?.open_time || null,
      take_out: this.props.shopSearch?.take_out || null,
      sort_shop_rating: this.props.shopSearch?.sort_shop_rating || false,
      sort_shop_rating_reverse: this.props.shopSearch?.sort_shop_rating_reverse || false,
      sort_budget: this.props.shopSearch?.sort_budget || false,
      sort_budget_reverse: this.props.shopSearch?.sort_budget_reverse || false,
      sort_open_time: this.props.shopSearch?.sort_open_time || false,
      sort_open_time_reverse: this.props.shopSearch?.sort_open_time_reverse || false,
      pref_items: [],
      station_items: [],
      category_items: [],
      open_time_items: [],
      shops: this.props.shopSearch?.shops || [],
      next_page_key: this.props.shopSearch?.next_page_key || null,
      page_Keys: this.props.shopSearch?.page_Keys || [],
      next_disabled: isNullOrUndefined(this.props.shopSearch?.next_disabled) ? true : this.props.shopSearch?.next_disabled,
      prev_disabled: isNullOrUndefined(this.props.shopSearch?.prev_disabled) ? true : this.props.shopSearch?.prev_disabled,
      station_error: false,
    }
  }

  componentWillMount () {
    if (!this.props.shopSearch) {
      this.props.setScreen('/ShopSearch')
    }
    this.createPrefItems()
    this.createStationItems(this.state.pref)
    this.createCategoryItems()
    this.createOpenTimeItems()
    if (this.state.shops.length === 0) {
      this.getShopsList()
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps, prevState) {
    if (
      JSON.stringify(this.state.pref) !== JSON.stringify(prevState.pref) ||
      JSON.stringify(this.state.station) !== JSON.stringify(prevState.station) ||
      JSON.stringify(this.state.category) !== JSON.stringify(prevState.category) ||
      JSON.stringify(this.state.open_time) !== JSON.stringify(prevState.open_time) ||
      JSON.stringify(this.state.take_out) !== JSON.stringify(prevState.take_out) ||
      JSON.stringify(this.state.sort_shop_rating) !== JSON.stringify(prevState.sort_shop_rating) ||
      JSON.stringify(this.state.sort_shop_rating_reverse) !== JSON.stringify(prevState.sort_shop_rating_reverse) ||
      JSON.stringify(this.state.sort_budget) !== JSON.stringify(prevState.sort_budget) ||
      JSON.stringify(this.state.sort_budget_reverse) !== JSON.stringify(prevState.sort_budget_reverse) ||
      JSON.stringify(this.state.sort_open_time) !== JSON.stringify(prevState.sort_open_time) ||
      JSON.stringify(this.state.sort_open_time_reverse) !== JSON.stringify(prevState.sort_open_time_reverse)
    ) {
      if (
        JSON.stringify(this.state.pref) !== JSON.stringify(prevState.pref) ||
        JSON.stringify(this.state.station) !== JSON.stringify(prevState.station) ||
        JSON.stringify(this.state.category) !== JSON.stringify(prevState.category) ||
        JSON.stringify(this.state.open_time) !== JSON.stringify(prevState.open_time) ||
        JSON.stringify(this.state.take_out) !== JSON.stringify(prevState.take_out)
      ) {
        this.setState({
          sort_shop_rating: false,
          sort_shop_rating_reverse: false,
          sort_budget: false,
          sort_budget_reverse: false,
          sort_open_time: false,
          sort_open_time_reverse: false,
        })
        if (JSON.stringify(this.state.station) !== JSON.stringify(prevState.station)) {
          if (isEmpty(this.state.station)) {
            this.setState({ station_error: true })
          } else {
            this.setState({ station_error: false })
          }
        }
      }
      this.setState({
        next_page_key: null,
        page_Keys: [],
      })
      this.getShopsList()
    }
  }

  async getShopsList (pageKey = null) {
    this.props.setLoading(true)
    if (isEmpty(this.state.station)) {
      this.setState({
        shops: [],
        next_page_key: null,
        page_Keys: [],
        next_disabled: true,
        prev_disabled: true,
      })
      this.props.setLoading(false)
      return
    }
    // 検索条件
    let params = '?'
    // 最寄駅指定
    if (!isEmpty(this.state.station)) {
      params += `nearest_station=${this.state.station}&`
    }
    // カテゴリ指定
    if (!isEmpty(this.state.category)) {
      params += `category=${this.state.category}&`
    }
    // 時間帯指定
    if (!isEmpty(this.state.open_time)) {
      params += `${this.state.open_time}=true&`
    }
    // 時間帯指定
    if (!isEmpty(this.state.take_out)) {
      params += 'take_out=true&'
    }
    // 雰囲気並び替え
    if (this.state.sort_shop_rating) {
      params += 'sort_key=shop_rating&'
      if (this.state.sort_shop_rating_reverse) {
        params += 'sort_reverse=true&'
      }
    }
    // 予算並び替え
    if (this.state.sort_budget) {
      params += 'sort_key=budget&'
      if (this.state.sort_budget_reverse) {
        params += 'sort_reverse=true&'
      }
    }
    // 時間帯並び替え
    if (this.state.sort_open_time) {
      params += 'sort_key=open_time&'
      if (this.state.sort_open_time_reverse) {
        params += 'sort_reverse=true&'
      }
    }
    // ページ
    if (pageKey) {
      params += `page_sort_key=${pageKey.page_sort_key}&`
      params += `page_id=${pageKey.id}&`
    }

    // お店リスト取得
    try {
      let data = await Api.getShops(params)
      this.setStateIfMounted({ shops: data.shops })
      let pageKeys = this.state.page_Keys
      if (pageKey && pageKey === this.state.next_page_key) {
        pageKeys.push(pageKey)
      }
      if (isNullOrUndefined(data.next_page_key)) {
        this.setStateIfMounted({
          next_page_key: null,
          page_Keys: pageKeys,
          next_disabled: true,
        })
      } else {
        if (pageKeys.length > 0) {
          if (JSON.stringify(pageKeys[pageKeys.length - 1]) === JSON.stringify(data.next_page_key)) {
            pageKeys.pop()
          }
        }
        this.setStateIfMounted({
          next_page_key: data.next_page_key,
          page_Keys: pageKeys,
          next_disabled: false,
        })
      }
      if (pageKeys.length > 0) {
        this.setStateIfMounted({ prev_disabled: false})
      } else {
        this.setStateIfMounted({ prev_disabled: true})
      }
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })

    if (event.target.name === 'pref') {
      if (event.target.value === '') {
        this.setState({ station_items: [] })
      } else {
        this.createStationItems(event.target.value)
      }
      this.setState({ station: '' })
    }
  }

  onClick () {
    let search = {
      pref: this.state.pref,
      station: this.state.station,
      category: this.state.category,
      open_time: this.state.open_time,
      take_out: this.state.take_out,
      sort_shop_rating: this.state.sort_shop_rating,
      sort_shop_rating_reverse: this.state.sort_shop_rating_reverse,
      sort_budget: this.state.sort_budget,
      sort_budget_reverse: this.state.sort_budget_reverse,
      sort_open_time: this.state.sort_open_time,
      sort_open_time_reverse: this.state.sort_open_time_reverse,
      shops: this.state.shops,
      next_page_key: this.state.next_page_key,
      page_Keys: this.state.page_Keys,
      next_disabled: this.state.next_disabled,
      prev_disabled: this.state.prev_disabled,
    }
    this.props.setShopSearch(search)
  }

  clickSortButton (target) {
    switch (target) {
      case 'sort_shop_rating':
        this.setState({
          sort_shop_rating: true,
          sort_shop_rating_reverse: !this.state.sort_shop_rating_reverse,
          sort_budget: false,
          sort_budget_reverse: false,
          sort_open_time: false,
          sort_open_time_reverse: false,
        })
        break
      case 'sort_budget':
        this.setState({
          sort_shop_rating: false,
          sort_shop_rating_reverse: false,
          sort_budget: true,
          sort_budget_reverse: !this.state.sort_budget_reverse,
          sort_open_time: false,
          sort_open_time_reverse: false,
        })
        break
      case 'sort_open_time':
        this.setState({
          sort_shop_rating: false,
          sort_shop_rating_reverse: false,
          sort_budget: false,
          sort_budget_reverse: false,
          sort_open_time: true,
          sort_open_time_reverse: !this.state.sort_open_time_reverse,
        })
        break
      default:
        break
    }
  }

  clickPrevButton () {
    if (this.state.page_Keys.length !== 0) {
      let prevPageKey = null
      if (this.state.page_Keys.length > 1) {
        prevPageKey = this.state.page_Keys[this.state.page_Keys.length - 2]
      }
      this.getShopsList(prevPageKey)
    }
  }

  clickNextButton () {
    if (this.state.next_page_key) {
      this.getShopsList(this.state.next_page_key)
    }
  }

  createSortButton (name, label, disabled = false) {
    const { classes } = this.props

    return (
      <Grid item className={classes.sortItem}>
        <Button
          variant="contained"
          color={this.state[name] ? 'inherit' : 'primary'}
          className={classes.sortButton}
          endIcon={<SortIcon />}
          disabled={disabled}
          onClick={() => this.clickSortButton(name)}
        >
          {label}
        </Button>
      </Grid>
    )
  }

  render () {
    const { classes, ...other } = this.props

    return(
      <React.Fragment>
        <AppHeader title={'レストラン一覧'} hideHome={true} {...other} />
        <div className={classes.root}>
          <div className={classes.container}>
            <Card className={classes.card}>
              { this.createSearchSelect('pref', '都道府県変更', this.state.pref_items, false, true) }
              { this.createSearchSelect('station', '最寄駅変更', this.state.station_items, this.state.pref === '' ? true : false) }
              { this.createSearchSelect('category', 'カテゴリ変更', this.state.category_items) }
              { this.createSearchSelect('open_time', '時間帯変更', this.state.open_time_items) }
              { this.createSearchSelect('take_out', 'テイクアウト', [{value: true, label: 'あり'}]) }
            </Card>
            <Typography className={classes.sortTitle}>
              並び替え
            </Typography>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.sortContainer}>
              { this.createSortButton('sort_shop_rating', '評価') }
              { this.createSortButton('sort_budget', '予算') }
              { this.createSortButton('sort_open_time', '時間帯', isEmpty(this.state.open_time) ? false : true) }
            </Grid>
            <div className={classes.buttonContainer}>
              <IconButton className={classes.iconButton} disabled={this.state.prev_disabled} onClick={this.clickPrevButton}>
                <PrevIcon />
              </IconButton>
              <IconButton className={classes.iconButton} disabled={this.state.next_disabled} onClick={this.clickNextButton}>
                <NextIcon />
              </IconButton>
            </div>
            {
              (this.state.shops.length === 0) ? (
                <Typography className={classes.emptyText}>
                  対象のレストランがありません。
                </Typography>
              ) : (
                <Table className={classes.table}>
                  <tbody>
                    {
                      this.state.shops.map((value, key) => {
                        return <ShopCell key={key} shop={value} onClick={this.onClick} {...other}/>
                      })
                    }
                  </tbody>
                </Table>
              )
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Shops.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Shops)