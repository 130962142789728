import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

const useStyles = makeStyles(theme => ({
  progressBar: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(3.375),
    height: '9px !important',
    borderRadius: 5,
    [`& .${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#DBDCFE'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#423BC7'
    },
  },
  item: {
    fontSize: '12px !important',
    fontWeight: '300 !important',
    textAlign: 'left',
    padding: '8px 0',
  },
  borderBottom: {
    borderBottom: '1px solid #ffffff',
  },
  borderTop: {
    borderTop: '1px solid #ffffff',
  },
}))

export default function SelectPattern (props) {
  const {
    selectedItems, wordType, onChange,
    onNext, onPrev, onClose, template,
  } = props
  const classes = useStyles()
  const item = selectedItems[0]
  const patterns = Object.entries(template.sentence).map(([k, v]) => {
    if (k.includes(item.title)) { return { [k]: v[wordType] } }
    return null
  }).filter(v => v)

  const createItem = (obj, index) => {
    const key = Object.keys(obj)[0]
    const value = Object.values(obj)[0]
    const text = value.join('').replace('{friend.nick_name}', '〇〇').replace('1', '「」').replace('2', '「」')
    return (
      <Grid
        key={index}
        className={`${classes.borderBottom} ${index === 0 && classes.borderTop}`}
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        data-testid={`pattern-${index}`}
        onClick={() => {
          onChange(0, key)
          onNext()
        }}
      >
        <Typography className={classes.item}>
          {text}
        </Typography>
        <Typography style={{ fontWeight: 700, marginLeft: 8 }}>＞</Typography>
      </Grid>
    )
  }

  return (
    <>
      <DialogTitle disableTypography>
        一言メッセージを作成
        <LinearProgress
          value={30}
          variant="determinate"
          className={classes.progressBar}
        />
        <Typography>
          使用する例文を選択してください。
        </Typography>
      </DialogTitle>
      <DialogContent>
        {patterns.map((obj, index) => createItem(obj, index))}
      </DialogContent>
      <DialogActions disableSpacing>
        <Button onClick={onPrev} style={{ marginBottom: -12 }}>戻る</Button>
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </>
  )
}

SelectPattern.propTypes = {
  type: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  wordType: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onClose: PropTypes.func,
  template: PropTypes.object,
}
