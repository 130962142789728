import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import Img1 from 'images/tutorial/likes_tutorial_img_1.png'
import Img2 from 'images/tutorial/likes_tutorial_img_2.png'

const styles = theme => ({
  slideIconsContainer: {
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: 8,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slide: {
    height: '100%',
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground3),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 48px)',
    maxWidth: 300,
    textAlign: 'left',
    '& p': {
      fontSize: 12,
      fontWeight: 400,
    }
  },
  speachUn: {
    ...theme.styles.naviSpeach(Const.colors.devilBackground3),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 48px)',
    maxWidth: 300,
  },
  speachTextA: {
    fontSize: 12,
    fontWeight: 400,
    color: Const.colors.angel,
    wordBreak: 'break-all',
    textAlign: 'left',
  },
  speachTextUn: {
    fontSize: 12,
    fontWeight: 400,
    color: Const.colors.devil,
    wordBreak: 'break-all',
    textAlign: 'left',
  },
  faceAvatar: {
    marginRight: theme.spacing(0.5),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(1),
  },
  image1: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  image2: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
})

export class LikesTutorial extends BaseComponent {
  constructor (props) {
    super(props)

    this.elementKey = 0
    this.state = {
      slideIndex: 0,
    }
  }

  async componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.open) !== JSON.stringify(prevProps.open)) {
      if (this.props.open) {
        this.setState({slideIndex: 0})
      }
    }
  }

  onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      this.setState({slideIndex: index})
    }
  }

  handleClose = () => {
    this.props.onClose()
  }

  render () {
    const { classes, ...other } = this.props
    return(
      <DialogThemeProvider color="secondary">
        <Dialog {...other}>
          <SwipeableViews
            index={this.state.slideIndex}
            onChangeIndex={this.onChangeSlideIndex}
            disabled={this.props.sex === 'male'}
          >
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>
                プロフィールに<br/>「好印象」をつけよう
              </DialogTitle>
              <DialogContent className={classes.content}>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="flex-end">
                  <img src={AImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachA}>
                    <Typography className={classes.speachTextA} variant="body1">
                      お相手の気になるプロフィールにハートをつけよう！
                    </Typography>
                  </div>
                </Grid>
                <Grid container wrap="nowrap" alignItems="flex-end">
                  <img src={UnImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachUn}>
                    <Typography className={classes.speachTextUn} variant="body1">
                      あなたの好みをAIが学習するよ。
                    </Typography>
                  </div>
                </Grid>
                <Typography variant="body1">
                  プロフィール画面
                </Typography>
                <Grid container justifyContent="center">
                  <img src={Img1} className={classes.image1} alt=""/>
                </Grid>
              </DialogContent>
            </div>
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle classes={{ root: classes.title }}>
                「好印象」の数を参考にしよう
              </DialogTitle>
              <DialogContent>
                <Grid container justifyContent="center" alignItems="flex-end">
                  <img src={AImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachA}>
                    <Typography className={classes.speachTextA} variant="body1">
                      「好印象」をたくさんもらっている人はプロフィールに表示されるよ。
                    </Typography>
                  </div>
                  <img src={UnImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachUn}>
                    <Typography className={classes.speachTextUn} variant="body1">
                      何人から「好印象」をもらっているか参考にしてね。
                    </Typography>
                  </div>
                </Grid>
                <Grid container justifyContent="center">
                  <img src={Img2} className={classes.image2} alt=""/>
                </Grid>
              </DialogContent>
            </div>
          </SwipeableViews>
          <DialogActions>
            <Grid container justifyContent="center">
              {
                this.props.sex === 'female' &&
                <div className={classes.slideIconsContainer}>
                  {this.state.slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                  {this.state.slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                </div>
              }
              <Button
                variant="contained"
                onClick={
                  () => (this.state.slideIndex === 1 || this.props.sex === 'male') ?
                    this.handleClose() : this.setState({slideIndex: this.state.slideIndex + 1})
                }
              >
                {(this.state.slideIndex === 1 || this.props.sex === 'male') ? '閉じる' : '次へ'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

LikesTutorial.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  sex: PropTypes.string.isRequired,
}

export default withStyles(styles)(LikesTutorial)
