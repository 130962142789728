import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FollowAfterDatingDialog from 'components/parts/FollowAfterDatingDialog'
import SecretQuestionRoomSuggestDialog from 'components/parts/SecretQuestionRoomSuggestDialog'
import Kagura from 'images/img_character_a.png'
import Sakaki from 'images/img_character_un.png'
import KaguraFoot from 'images/tutorial/img_footprint_a.png'
import SakakiFoot from 'images/tutorial/img_footprint_un.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: 338,
    fontWeight: 600,
    border: '2px dotted #5463F6',
    borderRadius: 22,
    margin: 'auto',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3.375),
    position: 'relative',
    textAlign: 'center',
  },
  sakaki: {
    width: 40,
    position: 'absolute',
    left: 10,
    top: 148,
    transform: 'rotate(20deg)',
  },
  kagura: {
    width: 32,
    position: 'absolute',
    right: 20,
    top: 90,
    transform: 'rotate(-15deg)',
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: '#1A1667',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    lineHeight: 1.3,
  },
  blueText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#423BC7',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    lineHeight: 1,
  },
  greyText: {
    fontSize: 20,
    fontWeight: 700,
    color: '#555555',
    marginBottom: theme.spacing(5),
    lineHeight: 1,
  },
  containedButton: {
    width: 230,
    height: 46,
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    backgroundColor: '#616AFA',
    borderRadius: 25,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#616AFA',
    }
  },
  button01: {
    marginBottom: theme.spacing(2),
  },
}))

export default function AfterDatingGuidance (props) {
  const { user } = props
  const classes = useStyles()
  const [openFollowAfterDatingDialog, setOpenFollowAfterDatingDialog] = useState(false)
  const [openSecretQuestionRoomSuggestDialog, setOpenSecretQuestionRoomSuggestDialog] = useState(false)

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="column"
        alignContent="center"
        alignItems="center"
      >
        <img className={classes.sakaki} src={Sakaki} />
        <img className={classes.kagura} src={Kagura} />
        <Grid container justifyContent="center" alignItems="center">
          <img src={KaguraFoot} width={22} />
          <Typography className={classes.title}>
            {user.nick_name}さんへの<br />
            メッセージ
          </Typography>
          <img src={SakakiFoot} width={22} />
        </Grid>
        <Typography className={classes.blueText}>
          デート後のフォローも任せて！
        </Typography>
        <Typography className={classes.greyText}>
          2人の間を仲立ちするよ
        </Typography>
        <Button
          className={`${classes.containedButton} ${classes.button01}`}
          variant="contained"
          onClick={() => setOpenFollowAfterDatingDialog(true)}
        >
          デート後フォローについて
        </Button>
        <Button
          className={classes.containedButton}
          variant="contained"
          onClick={() => setOpenSecretQuestionRoomSuggestDialog(true)}
        >
          秘密の質問部屋について
        </Button>
      </Grid>
      <FollowAfterDatingDialog
        open={openFollowAfterDatingDialog}
        onClose={() => setOpenFollowAfterDatingDialog(false)}
        sex={user.sex}
      />
      <SecretQuestionRoomSuggestDialog
        open={openSecretQuestionRoomSuggestDialog}
        onClose={() => setOpenSecretQuestionRoomSuggestDialog(false)}
      />
    </>
  )
}

AfterDatingGuidance.propTypes = {
  user: PropTypes.object.isRequired,
}
