import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  alert: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    color: '#F55083',
    textAlign: 'center',
    fontWeight: 500,
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  next: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
}))

const CancelMyPhoneNumber = (props) => {
  const { ...other } = props
  const classes = useStyles()

  const handleClick = async () => {
    props.setLoading(true)
    try {
      await Api.disableSms()
      props.setDisableSmsVerification(true)
      props.setScreen('/Home')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <AppHeader title="電話番号登録を解除" backWhite={true} {...other} />
      <Grid container direction="column" alignItems="center">
        <Typography className={classes.text}>
          機種変更に伴い、電話番号を変更する場合電話番号登録を一時的に解除することが可能です。
        </Typography>
        <Typography className={classes.alert}>
          解除後は、新しい電話番号を登録するまで全ての操作ができなくなりますのでご注意ください。
        </Typography>
        <Grid
          className={classes.buttons}
          container
          direction="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Button
            className={classes.next}
            variant="contained"
            color="secondary"
            onClick={handleClick}
          >
            登録を解除する
          </Button>
          <Button className={classes.cancel} variant="text" onClick={() => BaseComponent.goBack(props)}>
            キャンセル
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default CancelMyPhoneNumber

CancelMyPhoneNumber.propTypes = {
  authId: PropTypes.string,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  setDisableSmsVerification: PropTypes.func,
}