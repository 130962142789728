import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Api from 'commons/api'
import { isProfilePrivateFieldName, isProfileIgnoredKeys } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  table: {
    width: '100%',
  },
  cell: {
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  cellTitle: {
    width: '50%',
    padding: `${theme.spacing(1.375)}px 0`,
    borderStyle: 'none',
    verticalAlign: 'top',
  },
  itemTitle: {
    ...theme.styles.profileItem,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  itemValue: {
    ...theme.styles.profileItem,
    color: theme.palette.secondary.main,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    paddingRight: theme.spacing(1),
  },
  favoriteBorder: {
    color: '#AFB7F8',
    width: 18,
    height: 18,
    display: 'inline',
  },
  favoriteFill: {
    color: '#3D45C2',
    width: 18,
    height: 18,
    display: 'inline',
  },
  likeCell: {
    textAlign: 'right',
    borderStyle: 'none',
    padding: theme.spacing(0.5),
  },
  likeCheckbox: {
    height: theme.spacing(4),
  },
})

export class BasicProfile extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleLikeChange = this.handleLikeChange.bind(this)
    this._isMounted = false
    this.state = {
      friend: null,
      likes: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.initProfileInputs()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  initProfileInputs () {
    this.setStateIfMounted({
      friend: {
        birthday: this.props.friend.age + '歳',
        nationality: this.getItemLabel('nationality') || '',
        address1: this.props.friend.address1,
        working_industry: this.getItemLabel('working_industry') || '',
        working_occupation: this.getItemLabel('working_occupation') || '',
        main_working_company_name: this.getItemLabel('main_working_company_name') || '',
        job_transfer: this.getItemLabel('job_transfer') || '',
        work_location_flexibility: this.getItemLabel('work_location_flexibility') || '',
      },
      likes: {
        birthday: this.props.likes ? this.props.likes.my_likes.birthday : false,
        nationality: this.props.likes ? this.props.likes.my_likes.nationality : false,
        address1: this.props.likes ? this.props.likes.my_likes.address1 : false,
        working_industry: this.props.likes ? this.props.likes.my_likes.working_industry : false,
        working_occupation: this.props.likes ? this.props.likes.my_likes.working_occupation : false,
        photo: this.props.likes ? this.props.likes.my_likes.photo : false,
        job_transfer: this.props.likes ? this.props.likes.my_likes.job_transfer : false,
        work_location_flexibility: this.props.likes ? this.props.likes.my_likes.work_location_flexibility : false,
      },
    })
  }

  getItemLabel (key) {
    const { friend, master } = this.props
    if (!friend[key] || !master?.profile) { return '' }
    if (!master.profile[key].detail) { return '' }
    const detail = master.profile[key].detail
    if (Object.keys(detail).length === 0) { return friend[key] }
    const items = detail.common || detail[friend.sex] || []
    return items.find(item => item.value === friend[key])?.label || ''
  }

  async handleLikeChange (event) {
    const { friend, tutorialView } = this.props
    const key = event.target.value
    const checked = event.target.checked
    try {
      if (tutorialView === 'like-02' && checked) { BaseComponent.gaEvent('初回ナビ', 'click', '好印象') }
      this.props.setTutorialView(null)
      const data = await Api.updateLikes(friend.id, { likes: { [key]: checked } })
      this.setStateIfMounted({ likes: data.my_likes })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  getLabelOf (profileName) {
    switch (profileName) {
      case 'birthday': return '年齢'
      case 'address1': return '住まい'
      default:
        return this.props.master.profile[profileName].title
    }
  }

  render () {
    if (!this.state.friend) return null
    const { classes, tutorialView } = this.props
    return (
      <Grid container direction="column">
        <Table className={classes.table}>
          <tbody>
            {this.props.isLikes && (
              <TableRow id="like-tutorial-target-02" className={classes.row} key='photo'>
                <TableCell className={classes.cellTitle}>
                  <Typography className={classes.itemTitle}>
                    プロフィール写真
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell} />
                <TableCell className={classes.likeCell}>
                  <Checkbox
                    onChange={this.handleLikeChange}
                    icon={<FavoriteBorderIcon className={classes.favoriteBorder} />}
                    checkedIcon={<FavoriteIcon className={classes.favoriteFill} />}
                    value='photo'
                    checked={this.state.likes['photo']}
                    className={classes.likeCheckbox}
                    style={{ border: tutorialView === 'like-02' && '4px solid #6666FC', height: tutorialView === 'like-02' && 44 }}
                    inputProps={{ 'data-testid': 'checkbox-photo' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {Object.keys(this.state.friend).map(key => {
              if (!this.state.friend[key]) { return null }
              if (isProfilePrivateFieldName(key)) { return null }
              if (isProfileIgnoredKeys(key)) { return null }
              return (
                <TableRow className={classes.row} key={key}>
                  <TableCell className={classes.cellTitle}>
                    <Typography className={classes.itemTitle}>
                      {this.getLabelOf(key)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.itemValue}>{this.state.friend[key]}</Typography>
                  </TableCell>
                  {
                    this.props.isLikes ? (
                      <TableCell className={classes.likeCell}>
                        <Checkbox
                          onChange={this.handleLikeChange}
                          icon={<FavoriteBorderIcon className={classes.favoriteBorder} />}
                          checkedIcon={<FavoriteIcon className={classes.favoriteFill} />}
                          value={key}
                          checked={this.state.likes[key]}
                          className={classes.likeCheckbox}
                          inputProps={{ 'data-testid': `checkbox-${key}` }}
                        />
                      </TableCell>
                    ) : null
                  }
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </Grid>
    )
  }
}

BasicProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  isLikes: PropTypes.bool,
}

export default withStyles(styles)(BasicProfile)
