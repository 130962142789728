import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import Image1 from 'images/img_secret_1.png'
import Image2 from 'images/img_secret_2.png'
import Image3 from 'images/img_secret_3.png'
import Image4 from 'images/img_secret_4.png'

const styles = theme => ({
  slideIconsContainer: {
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: 8,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slide: {
    height: 560,
  },
  text: {
    marginBottom: theme.spacing(1.5),
  },
  notice: {
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 52px)',
    maxWidth: 300,
    textAlign: 'left',
    marginTop: theme.spacing(1),
    '& p': {
      fontSize: 10,
    }
  },
  speachUn: {
    ...theme.styles.naviSpeach(Const.colors.devilBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 52px)',
    maxWidth: 300,
    textAlign: 'left',
    marginTop: theme.spacing(1),
    '& p': {
      fontSize: 10,
    }
  },
  faceAvatar: {
    marginRight: theme.spacing(1),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(1),
  },
  faceAvatarA: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: 24,
    objectFit: 'contain',
    transform: 'rotate(-15deg)',
  },
  faceAvatarUn: {
    marginRight: theme.spacing(1),
    height: 24,
    objectFit: 'contain',
    transform: 'rotate(15deg)',
  },
  colorSecret: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  colorNormal: {
    color: '#FF0000',
    fontWeight: 'bold',
  },
  image1: {
    width: 232,
    height: 280,
    objectFit: 'contain',
  },
  image2: {
    width: 212,
    height: 330,
    objectFit: 'contain',
  },
  image3: {
    width: 240,
    height: 350,
    objectFit: 'contain',
  },
  image4: {
    width: 240,
    height: 284,
    objectFit: 'contain',
  },
})

export class SecretQuestionRoomTutorial extends BaseComponent {
  constructor (props) {
    super(props)

    this.elementKey = 0
    this.state = {
      slideIndex: 0,
    }
  }

  async componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.open) !== JSON.stringify(prevProps.open)) {
      if (this.props.open) {
        this.setState({slideIndex: 0})
      }
    }
  }

  onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      this.setState({slideIndex: index})
    }
  }

  handleClose = () => {
    this.props.onClose()
  }

  createChatMessageElements (msg, color, fontSize) {
    let allElems = []
    let lines = msg.split(/\r\n|\r|\n/)
    for (let line of lines) {
      let style = { wordBreak: 'break-all', textAlign: 'left' }
      let elems = [<Typography variant="body1" className={fontSize} style={style} key={this.nextKey()}>{line}</Typography>]
      for (let elem of elems) {
        allElems.push(elem)
      }
    }
    return allElems
  }

  nextKey () {
    this.elementKey += 1
    return this.elementKey
  }

  render () {
    const { classes, ...other } = this.props
    return(
      <DialogThemeProvider color="secondary">
        <Dialog {...other}>
          <SwipeableViews index={this.state.slideIndex} onChangeIndex={this.onChangeSlideIndex}>
            {
              this.props.friend ? (
                <div style={Object.assign({})} className={classes.slide}>
                  <DialogTitle disableTypography>
                    <Grid container justifyContent="center" alignItems="center">
                      <img src={AImage} className={classes.faceAvatarA} alt=""/>
                      おめでとう！！
                      <img src={UnImage} className={classes.faceAvatarUn} alt=""/>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <Typography className={classes.text} variant="body1">
                      {this.props.friend.nick_name}さんとの<br />イベントが発生するよ！！
                    </Typography>
                    <Typography className={classes.text} variant="body1">
                      秘密の質問部屋を開放して<br/>
                      {this.props.friend.nick_name}さんとの親密度を上げよう！
                    </Typography>
                    <Typography className={classes.notice} variant="body2">
                      ※秘密の質問部屋とは、<br/>
                      本当の{this.props.friend.nick_name}さんを知るためのアシストを
                      AIが行うトークイベントです。<br/>
                      （ある条件を満たした場合に発生します）
                    </Typography>
                    <Grid container justifyContent="center">
                      <img src={Image1} className={classes.image1} alt=""/>
                    </Grid>
                  </DialogContent>
                </div>
              ) : (
                <div style={Object.assign({})} className={classes.slide}>
                  <DialogTitle disableTypography>秘密の質問部屋とは？</DialogTitle>
                  <DialogContent>
                    <Typography className={classes.text} variant="body1">
                      ある条件を満たすとイベントが発生するよ！！
                    </Typography>
                    <Typography className={classes.text} variant="body1">
                      秘密の質問部屋とは、<br/>
                      相手のことをより深く知るためのアシストを<br/>
                      AIが行うトークイベントです。
                    </Typography>
                    <Grid container justifyContent="center">
                      <img src={Image1} className={classes.image1} alt=""/>
                    </Grid>
                  </DialogContent>
                </div>
              )
            }
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>秘密の質問部屋の質問とは？</DialogTitle>
              <DialogContent>
                <Typography className={classes.text} variant="body1">
                  AIキャラクター狛犬たちが<br/>
                  親密度の上がる質問を２人にするよ！
                </Typography>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="flex-end">
                  <img src={AImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachA}>
                    {this.createChatMessageElements('聞きたくても聞けないこと、\nボクたちが代わりに聞いちゃうよ！！', Const.colors.angel, classes.speach)}
                  </div>
                </Grid>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="flex-end">
                  <img src={UnImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachUn}>
                    {this.createChatMessageElements('イベントを重ねるごとに、\n質問がDEEPになるから乞うご期待。', Const.colors.devil, classes.speach)}
                  </div>
                </Grid>
                <Grid container justifyContent="center">
                  <img src={Image2} className={classes.image2} alt=""/>
                </Grid>
              </DialogContent>
            </div>
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>秘密の質問部屋の入り口</DialogTitle>
              <DialogContent>
                <Typography className={classes.text} variant="body1">
                  {
                    this.props.friend  &&
                    <span>{this.props.friend.nick_name}さんとの<br/></span>
                  }
                  トークルームの右上メニューに<br/>
                  秘密の質問部屋の入り口があるよ！
                </Typography>
                <Typography className={classes.notice} variant="body2">
                  ※一度秘密の質問部屋が開放されると<br/>
                  卒業までいつでも入ることができます。
                </Typography>
                <Grid container justifyContent="center">
                  <img src={Image3} className={classes.image3} alt=""/>
                </Grid>
              </DialogContent>
            </div>
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>秘密の質問部屋の未読お知らせ</DialogTitle>
              <DialogContent>
                <Typography className={classes.text} variant="body1">
                  秘密の質問部屋の<br/>
                  未読・新着メッセージは<br/>
                  「トーク」で確認を！
                </Typography>
                <Typography className={classes.notice} variant="body2">
                  ※秘密の質問部屋の未読・新着メッセージ数は<span className={classes.colorSecret}>紫色</span>で表示されます。<br/>
                  （通常トークは<span className={classes.colorNormal}>赤色</span>です。）
                </Typography>
                <Grid container justifyContent="center">
                  <img src={Image4} className={classes.image4} alt=""/>
                </Grid>
              </DialogContent>
            </div>
          </SwipeableViews>
          <DialogActions>
            <Grid container justifyContent="center">
              <div className={classes.slideIconsContainer}>
                {this.state.slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 2 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 3 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              </div>
              <Button
                variant="contained"
                onClick={() => this.state.slideIndex === 3 ? this.handleClose() : this.setState({slideIndex: this.state.slideIndex + 1})}
              >
                {this.state.slideIndex === 3 ? '閉じる' : '次へ'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

SecretQuestionRoomTutorial.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  friend: PropTypes.object,
}

export default withStyles(styles)(SecretQuestionRoomTutorial)
