import React from 'react'
import PropTypes from 'prop-types'
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh'
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'

export default function PhotoFilterEdit (props) {
  const { brightness, onChangeBrightness } = props

  const handleChange = (event, newValue) => {
    if (onChangeBrightness) {
      onChangeBrightness(newValue)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <BrightnessLowIcon />
        <Slider
          value={brightness}
          valueLabelFormat={v => `${v}%`}
          min={50}
          max={150}
          onChange={handleChange}
          valueLabelDisplay="auto"
        />
        <BrightnessHighIcon />
      </Stack>
    </Box>
  )
}

PhotoFilterEdit.propTypes = {
  brightness: PropTypes.number,
  onChangeBrightness: PropTypes.func,
}
