import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { isEmpty, httpForbidden, httpConflict, httpNotFound } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.styles.header.height,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#707070',
    lineHeight: 1,
    marginTop: theme.spacing(4.75),
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: '#707070',
    marginBottom: theme.spacing(2),
  },
  notice: {
    fontSize: 11,
    fontWeight: 300,
    color: '#707070',
    marginBottom: theme.spacing(5),
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    height: 44,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  next: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
}))

export default function PasswordForgot (props) {
  const classes = useStyles()
  const [mailAddress, setMailAddress] = useState('')
  const [mailAddressError, setMailAddressError] = useState(null)

  const onChangeMailAddress = (event) => {
    setMailAddress(event.target.value)
    setMailAddressError(null)
  }

  const submitMailAddress = async () => {
    if (isEmpty(mailAddress)) {
      setMailAddressError('メールアドレスを入力してください。')
      return
    } else if (!mailAddress.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      setMailAddressError('メールアドレスの形式が正しくありません。')
      return
    } else if (mailAddress.length > 255) {
      setMailAddressError('最大文字長は２５５文字です。')
      return
    }

    props.setLoading(true)
    try {
      const res = await Api.resetPassword(mailAddress)
      handleSuccess(res)
    } catch (error) {
      if (httpConflict(error)) {
        const msg = [
          '本登録が完了していません。',
          'ご登録のメールアドレスへお送り',
          'しております本登録URLより、',
          '本登録をお願いしたします。',
          '',
          '事前登録中の地域の方につきましては、',
          '本登録の開始まで',
          '今しばらくお待ちください。',
        ].join('\n')
        BaseComponent.showImportantErrorMessage(props, msg, () => {
          props.setScreen('Login')
        })
      } else if (httpForbidden(error)) {
        const msg = [
          'ただいま入会審査中です。',
          '審査が完了しましたら、ご登録のメールアドレスへご連絡いたします。',
          '今しばらくお待ちください。',
        ].join('\n')
        BaseComponent.showImportantErrorMessage(props, msg, () => {
          props.setScreen('Login')
        })
      } else if (httpNotFound(error)) {
        setMailAddressError('登録されているアカウントが見つかりません。')
      } else {
        BaseComponent.handleApiError(props, error)
      }
    } finally {
      props.setLoading(false)
    }
  }

  const handleSuccess = (data) => {
    props.setAuthId(data.auth.id)
    props.setMailAddress(mailAddress)
    props.setScreen('Passcode')
  }

  return (
    <>
      <AppHeader title="パスワード再設定" backWhite hideBack {...props} />
      <div className={classes.container}>
        <Typography className={classes.subtitle}>メールアドレスを入力</Typography>
        <Typography className={classes.text}>
          パスワードを再設定します。<br />
          登録済みのメールアドレスを入力してください。<br />
          認証コードを送信します。
        </Typography>
        <Typography className={classes.notice}>
          ＊1 迷惑メールフォルダへの振り分けにご注意ください<br />
          ＊2 下記アドレスの受信設定を許可してください<br />
          →「support@aill.ai」
        </Typography>
        <Typography className={classes.label}>メールアドレス</Typography>
        <TextField
          className={classes.input}
          type='email'
          value={mailAddress}
          onChange={onChangeMailAddress}
          InputProps={{ disableUnderline: true, inputProps: { 'aria-label': 'email' } }}
          error={!isEmpty(mailAddressError)}
          helperText={mailAddressError}
        />
      </div>
      <Grid
        className={classes.buttons}
        container
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button
          className={classes.next}
          variant="contained"
          color="secondary"
          onClick={submitMailAddress}
          disabled={!mailAddress}
        >
          次へ
        </Button>
        <Button className={classes.cancel} variant="text" onClick={() => BaseComponent.goBack(props)}>
          キャンセル
        </Button>
      </Grid>
    </>
  )
}

PasswordForgot.propTypes = {
  setAuthId: PropTypes.func,
  setMailAddress: PropTypes.func,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
