import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import { httpNotFound, httpNotModified, getNewMessageCount, getNewSecretMessageCount } from 'commons/utility'
import NaviPopover from 'components/first-navigation/NaviPopover'
import NaviPopper from 'components/first-navigation/NaviPopper'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import FriendCellTalk from 'components/parts/FriendCellTalk.jsx'
import PlanTermCheck from 'components/plan/PlanTermCheck'
import firstFavNaviImg01 from 'images/Komainu/firstFavNaviImg01.png'
import firstFavNaviImg02 from 'images/Komainu/firstFavNaviImg02.png'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.styles.footer.height,
  },
  table: {
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    tableLayout: 'fixed',
  },
  emptyTable: {
    height: 80,
  },
  centerBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  emptyImage: {
    opacity: 0.34,
    height: 144,
  },
  emptyComment: {
    opacity: 0.34,
    color: theme.palette.secondary.main,
  },
  sortContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    width: '100%',
    borderWidth: 1,
    borderColor: '#EFF0FF',
    borderBottomStyle: 'solid',
  },
  sortItem: {
    width: 80,
    height: 28,
    textAlign: 'center',
  },
  sortItemExcludeAi: {
    width: 96,
  },
  sortButton: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    width: 68,
    height: 28,
    padding: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
  },
  sortButtonDisabled: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    width: 68,
    height: 28,
    padding: 0,
    color: theme.palette.secondary.main,
    backgroundColor: '#DBDCFE',
    borderRadius: 20,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  sortButtonExcludeAi: {
    width: 96,
  },
}))

export default function Friends (props) {
  const { tutorialReadStates, tutorialView, ...other } = props
  const classes = useStyles()
  const [approvedFriend, setApprovedFriend] = useState([])
  const [sortType, setSortType] = useState('new')
  const [aiFriend, setAiFriend] = useState(null)

  useEffect(() => {
    props.setScreen('Friends')
    setAiFriendRoom()
    BaseComponent.addVisitPageLog()

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  // ↑の副作用hook(setAiFriendRoom()の副作用) AI Roomを取得後にinitFriends
  useEffect(() => {
    if (!aiFriend) { return }
    initFriends()
  }, [aiFriend])

  // ↑の副作用hook(initFriends()の副作用) approvedFriend更新後に初回ナビ表示
  useEffect(() => {
    if (approvedFriend.length === 0) { return }
    // 優先度: 友達部屋トークナビ > AI部屋トークナビ > 好感度ナビ
    !openFirstTalkNavi() && !openAIFirstTalkNavi() && openFirstFavNavi()
  }, [approvedFriend])

  // トークナビ表示
  const openFirstTalkNavi = () => {
    if (!tutorialReadStates) { return false }
    if (tutorialReadStates.includes('talk_list')) { return false }
    if (tutorialReadStates.includes('talk_room')) { return false }
    if (!document.getElementById('talk-list-tutorial-target')) { return false }
    props.setTutorialView('talk-list-01')
    BaseComponent.gaModalView('talklisttutorial')
    return true
  }

  // AIトークナビ表示
  const openAIFirstTalkNavi = () => {
    if (!tutorialReadStates) { return false }
    if (tutorialReadStates.includes('talk_list_ai')) { return false }
    if (tutorialReadStates.includes('talk_room_ai')) { return false }
    if (!document.getElementById('talk-list-ai-tutorial-target')) { return false }
    props.setTutorialView('talk-list-ai-01')
    BaseComponent.gaModalView('talklistaitutorial')
    return true
  }

  // 好感度ナビ表示
  const openFirstFavNavi = () => {
    if (!tutorialReadStates) { return false }
    if (approvedFriend.length < 3) { return false }
    if (tutorialReadStates.includes('fav_bar') && tutorialReadStates.includes('fav_update')) { return false }
    if (!document.getElementById('fav-bar-tutorial-target')) { return false }
    if (!document.getElementById('talk-list-ai-tutorial-target')) { return false }
    props.setTutorialView('fav-bar-01')
    BaseComponent.gaModalView('favbartutorial')
    return true
  }

  const setAiFriendRoom = async () => {
    const ai_chat_room_id = props.user?.ai_chat_room_id
    if (!ai_chat_room_id) { return }
    const ai = await BaseComponent.getAiFriend(props, ai_chat_room_id)
    if (!ai) { return }
    setAiFriend(ai)
  }

  const initFriends = async () => {
    let friends = props.friends
    if (friends) { showFriends(friends, sortType) }

    try {
      const entries = await BaseComponent.loadAppliedEntries(props)
      if (entries.received?.length || entries.sent?.length) {
        props.setLoading(true)
        friends = await BaseComponent.loadFriends(props)
      }
      friends = await updateFriends(friends)
      const friendToShow = findFriendToShow(friends)
      if (friendToShow) {
        props.setFriend(friendToShow)
        props.setScreen('Chat')
      } else {
        showFriends(friends, sortType)
        BaseComponent.loadNotifications(props)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const showFriends = (friends, sort_type) => {
    if (!friends) { return [] }
    // 今日の出会い、フレンド申請者、フレンド申請中以外のリスト
    const result = friends.filter(friend => {
      if (!friend) { return false }
      if (friend.type === 'daily') { return false }
      if (friend.type === 'friend_receiving') { return false }
      if (friend.type === 'friend_applying') { return false }
      if (friend.type === 'friend_pending_canceled') { return false }
      if (friend.type === 'ai') { return false }
      return true
    })

    if (props.user.ai_chat_room_id && aiFriend) {
      result.push(aiFriend)
    }

    switch (sort_type) {
      case 'new':
        // 並べ替え（最新順）
        result.sort(function (a, b) {
          if (a.chat_updated_at < b.chat_updated_at) return 1
          if (a.chat_updated_at > b.chat_updated_at) return -1
          return 0
        })
        break
      case 'new_exclude_ai':
        // 並べ替え（最新順AI除く）
        result.sort(function (a, b) {
          if (a.user_chat_updated_at < b.user_chat_updated_at) return 1
          if (a.user_chat_updated_at > b.user_chat_updated_at) return -1
          return 0
        })
        break
      case 'point':
        // 並べ替え（好感度降順）
        result.sort(function (a, b) {
          if (a.type === 'ai') return 1
          if (b.type === 'ai') return -1
          if (a.fav_point_to_me < b.fav_point_to_me) return 1
          if (a.fav_point_to_me > b.fav_point_to_me) return -1
          if (a.chat_updated_at < b.chat_updated_at) return 1
          if (a.chat_updated_at > b.chat_updated_at) return -1
          return 0
        })
        break
      case 'old':
        // 並べ替え（古い順）
        result.sort(function (a, b) {
          if (a.chat_updated_at > b.chat_updated_at) return 1
          if (a.chat_updated_at < b.chat_updated_at) return -1
          return 0
        })
        break
      default:
        break
    }
    // 並べ替え（保留中→お付き合い申請受信中→お付き合い申請送信中）
    result.sort(function (a, b) {
      if (a.type !== 'friend_pending' && b.type === 'friend_pending') return 1
      if (a.type === 'friend_pending' && b.type !== 'friend_pending') return -1
      if ((a.type !== 'lover_applying' && a.type !== 'lover_receiving') && (b.type === 'lover_applying' || b.type === 'lover_receiving')) return 1
      if ((a.type === 'lover_applying' || a.type === 'lover_receiving') && (b.type !== 'lover_applying' && b.type !== 'lover_receiving')) return -1
      if (a.type === 'lover_applying' && b.type === 'lover_receiving') return 1
      if (a.type === 'lover_receiving' && b.type === 'lover_applying') return -1
      return 0
    })
    setApprovedFriend(result)
  }

  const updateFriends = async (friends) => {
    if (!friends) return
    try {
      return await Promise.all(friends.map(friend => updateFriend(friend)))
    } catch (error) {
      BaseComponent.handleError(props, error)
    }
  }

  const updateFriend = async (friend) => {
    if (
      friend.type !== 'daily' &&
      friend.type !== 'friend_receiving' &&
      friend.type !== 'friend_applying' &&
      friend.type !== 'ai'
    ) {
      try {
        const updateFriend = await BaseComponent.loadFriendAndUpdateFriends(props, friend.id)
        // 新着メッセージがある場合return
        if (getNewMessageCount(updateFriend) !== 0) { return updateFriend }
        // 新着秘密の質問がある場合、秘密の質問部屋の詳細を取得してreturn
        if (getNewSecretMessageCount(updateFriend) > 0) { return setSecretMessage(updateFriend) }
        // 新着メッセージ、新着秘密の質問が無い場合、最終メッセージ送信者を取得してreturn
        return setLastMessageFrom(updateFriend)
      } catch (error) {
        if (httpNotModified(error)) {
          return friend
        } else if (httpNotFound(error)) {
          return null
        } else {
          BaseComponent.handleApiError(props, error)
        }
      }
    } else {
      return friend
    }
  }

  // 秘密の部屋メッセージ取得
  const setSecretMessage = async (friend) => {
    try {
      const secretMessages = await Api.getSecretRooms(friend.chat_room_id)
      if (!secretMessages) return null
      if (secretMessages.length === 0) return null

      const newMessages = secretMessages.filter(msg => {
        return msg.new_secret_message_count > 0
      })
      if (!newMessages) return null
      if (!newMessages.length === 0) return null

      const secretRoom = newMessages[0]
      const room = await Api.getChatRoom(secretRoom.secret_room_id)
      friend.secret_message = room.histories[room.histories.length - 1]
      friend.secret_title = secretRoom.secret_question_title
      return friend
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // 最終メッセージ送信者取得
  const setLastMessageFrom = async (friend) => {
    try {
      const room = await Api.getChatRoom(friend.chat_room_id)
      const reverse = room.histories.concat().reverse()
      const last = reverse.find(h => h.user_id)
      if (last) {
        friend.last_messag_from = last.user_id
      }
      return friend
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  // ネイティブ側でタップされたPush通知の対象フレンドを取得
  const findFriendToShow = (friends) => {
    if (!Array.isArray(friends)) { return }
    const targetFriendId = Storage.getFriendIdToShow()
    if (!targetFriendId) { return null }
    const found = friends.find(friend => {
      if (friend?.id !== targetFriendId) { return false }
      if (/^friend_pending(_.+)?$/.test(friend?.type)) { return false }
      return true
    })
    if (found) {
      Storage.clearFriendIdToShow()
    }
    return found
  }

  const createSortButton = (type, label) => {
    return (
      <Grid item className={`${classes.sortItem} ${type === 'new_exclude_ai' && classes.sortButtonExcludeAi}`}>
        {sortType === type ? (
          <div className={type === 'new_exclude_ai' ? classes.sortButtonDisabled + ' ' + classes.sortButtonExcludeAi : classes.sortButtonDisabled}>
            {label}
          </div>
        ) : (
          <Button
            variant="text"
            color="secondary"
            className={`${classes.sortButton} ${type === 'new_exclude_ai' && classes.sortButtonExcludeAi}`}
            onClick={() => clickSortButton(type)}
          >
            {label}
          </Button>
        )}
      </Grid>
    )
  }

  const clickSortButton = (sort_type) => {
    setSortType(sort_type)
    showFriends(approvedFriend, sort_type)
  }

  const createId = (value, index) => {
    if (value.type === 'ai') {
      return 'talk-list-ai-tutorial-target'
    } else if (index === 0 || index === 1) {
      return 'talk-list-tutorial-target'
    } else {
      return ''
    }
  }

  const focused = (value, index) => {
    if (!tutorialView) { return false }
    if (tutorialView === 'talk-list-ai-tutorial-target' && value.type === 'ai') { return true }
    if (tutorialView === 'talk-list-tutorial-target' && (index === 0 || index === 1)) { return true }
  }

  const deleteFriend = async (friend) => {
    props.setLoading(true)
    try {
      await Api.deleteFriend(friend.id)
      const load = await BaseComponent.loadFriends(props)
      const update = await updateFriends(load)
      showFriends(update)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <AppHeader
        title="トーク"
        hideBack={true}
        hideHome={true}
        showMypage={true}
        showTutorialIcon={true}
        showFeedback={true}
        {...other}
      />
      <div className={classes.root}>
        <PlanTermCheck {...props}>
          {approvedFriend.length > 0 && (
            <div>
              <Grid
                className={classes.sortContainer}
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                {createSortButton('new', '最新順')}
                {createSortButton('point', '好感度順')}
                {createSortButton('old', '古い順')}
                {createSortButton('new_exclude_ai', '最新順(AI除く)')}
              </Grid>
              <Table className={classes.table}>
                <tbody>
                  {approvedFriend.map((value, index) => (
                    <TableRow id={createId(value, index)} key={index} hover>
                      <TableCell component="td" style={{ padding: 0, borderStyle: 'none' }}>
                        {/* 今日の出会い、フレンド申請者、フレンド申請中以外のリスト（メッセージのやり取り可能な人） */}
                        <FriendCellTalk
                          {...props}
                          user={props.user}
                          friends={props.friends}
                          subscription={props.subscription}
                          friend={value}
                          sortType={sortType}
                          naviFocused={focused()}
                          deleteFriend={() => deleteFriend(value)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
              <div id="fav-bar-tutorial-target" style={{ position: 'fixed', top: '20%', left: '50%' }} />
              <NaviPopper
                {...props}
                id="talk-list-01"
                open={tutorialView === 'talk-list-01'}
                anchorEl={document.getElementById('talk-list-tutorial-target')}
                title={'タップしよう！'}
                text={'狛犬と一緒に\n初回メッセージを送ろう！'}
                placement={'bottom'}
                onClose={() => props.setTutorialView(null)}
                arrow
              />
              <NaviPopper
                {...props}
                id="talk-list-ai-01"
                open={tutorialView === 'talk-list-ai-01'}
                anchorEl={document.getElementById('talk-list-ai-tutorial-target')}
                title={'タップしよう！'}
                text={'狛犬お役立ちポイントがわかるよ！\nAIを上手く活用しよう！'}
                placement={'bottom'}
                onClose={() => props.setTutorialView(null)}
                arrow
              />
              <NaviPopover
                {...props}
                id="fav-bar-01"
                open={tutorialView === 'fav-bar-01'}
                anchorEl={document.getElementById('fav-bar-tutorial-target')}
                title={'好感度ナビについて'}
                text={'1. お相手のあなたに対する\n好感度を見える化！\n\n2. お相手が登録した好感度や\nトーク内容を元に解析して表示\n\n好感度の変更方法と\n好感度の見方を一緒に見てみよう！'}
                img={firstFavNaviImg01}
                position={'bottom'}
                onClose={() => props.setTutorialView(null)}
                okButton
              />
              <NaviPopover
                {...props}
                id="fav-bar-02"
                open={tutorialView === 'fav-bar-02'}
                anchorEl={document.getElementById('talk-list-tutorial-target')}
                title={'お相手のあなたに対する\n好感度だよ！'}
                text={'「バーの長さ」で好感度を\n表示しているよ'}
                img={firstFavNaviImg02}
                position={'bottom'}
                onClose={() => props.setTutorialView(null)}
                okButton
                arrow
              />
              <NaviPopper
                {...props}
                id="fav-bar-03"
                open={tutorialView === 'fav-bar-03'}
                anchorEl={document.getElementById('talk-list-tutorial-target')}
                title={'タップしよう！'}
                text={'あなたのお相手に対する好感度は\n「好感度登録」から登録できるよ'}
                placement={'bottom'}
                onClose={() => props.setTutorialView(null)}
                arrow
              />
            </div>
          )}
        </PlanTermCheck>
      </div>
    </>
  )
}

Friends.propTypes = {
  user: PropTypes.object.isRequired,
  friends: PropTypes.array.isRequired,
  subscription: PropTypes.object,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
  setFriend: PropTypes.func,
  tutorialReadStates: PropTypes.array,
  tutorialView: PropTypes.string,
  setTutorialView: PropTypes.func,
}
