import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Currency from 'commons/currency'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import GiftCard from 'images/gift_card_aill_discount.png'

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  img: {
    width: '100%',
    margin: 'auto',
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  notice: {
    marginBottom: theme.spacing(4),
  },
}))

/*
 * 割引き期間延長ポップアップ
 */
export default function DiscountTermExtentionDialog (props) {
  const { open, onClose, data } = props
  const classes = useStyles()

  if (!data) { return null }

  const discountEndAt = new Date(data.discount_end_at)
  discountEndAt.setDate(discountEndAt.getDate() - 1)
  const endDate = dateFormat(discountEndAt, 'yyyy年m月d日')
  const sellingPrice = Currency.withTax(6000)
  const discountPrice = Currency.withTax(data.discount_price)
  const normalPrice = Currency.withTax(data.normal_price)

  const handleClose = () => {
    onClose && onClose(data)
  }

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          {data.organization_name}様<br />
          {data.discount_title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.subtitle} variant="subtitle1">
            {endDate}まで<br />
            メンバーズサイトで確認を！
          </Typography>
          <img src={GiftCard} className={classes.img} alt="" />
          <Typography className={classes.notice} variant="body2">
            ＊{endDate}までスタンダードプランの月額ご利用料は{Currency.format(discountPrice)}円になります。<br />
            （販売価格{Currency.format(sellingPrice)}円、{data.burden_label}{Currency.format(sellingPrice - discountPrice)}円）<br />
            ＊キャンペーン終了後は月額{Currency.format(normalPrice)}円になります。<br />
            ＊スタンダードプラン（月額{Currency.format(normalPrice)}円）でご利用中方は、次回のお支払い日から対象期間中のご利用額が{Currency.format(discountPrice)}円になります。
          </Typography>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

DiscountTermExtentionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
}
