import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Chat from 'components/Chat'
import SecretQuestionRoom from 'components/parts/SecretQuestionRoom'
import Api from '../../commons/api'
import Const from '../../commons/constant'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
    },

    '& .MuiDialog-paper': {
      margin: 0,
      width: '100%',
      borderRadius: '25px 25px 0 0',
      maxHeight: '100%',
      height: `calc(${window.innerHeight}px - 18px)`,
    }
  },
  dialogTitle: {
    padding: theme.spacing(0, 0 ,1, 0),
    textAlign: 'right',
    borderBottom: '1px solid #E0E0E0',
  },
  closeIcon: {
    color: '#423BC7',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: '#1A1667',
  },
  content: {
    padding: 0,
    position: 'relative',
  },
  sendTo: {
    fontSize: 14,
    color: '#707070'
  },
  chatInput: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#423BC7',
    gap: theme.spacing(2),
  },
  input: {
    backgroundColor: '#ffffff',
    borderRadius: 3,
    '& .MuiInputBase-root': {
      padding: theme.spacing(1),
    },
  },
  button: {
    width: 30,
    height: 30,
    borderRadius: 50,
    color: '#423BC7',
    backgroundColor: '#ffffff',
  },
}))

const ScheduledChatEditDialog = (props) => {
  const {
    open,
    friend,
    onCancel,
    onEdit,
    onDelete,
    message,
    loading,
  } = props
  const classes = useStyles()

  const [inputValue, setInputValue] = useState()
  const scrollRef = useRef()
  const [secretMessage, setSecretMessage] = useState(null)

  useEffect(() => {
    setInputValue(message?.message)
  }, [message])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [loading])

  useEffect(() => {
    if (!message?.secret_question_title){
      return
    }
    const fetchSecretMessage = async () => {
      const messages = await Api.getSecretRooms(friend.chat_room_id)
      const secretMessage = messages.find(m => m.secret_room_id === message.chat_room_id)
      setSecretMessage(secretMessage)
    }

    fetchSecretMessage()
  }, [message?.secret_question_title])

  const handleSend = () => {
    onEdit(inputValue)
    onCancel()
  }

  const handleDelete = async () => {
    await onDelete()
    onCancel()
  }

  const handleChange = (e) => {
    const inputText = e.target.value
    if (inputText === undefined || inputText === null) { return }
    const msg = inputText.slice(0, Const.maxChatMessageLength)
    setInputValue(msg)
  }

  return (
    <Dialog className={classes.root} open={open} onClose={onCancel}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton className={classes.closeIcon} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
        <Grid container direction="column" alignItems="center">
          <Typography className={classes.title}>
            メッセージを編集する
          </Typography>
          <Typography className={classes.sendTo}>
            送信先：{friend.nick_name}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content} ref={scrollRef}>
        {message?.secret_question_title && secretMessage ?
          <SecretQuestionRoom
            {...props}
            message={secretMessage}
            friend={friend}
            chat_room_id={message.chat_room_id}
            open={true}
            isScheduledChatEditMode={true}
          /> :
          <Chat isScheduledChatEditMode={true} {...props} />
        }
      </DialogContent>
      <DialogActions style={{padding: 0}}>
        <Grid container className={classes.chatInput} wrap={'nowrap'} alignItems={'flex-end'}>
          <TextField className={classes.input}
            fullWidth
            multiline
            InputProps={{ disableUnderline: true }}
            value={inputValue}
            onChange={handleChange}
            placeholder="メッセージ"
            maxRows={10}
          />
          {
            inputValue ? (
              <IconButton className={classes.button} onClick={handleSend}>
                <CheckIcon />
              </IconButton>
            ) : (
              <IconButton className={classes.button} onClick={handleDelete}>
                <CloseIcon />
              </IconButton>
            )
          }
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ScheduledChatEditDialog

ScheduledChatEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  friend: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  message: PropTypes.object,
  loading: PropTypes.bool,
}