import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import FoodReportForm from 'components/foodreport/FoodReportForm'
import FoodReportOutlineDialog from 'components/foodreport/FoodReportOutlineDialog'
import FoodReportShopListDialog from 'components/foodreport/FoodReportShopListDialog'
import FoodReportThemeProvider from 'components/foodreport/FoodReportThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import Kagura from 'images/img_character_a.png'
import Sakaki from 'images/img_character_un.png'
import KaguraFoot from 'images/tutorial/img_footprint_a.png'
import SakakiFoot from 'images/tutorial/img_footprint_un.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: 338,
    fontWeight: 'bold',
    background: '#DBDCFE',
    borderRadius: 22,
    margin: 'auto',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(3.375),
    position: 'relative',
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  },
  sakaki: {
    width: 40,
    position: 'absolute',
    left: 10,
    bottom: 48,
    transform: 'rotate(20deg)',
  },
  kagura: {
    width: 32,
    position: 'absolute',
    right: 20,
    top: 93,
    transform: 'rotate(-15deg)',
  },
  message: {
    fontSize: 12,
    fontWeight: 600,
    color: '#1A1667',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    color: '#423BC7',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  eventName: {
    fontSize: 20,
    fontWeight: 700,
    color: '#555555',
    marginBottom: theme.spacing(1.5),
  },
  eventText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#555555',
    marginBottom: theme.spacing(2),
  },
}))

export default function FoodReportGuidance (props) {
  const classes = useStyles()
  const { type } = props
  const [shopList, setShopList] = useState([])
  const [campaignMaster, setCampaignMaster] = useState(null)

  const [openOutlineDialog, setOpenOutlineDialog] = useState(false)
  const [openShopListDialog, setOpenShopListDialog] = useState(false)
  const [openFormDialog, setOpenFormDialog] = useState(false)

  useEffect(() => {
    if (openOutlineDialog || openFormDialog) {
      (async () => {
        try {
          // お店一覧取得
          // TODO campaign_type は ChatMessage に入っている値を渡す
          const shops = await Api.getShopsOfCampaign('jr_west_poc')
          setShopList(shops)
          // CampaignMaster取得
          const campMaster = await Api.getCampaignMaster({ campaign_type: 'jr_west_poc' })
          setCampaignMaster(campMaster)
        } catch (e) {
          BaseComponent.handleApiError(props, e)
        }
      })()
    }
  }, [openOutlineDialog, openFormDialog])

  const handleOpenOutline = () => {
    setOpenOutlineDialog(true)
  }

  const handleCloseOutline = () => {
    setOpenOutlineDialog(false)
  }

  const handleOpenShopList = () => {
    setOpenOutlineDialog(false)
    setOpenShopListDialog(true)
  }

  const handleCloseShopList = () => {
    setOpenShopListDialog(false)
  }

  const prev = () => {
    handleCloseShopList()
    handleOpenOutline()
  }

  const handleOpenForm = () => {
    setOpenFormDialog(true)
  }

  const handleCloseForm = () => {
    setOpenFormDialog(false)
  }

  return (
    <FoodReportThemeProvider>
      <Grid
        className={classes.root}
        container
        direction="column"
        alignContent="center"
        alignItems="center"
      >
        <img className={classes.sakaki} src={Sakaki} alt="" />
        <img className={classes.kagura} src={Kagura} alt="" />
        <Grid container justifyContent="center" alignItems="center">
          <img src={KaguraFoot} width={22} alt="" />
          <Typography className={classes.message}>
            2人に見えるメッセージ
          </Typography>
          <img src={SakakiFoot} width={22} alt="" />
        </Grid>
        <Typography className={classes.title}>
          {type === 'form' && '食レポイベント前日'}
          {type === 'guidance' && '次のデートは\n２人で食レポイベントに行かない？'}
        </Typography>
        <Typography className={classes.eventName}>
          {type === 'form' && '食レポを投稿しよう！'}
          {type === 'guidance' && '食レポイベント'}
        </Typography>
        <Typography className={classes.eventText}>
          {type === 'form' && '食レポを投稿してお得に利用してね♪'}
          {type === 'guidance' && '2人でイベントに参加すると\n最大2,000円分のギフト券プレゼント！'}
        </Typography>
        {type === 'form' &&
          <Button variant="contained" onClick={handleOpenForm}>
            食レポ投稿フォームはこちら
          </Button>
        }
        {type === 'guidance' &&
          <Button variant="contained" onClick={handleOpenOutline}>
            イベント詳細を見る
          </Button>
        }
      </Grid>
      <FoodReportOutlineDialog
        openOutlineDialog={openOutlineDialog}
        onClose={handleCloseOutline}
        handleOpenShopList={handleOpenShopList}
        campaignMaster={campaignMaster}
        shopList={shopList}
        {...props}
      />
      <FoodReportShopListDialog
        openShopListDialog={openShopListDialog}
        onPrev={prev}
        onClose={handleCloseShopList}
        shopList={shopList}
        {...props}
      />
      <FoodReportForm
        openFormDialog={openFormDialog}
        onClose={handleCloseForm}
        shopList={shopList}
        {...props}
      />
    </FoodReportThemeProvider>
  )
}
FoodReportGuidance.propTypes = {
  type: PropTypes.string.isRequired,
  foodReportId: PropTypes.string,
}