import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import KomainuAdvice from 'components/parts/KomainuAdvice'
import Kagura from 'images/img_new_profile_item_dialog_3.png'
import Sakaki from 'images/img_remind_dialog_4.png'

const useStyles = makeStyles(() => ({
  center: {
    width: 'fit-content',
    textAlign: 'left',
  },
}))

export default function SelfIntroductionNotifyDialog (props) {
  const { open, user, onConfirm, onClose } = props

  const classes = useStyles()
  return (
    <DialogThemeProvider color="secondary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          {user.nick_name}さん<br />
          マッチング率が上がる<br />
          自己紹介文に変更しよう！<br />
          <span style={{ fontSize: 12 }}>(所要時間3分)</span>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">女性が最初に見るポイントは2つ！</Typography>
          <Typography className={classes.center} variant="body1">
            ・自己紹介文<br />
            ・プロフィール写真
          </Typography>
          <KomainuAdvice
            text={'テンプレートを使うと、\n簡単に変更できるよ♪'}
            img01={Kagura}
            img02={Sakaki}
          />
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onConfirm}>
            変更する
          </Button>
          <Button onClick={onClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SelfIntroductionNotifyDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}
