import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import Img01 from 'images/facebook_img_1.png'
import Img02 from 'images/facebook_img_2.png'
import Img03 from 'images/facebook_img_3.png'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
  img: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  note: {
    marginTop: theme.spacing(1),
  },
  actions: {
    paddingTop: theme.spacing(4),
  },
  red: {
    color: '#F55083',
  },
}))

export default function FBAnnounceDialog (props) {
  const { open, onConfirm, onClose, notification } = props
  const classes = useStyles()

  if (!notification) { return null }

  const newFeatureNotice = (notification.notification_type === 'new_feature_fb_connect')

  const createTitle = () => {
    return newFeatureNotice ? 'Facebook連携して\n友達に秘密で使おう！' : 'Facebook\n再連携が必要です'
  }

  const createText = () => {
    return newFeatureNotice ? 'Facebook上で友達の場合\n双方がアカウント連携すると\n自分にも相手にも紹介されません' : '最新の情報を取得するため\n再連携を行なってください。\n再連携をしない場合、\n紹介からお友達を\n除外できなくなります。'
  }

  const createImg = () => {
    return newFeatureNotice ? (
      <>
        <img className={classes.img} src={Img03} height={17.5} />
        <img className={classes.img} src={Img02} width={180} height={94} />
      </>
    ) : <img className={classes.img} src={Img01} width={184} height={169} />
  }

  return (
    <DialogThemeProvider color={newFeatureNotice ? 'primary' : 'default'}>
      <Dialog open={open}>
        <DialogCloseButton onClick={async () => {
          await BaseComponent.updateAlreadyRead(props, notification)
          onClose()
        }} />
        <DialogTitle className={classes.title} disableTypography>
          {createTitle()}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="body1">
            {createText()}
          </Typography>
          {createImg()}
          <Typography className={classes.note} variant="body2">
            ＊2人ともFacebook連携している場合のみ<br />
            ＊連携した場合も投稿は行われません<br />
            <span className={newFeatureNotice ? '' : classes.red}>＊3ヶ月ごとに再連携が必要です</span>
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button variant="contained" onClick={async () => {
            await BaseComponent.updateAlreadyRead(props, notification)
            onConfirm()
          }}>
            連携する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

FBAnnounceDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  notification: PropTypes.object,
}
