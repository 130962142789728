import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import InputBase from '@material-ui/core/InputBase'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  dialogSubTitle: {
    fontSize: 12,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  text: {
    textAlign: 'left',
  },
  reasonSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    ...theme.styles.dialogDivider,
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  input: {
    ...theme.styles.dialogInput,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  error: {
    borderColor: 'red',
  },
}))

const maxReasonInputLength = 300

const reasonValues = {
  feeling: '性格や価値観の不一致',
  privateAddress: 'プライベートアドレス交換の催促',
  business: 'ビジネス目的',
  play: '遊び目的',
  impersonation: '第三者なりすまし',
  improperChat: 'メッセージが不適切',
  others: 'その他',
}

export default function ReasonDialog (props) {
  const { open, friend, onSend, onClose } = props
  const classes = useStyles()
  const [reason, setReason] = useState('feeling')
  const [reasonInput, setReasonInput] = useState('')
  const [sending, setSending] = useState(false)
  const [disabledReasonInput, setDisabledReasonInput] = useState(true)
  const [errorReasonInput, setErrorReasonInput] = useState(false)
  const [reasonConfirmOpen, setReasonConfirmOpen] = useState(false)

  useEffect(() => {
    if (!open) { return }
    BaseComponent.gaModalView('friendcancel')
  }, [open])

  const handleChangeReason = (event) => {
    const value = event.target.value
    setReason(value)
    setDisabledReasonInput(value !== 'others')
    setErrorReasonInput(false)
  }

  const handleChangeReasonInput = (event) => {
    let txt = event.target.value
    if (maxReasonInputLength < txt.length) {
      txt = txt.slice(0, maxReasonInputLength)
    }
    setReasonInput(txt)
    setErrorReasonInput(false)
  }

  const validate = () => {
    const error = reason === 'others' && !reasonInput.trim()
    setErrorReasonInput(error)
    return !error
  }
  const getReasonText = () => {
    return reason === 'others' ? reasonInput : reasonValues[reason]
  }

  const onConfirm = () => {
    onClose()
    setReasonConfirmOpen(true)
  }

  const handleSend = async () => {
    if (!validate()) { return }
    props.setLoading(true)
    setSending(true)
    try {
      const friendId = friend.id
      const msg = getReasonText()
      await BaseComponent.postEntry(props, friendId, 'reject_friend', { to_message: msg })
      if (reason !== 'feeling' && reason !== 'others') {
        await BaseComponent.postEntry(props, friendId, 'report_violation', { to_message: msg })
      }
      onSend()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      setSending(false)
    }
  }

  return (
    <>
      <DialogThemeProvider color="default">
        <Dialog open={open}>
          <DialogTitle disableTypography>友達解消</DialogTitle>
          <DialogContent>
            <Typography className={classes.subtitle} variant="subtitle1">
              友達の解消を行います。
            </Typography>
            <Typography className={classes.text} variant="body1">
              解消する理由を選択してください。<br />
              ※解消理由はお相手に伝わることはございませんので、ご安心ください。
            </Typography>
            <Typography className={classes.dialogSubTitle}>
              理由：
            </Typography>
            <Divider className={classes.divider} />
            <Select
              className={classes.reasonSelect}
              value={reason}
              onChange={handleChangeReason}
              IconComponent={() => <KeyboardArrowDown />}
              disableUnderline
            >
              {Object.keys(reasonValues).map(key => (
                <MenuItem key={key} value={key}>
                  {reasonValues[key]}
                </MenuItem>
              ))}
            </Select>
            <Divider className={classes.divider} />
            <Typography className={classes.dialogSubTitle}>
              理由が「その他」の場合：
            </Typography>
            <InputBase
              className={classes.input}
              classes={{ error: classes.error }}
              value={reasonInput}
              onChange={handleChangeReasonInput}
              disabled={disabledReasonInput}
              error={errorReasonInput}
              placeholder="メッセージを入力"
              multiline
              rows="6"
            />
            {sending && <LinearProgress color="secondary" />}
          </DialogContent>
          <DialogActions disableSpacing>
            <Button variant="contained" onClick={onConfirm}>
              送信
            </Button>
            <Button variant="text" onClick={onClose}>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
      <ConfirmDialog
        open={reasonConfirmOpen}
        onOk={handleSend}
        onCancel={() => setReasonConfirmOpen(false)}
        question="yes"
        title="本当によろしいですか？"
        message={'※友達解消されますと、今後その異性とのコミュニケーションや情報を閲覧することができなくなります。'}
      />
    </>
  )
}

ReasonDialog.propTypes = {
  open: PropTypes.bool,
  friend: PropTypes.object,
  onSend: PropTypes.func,
  onClose: PropTypes.func,
  setLoading: PropTypes.func,
}
