import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { addDate } from 'commons/utility'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 22,
    fontWeight: 600,
    color: '#ffffff',
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(4.5),
    whiteSpace: 'pre-wrap',
  },
  smallText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(3),
  },
  movieBox: {
    width: '100%',
    maxWidth: 320,
    background: '#F6F6F6',
    paddingTop: 36,
    paddingBottom: 27,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: theme.spacing(2.5),
  },
  movieBoxText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#1A1667',
    marginBottom: theme.spacing(2.5),
  },
  thumbnail: {
    width: '100%',
    maxWidth: 288,
    marginBottom: theme.spacing(3),
  },
  button: {
    width: 230,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    background: '#423BC7',
    textTransform: 'none',
  },
  disabledButton: {
    color: '#ffffff !important',
    backgroundColor: '#B0B4FC !important',
  },
}))

export default function TrailerList (props) {
  const { movieEvents, exitPage } = props
  const classes = useStyles()

  const createMovieBox = (data) => {
    const movieTitle = data.movie_title
    const startAt = dateFormat(data.start_at, 'yyyy年m月d日')
    const endAt = dateFormat(addDate(data.end_at, -1), 'm月d日')
    const thumbnailUrl = data.thumbnail_image_url
    const id = data.id

    const checkTerm = () => {
      const now = new Date()
      return new Date(data.start_at) < now && now < new Date(data.end_at)
    }

    return (
      <div key={data.id} className={classes.movieBox}>
        <Typography className={classes.movieBoxText}>{movieTitle}</Typography>
        <img
          className={classes.thumbnail}
          src={thumbnailUrl}
          width={288}
        />
        <Typography className={classes.movieBoxText}>
          {startAt}〜{endAt}
        </Typography>
        <Button
          className={classes.button}
          classes={{ disabled: classes.disabledButton }}
          variant="contained"
          onClick={() => props.setScreen(`Theater/${id}`)}
          disabled={!checkTerm()}
        >
          {checkTerm() ? 'この映画で参加する' : 'Coming soon'}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Typography className={classes.text}>
        {movieEvents.length > 0 && (
          exitPage ? (
            <>
              <span style={{ fontSize: 14 }}>\ 更に参加してみる？ /</span><br />
              <br />
              現在参加できる<br />
              予告編はこちら！
            </>
          ) : (
            <>
              イベント対象の<br />
              映画予告はこちら！
            </>
          )
        )}
      </Typography>
      {movieEvents.map((movieData) => createMovieBox(movieData))}
    </>
  )
}

TrailerList.propTypes = {
  movieEvents: PropTypes.array,
  exitPage: PropTypes.bool,
  setScreen: PropTypes.func,
}
