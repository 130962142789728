import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Api from 'commons/api'
import Const from 'commons/constant'
import BaseComponent from 'components/parts/BaseComponent'
import CropDialog from 'components/parts/CropDialog'
import PhotoDialog from 'components/parts/PhotoDialog'
import PhotoFilter from 'components/parts/PhotoFilter'

const styleImgGallery = {
  height: '100%',
  minHeight: '100%',
  width: '100%',
  objectFit: 'cover',
  backgroundColor: '#C6C6C6',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  ImageListItem: {
    textAlign: 'center',
    width: '100%',
    maxWidth: 96,
    maxHeight: 96,
    boxSizing: 'border-box',
    paddingRight: theme.spacing(0.375),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(0.375),
  },
  imgContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    position: 'relative',
  },
  imgGallery: styleImgGallery,
  imgGalleryAdd: {
    ...styleImgGallery,
    position: 'absolute',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  addIconButton: {
    width: '100%',
    height: '100%',
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: 40,
    height: 40,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: '#1A1667',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
}))

export default function SubPhotos (props) {
  const classes = useStyles()
  const [openCropGalleryDialog, setOpenCropGalleryDialog] = useState(false)
  const [originalFile, setOriginalFile] = useState(null)
  const [imageFileUrl, setImageFileUrl] = useState(null)
  const [galleryItems, setGalleryItems] = useState([])
  const [openPhotoDialogGallery, setOpenPhotoDialogGallery] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)

  const galleryCols = [...Array(Const.maxGalleryItemCount).keys()]

  useEffect(() => {
    loadGalleryItems()
  }, [])

  const loadGalleryItems = async () => {
    props.setLoading(true)
    try {
      const res = await BaseComponent.reloadMyGalleryItems()
      setGalleryItems(res)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleReadImportedGallery = (event) => {
    const originalFile = event.target.files[0]
    if (!originalFile) { return }
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    const imageUrl = createObjectURL(originalFile)
    setOpenCropGalleryDialog(true)
    setOriginalFile(originalFile)
    setImageFileUrl(imageUrl)
  }

  const uploadCroppedGalleryFile = async (file, imgFilters) => {
    props.setLoading(true)
    try {
      // 最大数に達している場合は最初の画像を削除してからアップロード
      if (Const.maxGalleryItemCount <= galleryItems.length) {
        let itemToDel = galleryItems[0]
        await Api.deleteMyGalleryItem(itemToDel)
      }
      await BaseComponent.addGalleryItem(props, file, imgFilters)
      await loadGalleryItems()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      setOpenCropGalleryDialog(false)
      setImageFileUrl(null)
      props.setLoading(false)
    }
  }

  const deletePhoto = async () => {
    props.setLoading(true)
    const item = galleryItems[selectedIndex]
    try {
      await Api.deleteMyGalleryItem(item)
      await loadGalleryItems()
      setSelectedIndex(null)
      setOpenPhotoDialogGallery(false)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <ImageList style={{ margin: 0 }} cols={3}>
          {galleryCols.map(index => (
            (index >= 5 && index > (galleryItems.length - 1)) ? null : (
              <ImageListItem
                className={classes.ImageListItem}
                classes={{ item: classes.ImageListItem }}
                style={{ padding: 0, height: 'auto' }}
              >
                <div className={classes.imgContainer}>
                  {galleryItems[index] ? (
                    <PhotoFilter brightness={galleryItems[index].photo_filter_brightness}>
                      <img
                        className={classes.imgGallery}
                        src={galleryItems[index].icon_url}
                        alt="ギャラリー写真"
                        onClick={() => {
                          setSelectedIndex(index)
                          setOpenPhotoDialogGallery(true)
                        }}
                      />
                    </PhotoFilter>
                  ) : (
                    <div className={classes.imgGalleryAdd}>
                      <IconButton className={classes.addIconButton} htmlFor="fileGallery" component="label">
                        <AddIcon className={classes.addIcon} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </ImageListItem>
            )
          ))}
        </ImageList>
        <Typography className={classes.text}>
          {window.location.pathname !== '/Photo' && '登録時点で写真は自動保存されています。\n'}
          本登録完了後も変更・登録は可能です。
        </Typography>
        <div className={classes.point}></div>
      </div>
      <form noValidate autoComplete="off">
        <input
          id="fileGallery"
          type="file"
          accept="image/*"
          onClick={event => {
            event.target.value = null
            return false
          }}
          onChange={handleReadImportedGallery}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
            display: 'none',
          }}
        />
      </form>
      <CropDialog
        open={openCropGalleryDialog}
        originalFile={originalFile}
        photoUrl={imageFileUrl}
        showFilters={true}
        onUpload={uploadCroppedGalleryFile}
        onClose={() => {
          setOpenCropGalleryDialog(false)
          setImageFileUrl(null)
        }}
        aspectRatio={1 / 1}
        {...props}
      />
      {(!Number.isNaN(selectedIndex) && !!galleryItems[selectedIndex]) && (
        <PhotoDialog
          contentId={'PhotoDialog_Gallery'}
          open={openPhotoDialogGallery}
          imageUrl={galleryItems[selectedIndex].large_url}
          brightness={galleryItems[selectedIndex].photo_filter_brightness}
          onClose={() => setOpenPhotoDialogGallery(false)}
          deletebutton
          onDelete={deletePhoto}
          slider
        />
      )}
    </>
  )
}

SubPhotos.propTypes = {
  setLoading: PropTypes.func,
}
