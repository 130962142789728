import React from 'react'
import loadImage from 'blueimp-load-image'
import dateFormat from 'dateformat'
import $ from 'jquery'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import { expiredUrl } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const urlRegexp = /https?:\/\/\S+/g

// トークメッセージ表示の基底クラス
class CommonSpeech extends BaseComponent {
  constructor (props) {
    super(props)
    this.elementKey = 0
    this.openPhotoDialog = this.openPhotoDialog.bind(this)
    this.handleContextMenu = this.handleContextMenu.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)

    this._isMounted = false

    this.state = {
      openPhotoDialog: false,
      photoLargeUrl: null,
      photoLoading: this.isImageMessage(),
    }
  }

  nextKey () {
    this.elementKey += 1
    return this.elementKey
  }

  // 改行で分割してURLをリンクにする
  createChatMessageElements (color = null, fontSize = null) {
    let allElems = []
    let lines = this.props.message.message.split(/\r\n|\r|\n/)
    for (let line of lines) {
      let elems = this.prepareLinks(line, color, fontSize)
      for (let elem of elems) {
        allElems.push(elem)
      }
    }
    return allElems
  }

  // URLはa要素、それ以外はTypography要素に分割して配列で返す
  prepareLinks (msg, color, fontSize) {
    const style = {}
    if (color) style.color = color

    let urls = msg.match(urlRegexp)
    if (!urls) return [<Typography className={fontSize ? fontSize : ''} style={style} key={this.nextKey()}>{msg}</Typography>]

    let msgElems = []
    let currentIndex = 0
    for (let url of urls) {
      let urlIndex = msg.indexOf(url)
      let beforeText = msg.substring(currentIndex, urlIndex)
      if (0 < beforeText.length) {
        msgElems.push(<Typography className={fontSize ? fontSize : ''} style={style} key={this.nextKey()}>{beforeText}</Typography>)
      }
      msgElems.push(
        <Typography className={fontSize ? fontSize : ''} style={style} key={this.nextKey()}>
          <a
            style={{ color: style.color === Const.colors.rightSpeach ? 'inherit' : null }}
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            {url}
          </a>
        </Typography>
      )

      currentIndex = urlIndex + url.length
    }

    if (currentIndex < msg.length) {
      let afterText = msg.substring(currentIndex, msg.length)
      if (0 < afterText.length) {
        msgElems.push(<Typography className={fontSize ? fontSize : ''} style={style} key={this.nextKey()}>{afterText}</Typography>)
      }
    }

    return msgElems
  }

  getPostedTimeString () {
    if (!this.props.message) return ''
    if (!this.props.message.created_at) return ''

    let date = new Date(this.props.message.created_at)
    return dateFormat(date, 'HH:MM')
  }

  handleContextMenu (e) {
    if (this.props.onOpenMenu) {
      e.preventDefault()
    }
  }

  async componentDidMount () {
    this._isMounted = true
    if (this.isImageMessage()) {
      try {
        let chatPhoto = await this.loadChatPhoto()
        this.loadImage(chatPhoto)
      } catch (error) {
        this.handleApiError(error)
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  isImageMessage () {
    return this.props.message.message_type === 'image' && this.props.message.photo_id
  }

  async loadChatPhoto () {
    let roomId = this.props.message.chat_room_id
    let photoId = this.props.message.photo_id
    let chatPhoto = await Api.getChatPhotoItem(roomId, photoId)
    this.setStateIfMounted({ photoLargeUrl: chatPhoto.large_url })
    return chatPhoto
  }

  loadImage (chatPhoto) {
    this.setStateIfMounted({ photoLoading: true })

    const onLoad = img => {
      this.setStateIfMounted({ photoLoading: false })

      const containerClass = this.props.classes.imageContainer
      const container = $(`#message-${this.props.message.id} .${containerClass}`)
      const id = `img-${this.props.message.id}`
      img.id = id
      const oldElem = $(`#${id}`)
      if (oldElem.length) {
        oldElem.remove()
      }
      container.append(img)
      $(img).click(this.openPhotoDialog)
    }
    loadImage(chatPhoto.preview_url, onLoad)
  }

  async openPhotoDialog () {
    if (expiredUrl(this.state.photoLargeUrl)) {
      this.props.setLoading(true)
      try {
        await this.loadChatPhoto()
        this.setStateIfMounted({ openPhotoDialog: true })
      } catch (error) {
        this.handleApiError(error)
      } finally {
        this.props.setLoading(false)
      }
    } else {
      this.setStateIfMounted({ openPhotoDialog: true })
    }
  }

  closePhotoDialog = () => {
    this.setState({ openPhotoDialog: false })
  }

  deletePhoto =  () => {
    this.props.onDelete(this.props.message)
    this.setState({ openPhotoDialog: false })
  }

  handleTouchStart (e) {
    const el = e.currentTarget
    this.intervalTimer = setTimeout(() => {
      if (this.props.getAnchorEl) {
        this.props.getAnchorEl(el)
      }
      if (this.props.onOpenMenu) {
        this.props.onOpenMenu()
      }
    }, 1000)
  }

  handleTouchMove () {
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer)
      this.intervalTimer = null
    }
  }

  handleTouchEnd () {
    if (this.intervalTimer) {
      clearTimeout(this.intervalTimer)
      this.intervalTimer = null
    }
  }
}

CommonSpeech.propTypes = {
  onOpenMenu: PropTypes.func.isRequired,
}

export default CommonSpeech
