import React from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles} from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Api from '../../commons/api'
import BaseComponent from './BaseComponent'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  cell: {
    borderStyle: 'none',
  },
  item: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    width: 'calc(100% - 24px)',
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    margin: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
    fontSize: 16,
    fontWeight: 'bold',
  },
  text: {
    margin: `0 ${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
  },
  starContainer: {
    height: 24,
    marginTop: -theme.spacing(0.4),
  },
  starIcon: {
    ...theme.styles.starIcon,
  },
  imageContainer: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  image: {
    width: 100,
    height: 100,
    objectFit: 'cover',
    backgroundColor: theme.palette.primary.main,
    float: 'left',
    marginRight: theme.spacing(0.5),
  },
})

export class ShopCell extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)

    this._isMounted = false

    this.state = {
      station_items: [],
      photos: [],
    }
  }

  componentWillMount () {
    if (this.props.showDetail) {
      this.createStationItems(this.props.shop.pref)
    }
    this.getPhotos(this.props.shop.scraping_url, 3)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps) {
    if (this.props.shop !== prevProps.shop) {
      this.setState({ photos: [] })
      this.getPhotos(this.props.shop.scraping_url, 3)
    }
  }

  handleClick () {
    if (this.props.onClick) {
      this.props.onClick()
    }
    this.props.setShopDetail(this.props.shop)
    this.props.setScreen('ShopDetail')
  }

  async getPhotos (url, count) {
    try {
      let photos = await Api.searchPhotoScrapers(url, count)
      this.setStateIfMounted({ photos: photos })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  onClickPhoto (url) {
    if (this.props.showDetail && this.props.onClickPhoto) {
      this.props.onClickPhoto(url)
    }
  }

  createStar (rating) {
    let { classes } = this.props
    return (
      <div className={classes.starContainer}>
        {
          [...Array(5)].map((_, i) =>
            i > rating ? (
              <StarBorderIcon key={i} className={classes.starIcon} />
            ) : (
              <StarIcon key={i} className={classes.starIcon} />
            )
          )
        }
      </div>
    )
  }

  render () {
    const { classes } = this.props

    return (
      <TableRow onClick={this.handleClick}>
        <TableCell component="td" style={{ padding: 0 }} className={classes.cell}>
          <Grid
            className={classes.item}
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography className={classes.title}>
                {this.props.shop.shop_name}
              </Typography>
            </Grid>
            {this.state.photos.length > 0 && (
              <Grid item className={classes.imageContainer}>
                {this.state.photos.map((photo, index) => {
                  return (
                    <img
                      key={index}
                      className={classes.image}
                      src={photo}
                      onClick={() => this.onClickPhoto(this.state.photos[index])}
                      alt="画像"
                      data-testid={`shop-image-${index}`}
                    />
                  )
                })}
              </Grid>
            )}
            <Grid item>
              <Typography className={classes.text}>
                カテゴリ：{this.props.shop.categories}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                特徴：{this.props.shop.features}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="flex-start" justifyContent="center">
                <Grid item>
                  <Typography className={classes.text}>
                    失敗しないお店度
                  </Typography>
                </Grid>
                <Grid item>
                  {this.createStar(this.props.shop.shop_rating)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {(!isNullOrUndefined(this.props.shop.lunch_budget_max) || !isNullOrUndefined(this.props.shop.dinner_budget_max)) && (
                  '予算：'
                )}
                {(!isNullOrUndefined(this.props.shop.lunch_budget_max)) && (
                  '昼 ' + (!isNullOrUndefined(this.props.shop.lunch_budget_min) ? this.props.shop.lunch_budget_min : ' ') + '〜' + this.props.shop.lunch_budget_max + '円'
                )}
                {(!isNullOrUndefined(this.props.shop.lunch_budget_max) && !isNullOrUndefined(this.props.shop.dinner_budget_max)) && (
                  ' / '
                )}
                {(!isNullOrUndefined(this.props.shop.dinner_budget_max)) && (
                  '夜 ' + (!isNullOrUndefined(this.props.shop.dinner_budget_min) ? this.props.shop.dinner_budget_min : ' ') + '〜' + this.props.shop.dinner_budget_max + '円'
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text}>
                {(this.props.shop.open_lunch || this.props.shop.open_dinner) && '時間帯：'}
                {(this.props.shop.open_lunch) && '昼'}
                {(this.props.shop.open_lunch && this.props.shop.open_dinner) && ' / '}
                {(this.props.shop.open_dinner) && '夜'}
              </Typography>
            </Grid>
            {(this.props.shop.take_out && !this.props.showDetail) && (
              <Grid item>
                <Typography className={classes.text} >
                  テイクアウト：○
                </Typography>
              </Grid>
            )}
            {this.props.showDetail && (
              <div>
                {(this.props.shop.reserve_lunch || this.props.shop.reserve_dinner) && (
                  <Grid item>
                    <Typography className={classes.text}>
                      予約：
                      {(this.props.shop.reserve_lunch) && '昼'}
                      {(this.props.shop.reserve_lunch && this.props.shop.reserve_dinner) && ' / '}
                      {(this.props.shop.reserve_dinner) && '夜'}
                    </Typography>
                  </Grid>
                )}
                {this.props.shop.take_out && (
                  <Grid item>
                    <Typography className={classes.text}>
                      テイクアウト：○
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Typography className={classes.text}>
                    最寄駅：{this.getItemLabel(this.props.shop.nearest_station, this.state.station_items)}
                  </Typography>
                </Grid>
                {this.props.shop.address && (
                  <Grid item>
                    <Typography className={classes.text}>
                      住所：{this.props.shop.address}
                    </Typography>
                  </Grid>
                )}
                {this.props.shop.phone_number && (
                  <Grid item>
                    <Typography className={classes.text}>
                      電話番号：{this.props.shop.phone_number}
                    </Typography>
                  </Grid>
                )}
                {this.props.shop.note && (
                  <Grid item>
                    <Typography className={classes.text}>
                      備考：{this.props.shop.note}
                    </Typography>
                  </Grid>
                )}
              </div>
            )}
          </Grid>
        </TableCell>
      </TableRow>
    )
  }
}

ShopCell.propTypes = {
  classes: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  showDetail: PropTypes.bool,
}

export default withStyles(styles)(ShopCell)