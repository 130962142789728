import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PrevIcon from '@material-ui/icons/ArrowBackIos'
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import SearchIcon from '@material-ui/icons/Search'
import Api from 'commons/api'
import { isEmpty, isGlobalNotificationType } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import NotificationCell from 'components/parts/NotificationCell.jsx'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height
  },
  search: {
    backgroundColor: theme.palette.primary.main,
    border: 'solid 1px #999999',
    borderRadius: 10,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: 'relative',
  },
  searchIcon: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: 7.5,
    left: 10,
    pointerEvents: 'none',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  list: {
    overflow: 'auto',
    width: '100%',
    padding: 0,
  },
  buttons: {
    textAlign: 'right',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    marginTop: theme.spacing(1),
    padding: `0px ${theme.spacing(0.5)}px `,
  },
  noData: {
    textAlign: 'center',
    padding: theme.spacing(2),
    opacity: 0.5,
  },
  readButton: {
    ...theme.styles.entryButton,
    float: 'left',
    color: theme.palette.primary.contrastText,
  },
}))

export default function Notifications (props) {
  const { loading, ...other } = props
  const classes = useStyles()
  const [notifications, setNotifications] = useState([])
  const [notificationsFromAill, setNotificationsFromAill] = useState([])
  const [aillReadIds, setAillReadIds] = useState([])
  const [nextPageKey, setNextPageKey] = useState(null)
  const [pageKeys, setPageKeys] = useState([])
  const [nextDisabled, setNextDisabled] = useState(true)
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [createdTimes, setCreatedTimes] = useState(null)

  useLayoutEffect(() => {
    loadNotifications(null, null)
  }, [])

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  const loadNotifications = async (pageKey, nickName) => {
    props.setLoading(true)
    try {
      const res = await Api.getNotifications(pageKey, null, nickName)
      let notifications = res.notifications
      if (!nickName) {
        if (notifications.length > 0) {
          const firstDate = new Date(pageKey ? (Number(pageKey) * 1000) : notifications[0].created_at)
          const lastDate = new Date(notifications[notifications.length - 1].created_at)
          const aillNotifications = await getAillNotifications()
          aillNotifications.map(notification => {
            const date = new Date(notification.notification_date)
            if ((date < firstDate || !pageKey) && (date > lastDate || !res.next_page_key)) {
              notifications.push(notification)
            }
          })
        } else {
          notifications = await getAillNotifications()
        }
        notifications.sort(function (a, b) {
          const date_a = isGlobalNotificationType(a.notification_type) ? a.notification_date : a.created_at
          const date_b = isGlobalNotificationType(b.notification_type) ? b.notification_date : b.created_at
          if (date_a < date_b) return 1
          if (date_a > date_b) return -1
          return 0
        })
      }
      setNotifications(notifications)

      if (nickName) {
        const created_times = []
        res.notifications.map(data => !data.already_read && created_times.push(data.created_time))
        setNextPageKey(null)
        setPageKeys([])
        setNextDisabled(true)
        setPrevDisabled(true)
        setSearchText(nickName)
        setCreatedTimes(created_times)
      } else {
        const pKeys = []
        if (pageKey && pageKey === nextPageKey) {
          pKeys.push(pageKey)
        }
        if (pKeys.length > 0 && pKeys[pKeys.length - 1] === res.next_page_key) {
          pKeys.push(pageKey)
        }
        setNextPageKey(res.next_page_key)
        setPageKeys(pKeys)
        setSearchText('')
        setCreatedTimes(null)
        setNextDisabled(res.next_page_key ? false : true)
        setPrevDisabled(pKeys.length > 0 ? false : true)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const getAillNotifications = async () => {
    const aillNotifications = await Api.getGlobalNotifications()
    const aill_read_ids = await Api.getReadIds('GlobalNotification')
    setNotificationsFromAill(aillNotifications)
    setAillReadIds(aill_read_ids || [])
    return aillNotifications
  }

  const searchNotifications = (text) => {
    if (text.length > 1) {
      loadNotifications(null, text)
    } else if (text.length) {
      setNotifications([])
      setNextPageKey(null)
      setPageKeys([])
      setNextDisabled(true)
      setPrevDisabled(true)
      setSearchText(text)
    } else {
      loadNotifications(null, null)
    }
  }

  const handleAllAlreadyRead = async () => {
    props.setLoading(true)
    try {
      if (notificationsFromAill.length > 0) {
        const update_read_ids = []
        notificationsFromAill.map(notification => {
          if (!aillReadIds.includes(notification.id)) {
            update_read_ids.push(notification.id)
          }
        })
        if (update_read_ids.length > 0) {
          const params = { target: 'GlobalNotification', read_ids: update_read_ids }
          await Api.createReadState(params)
          const res = await Api.getReadIds('GlobalNotification')
          setAillReadIds(res)
        }
      }
      if (isEmpty(searchText) || createdTimes.length > 0) {
        await Api.updateAlreadyReadNotifications(createdTimes)
        const text = isEmpty(searchText) ? null : searchText
        loadNotifications(null, text)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const clickPrevButton = () => {
    if (pageKeys.length === 0) { return }
    const pKey = pageKeys.length > 1 ? pageKeys[pageKeys.length - 2] : null
    loadNotifications(pKey, null)
  }

  const clickNextButton = () => {
    if (!nextPageKey) { return }
    loadNotifications(nextPageKey, null)
  }

  return (
    <>
      <AppHeader title="お知らせ" hideHome {...other} />
      <div className={classes.root}>
        <div className={classes.search}>
          <Grid className={classes.searchIcon} container justifyContent="center" alignItems="center">
            <SearchIcon color="action" />
          </Grid>
          <Input
            placeholder="ニックネーム検索"
            disableUnderline
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{
              onKeyDown: (e) => { if (e.keyCode === 13) { e.target.blur() } },
              onBlur: (e) => searchNotifications(e.target.value),
            }}
          />
        </div>
        {notifications.length > 0 ? (
          <div>
            <div className={classes.buttons}>
              <Button className={classes.readButton} onClick={handleAllAlreadyRead}>
                <u>すべて既読にする</u>
              </Button>
              <IconButton className={classes.iconButton} disabled={prevDisabled} onClick={clickPrevButton} >
                <PrevIcon />
              </IconButton>
              <IconButton className={classes.iconButton} disabled={nextDisabled} onClick={clickNextButton} >
                <NextIcon />
              </IconButton>
            </div>
            <List className={classes.list}>
              {notifications.map((value, key) => (
                <NotificationCell
                  key={key}
                  notification={value}
                  readIds={aillReadIds}
                  parent={props}
                  {...other}
                />
              ))}
            </List>
          </div>
        ) : (
          !loading && (
            <Typography className={classes.noData}>
              {!isEmpty(searchText) ? `「${searchText}」の友達が見つかりません` : '新着はありません'}
            </Typography>
          )
        )}
      </div>
    </>
  )
}

Notifications.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
}
