/*
* APIを叩くなどの動作はここに書く、reducerに渡す処理
*/

//import AppActions from '../actions/app'で呼び出し
export default {
  screen: (screen) => {
    return { type: 'SCREEN', screen: screen }
  },
  authId: (authId) => {
    return { type: 'AUTH_ID', authId: authId }
  },
  mailAddress: (mailAddress) => {
    return { type: 'MAIL_ADDRESS', mailAddress: mailAddress }
  },
  preRegistered: (preRegistered) => {
    return { type: 'PRE_REGISTERED', preRegistered }
  },
  password: (password) => {
    return { type: 'PASSWORD', password: password }
  },
  isLogin: (isLogin) => {
    return { type: 'IS_LOGIN', isLogin: isLogin }
  },
  userId: (userId) => {
    return { type: 'USER_ID', userId: userId }
  },
  master: (master) => {
    return { type: 'MASTER', master: master}
  },
  user: (user) => {
    return { type: 'USER', user: user}
  },
  userImage: (userImage) => {
    return { type: 'USER_IMAGE', userImage: userImage}
  },
  identifyImage: (identifyImage) => {
    return { type: 'IDENTIFY_IMAGE', identifyImage: identifyImage}
  },
  affiliationImage: (affiliationImage) => {
    return { type: 'AFFILIATION_IMAGE', affiliationImage: affiliationImage }
  },
  federationToken: (federationToken) => {
    return { type: 'FEDERATION_TOKEN', federationToken: federationToken}
  },
  friend: (friend) => {
    return { type: 'FRIEND', friend: friend}
  },
  friends: (friends) => {
    return { type: 'FRIENDS', friends: friends}
  },
  entries: (entries) => {
    return { type: 'ENTRIES', entries: entries}
  },
  appLoading: (appLoading) => {
    return { type: 'APPLOADING', appLoading: appLoading}
  },
  loading: (loading) => {
    return { type: 'LOADING', loading: loading}
  },
  organizationId: (organizationId) => {
    return { type: 'ORGANIZATION_ID', organizationId: organizationId }
  },
  myOrganization: (myOrganization) => {
    return { type: 'MY_ORGANIZATION', myOrganization: myOrganization }
  },
  errorMessage: (errorMessage) => {
    return { type: 'ERROR_MESSAGE', errorMessage }
  },
  importantErrorMessage: (importantErrorMessage) => {
    return { type: 'IMPORTANT_ERROR_MESSAGE', importantErrorMessage }
  },
  onCloseErrorMessage: (onCloseErrorMessage) => {
    return { type: 'ON_CLOSE_ERROR_MESSAGE', onCloseErrorMessage }
  },
  onCloseImportantErrorMessage: (onCloseImportantErrorMessage) => {
    return { type: 'ON_CLOSE_IMPORTANT_ERROR_MESSAGE', onCloseImportantErrorMessage }
  },
  requestSuccessMessage: (requestSuccessMessage) => {
    return { type: 'REQUEST_SUCCESS_MESSAGE', requestSuccessMessage }
  },
  onCloseRequestSuccessMessage: (onCloseRequestSuccessMessage) => {
    return { type: 'ON_CLOSE_REQUEST_SUCCESS_MESSAGE', onCloseRequestSuccessMessage }
  },
  firstNaviFlag: (firstNaviFlag) => {
    return { type: 'FIRST_NAVI_FLAG', firstNaviFlag }
  },
  hideAppNavigation: (hideAppNavigation) => {
    return { type: 'HIDE_APP_NAVIGATION', hideAppNavigation }
  },
  company: (company) => {
    return { type: 'COMPANY', company: company }
  },
  newMessage: (newMessage) => {
    return { type: 'NEW_MESSAGE', newMessage }
  },
  shopSearch: (shopSearch) => {
    return { type: 'SHOP_SEARCH', shopSearch }
  },
  shopDetail: (shopDetail) => {
    return { type: 'SHOP_DETAIL', shopDetail }
  },
  matchings: (matchings) => {
    return { type: 'MATCHINGS', matchings: matchings}
  },
  notifications: (notifications) => {
    return { type: 'NOTIFICATIONS', notifications: notifications}
  },
  chatrooms: (chatrooms) => {
    return { type: 'CHATROOMS', chatrooms: chatrooms}
  },
  admin: (admin) => {
    return { type: 'ADMIN', admin: admin}
  },
  idText: (idText) => {
    return { type: 'ID_TEXT', idText }
  },
  affiliationText: (affiliationText) => {
    return { type: 'AFFILIATION_TEXT', affiliationText }
  },
  matchingState: (matchingState) => {
    return { type: 'MATCHING_STATE', matchingState }
  },
  subscription: (subscription) => {
    return { type: 'SUBSCRIPTION', subscription }
  },
  tutorialReadStates: (tutorialReadStates) => {
    return { type: 'TUTORIAL_READ_STATES', tutorialReadStates }
  },
  tutorialView: (tutorialView) => {
    return { type: 'TUTORIAL_VIEW', tutorialView }
  },
  specialOffers: (specialOffers) => {
    return { type: 'SPECIAL_OFFERS', specialOffers }
  },
  campaign: (campaign) => {
    return { type: 'CAMPAIGN', campaign }
  },
  phoneNumber: (phoneNumber) => {
    return { type: 'PHONE_NUMBER', phoneNumber }
  },
  isPhoneVerified: (isPhoneVerified) => {
    return { type: 'IS_PHONE_VERIFIED', isPhoneVerified }
  },
  disableSmsVerification: (disableSmsVerification) => {
    return { type: 'DISABLE_SMS_VERIFICATION', disableSmsVerification }
  },
  advicePhoto: (advicePhoto) => {
    return { type: 'ADVICE_PHOTO', advicePhoto }
  },
}
