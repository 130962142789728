import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Currency from 'commons/currency'
import { canUseCafeteriaPoint, canUseRefresh5000 } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  publishButton: {
    fontSize: 14,
    fontWeight: 600,
    color: '#423BC7',
    padding: 0,
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
  },
  pagenation: {
    '& .MuiToolbar-root': {
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    '& .MuiTablePagination-selectRoot': {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 0,
    },
  },
  // ##### PC #####
  historyTable: {
    '& th': {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#707070',
      whiteSpace: 'nowrap',
    },
    '& td': {
      fontSize: 14,
      color: '#707070',
      whiteSpace: 'nowrap',
    },
  },
  // ##### SP #####
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
  },
  item: {
    fontSize: 14,
    color: '#707070',
    marginRight: theme.spacing(1.5),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}))

export default function PaymentHistory (props) {
  const { billings, organization } = props
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [billingsData, setBillingsData] = useState([])
  const spMatches = !useMediaQuery('(min-width:1080px)')

  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number))
  }

  useEffect(() => {
    if (!billings || billings.length === 0) { return }
    const sliceArray = sliceByNumber(billings.filter(b => b.status === 'successful'), rowsPerPage)
    setBillingsData(sliceArray[page])
  }, [billings, page, rowsPerPage])

  const downloadReceiptPdf = async (billing) => {
    props.setLoading(true)
    try {
      const blob = await Api.getReceipt(billing.id)
      const myBillings = await Api.getMyBillings()
      setBillingsData(myBillings)
      const anchor = document.createElement('a')
      anchor.download = `Aill領収書_${billing.num}.pdf`
      anchor.href = URL.createObjectURL(blob)
      anchor.click()
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const createPublishButton = (billing) => {
    if (!billing) { return null }
    const label = billing.published_count === 0 ? '発行' : '再発行'
    return (
      <>
        <Button className={classes.publishButton} variant="text" onClick={() => downloadReceiptPdf(billing)}>
          {label}
        </Button>
        {billing.published_count !== 0 && '*再発行は1度までです'}
      </>
    )
  }

  if (!billingsData) {
    return <Typography>読み込み中…</Typography>
  }

  if (billingsData.length === 0) {
    return <Typography>お支払い履歴はありません。</Typography>
  }

  // TODO 「お支払い方法」列は一旦省略
  return (
    <>
      {spMatches ? (
        <>
          {billingsData.filter(b => b.status === 'successful').map((billing, index) => (
            <Card key={index} className={classes.root} variant="outlined">
              <CardContent className={classes.content}>
                <Grid container alignItems="center">
                  <Typography className={classes.item}>
                    <strong>日付&nbsp;&nbsp;</strong>
                    {dateFormat(billing.created_at, 'yyyy年m月d日')}
                  </Typography>
                  <Typography className={classes.item}>
                    <strong>内容&nbsp;&nbsp;</strong>
                    {billing.title}
                  </Typography>
                  <Typography className={classes.item}>
                    <strong>お支払い額&nbsp;&nbsp;</strong>
                    {Currency.format(billing.amount)} 円
                  </Typography>
                  {(canUseCafeteriaPoint(organization) || canUseRefresh5000(organization)) && (
                    <Typography className={classes.item}>
                      <strong>領収書&nbsp;&nbsp;</strong>
                      {createPublishButton(billing)}
                    </Typography>
                  )}
                  {(billing.campaign_titles && 0 < billing.campaign_titles.length) && (
                    <Typography className={classes.item}>
                      <strong>備考&nbsp;&nbsp;</strong>
                      {billing.campaign_titles.join(',')}適用済
                    </Typography>
                  )}
                </Grid>
              </CardContent>
            </Card>
          ))}
        </>
      ) : (
        <TableContainer component='div'>
          <Table className={classes.historyTable}>
            <TableHead>
              <TableRow>
                <TableCell>日付</TableCell>
                <TableCell>内容</TableCell>
                <TableCell>お支払い額</TableCell>
                <TableCell>備考</TableCell>
                {(canUseCafeteriaPoint(organization) || canUseRefresh5000(organization)) && (
                  <TableCell>領収書</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {billingsData.filter(b => b.status === 'successful').map((billing, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{dateFormat(billing.created_at, 'yyyy年m月d日')}</TableCell>
                    <TableCell>{billing.title}</TableCell>
                    <TableCell>{Currency.format(billing.amount)} 円</TableCell>
                    <TableCell>
                      {(billing.campaign_titles && 0 < billing.campaign_titles.length) && (
                        `${billing.campaign_titles.join(',')}適用済`
                      )}
                    </TableCell>
                    {(canUseCafeteriaPoint(organization) || canUseRefresh5000(organization)) && (
                      <TableCell>
                        {createPublishButton(billing)}
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        className={classes.pagenation}
        component="div"
        count={billings.filter(b => b.status === 'successful').length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[6, 12, 24]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='表示数：'
        labelDisplayedRows={({ from, to, count }) => `${from}-${to}/${count}`}
      />
    </>
  )
}

PaymentHistory.propTypes = {
  billings: PropTypes.array,
  organization: PropTypes.object,
  setLoading: PropTypes.func,
}
