import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  title: {
    color: '#707070',
    textAlign: 'center',
  },
  text: {
    color: '#707070',
  },
  linkItem: {
    listStyle: 'square',
    paddingTop: theme.spacing(1),
  }
}))

export default function SmsSettingsNotice (props) {
  const classes = useStyles()
  const { title } = props

  const createLink = (carrierName, url) => {
    return (
      <Link href={url} target="_blank">
        {carrierName}をご利用の場合
      </Link>
    )
  }

  const links = [
    createLink('Softbank', 'https://www.softbank.jp/mobile/support/mail/antispam/mms/fromoversea/'),
    createLink('au', 'https://www.au.com/support/service/mobile/trouble/mail/cmail/block/'),
    createLink('docomo', 'https://www.docomo.ne.jp/info/spam_mail/sms/'),
    createLink('Y!mobile', 'https://www.ymobile.jp/support/faq/view/24098'),
    createLink('UQ mobile', 'https://www.uqwimax.jp/mobile/plan/option/sms/filter/'),
    createLink('楽天モバイル', 'https://network.mobile.rakuten.co.jp/faq/detail/00001362/'),
  ]

  return (
    <div className={classes.root}>
      {title &&
        <Typography variant="body1" className={classes.title}>
          【{title}】
        </Typography>
      }
      <Typography variant="body2" className={classes.text}>
        認証コードの送信はAmazon Web Services経由となるため、送信元が海外となります。<br />
        お客様の迷惑メール拒否設定をご確認いただき、海外からのSMSを受信するように設定の変更をお願いいたします。
      </Typography>
      <ul>
        {links.map((elem, index) => (
          <li key={index} className={classes.linkItem}>{elem}</li>
        ))}
      </ul>
    </div>
  )
}

SmsSettingsNotice.propTypes = {
  title: PropTypes.string,
}
