import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/KeyboardArrowDown'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

const useStyles = makeStyles(theme => ({
  selectBox: {
    '& div.MuiSelect-select': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
      fontSize: 14,
    },
    '& svg.MuiSelect-icon': {
      color: '#423BC7',
      width: 15,
    }
  },
  box: {
    margin: theme.spacing(1.5)
  },
}))

export default function SelectBox (props) {
  const { index, selectedItems, onChange, template } = props
  const item = selectedItems[index]
  const [category, setCategory] = useState(item.category)
  const [title, setTitle] = useState(item.title)

  const onChangeCategory = event => {
    const val = event.target.value
    setCategory(val)
    setTitle('')
    if (onChange) {
      onChange(index, val, '')
    }
  }

  const onChangeTitle = event => {
    const val = event.target.value
    setTitle(val)
    if (onChange) {
      onChange(index, category, val)
    }
  }

  const titles = template.categoryAndItem[category] || []

  const duplicatedTitle = (title) => {
    if (!category) { return false }

    // すでに選択している内容は選択不可
    const selectedTitles = selectedItems.map(i => i.title)
    return selectedTitles.includes(title)
  }

  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography>
        {selectedItems.length > 1 && `${index + 1}つ目の`}「カテゴリ」を選択
      </Typography>
      <FormControl fullWidth>
        <Select
          id={`select-category-${index}`}
          data-testid={`select-category-${index}`}
          className={classes.selectBox}
          value={category}
          onChange={onChangeCategory}
          IconComponent={EditIcon}
          MenuProps={{
            PaperProps: {
              style: { maxHeight: 250, width: 250 },
            },
          }}
          displayEmpty
          renderValue={(selected) => (
            (selected.length === 0) ? <em>選択してください</em> : selected
          )}
        >
          <MenuItem value="">未選択</MenuItem>
          {Object.keys(template.categoryAndItem).map((category, index) => (
            <MenuItem key={index} value={category} className={classes.menuItem}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography>
        {selectedItems.length > 1 && `${index + 1}つ目の`}「内容」を選択
      </Typography>
      <FormControl fullWidth>
        <Select
          id={`select-item-${index}`}
          data-testid={`select-item-${index}`}
          className={classes.selectBox}
          value={title}
          onChange={onChangeTitle}
          IconComponent={EditIcon}
          MenuProps={{
            PaperProps: {
              style: { maxHeight: 250, width: 250 },
            },
          }}
          displayEmpty
          renderValue={(selected) => (
            (selected.length === 0) ? <em>選択してください</em> : selected
          )}
        >
          {0 < titles.length ? (
            titles.map((title, index) => (
              <MenuItem
                key={index}
                value={title}
                className={classes.menuItem}
                disabled={duplicatedTitle(title)}
              >
                {title}
              </MenuItem>
            ))
          ) : (
            <MenuItem value='' className={classes.menuItem}>
              カテゴリを選択してください
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Box className={classes.box} />
    </React.Fragment>
  )
}
SelectBox.propTypes = {
  index: PropTypes.number.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  template: PropTypes.object,
}