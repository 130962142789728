import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputBase from '@material-ui/core/InputBase'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  title: {
    fontSize: 13,
    paddingBottom: theme.spacing(2),
  },
  count: {
    width: 'fit-content',
    fontSize: 13,
    fontWeight: 600,
    color: '#423BC7',
    background: '#DBDCFE',
    lineHeight: 1,
    borderRadius: 15,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  question: {
    marginBottom: theme.spacing(4),
  },
  questionText: {
    fontSize: 14,
    letterSpacing: '0.35px',
    lineHeight: '22.4px',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  formControl: {
    '& label': {
      display: 'flex',
    },
  },
  labelRoot: {
    marginBottom: theme.spacing(1.25),
    marginLeft: theme.spacing(2),
    '& span': {
      padding: 0,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    textAlign: 'left',
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  radio: {
    width: 18,
    height: 18,
    color: '#423BC7',
    padding: 0,
  },
  checkbox: {
    color: '#423BC7',
    '& input': {
      width: 19,
      height: 19,
    },
    '& svg': {
      width: 19,
      height: 19,
    },
  },
  input: {
    width: 230,
    height: 101,
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    background: '#FFFFFFFF',
    border: '1px solid #423BC7',
    borderRadius: 8,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& textarea': {
      width: '100%',
      height: '100%',
    },
  },
  disabledInput: {
    width: 230,
    height: 101,
    fontSize: 12,
    fontWeight: 600,
    color: '#e0e0e0',
    background: '#FFFFFFFF',
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& textarea': {
      width: '100%',
      height: '100%',
    },
  },
  cansel: {
    marginTop: `${theme.spacing(3.5)}px !important`,
  },
})

const maxInputLength = 300

export class UserHearingDialog extends BaseComponent {
  constructor (props) {
    super(props)
    this.handleSend = this.handleSend.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.handleChangeChecked = this.handleChangeChecked.bind(this)
    this.handleClickChecked = this.handleClickChecked.bind(this)

    this._isMounted = false

    this.state = {
      survey_items: [],
      survey_count: 1,
      answer_1: null,
      answer_2: null,
      answer_3: null,
      answer_4: null,
      answer_5: null,
      answer_6: null,
      answer_7: null,
      answer_8: null,
      answer_9: null,
      answer_10: null,
      answer_11: null,
      answer_12: null,
      answer_13: null,
      answer_14: null,
      answer_15: null,
      answer_16: null,
      answer_17: null,
      answer_18: null,
      answer_19: null,
      answer_20: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.getSurveyItems(this.props.master.survey_type)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  async getSurveyItems (surveyType) {
    this.props.setLoading(true)
    try {
      let surveyItems = await Api.getSurveyItems(surveyType)
      this.setStateIfMounted({ survey_items: surveyItems })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async handleSend () {
    this.props.setLoading(true)
    try {
      const params = {
        user_id: this.props.user.id,
        survey_type: this.props.master.survey_type,
        survey_count: this.state.survey_count,
      }
      this.state.survey_items.map(item => {
        params['answer_' + item.order] = this.state['answer_' + item.order]
      })
      await Api.createSurvey(params)
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
      this.props.onClose()
      this.props.onDisplayed()
    }
  }

  async handleChange (event) {
    const name = event.target.name
    const value = event.target.value
    this.setStateIfMounted({ [name]: value })
  }

  handleChangeChecked (event) {
    const name = event.target.name
    const value = event.target.value
    if (event.target.checked) {
      if (!this.state[name]) {
        this.setStateIfMounted({ [name]: value })
      } else {
        // 「,」で連結
        this.setStateIfMounted({ [name]: this.state[name] + ',' + value })
      }
    } else {
      if (this.state[name] === value) {
        this.setStateIfMounted({ [name]: null })
      } else {
        // 「,~」or「~,」を削除
        const new_value = this.state[name].replace(',' + value, '').replace(value + ',', '')
        this.setStateIfMounted({ [name]: new_value })
      }
    }
  }

  handleClickChecked (event) {
    if (event.target.checked && this.state[event.target.name]) {
      const order = event.target.name.slice(7)
      const max_select_count = this.state.survey_items[order - 1].max_select_count || null
      const select_count = this.state[event.target.name].split(',').length
      if (!max_select_count || max_select_count > select_count) {
        // ignore
      } else {
        event.target.click()
      }
    }
  }

  handleChangeInput (event) {
    const name = event.target.name
    const value = event.target.value
    this.setStateIfMounted({ [name]: value.slice(0, maxInputLength) })
  }

  isAnswered () {
    if (this.state.survey_items.lentgh === 0) { return false }
    let result = true
    this.state.survey_items.map(item => {
      if (item.question.includes('任意')) { return }
      const answer = this.state['answer_' + item.order]
      // 回答が空（依存値がない場合）
      if (!answer && !item?.depend_to_order && !item?.depend_to_answer) { result = false }
      // 回答が空（依存値を選択している場合）
      if (!answer && (item?.depend_to_answer || []).some(target => (answer || '').includes(target))) { result = false }
    })
    return result
  }

  textConvertMultilines (text) {
    let row = text.split(/\r\n|\r|\n/).length
    if (row === 1) { return text }
    return (
      text.split(/\r\n|\r|\n/).map(
        (line, key) => <span key={key}>{line}<br /></span>
      )
    )
  }

  createMultiSelectBox (item) {
    const { classes } = this.props
    if (!item?.answer_choices) { return null }
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        {item.answer_choices.map((answer) => (
          <FormControlLabel
            key={item.id + answer}
            classes={{ root: classes.labelRoot, label: classes.label }}
            value={answer}
            control={
              <Checkbox
                className={classes.checkbox}
                name={'answer_' + item.order}
                onChange={this.handleChangeChecked}
                onClick={this.handleClickChecked}
              />
            }
            label={answer}
          />
        ))}
      </FormControl>
    )
  }

  createSelectBox (item) {
    const { classes } = this.props
    if (!item?.answer_choices) { return null }
    const disabled = () => {
      if (!item?.depend_to_order) { return false }
      if (!item?.depend_to_answer) { return false }
      const answer = this.state['answer_' + item.depend_to_order]
      if (!answer) { return true }
      return !item.depend_to_answer.some(target => answer.includes(target))
    }
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup name={'answer_' + item.order} onChange={this.handleChange}>
          {(item.answer_choices || []).map((answer) => (
            <FormControlLabel
              classes={{ root: classes.labelRoot, label: classes.label }}
              key={item.id + answer}
              value={answer}
              control={<Radio className={classes.radio} />}
              label={answer}
              disabled={disabled()}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  createInput (item) {
    const { classes } = this.props
    const disabled = () => {
      if (!item?.depend_to_order) { return false }
      if (!item?.depend_to_answer) { return false }
      const answer = this.state['answer_' + item.depend_to_order]
      if (!answer) { return true }
      return !item.depend_to_answer.some(target => answer.includes(target))
    }
    return (
      <InputBase
        name={'answer_' + item.order}
        value={this.state['answer_' + item.order]}
        onChange={this.handleChangeInput}
        className={disabled() ? classes.disabledInput : classes.input}
        disabled={disabled()}
        placeholder={`テキストを入力（最大${maxInputLength}文字）`}
        multiline
        rows="10"
      />
    )
  }

  createQuestions (item) {
    const { classes } = this.props
    return (
      <div key={item.id} className={classes.question}>
        <Typography className={classes.questionText} variant="body1">
          {item.display_num || item.order}. {this.textConvertMultilines(item.question)}
        </Typography>
        {!item?.answer_choices ? (
          this.createInput(item)
        ) : item.multi_select ? (
          this.createMultiSelectBox(item)
        ) : (
          this.createSelectBox(item)
        )}
      </div>
    )
  }

  getQuestionCount = () => {
    const items = this.state.survey_items
    if (!items || items.length === 0) { return 0 }

    const nums = items.map(item => (item.display_num || new String(item.order)))
    const uniqNums = new Set(nums.map(n => n.split('-')[0]))
    return uniqNums.size
  }

  render () {
    const { classes, open, children, myOrganization } = this.props
    if (!myOrganization) { return null }
    return (
      <>
        {children}
        <DialogThemeProvider color="default">
          <Dialog open={open && this.state.survey_items.length > 0} style={{ zIndex: 9998 }}>
            <DialogTitle className={classes.title} disableTypography>
              {this.textConvertMultilines(this.props.master.title)}
            </DialogTitle>
            <DialogContent>
              <div className={classes.count}>
                全{this.getQuestionCount()}問
              </div>
              {this.state.survey_items.map((item) => this.createQuestions(item))}
              <DialogActions disableSpacing>
                <Button variant="contained" onClick={this.handleSend} disabled={!this.isAnswered()}>
                  送信する
                </Button>
                <Button className={classes.cansel} variant="text" onClick={this.props.onClose}>
                  キャンセル
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </DialogThemeProvider>
      </>
    )
  }
}

UserHearingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onDisplayed: PropTypes.func,
  master: PropTypes.object.isRequired,
}

export default withStyles(styles)(UserHearingDialog)