import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ChartIcon from '@material-ui/icons/InsertChart'
import LinkIcon from '@material-ui/icons/Link'
import PasswordIcon from '@material-ui/icons/Lock'
import PersonIcon from '@material-ui/icons/PersonAdd'
import BaseComponent from 'components/parts/BaseComponent'
import PartnerPage from 'components/parts/PartnerPage'

const styles = theme => ({
  list: {
    width: theme.spacing(30),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export class PartnerHome extends BaseComponent {
  componentDidMount () {
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <PartnerPage title="パートナーメニュー" {...others}>
        <List component="nav" className={classes.list}>
          {
            this.props.admin &&
            <ListItem button onClick={() => this.props.setScreen('/PartnerLink')}>
              <ListItemIcon><LinkIcon /></ListItemIcon>
              <ListItemText>リンク作成</ListItemText>
            </ListItem>
          }
          <ListItem button onClick={() => this.props.setScreen('/PartnerResults')}>
            <ListItemIcon><ChartIcon /></ListItemIcon>
            <ListItemText>入会実績</ListItemText>
          </ListItem>
          {
            (this.props.admin || this.props.user.master) &&
            <ListItem button onClick={() => this.props.setScreen('/PartnerUsers')}>
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText>ユーザー管理</ListItemText>
            </ListItem>
          }
          <ListItem button onClick={() => this.props.setScreen('/PartnerPasswordChange')}>
            <ListItemIcon><PasswordIcon /></ListItemIcon>
            <ListItemText>パスワード変更</ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.logout()}>
            <ListItemIcon><ArrowBackIcon /></ListItemIcon>
            <ListItemText>ログアウト</ListItemText>
          </ListItem>
        </List>
      </PartnerPage>
    )
  }
}

PartnerHome.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PartnerHome)