import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Img from 'images/img_mail_star.png'

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(1.5),
  },
  message: {
    fontSize: 14,
    color: '#1A1667',
    backgroundColor: '#ffffff',
    width: 216,
    height: 120,
    padding: '6px 0 7px',
    marginTop: theme.spacing(1.5),
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: 'none',
    borderRadius: theme.spacing(1),
  },
  image: {
    textAlign: 'center',
  },
}))

export default function NotifyLoveReceiveDialog (props) {
  const { open, friend } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="primary">
      <Dialog key={friend.id} open={open || false}>
        <DialogTitle disableTypography>
          お付き合いリクエストが<br />届きました！
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="body1">
            {friend.nick_name}さんからのメッセージです
          </Typography>
          <div className={classes.image}><img alt="" src={Img} /></div>
          <Grid className={classes.message}>
            {friend.entry_request_message}
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={props.handleNext}>
            注意事項に進む
          </Button>
          <Button variant="text" onClick={props.handleHold}>
            保留する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}
NotifyLoveReceiveDialog.propTypes = {
  open: PropTypes.bool,
  friend: PropTypes.object,
  handleNext: PropTypes.func,
  handleHold: PropTypes.func,
}