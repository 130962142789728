import * as Sentry from '@sentry/react'
import IndexedDB from 'commons/indexed_db'
import Storage, * as LocalStorage from 'commons/local_storage'
export default Storage
export * from 'commons/local_storage'

// ======================================================================
// トークン関連
// ======================================================================
const KEY_AILL_TOKEN = 'aill_token'

export async function getToken () {
  try {
    const key = KEY_AILL_TOKEN
    const token = LocalStorage.get(key) || await IndexedDB.getConfig(key)
    return token
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

export async function setToken (token) {
  try {
    const key = KEY_AILL_TOKEN
    LocalStorage.set(key, token)
    await IndexedDB.setConfig(key, token)
  } catch (error) {
    Sentry.captureException(error)
  }
}

export async function clearToken () {
  try {
    const key = KEY_AILL_TOKEN
    LocalStorage.clear(key)
    await IndexedDB.clearConfig(key)
  } catch (error) {
    Sentry.captureException(error)
  }
}
