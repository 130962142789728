import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import GiftCard from 'images/gift_card_aill_0.png'

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(3),
  },
  img: {
    margin: 'auto',
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  notice: {
    marginBottom: theme.spacing(4),
  },
}))

/*
 * 無料期間延長ポップアップ
 */
export default function FreeTermExtentionDialog (props) {
  const { open, onClose, data } = props
  const classes = useStyles()

  if (!data) { return null }

  const handleClose = () => {
    onClose && onClose(data)
  }

  const title = data.title
  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="subtitle1">
            ずっと無料で<br />ご利用いただけます！
          </Typography>
          <img src={GiftCard} className={classes.img} alt="" />
          <Typography className={classes.notice} variant="body2">
            ＊フリープランへのプラン変更、ないしは退会された場合は、キャンペーンの適用外になりますのでご注意ください。
          </Typography>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtentionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
}
