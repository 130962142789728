import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { getFavHearts } from 'commons/utility'

const useStyles = makeStyles(theme => ({
  heartMark: {
    display: 'inline-block',
    width: 28,
    height: 28,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  clsoed: {
    filter: 'grayscale(100%)',
    opacity: 0.25,
  },
}))

export default function RatingSelect (props) {
  const { selectedRating, messageId, answered, closed, onSelect } = props
  const classes = useStyles()
  const [rating, setRating] = useState(selectedRating)

  useEffect(() => {
    if (selectedRating && selectedRating !== rating) {
      setRating(selectedRating)
    }
  }, [selectedRating])

  const clickHandler = (index) => {
    if (answered || closed) { return }
    const rate = (rating === index && rating !== 1) ? (index -1) * 20 : index * 20
    setRating(index)
    onSelect && onSelect(Math.floor(rate / 20))
  }

  return (
    <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
      {getFavHearts(rating * 20).map((heart, index) => (
        <img
          key={index}
          className={`${classes.heartMark} ${closed ? classes.clsoed : ''}`}
          src={heart}
          useMap={'#heart' + messageId + index}
          onClick={() => clickHandler(index + 1)}
          alt="好感度登録"
        />
      ))}
    </Grid>
  )
}

RatingSelect.propTypes = {
  selectedRating: PropTypes.number,
  messageId: PropTypes.string,
  answered: PropTypes.bool,
  closed: PropTypes.bool,
  onSelect: PropTypes.func,
}
