import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import WinterEventBox from 'components/winter-event/WinterEventBox'
import EventImg from 'images/winter-event/event.png'
import KomainuImg from 'images/winter-event/komainu01.png'
import WhiteSnowsLeftBottom from 'images/winter-event/white_snows_left_bottom.png'
import WhiteSnowsLeftTop from 'images/winter-event/white_snows_left_top.png'
import WhiteSnowsRightBottom from 'images/winter-event/white_snows_right_bottom.png'
import WhiteSnowsRightTop from 'images/winter-event/white_snows_right_top.png'

const useStyles = makeStyles(theme => ({
  paper: {
    width: 328,
    margin: 0,
    backgroundImage: `url(${WhiteSnowsLeftTop}), url(${WhiteSnowsRightTop}), url(${WhiteSnowsLeftBottom}), url(${WhiteSnowsRightBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '68px auto',
    backgroundPositionX: 'left, right, left, right',
    backgroundPositionY: 'top, top, bottom, bottom',
  },
  title: {
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    marginTop: theme.spacing(3),
  },
  imgA: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  imgB: {
    display: 'block',
    margin: 'auto',
  },
  actions: {
    paddingTop: theme.spacing(0.5),
  },
}))

export default function AnnounceDialog (props) {
  const { open, onConfirm, onClose, missions } = props
  const classes = useStyles()

  if (!missions?.length) { return null }

  // いいね！申請ミッション抽出
  const friendApplyMission = missions.find(mission => mission.mission_type === 'friend_apply')

  const onClick = () => {
    onClose?.()
    onConfirm?.()
  }

  return (
    <DialogThemeProvider color="winter-event">
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <DialogCloseButton onClick={() => onClose?.()} />
        <DialogTitle className={classes.title} disableTypography>
          イベントのご招待
        </DialogTitle>
        <DialogContent>
          <WinterEventBox mission={friendApplyMission} />
          <Typography className={classes.subtitle} variant="subtitle1">
            「いいね！」送信･返信ごとに<br />
            次回+1名紹介！
          </Typography>
          <img className={classes.imgA} src={EventImg} width={204} />
          <img className={classes.imgB} src={KomainuImg} width={204} />
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button variant="contained" onClick={onClick}>
            イベントページを見る
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

AnnounceDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  missions: PropTypes.array,
}
