import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { canUseCafeteriaPoint, canUseRefresh5000 } from 'commons/utility'

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    minHeight: theme.spacing(19),
    border: '1px solid #707070',
    padding: theme.spacing(4),
    marginTop: theme.spacing(3.75),
  },
  contentTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#1A1667',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  info: {
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1667',
  },
  step: {
    marginRight: theme.spacing(5),
  },
  text: {
    fontSize: 14,
    color: '#707070',
    whiteSpace: 'pre-wrap',
  },
  link: {
    fontSize: 14,
    fontWeight: 300,
    color: '#616AFA',
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
}))

export default function BenefitGuidance (props) {
  const { organization, scroll } = props
  const classes = useStyles()

  const createBox = (step, title, text, historyLink = false) => (
    <Grid className={classes.box} container alignItems="center">
      <Grid className={classes.title} container item xs={12} md={6}>
        <div className={classes.step}>{step}</div>
        <div>{title}</div>
      </Grid>
      <Grid className={classes.text} container item xs={12} md={6}>
        {text}
        {historyLink && <div>お支払い履歴は<u className={classes.link} onClick={() => scroll('paymentHistoryRef')}>こちら</u></div>}
      </Grid>
    </Grid>
  )

  return (
    <>
      {canUseCafeteriaPoint(organization) && (
        <>
          <Typography className={classes.contentTitle}>カフェテリアポイント ご利用方法説明</Typography>
          <Typography className={classes.info}>
            実質負担額0円！<br />
            会社の承認は不要のため、秘密に安心して利用できます。
          </Typography>
          {createBox(
            'STEP01',
            '領収書をダウンロード',
            '領収書はお支払い履歴欄の、「発行」ボタンから発行が可能です。',
            true
          )}
          {createBox(
            'STEP02',
            '会社に領収書を提出',
            '貴社のカフェテリアポイント申請・利用画面にて、領収書アップロード（提出）を行ってください。'
          )}
          {createBox(
            'STEP03',
            '会社より振り込み',
            '後日口座に振り込まれます。\n振込タイミングなどは各社の規定に準じます。'
          )}
          <Typography className={classes.link} onClick={() => scroll('standardPlanRef')}>
            スタンダードプランを確認する
          </Typography>
        </>
      )}
      {canUseRefresh5000(organization) && (
        <>
          <Typography className={classes.contentTitle}>リフレッシュ5000 ご利用方法説明</Typography>
          <Typography className={classes.info}>
            実質負担額0円！<br />
            リフレッシュ5000でお得にスタンダードプランを利用できます。<br />
            ＊リフレッシュ5000を利用せず、個人支払いでの利用も可能です。
          </Typography>
          {createBox(
            'STEP01',
            'Aillから領収書をダウンロード',
            '領収書はお支払い履歴欄の「発行」ボタンから発行が可能',
            true
          )}
          {createBox(
            'STEP02',
            'リフレッシュ5000申請',
            '貴社のリフレッシュ5000申請・利用画面にて\n領収書をアップロード（提出）'
          )}
          {createBox(
            'STEP03',
            '会社より振り込み',
            '領収書相当額がリフレッシュ5000に登録している口座に後日振込\n＊振込タイミングなどは各社規定に準じます。'
          )}
          <Typography className={classes.link} onClick={() => scroll('standardPlanRef')}>
            スタンダードプランを確認する
          </Typography>
        </>
      )}
    </>
  )
}

BenefitGuidance.propTypes = {
  organization: PropTypes.object,
  scroll: PropTypes.func,
}
