import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class FavoriteIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 31.349 13.958" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_854" data-name="グループ 854" transform="translate(-139.892 -40.897)">
            <path id="パス_457" data-name="パス 457" d="M147.132,53.523l-5.146-5.613a3.783,3.783,0,0,1,0-5.023,3.069,3.069,0,0,1,4.606,0l.54.59.586-.64a3.069,3.069,0,0,1,4.606,0,3.784,3.784,0,0,1,0,5.024Z" fill="none" stroke="#9d9d9e" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_458" data-name="パス 458" d="M164.3,54.7l-5.734-6.253a4.6,4.6,0,0,1,0-6.1A3.865,3.865,0,0,1,164.3,42.3a3.9,3.9,0,0,1,2.89-1.3h0a3.894,3.894,0,0,1,2.89,1.3,4.6,4.6,0,0,1,0,6.1Z" fill="#989898"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(FavoriteIcon)