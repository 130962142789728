import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import Config from 'commons/config'
import Storage from 'commons/storage'
import { isEmpty } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

// Google Analytics設定
// https://github.com/react-ga/react-ga
// https://github.com/PriceRunner/react-ga4
ReactGA.initialize(Config.uaTrackingId)
ReactGA4.initialize(Config.gaTrackingId)

const history = createBrowserHistory()

history.listen(location => {
  if (!location) { return }
  const path = location.pathname + location.search
  if (isEmpty(path)) { return }
  if (path === '/IntroductionProfile') { return }
  if (path === '/') { return }
  if (Config.deployMode === 'app') {
    Storage.pathAfterAppLaunch.value = path
  }
  switch (Config.deployMode) {
    case 'entry':
      BaseComponent.gaPageView('SearchCompany')
      break
    case 'members':
      BaseComponent.gaPageView('Login')
      break
    case 'partner':
      BaseComponent.gaPageView('PartnerLogin')
      break
    default:
      BaseComponent.gaPageView(path)
      break
  }
})

export default history