import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { getFavHearts } from 'commons/utility'

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(3.5),
  },
  heartMark: {
    display: 'inline-block',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    marginLeft: theme.spacing(0.438),
    marginRight: theme.spacing(0.438),
  }
}))

export default function Rating (props) {
  const { point } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {getFavHearts(point || 0.0).map((heart, index) => {
        return (
          <img
            className={classes.heartMark}
            key={index}
            src={heart}
            alt="あなたへの好感度"
          />
        )
      })}
    </div>
  )
}

Rating.propTypes = {
  point: PropTypes.number
}
