import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    paddingBottom: 32,
  },
  label: {
    color: '#1A1667',
    fontSize: 16,
    fontWeight: 'bold',
  },
  errorText: {
    color: '#f44336',
    fontSize: 14,
  },
  helperText: {
    color: '#9D9D9E',
    fontSize: 14,
  }
})

export default function InputContainer (props) {
  const classes = useStyles()
  const { label, errorText, helperText, children } = props
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>
        { label }
      </Typography>
      { children }
      {errorText &&
        <Typography className={classes.errorText}>
          { errorText }
        </Typography>
      }
      {
        helperText &&
        <Typography className={classes.helperText}>
          { helperText }
        </Typography>
      }
    </div>
  )
}

InputContainer.propTypes = {
  label: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  children: PropTypes.object,
}