import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import { isEmpty } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ChatMemo from 'components/parts/ChatMemo'
import LeftSpeech from 'components/parts/LeftSpeech'
import MessageMenuDialog from 'components/parts/MessageMenuDialog'
import NaviSpeech from 'components/parts/NaviSpeech'
import RightSpeech from 'components/parts/RightSpeech'
import ImageGood from 'images/img_demo_2.png'
import ImageBad from 'images/img_demo_3.png'
import ImageFemale from 'images/img_demo_female.png'
import ImageMale from 'images/img_demo_male.png'
import ImageQuestion from 'images/img_secret_message.png'

const demo_messages = {
  demo_female_1_1: [
    {
      id: 'female_1_1_1',
      message_type: 'message',
      message: '明日は18時に待ち合わせしましょう!',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_1_2',
      message_type: 'message',
      message: '明日が楽しみです(^^)\nよろしくお願いします。',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_1_3',
      message_type: 'message',
      message: '翌日（デート後）',
      created_at: '',
      target: 'time',
      navi_type: null,
    },
    {
      id: 'female_1_1_5',
      message_type: 'message',
      message: '今日は楽しかった？？今日の感想をシェアしてみてね(*^-^*)',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
    },
    {
      id: 'female_1_1_6',
      message_type: 'message',
      message: 'お相手の情報をメモしておこう！',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
      navi_to: 'user',
      template_id: 'follow_after_dating-06',
    },
    {
      id: 'female_1_1_7',
      message_type: 'openChatMenu',
    },
    {
      id: 'female_1_1_8',
      message_type: 'openChatMemo',
    },
    {
      id: 'female_1_1_9',
      message_type: 'demo',
      status: 1,
    },
    {
      id: 'female_1_1_10',
      message_type: 'demo',
      status: 2,
    },
    {
      id: 'female_1_1_11',
      message_type: 'demo',
      status: 3,
    },
    {
      id: 'female_1_1_12',
      message_type: 'demo',
      status: 4,
    },
    {
      id: 'female_1_1_13',
      message_type: null,
      message: 'デート中に聞いたことを覚えておくと、お相手に好印象だよ！\n右上のメニューからいつでも自由にメモできるから活用してね。',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_female_2_1: [
    {
      id: 'female_2_1_1',
      message_type: 'message',
      message: '好きな食べ物は何ですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_1_2',
      message_type: 'message',
      message: '和食が好きです(^^)\nただ、蕎麦アレルギーがあるので気をつけています。',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_1_3',
      message_type: 'message',
      message: 'この情報はメモしておいた方がいいかも。\nメッセージを長押しするとメモに保存できるよ。',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
      navi_to: 'user',
      menu: true,
      sleep: true,
    },
    {
      id: 'female_2_1_4',
      message_type: 'openMessageMenu',
    },
    {
      id: 'female_2_1_5',
      message_type: 'openChatMemo',
      message: '和食が好きです(^^)\nただ、蕎麦アレルギーがあるので気をつけています。',
    },
    {
      id: 'female_2_1_6',
      message_type: 'demo',
      status: 1,
    },
    {
      id: 'female_2_1_7',
      message_type: 'demo',
      status: 2,
    },
    {
      id: 'female_2_1_8',
      message_type: 'demo',
      status: 3,
    },
    {
      id: 'female_2_1_9',
      message_type: 'demo',
      status: 4,
    },
    {
      id: 'female_2_1_10',
      message_type: null,
      message: '大事なメッセージをメモしておくと、デートの時に役立つよ！\nメッセージ長押しで簡単にメモできるから活用してね。',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_male_1_1: [
    {
      id: 'male_1_1_1',
      message_type: 'message',
      message: '明日は18時に待ち合わせしましょう!',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_1_2',
      message_type: 'message',
      message: '明日が楽しみです(^^)\nよろしくお願いします。',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_1_1_3',
      message_type: 'message',
      message: '翌日（デート後）',
      created_at: '',
      target: 'time',
      navi_type: null,
    },
    {
      id: 'male_1_1_5',
      message_type: 'message',
      message: '今日は楽しかった？？今日の感想をシェアしてみてね(*^-^*)',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
    },
    {
      id: 'male_1_1_6',
      message_type: 'message',
      message: 'お相手の情報をメモしておこう！',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
      navi_to: 'user',
      template_id: 'follow_after_dating-06',
    },
    {
      id: 'male_1_1_7',
      message_type: 'openChatMenu',
    },
    {
      id: 'male_1_1_8',
      message_type: 'openChatMemo',
    },
    {
      id: 'male_1_1_9',
      message_type: 'demo',
      status: 1,
    },
    {
      id: 'male_1_1_10',
      message_type: 'demo',
      status: 2,
    },
    {
      id: 'male_1_1_11',
      message_type: 'demo',
      status: 3,
    },
    {
      id: 'male_1_1_12',
      message_type: 'demo',
      status: 4,
    },
    {
      id: 'male_1_1_13',
      message_type: null,
      message: 'デート中に聞いたことを覚えておくと、お相手に好印象だよ！\n右上のメニューからいつでも自由にメモできるから活用してね。',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_male_2_1: [
    {
      id: 'male_2_1_1',
      message_type: 'message',
      message: '好きな食べ物は何ですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_1_2',
      message_type: 'message',
      message: '和食が好きです(^^)\nただ、蕎麦アレルギーがあるので気をつけています。',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_2_1_3',
      message_type: 'message',
      message: 'この情報はメモしておいた方がいいかも。\nメッセージを長押しするとメモに保存できるよ。',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
      navi_to: 'user',
      menu: true,
      sleep: true,
    },
    {
      id: 'male_2_1_4',
      message_type: 'openMessageMenu',
    },
    {
      id: 'male_2_1_5',
      message_type: 'openChatMemo',
      message: '和食が好きです(^^)\nただ、蕎麦アレルギーがあるので気をつけています。',
    },
    {
      id: 'male_2_1_6',
      message_type: 'demo',
      status: 1,
    },
    {
      id: 'male_2_1_7',
      message_type: 'demo',
      status: 2,
    },
    {
      id: 'male_2_1_8',
      message_type: 'demo',
      status: 3,
    },
    {
      id: 'male_2_1_9',
      message_type: 'demo',
      status: 4,
    },
    {
      id: 'male_2_1_10',
      message_type: null,
      message: '大事なメッセージをメモしておくと、デートの時に役立つよ！\nメッセージ長押しで簡単にメモできるから活用してね。',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
}

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
  },
  message: {
    ...theme.styles.messageColor,
    fontSize: 16,
    lineHeight: 1.75,
    marginTop: theme.spacing(4),
  },
  messageTitle: {
    ...theme.styles.messageColor,
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  scroll: {
    width: '100%',
    textAlign: 'left',
  },
  messageAnimation: {
    animation: '$message-animation forwards',
    animationDuration: '1.5s',
  },
  '@keyframes message-animation': {
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1
    },
    '20%': {
      transform: 'translateY(0px)',
      opacity: 1
    },
    '0%': {
      transform: `translateY(+${theme.spacing(10)}px)`,
      opacity: 0,
      visibility: 'hidden'
    }
  },
  timeAnimation: {
    animation: '$time-animation forwards',
    animationDuration: '4s',
  },
  '@keyframes time-animation': {
    '100%': {
      transform: 'translateX(0px)',
      opacity: 1
    },
    '20%': {
      transform: 'translateX(0px)',
      opacity: 1
    },
    '0%': {
      transform: `translateX(-${theme.spacing(10)}px)`,
      opacity: 0,
      visibility: 'hidden'
    }
  },
  questionContainer: {
    width: theme.spacing(30),
    ...theme.styles.secretRoomColor,
    borderRadius: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundImage: `url(${ImageQuestion})`,
    backgroundSize: 'contain',
  },
  questionButton: {
    ...theme.styles.entryButton,
    fontSize: 14,
    fontWeight: 'bold',
    width: theme.spacing(20),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  timeContainer: {
    width: '80%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  finishContainer: {
    width: '80%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  finishButton: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(25),
    height: theme.spacing(5.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  finishImageGood: {
    width: theme.spacing(25),
    height: theme.spacing(12.242),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  finishImageBad: {
    width: theme.spacing(25),
    height: theme.spacing(10.542),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  naviAngel: {
    color: Const.colors.angel,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
  naviAngelSecret: {
    color: Const.colors.angelSecret,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
  naviDevil: {
    color: Const.colors.devil,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
  naviDevilSecret: {
    color: Const.colors.devilSecret,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
}))

const demoFriend = {
  type: 'friend',
  chat_room_id: 'demo',
  push_notification_enabled: true,
}

export default function ChatMemoDemoDialog (props) {
  const { open, type, user, friend, onDemoClose, ...other } = props
  const classes = useStyles()
  const [displayItems, setDisplayItems] = useState([])
  const [memoOpen, setMemoOpen] = useState(false)
  const [memoText, setMemoText] = useState('')
  const [openChatMenu, setOpenChatMenu] = useState(false)
  const [demoStatus, setDemoStatus] = useState(0)
  const [menuTargetMessage, setMenuTargetMessage] = useState(null)
  const [openMessageMenuDialog, setOpenMessageMenuDialog] = useState(false)
  const demoPlaying = useRef(null)

  useEffect(() => {
    if (open) {
      BaseComponent.gaModalView('chatMemoDemo')
      handleStart()
    } else {
      demoPlaying.current = false
      setDisplayItems([])
    }
  }, [open])

  const handleStart = async () => {
    demoPlaying.current = true
    const target = demo_messages[`demo_${user.sex}_${type}_1`]
    for (const msg of target) {
      // 途中終了した場合break
      if (!demoPlaying.current) { break }
      if (msg.message_type === 'openChatMenu') {
        await setOpenChatMenu(true)
        await sleep(1000)
      } else if (msg.message_type === 'openChatMemo') {
        await handleChatMemo(msg.message ? msg.message : '')
        await setOpenChatMenu(false)
        await setMenuTargetMessage(null)
        await setOpenMessageMenuDialog(false)
        await sleep(1000)
      } else if (msg.message_type === 'demo') {
        await setDemoStatus(msg.status)
        await sleep(1000)
      } else if (msg.message_type === 'openMessageMenu') {
        await handleOpenMessageMenu(msg)
        await sleep(1000)
      } else {
        await addDisplayItem(msg)
      }
      demoDisplayed(msg)
    }
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  const scrollToBottom = () => {
    let scroll = $('#scroll-dialog')
    scroll.scrollTop(scroll.prop('scrollHeight'))
  }

  // 画面にSpeachオブジェクトを追加
  const addDisplayItem = async (msg) => {
    await setDisplayItems((displayItems) => [...displayItems, msg])
    await scrollToBottom()
    await sleep(1000)
  }

  const handleChatMemo = (text = '') => {
    setMemoOpen(true)
    setMemoText(text)
    setOpenChatMenu(false)
  }

  const handleMemoClose = () => {
    setMemoOpen(false)
    setMemoText('')
  }

  const createReadStateDemoDialogDisplayed = async (read_id) => {
    try {
      const params = {
        target: 'DemoDialogDisplayed',
        read_ids: [read_id],
      }
      await Api.createReadState(params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const isReadStateDemoDialogDisplayed = async (read_id) => {
    try {
      const read_ids = await Api.getReadIds('DemoDialogDisplayed')
      return read_ids.includes(read_id)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const getNaviClass = (msg) => {
    if (msg.navi_type === 'angel') {
      return isEmpty(msg.navi_to) ? classes.naviAngel : classes.naviAngelSecret
    } else {
      return isEmpty(msg.navi_to) ? classes.naviDevil : classes.naviDevilSecret
    }
  }

  const createNaviMessageElement = (msg, key) => {
    return (
      <div>
        {msg.menu && (
          <Typography className={getNaviClass(msg)}>
            ↑このメッセージを長押し
          </Typography>
        )}
        <NaviSpeech
          {...other}
          key={key}
          naviType={msg.navi_type}
          message={msg}
          secret={!isEmpty(msg.navi_to)}
          friend={friend}
          onConfirmSend={() =>{}}
          onSecretRoom={() => {}}
          onMemoOpen={handleChatMemo}
        />
      </div>
    )
  }

  const createMyMessageElement = (msg, key) => {
    return (
      <RightSpeech
        key={key}
        userGender={user.sex === 'female' ? 'female' : 'male'}
        message={msg}
      />
    )
  }

  const handleOpenMessageMenu = (msg) => {
    setMenuTargetMessage(msg)
    setOpenMessageMenuDialog(true)
  }

  const createFriendMessageElement = (msg, key) => {
    return (
      <LeftSpeech
        key={key}
        photo={user.sex === 'female' ? ImageMale : ImageFemale}
        userGender={user.sex === 'female' ? 'male' : 'female'}
        message={msg}
        onOpenMenu={() => handleOpenMessageMenu(msg)}
      />
    )
  }

  // 回答
  const handleAnswer = async (msg, ans) => {
    const ans_value = '_' + (ans === 'yes' ? '1' : '2')
    const target = demo_messages[msg['next' + ans_value]]
    for (const msg of target) {
      // 途中終了した場合break
      if (!demoPlaying.current) { break }
      if (msg.message_type === 'openChatMenu') {
        setOpenChatMenu(true)
        await sleep(1000)
      } else if (msg.message_type === 'openChatMemo') {
        handleChatMemo(msg.message ? msg.message : '')
        setOpenChatMenu(false)
        setMenuTargetMessage(null)
        setOpenMessageMenuDialog(false)
        await sleep(1000)
      } else if (msg.message_type === 'demo') {
        setDemoStatus(msg.status)
        await sleep(1000)
      } else if (msg.message_type === 'openMessageMenu') {
        handleOpenMessageMenu(msg)
        await sleep(1000)
      } else {
        await addDisplayItem(msg)
      }
      demoDisplayed(msg)
    }
  }

  // デモ機能既読処理
  const demoDisplayed = async (msg) => {
    if (msg.target !== 'finished') { return }
    const displayed = await isReadStateDemoDialogDisplayed(msg.id)
    if (displayed) { return }
    await createReadStateDemoDialogDisplayed(msg.id)
  }

  const createQuestionMessageElement = (msg) => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          className={classes.questionContainer}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.questionButton}
            onClick={() => handleAnswer(msg, 'yes')}
          >
            { msg.answer_1 }
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.questionButton}
            onClick={() => handleAnswer(msg, 'no')}
          >
            { msg.answer_2 }
          </Button>
        </Grid>
      </Grid>
    )
  }

  const createTimeMessageElement = (msg) => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          className={classes.timeContainer}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.message}>
            ----- { msg.message } -----
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const createFinishedMessageElement = (msg) => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          className={classes.finishContainer}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.message}>
            {BaseComponent.textConvertMultiline(msg.message)}
          </Typography>
          <img
            className={msg.image === 'Good' ? classes.finishImageGood : classes.finishImageBad}
            src={msg.image === 'Good' ? ImageGood : ImageBad}
            alt=""
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.finishButton}
            onClick={onDemoClose}
          >
            閉じる
          </Button>
        </Grid>
      </Grid>
    )
  }

  const rootEl = document.getElementById('root')

  return (
    <Dialog open={open} fullScreen>
      <DialogContent id='scroll-dialog' className={classes.content}>
        <Popper
          open={open}
          style={{ zIndex: 1500, width: '100%' }}
          anchorEl={rootEl}
          modifiers={{
            preventOverflow: { enabled: false },
            hide: { enabled: false }
          }}
        >
          <></>
        </Popper>
        <AppHeader
          title={'トーク画面'}
          toggleMenu
          user={user}
          target={demoFriend}
          onBack={onDemoClose}
          backWhite
          onlyMemo
          {...other}
          onChatMemo={handleChatMemo}
          openChatMenu={openChatMenu}
          chat_room_id={'sample'}
        />
        <ChatMemo
          {...other}
          memoOpen={memoOpen}
          user={user}
          friend={demoFriend}
          text={memoText}
          onClose={handleMemoClose}
          secret
          demoStatus={demoStatus}
        />
        <Grid
          className={classes.container}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.scroll}>
            {displayItems.map((item, key) => (
              <div key={key} className={item.target === 'time' ? classes.timeAnimation : classes.messageAnimation}>
                {item.target === 'navi' ? createNaviMessageElement(item, key)
                  : item.target === 'user' ? createMyMessageElement(item, key)
                    : item.target === 'friend' ? createFriendMessageElement(item, key)
                      : item.target === 'question' ? createQuestionMessageElement(item, key)
                        : item.target === 'time' ? createTimeMessageElement(item, key)
                          : createFinishedMessageElement(item, key)
                }
              </div>
            ))}
          </div>
        </Grid>
        {menuTargetMessage && (
          <MessageMenuDialog
            {...props}
            open={openMessageMenuDialog}
            message={menuTargetMessage}
            onDeleteMessage={() => {}}
            onClose={() => setOpenMessageMenuDialog(false)}
            onChatMemo={text => handleChatMemo(text)}
            onlyMemo
            anchorEl={document.getElementById('message-text-male_2_1_3')}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

ChatMemoDemoDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  friend: PropTypes.object,
  onDemoClose: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
}