import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import * as storage from 'commons/storage'
import { formatJapaneseDate, formatJapaneseDateTime } from 'commons/utility'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  root: {
    maxWidth: 700,
    margin: 'auto',
    paddingBottom: theme.spacing(10),
    overflowX: 'hidden',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    color: '#1A1667',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    textAlign: 'center',
    marginBottom: theme.spacing(6),
  },
  textRejected: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    textAlign: 'left'
  },
  appInstall: {
    textAlign: 'left',
  }
})

// 審査中画面
class IdChecking extends BaseComponent {
  constructor (props) {
    super(props)

    this.state = {
      area: null,
      user: null,
    }
  }

  async componentDidMount () {
    this.addVisitPageLog()
    window.scrollTo(0, 0)

    let areas = await Api.getAreas()
    this.setAreaValues(areas, this.props.user)

    let delay = 10 * 1000
    this.intervalId = window.setInterval(() => this.checkStatus(areas), delay)
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  async checkStatus (areas) {
    try {
      const user = await Api.getUser()
      this.props.setUser(user)
      this.setState({ user: user })
      const idStatus = user.identification_status
      if (idStatus === 'checked' || idStatus === 'check_after' || idStatus === 'rejected') {
        this.setAreaValues(areas, user)
        window.clearInterval(this.intervalId)
        if (this.locationIsApp()) {
          // HOME画面へ
          this.props.setScreen('Home')
        }
      } else if (idStatus === 'pending') {
        window.clearInterval(this.intervalId)
        this.toEntryNextStepPending(user)
      }
    } catch (error) {
      window.clearInterval(this.intervalId)
      if (error.response && error.response.status === 401) {
        // 入会審査NG
        this.clearSession()
      } else {
        this.handleApiError(error)
      }
    }
  }

  // アプリ用画面か
  locationIsApp () {
    return Config.deployMode === 'app'
  }

  setAreaValues (areas, user) {
    if (!areas || !user) return
    let area = areas.find(area => (area.id === user.area_id))
    if (area) {
      this.setState({ area: area })
    }
  }

  isServiceStarted (area) {
    if (!area) return true
    if (!area.pre_entry_end_at) return true
    let today = new Date()
    let preEndDay = new Date(area.pre_entry_end_at)
    if (today > preEndDay) {
      return true
    }
    return false
  }

  render () {
    if (!storage.default.enabled) {
      return <DisableCookieScreen />
    }

    const { classes, ...other } = this.props
    const idStatus = this.props.user.identification_status
    const full_name = this.props.user.last_name + ' ' + this.props.user.first_name
    return (
      <div className={classes.root}>
        <AppHeader title="入会審査" hideBack={true} {...other} />
        <EntrySteps step={4} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
        {
          idStatus === 'checking' && (
            <>
              <Typography className={classes.text}>
                ただいま入会審査中です<br />
                （最大72時間）。<br />
                しばらくお待ちください。
              </Typography>
            </>
          )
        }
        {
          (idStatus === 'checked' || idStatus === 'check_after') && this.isServiceStarted(this.state.area) &&
          <Typography className={classes.text}>
            入会審査が完了しました！<br />
            Aillサービスをお楽しみくださいませ。<br />
            <br />
            ＜ご利用の流れ＞<br />
            <a href='https://play.google.com/store/apps/details?id=jp.aill_corporation.aill&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img
                alt='Google Play で手に入れよう'
                src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png'
                width="240px"
              />
            </a>
            <a href="https://apps.apple.com/us/app/aill/id1458844712?l=ja&ls=1">
              <img
                alt="App Storeからダウンロード"
                src="https://aill.ai/img/Download_on_the_App_Store_Badge_JP_blk_100317.png"
                width="200px"
              />
            </a>
            <br />
            <span className={classes.appInstall}>
              ①App StoreないしはGoogle PlayからAillアプリをインストール<br />
              ②アプリを起動し、登録した私用メールアドレス（ID）とパスワードを入力しログイン<br />
              ③Aillサービス スタート<br />
            </span>
            <br />
            本サービスについてご不明な点がございましたら、以下のメールアドレスまでご連絡をお願いいたします。<br />
            <a href="mailto:support@aill.ai">support@aill.ai</a>
          </Typography>
        }
        {
          (idStatus === 'checked' || idStatus === 'check_after') && !this.isServiceStarted(this.state.area) &&
          <Typography className={classes.text}>
            この度はAillの入会登録、<br />ありがとうございます。<br />
            <br />
            入会審査が完了し、{full_name}様は<br />「審査通過」となりました。<br />
            <br />
            サービス開始は{formatJapaneseDateTime(this.state.area.pre_entry_end_at)}を予定しています。<br />
            （アプリのダウンロード方法については{formatJapaneseDate(this.state.area.pre_entry_end_at)}午前中にご案内させていただきます。）<br />
            <br />
            ただいま一斉にご登録いただいております。<br />
            みなさまのプロフィール登録が整い次第、サービスを開始いたします。
          </Typography>
        }
        {
          idStatus === 'rejected' &&
          <Typography className={classes.textRejected}>
            この度はAillの入会登録、ありがとうございます。<br />
            <br />
            厳正なる審査の結果、誠に残念ではございますが、登録の承認をご遠慮させていただく事となりましたのでご連絡申し上げます。<br />
            ご希望に添えず申し訳ございません。<br />
            <br />
            具体的な審査内容の詳細は開示しておらず、お問い合わせをいただきましてもお答えすることは差し控えさせていただきます。<br />
            <br />
            誠に恐縮ではございますが、何卒ご了承下さいますようお願い申し上げます。
          </Typography>
        }
      </div>
    )
  }
}

export default withStyles(styles)(IdChecking)
