import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import BaseComponent from 'components/parts/BaseComponent'
import CanselIcon from 'images/tutorial/firstNaviCanselImg.png'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 9999
  },
  topArrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 22px 15px 22px',
    borderColor: 'transparent transparent #6666FF transparent',
    display: 'block',
    margin: 'auto',
  },
  bottomArrow: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 22px 0 22px',
    borderColor: '#6666FF transparent transparent transparent',
    display: 'block',
    margin: 'auto',
  },
  paper: {
    width: 289,
    backgroundColor: '#6666FF',
    borderRadius: 12,
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    position: 'relative',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#ffffff',
    marginTop: theme.spacing(2),
  },
  img: {
    marginTop: theme.spacing(2),
    width: 190,
  },
  button: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: 230,
    height: 44,
    position: 'fixed',
    bottom: 40,
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 9999,
  },
  canselButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

// ##################################################
// Backdrop クリック有効Modal
// ##################################################
export default function NaviPopper (props) {
  const classes = useStyles()
  const {
    id,
    open,
    anchorEl,
    title,
    text,
    img,
    child,
    placement,
    onClose,
    arrow,
    canselButton
  } = props

  useEffect(() => {
    if (open) {
      return async () => await BaseComponent.toNextTutorial(props, id)
    }
  }, [open])

  return (
    <>
      <Popper
        className={classes.root}
        open={open || false}
        anchorEl={anchorEl}
        placement={placement}
        transition
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      >
        {(placement === 'bottom' && arrow) && <span className={classes.topArrow} />}
        <div className={classes.paper}>
          {canselButton && (
            <IconButton className={classes.canselButton} onClick={onClose}>
              <img src={CanselIcon} alt="" />
            </IconButton>
          )}
          {title && <Typography className={classes.title}>{title}</Typography>}
          {text && <Typography className={classes.text}>{text}</Typography>}
          {img && <img className={classes.img} src={img} alt="" />}
          {child && child}
        </div>
        {(placement === 'top' && arrow) && <span className={classes.bottomArrow} />}
      </Popper>
    </>
  )
}

NaviPopper.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  child: PropTypes.object,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  arrow: PropTypes.bool,
  canselButton: PropTypes.bool,
  setTutorialView: PropTypes.func,
  tutorialReadStates: PropTypes.array,
}
