import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class PushIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 29.925 25.068" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_858" data-name="グループ 858" transform="translate(-44.976 -193.734)">
            <path id="パス_466" data-name="パス 466" d="M55.4,201.8" fill="#989898" stroke="#989898" strokeLinejoin="round" strokeWidth="0.75"/>
            <path id="パス_467" data-name="パス 467" d="M55.4,210.657H45.875v-8.778H55.4l-.06-.049,9.661-7.2V217.9l-9.661-7.2Z" fill="none" stroke="#9d9d9e" strokeLinejoin="round" strokeWidth="1.8"/>
            <line id="線_28" data-name="線 28" x2="4" transform="translate(70 206)" fill="none" stroke="#989898" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.8"/>
            <line id="線_29" data-name="線 29" y1="2.828" x2="2.828" transform="translate(69.586 198.586)" fill="none" stroke="#989898" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.8"/>
            <line id="線_30" data-name="線 30" x1="2.828" y1="2.828" transform="translate(69.586 210.586)" fill="none" stroke="#989898" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.8"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(PushIcon)