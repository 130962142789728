import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import SearchCompanyResultList from 'components/entry/SearchCompanyResultList'
import DialogCloseButton from 'components/parts/DialogCloseButton'

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 289,
  },
  title: {
    fontSize: 14,
  },
  content: {
    padding: 0,
  },
  actions: {
    padding: 0,
  },
}))

export default function SelectCompanyDialog (props) {
  const { open, companies, searchWord, onClose } = props
  const classes = useStyles()

  const onSelectCompany = (company) => {
    props.setCompany(company)
    props.setScreen(company.organization_id === 'none' ? 'SearchCompanyResult' : 'Signup')
    onClose()
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} PaperProps={{ className: classes.paper }}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle className={classes.title} disableTypography>
          {companies.length + '件の検索結果'}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <SearchCompanyResultList
            companies={companies}
            onSelect={company => onSelectCompany(company)}
            onSelectNone={() => onSelectCompany({ name: searchWord, organization_id: 'none' })}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>

        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SelectCompanyDialog.propTypes = {
  open: PropTypes.bool,
  companies: PropTypes.array,
  searchWord: PropTypes.string,
  onClose: PropTypes.func,
  setCompany: PropTypes.func,
  setScreen: PropTypes.func,
}
