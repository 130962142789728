import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import KaguraSakaki from 'images/img_demo_1.png'
import Kagura from 'images/kagura_donmai.png'

const useStyles = makeStyles(theme => ({
  center: {
    width: 'fit-content',
    textAlign: 'left',
    margin: 'auto',
  },
  img: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  okImg: {
    width: 120,
  },
  badImg: {
    width: 60,
  },
}))

export default function PhotoRateNotifyDialog (props) {
  const { open, user, photoRate, onConfirm, onClose } = props
  const classes = useStyles()

  if (!photoRate) { return null }

  return (
    <DialogThemeProvider color="secondary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          {photoRate === 'ok' && `${user.nick_name}さんの\nプロフィール写真変更を！`}
          {photoRate === 'bad' && '<至急>\nプロフィール写真変更のお願い'}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.text} variant="body1">
            {photoRate === 'ok' &&
              'お相手に好印象＆マッチング率が上がるプロフィール写真に変更しよう！\n今の写真は「サブの写真」が\nオススメ。\nもっとボクたちが紹介できる\n異性が広がるよ！'
            }
            {photoRate === 'bad' &&
              '今のままの写真だと、\nマッチングしない可能性大。\nお相手に好印象＆マッチング率が上がるプロフィール写真に変更しよう！'
            }
          </Typography>
          {/* チュートリアルへの導線 */}
          <br />
          <Typography variant="body1">異性が最初に見るポイントは2つ！</Typography>
          <Typography className={classes.center} variant="body1">
            ・自己紹介文<br />
            ・プロフィール写真
          </Typography>
          {photoRate === 'ok' && <img className={`${classes.img} ${classes.okImg}`} src={KaguraSakaki} />}
          {photoRate === 'bad' && <img className={`${classes.img} ${classes.badImg}`} src={Kagura} />}
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onConfirm}>
            変更する
          </Button>
          <Button variant="text" onClick={onClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

PhotoRateNotifyDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object,
  photoRate: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}
