import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#33f',
    strokeMiterlimit: 10,
    strokeWidth: 4,
  },
  cls2: {
    fill: '#33f',
    stroke: '#33f',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2,
  },
  cls3: {
    strokeWidth: 1.8,
    stroke: '#33f',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  },
}

class CampaignOnIcon extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <SvgIcon viewBox="0 0 25.154 28.381">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="25.154" height="28.381" viewBox="0 0 25.154 28.381">
          <g id="グループ_26" data-name="グループ 26" transform="translate(-118.083 -213.858)">
            <line id="線_6" data-name="線 6" y2="20.284" transform="translate(130.66 220.396)" className={classes.cls1}/>
            <g id="グループ_8" data-name="グループ 8" transform="translate(-3 81)">
              <path id="パス_7" data-name="パス 7" d="M128.179,133.858l-1.74,2.866,6.327,1.88Z" className={classes.cls2}/>
              <path id="パス_8" data-name="パス 8" d="M139.141,133.858l1.74,2.866-6.327,1.88Z" className={classes.cls2}/>
            </g>
            <g id="グループ_9" data-name="グループ 9" transform="translate(-3 81)">
              <rect id="長方形_15" data-name="長方形 15" width="23.354" height="5.008" rx="1.692" transform="translate(121.983 140.056)" className={classes.cls3}/>
              <rect id="長方形_16" data-name="長方形 16" width="19.628" height="15.276" rx="1.753" transform="translate(123.876 145.064)" className={classes.cls3}/>
            </g>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

CampaignOnIcon.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(CampaignOnIcon)