import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { entryFooterHight } from 'commons/theme'
import { isEmpty, httpNotFound } from 'commons/utility'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import BaseComponent from 'components/parts/BaseComponent'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${entryFooterHight}px)`,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  titleBar: {
    height: theme.spacing(0.625),
    width: theme.spacing(20),
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
  },
  container: {
    width: '50%',
    minWidth: theme.spacing(35),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginTop: 0,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(10),
    border: '1px solid',
  },
  message: {
    color: '#878787',
    fontSize: 12.5,
    marginTop: 0,
    lineHeight: 1.75,
  },
  messageContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
})

export class MembersPasswordForgot extends BaseComponent {
  constructor (props) {
    super(props)

    this.submitMailAddress = this.submitMailAddress.bind(this)
    this.onChangeMailAddress = this.onChangeMailAddress.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)

    this._isMounted = false

    this.state = {
      mailAddress: '',
      mailAddressError: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onChangeMailAddress (event) {
    this.setState({
      mailAddress: event.target.value,
      mailAddressError: null,
    })
  }

  async submitMailAddress () {
    // 入力のバリデーション
    let inputError = false

    // 入力されているか？
    if (isEmpty(this.state.mailAddress)) {
      inputError = true
      this.setState({ mailAddressError: 'メールアドレスを入力してください。' })
    } else if (!this.state.mailAddress.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/)) {
      inputError = true
      this.setState({ mailAddressError: 'メールアドレスの形式が正しくありません。' })
    } else if (this.state.mailAddress.length > 255) {
      this.setState({ mailAddressError: '最大文字長は２５５文字です。' })
      inputError = true
    }

    if (inputError) { return }

    this.props.setLoading(true)
    try {
      let data = await Api.resetPassword(this.state.mailAddress)
      this.handleSuccess(data)
    } catch (error) {
      if (httpNotFound(error)) {
        this.setStateIfMounted({ mailAddressError: '登録されているアカウントが見つかりません。' })
      } else {
        BaseComponent.handleApiError(this.props, error)
      }
    } finally {
      this.props.setLoading(false)
    }
  }

  handleSuccess (data) {
    this.props.setAuthId(data.auth.id)
    this.props.setMailAddress(this.state.mailAddress)
    this.props.setScreen('Passcode')
  }

  render () {
    const { classes } = this.props

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.body}>
            <EntryHeader/>
            <Grid container direction='column' alignItems="center">
              <Typography className={classes.title}>
                PASSWORD RESET
              </Typography>
              <div className={classes.titleBar}/>
              <Grid item className={classes.container}>
                <Typography className={classes.subTitle}>
                  パスワード再発行
                </Typography>
                <Typography className={classes.label}>
                  本人確認（メールアドレス入力）
                </Typography>
                <TextField
                  value={this.state.mailAddress}
                  type="email"
                  margin="normal"
                  variant="outlined"
                  error={!isEmpty(this.state.mailAddressError)}
                  helperText={this.state.mailAddressError}
                  placeholder="メールアドレスを入力"
                  className={classes.textField}
                  color="primary"
                  onChange={this.onChangeMailAddress}
                />
              </Grid>
              <Button variant="outlined" color="primary" className={classes.entry} onClick={this.submitMailAddress}>
                送信
              </Button>
              <Grid item className={classes.messageContainer}>
                <Typography className={classes.message}>
                  登録済みメールアドレスを入力ください。
                </Typography>
                <Typography className={classes.message}>
                  確認用の認証コードをお送ります。
                </Typography>
                <Typography className={classes.message}>
                  ※1.携帯アドレス登録の場合は、PCメール受信の設定をお願いします。
                </Typography>
                <Typography className={classes.message}>
                  ※2.迷惑メールフォルダへの受信にご注意ください。
                </Typography>
              </Grid>
            </Grid>
          </div>
          <EntryFooter/>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MembersPasswordForgot)
