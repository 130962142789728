import React from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { baseThemeProps, sizeRatio } from 'commons/theme'

export default function TemplateDialogThemeProvider (props) {
  const palette = { main: '#616AFA', contrastText: '#ffffff' }
  return (
    <ThemeProvider theme={createNewTheme(palette)}>
      {props.children}
    </ThemeProvider>
  )
}

TemplateDialogThemeProvider.propTypes = {
  children: PropTypes.object,
}

function createNewTheme (palette) {
  const textStyle = {
    color: palette.contrastText,
    fontSize: `${12 * sizeRatio()}px !important`,
    fontWeight: 'bold !important',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  }
  const overrides = {
    MuiDialog: {
      root: {
        '& div.MuiBackdrop-root': {
          backdropFilter: 'blur(5px)',
        }
      },
      paper: {
        height: '90%',
        width: '80%',
        backgroundColor: palette.main,
        borderRadius: 16,
      }
    },
    MuiDialogTitle: {
      root: {
        fontSize: 16 * sizeRatio(),
        color: palette.contrastText,
        fontWeight: 'bold',
        textAlign: 'center',
      }
    },
    MuiDialogContent: {
      root: {
        fontSize: `${12 * sizeRatio()}px !important`,
        fontWeight: 'bold !important',
        textAlign: 'center',
        '& div.MuiFormControl-root': {
          background: palette.contrastText,
          borderRadius: 8,
          marginTop: 4,
          marginBottom: 4,
          '& div.MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiTypography: {
      root: textStyle,
    },
    MuiDialogActions: {
      root: {
        paddingTop: 32,
        paddingRight: 16,
        paddingBottom: 32,
        paddingLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& button:nth-child(2)': {
          marginTop: 8,
          marginBottom: -12,
        },
        '& button:nth-child(3)': {
          marginTop: 8,
          marginBottom: -12,
        },
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
      },
      contained: {
        width: 230,
        height: 44,
        fontSize: 16,
        color: palette.contrastText,
        backgroundColor: '#423BC7',
        borderRadius: 26,
        boxShadow: 'none !important',
        padding: 0,
        '&:disabled': {
          color: '#ffffff',
          backgroundColor: '#e0e0e0 !important',
        },
        '&:hover': {
          color: palette.contrastText,
          backgroundColor: '#423BC7 !important',
        },
      },
      text: {
        width: 160,
        height: 42,
        fontSize: 12,
        color: palette.contrastText,
        backgroundColor: palette.main,
        padding: 0,
        '&:hover': {
          color: palette.contrastText,
          backgroundColor: palette.main,
        },
      }
    }
  }
  return createTheme(
    Object.assign(baseThemeProps(), { overrides })
  )
}
