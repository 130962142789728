import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import FoodReportExample from 'components/foodreport/FoodReportExample'
import Koma from 'images/foodreport/koma.png'
import step5Img from 'images/foodreport/text_5step.png'
import BgImg from 'images/img_secret_room.png'

const useStyles = makeStyles(theme => ({
  paper: {
    background: '#DBDCFE',
    padding: 20,
    borderRadius: '24px 24px 0 0',
    backgroundImage: `url(${BgImg})`,
  },
  backButton: {
    padding: 0,
    color: theme.palette.secondary.main,
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  step5Img: {
    width: 216,
    margin: 'auto',
    marginBottom: theme.spacing(1),
  },
  komaImg: {
    width: 168,
    height: 68,
    objectFit: 'cover',
    objectPosition: '0 0',
    margin: 'auto',
  },
  stepBox: {
    fontWeight: 'bold',
    background: '#ffffff',
    width: 289,
    borderRadius: 12,
    boxShadow: '0 1px 6px #00000029',
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(3),
  },
  stepNum: {
    fontSize: 22,
    color: '#1A1667',
    width: 16,
    position: 'absolute',
  },
  stepTitle: {
    fontSize: 16,
    color: '#1A1667',
    textAlign: 'center',
  },
  underline: {
    textDecoration: 'underline',
  },
  stepSubText: {
    fontSize: 11,
    color: '#423BC7',
    textAlign: 'center',
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 9.5px 0 9.5px',
    borderColor: '#423BC7 transparent transparent transparent',
    margin: 'auto',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: 'auto',
    marginBottom: theme.spacing(5),
    marginLeft: 'auto',
  },
  detailBox: {
    fontSize: 12,
    color: '#1A1667',
    background: '#ffffff',
    width: 289,
    borderRadius: 12,
    boxShadow: '0 1px 6px #00000029',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  column: {
    marginBottom: theme.spacing(1),
  },
  detailTitle: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
    whiteSpace: 'pre',
  },
  attention: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(0.5),
  },
}))

export default function FoodReportOutlineDialog (props) {
  const classes = useStyles()
  const { openOutlineDialog, onClose, handleOpenShopList, campaignMaster, shopList } = props
  const [openExampleDialog, setOpenExampleDialog] = useState(false)
  const [campaignStartAt, setCampaignStartAt] = useState('')
  const [campaignEndAt, setCampaignEndAt] = useState('')

  useEffect(() => {
    if (campaignMaster) {
      const startDate = campaignMaster.campaign_start_at
      setCampaignStartAt(dateFormat(startDate, 'yyyy/mm/dd'))
      const endDate = new Date(campaignMaster.campaign_end_at)
      endDate.setDate(endDate.getDate() - 1)
      setCampaignEndAt(dateFormat(endDate, 'yyyy/mm/dd'))
    }
  }, [campaignMaster])

  const createStepBox = (num, title, text) => {
    const funcMap = {
      1: handleOpenShopList,
      2: null,
      3: null,
      4: () => setOpenExampleDialog(true),
      5: null,
    }
    return (
      <React.Fragment>
        <div className={classes.stepBox} onClick={funcMap[num]}>
          <span className={classes.stepNum}>{num}</span>
          <div className={`${classes.stepTitle} ${(num === 1 || num === 4) && classes.underline}`}>{title}</div>
          <div className={classes.stepSubText}>{text}</div>
        </div>
        {num !== 5 && <div className={classes.triangle} />}
      </React.Fragment>
    )
  }

  const createDetailInfo = (title, text) => {
    return (
      <Grid className={classes.column} container wrap="nowrap">
        <div className={classes.detailTitle}>{title}</div>
        <div>{text}</div>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <Dialog
        open={openOutlineDialog}
        fullScreen
        PaperProps={{ className: classes.paper }}
      >
        <Grid container justifyContent="space-between">
          <IconButton
            className={classes.backButton}
            onClick={onClose}
          >
            <LeftIcon />
          </IconButton>
          <IconButton
            className={classes.backButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container direction="column" alignContent="center">
          <div className={classes.title}>食レポイベント参加方法</div>
          <div className={classes.text}>
            対象店舗利用＆食レポ投稿で<br />
            それぞれに最大2,000円分の<br />
            次回使えるギフト券をプレゼント！
          </div>
          <img className={classes.step5Img} src={step5Img} alt="" />
          <img className={classes.komaImg} src={Koma} alt="" />
          {createStepBox(1, '対象店をチェックする', `${shopList.length}つのレストランから選べるよ！`)}
          {createStepBox(2, '各サービスサイトから予約', '「Aillを見て予約」と伝えるとGood！')}
          {createStepBox(3, 'お店に来店', '＊食レポに必要な写真撮影を忘れずに')}
          {createStepBox(4, '食レポを投稿', '食レポイメージ\n\nこちらから')}
          {createStepBox(5, 'ギフト券受け取り', 'ギフト券は後日郵送されます')}
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleOpenShopList}
          >
            お店を予約する
          </Button>
          <div className={classes.title}>食レポイベント詳細</div>
          <div className={classes.detailBox}>
            {createDetailInfo('対象期間', `${campaignStartAt} ~ ${campaignEndAt}`)}
            {createDetailInfo(
              '適用条件',
              <>
                1.対象のレストランを選ぶ<br />
                2.店舗HPもしくは電話にて<br />「Aillを見ました」と伝え予約するとスムーズ<br />
                3.当日お店に来店<br />
                4.お気に入りの料理の写真を撮影<br />
                5.食事レポートを投稿<br />
                6.ギフト券の送付先の住所を送信
              </>
            )}
            {createDetailInfo(
              '特典内容',
              <>
                適用条件を満たした2人それぞれに、次回使えるギフト券をプレゼント！<br />
                ルクア大阪内の対象店舗：1,000円分<br />
                ホテルグラヴィア大阪内の対象店舗：2,000円分
              </>
            )}
            {createDetailInfo(
              '対象　　',
              <>
                このお知らせが表示された<br />
                Aill goenユーザー
              </>
            )}
            {createDetailInfo(
              '対象店舗',
              <>
                {shopList.length > 0 && shopList.map((shopData, index) => {
                  return <div key={index}>{shopData.shop_name}&nbsp;&nbsp;</div>
                })}
              </>
            )}
          </div>
          <div className={classes.attention}>＊蔓延防止規程に則ってイベントは実施されます</div>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleOpenShopList}
          >
            お店を予約する
          </Button>
        </Grid>
      </Dialog>
      <FoodReportExample
        open={openExampleDialog}
        onClose={() => setOpenExampleDialog(false)}
      />
    </React.Fragment>
  )
}

FoodReportOutlineDialog.propTypes = {
  openOutlineDialog: PropTypes.bool,
  onClose: PropTypes.func,
  handleOpenShopList: PropTypes.func,
  campaignMaster: PropTypes.object,
  shopList: PropTypes.array,
}
