import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class SendIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 440 320" preserveAspectRatio="xMidYMid meet">
          <g>
            <path d="M0 314 c0 -4 25 -37 55 -75 30 -37 55 -73 55 -79 0 -6 -25 -42 -55 -79 -30 -38 -55 -71 -55 -75 0 -3 29 -6 64 -6 l65 0 60 75 c34 41 61 80 61 86 0 6 -27 44 -61 85 l-60 74 -65 0 c-35 0 -64 -3 -64 -6z"/>
            <path d="M190 314 c0 -3 27 -39 61 -80 l60 -74 -60 -74 c-34 -41 -61 -77 -61 -80 0 -3 30 -6 68 -6 l67 1 58 73 c31 41 57 79 57 86 0 7 -26 45 -57 86 l-58 73 -67 1 c-38 0 -68 -3 -68 -6z"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(SendIcon)