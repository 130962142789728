import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import CheckIcon from 'images/invitation-campaign/check_icon.png'
import CopyIcon from 'images/invitation-campaign/copy_icon.png'
import InputIcon from 'images/invitation-campaign/input_icon.png'
import SendIcon from 'images/invitation-campaign/send_icon.png'

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#1A1667',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    lineHeight: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    background: 'linear-gradient(transparent 50%, #DBDCFE 50%)'
  },
  text: {
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
  },
  img: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
}))

export default function HowToInvitationCampaignDialog (props) {
  const classes = useStyles()
  const { open, onClose } = props

  const createStep = (text) => (
    <Typography className={classes.subtitle} variant="subtitle1">{text}</Typography>
  )

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} style={{ color: '#17185B' }} />
        <DialogTitle className={classes.title} disableTypography>
          お友達紹介キャンペーン<br />
          利用方法
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" justyfyContent="center" alignItems="center">
            {createStep(' ステップ1 ')}
            <Typography className={classes.text} variant="body1">
              「紹介コード」欄より<br />
              紹介コードをコピー
            </Typography>
            <img className={classes.img} src={CopyIcon} width={60} height={60} />
            {createStep(' ステップ2 ')}
            <Typography className={classes.text} variant="body1">
              コードをお友達に送信
            </Typography>
            <img className={classes.img} src={SendIcon} width={60} height={60} />
            {createStep(' ステップ3 ')}
            <Typography className={classes.text} variant="body1">
              お友達がAill goenに登録し<br />
              本登録画面にて紹介コードを入力
            </Typography>
            <img className={classes.img} src={InputIcon} width={60} height={60} />
            {createStep(' ステップ4 ')}
            <Typography className={classes.text} variant="body1">
              お友達が入会審査通過後<br />
              双方に無料期間が付与
            </Typography>
            <img className={classes.img} src={CheckIcon} width={60} height={60} />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions} />
      </Dialog>
    </DialogThemeProvider>
  )
}

HowToInvitationCampaignDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
