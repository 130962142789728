import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    borderColor: '#707070',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  title: {
    color: '#707070',
    fontSize: 14,
  },
  payment: {
    color: '#1A1667',
    fontSize: 16,
  },
  helperText: {
    color: '#9D9D9E',
    fontSize: 14,
  },
  button: {
    width: 240,
  }
}))

function Payment (props) {
  const { payment, onClickUpdate } = props

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Box flexGrow={2} minWidth={240}>
        <Typography className={classes.title}>
          {payment ? '登録済みのお支払い方法' : 'お支払い方法が登録されていません。'}
        </Typography>
        {
          payment &&
          <Fragment>
            <Typography className={classes.payment}>
              {payment.card_brand}&nbsp;&nbsp;末尾{payment.card_number_tail}
            </Typography>
            <Typography className={classes.helperText}>
              ＊有効期限は&nbsp;{payment.card_expire}&nbsp;です。
            </Typography>
          </Fragment>
        }
      </Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => onClickUpdate && onClickUpdate()}
      >
        お支払い方法を{payment ? '変更' : '登録'}する
      </Button>
    </div>
  )
}

Payment.propTypes = {
  payment: PropTypes.object,
  onClickUpdate: PropTypes.func,
}

export default Payment