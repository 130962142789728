/* eslint-disable */
// 紹介画面バナー背景画像
import BackImg202405 from 'images/special-offer/202405/banner_friend_home.png'
import BackImg202407 from 'images/special-offer/202407/banner_friend_home.png'
import BackImg202408 from 'images/special-offer/202408/banner_friend_home.png'
// お知らせポップアップ画像
import BgImgPink from 'images/special-offer/202405/dialog_pink.png'
import BgImgLightBlue from 'images/special-offer/202407/dialog_light_blue.png'
import BgImgOrange from 'images/special-offer/202408/dialog_orange.png'
// FriendCell背景
import FriendCellImg202405 from 'images/special-offer/202405/friend_cell.png'
import FriendCellImg202407 from 'images/special-offer/202407/friend_cell.png'
import FriendCellImg202408 from 'images/special-offer/202408/friend_cell.png'
// スペシャルオファー詳細画面TOP画像
import TopPink from 'images/special-offer/202405/top_pink.png'
import TopLightBlue from 'images/special-offer/202407/top_light_blue.png'
import TopOrange from 'images/special-offer/202408/top_orange.png'
// スペシャルオファー詳細画面サブ画像
import SubPink from 'images/special-offer/202405/sub_pink.png'
import SubLightBlue from 'images/special-offer/202407/sub_light_blue.png'
import SubOrange from 'images/special-offer/202408/sub_orange.png'
// スペシャルオファー詳細画面Bottom画像
import BottomPink from 'images/special-offer/202405/bottom_pink.png'
import BottomLightBlue from 'images/special-offer/202407/bottom_light_blue.png'
import BottomOrange from 'images/special-offer/202408/bottom_orange.png'
// 利用方法画像1
import HowTo01Pink from 'images/special-offer/202405/how_to_01_pink.png'
import HowTo01LightBlue from 'images/special-offer/202407/how_to_01_light_blue.png'
import HowTo01Orange from 'images/special-offer/202408/how_to_01_orange.png'
// 利用方法画像2
import HowTo02Pink from 'images/special-offer/202405/how_to_02_pink.png'
import HowTo02LightBlue from 'images/special-offer/202407/how_to_02_light_blue.png'
import HowTo02Orange from 'images/special-offer/202408/how_to_02_orange.png'
// 利用方法画像3
import HowTo03Pink from 'images/special-offer/202405/how_to_03_pink.png'
import HowTo03LightBlue from 'images/special-offer/202407/how_to_03_light_blue.png'
import HowTo03Orange from 'images/special-offer/202408/how_to_03_orange.png'
// キャンペーン一覧画面画像
import BannerPink from 'images/special-offer/202405/banner_pink.png'
import BannerLightBlue from 'images/special-offer/202407/banner_light_blue.png'
import BannerOrange from 'images/special-offer/202408/banner_orange.png'

const theme_202405 = {
  mainColor: '#F8608B',
  subColor: '#FFEBF1',
  textShadowColor: '#F8608B',
  valueColor: '#F41F5B',
  bannerTextColor: '#F8608B',
  topBannerImg: BackImg202405,
  dialogImg: BgImgPink,
  friendCellImg: FriendCellImg202405,
  topImg: TopPink,
  subImg: SubPink,
  bottomImg: BottomPink,
  howTo01Img: HowTo01Pink,
  howTo02Img: HowTo02Pink,
  howTo03Img: HowTo03Pink,
  bannerImg: BannerPink,
}

const theme_202407 = {
  mainColor: '#0092FF',
  subColor: '#EAF7FF',
  textShadowColor: '#A7DBFC',
  valueColor: '#0082FF',
  bannerTextColor: 'none',
  topBannerImg: BackImg202407,
  dialogImg: BgImgLightBlue,
  friendCellImg: FriendCellImg202407,
  topImg: TopLightBlue,
  subImg: SubLightBlue,
  bottomImg: BottomLightBlue,
  howTo01Img: HowTo01LightBlue,
  howTo02Img: HowTo02LightBlue,
  howTo03Img: HowTo03LightBlue,
  bannerImg: BannerLightBlue,
}

const theme_202408 = {
  mainColor: '#FF3100',
  subColor: '#FFEFE0',
  textShadowColor: '#FCB9AC',
  valueColor: '#FF3100',
  bannerTextColor: 'none',
  topBannerImg: BackImg202408,
  dialogImg: BgImgOrange,
  friendCellImg: FriendCellImg202408,
  topImg: TopOrange,
  subImg: SubOrange,
  bottomImg: BottomOrange,
  howTo01Img: HowTo01Orange,
  howTo02Img: HowTo02Orange,
  howTo03Img: HowTo03Orange,
  bannerImg: BannerOrange,
}

export const theme = (offer) => {
  // 3ヶ月ごとにループ
  const date = offer?.end_at ? new Date(offer.end_at) : new Date()
  const month = date.getMonth() + 1
  switch (month % 3) {
    case 0:
      return theme_202405
    case 1:
      return theme_202407
    case 2:
      return theme_202408
    default:
      return theme_202405
  }
}
