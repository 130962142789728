import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import AppHeader from 'components/parts/AppHeader'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.styles.header.height,
  },
  text: {
    fontSize: 16,
    marginTop: theme.spacing(4),
  },
  link: {
    color: '#423BC7',
  },
}))

export default function AccountInfo (props) {
  const { ...other } = props
  const classes = useStyles()

  const openServiceSite = () => {
    window.open(Config.serviceSiteUrl)
  }

  return (
    <div className={classes.root}>
      <AppHeader title="アカウント情報" backWhite={true} {...other} />
      <div>
        <Typography className={classes.text}>
          会員情報の確認・変更・退会は、<br />
          <span className={classes.link} onClick={openServiceSite}>
            Aill goenサービスサイト
          </span>
          の「会員ログイン（メンバーズサイト）」よりお願いします。
        </Typography>
      </div>
    </div>
  )
}
