import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import FlagLeft from 'images/comeback-campaign/flag_left.png'
import FlagRight from 'images/comeback-campaign/flag_right.png'

const useStyles = makeStyles(() => ({
  root: {
    height: 80,
    fontSize: 18,
    fontWeight: 600,
    color: '#ffffff',
    background: '#F55083',
    boxSizing: 'border-box',
  },
}))

export default function ComebackBanner () {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container justifyContent="space-between" alignItems="center">
      <img src={FlagLeft} width={40} height={80} />
      スタンダードプラン無料体験中
      <img src={FlagRight} width={40} height={80} />
    </Grid>
  )
}
