import React from 'react'
import { isNullOrUndefined } from 'util'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import ChatInput from 'components/parts/ChatInput.jsx'
import CommonChat from 'components/parts/CommonChat'
import IconX from 'images/img_photo_exX.png'
import BackgroundImage from 'images/img_secret_room.png'

// 秘密の質問ボタン表示
const displayButton = [
  'strength_1',
  'strength_3',
  'weakness_1',
  'weakness_3',
  'hobby_1',
  'life_cycle_1',
  'family_1',
  'family_2',
  'family_3',
  'family_4',
  'communication_with_family_1',
  'communication_with_family_2',
  'communication_with_family_3',
  'communication_with_family_4',
  'talk_compat_1',
  'talk_compat_2',
  'job_1',
  'job_2',
  'job_3',
  'job_4',
  'career_plan_1',
  'career_plan_2',
  'career_plan_3',
  'career_plan_4',
  'friend_1',
  'friend_2',
  'true_values_1',
  'true_values_2',
  'true_values_3',
  'true_values_4',
  'view_of_love_2',
  'love_weight_1',
  'love_weight_2',
  'life_plan_1',
  'life_plan_2',
  'life_plan_3',
  'view_of_marriage_2',
]

// 秘密の質問選択リスト表示
const displaySelect = [
  'talk_compat_3',
  'true_values_5',
]

const styles = theme => ({
  dialogRoot: {
    position: 'relative!important',
    height: '100%',
  },
  paper: {
    width: '100%',
    maxWidth: '100%',
    margin: 0,
    height: `calc(${window.innerHeight}px - 18px)`,
    maxHeight: '100%',
    borderRadius: '32px 32px 0 0',
    position: 'fixed',
    bottom: 0,
    ...theme.styles.secretRoomColor,
    backgroundImage: `url(${BackgroundImage})`,
  },
  scroll: {
    padding: 0,
  },
  container: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  iconX: {
    position: 'fixed',
    top: 34,
    right: 24,
    width: 16,
    height: 16,
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    ...theme.styles.messageColor,
    textAlign: 'center',
  },
  question_container: {
    marginTop: theme.spacing(1.5),
  },
  question: {
    fontSize: 12,
    fontWeight: 'bold',
    ...theme.styles.messageColor,
    textAlign: 'center',
  },
  selectButton: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 'bold',
    width: 230,
    height: 40,
    marginTop: theme.spacing(1.5),
  },
  dateContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  date: {
    display: 'inline-block',
    color: '#555555',
    fontSize: 10,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  messageContainer: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  answer: {
    width: 230,
    height: 40,
    marginTop: theme.spacing(1),
    display: 'table',
    zIndex: '999'
  },
  answerValue: {
    backgroundColor: theme.palette.primary.main,
    color: '#555555',
    width: 230,
    fontSize: 14,
    fontWeight: 'bold',
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: 26,
  },
  answerValueDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
    width: 230,
    fontSize: 14,
    fontWeight: 'bold',
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: 26,
  },
  rightSpeach: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '25px 25px 5px 25px',
    padding: theme.spacing(2),
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2.5),
    display: 'inline-block',
  },
  leftSpeach: {
    backgroundColor: '#F5F5F5',
    borderRadius: '25px 25px 25px 5px',
    padding: theme.spacing(2),
    marginTop: -theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'inline-block',
  },
  rightSpeachText: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  leftSpeachText: {
    fontSize: 12,
    color: '#414141',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  faceAvatar: {
    marginLeft: theme.spacing(2.375),
    marginRight: theme.spacing(1.375),
    marginBottom: theme.spacing(1),
    width: 36,
    height: 36,
  },
  actions: {
    padding: 0
  },
  formControl: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
    width: 240,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 26,
  },
  selectList: {
    textAlign: 'center',
    paddingLeft: `calc(24px + ${theme.spacing(3)}px)`,
  },
})

export class SecretQuestionRoom extends CommonChat {
  constructor (props) {
    super(props)

    this.toItemElement = this.toItemElement.bind(this)
    this.sendAnswerSecretQuestion = this.sendAnswerSecretQuestion.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleConfirmOK = this.handleConfirmOK.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSelectList = this.handleSelectList.bind(this)

    this.cable = null
    this.channel = null

    this.receivedMessages = []
    this.firstMessage = null
    this.lastMessage = null
    this.canFetchPastMessages = true
    this.noUpdateLastMessageId = false
    this.selectedAnswer = null
    this.elementKey = 0

    this._isMounted = false

    this.state = {
      scrollBottom: true,
      friend: props.friend,
      chat_room_id: props.chat_room_id,
      userId: props.user_id,
      displayItems: [],
      openWarningDialog: false,
      warningText: '',
      openNaviIntroDialog: false,
      openConfirmDialog: false,
      confirmMessage: '',
      menuTargetMessage: {},
      openMessageMenuDialog: false,
      openMessageDeleteConfirmDialog: false,
      chatInputHeight: 56,
      userAnswer: null,
      friendAnswer: null,
      openAnswerConfirm: false,
      answerToConfirm: '',
      selectedItem: 'not_select',
      okDisabled: false,
      memoOpen: false,
      memoText: '',
    }
  }

  async componentWillMount () {
    this.props.setLoading(true)
    try {
      await this.setAnswerQuestion()
      await this.initChat(this.props.chat_room_id)
      this.patchLastMessageId()

      // 過去メッセージ取得のトリガーとしてYスクロールを監視
      let chatScroll = this.getMessagesContainer()
      chatScroll.on('scroll', _.throttle(this.handleScroll, 300))

      this.scrollToBottom()
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  componentDidMount () {
    this._isMounted = true
    Api.addActionLog('visit_page', '/SecretQuestionRoom', {
      friend_user_id: this.props.friend.id
    })
    BaseComponent.gaModalView('secretquestionroom')
  }

  async componentWillUnmount () {
    this._isMounted = false
    await this.patchLastMessageId()
    if (this.channel) {
      this.channel.unsubscribe()
      this.channel = null
    }
    if (this.cable) {
      this.cable.disconnect()
      this.cable = null
    }

    Api.addActionLog('leave_page', '/SecretQuestionRoom', {
      friend_user_id: this.props.friend.id
    })
    $(window).off('scroll')
  }

  async componentWillReceiveProps (nextProps) {
    if (nextProps.chat_room_id !== this.state.chat_room_id) {
      this.componentWillUnmount()
      this.setState({ chat_room_id: nextProps.chat_room_id })
      try {
        await this.setAnswerQuestion()
        await this.initChat(nextProps.chat_room_id)
        this.scrollToBottom()
      } catch (error) {
        this.handleApiError(error)
      }
    }
  }

  // 秘密の質問回答取得
  async setAnswerQuestion () {
    try {
      let messages = await Api.getSecretQuestionAnswers(this.props.chat_room_id, this.props.message.id)
      messages.forEach(message => {
        if (message.user_id === this.props.userId) {
          // 自分の回答
          this.setStateIfMounted({
            userAnswer: message.secret_question_answer,
            selectedItem: message.secret_question_answer,
          })
        } else {
          // 相手の回答
          this.setStateIfMounted({ friendAnswer: message.secret_question_answer })
        }
      })
    } catch (error) {
      this.handleApiError(error)
    }
  }

  // 未回答アンケートチェック
  existNotAnsweredQuestion () {
    if (this.isNotAnsweredSecretQuestion()) return true
    return this.receivedMessages.some(msg => {
      return this.isNotAnsweredQuestion(msg) || this.isNotAnsweredConfirm(msg)
    })
  }

  // 秘密の質問に回答済か
  isNotAnsweredSecretQuestion () {
    if (this.isButtonList() || this.isSelectList()) {
      return  isNullOrUndefined(this.state.userAnswer)
    } else {
      return false
    }
  }

  toItemElement (item, key) {
    if (item.getTime) {
      return this.createDateElement(item, key)
    }
    else if (!isNullOrUndefined(item.secret_question_answer)) {
      return this.createAnswerElement(item)
    }
    else if (item.navi_type === 'angel' || item.navi_type === 'devil') {
      return this.createNaviMessageElement(item, key)
    } else {
      if (item.user_id === this.props.userId) {
        // 自分のメッセージ
        return this.createMyMessageElement(item, key)
      } else {
        // 相手のメッセージ
        return this.createFriendMessageElement(item, key)
      }
    }
  }

  // 回答値から表示ラベルを取得
  getQestionAnswerLabel (answerValue) {
    let label = ''
    if (!isNullOrUndefined(answerValue)) {
      let answer_choices = this.props.message.question_answer_choices
      let answer = answer_choices.find(answer => {
        if (isFinite(answer.value)) {
          return Math.floor(answer.value).toString() === answerValue.toString()
        } else {
          return answer.value === answerValue
        }
      })
      label = answer.label
    }
    return label
  }

  // 秘密の質問アンケート回答送信
  async sendAnswerSecretQuestion (message, value) {
    this.props.setLoading(true)
    try {
      await Api.answerSecretQuestion(message, value)
      return true
    } catch (error) {
      this.handleApiError(error)
      return false
    } finally {
      this.props.setLoading(false)
    }
  }

  handleClose () {
    this.props.onClose()
  }

  handleClickButton (item) {
    if (!isNullOrUndefined(this.state.userAnswer)) { return }
    if (isNullOrUndefined(item.value)) { return }

    this.selectedAnswer = item.value
    this.setState({
      answerToConfirm: item.label,
      openAnswerConfirm: true,
    })
  }

  handleSelectList (event) {
    if (isNullOrUndefined(event.target.value)) { return }
    if (!isNullOrUndefined(this.state.userAnswer)) { return }
    if (event.target.value === 'not_select') { return }

    this.selectedAnswer = event.target.value
    this.setState({
      answerToConfirm: this.getQestionAnswerLabel(event.target.value),
      openAnswerConfirm: true,
      selectedItem: event.target.value,
    })
  }

  async handleConfirmOK () {
    if (this.state.okDisabled) { return }

    this.setState({ okDisabled: true })
    const success = await this.sendAnswerSecretQuestion(
      this.props.message, this.selectedAnswer
    )
    this.setState({ okDisabled: false })
    if (success) {
      this.setStateIfMounted({ userAnswer: this.selectedAnswer })
    }
    this.handleCancel()
  }

  handleCancel () {
    this.selectedAnswer = null
    this.setState({
      openAnswerConfirm: false,
      answerToConfirm: '',
      selectedItem: 'not_select',
    })
  }

  createSelectElement () {
    if (this.isButtonList()) {
      return this.createButtonList()
    } else if (this.isSelectList()) {
      return this.createSelectList()
    } else {
      return null
    }
  }

  isButtonList () {
    if (displayButton.includes(this.props.message.secret_question_type)) {
      return true
    }
    return false
  }

  isSelectList () {
    if (displaySelect.includes(this.props.message.secret_question_type)) {
      return true
    }
    return false
  }

  createButtonList () {
    const { classes } = this.props
    const answer_choices = this.props.message.question_answer_choices

    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {answer_choices.map(item => {
          if (!isNullOrUndefined(this.state.userAnswer)) {
            return (
              <div className={classes.answer} key={item.value}>
                <Typography className={this.state.userAnswer === item.value ? classes.answerValue : classes.answerValueDisabled}>
                  {item.label}
                </Typography>
              </div>
            )
          } else {
            return (
              <Button
                className={classes.selectButton}
                variant="contained"
                color="primary"
                key={item.value}
                onClick={() => this.handleClickButton(item)}
              >
                {item.label}
              </Button>
            )
          }
        })}
      </Grid>
    )
  }

  createSelectList () {
    const { classes } = this.props
    const answer_choices = this.props.message.question_answer_choices

    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <FormControl
          className={classes.formControl}
          key='secret-select'
        >
          <Select
            className={classes.selectList}
            disabled={!isNullOrUndefined(this.state.userAnswer)}
            value={!isNullOrUndefined(this.state.userAnswer) ? this.state.userAnswer : this.state.selectedItem}
            onClick={this.handleSelectList}
            name='secret-select'
            IconComponent={() => (
              <KeyboardArrowDown />
            )}
          >
            <MenuItem value='not_select'><em>未選択</em></MenuItem>
            {answer_choices.map((val, i) => (
              <MenuItem
                key={i}
                value={isFinite(val.value) ? Math.floor(val.value) : val.value}
              >
                {val.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  createAnswerConfirm () {
    return (
      <DialogThemeProvider color="default">
        <Dialog open={this.state.openAnswerConfirm}>
          <DialogTitle disableTypography>アンケート内容の確認</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              「{this.state.answerToConfirm}」でよろしいですか？
            </Typography>
          </DialogContent>
          <DialogActions disableSpacing>
            <Button variant="contained" disabled={this.state.okDisabled} onClick={this.handleConfirmOK}>
              回答
            </Button>
            <Button variant="text" onClick={this.handleCancel}>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }

  createAnswerElement (msg) {
    if (msg.user_id === this.props.userId) {
      if (isNullOrUndefined(this.state.userAnswer)) return null

      const { classes } = this.props
      return (
        <div key="answer_user">
          <Grid container justifyContent="center" alignItems="center">
            <div className={classes.answer}>
              <Typography className={classes.answerValue}>
                {this.getQestionAnswerLabel(this.state.userAnswer)}
              </Typography>
            </div>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center">
            <div className={classes.rightSpeach}>
              <Typography className={classes.rightSpeachText}>を選びました。</Typography>
            </div>
          </Grid>
        </div>
      )
    } else {
      if (isNullOrUndefined(this.state.friendAnswer)) return null

      const { classes } = this.props
      const { friend } = this.state
      const icon = friend.type === 'closed' ? null : friend.photo_icon
      return (
        <div key="answer_friend">
          <Grid container justifyContent="center" alignItems="center">
            <div className={classes.answer}>
              <Typography className={classes.answerValue}>
                {this.getQestionAnswerLabel(this.state.friendAnswer)}
              </Typography>
            </div>
          </Grid>
          <Grid container justifyContent="flex-start" alignItems="center">
            <div className={classes.avatarContainer}>
              <Avatar src={icon} className={classes.faceAvatar} onClick={this.clickFriend} />
            </div>
            <div className={classes.leftSpeach}>
              <Typography className={classes.leftSpeachText}>を選びました。</Typography>
            </div>
          </Grid>
        </div>
      )
    }
  }

  createMessageElements (msg) {
    const { classes } = this.props
    let allElems = []
    let lines = msg.split(/\r\n|\r|\n/)
    for (let line of lines) {
      let style = {}
      let elems = [<Typography className={classes.question} style={style} key={this.nextKey()}>{line}</Typography>]
      for (let elem of elems) {
        allElems.push(elem)
      }
    }
    return allElems
  }

  nextKey () {
    this.elementKey += 1
    return this.elementKey
  }

  render () {
    const { classes, isScheduledChatEditMode, ...other } = this.props

    const scheduledChatEditModeProps = isScheduledChatEditMode && {
      className: classes.dialogRoot,
      disablePortal: true,
      disableEnforceFocus: true,
      hideBackdrop: true,
      PaperProps: {
        style: {
          position: 'static',
          height: '100%',
          borderRadius: 0,
        }
      }
    }

    return(
      <Dialog open={this.props.open} classes={{ paper: classes.paper }} {...scheduledChatEditModeProps}>
        {!isScheduledChatEditMode && <img src={IconX} className={classes.iconX} alt="" onClick={this.handleClose}/>}
        <DialogTitle disableTypography className={classes.title}>
          秘密の質問コーナーだよ！
          <div className={classes.question_container}>
            {this.createMessageElements(this.props.message.message)}
          </div>
        </DialogTitle>
        <DialogContent id='chat-scroll-secret' className={classes.scroll}>
          <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
            {this.createSelectElement()}
            {this.createDateElement(new Date(this.props.message.created_at), this.props.message.created_at)}
            <Divider className={classes.divider} />
          </Grid>
          <div className={classes.messageContainer}>
            {this.state.displayItems.map(this.toItemElement)}
          </div>
          {this.createNavi()}
          {this.createWarningDialog()}
          {this.createConfirmDialog()}
          {this.createMessageMenuDialog()}
          {this.createMessageDeleteConfirmDialog()}
          {this.createAnswerConfirm()}
          {this.createChatMemo(true)}
        </DialogContent>
        {!isScheduledChatEditMode && (
          <DialogActions className={classes.actions} style={{ height: this.state.chatInputHeight }} disableSpacing>
            <ChatInput
              {...other}
              inputId="secretMessage"
              onSend={this.sendMessage}
              friend={this.state.friend}
              setLoading={this.props.setLoading}
              secretQuestionRoomOpen={this.props.open}
              secretQuestionRoomId={this.props.message.secret_room_id}
              replyToMessage={Storage.getReplyMessage(this.props.chat_room_id)}
              changeInputRows={this.changeInputRows}
              chatRoomId={this.props.chat_room_id}
              onSendScheduledMessage={this.sendScheduledMessage}
            />
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

SecretQuestionRoom.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  message: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  chat_room_id: PropTypes.string.isRequired,
  isScheduledChatEditMode: PropTypes.bool,
}

export default withStyles(styles)(SecretQuestionRoom)