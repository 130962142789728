import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import FootPrintBGImg from 'images/tutorial/footprint_popup_background.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'

const useStyles = makeStyles(theme => ({
  rootDialog: {
    backgroundImage: `url(${FootPrintBGImg})`,
    backgroundSize: 'cover',
    ...theme.styles.secretRoomColor,
  },
  content: {
    padding: 0,
  },
  imageATitle: {
    marginRight: theme.spacing(1),
    width: 32,
  },
  imageUnTitle: {
    marginLeft: theme.spacing(1),
    width: 40,
  },
  title: {
    color: '#414141',
    margin: 0,
  },
  title2: {
    ...theme.styles.messageColor,
    margin: 0,
  },
  whiteContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  whiteNgContainer: {
    backgroundColor: '#F5F5F5',
    borderRadius: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: 376,
  },
  subTitle2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 12,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  imageItem: {
    width: 224,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text2: {
    fontSize: 12,
    ...theme.styles.messageColor,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  dialogContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    maxWidth: 400,
    position: 'relative',
  },
  tutorialImageDialog: {
    width: 288,
    height: 200,
  },
  tutorialTitleDialog: {
    width: '90%',
    position: 'absolute',
    top: 16,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  imageFootA: {
    width: 22,
    marginRight: theme.spacing(1),
  },
  imageFootUn: {
    width: 22,
    marginLeft: theme.spacing(1),
  },
  titleText: {
    fontSize: 11,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
  },
  tutorialButton: {
    width: 230,
    height: 34,
    fontSize: 12,
    borderRadius: 21,
    position: 'absolute',
    top: '30%',
    left: 0,
    right: 0,
    margin: 'auto',
    boxShadow: '0 1px 6px #00000029',
  },
  purpleButton: {
    // color: '#3C0077',
    background: 'rgba(162,105,218, 1)',
  },
  blueButton: {
    // color: '#060179',
    background: 'rgba(88,155,251, 1)',
  },
  messageATop: {
    top: 56,
  },
  messageUNTop: {
    top: 104,
  },
  messageGallery: {
    top: 152,
  },
  visitTutorial: {
    fontSize: 14,
  },
  cancel: {
    color: '#423BC7',
    background: 'none',
  },
}))

export default function PhotoTutorialNomalDialog  (props) {
  const { open, type } = props
  const classes = useStyles()

  const openTutorialPage = () => {
    const url = Config.serviceSiteUrl + 'photo-tutorial.html'
    window.open(url)
  }

  return (
    <DialogThemeProvider color="secondary">
      <Dialog classes={{ paper: classes.rootDialog }} open={open}>
        <DialogContent className={classes.content}>
          <Grid container justifyContent="center" alignItems="center">
            <img src={AImage} className={classes.imageATitle} alt="" />
            <Typography className={type === 'dialog' ? classes.title : classes.title2} variant="subtitle1">
              {type === 'dialog' ? 'Aill通信' : <>好印象な<br />プロフィール写真</>}
            </Typography>
            <img src={UnImage} className={classes.imageUnTitle} alt="" />
          </Grid>
          <Grid container direction="row" justifyContent="center" className={classes.whiteContainer}>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.whiteNgContainer}>
              <Typography className={classes.subTitle2}>マッチングしやすい秘訣とは</Typography>
              <Grid item container justifyContent="center" className={classes.imageItem}>
                <Typography className={classes.text2}>・好印象な写真を登録しよう！</Typography>
              </Grid>
              <Grid item container justifyContent="center" className={classes.imageItem}>
                <Typography className={classes.text2}>・複数の写真を登録しよう！</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center">
            <div className={classes.dialogContainer}>
              <div className={classes.tutorialImageDialog} />
              <Grid container justifyContent="center" alignItems="center" className={classes.tutorialTitleDialog}>
                <img src={FootAImage} className={classes.imageFootA} alt="" />
                <Typography className={classes.titleText}>カグラとサカキのちょっとアドバイス</Typography>
                <img src={FootUnImage} className={classes.imageFootUn} alt="" />
              </Grid>
              <Button
                className={`${classes.tutorialButton} ${classes.purpleButton} ${classes.messageATop}`}
                onClick={props.clickTutorial}
              >
                好印象の写真の法則を見てみよう！
              </Button>
              <Button
                className={`${classes.tutorialButton} ${classes.blueButton} ${classes.messageUNTop}`}
                onClick={props.clickTutorialBad}
              >
                こんな写真はオススメしないよ。
              </Button>
              <Button
                className={`${classes.tutorialButton} ${classes.purpleButton} ${classes.messageGallery}`}
                onClick={props.clickGalleryTutorial}
              >
                複数の写真を登録しよう！
              </Button>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='column' alignItems="center" className={classes.buttonContainerDialog}>
            <Button variant="contained" className={classes.visitTutorial} onClick={openTutorialPage}>
              好印象な写真を簡単に撮る方法
            </Button>
            <Button variant="text" className={classes.cancel} onClick={props.clickCancel}>
              キャンセル
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}
PhotoTutorialNomalDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  clickTutorial: PropTypes.func,
  clickTutorialBad: PropTypes.func,
  clickGalleryTutorial: PropTypes.func,
  clickCancel: PropTypes.func,
}