import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
  cls1: {
    fill: '#423bc7',
    stroke: '#423bc7',
    strokeWidth: 1.3,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
  cls2: {
    fill: '#423bc7',
    stroke: '#423bc7',
    strokeWidth: 1.3,
    strokeLinejoin: 'round',
  },
  cls3: {
    stroke: '#423bc7',
    strokeWidth: 1.3,
    fill: 'none',
    strokeMiterlimit: 10,
  },
  cls4: {
    fill: '#423bc7',
  },
  cls5: {
    stroke: '#423bc7',
    strokeWidth: 1.3,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  },
}

class FemaleOnIcon extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <SvgIcon viewBox="0 0 20.75 18.953">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20.75" height="18.953" viewBox="0 0 20.75 18.953">
          <g id="グループ_427" data-name="グループ 427" transform="translate(-770.35 -832.359)">
            <path id="パス_176" data-name="パス 176" className={classes.cls1} d="M15.457,270.3a10.242,10.242,0,0,0,5.679-1.832,9.984,9.984,0,0,0,2.625-2.747,9.988,9.988,0,0,0,2.626,2.747,10.242,10.242,0,0,0,5.679,1.832h-.008a8.3,8.3,0,0,0-16.592,0" transform="translate(758.356 570.638)"/>
            <g id="グループ_388" data-name="グループ 388" transform="translate(771 833.16)">
              <path id="パス_177" data-name="パス 177" className={classes.cls2} d="M16.025,283.613a5.474,5.474,0,0,1-1.247,1.766,7.417,7.417,0,0,1-3.749,1.839,7.417,7.417,0,0,1,1.839-3.749,5.651,5.651,0,0,1,2.058-1.373" transform="translate(-11.029 -269.715)"/>
              <circle id="楕円形_38" data-name="楕円形 38" className={classes.cls3} cx="8.304" cy="8.304" r="8.304" transform="translate(2.829)"/>
              <circle id="楕円形_39" data-name="楕円形 39" className={classes.cls4} cx="0.843" cy="0.843" r="0.843" transform="translate(13.473 8.194)"/>
              <circle id="楕円形_40" data-name="楕円形 40" className={classes.cls4} cx="0.843" cy="0.843" r="0.843" transform="translate(7.058 8.194)"/>
              <path id="パス_178" data-name="パス 178" className={classes.cls5} d="M24.106,281.179a3.557,3.557,0,0,0,2.825,1.316,3.466,3.466,0,0,0,2.825-1.316" transform="translate(-15.798 -269.381)"/>
              <path id="パス_179" data-name="パス 179" className={classes.cls5} d="M15.482,272.22a10.243,10.243,0,0,0,5.679-1.832,9.984,9.984,0,0,0,2.625-2.747" transform="translate(-12.653 -264.444)"/>
              <path id="パス_180" data-name="パス 180" className={classes.cls5} d="M36.857,272.22a10.242,10.242,0,0,1-5.679-1.832,9.978,9.978,0,0,1-2.626-2.747" transform="translate(-17.419 -264.444)"/>
            </g>
            <path id="パス_300" data-name="パス 300" className={classes.cls1} d="M15.457,270.3a10.242,10.242,0,0,0,5.679-1.832,9.984,9.984,0,0,0,2.625-2.747,9.988,9.988,0,0,0,2.626,2.747,10.242,10.242,0,0,0,5.679,1.832h-.008a8.3,8.3,0,0,0-16.592,0" transform="translate(758.356 570.637)"/>
            <g id="グループ_747" data-name="グループ 747" transform="translate(771 833.16)">
              <path id="パス_177-2" data-name="パス 177" className={classes.cls2} d="M16.025,283.613a5.474,5.474,0,0,1-1.247,1.766,7.417,7.417,0,0,1-3.749,1.839,7.417,7.417,0,0,1,1.839-3.749,5.651,5.651,0,0,1,2.058-1.373" transform="translate(-11.029 -269.715)"/>
              <circle id="楕円形_38-2" data-name="楕円形 38" className={classes.cls3} cx="8.304" cy="8.304" r="8.304" transform="translate(2.829)"/>
              <circle id="楕円形_39-2" data-name="楕円形 39" className={classes.cls4} cx="0.843" cy="0.843" r="0.843" transform="translate(13.473 8.194)"/>
              <circle id="楕円形_40-2" data-name="楕円形 40" className={classes.cls4} cx="0.843" cy="0.843" r="0.843" transform="translate(7.058 8.194)"/>
              <path id="パス_178-2" data-name="パス 178" className={classes.cls5} d="M24.106,281.179a3.557,3.557,0,0,0,2.825,1.316,3.466,3.466,0,0,0,2.825-1.316" transform="translate(-15.798 -269.381)"/>
              <path id="パス_179-2" data-name="パス 179" className={classes.cls5} d="M15.482,272.22a10.243,10.243,0,0,0,5.679-1.832,9.984,9.984,0,0,0,2.625-2.747" transform="translate(-12.653 -264.444)"/>
              <path id="パス_180-2" data-name="パス 180" className={classes.cls5} d="M36.857,272.22a10.242,10.242,0,0,1-5.679-1.832,9.978,9.978,0,0,1-2.626-2.747" transform="translate(-17.419 -264.444)"/>
            </g>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

FemaleOnIcon.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(FemaleOnIcon)