import React, { useEffect, useMemo } from 'react'
import { DndContext, MouseSensor, TouchSensor, useSensor, closestCenter } from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  horizontalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable'
import PropTypes from 'prop-types'
import { Grid, ImageList } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from '../../commons/constant'
import SortableGalleryItem from './SortableGalleryItem'

const useStyles = makeStyles(theme => ({
  gallery: {
    ...theme.styles.photoGallerySize,
    marginTop: theme.spacing(1),
  },
  ImageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  caption: {
    fontSize: 12,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(0.625),
  },
}))

export const ProfileEditGallery = (props) => {
  const {
    user,
    galleryItems,
    selectedGalleryIndex,
    handleOpenTutorialDialogGallery,
    handleOpenPhotoDialogGallery,
    handleUpdateDisplayOrder,
    setGalleryItems,
  } = props
  const sortableGalleryItems = useMemo(() => galleryItems?.filter(item => item.id), [galleryItems])
  const classes = useStyles()
  const galleryCols = [...Array(Const.maxGalleryItemCount).keys()]
  const [bounds, setBounds] = React.useState({ minX: 0, maxX: 0 })

  useEffect(() => {
    if (sortableGalleryItems.length === 0) return
    const firstItem = document.querySelector(`#sortable-gallery-${sortableGalleryItems[0].id}`)
    const lastItem = document.querySelector(`#sortable-gallery-${sortableGalleryItems[sortableGalleryItems.length - 1].id}`)
    setBounds({
      minX: firstItem?.getBoundingClientRect().left,
      maxX: lastItem?.getBoundingClientRect().right,
    })
  }, [sortableGalleryItems])

  const activationConstraint = {
    delay: 100,
    tolerance: 5,
  }
  const sensors = [
    useSensor(MouseSensor, {
      activationConstraint
    }),
    useSensor(TouchSensor, {
      activationConstraint
    })
  ]

  const handleDragEnd = async (event) => {
    const {
      active,
      over
    } = event
    if (active && over && active.id !== over.id) {
      setGalleryItems((items) => {
        const oldIndex = items.findIndex(item => item.id === active.id)
        const newIndex = items.findIndex(item => item.id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
      const nextOrder = sortableGalleryItems.findIndex(item => item.id === over.id)
      await handleUpdateDisplayOrder(
        active.id,
        nextOrder
      )
    }
  }

  // アップロード済みのサブ写真をはみ出ないように移動範囲を制限
  const limitMovementToGallery = (items, { minX, maxX }) => {
    return ({ transform, draggingNodeRect }) => {
      if (items.length === 0 || !draggingNodeRect || minX === undefined || maxX === undefined) {
        return transform
      }

      if (draggingNodeRect.left + transform.x < minX) {
        return { ...transform, x: minX - draggingNodeRect.left }
      }

      if (draggingNodeRect.right + transform.x > maxX) {
        return { ...transform, x: maxX - draggingNodeRect.right }
      }
      return transform
    }
  }

  return (
    <Grid item className={classes.gallery}>
      <DndContext sensors={sensors} collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis, limitMovementToGallery(sortableGalleryItems, bounds)]}
        onDragEnd={handleDragEnd}>
        <SortableContext items={sortableGalleryItems} strategy={horizontalListSortingStrategy}>
          <ImageList className={classes.ImageList} style={{ margin: 0 }} cols={6}>
            {
              galleryCols.map(index => (
                (index >= 6 && index > (galleryItems.length - 1)) ? null : (
                  <SortableGalleryItem
                    user={user}
                    key={galleryItems[index]?.id || index}
                    galleryItem={galleryItems[index]}
                    isActive={index === selectedGalleryIndex}
                    handleOpenTutorialDialogGallery={handleOpenTutorialDialogGallery}
                    handleOpenPhotoDialogGallery={() => handleOpenPhotoDialogGallery(index)}/>
                )
              ))
            }
          </ImageList>
        </SortableContext>
      </DndContext>
      <Typography align={'right'} className={classes.caption}>
        ＊ 長押しでサブ写真の並び替えができます
      </Typography>
    </Grid>
  )
}

export default ProfileEditGallery

ProfileEditGallery.propTypes = {
  user: PropTypes.object,
  galleryItems: PropTypes.array,
  selectedGalleryIndex: PropTypes.number,
  handleOpenTutorialDialogGallery: PropTypes.func,
  handleOpenPhotoDialogGallery: PropTypes.func,
  handleUpdateDisplayOrder: PropTypes.func,
  setGalleryItems: PropTypes.func,
}
