import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import BaseComponent from 'components/parts/BaseComponent'
import SelfIntroductionTemplateDialog from 'components/self-introduction/SelfIntroductionTemplateDialog'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import TutorialImage from 'images/tutorial/img_message_tutorial.png'

const useStyles = makeStyles(theme => ({
  inputContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  tutorialContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
    maxWidth: 400,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  message: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
    border: 'solid 1px #707070',
    borderRadius: 8,
  },
  error: {
    borderColor: 'red',
  },
  countText: {
    textAlign: 'right',
    height: 35,
    marginTop: theme.spacing(0.75),
  },
  textError: {
    color: 'red',
  },
  saveBotton: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: 268,
    height: 44,
    marginBottom: theme.spacing(2),
  },
  temporarySaveBotton: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: 268,
    height: 44,
    marginBottom: theme.spacing(0.5),
  },
  attentionText: {
    fontSize: 12,
    color: '#414141',
  },
  tutorialImage: {
    width: '100%',
    height: 190,
  },
  imageContainer: {
    position: 'relative',
  },
  messageA: {
    backgroundColor: '#8636D6',
    borderRadius: 21,
    position: 'absolute',
    top: '30%',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '68%',
    height: 34,
    marginTop: theme.spacing(1.5)
  },
  messageAText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: '34px',
  },
  messageUN: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: '#006EDF',
    borderRadius: 21,
    position: 'absolute',
    top: '65%',
    left: 0,
    right: 0,
    margin: 'auto',
    width: '68%',
    height: 34,
  },
  imageA: {
    position: 'absolute',
    top: '32%',
    left: '4%',
    width: '9%',
    marginTop: theme.spacing(1.5)
  },
  imageUn: {
    position: 'absolute',
    top: '69%',
    right: '4%',
    width: '11%',
  },
  tutorialTitle: {
    width: '90%',
    position: 'absolute',
    top: 16,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  titleText: {
    fontSize: 11,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
  },
  subText: {
    fontSize: 11,
    ...theme.styles.messageColor,
  },
  imageFootA: {
    width: 22,
    marginRight: theme.spacing(1),
  },
  imageFootUn: {
    width: 22,
    marginLeft: theme.spacing(1),
  },
}))

export default function SelfIntroduction (props) {
  const classes = useStyles()
  const { user, openTutorial } = props
  // DEV-1305 テキスト入力時のstate更新制御対応
  // const [message, setMessage] = useState(user.to_message || '')
  const [errorInputMessage, setErrorInputMessage] = useState(false)
  const [overLength, setOverLength] = useState(false)
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false)

  useEffect(() => {
    if (!user) { return }
    setMessage(user.to_message || '')
  }, [user])

  const setMessage = (text) => {
    const input = document.getElementById('selfIntroductionMessage')
    input.value = text || ''
  }

  const clickSave = async () => {
    const input = document.getElementById('selfIntroductionMessage')
    if (input.value.length > Const.maxToMessageLength) {
      // 最大文字数を超えている
      setErrorInputMessage(true)
      setOverLength(true)
      return
    } else if (input.value.length === 0) {
      // 未入力
      setErrorInputMessage(true)
      return
    } else if (!input.value.match(/\S/g)) {
      // 空白文字のみ入力
      setErrorInputMessage(true)
      return
    }
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, {
        to_message: input.value
      })
      BaseComponent.goBack(props)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const onChangeMessage = event => {
    const text = event.target.value
    // setMessage(text)
    const overLength = text.length > Const.maxToMessageLength
    if (!errorInputMessage && overLength) {
      setErrorInputMessage(true)
      setOverLength(true)
    } else if (errorInputMessage && !overLength) {
      setErrorInputMessage(false)
      setOverLength(false)
    }
  }

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <div className={classes.inputContainer}>
          <InputBase
            id="selfIntroductionMessage"
            className={classes.message}
            placeholder="自己紹介文を入力"
            // value={message}
            onChange={onChangeMessage}
            error={errorInputMessage}
            classes={{ error: classes.error }}
            multiline
            rows="15"
            inputProps={{ maxLength: Const.maxToMessageLength }}
          />
          <Typography className={`${classes.countText} ${overLength && classes.textError}`}>
            最大{Const.maxToMessageLength}文字
          </Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          className={classes.saveBotton}
          onClick={() => clickSave()}
        >
          保存する
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.temporarySaveBotton}
          onClick={() => setOpenTemplateDialog(true)}
        >
          テンプレートで作成する
        </Button>
        <Typography className={classes.attentionText}>
          ※一時保存中もこちらから
        </Typography>
        <div className={classes.tutorialContainer}>
          <div className={classes.imageContainer}>
            <img src={TutorialImage} className={classes.tutorialImage} alt="" />
            <Grid container justifyContent="center" alignItems="center" className={classes.tutorialTitle}>
              <img src={FootAImage} className={classes.imageFootA} alt="" />
              <Typography className={classes.titleText}>
                カグラとサカキのちょっとアドバイス
              </Typography>
              <img src={FootUnImage} className={classes.imageFootUn} alt="" />
              <Typography className={classes.subText}>
                テンプレートを使うと魅力的な文章が書けるよ!
              </Typography>
            </Grid>
            <div className={classes.messageA}>
              <Typography className={classes.messageAText}>
                自分らしさを伝える自己紹介とは？
              </Typography>
            </div>
            <Button
              variant="contained"
              className={classes.messageUN}
              onClick={openTutorial}
            >
              好印象な自己紹介例を見てみよう
            </Button>
            <img src={AImage} className={classes.imageA} alt="" />
            <img src={UnImage} className={classes.imageUn} alt="" />
          </div>
        </div>
      </Grid>
      <SelfIntroductionTemplateDialog
        open={openTemplateDialog}
        onClose={() => setOpenTemplateDialog(false)}
        onChange={text => setMessage(text)}
        user={user}
      />
    </>
  )
}

SelfIntroduction.propTypes = {
  user: PropTypes.object,
  openTutorial: PropTypes.func,
  setLoading: PropTypes.func,
  updateUser: PropTypes.func,
  history: PropTypes.object,
}