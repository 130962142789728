import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import BaseComponent from 'components/parts/BaseComponent'
import MyProfileHeader from 'components/parts/MyProfileHeader'
import Settings from 'components/Settings'

const styles = theme => ({
  root: {
    width: theme.spacing(38.75),
    paddingTop: theme.spacing(4.25),
  },
  buttonContainer: {
    marginTop: theme.spacing(1.5),
  },
  button: {
    fontSize: 12,
    fontWeight: 'bold',
    width: 192,
    height: 32,
  },
  settingsContainer: {
    width: '100%',
  },
  feedbackButton: {
    ...theme.styles.entryButton,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    width: theme.spacing(46),
    height: theme.spacing(6),
  },
  spaceContainer: {
    height: theme.spacing(5.2),
  },
  space: {
    marginBottom: theme.spacing(1.3),
  },
})

export class MyPage extends BaseComponent {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    const { classes, ...other } = this.props

    return (
      <div>
        <Grid container direction='column' alignItems="center" className={classes.root}>
          <MyProfileHeader
            user={this.props.user}
            component={this}
            simple={true}
            {...other}
          />
          <Grid item className={classes.buttonContainer}>
            <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.props.setScreen('MyProfile')}>
              プロフィールを編集・確認
            </Button>
          </Grid>
          {
            this.props.user.sex === 'male' ? (
              <div className={classes.buttonContainer}>
                <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.props.setScreen('ShopSearch')}>
                  レストラン検索
                </Button>
              </div>
            ) : (
              <div className={classes.spaceContainer}/>
            )
          }
          <div className={classes.space}/>
          <Grid item className={classes.settingsContainer}>
            <Settings {...other} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

MyPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MyPage)