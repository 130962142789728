import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import WinterEventChallengeDialog from 'components/winter-event/WinterEventChallengeDialog'
import SnowImg from 'images/winter-event/snow.png'

const useStyles = makeStyles(() => ({
  root: {
    height: 80,
    backgroundColor: '#C73C51',
    backgroundImage: `url(${SnowImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPositionX: 'center',
    position: 'relative',
    boxSizing: 'border-box',
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
  },
}))

export default function WinterEventBanner (props) {
  const { missions} = props
  const classes = useStyles()
  const [openWinterEventChallengeDialog, setOpenWinterEventChallengeDialog] = useState(false)

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => setOpenWinterEventChallengeDialog(true)}
      >
        <Typography className={classes.subTitle}>ウィンターデートに行こう！</Typography>
        <Typography className={classes.title}>「いいね！」で最大+10名ご紹介</Typography>
      </Grid>
      <WinterEventChallengeDialog
        open={openWinterEventChallengeDialog}
        onClose={() => setOpenWinterEventChallengeDialog(false)}
        missions={missions}
      />
    </>
  )
}

WinterEventBanner.propTypes = {
  missions: PropTypes.array,
}
