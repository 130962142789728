import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  notice: {
    marginBottom: theme.spacing(2),
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: 182,
  },
}))

export default function NewCampaignDialog (props) {
  const { open, title, subtitle, text, notice, imageSrc, onClose } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.subtitle} variant="subtitle1">
            {subtitle}
          </Typography>
          <Typography className={classes.text} variant="body1">
            {text}
          </Typography>
          <Typography className={classes.notice} variant="body2">
            {notice}
          </Typography>
          <img src={imageSrc} className={classes.img} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

NewCampaignDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  notice: PropTypes.string,
  imageSrc: PropTypes.string,
  onClose: PropTypes.func,
}
