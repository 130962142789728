import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { calcDateDiff } from 'commons/utility'
import ShortCurtain from 'images/movie/theater_curtain_short.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: 80,
    backgroundColor: '#1A1667',
    backgroundImage: `url(${ShortCurtain})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPositionX: 'center',
    backgroundPositionY: '-32px',
    position: 'relative',
    paddingTop: theme.spacing(3.75),
    paddingBottom: theme.spacing(1.75),
    boxSizing: 'border-box',
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
  },
  badge: {
    position: 'absolute',
    top: 36,
    right: 36,
    padding: theme.spacing(1.25)
  },
  badgeTextSmall: {
    fontSize: 7,
    fontWeight: 600,
    color: '#EFCD3F',
    position: 'absolute',
    top: 5,
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  badgeText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#EFCD3F',
  },
  line: {
    width: 2,
    height: 6,
    background: '#EFCD3F',
    position: 'absolute',
  },
}))

export default function MovieEventBanner (props) {
  const { movieEvent } = props
  const classes = useStyles()

  const endAt = movieEvent.end_at
  const dateDiff = calcDateDiff(new Date(), new Date(endAt))

  const createSmallText = () => {
    return dateDiff <= 7 ? '残り' : '追加'
  }

  const createText = () => {
    return dateDiff <= 7 ? `${dateDiff}日` : '紹介'
  }

  const createBadge = () => (
    <div className={classes.badge}>
      <Typography className={classes.badgeTextSmall}>{createSmallText()}</Typography>
      <Typography className={classes.badgeText}>{createText()}</Typography>
      <div className={classes.line} style={{ top: 2, left: 6, transform: 'rotate(-45deg)' }} />
      <div className={classes.line} style={{ top: '46%', left: 0, transform: 'rotate(90deg)' }} />
      <div className={classes.line} style={{ bottom: 0, left: 6, transform: 'rotate(45deg)' }} />
      <div className={classes.line} style={{ top: 2, right: 6, transform: 'rotate(45deg)' }} />
      <div className={classes.line} style={{ top: '46%', right: 0, transform: 'rotate(90deg)' }} />
      <div className={classes.line} style={{ bottom: 0, right: 6, transform: 'rotate(-45deg)' }} />
    </div>
  )

  return (
    <div className={classes.root} onClick={() => props.setScreen('Theater')}>
      <Typography className={classes.subTitle}>イベント開催中！！</Typography>
      <Typography className={classes.title}>映画de運命の出会いを</Typography>
      {createBadge()}
    </div>
  )
}

MovieEventBanner.propTypes = {
  movieEvent: PropTypes.object,
  setScreen: PropTypes.func,
}
