import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { addDate } from 'commons/utility'
import EventTitle from 'images/winter-event/event_title.png'
import PinkSnowLeftBottom from 'images/winter-event/pink_snow_left_bottom.png'
import PinkSnowLeftTop from 'images/winter-event/pink_snow_left_top.png'
import PinkSnowRightBottom from 'images/winter-event/pink_snow_right_bottom.png'
import PinkSnowRightTop from 'images/winter-event/pink_snow_right_top.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#ffffff',
    borderRadius: 12,
    boxShadow: '0px 1px 6px #00000029',
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2.5),
    backgroundImage: `url(${PinkSnowLeftTop}), url(${PinkSnowRightTop}), url(${PinkSnowLeftBottom}), url(${PinkSnowRightBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '36px 35px',
    backgroundPositionX: '3%, 97%, 3%, 97%',
    backgroundPositionY: '2%, 2%, 97%, 97%',
  },
  title: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(1.5),
  },
  text: {
    fontSize: 13,
    lineHeight: 1,
    color: '#C73C51',
    marginBottom: theme.spacing(1),
  },
  incentive: {
    width: 166,
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '37px',
    color: '#C73C51',
    marginBottom: theme.spacing(2),
    background: 'linear-gradient(transparent 65%, #FFDBE7 0)',
  },
  terms: {
    lineHeight: 1,
    color: '#C73C51',
  },
}))

export default function WinterEventBox (props) {
  const { mission } = props
  const classes = useStyles()

  if (!mission) { return null }

  // 期間
  const startAt = new Date(mission?.start_at || '2022-11-22T00:00:00.000+09:00')
  const endAt = addDate(new Date(mission.end_at), -1)

  return (
    <div className={classes.root}>
      <img className={classes.title} src={EventTitle} width={175} />
      <Typography className={classes.text} variant="subtitle1">
        「いいね！」送信・返信で
      </Typography>
      <Typography className={classes.incentive}>
        <span style={{
          width: 11,
          fontSize: 11,
          writingMode: 'vertical-rl',
          marginRight: -11,
        }}>最大</span>
        <span style={{ fontSize: 16 }}>+</span>
        10
        <span style={{ fontSize: 16 }}>名</span>
        ご紹介
      </Typography>
      <Typography className={classes.terms} variant="body1">
        {dateFormat(startAt, 'yyyy.mm.dd')}-{dateFormat(endAt, 'yyyy.mm.dd')}
      </Typography>
    </div>
  )
}

WinterEventBox.propTypes = {
  mission: PropTypes.object,
}
