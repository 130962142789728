import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LoadingImg from 'images/loading_friend_home.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: `calc(${window.innerHeight}px - ${theme.styles.header.height}px - ${theme.styles.footer.height}px)`,
    background: '#ffffff',
    zIndex: 3,
    position: 'fixed',
  },
  fadeOut: {
    animationName: '$fadeOut',
    animationDuration: '1s',
    animationTimingFunction : 'ease',
    animationFillMode: 'forwards',
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      visibility: 'visible',
    },
    '100%': {
      opacity: 0,
      visibility: 'hidden',
    }
  },
  loadingText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#E0E0E0',
    lineHeight: 1,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    '& span': {
      display: 'inline-block',
      transform: 'translateY(-40px)',
      animationName: '$falling',
      animationDuration: '0.6s',
      animationTimingFunction: 'ease',
      animationFillMode: 'forwards',
      // animationIterationCount: 'infinite',
    },
    '& span:nth-of-type(1)': {
      animationDelay: '0.0s',
    },
    '& span:nth-of-type(2)': {
      animationDelay: '0.2s',
    },
    '& span:nth-of-type(3)': {
      animationDelay: '0.4s',
    },
    '& span:nth-of-type(4)': {
      animationDelay: '0.6s',
    },
    '& span:nth-of-type(5)': {
      animationDelay: '0.8s',
    },
    '& span:nth-of-type(6)': {
      animationDelay: '1.0s',
    },
    '& span:nth-of-type(7)': {
      animationDelay: '1.2s',
    },
    '& span:nth-of-type(8)': {
      animationDelay: '1.4s',
    },
    '& span:nth-of-type(9)': {
      animationDelay: '1.6s',
    },
    '& span:nth-of-type(10)': {
      animationDelay: '1.8s',
    },
    '& span:nth-of-type(11)': {
      animationDelay: '2.0s',
    },
    '& span:nth-of-type(12)': {
      animationDelay: '2.2s',
    },
    '& span:nth-of-type(13)': {
      animationDelay: '2.4s',
    },
  },
  '@keyframes falling': {
    '0%': {
      transform: 'translateY(-40px)',
    },
    '100%': {
      transform: 'translateY(0)',
    }
  },
}))

export default function FriendHomeLoading (props) {
  const { loading } = props
  const classes = useStyles()

  return (
    <Grid
      className={`${classes.root} ${loading && classes.fadeOut}`}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src={LoadingImg} width={215} height={133} />
      <Typography className={classes.loadingText}>
        <span>紹</span>
        <span>介</span>
        <span>情</span>
        <span>報</span>
        <span>を</span>
        <span>読</span>
        <span>み</span>
        <span>込</span>
        <span>ん</span>
        <span>で</span>
        <span>い</span>
        <span>ま</span>
        <span>す</span>
      </Typography>
    </Grid>
  )
}

FriendHomeLoading.propTypes = {
  loading: PropTypes.bool
}
