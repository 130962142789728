import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Footer from '../entry/EntryFooter'
import Header from '../entry/EntryHeader'
import Content from './PartnerContent'
import Title from './PartnerTitle'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
  },
}))

export default function PartnerPage (props) {
  const { title, children, ...others } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Header {...others} />
        <Title>{title}</Title>
        <Content>{children}</Content>
      </div>
      <Footer />
    </div>
  )
}

PartnerPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
}
