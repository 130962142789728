import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Api from 'commons/api'
import BaseComponent from 'components/parts/BaseComponent'
import phoneIcon from 'images/foodreport/phone_icon.png'

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#616AFA',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  shopName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#707070',
  },
  shopFeatures: {
    fontSize: 10,
    color: '#707070',
    marginBottom: theme.spacing(0.5),
  },
  shopCategories: {
    fontSize: 12,
    color: '#707070',
    marginBottom: theme.spacing(0.5),
  },
  shopBudget: {
    fontSize: 12,
    color: '#707070',
    marginBottom: theme.spacing(0.5),
  },
  shopPhoneNumber: {
    fontSize: 16,
    color: '#707070',
    marginLeft: theme.spacing(1),
  },
  imageContainer: {
    height: 95,
  },
  image: {
    height: 95,
    width: 95,
  },
  button: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}))

export default function FoodReportShopTile (props) {
  const classes = useStyles()
  const { shopData } = props
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    if (shopData.url) {
      (async () => {
        try {
          // URLから写真取得
          const data = await Api.searchPhotoScrapers(shopData.scraping_url, 3)
          setPhotos(data)
        } catch (e) {
          BaseComponent.handleApiError(props, e)
        }
      })()
    }
  }, [shopData])

  const openShopSite = () => {
    if (!shopData.url) return
    window.open(shopData.url)
  }

  // ランチ、ディナーの価格帯
  const numberWithComma = new Intl.NumberFormat()
  const lunchBudgetMin = numberWithComma.format(shopData.lunch_budget_min) || ''
  const lunchBudgetMax = numberWithComma.format(shopData.lunch_budget_max) || ''
  const dinnerBudgetMin = numberWithComma.format(shopData.dinner_budget_min) || ''
  const dinnerBudgetMax = numberWithComma.format(shopData.dinner_budget_max) || ''
  // 1000円ギフト対象店ID(ルクア大阪)
  const gift1000 = [7716, 5061, 7717, 7718]
  // 2000円ギフト対象店ID(ホテルグランビア)
  const gift2000 = [1190, 7719]

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardContent>
          <div className={classes.shopName}>{shopData.shop_name}</div>
          <Grid className={classes.imageContainer} container justifyContent="space-between">
            {photos.map((photo, index) => {
              return (
                <img
                  key={index}
                  className={classes.image}
                  src={photo}
                  alt="画像"
                />
              )
            })}
          </Grid>
          <div className={classes.text}>
            {gift1000.includes(shopData.id) && '1,000'}
            {gift2000.includes(shopData.id) && '2,000'}
            円分のギフト券 対象店舗
          </div>
          <div className={classes.shopFeatures}>{shopData.features}</div>
          <div className={classes.shopCategories}>{shopData.categories}</div>
          <div className={classes.shopBudget}>
            昼：{lunchBudgetMin}〜{lunchBudgetMax}円&nbsp;&nbsp;/&nbsp;&nbsp;夜：{dinnerBudgetMin}〜{dinnerBudgetMax}円
          </div>
          <Grid container alignItems="center">
            <img
              src={phoneIcon}
            />
            <div className={classes.shopPhoneNumber}>{shopData.phone_number}</div>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          className={classes.button}
          variant="contained"
          onClick={openShopSite}
        >
          お店ホームページへ
        </Button>
      </CardActions>
    </Card>
  )
}

FoodReportShopTile.propTypes = {
  shopData: PropTypes.object,
}
