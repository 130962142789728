import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import FoodReportShopTile from 'components/foodreport/FoodReportShopTile'
import BgImg from 'images/img_secret_room.png'

const useStyles = makeStyles(theme => ({
  paper: {
    background: '#DBDCFE',
    padding: 20,
    borderRadius: '24px 24px 0 0',
    backgroundImage: `url(${BgImg})`,
  },
  backButton: {
    padding: 0,
    color: theme.palette.secondary.main,
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 12,
    color: '#1A1667',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '100%',
    borderTop: 'solid 1px #423BC7',
    marginBottom: theme.spacing(2),
  },
}))

export default function FoodReportShopListDialog (props) {
  const classes = useStyles()
  const { openShopListDialog, onPrev, onClose, shopList } = props

  return (
    <Dialog
      open={openShopListDialog}
      fullScreen
      PaperProps={{ className: classes.paper }}
    >
      <Grid container justifyContent="space-between">
        <IconButton
          className={classes.backButton}
          onClick={onPrev}
        >
          <LeftIcon />
        </IconButton>
        <IconButton
          className={classes.backButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container direction="column" alignContent="center">
        <div className={classes.title}>お店を予約する</div>
        <div className={classes.subtitle}>注意事項</div>
        <div className={classes.text}>
          1.イベント適用は対象の店舗に限ります<br />
          2.予約時に「Aillを見て予約した」と伝えるとスムーズです
        </div>
        <div className={classes.divider} />
        <div className={classes.subtitle}>イベント対象の店舗一覧</div>
        {shopList.map((data, index) => {
          return <FoodReportShopTile key={index} shopData={data} />
        })}
      </Grid>
    </Dialog>
  )
}

FoodReportShopListDialog.propTypes = {
  openShopListDialog: PropTypes.bool,
  onPrev: PropTypes.func,
  onClose: PropTypes.func,
  shopList: PropTypes.array,
}
