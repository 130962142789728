import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'

const styles = (theme) => ({
  longText: {
    textAlign: 'left'
  },
  consent: {
    fontSize: 14,
  },
  img: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(3.5),
  },
})

export class ConfirmDialog extends React.Component {
  constructor (props) {
    super(props)
    this.handleOk = this.handleOk.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleComfirm = this.handleComfirm.bind(this)
    this.handleConsent = this.handleConsent.bind(this)

    this.state = {
      checkeConsent: false,
      isDisabled: true,
    }
  }

  handleOk () {
    this.props.onOk()
  }

  handleCancel () {
    this.props.onCancel()
  }

  handleComfirm () {
    this.props.onClose()
  }

  handleConsent (event) {
    this.setState({
      checkeConsent: event.target.checked,
      isDisabled: !event.target.checked
    })
  }

  // URLはa要素、それ以外は React.Fragment 要素に分割して配列で返す
  // TODO CommonSpeech と共通化したい
  prepareLinks = (text) => {
    if ((typeof text) !== 'string') { return text }

    const urlRegexp = /https?:\/\/\S+/g
    const urls = text.match(urlRegexp)
    if (!urls) { return text }

    const textElems = []
    let currentIndex = 0
    urls.forEach(url => {
      const urlIndex = text.indexOf(url)
      const beforeText = text.substring(currentIndex, urlIndex)
      if (0 < beforeText.length) {
        textElems.push(<React.Fragment key="before">{beforeText}</React.Fragment>)
      }
      textElems.push(
        <a key="url" href={url} target="_blank" rel="noreferrer" style={{ color: '#423BC7', wordBreak: 'break-all' }}>
          {url}
        </a>
      )
      currentIndex = urlIndex + url.length
    })
    if (currentIndex < text.length) {
      const afterText = text.substring(currentIndex, text.length)
      if (0 < afterText.length) {
        textElems.push(<React.Fragment key="after">{afterText}</React.Fragment>)
      }
    }
    return textElems
  }

  render () {
    const {
      classes, open, title, message,
      messageLeft, imageUrl, review, consent, confirm,
      tutorial, close, question,
      toAppStore, toGooglePlay,
    } = this.props
    const messageElems = this.prepareLinks(message)
    return (
      <DialogThemeProvider color="default">
        <Dialog open={open}>
          {review && <DialogCloseButton onClick={this.handleCancel} />}
          <DialogTitle disableTypography>
            {title}
            {consent && (
              <span className={classes.consent}><br/>※一番下の同意するにチェックをしてください。</span>
            )}
          </DialogTitle>
          <DialogContent>
            <Typography className={`${messageLeft && classes.longText}`} variant="body1" component="div">
              {messageElems}
            </Typography>
            {consent && (
              <Grid container alignItems="center" justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkeConsent}
                      onChange={this.handleConsent}
                      style={{ color: '#423BC7' }}
                    />
                  }
                  label="同意する"
                  style={{ fontSize: 14 }}
                />
              </Grid>
            )}
            {imageUrl && (
              <img className={classes.img} src={imageUrl} width={216} />
            )}
          </DialogContent>
          <DialogActions disableSpacing>
            {consent && (
              <FormGroup>
                <Button
                  variant="contained"
                  onClick={this.handleComfirm}
                  disabled={this.state.isDisabled}
                >
                  次へ
                </Button>
              </FormGroup>
            )}
            {confirm && (
              <Button variant="contained" onClick={this.handleComfirm}>
                確認
              </Button>
            )}
            {tutorial && (
              <Button variant="contained" onClick={this.handleComfirm}>
                閉じる
              </Button>
            )}
            {close && (
              <Button variant="contained" onClick={this.handleComfirm}>
                閉じる
              </Button>
            )}
            {question && (
              <>
                <Button variant="contained" onClick={this.handleOk}>
                  OK
                </Button>
                <Button variant="contained" onClick={this.handleCancel}>
                  キャンセル
                </Button>
              </>
            )}
            {review && (
              <>
                <Button variant="contained" onClick={toAppStore}>
                  App Store
                </Button>
                <Button variant="contained" onClick={toGooglePlay}>
                  Google Play
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

ConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  tutorial: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  consent: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  close: PropTypes.string,
  question: PropTypes.string,
  confirm: PropTypes.string,
  review: PropTypes.bool,
  toAppStore: PropTypes.func,
  toGooglePlay: PropTypes.func,
  messageLeft: PropTypes.bool,
  imageUrl: PropTypes.string,
}

export default withStyles(styles)(ConfirmDialog)