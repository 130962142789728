import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Currency from 'commons/currency'
import { addDate, formatPrevJapaneseDate } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'

// GOEN-594 Windowsに DIN Alternate が入っていない可能性があるため一時対応
// TODO theme/index.js の typography に指定している値をベースとして共用できるようにする。
// 但し、ここに関しては游ゴシックの方がマシであるため Noto Sans JP を下げておく。
const fontFamily = [
  '"DIN Alternate"',
  '"游ゴシック体"',
  '"YuGothic"',
  '"游ゴシック Medium"',
  '"Yu Gothic Medium"',
  '"Noto Sans Japanese"',
  '"sans-seri"',
].join(',')

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 674,
    border: '1px solid #423BC7',
    margin: 'auto',
  },
  header: {
    background: '#423BC7',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: sp => sp ? 13 : 22,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: sp => sp ? '18px' : '27px',
    letterSpacing: sp => sp ? '1.8px' : '2.7px',
    textAlign: 'center',
    textStroke: '3px #ffffff',
    '-webkit-text-stroke': '3px #ffffff',
    position: 'relative',
    '& span': {
      textStroke: 0,
      '-webkit-text-stroke': 0,
      position: 'absolute',
    },
    whiteSpace: 'pre-wrap',
  },
  body: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(1.5),
    paddingBottom: sp => theme.spacing(sp ? 2 : 5),
    paddingLeft: theme.spacing(1.5),
  },
  box: {
    maxWidth: sp => sp ? 288 : 464,
    background: '#DBDCFE',
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    margin: 'auto',
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  label: {
    fontSize: sp => sp ? 12 : 17,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.5px' : '1.7px',
    background: '#FFFFC6',
    paddingTop: theme.spacing(0.75),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    position: 'absolute',
    top: 12,
    left: -16,
    transform: 'rotate(-14deg)',
    minWidth: sp => sp ? 57 : 77,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  subtitle: {
    whiteSpace: 'pre-wrap',
    marginBottom: sp => theme.spacing(sp ? 0.75 : 1.25),
    position: 'relative',
    zIndex: 1,
  },
  text: {
    width: 'fit-content',
    fontSize: sp => sp ? 13 : 15,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: '22px',
    letterSpacing: sp => sp ? '1.3px' : '1.5px',
    margin: 'auto',
  },
  aboutPriceText: {
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    position: 'relative',
    '& span': {
      fontWeight: 600,
      display: 'inline-block',
    },
    '& span:nth-of-type(1)': {
      fontSize: sp => sp ? 9 : 12,
      color: '#423BC7',
      lineHeight: 1.2,
      letterSpacing: sp => sp ? '0.9px' : '1.2px',
      textAlign: sp => sp ? 'right' : 'center',
      position: 'absolute',
      top: '50%',
      left: -17,
      transform: 'translate(-100%, -50%)',
    },
    '& span:nth-of-type(3)': {
      fontSize: sp => sp ? 13 : 20,
      color: '#423BC7',
      lineHeight: 1,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
    },
    '& span:nth-of-type(4)': {
      fontSize: sp => sp ? 19 : 32,
      fontFamily: fontFamily,
      color: '#423BC7',
      lineHeight: 1,
    },
  },
  aboutPriceDetail: {
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    textAlign: 'center',
    '& span': {
      display: 'inline-block',
    },
    '& span:nth-of-type(1)': {
      fontSize: sp => sp ? 9 : 8,
      marginRight: theme.spacing(0.75),
    },
    '& span:nth-of-type(2)': {
      fontSize: sp => sp ? 19 : 25,
      fontFamily: fontFamily,
      marginRight: theme.spacing(0.25),
    },
    '& span:nth-of-type(3)': {
      fontSize: sp => sp ? 13 : 14,
    },
  },
  aboutPriceSubText: {
    fontSize: sp => sp ? 8 : 12,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1.2,
    textAlign: sp => sp ? 'right' : 'center',
    position: 'absolute',
    top: '50%',
    left: -14,
    transform: 'translate(-100%, -50%)',
  },
  aboutCouponDetail: {
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    textAlign: 'center',
    position: 'relative',
    whiteSpace: 'nowrap',
    margin: 'auto',
    marginBottom: theme.spacing(1),
    '& span': {
      display: 'inline-block',
    },
    '& span:nth-of-type(1)': {
      fontSize: sp => sp ? 25 : 35,
      fontFamily: fontFamily,
      color: '#ffffff',
    },
    '& span:nth-of-type(2)': {
      fontSize: sp => sp ? 15 : 20,
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    '& span:nth-of-type(3)': {
      fontSize: sp => sp ? 22 : 32,
      fontFamily: fontFamily,
    },
  },
  miniText: {
    fontSize: sp => sp ? 8 : 9,
    fontWeight: 600,
    color: '#423BC7',
    letterSpacing: sp => sp ? '0.8px' : '0.9px',
  },
  miniLabel: {
    position: 'absolute',
    top: '50%',
    left: sp => sp ? -40 : -52,
    transform: 'translate(0, -50%)',
  },
  value: {
    width: 'fit-content',
    fontSize: sp => sp ? 28 : 35,
    fontWeight: 600,
    fontFamily: fontFamily,
    color: '#ffffff',
    lineHeight: 1,
    letterSpacing: sp => sp ? '2.8px' : '3.5px',
  },
  day: {
    width: 'fit-content',
    fontSize: sp => sp ? 26 : 30,
    fontWeight: 600,
    fontFamily: fontFamily,
    color: '#ffffff',
    lineHeight: 1,
    letterSpacing: sp => sp ? '2.6px' : '3.0px',
    textAlign: 'center',
  },
  term: {
    width: 'fit-content',
    fontSize: sp => sp ? 11 : 20,
    fontWeight: 600,
    color: '#ffffff',
    lineHeight: sp => sp ? 1.5 : 1,
    letterSpacing: sp => sp ? '1.1px' : '2.0px',
  },
  textShadow: {
    textShadow: '3px 1px 0 #423BC7, -1px 1px 0 #423BC7, 1px -1px 0 #423BC7, -1px -1px 0 #423BC7',
  },
  outline: {
    border: '2px solid #B0B4FC',
    paddingTop: sp => theme.spacing(sp ? 2 : 5.5),
    paddingBottom: sp => theme.spacing(sp ? 0.25 : 1.75),
    marginTop: sp => theme.spacing(sp ? 3.25 : 4),
    marginRight: sp => theme.spacing(sp ? 0 : 6),
    marginBottom: sp => theme.spacing(sp ? 1.5 : 4),
    marginLeft: sp => theme.spacing(sp ? 0 : 6),
    position: 'relative',
  },
  borderBottom: {
    borderBottom: '4px double #423BC7',
  },
  note: {
    maxWidth: 463,
    fontSize: sp => sp ? 8 : 9,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: '16px',
    letterSpacing: sp => sp ? '0.8px' : '0.9px',
    margin: 'auto',
  },
  arrow: {
    width: 'fit-content',
    marginRight: sp => theme.spacing(sp ? 0.75 : 1.5),
    marginLeft: sp => theme.spacing(sp ? 1.25 : 2.5),
  },
  arrowColumn: {
    marginTop: sp => theme.spacing(sp ? 1.5 : 0),
    marginBottom: sp => theme.spacing(sp ? 0.75 : 0),
    transform: sp => sp ? 'rotate(90deg)' : '',
  },
  bar: {
    width: sp => sp ? 12 : 20,
    height: sp => sp ? 8 : 14,
    background: '#423BC7',
    marginRight: -1,
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderTop: sp => `${sp ? 10 : 15}px solid transparent`,
    borderBottom: sp => `${sp ? 10 : 15}px solid transparent`,
    borderLeft: sp => `${sp ? 15 : 21}px solid #423BC7`,
    borderRight: 0,
  },
  plus: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: sp => sp ? 10 : 20,
      height: sp => sp ? 30 : 60,
      background: '#423BC7',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: sp => sp ? 10 : 20,
      height: sp => sp ? 30 : 60,
      background: '#423BC7',
      transform: 'rotate(90deg)',
    },
  },
  plusPosition: {
    position: 'absolute',
    top: sp => sp ? -16 : -31,
    left: '50%',
    transform: sp => `translate(${sp ? -2.5 : -7.5}px, 0px)`
  },
  fitContent: {
    width: 'fit-content'
  },
}))

const sellingPrice = 6600

export default function EntryCampaignBox (props) {
  const { organization, freeTerm, campMaster } = props
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)
  const [monthlyPrice, setMonthlyPrice] = useState(null)
  const [incentivePrice, setIncentivePrice] = useState(null)

  if (!organization) { return null }

  useEffect(() => {
    (async () => {
      try {
        const monthly = await BaseComponent.getMonthlyPrice(props, organization.id)
        setMonthlyPrice(monthly)
        const incentive = await BaseComponent.getIncentivePrice(props, campMaster)
        setIncentivePrice(Currency.withTax(incentive))
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    })()
  }, [campMaster])

  const createTitle = (text) => (
    <Typography className={classes.title}>
      <span>{text}</span>{text}
    </Typography>
  )

  const createSubtitle = (text) => (
    <Typography className={`${classes.text} ${classes.borderBottom} ${classes.subtitle}`}>{text}</Typography>
  )

  const createLabel = (text) => (
    <Typography className={classes.label}>{text}</Typography>
  )

  const createArrow = (className = null) => (
    <Grid className={`${classes.arrow} ${className}`} container wrap="nowrap" justifyContent="center" alignItems="center">
      <div className={classes.bar} />
      <div className={classes.triangle} />
    </Grid>
  )

  const createAboutPrice = () => {
    const price = organization.full_paid_enabled ? 0 : monthlyPrice
    if (!Number.isInteger(price)) { return null }
    if (price < 4000) {
      const endDate = formatPrevJapaneseDate(organization?.full_paid_end_date)
      return (
        <div className={classes.box} data-testid="about-price">
          {createLabel('特典')}
          {createSubtitle(`${endDate ? `${endDate}まで、` : ''}特別な利用料金`)}
          <Grid container wrap="nowrap" direction={spMatches ? 'column' : 'row'} justifyContent="center" alignItems="center" style={{ marginBottom: 12 }}>
            <Typography className={classes.fitContent}>
              <span className={classes.text} style={{ fontSize: 8, lineHeight: 1.5, marginRight: 6, display: 'inline-block' }}>
                販売価格<br />（税込）
              </span>
              <span className={`${classes.borderBottom} ${classes.textShadow}`}>
                <span className={classes.value}>{Currency.format(sellingPrice)}</span>
                <span className={classes.term} style={{ marginLeft: 6 }}>円</span>
              </span>
            </Typography>
            {createArrow(classes.arrowColumn)}
            <Typography className={classes.fitContent}>
              <span className={classes.text} style={{ fontSize: 8, lineHeight: 1.5, marginRight: 6, display: 'inline-block' }}>
                月額料金<br />（税込）
              </span>
              <span className={`${classes.borderBottom} ${classes.textShadow}`}>
                <span className={classes.value}>{Currency.format(price)}</span>
                <span className={classes.term} style={{ marginLeft: 6 }}>円</span>
              </span>
            </Typography>
          </Grid>
          {endDate && (
            <Typography className={classes.miniText} style={{ fontSize: 9, lineHeight: 1, textAlign: 'center', marginBottom: 10 }}>
              期間終了後のご利用料金は月額{Currency.format(monthlyPrice)}円（税込）を予定しております
            </Typography>
          )}
        </div>
      )
    } else {
      return (
        <div className={classes.box} data-testid="about-price">
          {createLabel('特典')}
          {createSubtitle('お得な利用料金')}
          <Typography className={`${classes.aboutPriceText} ${classes.borderBottom} ${classes.fitContent}`}>
            <span>月額利用料<br />毎月</span>
            <span className={`${classes.value} ${classes.textShadow}`}>{Currency.format(sellingPrice - price)}</span>
            <span style={{ fontSize: spMatches ? 13 : 20, lineHeight: 1, marginRight: 8, marginLeft: 4 }}>円</span>
            <span style={{ fontSize: spMatches ? 19 : 32, lineHeight: 1, fontFamily: fontFamily }}>OFF</span>
          </Typography>
          <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
            <Typography className={classes.aboutPriceDetail}>
              <span>販売価格<br />（税込）</span>
              <span>{Currency.format(sellingPrice)}</span>
              <span>円</span>
            </Typography>
            {createArrow()}
            <Typography className={classes.aboutPriceDetail}>
              <span>月額料金<br />（税込）</span>
              <span>{Currency.format(price)}</span>
              <span>円</span>
            </Typography>
          </Grid>
        </div>
      )
    }
  }

  const createAboutFreeTerm = () => {
    if (organization.full_paid_enabled) { return null }
    return (
      <>
        {freeTerm && (
          <Typography className={`${classes.text} ${!spMatches && classes.borderBottom}`} style={{ marginBottom: spMatches ? 4 : 12 }}>
            {dateFormat(addDate(freeTerm.entry_end_at, -1), 'yyyy年m月d日までのご登録で')}
          </Typography>
        )}
        <div className={classes.box} data-testid="about-free-term">
          {extend && createLabel('更に！')}
          {createSubtitle(`お試し無料期間${extend ? 'の延長!!' : 'あり！'}`)}
          <Grid className={classes.fitContent} container wrap="nowrap" justifyContent="center" alignItems="center" style={{ position: 'relative', margin: 'auto' }}>
            {extend ? (
              <>
                <span className={`${classes.miniText} ${classes.miniLabel}`}>通常期間</span>
                <Typography className={`${classes.borderBottom} ${classes.textShadow}`}>
                  <span className={classes.day}>{defaultFreeDays}</span>
                  <span className={classes.term}>日</span>
                </Typography>
                {createArrow()}
                {freeEndAt ? (
                  <Typography className={`${classes.borderBottom} ${classes.textShadow}`}>
                    <span className={classes.day}>{dateFormat(freeEndAt, 'yyyy')}</span>
                    <span className={classes.term}>年</span>
                    <span className={classes.day}>{dateFormat(freeEndAt, 'm')}</span>
                    <span className={classes.term}>月</span>
                    <span className={classes.day}>{dateFormat(freeEndAt, 'd')}</span>
                    <span className={classes.term}>日</span>
                    <span className={classes.term}>まで</span>
                  </Typography>
                ) : (
                  <Typography className={`${classes.borderBottom} ${classes.textShadow}`}>
                    <span className={classes.day}>{freeDays}</span>
                    <span className={classes.term}>日</span>
                  </Typography>
                )}
              </>
            ) : (
              <>
                <span className={classes.miniText} style={{ marginRight: 19, textAlign: 'right' }}>
                  スタンダードプラン<br />
                  無料体験
                </span>
                <Typography className={`${classes.borderBottom} ${classes.textShadow}`}>
                  <span className={classes.day}>{defaultFreeDays}</span>
                  <span className={classes.term}>日</span>
                </Typography>
              </>
            )}
          </Grid>
        </div>
      </>
    )
  }

  const createAboutCoupon = () => {
    if (!campMaster) { return null }
    if (!incentivePrice) { return null }
    const incentiveLimit = campMaster?.display_org_incentive_limit
    if (organization.full_paid_enabled) { return null }
    return (
      <div className={classes.box} data-testid="about-coupon">
        <Typography className={classes.label} style={{ fontSize: 11, lineHeight: 1.3, paddingTop: 2, paddingBottom: 2 }}>
          {incentiveLimit > 0 ? '企業様\n補助で！' : '今だけ'}
        </Typography>
        {createSubtitle(incentiveLimit > 0 ? `本登録完了の先着${incentiveLimit}名様限定！` : 'クーポン利用でさらにお得に！')}
        <Typography className={`${classes.aboutCouponDetail} ${classes.borderBottom} ${classes.fitContent}`}>
          <span className={classes.textShadow}>{Currency.format(incentivePrice)}</span>
          <span>円</span>
          <span>OFF</span>
        </Typography>
        <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
          <Typography className={classes.aboutPriceDetail}>
            <span>月額料金<br />（税込）</span>
            <span>{Currency.format(monthlyPrice)}</span>
            <span>円</span>
          </Typography>
          {createArrow()}
          <Typography className={classes.aboutPriceDetail}>
            <span>月額料金 <br />（税込）</span>
            <span>{Currency.format(Math.max(monthlyPrice - incentivePrice, 0))}</span>
            <span>円</span>
          </Typography>
        </Grid>
        <Typography className={classes.miniText} style={{ marginTop: 8, marginRight: 12, marginLeft: 12, textAlign: 'center' }}>
          クーポンは入会後にメンバーズサイトよりご利用いただけます。<br />
          クーポンは1回限り有効です。
        </Typography>
      </div>
    )
  }

  const createEventInfo = () => {
    if (!organization.full_paid_enabled) { return null }
    if (extend) { return null }
    if (createAboutCoupon()?.type) { return null }
    return (
      <>
        <Typography className={`${classes.text} ${!spMatches && classes.borderBottom}`} style={{ marginBottom: spMatches ? 4 : 12 }}>
          アプリ内のイベント達成で
        </Typography>
        <div className={classes.box} data-testid="about-event-info">
          {createLabel('更に！')}
          {createSubtitle(<>初回の紹介人数<span style={{ fontSize: 22 }}>2</span><span style={{ fontSize: 18 }}>倍</span>!!</>)}
          <Grid container wrap="nowrap" justifyContent="center" alignItems="center" style={{ marginBottom: 12 }}>
            <Typography className={classes.fitContent}>
              <span className={classes.text} style={{ fontSize: 8, lineHeight: 1.5, marginRight: 6, display: 'inline-block' }}>通常</span>
              <span className={`${classes.borderBottom} ${classes.textShadow}`}>
                <span className={classes.value}>5</span>
                <span className={classes.term} style={{ marginLeft: 6 }}>人</span>
              </span>
            </Typography>
            {createArrow()}
            <Typography className={`${classes.fitContent} ${classes.borderBottom} ${classes.textShadow}`}>
              <span className={classes.value}>10</span>
              <span className={classes.term} style={{ marginLeft: 6 }}>人</span>
            </Typography>
          </Grid>
          <Typography className={classes.miniText} style={{ fontSize: 9, lineHeight: 1, textAlign: 'center' }}>
            初回紹介5名＋イベント達成特典5名
          </Typography>
          <Typography className={classes.miniText} style={{ lineHeight: 2, textAlign: 'center' }}>
            ＊一部地域を除く
          </Typography>
        </div>
      </>
    )
  }

  const defaultFreeDays = 7
  const freeDays = freeTerm?.free_days || defaultFreeDays
  const freeEndAt = freeTerm?.free_end_at
  // 延長有効
  const extend = defaultFreeDays !== freeDays || freeEndAt
  // 青枠(+マーク)表示判定
  const puls = extend || createAboutCoupon()?.type || createEventInfo()?.type

  return (
    <div className={classes.root} data-testid="entry-campaign-box">
      <Grid className={classes.header} container justifyContent="center" alignItems="center">
        {createTitle(`${organization.canonical_name}様\n限定プラン`)}
      </Grid>
      <div className={classes.body}>
        {createAboutPrice()}
        {!extend && createAboutFreeTerm()}
        {puls && (
          <div className={classes.outline}>
            <div className={classes.plusPosition}><div className={classes.plus} /></div>
            {extend && createAboutFreeTerm()}
            {createAboutCoupon()}
            {createEventInfo()}
          </div>
        )}
        <Typography className={classes.note}>
          無料トライアル期間終了日の翌日午前7時までにフリープランに変更いただいた場合はご利用料金が発生しません。<br />
          ご利用料金は無料トライアル期間終了日の翌日午前8時のお支払いとなります。<br />
          無料トライアル期間終了後は選択されているご利用プランの自動更新となります。<br />
          ご利用プランは入会後にメンバーズサイトより変更いただけます。<br />
          一部地域ではベーシックプランのみの提供になります。
        </Typography>
      </div>
    </div>
  )
}

EntryCampaignBox.propTypes = {
  organization: PropTypes.object,
  freeTerm: PropTypes.object,
  campMaster: PropTypes.object,
}
