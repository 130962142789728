import React, { useState } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import BaseComponent from 'components/parts/BaseComponent'
import * as Offer from 'components/special-offer/index'
import PurchaseConfirmDialog from 'components/special-offer/PurchaseConfirmDialog'
import PurchaseFinishDialog from 'components/special-offer/PurchaseFinishDialog'
import Label from 'images/special-offer/label_yellow.png'

const useStyles = makeStyles(theme => {
  const soTheme = Offer.theme()
  return {
    planContainer: {
      width: '100%',
      maxWidth: theme.spacing(80),
      background: soTheme.subColor,
      whiteSpace: 'pre-wrap',
      margin: 'auto',
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(6),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
      position: 'relative',
    },
    planTitle: {
      fontSize: 24,
      fontWeight: 700,
      color: soTheme.mainColor,
      marginBottom: theme.spacing(3),
      '& span': {
        fontSize: 12,
        display: 'block',
      },
    },
    planText: {
      fontSize: 14,
      color: soTheme.valueColor,
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    endAt: {
      fontSize: 14,
      fontWeight: 700,
      color: soTheme.mainColor,
      lineHeight: 1,
      marginBottom: theme.spacing(3),
    },
    label: {
      height: 49,
      width: 'calc(100% + 32px)',
      maxWidth: 562,
      position: 'relative',
      backgroundImage: `url(${Label})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 49px',
      backgroundPosition: 'center',
      marginBottom: theme.spacing(-1.5),
      zIndex: 1,
      '& p': {
        fontSize: 18,
        fontWeight: 600,
        color: soTheme.mainColor,
        lineHeight: 1,
        letterSpacing: '2.12px',
        position: 'absolute',
        top: 12,
        left: '50%',
        transform: 'translate(-50%, 0%)',
      },
    },
    item: {
      width: '100%',
      maxWidth: 506,
      background: '#FFFFFF',
      borderRadius: theme.spacing(1.25),
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
      position: 'relative',
    },
    num: {
      fontSize: 14,
      fontWeight: 600,
      color: soTheme.mainColor,
      lineHeight: '21px',
      letterSpacing: '0.28px',
      width: 60,
      height: 60,
      background: soTheme.subColor,
      whiteSpace: 'pre',
      borderRadius: 5,
    },
    value: {
      fontSize: 14,
      fontWeight: 300,
      color: soTheme.valueColor,
      lineHeight: 1,
      marginLeft: theme.spacing(3),
      '& span': {
        fontSize: 24,
        fontWeight: 600,
      },
    },
    buttons: {
      marginTop: theme.spacing(0.5),
    },
    button: {
      width: 200,
      height: 36,
      fontSize: 13,
      fontWeight: 600,
      color: '#ffffff',
      background: soTheme.mainColor,
      marginTop: theme.spacing(1.5),
      display: 'block',
      position: 'relative',
      '&:hover': {
        background: soTheme.mainColor,
        '&:after': {
          content: '""',
          width: 200,
          height: 36,
          background: 'rgba(65,65,65,0.4)',
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: 20,
        },
      },
    },
    attentionText: {
      fontSize: 13,
      color: '#707070',
      width: '100%',
      textAlign: 'left',
      marginTop: theme.spacing(3),
    },
  }
})

export default function AdditionalMatching (props) {
  const { offer } = props
  const classes = useStyles()
  const [openPurchaseConfirmDialog, setOpenPurchaseConfirmDialog] = useState(false)
  const [purchaseCount, setPurchaseCount] = useState(null)
  const [openPurchaseFinishDialog, setOpenPurchaseFinishDialog] = useState(false)
  const [incentiveCount, setIncentiveCount] = useState(0)

  const disable = () => {
    return offer.max_count <= offer.purchased_count
  }

  const onConfirmOK = async () => {
    props.setLoading(true)
    try {
      setOpenPurchaseConfirmDialog(false)
      const params = {
        special_offer_id: offer.id,
        purchase_count: purchaseCount,
      }
      const res = await Api.submitTicket(params)
      console.log('res: ', res)
      let count = 0
      res.map(r => count += r.incentive_param)
      setIncentiveCount(count)
      setOpenPurchaseFinishDialog(true)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <Box className={classes.planContainer} sx={{ px: { xs: 1, sm: 8.375 } }}>
        <div className={classes.planTitle}>
          <span>スペシャルオファー</span>
          追加紹介
        </div>
        <div className={classes.planText}>
          現在ご利用中のプランのご紹介に加え<br />
          スポットで追加の紹介を受けられる<br />
          オプションです。
        </div>
        <Typography className={classes.endAt}>{dateFormat(offer.end_at, 'yyyy年mm月dd日 HH:MM')} まで</Typography>
        <div className={classes.label}>
          <Typography>残り{offer.max_count - offer.purchased_count}回</Typography>
        </div>
        <Grid className={classes.item} container justifyContent="space-between" alignItems="center">
          <Grid className={classes.num} container justifyContent="center" alignItems="center">
            3人<br />紹介
          </Grid>
          <div className={classes.value}>
            1回<span>&nbsp;&nbsp;{offer.price}</span>円（税込）
          </div>
          <Box className={classes.buttons} sx={{ mx: { xs: 'auto', md: 0 } }}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={disable()}
              onClick={() => {
                setPurchaseCount(1)
                setOpenPurchaseConfirmDialog(true)
              }}
            >
              1回 購入する
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={disable()}
              onClick={() => {
                setPurchaseCount(offer.max_count - offer.purchased_count)
                setOpenPurchaseConfirmDialog(true)
              }}
            >
              まとめて購入する
            </Button>
          </Box>
        </Grid>
        <div className={classes.attentionText}>
          ＊購入後に紹介異性が退会された場合は、通常紹介時と同様に一部条件を除き、追加の紹介はございません。詳しくはアプリ内Q&Aをご確認ください。
        </div>
      </Box>
      <PurchaseConfirmDialog
        open={openPurchaseConfirmDialog}
        offer={offer}
        count={purchaseCount}
        onConfirmOK={onConfirmOK}
        onClose={() => setOpenPurchaseConfirmDialog(false)}
      />
      <PurchaseFinishDialog
        open={openPurchaseFinishDialog}
        offer={offer}
        num={incentiveCount}
        onClose={() => window.location.reload()}
      />
    </>
  )
}

AdditionalMatching.propTypes = {
  offer: PropTypes.object,
  setLoading: PropTypes.func,
}
