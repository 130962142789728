import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { copyToClipboard } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import XmasCampaignBox from 'components/xmas-campaign/XmasCampaignBox'
import CheckIcon from 'images/xmas-campaign/check_icon.png'
import CopyIcon from 'images/xmas-campaign/copy_icon.png'
import Komainu from 'images/xmas-campaign/komainu.png'
import WhiteSnowLeftBottom from 'images/xmas-campaign/white_snows_left_bottom.png'
import WhiteSnowLeftTop from 'images/xmas-campaign/white_snows_left_top.png'
import WhiteSnowRightBottom from 'images/xmas-campaign/white_snows_right_bottom.png'
import WhiteSnowRightTop from 'images/xmas-campaign/white_snows_right_top.png'

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 268,
    minHeight: 156,
    backgroundImage: `url(${WhiteSnowLeftTop}), url(${WhiteSnowRightTop}), url(${WhiteSnowLeftBottom}), url(${WhiteSnowRightBottom})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '68px 103px',
    backgroundPositionX: '0%, 100%, 0%, 100%',
    backgroundPositionY: '0%, 0%, 67%, 67%',
    paddingRight: theme.spacing(3.75),
    paddingLeft: theme.spacing(3.75),
  },
  title: {
    fontSize: 16,
    lineHeight: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  text: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  img: {
    display: 'block',
    margin: 'auto',
  },
  content: {
    padding: 0,
  },
  actions: {
    paddingTop: theme.spacing(0.5),
  },
  button: {
    width: 268,
  },
}))

export default function AnnounceDialog (props) {
  const { open, onClose, type, code } = props
  const classes = useStyles()
  const [copied, setCopied] = useState(false)

  if (!type) { return null }

  const copy = async () => {
    if (!code) { return }
    copyToClipboard(code.toUpperCase())
    await setCopied(true)
    setTimeout(() => { setCopied(false) }, 1500)
  }

  return (
    <DialogThemeProvider color="winter-event">
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle className={classes.title} disableTypography>
          キャンペーンのお知らせ
        </DialogTitle>
        <DialogContent className={classes.content}>
          <XmasCampaignBox type={type} />
          <Grid container wrap='nowrap' justifyContent='center' alignItems='center'>
            <div style={{
              width: 38,
              height: 2,
              background: '#ffffff',
              transform: 'rotate(60deg)',
              marginTop: 16,
            }}
            />
            <Typography className={classes.text} variant="body1">
              {type === 'xmas_for_free' ? 'メンバーズサイトに\nクーポンを登録してね！' : 'ボクたちからの\nクリスマスプレゼントだよ！'}
            </Typography>
            <div style={{
              width: 38,
              height: 2,
              background: '#ffffff',
              transform: 'rotate(-60deg)',
              marginTop: 16,
            }} />
          </Grid>
          <img className={classes.img} src={Komainu} width={246} />
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          {type === 'xmas_for_free' && (
            <Button className={classes.button} variant="contained" onClick={copy}>
              {copied ? 'コピーしました' : 'クーポンコードをコピー'}
              <img src={copied ? CheckIcon : CopyIcon} width={27} height={27} alt="" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

AnnounceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  code: PropTypes.string,
}
