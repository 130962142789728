import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeWidth: 1.3,
    strokeLinejoin: 'round',
  },
  cls2: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeWidth: 1.3,
    strokeMiterlimit: 10,
  },
  cls3: {
    fill: '#9d9d9e',
  },
  cls4: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeWidth: 1.3,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  },
}

class FemaleOffIcon extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <SvgIcon viewBox="0 0 20.75 18.802">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20.75" height="18.802" viewBox="0 0 20.75 18.802">
          <g id="グループ_384" data-name="グループ 384" transform="translate(0.65 0.65)">
            <path id="パス_162" data-name="パス 162" className={classes.cls1} d="M16.589,239.591a5.471,5.471,0,0,1-1.247,1.766,7.417,7.417,0,0,1-3.749,1.839,7.419,7.419,0,0,1,1.839-3.749,5.658,5.658,0,0,1,2.058-1.373" transform="translate(-11.594 -225.693)"/>
            <circle id="楕円形_29" data-name="楕円形 29" className={classes.cls2} cx="8.304" cy="8.304" r="8.304" transform="translate(2.829)"/>
            <circle id="楕円形_30" data-name="楕円形 30" className={classes.cls3} cx="0.843" cy="0.843" r="0.843" transform="translate(13.473 8.194)"/>
            <circle id="楕円形_31" data-name="楕円形 31" className={classes.cls3} cx="0.843" cy="0.843" r="0.843" transform="translate(7.058 8.194)"/>
            <path id="パス_163" data-name="パス 163" className={classes.cls4} d="M24.671,237.157a3.556,3.556,0,0,0,2.825,1.316,3.466,3.466,0,0,0,2.825-1.316" transform="translate(-16.363 -225.359)"/>
            <path id="パス_164" data-name="パス 164" className={classes.cls4} d="M16.047,228.2a10.247,10.247,0,0,0,5.678-1.832,9.97,9.97,0,0,0,2.626-2.747" transform="translate(-13.218 -220.422)"/>
            <path id="パス_165" data-name="パス 165" className={classes.cls4} d="M37.421,228.2a10.247,10.247,0,0,1-5.678-1.832,9.97,9.97,0,0,1-2.626-2.747" transform="translate(-17.984 -220.422)"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

FemaleOffIcon.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(FemaleOffIcon)