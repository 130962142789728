import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Config from 'commons/config'
import logoImage from 'images/logo_aill_2021_negative.png'
import InstagramImg from 'images/sns-icon/instagram_icon.png'
import LineImg from 'images/sns-icon/line_icon.png'
import TwitterImg from 'images/sns-icon/twitter_icon.png'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    minHeight: 434,
    color: '#ffffff',
    background: '#060179',
    paddingTop: sp => theme.spacing(sp ? 3 : 5),
    paddingBottom: theme.spacing(3),
    paddingLeft: sp => theme.spacing(sp ? 4.5 : 21),
  },
  logoLink: {
    width: 'fit-content',
    display: 'inline-flex',
    marginBottom: sp => theme.spacing(sp ? 2 : 4.5),
    cursor: 'pointer',
  },
  links: {
    width: 'fit-content',
    marginRight: sp => theme.spacing(sp ? 0 : 10),
    '& div:nth-of-type(1)': {
      marginBottom: sp => theme.spacing(sp ? 8 : 12),
      marginRight: sp => theme.spacing(sp ? 8 : 21.75),
    },
    '& a': {
      marginBottom: theme.spacing(2.5),
    },
  },
  link: {
    width: 'fit-content',
    color: '#ffffff',
    display: 'block',
    lineHeight: 1,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  snsContainer: {
    width: 200,
    marginBottom: theme.spacing(3),
  },
  snsLink: {
    width: 40,
    height: 40,
  },
  truste: {
    width: sp => sp ? 120 : 200,
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  copyright: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    lineHeight: 1,
  },
}))

export default function EntryFooter () {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  return (
    <div className={classes.root}>
      <a className={classes.logoLink} href={Config.serviceSiteUrl} target="_blank" rel="noreferrer">
        <img src={logoImage} width={132} height={38} alt="" />
      </a>
      <Grid container>
        <Grid className={classes.links} container wrap="nowrap">
          <div>
            <a className={classes.link} href={`${Config.serviceSiteUrl}#service`} target="_blank" rel="noreferrer">サービス概要</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}#effect`} target="_blank" rel="noreferrer">AIの効果</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}#userFeedback`} target="_blank" rel="noreferrer">ユーザーの声</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}#planPrice`} target="_blank" rel="noreferrer">料金プラン</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}#faq`} target="_blank" rel="noreferrer">よくある質問</a>
          </div>
          <div>
            <a className={classes.link} href="https://aill.ai/company/" target="_blank" rel="noreferrer">運営会社</a>
            <a className={classes.link} href="https://www.wantedly.com/companies/Aill" target="_blank" rel="noreferrer">採用情報</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}law/`} target="_blank" rel="noreferrer">特定商法取引法に基づく表示</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}terms/`} target="_blank" rel="noreferrer">利用規約</a>
            <a className={classes.link} href={`${Config.serviceSiteUrl}privacy/`} target="_blank" rel="noreferrer">プライバシーステートメント</a>
            <a className={classes.link} href="mailto:support@aill.ai" target="_blank" rel="noreferrer">お問い合わせ</a>
          </div>
        </Grid>
        <div>
          <Grid className={classes.snsContainer} container justifyContent="space-between" alignItems="center">
            <a className={classes.snsLink} href="https://line.me/R/ti/p/@408cfcnr?oat_content=url" target="_blank" rel="noreferrer">
              <img src={LineImg} width={40} height={40} alt="" />
            </a>
            <a className={classes.snsLink} href="https://www.instagram.com/app.aill.ai/" target="_blank" rel="noreferrer">
              <img src={InstagramImg} width={40} height={40} alt="" />
            </a>
            <a className={classes.snsLink} href="https://twitter.com/Aill__ai" target="_blank" rel="noreferrer">
              <img src={TwitterImg} width={40} height={40} alt="" />
            </a>
          </Grid>
          <a className={classes.truste} href={`${Config.serviceSiteUrl}privacy/`} target="_blank" rel="noreferrer">
            <img src="https://aill.ai/images/TRUSTe_certified_privacy_jp.png" width={'100%'} alt="" />
          </a>
        </div>
      </Grid>
      <>©Aill, Inc. All Rights Reserved.</>
    </div>
  )
}

EntryFooter.propTypes = {
  signup: PropTypes.bool,
  step: PropTypes.number.isRequired,
  titles: PropTypes.array.isRequired,
}
