import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import BaseComponent from 'components/parts/BaseComponent'
import InputContainer from './InputContainer'

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    maxWidth: 320,
  },
  expSelect: {
    width: 96,
  },
  expLavel: {
    fontSize: 16,
    color: '#707070',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  securityCodeField: {
    width: 96,
  },
  errorText: {
    color: '#f44336',
    fontSize: 14,
  },
}))

export default function PaymentInput (props) {
  const { submitLabel, onUpdate } = props

  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  const expMonths = createExpValues(1, 12, 2)
  const expYears = createExpValues(currentYear, 20, 4)

  const [cardNumber, setCardNumber] = useState('')
  const [cardBrand, setCardBrand] = useState(null)
  const [cardNumberError, setCardNumberError] = useState('')

  const [cardName, setCardName] = useState('')
  const [cardNameError, setCardNameError] = useState('')

  const [expYear, setExpYear] = useState(expYears[0])
  const [expMonth, setExpMonth] = useState(expMonths[currentMonth])
  const [expError, setExpError] = useState('')

  const [securityCode, setSecurityCode] = useState('')
  const [securityCodeError, setSecurityCodeError] = useState('')

  const [updating, setUpdating] = useState(false)
  const [apiError, setApiError] = useState('')

  const clearInputs = () => {
    setCardNumber('')
    setCardBrand('')
    setCardName('')
    setExpYear(expYears[0])
    setExpMonth(expMonths[currentMonth])
    setSecurityCode('')
  }

  const onChangeCardNumber = evt => {
    setCardNumberError('')
    const newValue = evt.target.value
    const cardNum = newValue.split(' ').join('').slice(0, 16)
    setCardBrand(specifyCardBrand(cardNum))
    try {
      const lengths = getCardNumberLengthsOfSegments(cardBrand)
      const segments = splitIntoLength(cardNum, lengths)
      setCardNumber(segments.join(' '))
    } catch (err) {
      console.log(err)
      setCardNumber(cardNum)
    }
  }

  const canonicalizeCardNumber = () => {
    return cardNumber.split(' ').join('').trim()
  }

  const onChangeCardName = evt => {
    setCardNameError('')
    setCardName(evt.target.value.slice(0, 50))
  }

  const onChangeExpMonth = evt => {
    setExpError('')
    setExpMonth(evt.target.value)
  }

  const onChangeExpYear = evt => {
    setExpError('')
    setExpYear(evt.target.value)
  }

  const onChangeSecurityCode = evt => {
    setSecurityCodeError('')
    setSecurityCode(evt.target.value.slice(0, 4))
  }

  const onClickSubmit = async () => {
    if (validateInputs()) {
      const payment = await updatePayment()
      if (payment) {
        clearInputs()
        if (onUpdate) {
          onUpdate(payment)
        }
      }
    }
  }

  const validateInputs = () => {
    let valid = true
    valid = validateCardNumber() && valid
    valid = validateCardName() && valid
    valid = validateExp() && valid
    valid = validateSecurityCode() && valid
    return valid
  }

  const validateCardNumber = () => {
    let val = canonicalizeCardNumber()
    if (val.length === 0) {
      setCardNumberError('この項目は必須です。')
      return false
    }
    if (val.length < 14 || val.match(/\D/)) {
      setCardNumberError('14〜16桁の数字を入力してください。')
      return false
    }
    if (!validateLuhnChecksum(val)) {
      setCardNumberError('不正なカード番号です。')
      return false
    }
    if (!cardBrand) {
      setCardNumberError('未対応のクレジットカードです。')
      return false
    }
    setCardNumberError('')
    return true
  }

  const validateCardName = () => {
    // 全角スペース -> 半角スペース
    const val = cardName.split('　').join(' ').trim()
    if (val.length === 0) {
      setCardNameError('この項目は必須です。')
      return false
    }
    if (val.match(/[^a-zA-Z ]/)) {
      setCardNameError('カード表面に記載されている名義を入力してください')
      return false
    }
    setCardNameError('')
    return true
  }

  const validateExp = () => {
    const now = new Date()
    if (now.getFullYear() < parseInt(expYear)) {
      setExpError('')
      return true
    }
    if (parseInt(expMonth) < (now.getMonth() + 1)) {
      setExpError('有効期限が不正です。')
      return false
    }
    setExpError('')
    return true
  }

  const validateSecurityCode = () => {
    const val = securityCode.trim()
    if (val.length === 0) {
      setSecurityCodeError('この項目は必須です。')
      return false
    }
    if (val.match(/\D/)) {
      setSecurityCodeError('不正な文字が含まれています。')
      return false
    }
    setSecurityCodeError('')
    return true
  }

  const updatePayment = async () => {
    setApiError('')
    setUpdating(true)
    props.setLoading(true)
    try {
      const payment = await Api.createMyPayment({
        service: 'card',
        card_number: canonicalizeCardNumber(),
        card_brand: cardBrand,
        card_name: cardName.trim(),
        card_security_code: securityCode.trim(),
        card_expire: `${expMonth}/${expYear.slice(2)}`,
      })
      return payment
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      setUpdating(false)
    }
  }

  const classes = useStyles()
  return (
    <div>
      <InputContainer
        label="カード番号"
        helperText="＊ご利用いただけるカード：VISA, Mastercard, JCB, American Express"
        errorText={cardNumberError}
      >
        <TextField
          value={cardNumber}
          error={!!cardNumberError}
          variant="outlined"
          className={classes.textField}
          onChange={onChangeCardNumber}
          name="card_number"
          inputProps={{ 'data-testid': 'input-card-number' }}
        />
      </InputContainer>
      <InputContainer
        label="カード名義"
        helperText="＊カード表面に記載されている名義を入力してください。"
        errorText={cardNameError}
      >
        <TextField
          value={cardName}
          error={!!cardNameError}
          variant="outlined"
          className={classes.textField}
          onChange={onChangeCardName}
          name="card_name"
          inputProps={{ 'data-testid': 'input-card-name' }}
        />
      </InputContainer>
      <InputContainer
        label="有効期限"
        errorText={expError}
      >
        <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Select
            value={expMonth}
            error={!!expError}
            variant="outlined"
            className={classes.expSelect}
            onChange={onChangeExpMonth}
          >
            {expMonths.map(val=> <MenuItem key={val} value={val}>{val}</MenuItem>)}
          </Select>
          <Typography className={classes.expLavel}>月</Typography>
          <Select
            value={expYear}
            error={!!expError}
            variant="outlined"
            className={classes.expSelect}
            onChange={onChangeExpYear}
          >
            {expYears.map(val=> <MenuItem key={val} value={val}>{val}</MenuItem>)}
          </Select>
          <Typography className={classes.expLavel}>年</Typography>
        </Box>
      </InputContainer>
      <InputContainer
        label="セキュリティコード"
        helperText="＊カード裏面に記載されている3桁または4桁の数字を入力してください。"
        errorText={securityCodeError}
      >
        <TextField
          value={securityCode}
          error={!!securityCodeError}
          variant="outlined"
          className={classes.securityCodeField}
          onChange={onChangeSecurityCode}
          name="card_code"
          inputProps={{ 'data-testid': 'input-card-code' }}
        />
      </InputContainer>
      <Button
        color="primary"
        variant="contained"
        disabled={updating}
        onClick={onClickSubmit}
      >
        { submitLabel || '変更する' }
      </Button>
      {
        apiError &&
        <Typography className={classes.errorText}>
          { apiError }
        </Typography>
      }
    </div>
  )
}

PaymentInput.propTypes = {
  submitLabel: PropTypes.string,
  onUpdate: PropTypes.func,
  setLoading: PropTypes.func,
}

const BRAND_VISA = 'VISA'
const BRAND_MASTERCARD = 'Mastercard'
const BRAND_JCB = 'JCB'
const BRAND_AMEX = 'American Express'
const BRAND_DINERS = 'Diners Club'

// https://gist.github.com/matsubo/2c91c9cbedf17a490dca
function specifyCardBrand (cardNum) {
  if (!cardNum) { return null }
  if (cardNum.length === 0) { return null }

  if (1 <= cardNum.length) {
    switch (cardNum.slice(0, 1)){
      case '4': return BRAND_VISA
      case '5': return BRAND_MASTERCARD
    }
  }
  if (2 <= cardNum.length) {
    switch (cardNum.slice(0, 2)) {
      case '34':
      case '37':
        return BRAND_AMEX
      case '36':
      case '38':
      case '39':
        return BRAND_DINERS
    }
  }
  if (3 <= cardNum.length) {
    let n = parseInt(cardNum.slice(0, 3))
    if (300 <= n && n <= 305) {
      return BRAND_DINERS
    }
  }
  if (4 <= cardNum.length) {
    let n = parseInt(cardNum.slice(0, 4))
    if (n === 3095) {
      return BRAND_DINERS
    }
    if (3528 <= n && n <= 3589) {
      return BRAND_JCB
    }
  }
  return null
}

function getCardNumberLengthsOfSegments (brand) {
  switch (brand) {
    case BRAND_DINERS:
      return [4, 6, 4]
    case BRAND_AMEX:
      return [4, 6, 5]
    default:
      return [4, 4, 4, 4]
  }
}

// 文字数ごとに分割
function splitIntoLength (str, lengthsOfSegments) {
  if (!lengthsOfSegments) { return [str] }
  if (lengthsOfSegments.length === 0) { return [str] }

  let segments = []
  let start = 0
  lengthsOfSegments.forEach(len => {
    const end = start + len
    segments.push(str.slice(start, end))
    start = end
  })
  segments.push(start, str.length)
  return segments.filter(s => 0 < s.length)
}

// 有効期限選択の値生成
function createExpValues (startVal, count, digits) {
  const vals = []
  for (let i = 0; i < count; i++) {
    vals.push(('0000' + (startVal + i)).slice(-digits))
  }
  return vals
}

// チェックデジット判定
// https://ja.wikipedia.org/wiki/Luhn%E3%82%A2%E3%83%AB%E3%82%B4%E3%83%AA%E3%82%BA%E3%83%A0
function validateLuhnChecksum (cardNum) {
  const digits = cardNum.split('').reverse()
  let sum = 0
  for (let i = 0; i < digits.length; i++) {
    const x = digits[i] * (1 + (i % 2))
    sum += (9 < x ? x - 9 : x)
  }
  return sum % 10 === 0
}