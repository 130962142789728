import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import GiftCard from 'images/gift_card_null.png'

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  giftcard: {
    position: 'relative',
    marginBottom: theme.spacing(-2),
  },
  giftcardText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    position: 'absolute',
    bottom: 26,
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  achievedNote: {
    marginTop: theme.spacing(1.5),
    marginBottom: 0,
    textAlign: 'center',
  },
  largeText: {
    fontSize: 16,
  },
}))

export default function FreeTermExtensionChallengeAchievedDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          チャレンジ達成！
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.giftcard}>
            <img src={GiftCard} width={216} />
            <Typography className={classes.giftcardText}>
              無料トライアル期間<br />
              <span className={classes.largeText}>14日延長</span>
            </Typography>
          </div>
          <Typography className={classes.achievedNote} variant="body2">
            ＊詳細はメンバーズサイト<br />
            「お支払い履歴」を確認ください
          </Typography>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtensionChallengeAchievedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
