import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoAdviceContent from 'components/parts/PhotoAdviceContent'
import PhotoIssueDialog from 'components/photo-tutorial/PhotoIssueDialog'
import Kagura from 'images/photo-advice/kagura.png'
import Sakaki from 'images/photo-advice/sakaki.png'
import Text02 from 'images/photo-advice/text_0_2.png'
import TopBG from 'images/photo-advice/top_background.png'
import TopText from 'images/photo-advice/top_text.png'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(9),
  },
  topImg: {
    position: 'relative',
    marginBottom: theme.spacing(6),
    '& img:nth-of-type(2)': {
      position: 'absolute',
      top: 27,
      left: 0,
    },
    '& p': {
      fontSize: 17,
      fontWeight: 700,
      color: '#423BC7',
      textAlign: 'center',
      lineHeight: '20px',
      position: 'absolute',
      bottom: -10,
      left: '50%',
      transform: 'translate(-50%, -100%)',
    },
  },
  button: {
    width: 268,
    height: 43,
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    background: '#616AFA',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      color: '#ffffff',
      background: '#616AFA',
      border: 'solid 1px #423BC7',
    }
  },
  self: {
    width: 336,
    height: 255,
    background: '#616AFA',
    borderRadius: 8,
    margin: 'auto',
    paddingTop: theme.spacing(3.75),
    marginBottom: theme.spacing(4),
    '& p': {
      fontSize: 14,
      fontWeight: 600,
      color: '#ffffff',
      lineHeight: 1,
      marginTop: theme.spacing(0.25),
      marginBottom: theme.spacing(1),
    },
    '& button': {
      color: '#423BC7',
      background: '#ffffff',
      margin: 0,
    },
  },
}))

export default function PhotoAdvice (props) {
  const { user, advicePhoto } = props
  const classes = useStyles()
  const [galleryItems, setGalleryItems] = useState([])
  const [openPhotoIssueDialog, setOpenPhotoIssueDialog] = useState(false)

  useEffect(() => {
    if (!advicePhoto || advicePhoto.length === 0) {
      props.setScreen('Home')
      return
    }
    (async () => {
      props.setLoading(true)
      try {
        BaseComponent.addVisitPageLog()
        if (advicePhoto.includes('not_clean') || advicePhoto.includes('bad_background') || advicePhoto.includes('bad_selfie')) {
          const res = await Api.getMyGalleryItems()
          setGalleryItems(res)
        }
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()

    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  if (!advicePhoto || advicePhoto.length === 0) {
    return null
  }

  const advice = advicePhoto.slice(0, 3)

  return (
    <>
      <div className={classes.root}>
        <AppHeader title="マッチング率アップの秘訣" backWhite {...props} />
        <div className={classes.topImg}>
          <img src={TopBG} width={'100%'} />
          <img src={TopText} width={'100%'} />
          <Typography>{advice.length}STEP<br />{advice.length}分で簡潔！！</Typography>
        </div>
        {advice.map((type, index) => <PhotoAdviceContent key={index} {...props} galleryItems={galleryItems} type={type} num={index} />)}
        <Grid className={classes.self} container direction="column" alignItems="center">
          <div>
            <img src={Kagura} width={57} height={81} />
            <img src={Text02} width={192} height={138} />
            <img src={Sakaki} width={62} height={81} />
          </div>
          <Typography className={classes.text}>あなたの魅力、伝わってる？</Typography>
          <Button className={classes.button} variant="contained" onClick={() => setOpenPhotoIssueDialog(true)}>
            自分の見え方を確認しょう！
          </Button>
        </Grid>
        <Button className={classes.button} variant="contained" onClick={() => {
          const url = Config.serviceSiteUrl + 'photo-tutorial.html'
          window.open(url)
        }}>
          好印象な写真の撮り方を見る
        </Button>
      </div>
      <PhotoIssueDialog
        {...props}
        open={openPhotoIssueDialog}
        onClose={() => setOpenPhotoIssueDialog(false)}
        user={user}
        type="Home"
      />
    </>
  )
}

PhotoAdvice.propTypes = {
  user: PropTypes.object,
  advicePhoto: PropTypes.array,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
