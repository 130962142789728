import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  list: {
    padding: 0,
  },
  companyName: {
    '& span': {
      textAlign: 'left',
      margin: 0,
    },
    '& p': {
      textAlign: 'left',
      margin: 0,
    },
  },
  divider: {
    height: 0.5,
  },
})

// 会社検索結果リスト
class SearchCompanyResultList extends React.Component {

  prepareCompanyName = (name) => {
    switch (name) {
      // 開発環境用
      case 'ABCシステムズ株式会社':
        return name + ' (ABCシス)'
      case '西日本旅客鉄道株式会社':
        return name + ' (JR西日本)'
      case '西日本鉄道株式会社':
        return name + ' (西鉄)'
      default:
        return name
    }
  }

  render () {
    const { classes, companies } = this.props
    const now = new Date()
    return (
      <List className={classes.list}>
        {companies.map(company => {
          const serviceStartAt = new Date(company.service_start_at)
          const disabled = (now < serviceStartAt)
          return (
            <React.Fragment key={company.name}>
              <Divider className={classes.divider} />
              <ListItem button onClick={() => this.onSelectCompany(company)} disabled={disabled}>
                <ListItemText
                  primary={this.prepareCompanyName(company.name)}
                  secondary={disabled ? `${this.formatServiceStartDate(serviceStartAt)} 登録開始予定` : null}
                  className={classes.companyName}
                />
              </ListItem>
            </React.Fragment>
          )
        })}
        <Divider className={classes.divider} />
        <ListItem button onClick={() => this.onSelectNone()}>
          <ListItemText primary="該当なし" className={classes.companyName} />
        </ListItem>
      </List>
    )
  }

  formatServiceStartDate (date) {
    if (date) {
      let d = this.formatServiceStartDay(date)
      return dateFormat(date, `yyyy年m月${d}`)
    } else {
      return '(日付未定)'
    }
  }

  formatServiceStartDay  (date) {
    let d = date.getDate()
    if (d <= 10) { return '上旬' }
    if (d <= 20) { return '中旬' }
    return '下旬'
  }

  onSelectCompany = company => {
    if (this.props.onSelect) {
      this.props.onSelect(company)
    }
  }

  onSelectNone = () => {
    if (this.props.onSelectNone) {
      this.props.onSelectNone()
    }
  }
}

SearchCompanyResultList.propTypes = {
  classes: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onSelectNone: PropTypes.func,
}

export default withStyles(styles)(SearchCompanyResultList)
