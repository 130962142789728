import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Api from 'commons/api'
import NaviPopper from 'components/first-navigation/NaviPopper'
import InterestedThingsProfile from 'components/interested-things/InterestedThingsProfile'
import BaseComponent from 'components/parts/BaseComponent'
import KomainuAdvice from 'components/parts/KomainuAdvice'
import MyBasicProfile from 'components/parts/MyBasicProfile.jsx'
import MyLoveProfile from 'components/parts/MyLoveProfile.jsx'
import MyProfileHeader from 'components/parts/MyProfileHeader'
import Kagura from 'images/img_new_profile_item_dialog_3.png'
import Sakaki from 'images/img_remind_dialog_4.png'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  bottomSpacer: {
    height: theme.spacing(15),
  },
  backButton: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: theme.spacing(8),
    height: theme.spacing(6.375),
    padding: 0,
    zIndex: '999'
  },
  backIcon: {
    color: theme.palette.secondary.main,
  },
  basicProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  loveProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
  },
  profileTitle: {
    ...theme.styles.fontSize(14),
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.375),
  },
  buttonContainer: {
    bottom: theme.spacing(4.375),
    position: 'fixed',
  },
  editBotton: {
    ...theme.styles.entryButton,
    fontWeight: 'bold',
    width: theme.spacing(33.5),
    height: theme.spacing(5.5),
    marginBottom: theme.spacing(1),
  },
})

export class MyProfile extends BaseComponent {
  constructor (props) {
    super(props)

    this.clickBack = this.clickBack.bind(this)
    this.clickEdit = this.clickEdit.bind(this)

    this._isMounted = false

    this.state = {
      reasonOpen: false,
      cancelOpen: false,
      questionOpen: false,
      confirmOpen: false,
      profilePublicSetting: null,
      openNavi: false,
    }
  }

  componentWillMount () {
    this.getProfilePublicSetting(this.props.user.id)
  }

  async componentDidMount () {
    this._isMounted = true
    this.addVisitPageLog()

    // 興味のあることチュートリアル表示判定
    if (!this.props.tutorialReadStates) { return }
    if (this.props.tutorialReadStates.length === 0) { return }
    if (this.props.tutorialReadStates.includes('interested_things')) { return }
    this.props.setTutorialView('interested-things-01')
  }

  async componentWillUnmount () {
    this._isMounted = false
    this.addLeavePageLog()
  }

  async getProfilePublicSetting (user_id) {
    try {
      let setting = await Api.getProfilePublicSetting(user_id)
      this.setStateIfMounted({
        profilePublicSetting: setting,
      })
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  clickBack () {
    BaseComponent.goBack(this.props)
  }

  clickEdit () {
    this.props.setScreen('ProfileEdit')
  }

  render () {
    const { classes, ...other } = this.props
    return (
      <>
        <div className={classes.root}>
          <IconButton aria-label="add" className={classes.backButton} onClick={this.clickBack} data-testid="back-button">
            <LeftIcon className={classes.backIcon}/>
          </IconButton>
          <MyProfileHeader
            user={this.props.user}
            component={this}
            {...other}
          />
          <div className={classes.basicProfileContainer}>
            <Typography className={classes.profileTitle}>
              興味のあること
            </Typography>
            <InterestedThingsProfile {...this.other} user={this.props.user} />
          </div>
          {
            this.state.profilePublicSetting &&
            <div className={classes.basicProfileContainer}>
              <Typography  className={classes.profileTitle}>
                基本情報
              </Typography>
              <MyBasicProfile {...other} publics={this.state.profilePublicSetting} />
            </div>
          }
          {
            this.state.profilePublicSetting &&
            <div className={classes.loveProfileContainer}>
              <Typography  className={classes.profileTitle}>
                恋愛情報
              </Typography>
              <MyLoveProfile {...other} publics={this.state.profilePublicSetting} />
            </div>
          }
          <Grid container className={classes.buttonContainer} justifyContent="center" id="interested-things-tutorial-target">
            <Button variant="contained" color="secondary" className={classes.editBotton} onClick={this.clickEdit}>
              プロフィールを編集する
            </Button>
          </Grid>
          <div className={classes.bottomSpacer} />
        </div>
        <NaviPopper
          {...this.props}
          id="interested-things-01"
          open={this.props.tutorialView === 'interested-things-01'}
          anchorEl={document.getElementById('interested-things-tutorial-target')}
          title={'興味のあることを\n登録しよう！'}
          text={'登録すると人となりが伝わって\nマッチングしやすくなるよ'}
          child={(
            <KomainuAdvice
              text={'ボクたちが学習して\n紹介にも反映するよ♪'}
              img01={Kagura}
              img02={Sakaki}
            />
          )}
          placement={'top'}
          onClose={() => this.props.setTutorialView(null)}
          arrow
        />
      </>
    )
  }
}

MyProfile.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MyProfile)