import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import WelcomeDialog from 'components/parts/WelcomeDialog'

/*
 * 本日の出逢いチュートリアル更新のお知らせポップアップ
 */
function MatchingTutorialUpdatedDialog (props) {
  const { open, onClose, ...other } = props
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false)

  const onClickConfirm = () => {
    setOpenWelcomeDialog(true)
    if (onClose) { onClose() }
  }

  return (
    <>
      <DialogThemeProvider color="primary">
        <Dialog open={open} onClose={onClose}>
          <DialogTitle disableTypography>
            本日の出逢いチュートリアル更新のお知らせ
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              本日の出逢いのチュートリアルを<br />
              更新しました。<br />
              大切な更新ですので、<br />
              ご確認お願いします。
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={onClickConfirm}>
              確認
            </Button>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
      <WelcomeDialog
        open={openWelcomeDialog}
        onClose={() => setOpenWelcomeDialog(false)}
        {...other}
      />
    </>
  )
}
MatchingTutorialUpdatedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
export default MatchingTutorialUpdatedDialog