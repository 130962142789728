import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import BadIcon from 'images/photo-advice/bad_icon.png'
import BadIcon02 from 'images/photo-advice/bad_icon_02.png'
import ExampleBadBackgroundBadFemale from 'images/photo-advice/example_bad_background_bad_female.png'
import ExampleBadBackgroundBadMale from 'images/photo-advice/example_bad_background_bad_male.png'
import ExampleBadBackgroundGoodFemale from 'images/photo-advice/example_bad_background_good_female.png'
import ExampleBadBackgroundGoodMale from 'images/photo-advice/example_bad_background_good_male.png'
import ExampleBadSelfieBadFemale from 'images/photo-advice/example_bad_selfie_bad_female.png'
import ExampleBadSelfieBadMale from 'images/photo-advice/example_bad_selfie_bad_male.png'
import ExampleBadSelfieGoodFemale from 'images/photo-advice/example_bad_selfie_good_female.png'
import ExampleBadSelfieGoodMale from 'images/photo-advice/example_bad_selfie_good_male.png'
import ExampleFewFacesBad01 from 'images/photo-advice/example_few_faces_bad_01.png'
import ExampleFewFacesBad02 from 'images/photo-advice/example_few_faces_bad_02.png'
import ExampleFewFacesBad03 from 'images/photo-advice/example_few_faces_bad_03.png'
import ExampleFewFacesBad04 from 'images/photo-advice/example_few_faces_bad_04.png'
import ExampleFewFacesBad05 from 'images/photo-advice/example_few_faces_bad_05.png'
import ExampleFewFacesBad06 from 'images/photo-advice/example_few_faces_bad_06.png'
import ExampleFewFacesGoodFemale01 from 'images/photo-advice/example_few_faces_good_female_01.png'
import ExampleFewFacesGoodFemale02 from 'images/photo-advice/example_few_faces_good_female_02.png'
import ExampleFewFacesGoodFemale04 from 'images/photo-advice/example_few_faces_good_female_04.png'
import ExampleFewFacesGoodFemale06 from 'images/photo-advice/example_few_faces_good_female_06.png'
import ExampleFewFacesGoodMale01 from 'images/photo-advice/example_few_faces_good_male_01.png'
import ExampleFewFacesGoodMale02 from 'images/photo-advice/example_few_faces_good_male_02.png'
import ExampleFewFacesGoodMale04 from 'images/photo-advice/example_few_faces_good_male_04.png'
import ExampleFewGalleryItemsBadFemale from 'images/photo-advice/example_few_gallery_items_bad_female.png'
import ExampleFewGalleryItemsBadMale from 'images/photo-advice/example_few_gallery_items_bad_male.png'
import ExampleFewGalleryItemsGoodFemale from 'images/photo-advice/example_few_gallery_items_good_female.png'
import ExampleFewGalleryItemsGoodMale from 'images/photo-advice/example_few_gallery_items_good_male.png'
import ExampleHiddenFaceBadFemale from 'images/photo-advice/example_hidden_face_bad_female.png'
import ExampleHiddenFaceBadMale from 'images/photo-advice/example_hidden_face_bad_male.png'
import ExampleHiddenFaceGoodFemale from 'images/photo-advice/example_hidden_face_good_female.png'
import ExampleHiddenFaceGoodMale from 'images/photo-advice/example_hidden_face_good_male.png'
import ExampleLowQualityBadFemale from 'images/photo-advice/example_low_quality_bad_female.png'
import ExampleLowQualityBadMale from 'images/photo-advice/example_low_quality_bad_male.png'
import ExampleLowQualityGoodFemale from 'images/photo-advice/example_low_quality_good_female.png'
import ExampleLowQualityGoodMale from 'images/photo-advice/example_low_quality_good_male.png'
import ExampleNoExpressionBadFemale from 'images/photo-advice/example_no_expression_bad_female.png'
import ExampleNoExpressionBadMale from 'images/photo-advice/example_no_expression_bad_male.png'
import ExampleNoExpressionGoodFemale from 'images/photo-advice/example_no_expression_good_female.png'
import ExampleNoExpressionGoodMale from 'images/photo-advice/example_no_expression_good_male.png'
import ExampleNotCleanBadFemale from 'images/photo-advice/example_not_clean_bad_female.png'
import ExampleNotCleanBadMale from 'images/photo-advice/example_not_clean_bad_male.png'
import ExampleNotCleanGoodFemale from 'images/photo-advice/example_not_clean_good_female.png'
import ExampleNotCleanGoodMale from 'images/photo-advice/example_not_clean_good_male.png'
import ExampleTooProcessingBadFemale from 'images/photo-advice/example_too_processing_bad_female.png'
import ExampleTooProcessingBadMale from 'images/photo-advice/example_too_processing_bad_male.png'
import ExampleTooProcessingGoodFemale from 'images/photo-advice/example_too_processing_good_female.png'
import ExampleTooProcessingGoodMale from 'images/photo-advice/example_too_processing_good_male.png'
import Focus from 'images/photo-advice/focus.png'
import GoodIcon from 'images/photo-advice/good_icon.png'
import GoodIcon02 from 'images/photo-advice/good_icon_02.png'
import Img from 'images/photo-advice/img.png'
import Kagura01 from 'images/photo-advice/kagura01.png'
import Kagura02 from 'images/photo-advice/kagura02.png'
import Kagura03 from 'images/photo-advice/kagura03.png'
import PhoneFemale from 'images/photo-advice/phone_female.png'
import PhoneMale from 'images/photo-advice/phone_male.png'
import Sakaki01 from 'images/photo-advice/sakaki01.png'
import Sakaki02 from 'images/photo-advice/sakaki02.png'
import Sakaki03 from 'images/photo-advice/sakaki03.png'
import Speech from 'images/photo-advice/speech.png'
import Stamp from 'images/photo-advice/stamp.png'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(5.25),
  },
  title: {
    width: 311,
    height: 70,
    fontSize: 18,
    fontWeight: 600,
    color: '#423BC7',
    background: '#EFF0FF',
    whiteSpace: 'pre-wrap',
    border: '2px solid #616AFA',
    borderRadius: 12,
    margin: 'auto',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: 8,
    left: '50%',
    '& span': {
      fontSize: 30,
      fontWeight: 700,
      fontFamily: '"ヒラギノ角ゴシック"',
      marginLeft: theme.spacing(2.25),
      marginRight: theme.spacing(1.5),
    },
  },
  komainuIcon: {
    position: 'absolute',
    right: 4,
    bottom: 0,
    transform: 'translate(0%, 30%)',
  },
  box: {
    width: 336,
    border: '2px solid #616AFA',
    borderRadius: 11,
    margin: 'auto',
    paddingTop: theme.spacing(8.75),
    paddingBottom: theme.spacing(3),
    '& button': {
      marginTop: theme.spacing(3.75),
    },
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: '21px',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  example: {
    position: 'relative',
    '& span': {
      width: 38,
      height: 38,
      background: '#ffffff',
      borderRadius: '0 0 20px 0',
      paddingTop: theme.spacing(0.625),
      paddingLeft: theme.spacing(0.625),
      position: 'absolute',
      top: 0,
      left: 8,
      '& img': {
        display: 'block',
        margin: 0,
      },
    },
    '& img': {
      display: 'block',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  comment: {
    fontSize: 12,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  button: {
    width: 268,
    height: 43,
    fontSize: 14,
    fontWeight: 600,
    color: '#ffffff',
    background: '#616AFA',
    display: 'block',
    margin: 'auto',
  },
  frameA: {
    width: 156,
    height: 156,
    background: '#423BC7',
    margin: 'auto',
    position: 'relative',
    zIndex: 1,
    '& span': {
      width: 50,
      height: 24,
      fontSize: 12,
      fontWeight: 600,
      color: '#ffffff',
      background: '#423BC7',
      lineHeight: 1,
      borderRadius: '0 0 10px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 3,
      top: 0,
      left: 0,
    },
  },
  frameB: {
    width: 72,
    height: 72,
    background: '#423BC7',
    margin: theme.spacing(1),
    position: 'relative',
    zIndex: 1,
    '& span': {
      width: 36,
      height: 17,
      fontSize: 9,
      fontWeight: 600,
      color: '#ffffff',
      background: '#423BC7',
      lineHeight: 1,
      borderRadius: '0 0 10px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 3,
      top: 0,
      left: 0,
    },
  },
  brightness: {
    width: 294,
    height: 145,
    background: '#EFF0FF80',
    border: 'dotted 2px #616AFA',
    borderRadius: 12,
    marginTop: theme.spacing(3),
    '& p': {
      fontSize: 16,
      fontWeight: 600,
      color: '#616AFA',
      textAlign: 'center',
    },
  },
  outlinedButton: {
    width: 230,
    height: 44,
    fontSize: 14,
    fontWeight: 600,
    color: '#616AFA',
    background: '#ffffff',
    border: '2px solid #616AFA',
    borderRadius: '22px',
    marginTop: `${theme.spacing(2)}px !important`,
  },
  selfie: {
    fontSize: 14,
    fontWeight: 600,
    color: '#616AFA',
    lineHeight: '20px',
    borderBottom: 'solid 1px #616AFA',
    marginBottom: theme.spacing(3),
  },
  this: {
    '& p:nth-of-type(1)': {
      fontSize: 16,
      fontWeight: 600,
      color: '#616AFA',
      lineHeight: 1,
      marginBottom: theme.spacing(2.5),
    },
    '& p:nth-of-type(2)': {
      fontSize: 12,
      fontWeight: 600,
      color: '#616AFA',
      lineHeight: 1,
      textAlign: 'center',
      marginTop: theme.spacing(1),
    },
  },
  subPhoto: {
    marginTop: theme.spacing(8),
    position: 'relative',
    '& img': {
      display: 'block',
      margin: 'auto',
    },
    '& img:nth-of-type(2)': {
      position: 'absolute',
      top: -24,
      right: -20,
    },
  },
  fewFacesGood: {
    width: 294,
    height: 305,
    background: '#EFF0FF',
    border: 'dotted 2px #616AFA',
    borderRadius: 12,
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    '& p:nth-of-type(1)': {
      width: 223,
      height: 34,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1,
      color: '#616AFA',
      background: '#ffffff',
      textAlign: 'center',
      border: 'solid 2px #616AFA',
      borderRadius: 25,
      position: 'absolute',
      padding: 5,
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& div': {
      width: 'fit-content',
      margin: 'auto',
      marginBottom: theme.spacing(1.5),
    },
    '& img:nth-of-type(n+2)': {
      display: 'inline-block',
      marginRight: 2,
      marginLeft: 2,
    },
    '& p:nth-of-type(2)': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '21px',
      color: '#423BC7',
      textAlign: 'center',
    },
    '& img:nth-of-type(6)': {
      position: 'absolute',
      bottom: 8,
      right: 8,
    },
  },
  fewFacesBad: {
    width: 294,
    height: 305,
    background: '#F5F5F5',
    border: 'dotted 2px #414141',
    borderRadius: 12,
    position: 'relative',
    paddingTop: theme.spacing(4),
    '& p:nth-of-type(1)': {
      width: 223,
      height: 34,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1,
      color: '#414141',
      background: '#ffffff',
      textAlign: 'center',
      border: 'solid 2px #414141',
      borderRadius: 25,
      position: 'absolute',
      padding: 5,
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& div': {
      width: 'fit-content',
      margin: 'auto',
      marginBottom: theme.spacing(1.5),
    },
    '& img:nth-of-type(n+2)': {
      display: 'inline-block',
      marginRight: 2,
      marginLeft: 2,
    },
    '& p:nth-of-type(2)': {
      fontSize: 14,
      fontWeight: 600,
      color: '#414141',
      lineHeight: '21px',
      textAlign: 'center',
    },
    '& img:nth-of-type(6)': {
      position: 'absolute',
      bottom: 8,
      right: 8,
    },
  },
  changeThis: {
    width: 294,
    background: '#EFF0FF',
    border: 'dotted 2px #616AFA',
    borderRadius: 12,
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(2.75),
    marginTop: theme.spacing(7),
    position: 'relative',
    '& p': {
      width: 211,
      height: 33,
      fontSize: 15,
      fontWeight: 600,
      color: '#423BC7',
      lineHeight: 1,
      background: '#ffffff',
      textAlign: 'center',
      border: 'solid 2px #423CC7',
      borderRadius: 25,
      position: 'absolute',
      padding: 5,
      top: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}))

const Types = [
  // 横顔
  'from_side',
  // 顔が見えない（70%以上が隠れている）
  'hidden_face',
  // 画像が荒い
  'low_quality',
  // 加工しすぎ
  'too_processing',
  // 清潔感がない
  'not_clean',
  // 写真が暗い
  'low_brightness',
  // 無表情
  'no_expression',
  // 背景が微妙
  'bad_background',
  // 自撮りの写りが悪い
  'bad_selfie',
  // メインにした方が良い
  'better_to_main',
  // サブ写真が少ない
  'few_gallery_items',
  // もう一枚顔全体が写った写真があれば良い
  'few_faces',
]

const kagura = [Kagura01, Kagura02, Kagura03]
const sakaki = [Sakaki01, Sakaki02, Sakaki03]

export default function PhotoAdviceContent (props) {
  const { user, galleryItems, type, num } = props
  const classes = useStyles()
  if (!user) { return null }
  if (!Types.includes(type)) { return null }

  const createAdviceTitle = () => {
    switch (type) {
      case 'from_side':
      case 'hidden_face':
        return 'メイン写真は笑顔が大切'
      case 'low_quality':
        return 'メイン写真は高画質に'
      case 'too_processing':
        return '自然体の\nメイン写真にしよう！'
      case 'not_clean':
        return 'マッチングは\n清潔感が決め手'
      case 'low_brightness':
        return '明るさで\n写真の印象が変わる！'
      case 'no_expression':
        return 'ポジティブな表情が\n魅力UPに'
      case 'bad_background':
        return 'シンプルな背景で\n清潔感UP！'
      case 'bad_selfie':
        return '自撮りの写真は\n難易度MAX'
      case 'better_to_main':
        return 'サブ写真を\nメイン写真にしよう！'
      case 'few_gallery_items':
        return 'サブ写真を増やそう'
      case 'few_faces':
        return 'サブ写真にもう1枚\n自分の写真を追加！'
      default:
        return ''
    }
  }

  const createAdviceText = () => {
    switch (type) {
      case 'from_side':
      case 'hidden_face':
        return 'ポジティブな表情が見えると好印象！'
      case 'low_quality':
        return '画質が荒いと伝わる魅力が半減\nメイン写真は高画質＆笑顔がオススメ'
      case 'too_processing':
        return '真剣な出会いを探している方ばかり\n会った時も好印象のために\nメイン写真は自然体＆笑顔で！'
      case 'not_clean':
        return '明るい表情＆服装で、清潔感UP'
      case 'low_brightness':
        return 'メイン写真を明るくしよう！'
      case 'no_expression':
        return '明るい表情は第一印象に直結するよ'
      case 'bad_background':
        return '「背景」も\nお相手の着目ポイントの1つ！'
      case 'bad_selfie':
        return '手持ち写真が無ければ\nZoomでスクショを'
      case 'better_to_main':
        return ''
      case 'few_gallery_items':
        return 'サブ写真は「人となり」が伝わる\n重要アイテム'
      case 'few_faces':
        return 'もう一枚自分の写真があると\nお相手の安心感がUP'
      default:
        return ''
    }
  }

  const createKomainuIcon = () => {
    switch (type) {
      case 'from_side':
      case 'hidden_face':
      case 'too_processing':
      case 'low_brightness':
      case 'bad_background':
      case 'better_to_main':
      case 'few_faces': {
        const src = kagura.shift()
        if (!src) { return null }
        return <img className={classes.komainuIcon} src={src} height={57} />
      }
      case 'low_quality':
      case 'not_clean':
      case 'no_expression':
      case 'bad_selfie':
      case 'few_gallery_items': {
        const src = sakaki.shift()
        if (!src) { return null }
        return <img className={classes.komainuIcon} src={src} height={57} />
      }
      default:
        return null
    }
  }

  const createGoodExample = (src) => {
    return (
      <div className={classes.example}>
        <span>
          <img src={GoodIcon} width={24} height={24} />
        </span>
        <img src={src} width={140} height={140} />
      </div>
    )
  }

  const createBadExample = (src) => {
    return (
      <div className={classes.example}>
        <span>
          <img src={BadIcon} width={18} height={18} />
        </span>
        <img src={src} width={140} height={140} />
      </div>
    )
  }

  const findTargetItems = () => {
    const result = []
    if ((user?.photo_features || []).includes(type)) {
      result.push(user.photo_large)
    }
    galleryItems.map(item => {
      if ((item?.features || []).includes(type)) {
        result.push(item.large_url)
      }
    })
    return result
  }

  const createChangeThisPhoto = () => {
    const targets = findTargetItems()
    if (targets.length === 0) { return null }
    return (
      <div className={classes.changeThis}>
        <Typography>この写真を変更しよう<img src={Stamp} width={22} height={22} /></Typography>
        <Grid container justifyContent="center">
          {targets.map((target, i) => {
            const label = target.includes('large_photo_') ? 'メイン' : 'サブ'
            return (
              <Grid key={i} className={classes.frameB} container justifyContent="center" alignItems="center">
                <span>{label}</span>
                <img src={target} width={64} height={64} />
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }

  const createAdviceContents = () => {
    switch (type) {
      case 'from_side':
      case 'hidden_face': {
        const good = user.sex === 'male' ? ExampleHiddenFaceGoodMale : ExampleHiddenFaceGoodFemale
        const bad = user.sex === 'male' ? ExampleHiddenFaceBadMale : ExampleHiddenFaceBadFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>第一印象良し</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>？？どんな人？？</Typography>
            </div>
          </>
        )
      }
      case 'low_quality': {
        const good = user.sex === 'male' ? ExampleLowQualityGoodMale : ExampleLowQualityGoodFemale
        const bad = user.sex === 'male' ? ExampleLowQualityBadMale : ExampleLowQualityBadFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>魅力が伝わる</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>印象がぼやける・・・</Typography>
            </div>
          </>
        )
      }
      case 'too_processing': {
        const good = user.sex === 'male' ? ExampleTooProcessingGoodMale : ExampleTooProcessingGoodFemale
        const bad = user.sex === 'male' ? ExampleTooProcessingBadMale : ExampleTooProcessingBadFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>好印象！</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>警戒心を抱く・・・</Typography>
            </div>
          </>
        )
      }
      case 'not_clean': {
        const good = user.sex === 'male' ? ExampleNotCleanGoodMale : ExampleNotCleanGoodFemale
        const bad = user.sex === 'male' ? ExampleNotCleanBadMale : ExampleNotCleanBadFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>好印象！</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>警戒心を抱く・・・</Typography>
            </div>
            {createChangeThisPhoto([])}
          </>
        )
      }
      case 'low_brightness':
        if (!user) { return null }
        return (
          <>
            <Grid className={classes.frameA} container justifyContent="center" alignItems="center">
              <span>メイン</span>
              <img src={user.photo_large} width={140} height={140} />
            </Grid>
            <Grid className={classes.brightness} container direction="column" justifyContent="center" alignItems="center">
              <Typography>プロフィール編集から調整可能！<br />運営が明るくしても良い？</Typography>
              <Button className={classes.outlinedButton} variant="outlined" onClick={() => props.setScreen('ProfileEdit')}>
                明るさ調整を許可する<img src={Focus} width={25} height={27} />
              </Button>
            </Grid>
          </>
        )
      case 'no_expression': {
        const good = user.sex === 'male' ? ExampleNoExpressionGoodMale : ExampleNoExpressionGoodFemale
        const bad = user.sex === 'male' ? ExampleNoExpressionBadMale : ExampleNoExpressionBadFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>好印象！</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>警戒心を抱く・・・</Typography>
            </div>
          </>
        )
      }
      case 'bad_background': {
        const good = user.sex === 'male' ? ExampleBadBackgroundGoodMale : ExampleBadBackgroundGoodFemale
        const bad = user.sex === 'male' ? ExampleBadBackgroundBadMale : ExampleBadBackgroundBadFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>清潔感があるね！</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>清潔感がBAD</Typography>
            </div>
            {createChangeThisPhoto([1])}
          </>
        )
      }
      case 'bad_selfie': {
        const good = user.sex === 'male' ? ExampleBadSelfieGoodMale : ExampleBadSelfieGoodFemale
        const bad = user.sex === 'male' ? ExampleBadSelfieBadMale : ExampleBadSelfieBadFemale
        return (
          <>
            <Typography className={classes.selfie} onClick={() => {
              const url = Config.serviceSiteUrl + 'photo-tutorial.html'
              window.open(url)
            }}>
              ▶︎ 好印象な写真の撮り方はこちら
            </Typography>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>適切なバランス◎</Typography>
            </div>
            <div>
              {createBadExample(bad)}
              <Typography className={classes.comment}>角度・バランスが不自然</Typography>
            </div>
            {createChangeThisPhoto([1, 1])}
          </>
        )
      }
      case 'better_to_main': {
        const label = 'サブ'
        const target = findTargetItems()[0]
        return (
          <div className={classes.this}>
            <Grid container justifyContent="center" alignItems="center">
              <div>
                <div style={{ width: 33, height: 3, background: '#B0B4FC', transform: 'rotate(25deg)' }} />
                <div style={{ width: 33, height: 3, background: '#B0B4FC', margin: '40px 0' }} />
                <div style={{ width: 33, height: 3, background: '#B0B4FC', transform: 'rotate(-25deg)' }} />
              </div>
              <div>
                <Typography>この写真をメインにしよう！</Typography>
                <Grid className={classes.frameA} container justifyContent="center" alignItems="center">
                  <span>{label}</span>
                  <img src={target} width={140} height={140} />
                </Grid>
                <Typography>これが素敵！！</Typography>
              </div>
              <div>
                <div style={{ width: 33, height: 3, background: '#B0B4FC', transform: 'rotate(-25deg)' }} />
                <div style={{ width: 33, height: 3, background: '#B0B4FC', margin: '40px 0' }} />
                <div style={{ width: 33, height: 3, background: '#B0B4FC', transform: 'rotate(25deg)' }} />
              </div>
            </Grid>
          </div>
        )
      }
      case 'few_gallery_items': {
        const good = user.sex === 'male' ? ExampleFewGalleryItemsGoodMale : ExampleFewGalleryItemsGoodFemale
        const bad = user.sex === 'male' ? ExampleFewGalleryItemsBadMale : ExampleFewGalleryItemsBadFemale
        const phone = user.sex === 'male' ? PhoneMale : PhoneFemale
        return (
          <>
            <div>
              {createGoodExample(good)}
              <Typography className={classes.comment}>趣味がわかる◎</Typography>
            </div>
            <div>
              {createGoodExample(bad)}
              <Typography className={classes.comment}>好きなものがわかる◎</Typography>
            </div>
            <div className={classes.subPhoto}>
              <img src={phone} width={180} height={210} />
              <img src={Img} width={63} height={57} />
              <img src={Speech} width={262} height={74} />
            </div>
          </>
        )
      }
      case 'few_faces': {
        const good01 = user.sex === 'male' ? ExampleFewFacesGoodMale01 : ExampleFewFacesGoodFemale01
        const good02 = user.sex === 'male' ? ExampleFewFacesGoodMale02 : ExampleFewFacesGoodFemale02
        const good03 = ExampleFewFacesBad03
        const good04 = user.sex === 'male' ? ExampleFewFacesGoodMale04 : ExampleFewFacesGoodFemale04
        const good05 = ExampleFewFacesBad05
        const good06 = user.sex === 'male' ? ExampleFewFacesBad06 : ExampleFewFacesGoodFemale06
        const bad01 = ExampleFewFacesBad01
        const bad02 = ExampleFewFacesBad02
        const bad03 = ExampleFewFacesBad03
        const bad04 = ExampleFewFacesBad04
        const bad05 = ExampleFewFacesBad05
        const bad06 = ExampleFewFacesBad06
        return (
          <>
            <div className={classes.fewFacesGood}>
              <Typography>自分の写真が複数枚ある</Typography>
              <div>
                <img src={good01} width={140} height={140} />
              </div>
              <div>
                <img src={good02} width={48} height={48} />
                <img src={good03} width={48} height={48} />
                <img src={good04} width={48} height={48} />
                <img src={good05} width={48} height={48} />
                <img src={good06} width={48} height={48} />
                <img src={GoodIcon02} width={34} height={34} />
              </div>
              <Typography>→人となりを想像しやすく<br />いいね！に繋がる</Typography>
            </div>
            <div className={classes.fewFacesBad}>
              <Typography>食べ物や景色の写真のみ</Typography>
              <div>
                <img src={bad01} width={140} height={140} />
              </div>
              <div>
                <img src={bad02} width={48} height={48} />
                <img src={bad03} width={48} height={48} />
                <img src={bad04} width={48} height={48} />
                <img src={bad05} width={48} height={48} />
                <img src={bad06} width={48} height={48} />
                <img src={BadIcon02} width={34} height={34} />
              </div>
              <Typography>→人となりを想像しづらいため<br />いいね！に繋がりにくい</Typography>
            </div>
          </>
        )
      }
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.title} container alignItems="center">
        <span>{num + 1}</span>{createAdviceTitle()}
        {createKomainuIcon()}
      </Grid>
      <div className={classes.box}>
        <Typography className={classes.text}>{createAdviceText()}</Typography>
        <Grid container justifyContent="center">
          {createAdviceContents()}
        </Grid>
        <Button className={classes.button} variant="contained" onClick={() => props.setScreen('ProfileEdit')}>
          プロフィール写真を編集する
        </Button>
      </div>
    </div>
  )
}

PhotoAdviceContent.propTypes = {
  user: PropTypes.object,
  galleryItems: PropTypes.array,
  type: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  setScreen: PropTypes.func,
}
