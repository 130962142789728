import React from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-confetti'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import CongratulationsImage from 'images/img_congratulations.png'

const styles = theme => ({
  congratulationsImage:{
    width: 226,
    marginTop: theme.spacing(1.5),
  },
})

export class GraduatedDialog extends React.Component {

  handleOK = () => {
    this.props.onClose()
  }

  render () {
    const { classes, ...other } = this.props

    if (!this.props.friend) { return null }

    return(
      <div style={{ display: this.props.open ? 'block' : 'none' }}>
        <Confetti
          style={{
            'pointerEvents': 'none',
            zIndex: 9999,
          }}
        />
        <DialogThemeProvider color="default">
          <Dialog {...other}>
            <DialogTitle disableTypography>
              Congratulations!
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {this.props.friend.nick_name}さんとのお付き合いが<br/>
                成立しました！
              </Typography>
              <img
                src={CongratulationsImage}
                alt="congratulations"
                className={classes.congratulationsImage}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => this.props.onClose()}>
                確認
              </Button>
            </DialogActions>
          </Dialog>
        </DialogThemeProvider>
      </div>
    )
  }
}

GraduatedDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  friend: PropTypes.object,
  open: PropTypes.bool,
}

export default withStyles(styles)(GraduatedDialog)