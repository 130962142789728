import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Image1_female from 'images/tutorial/fav_female.png'
import Image1_male from 'images/tutorial/fav_male.png'
import Image2_female from 'images/tutorial/fav_rating_female.png'
import Image2_male from 'images/tutorial/fav_rating_male.png'

const useStyles = makeStyles(theme => ({
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(1.75),
  },
  note: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  slideIconsContainer: {
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: 8,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slide: {
    height: 480,
  },
}))

export default function FavTutorialDialog (props) {
  const { open, sex, onClose } = props
  const classes = useStyles()
  const [slideIndex, setSlideIndex] = useState(0)

  useEffect(() => {
    if (!open) { return }
    setSlideIndex(0)
  }, [open])

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  return (
    <DialogThemeProvider color="secondary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          {slideIndex === 0 ? '好感度ナビとは？' : 'お相手への好感度の\n変更の仕方'}
        </DialogTitle>
        <DialogContent>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            <div style={Object.assign({})} className={classes.slide}>
              <Typography className={classes.subTitle} variant="subtitle1">
                お相手のあなたに対する<br />好感度を見える化
              </Typography>
              <Typography className={classes.text} variant="body1">
                進展可能性を予測して<br />効率よくアプローチができます
              </Typography>
              <Typography className={classes.text} variant="body1">
                トーク一覧画面のバーの長さで<br />好感度が表示されます
              </Typography>
              <Typography className={classes.note} variant="body2">
                ＊お相手が登録した好感度や<br />トーク内容を元に解析
              </Typography>
              <Grid container justifyContent="center">
                <img
                  src={sex === 'male' ? Image1_male : Image1_female}
                  width={'100%'}
                  alt=""
                />
              </Grid>
            </div>
            <div style={Object.assign({})} className={classes.slide}>
              <Typography className={classes.text} variant="body1">
                トークメニューの好感度登録の<br />画面から変更できます
              </Typography>
              <Typography className={classes.text} variant="body1">
                好感度変更は<br />好感度解析の重要な指標の1つです
              </Typography>
              <Typography className={classes.note}>
                ＊トーク内容や頻度など総合的に解析して<br />お相手に好感度として表示
              </Typography>
              <Grid container justifyContent="center">
                <img
                  src={sex === 'male' ? Image2_male : Image2_female}
                  width={'100%'}
                  alt=""
                />
              </Grid>
            </div>
          </SwipeableViews>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <div className={classes.slideIconsContainer}>
              {slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              {slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
            </div>
            <Button
              variant="contained"
              onClick={() => slideIndex === 1 ? onClose() : setSlideIndex(slideIndex + 1)}
            >
              {slideIndex === 1 ? '閉じる' : '次へ'}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

FavTutorialDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  sex: PropTypes.string.isRequired,
}
