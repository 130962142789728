import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import MovieEventProfile from 'components/movie/MovieEventProfile'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoFilter from 'components/parts/PhotoFilter'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  avatarContainer: {
    margin: 'auto',
  },
  faceAvatarBig: {
    width: 186,
    height: 186,
  },
  faceAvatar: {
    width: 149,
    height: 149,
  },
  imageWidth: {
    ...theme.styles.profilePhotoWidth,
  },
  imageContainer: {
    ...theme.styles.photoContainer,
  },
  imageMain: {
    ...theme.styles.photoSize,
    objectFit: 'cover',
  },
  imageGallery: {
    ...theme.styles.photoSize,
    objectFit: 'contain',
  },
  gallery: {
    ...theme.styles.photoGallerySize,
    marginTop: theme.spacing(1),
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  imageListItem: {
    textAlign: 'center',
    width: '100%',
    padding: `0 ${theme.spacing(0.625)}px`,
  },
  imgContainerSelected: {
    width: '100%',
    height: 0,
    paddingBottom: 'calc(100% - 4px)',
    position: 'relative',
    boxSizing: 'border-box',
    border: '2px solid #3D45C2',
  },
  imgContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    position: 'relative',
  },
  imgGallery: {
    height: '100%',
    minHeight: '100%',
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#F5F5F5',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  nameContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
    marginTop: theme.spacing(3.75),
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    maxWidth: 'calc(100% - 32px)',
    maxHeight: 56,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    textAlign: 'left',
  },
  age: {
    fontSize: 11,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  nameContainerSimple: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  nameSimple: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  messageContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.875),
    paddingRight: theme.spacing(2.875),
    textAlign: 'left',
  },
  message: {
    fontSize: 14,
    marginTop: theme.spacing(2),
  },
}))

export default function CommonProfile (props) {
  const { friend, simple, onlyAvatar, } = props
  const classes = useStyles()
  const [photo, setPhoto] = useState(friend.photo_large)
  const [photoBrightness, setPhotoBrightness] = useState(friend.photo_filter_brightness)
  const [photo_type, setPhoto_type] = useState('main')
  const [galleryItems, setGalleryItems] = useState([])
  const [selectedGalleryIndex, setSelectedGalleryIndex] = useState(0)
  const isShowLikes = (friend.type === 'daily' && friend.sex === 'male' && friend?.likes >= 10)

  useEffect(() => {
    (async () => {
      try {
        // メイン写真
        const mainItem = {
          icon_url: friend.photo_icon,
          large_url: friend.photo_large,
          photo_filter_brightness: friend.photo_filter_brightness,
        }
        // ギャラリー写真
        const galleryItems = await Api.getGalleryItems(friend.id)
        galleryItems.unshift(mainItem)
        setGalleryItems(galleryItems)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      }
    })()
  }, [])

  if (!friend) { return null }

  const galleryCols = [...Array(Const.maxGalleryItemCount).keys()]
  const toMessage = friend.to_message ? BaseComponent.textConvertMultiline(friend.to_message) : ''

  const createAvatar = () => (
    <>
      {simple ? (
        <Grid className={classes.avatarContainer}>
          <PhotoFilter brightness={photoBrightness}>
            <Avatar
              className={onlyAvatar ? classes.faceAvatarBig : classes.faceAvatar}
              src={friend.photo_icon}
            />
          </PhotoFilter>
        </Grid>
      ) : (
        <Grid item className={classes.imageWidth}>
          <PhotoFilter className={classes.imageContainer} brightness={photoBrightness} >
            <img
              className={photo_type === 'main' ? classes.imageMain : classes.imageGallery}
              src={photo}
            />
          </PhotoFilter>
        </Grid>
      )}
    </>
  )

  const openPhotoGallery = (index) => {
    const item = galleryItems[index]
    if (!item) { return }
    Api.addActionLog('look_large_photo', null, { friend_user_id: friend.id })
    const photo_type = index !== 0 ? 'gallery' : 'main'
    setSelectedGalleryIndex(index)
    setPhoto(item.large_url)
    setPhotoBrightness(item.photo_filter_brightness)
    setPhoto_type(photo_type)
  }

  const createGalleryItem = (index) => {
    if (index >= 6 && index > (galleryItems.length - 1)) { return null }
    return (
      <ImageListItem
        key={index}
        className={classes.imageListItem}
        classes={{ item: classes.imageListItem }}
        style={{ padding: 0, height: 'auto' }}
        onClick={() => openPhotoGallery(index)}
      >
        <div className={index === selectedGalleryIndex ? classes.imgContainerSelected : classes.imgContainer}>
          {index < galleryItems.length ? (
            <PhotoFilter brightness={galleryItems[index].photo_filter_brightness}>
              <img className={classes.imgGallery} src={galleryItems[index].icon_url} />
            </PhotoFilter>
          ) : (
            <div className={classes.imgGallery} />
          )}
        </div>
      </ImageListItem>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center">
        {createAvatar()}
        {(!simple && galleryItems.length > 0) && (
          <Grid item className={classes.gallery}>
            <ImageList className={classes.imageList} style={{ margin: 0 }} cols={6}>
              {galleryCols.map(index => createGalleryItem(index))}
            </ImageList>
          </Grid>
        )}
        {!simple && <MovieEventProfile friend={friend} />}
        {!simple ? (
          <Grid
            className={classes.nameContainer}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Typography className={classes.name}>{friend.nick_name}</Typography>
            <Typography className={classes.age}>{friend.age}歳</Typography>
            {isShowLikes && (
              <Typography className={classes.age}>
                <span className={classes.age}>好印象を貰った数：</span>
                {friend.likes}
              </Typography>
            )}
          </Grid>
        ) : (
          !onlyAvatar && (
            <Grid item className={classes.nameContainerSimple}>
              <Typography className={classes.nameSimple}>
                {friend.nick_name}
              </Typography>
            </Grid>
          )
        )}
        {(!simple && toMessage) && (
          <Grid item className={classes.messageContainer}>
            <Typography className={Array.isArray(toMessage) ? null : classes.message}>
              {toMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

CommonProfile.propTypes = {
  friend: PropTypes.object,
  simple: PropTypes.bool,
  onlyAvatar: PropTypes.bool,
}
