import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import TRUSTeMark from 'images/TRUSTe_certified_privacy_jp.png'

const Title = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  }
}))(Typography)

const Paragraph = withStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1),
  }
}))(Typography)

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: `calc(${theme.styles.header.height}px + ${theme.spacing(3)}px)`,
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
})

export class PrivacyStatement extends BaseComponent {
  componentDidMount () {
    this.addVisitPageLog()
  }

  componentWillUnmount () {
    this.addLeavePageLog()
  }

  // TRUSTeライセンシー確認ページを開く
  openTRUSTeLicenseePage = () => {
    const num = window.android ? '01576' : '01575'
    const url = `https://www.truste.or.jp/hssl/validate/sp/${num}.php`
    window.open(url)
  }

  render () {
    const { classes, ...other } = this.props
    return (
      <React.Fragment>
        <AppHeader title="プライバシーステートメント" backWhite={true} {...other} />
        <div className={classes.root}>
          <Paragraph>株式会社Aill（以下、「弊社」といいます。）は、弊社の提供するサービス（以下、「弊社サービス」といいます。）において、機密性の高い情報を扱うという事業特性を踏まえ、お客様から取得した情報を、以下のとおり取扱います。弊社は、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。</Paragraph>
          <Paragraph>
            <img src={TRUSTeMark} alt="TRUSTe認証プライバシー" onClick={this.openTRUSTeLicenseePage} />
            <br />
            弊社が運営する【Aill】（<a href="https://aill.ai/" target="_blank" rel="noreferrer">https://aill.ai/</a>、及びスマホアプリ）はTRUSTeプライバシー・プログラムのライセンス認証を受けております。このプライバシーステートメントは弊社をご利用いただくお客様に対して、弊社サービスのプライバシー保護の内容を開示しています。
          </Paragraph>
          <Paragraph>TRUSTeは、個人情報の適正な使用を促進することによって、ユーザーのインターネットに対する信用と信頼を築くために設立された組織です。弊社は、TRUSTeに個人情報の遵守状況を調査されることを同意しています。</Paragraph>
          <Paragraph>
            お客様が､プライバシーステートメントに掲示された内容が、守られていないと感ずる場合には、下記お問い合わせまでご連絡ください。内容を確認した後、適切な対応を取らせていただきます。<br />
            もしその際に、問合わせを受けた旨の通知を、弊社から受け取らなかった場合、またはお問い合わせに対し満足な回答を得られなかった場合には、TRUSTeにご連絡ください。その際TRUSTeは、ウェブサイトへの連絡役として、ウェブサイトご利用者の皆様方のお申し出を解決すべく活動します。
          </Paragraph>
          <Paragraph>
            <a href="https://www.truste.or.jp/hssl/watchdog/sp/" target="_blank" rel="noreferrer">
              Watchdog（オンライン紛争解決プログラム）ページ
            </a>
          </Paragraph>

          <Title>法令の遵守について</Title>
          <Paragraph>弊社は、個人情報の保護に関する法律その他の関連法令、国が定める指針及び本プライバシーポリシーを遵守します。</Paragraph>

          <Title>取得するお客様情報</Title>
          <Paragraph>弊社は、お客様へ弊社サービスを提供するにあたり、次の情報（以下「お客様情報」といいます）を取得いたします。なお、一般に公開された情報を除き、弊社は、お客様のご了解なく第三者からお客様の個人情報を取得することはございません。尚、お客様から提供いただいた情報に、他の情報源からの情報によって補足することはございません。</Paragraph>
          <Paragraph>
            (1)会員登録の際に必要となる情報<br />
            お客様の氏名、住所、生年月日、電話番号、緊急連絡先電話番号、E-Mailアドレス、職歴、学歴、写真、免許証、健康保険証等
          </Paragraph>
          <Paragraph>
            (2)弊社サービスの利用により取得する情報<br />
            お客様が弊社の提供するアプリ上に入力した情報（趣味、特技、自己紹介文など）、弊社のシステム上で判定する情報（交際ステータス情報、好感度情報など）、弊社サービスの中で他のお客様へ送付したメッセージに関する情報（メッセージ内容、メッセージ送付の日時など）等
          </Paragraph>
          <Paragraph>
            (3)支払に関する情報<br />
            お客様のクレジットカード情報等
          </Paragraph>

          <Title>お客様情報の利用目的について</Title>
          <Paragraph>取得したお客様情報の利用目的は、以下の通りであり、目的外での利用はいたしません。 取得したお客様情報や弊社サービスの中で他のお客様へ送付したメッセージに関する情報は所属会社に共有されることはありません。</Paragraph>
          <Paragraph component="div">
            (1)会員登録の際に必要となる情報
            <ul>
              <li>弊社サービスへの会員登録のため</li>
              <li>弊社サービスにおける個人認証のため</li>
              <li>登録情報の変更や削除のため</li>
              <li>弊社サービスに関するお知らせやご連絡のため</li>
              <li>OB・OG会員への成婚・結婚記念品や各種催し物等のご案内のため</li>
              <li>お客様からのお問い合わせへの対応のため</li>
              <li>不正利用が発生した場合などに本人確認や連絡を行うため</li>
            </ul>
          </Paragraph>
          <Paragraph component="div">
            (2)弊社サービスの利用により取得する情報
            <ul>
              <li>お客様が弊社サービスを円滑に利用できるようにするため</li>
              <li>弊社サービスにおいて最適なマッチング候補を抽出するため</li>
              <li>弊社サービスの利用に関する統計データを作成し、改善につなげるため</li>
              <li>弊社サービスの品質向上を目的としたアルゴリズムの開発のため</li>
              <li>マーケットリサーチ及び顧客満足度調査測定のため</li>
              <li>スパム行為や不正アクセス等、不正利用防止のため</li>
            </ul>
          </Paragraph>
          <Paragraph component="div">
            (3)支払に関する情報
            <ul>
              <li>お客様から弊社サービスの利用料金の支払を受けるため</li>
            </ul>
          </Paragraph>

          <Title>お客様情報の管理について</Title>
          <Paragraph>弊社は、お客様情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施しお客様情報の厳重な管理を行ないます。継続的に内外の環境の変化に対応し、万が一、問題等が発生した場合でも速やかに適切な対応をし、更なる改善を図ります。</Paragraph>

          <Title>お客様情報の正確性の確保について</Title>
          <Paragraph>弊社は、利用目的の達成に必要な範囲内において、お客様情報を、正確、かつ、最新の状態で管理します。お客様から変更届を受理した場合は、速やかにデータ変更処理を行い、正確かつ最新の内容に保つように努めます。</Paragraph>

          <Title>お客様情報を提供いただけなかった場合に生ずる結果について</Title>
          <Paragraph>お客様情報を弊社にご提供されるかどうかは、お客様ご自身の判断となります。なお、当該お客様情報をご提供いただけなかった場合には、所定の弊社サービスを提供できない場合があります。</Paragraph>

          <Title>お客様情報の第三者への提供</Title>
          <Paragraph>弊社は、お客様情報について、次の各号に定める場合を除き、お客様ご本人の同意を得ずに第三者に開示することは、いたしません。</Paragraph>
          <Paragraph>
            (1)法令に基づく場合<br />
            (2)人命、身体又は財産の保護のために必要であって、本人の同意を得ることが困難な場合<br />
            (3)公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難な又は場合<br />
            (4)国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
          </Paragraph>

          <Title>お客様情報の委託</Title>
          <Paragraph>弊社は、契約により守秘義務が課され、弊社と同様のセキュリティ管理が行われていることが確認された業務委託先に、利用目的の達成に必要範囲において取得したお客様情報の全部又は一部を委託することがあります。</Paragraph>

          <Title>匿名加工情報の提供について</Title>
          <Paragraph component="div">
            (1)匿名加工情報に含まれる個人に関する情報の項目
            <ul>
              <li>性別</li>
              <li>年代</li>
              <li>業種／職種</li>
              <li>居住都道府県</li>
              <li>サービス利用状況</li>
              <li>趣味・嗜好に関する情報</li>
            </ul>
          </Paragraph>
          <Paragraph component="div">
            (2)匿名加工情報の提供の方法
            <ul>
              <li>記録媒体による提供</li>
              <li>電子メールによる提供</li>
              <li>サーバーにアップロードする方法による提供</li>
            </ul>
          </Paragraph>

          <Title>お客様情報の開示･訂正･追加･削除･利用目的の通知等について</Title>
          <Paragraph>お客様は、ご自身のお客様情報に関して、弊社に対し、いつでもその内容を照会することができます。照会の結果、万一情報が誤っていた場合には、お客様は、弊社に対し、当該お客様情報の訂正、追加、削除、利用停止又は提供の拒否等の措置を、弊社のお問い合わせ窓口に問いあわせることにより、求めることができます。その場合、弊社は、お客様ご本人であることを確認させていただいた上で、合理的な期間内に迅速に対応いたします。 なお、お客様のマイページ内に記載されている情報（住所、電話番号等）の変更につきましては、マイページ内のプロフィール変更ページより届出を行ってください。</Paragraph>

          <Title>ログファイル・クッキー・ウェブビーコン等の技術的情報について</Title>
          <Paragraph>弊社サービスにおきまして、ログファイルの取得、クッキー（第三者サーバからのものを含む）、ウェブビーコンなどによりお客様のIPアドレス、アクセス回数、ご利用ブラウザ、OSその他利用端末などの情報を収集する場合がございます。アプリをご利用の場合、端末識別番号・広告識別番号等の情報も取得する場合がございます。クッキーはお客様がご利用のブラウザの設定によって受け入れを拒否することができますが、その場合には弊社サービスの一部がご利用頂けなくなる可能性があります。これらの情報は、弊社サービスの利用傾向の分析や問題発生時の原因究明を目的に収集しており、個人を特定する目的の利用はしておりません。 尚、第三者による情報収集モジュールの利用に関しましては、『情報収集モジュールによる情報の取得』をご確認ください。</Paragraph>

          <Title>情報収集モジュールによる情報の取得</Title>
          <Paragraph>弊社は、弊社サービスの向上又はお客様への適切な広告の配信のため、第三者による情報収集モジュールを利用することがあります。弊社が利用する情報収集モジュールは次の通りであり、当該情報収集モジュールについては、提供する第三者の提示する利用規約、利用条件等が適用されます。</Paragraph>
          <Paragraph>
            情報収集モジュールを用いたサービス： Aill goen（エール ゴエン）<br />
            情報収集モジュール： Googleアナリティクス<br />
            提供者： Google, Inc<br />
            利用規約： <a href="https://policies.google.com/terms?hl=ja" target="_blank" rel="noreferrer">https://policies.google.com/terms?hl=ja</a>
          </Paragraph>

          <Title>リンクについて</Title>
          <Paragraph>弊社サービスにおきまして、外部のサイトへのリンクが貼られることがあります。弊社はリンク先のウェブサイトにおける個人情報の取り扱いについては責任を負いかねますので、各リンク先のサイトのご利用に際してはそれぞれのプライバシーポリシーを一読される事を推奨します。</Paragraph>

          <Title>個人情報保護管理者について</Title>
          <Paragraph>弊社代表取締役社長は、個人情報保護管理者として、お客様情報を適切かつ安全に管理・保護する措置を講じます。</Paragraph>

          <Title>退会者の情報</Title>
          <Paragraph>お客様が弊社サービスを退会された場合、弊社は、当該お客様のお客様情報を速やかに削除いたします。ただし、お客様が希望された場合、弊社は、退会後もお客様情報を継続して保管します。この場合、お客様は、退会後、再度弊社サービスを利用する際に、前回のサービス利用時の情報を引き継ぐことができます。</Paragraph>

          <Title>本ポリシーの変更</Title>
          <Paragraph>弊社は、本ポリシーを変更することができます。ただし、重要な変更については、お客様に対して通知するものとします。また、お客様情報を当初の目的以外の目的で使用する場合や、第三者に提供する場合は、事前に同意を取得の上行います。</Paragraph>

          <Title>お問い合わせ</Title>
          <Paragraph>弊社のお客様情報の取扱に関するお問い合せはこちらまでご連絡ください。</Paragraph>
          <Paragraph>
            対応窓口: Aillサポート<br />
            連絡先: <a href="mailto:support@aill.ai">support@aill.ai</a><br />
            受付時間: 平日9:00~17:30
          </Paragraph>

          <Title>附則</Title>
          <Paragraph>
            令和1年10月1日制定<br />
            令和3年10月13日改訂
          </Paragraph>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(PrivacyStatement)
