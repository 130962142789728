import React, {useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppHeader from 'components/parts/AppHeader'
import SelfIntroduction from 'components/self-introduction/SelfIntroduction'
import SelfIntroductionTutorial from 'components/self-introduction/SelfIntroductionTutorial'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
  },
}))

export default function MessageEditBase (props) {
  const classes = useStyles()
  const [tutorial, setTutorial] = useState(false)

  return (
    <div className={classes.root}>
      <AppHeader title="自己紹介文" modeEdit backWhite hideHome {...props} />
      {!tutorial ? (
        <SelfIntroduction {...props} openTutorial={() => setTutorial(true)} />
      ) : (
        <SelfIntroductionTutorial {...props} closeTutorial={() => setTutorial(false)} />
      )}
    </div>
  )
}

MessageEditBase.propTypes = {
  updateUser: PropTypes.func,
  handleApiError: PropTypes.func,
}
