import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { calculateHourDiff } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import Img02 from 'images/friend_count_exceeded.png'
import Img01 from 'images/Komainu/friend_count_exceeded.png'

const useStyles = makeStyles(theme => ({
  img01: {
    display: 'block',
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  img02: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(3),
  },
  note: {
    marginTop: theme.spacing(2),
  },
  actions: {
    paddingBottom: theme.spacing(4),
  },
  button: {
    color: '#423BC7',
    backgroundColor: '#ffffff !important',
    border: '2px solid #423BC7',
    marginTop: `${theme.spacing(1.5)}px !important`,
    marginBottom: '0px !important',
  },
}))

export default function FriendCountExceededDialog (props) {
  const { open, onClose, friend, subscription } = props
  const classes = useStyles()

  if (!friend) { return null }
  if (!subscription) { return null }

  const usingStandard = BaseComponent.isUsingStandardPlan(props)

  const toProfile = () => {
    props.setFriend(friend)
    props.setScreen('Profile')
  }

  const toMembersSite = () => {
    window.open(Config.membersSiteUrl)
    onClose()
  }

  const toFriends = () => {
    props.setScreen('Friends')
    onClose()
  }

  const createTitle = () => {
    const text = usingStandard ? `${Const.maxFriendCountStandard}名` : ''
    return `トーク人数が\n上限${text}に達しています`
  }

  const createContents = () => {
    const expiredAt = friend?.pending_expired_at
    const hourDiff = expiredAt ? calculateHourDiff(new Date(expiredAt), new Date()) : 0
    return (
      <>
        {!usingStandard && <img className={classes.img02} src={Img02} width={230} height={142} />}
        <Typography className={classes.text} variant="body1">
          トーク中の方と友達解消でも<br />
          トーク可能になります。
        </Typography>
        {hourDiff >= 72 && <img className={classes.img01} src={Img01} width={164} height={83} />}
        {!usingStandard && (
          <Typography className={classes.note} variant="body2">
            ＊プラン変更はメンバーズサイトから可能です
          </Typography>
        )}
      </>
    )
  }

  const createButtons = () => {
    if (window.location.pathname === '/Friends') {
      return (
        <>
          <Button variant="contained" onClick={toProfile}>
            相手のプロフィールを見る
          </Button>
          {!usingStandard && (
            <Button className={classes.button} variant="contained" onClick={toMembersSite}>
              メンバーズサイトへ
            </Button>
          )}
        </>
      )
    } else if (window.location.pathname === '/Profile') {
      return (
        <>
          {usingStandard ? (
            <Button variant="contained" onClick={toFriends}>
              トーク一覧へ
            </Button>
          ) : (
            <Button variant="contained" onClick={toMembersSite}>
              メンバーズサイトへ
            </Button>
          )}
        </>
      )
    } else {
      return null
    }
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          {createTitle()}
        </DialogTitle>
        <DialogContent>
          {createContents()}
        </DialogContent>
        <DialogActions disableSpacing>
          {createButtons()}
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

FriendCountExceededDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  friend: PropTypes.object,
  subscription: PropTypes.object,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
}
