import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoDialog from 'components/parts/PhotoDialog'

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: '1px solid #ffffff',
    marginTop: theme.spacing(1.5),
  },
  friendInfoTitle: {
    fontSize: '12px !important',
    lineHeight: 1,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  friendInfoToMessage: {
    fontSize: '12px !important',
    fontWeight: '300 !important',
    textAlign: 'left',
  },
  friendInfoImages: {
    width: 222,
    margin: 'auto',
  },
  friendInfoImage: {
    objectFit: 'cover',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  friendInfoText: {
    fontSize: '12px !important',
    fontWeight: '300 !important',
    textAlign: 'left',
    maxWidth: 120,
    lineHeight: 1,
    marginBottom: theme.spacing(0.5),
  },
  friendInfoProfile: {
    width: 240,
    margin: 'auto',
  },
}))

export default function FriendInfo (props) {
  const { friend, master, category } = props
  const classes = useStyles()
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)
  const [imgUrl, setImgUrl] = useState(null)

  if (!friend || !master) { return null }

  const BasicProfileOrder = [
    'nick_name',
    'age',
    'nationality',
    'address1',
    'working_industry',
    'working_occupation',
    'job_transfer',
  ]
  const LoveProfileOrder = BaseComponent.getUserProfileDisplayKeys()

  const getLabelOf = (key) => {
    switch (key) {
      case 'age': return '年齢'
      case 'address1': return '住まい'
      default:
        return master?.profile[key].title
    }
  }

  const getItemLabel = (key) => {
    if (key === 'age') { return `${friend[key]}歳` }
    const detail = master.profile[key].detail
    if (Object.keys(detail).length === 0) { return friend[key] }
    const items = detail.common || detail[friend.sex] || []
    return items.find(item => item.value === friend[key])?.label || ''
  }

  return (
    <>
      {category === '写真について' ? (
        <div className={classes.root}>
          <Typography className={classes.friendInfoTitle}>お相手のプロフィール写真</Typography>
          <Grid className={classes.friendInfoImages} container>
            {friend.images.map((img, i) => (
              <img
                key={i}
                className={classes.friendInfoImage}
                src={img.icon_url}
                width={66}
                height={66}
                onClick={() => {
                  setImgUrl(img.icon_url)
                  setOpenPhotoDialog(true)
                }}
              />
            ))}
          </Grid>
          <PhotoDialog
            contentId={'Template'}
            open={openPhotoDialog}
            imageUrl={imgUrl}
            onClose={() => {
              setImgUrl(null)
              setOpenPhotoDialog(false)
            }}
          />
        </div>
      ) : category === '自己紹介文について' ? (
        <div className={classes.root}>
          <Typography className={classes.friendInfoTitle}>お相手の自己紹介文</Typography>
          <Typography className={classes.friendInfoToMessage}>{friend.to_message}</Typography>
        </div>
      ) : category === 'プロフィールについて' ? (
        <div className={classes.root}>
          <Typography className={classes.friendInfoTitle}>お相手のプロフィール</Typography>
          {[...BasicProfileOrder, ...LoveProfileOrder].map(key => {
            if (key === 'to_message') { return null }
            if (!friend[key]) { return null }
            return (
              <Grid key={key} className={classes.friendInfoProfile} container justifyContent="space-between" alignItems="flex-start">
                <Typography className={classes.friendInfoText}>
                  {getLabelOf(key)}
                </Typography>
                <Typography className={classes.friendInfoText}>
                  {getItemLabel(key)}
                </Typography>
              </Grid>
            )
          })}
        </div>
      ) : null}
    </>
  )
}

FriendInfo.propTypes = {
  friend: PropTypes.object,
  master: PropTypes.object,
  category: PropTypes.string,
}
