import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import PhotoFilter from 'components/parts/PhotoFilter'

const useStyles = makeStyles(theme => ({
  icon: {
    width: 56,
    height: 56,
    marginTop: theme.spacing(2),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  button: {
    color: '#423BC7',
    backgroundColor: '#ffffff !important',
    border: '2px solid #423BC7',
    marginTop: `${theme.spacing(1.5)}px !important`,
    marginBottom: '0px !important',
  },
}))

export default function EstablishFriendDialog (props) {
  const { open, onClose, friend } = props
  const classes = useStyles()

  if (!friend) { return null }

  const toProfile = () => {
    props.setFriend(friend)
    props.setScreen('Profile')
  }

  const startTalk = async () => {
    await BaseComponent.startTalk(props)
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          {friend.nick_name}さんと<br />
          トークを開始しますか？
        </DialogTitle>
        <DialogContent>
          <PhotoFilter brightness={friend.photo_filter_brightness}>
            <Avatar className={classes.icon} src={friend.photo_icon} />
          </PhotoFilter>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={startTalk}>
            トークを開始する
          </Button>
          <Button className={classes.button} variant="contained" onClick={toProfile}>
            相手のプロフィールを見る
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

EstablishFriendDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  friend: PropTypes.object,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
  setLoading: PropTypes.func,
}
