// 追加紹介オプションのキャンペーンページ
import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import {Grid, Box} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import * as Offer from 'components/special-offer/index'
import hrImg from 'images/special-offer/hr.png'
import image02Female from 'images/special-offer/image_02_female.png'
import image02Male from 'images/special-offer/image_02_male.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  relative: {
    position: 'relative',
  },
  amTitle: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: '20%',
    left: '50%',
  },
  incentive: {
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: '49%',
    left: '50%',
    '& span:nth-of-type(1)': {
      fontSize: 10.55,
      writingMode: 'vertical-rl',
      marginRight: theme.spacing(1),
    },
    '& span:nth-of-type(2)': {
      fontSize: 25.47,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
    '& span:nth-of-type(3)': {
      fontSize: 14,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        bottom: 4,
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  backButton: {
    position: 'fixed',
    top: theme.styles.header.height,
    left: 0,
    width: theme.spacing(8),
    height: theme.spacing(6.375),
    padding: 0,
    zIndex: '999'
  },
  backIcon: {
    color: theme.palette.secondary.main,
  },
  cover: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  hr: {
    width: '100%',
    backgroundImage: `url(${hrImg})`,
    backgroundSize: '100%',
    border: 0,
    margin: 0,
    height: 8
  },
  text: {
    color: offer => Offer.theme(offer).mainColor,
    fontWeight: 600,
  },
  marker: {
    background: 'linear-gradient(transparent 55%, #FFF24D 0%)',
    padding: '0 4px',
  },
  button: {
    width: 268,
    height: 44,
    backgroundColor: offer => Offer.theme(offer).mainColor,
    color: '#FFF',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    borderRadius: 22,
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: offer => Offer.theme(offer).mainColor,
      color: '#FFF',
    }
  },
  strongTxt: {
    fontSize: 20,
  },
  incentive2: {
    fontWeight: 600,
    letterSpacing: '1px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(-2),
    '& span:nth-of-type(1)': {
      fontSize: 14.71,
      writingMode: 'vertical-rl',
      marginRight: theme.spacing(1),
    },
    '& span:nth-of-type(2)': {
      fontSize: 42.07,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
    '& span:nth-of-type(3)': {
      fontSize: 19.52,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        bottom: 4,
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  invitaion: {
    fontSize: 15.41,
    fontWeight: 700,
    color: '#ffffff',
    letterSpacing: '0.75px',
    position: 'absolute',
    top: 36,
    left: 57,
    '& span': {
      fontSize: 11,
    },
  },
  price: {
    fontWeight: 600,
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 40,
    left: '73%',
    '& span:nth-of-type(1)': {
      fontSize: 32.623,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  count: {
    fontWeight: 600,
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 156,
    left: '50%',
    '& span:nth-of-type(1)': {
      fontSize: 10.51,
      writingMode: 'vertical-rl',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    '& span:nth-of-type(2)': {
      fontSize: 39.71,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
    '& span:nth-of-type(3)': {
      fontSize: 21.93,
      position: 'relative',
      marginLeft: theme.spacing(0.5),
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        bottom: 0,
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  card: {
    width: 338,
    height: 198,
    backgroundColor: '#FFFFFF',
    border: offer => `1px solid ${Offer.theme(offer).mainColor}`,
    borderRadius: '10px',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 60,
      height: 60,
      background: offer => `linear-gradient(to bottom right, ${Offer.theme(offer).mainColor} 50%, transparent 50%)`,
    },
  },
  cardIndex: {
    position: 'absolute',
    top: 8,
    left: 9,
    color: '#FFF',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    content: '"1"',
  },
  cardImg: {
    width: 140,
    height: 140,
    marginLeft: theme.spacing(2),
  },
  cardText: {
    color: offer => Offer.theme(offer).mainColor,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  count2: {
    fontWeight: 600,
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 80,
    left: '50%',
    '& span:nth-of-type(1)': {
      fontSize: 10,
      writingMode: 'vertical-rl',
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    '& span:nth-of-type(2)': {
      fontSize: 41.19,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
    '& span:nth-of-type(3)': {
      fontSize: 22.74,
      position: 'relative',
      marginLeft: theme.spacing(0.5),
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        bottom: 0,
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  count2Text: {
    fontSize: 12,
    lineHeight: 1,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: 156,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}))

export default function SpecialOffer (props) {
  const { user, campaign } = props
  const classes = useStyles(campaign)
  const soTheme = Offer.theme(campaign)

  if (!campaign) { return null }

  const toMembersSite = () => {
    window.open(Config.membersSiteUrl)
  }

  const textShadow = `
    2px 2px 2px ${soTheme.textShadowColor}, -2px -2px 1px ${soTheme.textShadowColor},
    -2px 2px 1px ${soTheme.textShadowColor}, 2px -2px 1px ${soTheme.textShadowColor},
    2px 0 1px ${soTheme.textShadowColor}, -2px  0 1px ${soTheme.textShadowColor},
    0 2px 1px ${soTheme.textShadowColor}, 0 -2px 1px ${soTheme.textShadowColor}
  `

  return (
    <Grid container className={classes.root} direction='column' alignItems='center'>
      <div className={classes.relative}>
        <img className={classes.cover} src={soTheme.topImg} alt='追加紹介オプションカバー画像'/>
        <Typography className={`${classes.amTitle} ${classes.marker}`} variant="body1" style={{ color: soTheme.mainColor }}>
          あなただけのスペシャルオファー
        </Typography>
        <Typography className={`${classes.incentive} ${classes.marker}`} variant="body1" style={{ color: soTheme.mainColor }}>
          <span>最大</span>
          <span data-text={3 * campaign.max_count} style={{ textShadow: textShadow }}>{3 * campaign.max_count}</span>
          <span data-text="人ご紹介" style={{ textShadow: textShadow }}>人ご紹介</span>
        </Typography>
      </div>
      <hr className={classes.hr}/>
      <Grid container style={{ marginTop: 16, marginBottom: 24 }} direction='column' alignItems='center'>
        <Typography className={classes.text} style={{ fontSize: 12, lineHeight: '20px' }}>キャンペーン期間</Typography>
        <Typography className={`${classes.text} ${classes.marker}`} style={{ fontSize: 16, lineHeight: '27px' }}>
          {dateFormat(campaign.end_at, 'yyyy/mm/dd HH:MM')} まで
        </Typography>
        <Button
          variant="contained"
          onClick={toMembersSite}
          className={classes.button}
        >
          メンバーズサイトで購入する
        </Button>
      </Grid>
      <hr className={classes.hr}/>
      <Grid container style={{ marginTop: 24, marginBottom: 48 }} direction='column' alignItems='center'>
        <Typography className={classes.text} style={{ fontSize: 14, lineHeight: '22.4px' }} align='center'>
          通常の紹介に加えて<br />
          あなただけのスペシャルオファーで、<br />
          1回{campaign.price}円で、3人ご紹介。<br/>
          あなたは<span className={classes.strongTxt}>{campaign.max_count}回</span>利用可能！
        </Typography>
        <Typography className={classes.incentive2} variant="body1" style={{ color: soTheme.mainColor }}>
          <span>最大</span>
          <span data-text={3 * campaign.max_count} style={{ textShadow: textShadow }}>{3 * campaign.max_count}</span>
          <span data-text="人ご紹介" style={{ textShadow: textShadow }}>人ご紹介</span>
        </Typography>
        <div className={classes.relative}>
          <img src={soTheme.subImg} width={268} alt='' style={{ display: 'block', marginTop: 24 }}/>
          <Typography className={classes.invitaion} variant="body1">
            3人<span>紹介</span>
          </Typography>
          <Typography className={classes.price} variant="body1" style={{ color: soTheme.mainColor }}>
            <span data-text={campaign.price} style={{ textShadow: textShadow }}>{campaign.price}</span>
          </Typography>
          <Typography className={classes.count} variant="body1" style={{ color: soTheme.mainColor }}>
            <span>最大</span>
            <span data-text={campaign.max_count} style={{ textShadow: textShadow }}>{campaign.max_count}</span>
            <span data-text="回" style={{ textShadow: textShadow }}>回</span>
          </Typography>
        </div>
      </Grid>
      <hr className={classes.hr}/>
      <Grid container style={{ marginTop: 48, marginBottom: 48 }} direction='column' alignItems='center'>
        <Typography className={`${classes.text} ${classes.marker}`} style={{ fontSize: 20, lineHeight: '24px' }}>どんな人が紹介される？</Typography>
        <Typography className={classes.text} style={{ fontSize: 14, lineHeight: '22.4px', marginTop: 21 }} align='center'>
          相性70%以上の異性を<br/>１回3人ご紹介！
        </Typography>
        <img src={user.sex === 'male' ? image02Male : image02Female} alt="どんな人が紹介される？画像" width={225} height={180} style={{ marginTop: 24 }}/>
      </Grid>
      <hr className={classes.hr}/>
      <Grid container style={{ paddingTop: 48, paddingBottom: 48, background: soTheme.subColor }} direction='column' alignItems='center'>
        <Typography className={`${classes.text} ${classes.marker}`} style={{ fontSize: 20, lineHeight: '24px' }}>利用方法</Typography>
        <Typography className={classes.text} style={{ fontSize: 14, lineHeight: '24px', marginTop: 21 }}>下記の手順で紹介を受けることができるよ！</Typography>
        <Grid container style={{ marginTop: 24, gap: 16}} direction='column' alignItems='center'>
          <Box className={classes.card}>
            <span className={classes.cardIndex}>1</span>
            <img className={classes.cardImg} src={soTheme.howTo01Img} alt='利用方法1画像' />
            <Typography className={classes.cardText}>メンバーズサイトへ<br />アクセス</Typography>
          </Box>
          <Box className={classes.card}>
            <span className={classes.cardIndex}>2</span>
            <img className={classes.cardImg} src={soTheme.howTo02Img} alt="利用方法2画像"/>
            <Typography className={classes.cardText}>「オプション購入」の<br />「追加紹介」より<br />「3人紹介」を購入</Typography>
          </Box>
          <Box className={classes.card}>
            <span className={classes.cardIndex}>3</span>
            <img className={classes.cardImg} src={soTheme.howTo03Img} alt="利用方法3画像"/>
            <Typography className={classes.cardText}>購入完了後<br />アプリに戻り<br />紹介画面を確認</Typography>
          </Box>
        </Grid>
      </Grid>
      <hr className={classes.hr}/>
      <Grid container style={{ marginTop: 36, marginBottom: 90 }} direction='column' alignItems='center'>
        <Typography className={`${classes.text} ${classes.marker}`} style={{ fontSize: 20, lineHeight: '24px' }}>利用回数</Typography>
        <Typography className={classes.text} style={{ fontSize: 14, lineHeight: '22.4px', marginTop: 21 }} align='center'>
          期間中に最大{campaign.max_count}回利用できるよ！<br />最新の残り回数は<br />メンバーズサイトで確認してね。
        </Typography>
        <div className={classes.relative}>
          <img src={soTheme.bottomImg} width={338} alt='利用回数画像' style={{ display: 'block', marginTop: 24 }}/>
          <Typography className={classes.count2} variant="body1" style={{ color: soTheme.mainColor }}>
            <span>最大</span>
            <span data-text={campaign.max_count} style={{ textShadow: textShadow }}>{campaign.max_count}</span>
            <span data-text="回" style={{ textShadow: textShadow }}>回</span>
          </Typography>
          <Typography className={`${classes.text} ${classes.count2Text}`}>
            ※1回3人×{campaign.max_count}回で最大{3 * campaign.max_count}人を追加紹介！
          </Typography>
        </div>
        <Button
          variant="contained"
          onClick={toMembersSite}
          className={classes.button}
          style={{ marginTop: 40 }}
        >
          メンバーズサイトで購入する
        </Button>
      </Grid>
    </Grid>
  )
}

SpecialOffer.propTypes = {
  user: PropTypes.object,
  campaign: PropTypes.object,
}