import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class PhotoIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 480 410" preserveAspectRatio="xMidYMid meet">
          <g>
            <path d="M17 399 c-15 -9 -17 -27 -17 -153 0 -113 3 -146 15 -156 22 -18 426 -20 448 -1 14 11 17 35 17 155 0 97 -4 146 -12 154 -15 15 -424 16 -451 1z m285 -96 c12 -11 18 -30 18 -59 0 -34 -5 -47 -26 -63 -55 -43 -134 -11 -134 55 0 44 15 72 43 84 29 12 76 4 99 -17z"/>
            <path d="M55 61 c-3 -5 0 -21 6 -35 11 -24 18 -26 69 -26 52 0 58 2 69 27 10 21 9 28 -1 34 -18 12 -135 11 -143 0z"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(PhotoIcon)