import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  badge: {
    width: 63,
    height: 63,
    borderRadius: '50%',
  },
  grey: {
    background: '#9D9D9E',
  },
  pink: {
    background: '#F55083',
  },
  badgeText: {
    fontWeight: 600,
    color: '#ffffff',
    lineHeight: 1,
  },
}))

export default function BoostBadge (props) {
  const { plus, subText, disabled } = props
  const classes = useStyles()

  return (
    <Grid
      className={`${classes.badge} ${disabled ? classes.grey :classes.pink}`}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Typography className={classes.badgeText}>
        {plus && <span style={{ fontSize: 18 }}>+</span>}
        <span style={{ fontSize: 28 }}>1</span>
        <span style={{ fontSize: 12 }}>名</span><br />
        <span style={{ fontSize: 8 }}>{subText}</span>
      </Typography>
    </Grid>
  )
}

BoostBadge.propTypes = {
  plus: PropTypes.bool,
  subText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}
