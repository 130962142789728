import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Api from 'commons/api'
import Storage from 'commons/storage'
import { getUrlParam } from 'commons/utility'
import Contact from 'components/entry/Contact'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import SelectCompanyDialog from 'components/entry/SelectCompanyDialog'
import SnsInfo from 'components/entry/SnsInfo'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: theme.palette.primary.main,
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
    paddingTop: sp => theme.spacing(sp ? 4.5 : 8.25),
  },
  title: {
    fontSize: sp => sp ? 19 : 32,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.9px' : '3.2px',
    textAlign: 'center',
  },
  titleBar: {
    height: sp => sp ? 2 : 4,
    width: sp => sp ? 145 : 234,
    background: '#616AFA',
    margin: 'auto',
    marginTop: sp => theme.spacing(sp ? 1.5 : 2),
  },
  searchCompany: {
    paddingTop: sp => theme.spacing(sp ? 3.25 : 5.25),
    paddingBottom: sp => theme.spacing(sp ? 4 : 9.75),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  subtitle: {
    fontSize: sp => sp ? 14 : 28,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : 1,
    letterSpacing: sp => sp ? '1.4px' : '2.8px',
    textAlign: 'center',
  },
  text: {
    fontSize: sp => sp ? 12 : 16,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : '26px',
    letterSpacing: sp => sp ? '1.2px' : '1.6px',
    textAlign: sp => sp ? 'left' : 'center',
    marginTop: sp => theme.spacing(sp ? 5 : 3.75),
  },
  textField: {
    marginTop: sp => theme.spacing(sp ? 2 : 6.25),
    marginBottom: sp => theme.spacing(sp ? 4 : 7),
    '& .Mui-error': {
      '& input': {
        border: '2px solid red',
      }
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        width: sp => sp ? 297 : 485,
        height: 50,
        color: '#433CC7',
        '&::placeholder': {
          color: '#DBDCFE'
        },
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '1.6px',
        boxSizing: 'border-box',
        border: '2px solid #423BC7',
        borderRadius: 10,
        paddingTop: 17,
        paddingRight: 19,
        paddingBottom: 17,
        paddingLeft: 19,
      },
      '& fieldset': {
        display: 'none'
      },
    },
  },
  button: {
    color: '#ffffff',
    background: '#423BC7',
    width: spMatches => spMatches ? 243 : 392,
    height: spMatches => spMatches ? 49 : 79,
    fontSize: spMatches => spMatches ? 14 : 24,
    fontWeight: 600,
    letterSpacing: sp => sp ? '1.4px' : '2.4px',
    borderRadius: 50,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#423BC7',
    },
    '&.Mui-disabled': {
      color: '#ffffff',
      backgroundColor: '#E0E0E0'
    },
  },
}))

export default function SearchCompany (props) {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)
  const [searchWord, setSearchWord] = useState('')
  const [searchWordError, setSearchWordError] = useState(null)
  const [companies, setCompanies] = useState([])
  const [openSelectCompany, setOpenSelectCompany] = useState(false)

  useEffect(() => {
    const campaignCode = getUrlParam('campaign_code')
    if (campaignCode) {
      Storage.invitationCampaignCode.value = campaignCode
    }

    const wa = getUrlParam('wa')
    if (wa) {
      Storage.welfareAccountId.value = wa
    }

    if (getUrlParam('source')) {
      const utmParams = {
        utm_source: getUrlParam('source'),
        utm_medium: getUrlParam('medium'),
        utm_campaign: getUrlParam('campaign'),
        utm_term: getUrlParam('term'),
        utm_content: getUrlParam('content'),
      }
      Storage.utmPrams.value = utmParams
    }

    const region = getUrlParam('region')
    if (region) {
      Storage.region.value = region
    } else {
      Storage.region.clear()
    }
  }, [])

  const onChangeSearchWord = (e) => {
    setSearchWord(e.target.value)
    setSearchWordError(null)
  }

  const onSearchCompany = async () => {
    if (!searchWord) {
      setSearchWordError('会社名を入力してください。')
      return
    }

    props.setLoading(true)
    try {
      const res = await Api.searchCompany(searchWord, { include_not_started: true })
      if (res.length === 0) {
        props.setCompany({ name: searchWord, organization_id: 'none' })
        props.setScreen('SearchCompanyResult')
      } else {
        setCompanies(res)
        setOpenSelectCompany(true)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <EntryHeader />
      <div className={classes.body}>
        <Typography className={classes.title}>SEARCH</Typography>
        <div className={classes.titleBar} />
        <div className={classes.searchCompany}>
          <Typography className={classes.subtitle}>勤務先が対象企業か検索する</Typography>
          <Typography className={classes.text}>
            ご利用には勤務先のAill goen加盟が必要になります<br />
            <br />
            ＊Aill goenは導入いただいた企業の独身社員専用のサービスです。<br />
            ＊記入いただいた会社名は本検索以外には使用しませんのでご安心ください。
          </Typography>
          <Grid container direction="column" alignItems="center">
            <TextField
              className={classes.textField}
              id="searchWord"
              type="text"
              variant="outlined"
              placeholder="あなたの所属する会社名"
              error={Boolean(searchWordError)}
              helperText={searchWordError}
              onChange={onChangeSearchWord}
            />
            <Button
              className={classes.button}
              variant="outlined"
              disabled={searchWord.length === 0}
              onClick={onSearchCompany}>
              検索する
            </Button>
          </Grid>
        </div>
        <SnsInfo />
        <Contact />
      </div>
      <EntryFooter />
      <SelectCompanyDialog
        {...props}
        open={openSelectCompany}
        companies={companies}
        searchWord={searchWord}
        onClose={() => setOpenSelectCompany(false)}
      />
    </>
  )
}

SearchCompany.propTypes = {
  setLoading: PropTypes.func,
  setCompany: PropTypes.func,
  setScreen: PropTypes.func,
}
