import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import BaseComponent from 'components/parts/BaseComponent'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import BackgroundImage from 'images/img_secret_message.png'

const useStyles = makeStyles(theme => ({
  secretContainer: {
    width: 338,
    minHeight: 176,
    ...theme.styles.secretRoomColor,
    margin: 'auto',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(3),
    position: 'relative',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  subTitle: {
    marginTop: theme.spacing(1.5),
    fontSize: 20,
    fontWeight: 'bold',
    color: '#555555',
    textAlign: 'center',
    maxWidth: 240,
    lineHeight: '24px',
  },
  text: {
    marginTop: theme.spacing(2),
    fontSize: 12,
    fontWeight: 'bold',
    color: '#555555',
    textAlign: 'center',
    maxWidth: 240,
  },
  button: {
    fontSize: 14,
    fontWeight: 'bold',
    width: 230,
    height: theme.spacing(5),
    backgroundColor: '#616AFA',
    color: theme.palette.secondary.contrastText,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  imageA: {
    position: 'absolute',
    top: 40,
    right: 20,
    width: 30,
    transform: 'rotate(-15deg)',
  },
  imageUn: {
    position: 'absolute',
    top: 104,
    left: 12,
    width: 37,
    transform: 'rotate(15deg)',
  },
}))

export default function FoodReportListItem (props) {
  const { foodReport, onClick } = props
  const [shop, setShop] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    if (!foodReport.shop_id) { return }
    if (shop) { return }

    (async () => {
      try {
        const s = await Api.getShop(foodReport.shop_id)
        setShop(s)
      } catch (e) {
        BaseComponent.handleApiError(props, e)
      }
    })()
  }, [])

  return (
    <ListItem>
      <div className={classes.secretContainer} key={foodReport.id}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography className={classes.title}>
            {dateFormat(foodReport.created_at, 'yyyy年m月d日')}
          </Typography>
          <Typography className={classes.subTitle}>
            {shop ? shop.shop_name : '-'}
          </Typography>
          <Typography className={classes.text}>
            {props.friend.nick_name}さんと参加
          </Typography>
          <Button variant="contained" className={classes.button} onClick={() => onClick && onClick(foodReport)}>
            送信した食レポを見る
          </Button>
        </Grid>
        <img src={AImage} className={classes.imageA} alt="" />
        <img src={UnImage} className={classes.imageUn} alt="" />
      </div>
    </ListItem>
  )
}

FoodReportListItem.propTypes = {
  foodReport: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  friend: PropTypes.object,
}
