import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EntrySteps from 'components/entry/EntrySteps'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoFilter from 'components/parts/PhotoFilter'
import PhotoFilterEdit from 'components/parts/PhotoFilterEdit'
import 'cropperjs/dist/cropper.css'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 700,
    margin: 'auto',
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#FF0000',
    opacity: 0.54,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  entry: {
    fontSize: 16,
    fontWeight: 600,
    width: 230,
    height: 44,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  back: {
    fontSize: 12,
    fontWeight: 600,
    width: 160,
    height: 42,
    textAlign: 'center',
    flexBasis: 'auto',
  },
  cropper: {
    width: '100%',
    maxHeight: 280,
    maxWidth: 530,
    '& .cropper-container': {
      maxWidth: window.innerWidth,
    },
    '& img': {
      maxWidth: window.innerWidth,
    },
  },
}))

PhotoConfirm.propTypes = {
  userImage: PropTypes.object,
  history: PropTypes.object,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}

export default function PhotoConfirm (props) {
  const { userImage, history } = props
  const classes = useStyles()
  const [imageSrc ,setImageSrc] = useState(null)
  const [brightness, setBrightness] = useState(100)
  const [allowAutoPhotoAdjustment, setAllowAutoPhotoAdjustment] = useState(true)
  const cropperRef = useRef(null)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
    }
  }, [])

  useEffect(() => {
    if (!userImage) { return }
    const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
    setImageSrc(createObjectURL(userImage))
  }, [userImage])

  const mimeType = () => {
    return userImage ? userImage.type : 'image/png'
  }

  const croppedFileName = () => {
    return userImage ? `cropped_${userImage.name}` : 'cropped_photo.png'
  }

  const save = () => {
    if (!cropperRef?.current) { return }
    const cropper = cropperRef.current.cropper
    const canvas = cropper.getCroppedCanvas({
      minWidth: 256,
      minHeight: 256,
      maxWidth: 4096,
      maxHeight: 4096,
    })
    props.setLoading(true)
    try {
      // Jestで処理を行わないため
      if (process.env.NODE_ENV === 'test') {
        const dummyfile = {}
        upload(dummyfile)
      }
      if (canvas.toBlob) {
        const quality = 1
        canvas.toBlob(upload, mimeType(), quality)
      } else if (canvas.msToBlob) {
        upload(canvas.msToBlob())
      }
    } catch (error) {
      BaseComponent.handleError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const upload = async (blob) => {
    props.setLoading(true)
    try {
      const fileName = croppedFileName()
      const opts = { type: mimeType() }
      const file = new File([blob], fileName, opts)
      await BaseComponent.updateUser(props, { allow_auto_photo_adjustment: allowAutoPhotoAdjustment })
      const user = await BaseComponent.updatePhoto(props, file, { brightness })

      BaseComponent.toEntryNextStep(props, user)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <AppHeader title="写真登録" hideBack {...props} />
      <EntrySteps step={2} titles={['基本情報', '恋愛情報', '写真登録', '確認書類', '入会審査']} />
      <Typography className={classes.subTitle}>
        この写真を設定しますか？
      </Typography>
      <Grid container direction='column' alignItems="center" justifyContent="center">
        <PhotoFilter brightness={brightness}>
          <Cropper
            className={classes.cropper}
            viewMode={1}
            aspectRatio={1 / 1}
            preview=".img-preview"
            guides={false}
            src={imageSrc}
            ref={cropperRef}
          />
        </PhotoFilter>
        <PhotoFilterEdit brightness={brightness} onChangeBrightness={(value) => setBrightness(value)} />
        <FormControlLabel
          label="画像の自動調整を許可する"
          control={
            <Checkbox
              checked={allowAutoPhotoAdjustment}
              onChange={(_, value) => setAllowAutoPhotoAdjustment(value)}
            />
          }
        />
        <Button
          className={classes.entry}
          variant="contained"
          color="secondary"
          onClick={save}
        >
          保存する
        </Button>
        <Button
          className={classes.back}
          variant="text"
          color="secondary"
          onClick={() => history.goBack()}
        >
          写真を選択し直す
        </Button>
      </Grid>
    </div>
  )
}
