import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import TrialPlanBox from 'components/parts/TrialPlanBox'

const useStyles = makeStyles(theme => ({
  contentRoot: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: theme.spacing(2),
  },
  notice: {
    fontSize: 12,
    fontWeight: 600,
  },
}))

export default function ConfirmFewUsersDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()
  return (
    <DialogThemeProvider  color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={() => onClose && onClose()} />
        <DialogTitle>
          &lt;&lt;注意事項&gt;&gt;
        </DialogTitle>
        <DialogContent className={classes.contentRoot}>
          <Typography className={classes.text}>
            ご紹介できる異性の人数が少なくなる可能性があります。<br />
            予め、ご了承をお願いいたします。<br />
            無料でご利用いただける「フリープラン」もございます。
          </Typography>
          <TrialPlanBox />
          <Typography className={classes.notice}>
            ＊ご利用プランは、ご入会後にメンバーズサイトよりご変更いただけます。
          </Typography>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

ConfirmFewUsersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}