import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

export default function IdDescription (props) {
  const { myOrganization, idText, affiliationText } = props
  const classes = useStyles()

  if (!myOrganization) { return null }
  if (!idText) { return null }

  const label = myOrganization.burden_label.includes('企業') ? '会社' : '組織'

  return (
    <Typography className={classes.root}>
      厳格な本人確認で安心安全！<br />
      {affiliationText && <>
        1.&nbsp;{idText.label}で本人確認<br />
        2.&nbsp;{affiliationText.label}で所属{label}を確認<br />
        3.&nbsp;クレジットカード与信で本人認証を実施
      </>}
      {!affiliationText && <>
        1.&nbsp;{idText.label}で本人確認と所属{label}を確認<br />
        2.&nbsp;クレジットカード与信で本人認証を実施
      </>}
    </Typography>
  )
}

IdDescription.propTypes = {
  myOrganization: PropTypes.object,
  idText: PropTypes.object,
  affiliationText: PropTypes.object,
}
