import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import InterestedSelectDialog from 'components/interested-things/InterestedSelectDialog'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'
import DeleteImg from 'images/icon_delete.png'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.styles.header.height,
  },
  container: {
    paddingTop: theme.spacing(3.75),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(4.5),
  },
  item: {
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.3px',
    color: '#423BC7',
    background: '#DBDCFE',
    borderRadius: 21,
    paddingTop: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    position: 'relative',
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  delete: {
    position: 'absolute',
    top: -4,
    right: -4,
    zIndex: 1,
  },
  add: {
    width: 230,
    height: 40,
    fontSize: 14,
    background: '#616AFA',
    display: 'block',
    margin: 'auto',
    '&:hover': {
      background: '#616AFA',
    }
  },
  save: {
    width: 230,
    height: 44,
    position: 'fixed',
    left: '50%',
    bottom: 36,
    transform: 'translate(-50%, 0%)'
  },
}))

export default function InterestedThings (props) {
  const { user } = props
  const classes = useStyles()
  const [selectedItems, setSelectedItems] = useState([])
  const [openInterestedSelectDialog, setOpenInterestedSelectDialog] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const intervalTimerRef = useRef(null)

  useEffect(() => {
    if (!user) { return }
    if ((user?.interested_things || []).length === 0) {
      setOpenInterestedSelectDialog(true)
    } else {
      setSelectedItems(user.interested_things)
    }
  }, [])

  const createChip = (label) => {
    return (
      <div
        key={label}
        className={classes.item}
        onContextMenu={e => e.preventDefault()}
        onTouchStart={() => {
          if (editMode) { return }
          intervalTimerRef.current = setTimeout(() => {
            if (!editMode) { setEditMode(true) }
          }, 1000)
        }}
        onTouchEnd={() => {
          clearTimeout(intervalTimerRef.current)
          intervalTimerRef.current = null
        }}
      >
        {editMode && <img
          className={classes.delete}
          src={DeleteImg}
          width={16}
          height={16}
          onClick={() => setSelectedItems(selectedItems.filter(item => (item !== label)))}
        />}
        {label}
      </div>
    )
  }

  const save = async () => {
    props.setLoading(true)
    try {
      await BaseComponent.updateUser(props, { interested_things: selectedItems })
      BaseComponent.goBack(props)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(true)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <AppHeader title="興味のあること" backWhite hideHome {...props} />
        <Grid className={classes.container} container data-testid="selected-items">
          {selectedItems.map(label => createChip(label))}
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          className={classes.add}
          onClick={() => setOpenInterestedSelectDialog(true)}
          data-testid="add-button"
        >
          興味のタグを追加する
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.save}
          onClick={save}
          data-testid="save-button"
        >
          登録する
        </Button>
      </div>
      <InterestedSelectDialog
        {...props}
        open={openInterestedSelectDialog}
        onClose={() => setOpenInterestedSelectDialog(false)}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </>
  )
}

InterestedThings.propTypes = {
  user: PropTypes.object,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
