import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import PersonIcon from '@material-ui/icons/Person'
import Api from 'commons/api'
import Const from 'commons/constant'
import {
  expiredUrl,
  httpNotFound,
  httpNotModified,
  calculateHourDiff,
  calculateMinuteDiff,
  calculateRemainingDays,
} from 'commons/utility'
import NaviPopover from 'components/first-navigation/NaviPopover'
import NaviPopper from 'components/first-navigation/NaviPopper'
import MovieBoostNavi from 'components/movie/MovieBoostNavi'
import BaseComponent from 'components/parts/BaseComponent'
import FirstBoostNavi from 'components/parts/FirstBoostNavi'
import KomaNavi from 'components/parts/KomaNavi'
import PhotoFilter from 'components/parts/PhotoFilter'
import SpecialOfferNavi from 'components/special-offer/SpecialOfferNavi'
import ValentineEventBoostNavi from 'components/valentine-event/ValentineEventBoostNavi'
import WinterEventBoostNavi from 'components/winter-event/WinterEventBoostNavi'
import 'react-circular-progressbar/dist/styles.css'
import HeartIcon from 'images/icon_heart.png'

const useStyles = makeStyles(theme => ({
  faceAvatar: {
    width: 56,
    height: 56,
  },
  name: {
    fontWeight: 600,
    overflow: 'hidden',
    fontSize: 16,
    lineHeight: 1,
    maxHeight: 56,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
    marginRight: theme.spacing(1),
  },
  age: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },
  toMessage: {
    width: '100%',
    fontSize: 12.5,
    fontWeight: 300,
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.25),
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
    whiteSpace: 'pre-wrap',
  },
  matchingTitle: {
    color: theme.palette.secondary.main,
    ...theme.styles.highlightColor,
    fontSize: 8,
    fontWeight: 600,
    lineHeight: '10px',
    marginTop: theme.spacing(0.5),
  },
  matching: {
    color: theme.palette.secondary.main,
    display: 'inline',
    fontSize: 24,
    fontWeight: 'bold',
    width: 60,
    height: 60,
  },
  matchingText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    lineHeight: 1,
    fontFamily: '"ヒラギノ角ゴシック"',
  },
  matchingTextSmall: {
    fontSize: 8,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    lineHeight: 1,
    marginLeft: theme.spacing(0.25),
  },
  badge: {
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 600,
    position: 'absolute',
    bottom: -11,
    right: 16,
    lineHeight: 1,
    paddingTop: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.main,
    borderRadius: 10,
    zIndex: 2,
  },
  applying: {
    background: '#616AFA',
  },
  remain: {
    ...theme.styles.remainBackgroundColor,
  },
  cell: {
    borderStyle: 'none',
    paddingTop: 0,
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  card: {
    borderRadius: 10,
    position: 'relative',
  },
  applyingFilter: {
    width: '100%',
    height: '100%',
    background: '#DBDCFE',
    opacity: 0.42,
    position: 'absolute',
    zIndex: 1,
  },
  nameContainer: {
    width: '100%',
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
    marginTop: theme.spacing(1.5),
  },
  cellContainer: {
    width: '100%',
    background: theme.palette.primary.main,
  },
  matchingProfileContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  matchingContainer: {
    width: 60,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  rightContainer: {
    width: 'calc(100% - 130px)',
    height: 130,
    flexGrow: 1,
    backgroundColor: '#EFF0FF',
    position: 'relative',
  },
  emptySquare: {
    width: 130,
    height: 130,
    background: '#ffffff',
    textAlign: 'center',
  },
  scoreContainer: {
    maxWidth: 79,
    minWidth: 79,
    '& img': {
      display: 'block',
    }
  },
  profileContainer: {
    width: '100%',
  },
  profileTitle: {
    ...theme.styles.highlightColor,
    display: 'inline-block',
  },
  profile: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 11,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '1',
  },
  withdrawnFilter: {
    width: '100%',
    height: '100%',
    background: 'rgba(198, 198, 198, 0.32)', // #C6C6C6
    position: 'absolute',
    zIndex: 1,
  },
  withdrawnText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1,
    color:'#707070',
    border: '1.5px solid #707070',
    borderRadius: 10,
    padding: theme.spacing(1.25),
  },
  withdrawnIcon: {
    width: '100%',
    height: '100%',
    color: '#F5F5F5',
    backgroundColor: '#E8E8E8',
  },
  withdrawnRightContainer: {
    width: 'calc(100% - 130px)',
    height: 130,
    flexGrow: 1,
    backgroundColor: '#F5F5F5',
    position: 'relative',
  },
  withdrawnBottomContainer: {
    minHeight: 63,
  },
  image: {
    width: 130,
    height: 130,
    objectFit: 'cover',
    backgroundColor: theme.palette.primary.main,
  },
  fav: {
    marginBottom: theme.spacing(0.3),
    paddingRight: theme.spacing(1),
    fontSize: 11,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  favIcon: {
    color: '#636AF1',
    fontSize: 12,
  },
  newBadge: {
    width: 60,
    height: 60,
    backgroundImage: 'linear-gradient(to left top, transparent 50%, #F55083 50%)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  newBadgeText: {
    fontSize: 10,
    fontWeight: 600,
    color: '#ffffff',
    transform: 'rotate(-45deg)',
    transformOrigin: '50% 50%',
  },
  requestMessage: {
    width: '100%',
    fontSize: 12,
    fontWeight: 300,
    ...theme.styles.messageColor,
    background: '#EFF0FF',
    borderRadius: 10,
    paddingTop: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '& strong': {
      fontWeight: 600,
      lineHeight: 1,
      marginBottom: theme.spacing(1.5),
      display: 'inline-block',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      position: 'relative',
      top: -1.5,
    },
    '& span': {
      maxHeight: 53,
      overflow: 'hidden',
      display: '-webkit-box',
      'text-overflow': 'ellipsis',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '3',
      whiteSpace: 'pre-wrap',
    },
  },
  buttonApproveContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  buttonApprove: {
    ...theme.styles.entryButton,
    fontWeight: 600,
    width: 268,
    height: 44,
  },
}))

const heightMap = {
  under150cm: 149,
  over150cm: 150,
  over155cm: 155,
  over160cm: 160,
  over165cm: 165,
  over170cm: 170,
  over175cm: 175,
  over180cm: 180,
  over185cm: 185,
}

const annualIncomeMap = {
  under300: 300,
  range400: 400,
  range500: 500,
  range600: 600,
  range700: 700,
  range800: 800,
  range900: 900,
  range1000: 1000,
  over1100: 1100,
}

export default function FriendCell (props) {
  const {
    user, master, index, friendsNum, subscription,
    tutorial, displayProfileItems, readIds,
    tutorialReadStates, tutorialView, friendRequestPage,
  } = props
  const classes = useStyles()
  const [friend, setFriend] = useState(null)
  const photourl = props.friend?.photo_icon || ''
  const expiredPhotoUrl = tutorial ? photourl : expiredUrl(photourl) ? null : photourl
  const [photoUrl, setPhotoUrl] = useState(expiredPhotoUrl)
  const [galleryItems, setGalleryItems] = useState([])
  const [dailyRemainingTime, setDailyRemainingTime] = useState(null)
  const [requestRemainingTime, setRequestRemainingTime] = useState(null)
  const naviTargetRef = useRef(null)
  const [displayItems, setDisplayItems] = useState([])
  const isShowLikes = (user.sex === 'female' && friend?.likes >= 10)
  const firstBoostEnabled = BaseComponent.isFirstMatchingBoostEnabled(props)
  const isFirstBoostFriend = friend?.boost_type === 'first_boost'
  const isMovieEventBoostFriend = friend?.boost_type === 'movie_event'
  const isWinterEventBoostFriend = friend?.boost_type === 'winter_event'
  const isValentineEventBoostFriend = friend?.boost_type === 'valentine_event'
  const isXmasBoostFriend = friend?.boost_type === 'xmas'
  const isApplyingFriend = friend?.type === 'friend_applying'
  const isAdditionalMatchingFriend = friend?.boost_type === 'additional_matching'
  // 退会済みフラグ
  const isWithdrawnFriend = friend?.withdrawn
  // 自分がベーシックプラン
  const myPlanIsBasic = (subscription?.content === Const.planTypes.BASIC)

  // loadFriend(): 詳細情報取得
  useEffect(() => {
    if (!props.friend) { return }
    if (tutorial) {
      setFriend(props.friend)
      return
    }
    loadFriend()
  }, [props.friend])

  // setDRT(): 残り表示テキスト生成
  useEffect(() => {
    if (!friend) { return }
    setDRT()
    setRRT()
    loadGalleryItems()
  }, [friend])

  // setDisplayItems: 値が存在する項目が4つになるまでループ
  // 除外条件を変更する場合は仕様書も更新すること
  // https://docs.google.com/spreadsheets/d/10uhSZU0ZXMn2BgHTfpDk5aAjhGvqGeI-rI3vPo8R0GQ/edit?usp=sharing
  useEffect(() => {
    if (!friend) { return }
    if (!displayProfileItems) { return }
    const items = displayProfileItems.filter(item => {
      if (!friend[item]) { return false }
      switch (item) {
        case 'height':
          if (user.sex === 'male') {
            // 自分より上であれば表示しない
            return heightMap[user.height] > heightMap[friend.height]
          } else if (user.sex === 'female') {
            // 差が10cm未満になる場合は表示しない
            return heightMap[user.height] + 10 < heightMap[friend.height]
          }
          return true
        case 'annual_income':
          if (user.sex === 'male') {
            // 自分より上であれば表示しない
            return user.height > friend.height
          } else if (user.sex === 'female') {
            if (annualIncomeMap[user.annual_income] !== 300) {
              // 自分が400万以上であれば、自分より低い場合は表示しない
              return annualIncomeMap[user.annual_income] <= annualIncomeMap[friend.annual_income]
            } else {
              // 自分が400万未満(300)であれば、自分との差が100万以下の場合表示しない
              return annualIncomeMap[user.annual_income] + 100 < annualIncomeMap[friend.annual_income]
            }
          }
          break
        case 'smoking_status':
          return user.heterosexual_smoking === friend.smoking_status
        default:
          return true
      }
    }).slice(0, 4)
    setDisplayItems(items)
  }, [friend, displayProfileItems])

  // 初回ナビ関連
  useEffect(() => {
    if (!tutorialReadStates) { return }
    if (tutorialReadStates.length === 0) { return }
    if (tutorialReadStates.includes('matching')) { return }
    if (tutorial) { return }

    props.setTutorialView('matching-01')
    BaseComponent.gaModalView('matchingtutorial')
  }, [user])

  const setDRT = () => {
    if (tutorial) { return }
    let drtText = null
    const today = new Date()
    const expiredDay = new Date(friend.daily_expired_at)
    // 時間差分
    const hourDiff = calculateHourDiff(today, expiredDay) + 1
    // 時間差分（分刻み）
    const minuteDiff = calculateMinuteDiff(today, expiredDay) + 1
    // 日付差分
    const daysDiff = Math.floor((hourDiff - 1) / 24) + 1

    if (hourDiff < 0) {
      drtText = null
    } else if (hourDiff === 1) {
      drtText = '● 残り' + minuteDiff + '分！'
    } else if (hourDiff < 24) {
      drtText = '● 残り' + hourDiff + '時間！'
    } else {
      drtText = '● 残り' + daysDiff + '日！'
    }
    setDailyRemainingTime(drtText)
  }

  const setRRT = () => {
    if (!friend) return
    if (tutorial) { return }
    const type = friend.type === 'lover_receiving' ? 'lover' : 'friend'
    const remainingTime = calculateRemainingDays(friend.entry_applied_at, type)
    setRequestRemainingTime(remainingTime)
  }

  const loadFriend = async () => {
    try {
      const friendDetail = await BaseComponent.loadFriendAndUpdateFriends(props, props.friend.id)
      setFriend(friendDetail)
      setPhotoUrl(friendDetail.photo_icon)
    } catch (error) {
      if (httpNotModified(error)) {
        // noop
      } else if (httpNotFound(error)) {
        setFriend(null)
        await BaseComponent.loadFriends(props, true)
      } else {
        BaseComponent.handleApiError(props, error)
      }
    }
  }

  const loadGalleryItems = async () => {
    try {
      if (friend.id) {
        const res = await Api.getGalleryItems(friend.id)
        setGalleryItems(res)
      } else {
        const items = [
          { icon_url: friend?.gallery_item_1 },
          { icon_url: friend?.gallery_item_2 },
          { icon_url: friend?.gallery_item_3 },
          { icon_url: friend?.gallery_item_4 },
          { icon_url: friend?.gallery_item_5 },
        ].filter(v => v.icon_url)
        setGalleryItems(items)
      }
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const clickFriend = () => {
    if (isWithdrawnFriend) { return }
    if (!friend) {
      loadFriend()
      return
    }
    if (tutorial) {
      BaseComponent.showRequestSuccessMessage(props, 'デモ画面です')
      return
    }
    if (index === 0 && tutorialView === 'matching-03') {
      BaseComponent.gaEvent('初回ナビ', 'click', 'プロフィール閲覧')
    }
    props.setFriend(friend)
    props.setScreen('Profile')
  }

  const createAvatar = () => {
    if (isWithdrawnFriend) { return <PersonIcon className={classes.withdrawnIcon} /> }
    if (!photoUrl) { return <CircularProgress color="secondary" /> }
    return (
      <PhotoFilter brightness={friend?.photo_filter_brightness}>
        <img src={photoUrl} className={classes.image} alt="" />
      </PhotoFilter>
    )
  }

  const createMatching = () => {
    if (isWithdrawnFriend) { return null }
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.matchingContainer}
        data-testid={`friend-cell-${index}-score`}
      >
        <div className={classes.matching} >
          <CircularProgressbarWithChildren
            value={friend?.matching || 0}
            styles={buildStyles({
              strokeLinecap: 'butt',
              textColor: '#423BC7',
              pathColor: '#616AFA',
              trailColor: '#ffffff',
            })}
          >
            <Typography className={classes.matchingText}>
              {`${friend?.matching || 0}`}<span className={classes.matchingTextSmall}>%</span>
            </Typography>
          </CircularProgressbarWithChildren>
        </div>
        <Typography className={classes.matchingTitle}>
          プロフィール<br />相性
        </Typography>
      </Grid>
    )
  }

  const createGalleryItems = () => {
    if (isWithdrawnFriend) { return null }
    return (
      <Grid container>
        {[...Array(4)].map((_, index) => {
          const item = galleryItems?.[index]
          if (item) {
            return <img key={index} src={item.icon_url} width={16} height={16} />
          }  else {
            return <div style={{ width: 19, height: 19, backgroundColor: `rgba(219, 220, 254, ${index % 2 === 0 ? '0.4' : '1'})` }} />
          }
        })}
      </Grid>
    )
  }

  const createProfile = (key) => {
    if (!friend) { return null }
    if (isWithdrawnFriend) { return null }
    if (!friend[key]) { return null }
    if (!master?.profile) { return null }
    const map = {
      [key]: master.profile[key].title,
      working_industry: '業種',
      working_occupation: '職種',
    }
    const title = map[key]
    return (
      <Typography key={key} className={classes.profile} data-testid={`friend-cell-${index}-profile-${key}`}>
        <span className={classes.profileTitle}>{title}：</span>
        {BaseComponent.getUserProfileItemLabel(props, friend, key)}
      </Typography>
    )
  }

  const createBadge = () => {
    if (isWithdrawnFriend) { return null }
    if (isApplyingFriend) {
      return (
        <Typography className={`${classes.badge} ${classes.applying}`} data-testid={`friend-cell-${index}-badge`}>
          いいね！送信済み
        </Typography>
      )
    }
    if (dailyRemainingTime) {
      return (
        <Typography className={`${classes.badge} ${classes.remain}`} data-testid={`friend-cell-${index}-badge`}>
          {dailyRemainingTime}
        </Typography>
      )
    }
    if (requestRemainingTime) {
      return (
        <Typography className={`${classes.badge} ${classes.remain}`}>
          {requestRemainingTime}
        </Typography>
      )
    }
  }

  const createFriendCard = () => {
    const receiving = friend?.type === 'friend_receiving' || friend?.type === 'lover_receiving'
    const pending = friend?.type === 'friend_pending'
    const msg = receiving ? friend.entry_request_message : pending ? friend.entry_response_message : ''

    return (
      <Card id="friendCard" className={classes.card}>
        {(friendRequestPage && readIds && friend && !readIds.includes(friend?.id)) && (
          <div className={classes.newBadge}>
            <Typography className={classes.newBadgeText}>
              NEW!
            </Typography>
          </div>
        )}
        {isApplyingFriend && <div className={classes.applyingFilter} onClick={clickFriend} />}
        {isWithdrawnFriend && (
          <Grid className={classes.withdrawnFilter} container justifyContent="center" alignItems="center">
            <Typography className={classes.withdrawnText}>この会員は卒業されました</Typography>
          </Grid>
        )}
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.cellContainer}
        >
          <Grid item onClick={clickFriend} data-testid={`friend-cell-${index}-icon`}>
            <Grid container justifyContent="center" alignItems="center" className={classes.emptySquare}>
              {createAvatar()}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            className={`${isWithdrawnFriend ? classes.withdrawnRightContainer : classes.rightContainer}`}
            onClick={clickFriend}
          >
            <Grid className={classes.matchingProfileContainer} container wrap="nowrap" justifyContent="flex-start">
              <Grid className={classes.scoreContainer} container direction="column" wrap="nowrap" justifyContent="space-between">
                {createMatching()}
                {createGalleryItems()}
              </Grid>
              <div className={classes.profileContainer}>
                {displayItems.map(item => createProfile(item))}
                {isShowLikes && (
                  <Typography className={classes.profile}>
                    <span className={classes.profileTitle}>好印象を貰った数：</span>
                    {friend.likes}
                  </Typography>
                )}
              </div>
            </Grid>
            {createBadge()}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            className={classes.nameContainer}
            onClick={clickFriend}
          >
            <Typography className={classes.name} data-testid={`friend-cell-${index}-name`}>
              {isWithdrawnFriend ? null : friend?.nick_name}
            </Typography>
            <Typography className={classes.age} data-testid={`friend-cell-${index}-age`}>
              {isWithdrawnFriend ? null : friend?.age && `${friend.age}歳`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            onClick={clickFriend}
          >
            <Typography
              className={`${classes.toMessage} ${isWithdrawnFriend ? classes.withdrawnBottomContainer : ''}`}
              data-testid={`friend-cell-${index}-msg`}
            >
              {isWithdrawnFriend ? null : friend?.to_message}
            </Typography>
            {(friendRequestPage && friend) && (
              <>
                <Typography className={classes.requestMessage} onClick={clickFriend}>
                  <img src={HeartIcon} width={12} height={12} />
                  <strong>
                    あなたへのメッセージ
                  </strong>
                  <img src={HeartIcon} width={12} height={12} />
                  <br />
                  <span>{msg}</span>
                </Typography>
                <Grid className={classes.buttonApproveContainer}>
                  <Button
                    className={classes.buttonApprove}
                    variant="contained"
                    color="secondary"
                  >
                    {friend?.type === 'lover_receiving' ? 'お付き合いする' : '詳細を見る'}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    )
  }

  return (
    <TableRow hover>
      <TableCell component="td" className={classes.cell}>
        <div id={index === 0 ? 'matching-tutorial-target' : ''} ref={naviTargetRef}>
          {firstBoostEnabled ? (
            <FirstBoostNavi>{createFriendCard()}</FirstBoostNavi>
          ) : isFirstBoostFriend ? (
            <FirstBoostNavi boostTitle={friend?.boost_title}>{createFriendCard()}</FirstBoostNavi>
          ) : isMovieEventBoostFriend ? (
            <MovieBoostNavi boostTitle={friend?.boost_title}>{createFriendCard()}</MovieBoostNavi>
          ) : (isWinterEventBoostFriend || isXmasBoostFriend) ? (
            <WinterEventBoostNavi boostTitle={friend?.boost_title}>{createFriendCard()}</WinterEventBoostNavi>
          ) : isValentineEventBoostFriend ? (
            <ValentineEventBoostNavi boostTitle={friend?.boost_title}>{createFriendCard()}</ValentineEventBoostNavi>
          ) : isAdditionalMatchingFriend ? (
            <SpecialOfferNavi boostTitle={friend?.boost_title}>{createFriendCard()}</SpecialOfferNavi>
          ) : (
            <KomaNavi friend={friend} index={index} friendRequestPage={friendRequestPage}>
              {createFriendCard()}
            </KomaNavi>
          )}
        </div>
        <NaviPopover
          {...props}
          id="matching-01"
          open={tutorialView === 'matching-01'}
          anchorEl={document.getElementById('matching-tutorial-target')}
          title={'今日のあなたの画面です'}
          text={'順を追って「いいね！」の流れを\n試してみよう'}
          onClose={() => props.setTutorialView(null)}
          okButton
          arrow
        />
        <NaviPopover
          {...props}
          id="matching-02"
          open={tutorialView === 'matching-02'}
          anchorEl={document.getElementById('matching-tutorial-target')}
          title={`${myPlanIsBasic ? '毎週' : '毎日'}1名12時頃に紹介！`}
          text={`初回のみ${friendsNum}名紹介（7日間表示）${myPlanIsBasic ? '' : '\n＊ブースト期間終了後から開始'}`}
          onClose={() => props.setTutorialView(null)}
          okButton
          arrow
        />
        <NaviPopper
          {...props}
          id="matching-03"
          open={tutorialView === 'matching-03'}
          anchorEl={document.getElementById('matching-tutorial-target')}
          title={'タップしよう！'}
          text={'プロフィールをタップすると\n詳細ページに進むよ'}
          placement={'bottom'}
          onClose={() => props.setTutorialView(null)}
          arrow
        />
      </TableCell>
    </TableRow>
  )
}

FriendCell.propTypes = {
  user: PropTypes.object.isRequired,
  master: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  friend: PropTypes.object.isRequired,
  friendsNum: PropTypes.number,
  subscription: PropTypes.object,
  tutorial: PropTypes.bool,
  matchingState: PropTypes.object,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
  displayProfileItems: PropTypes.array,
  readIds: PropTypes.array,
  tutorialReadStates: PropTypes.array,
  tutorialView: PropTypes.string,
  setTutorialView: PropTypes.func,
  friendRequestPage: PropTypes.bool,
}
