import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { entryFooterHight } from 'commons/theme'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${entryFooterHight}px)`,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  titleBar: {
    height: theme.spacing(0.625),
    width: theme.spacing(20),
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
  },
  container: {
    width: '50%',
    minWidth: theme.spacing(35),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginTop: 0,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(10),
    border: '1px solid',
  },
  message: {
    color: '#878787',
    fontSize: 12.5,
    marginTop: 0,
    lineHeight: 1.75,
  },
  messageContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
})

export class MembersPasscode extends BaseComponent {
  constructor (props) {
    super(props)

    this.submitPasscode = this.submitPasscode.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.onChangePasscode = this.onChangePasscode.bind(this)

    this._isMounted = false

    this.state = {
      isError: false,
      passcodeError: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onChangePasscode () {
    this.setState({passcodeError: null})
  }

  dialogClose () {
    this.setState({isError: false})
  }

  resetError () {
    this.setState({ passcodeError: null })
  }

  async submitPasscode () {
    this.resetError()

    // 入力のバリデーション
    let inputError = false

    let passcode = document.getElementById('passcode')

    // 入力されているか？
    if (isEmpty(passcode.value)) {
      this.setState({passcodeError: '認証コードを入力してください。'})
      inputError = true
    } else if (!isUsableCharacters(passcode.value)) {
      this.setState({passcodeError: '使用できない文字が含まれています。'})
      inputError = true
    } else if (passcode.value.length > 12) {
      this.setState({passcodeError: '最大文字長は１２文字です。'})
      inputError = true
    }

    if (inputError) { return }

    // ローディング表示
    this.props.setLoading(true)

    // 認証コード認証
    try {
      let data = await Api.authByPasscode(this.props.authId, passcode.value)
      this.props.setAuthId(data.auth.id)
      this.props.setOrganizationId(data.auth.organization_id)
      await this.loadMasters(data.auth.id)
      this.props.setScreen('Password')
    } catch (error) {
      if (error.response.status === 401) {
        this.setStateIfMounted({passcodeError: '認証コードが間違っています。'})
      } else {
        this.handleApiError(error)
      }
      this.setStateIfMounted({isError: true})
    } finally {
      this.props.setLoading(false)
    }
  }

  componentWillMount () {
    if (this.props.authId == null) {
      this.props.setScreen('/')
    }
    if (isEmpty(this.props.mailAddress)) {
      this.props.setScreen('/')
    }
  }

  render () {
    const { classes } = this.props

    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes" title="認証コード" message="認証コードの認証ができませんでした。"
        />

        <div className={classes.root}>
          <div className={classes.body}>
            <EntryHeader/>
            <Grid container direction='column' alignItems="center">
              <Typography className={classes.title}>
                PASSCODE
              </Typography>
              <div className={classes.titleBar}/>
              <Grid item className={classes.container}>
                <Typography className={classes.subTitle}>
                  パスワード再発行
                </Typography>
                <Typography className={classes.label}>
                  本人確認（認証コード入力）
                </Typography>
                <TextField
                  id="passcode"
                  type="tel"
                  margin="normal"
                  variant="outlined"
                  error={!isEmpty(this.state.passcodeError)}
                  placeholder="認証コード"
                  helperText={isEmpty(this.state.passcodeError) ? '' : this.state.passcodeError}
                  className={classes.textField}
                  color="primary"
                  onChange={this.onChangePasscode} />
              </Grid>
              <Button variant="outlined" color="primary" className={classes.entry} onClick={this.submitPasscode}>
                送信
              </Button>
              <Grid item className={classes.messageContainer}>
                <Typography className={classes.message}>
                  メールにてお送りした認証コードを入力してください。
                </Typography>
              </Grid>
            </Grid>
          </div>
          <EntryFooter/>
        </div>
      </React.Fragment>
    )
  }
}
export default withStyles(styles)(MembersPasscode)
