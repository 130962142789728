import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.styles.header.height,
    paddingRight: theme.spacing(3.75),
    paddingLeft: theme.spacing(3.75),
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
    height: 44,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    border: '1px solid #999999',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.625),
    marginBottom: theme.spacing(4),
  },
  re: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1,
    padding: 0,
    marginBottom: theme.spacing(5),
  },
  buttons: {
    position: 'fixed',
    bottom: 35,
  },
  save: {
    width: 230,
    height: 44,
    marginBottom: theme.spacing(1),
  },
  cancel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flexBasis: 'auto',
    marginBottom: theme.spacing(1),
  },
}))

export default function AuthCode (props) {
  const { mailAddress, authId } = props
  const classes = useStyles()
  const [code, setCode] = useState('')
  const [reSubmitDisable, setReSubmitDisable] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    BaseComponent.addVisitPageLog()
    return () => {
      BaseComponent.addLeavePageLog()
      if (!timerId) { return }
      window.clearTimeout(timerId)
    }
  }, [])

  const onChangeCode = (e) => {
    setCode(e.target.value)
    setError('')
  }

  const reSubmitMailAddress = async () => {
    if (!authId || !mailAddress) { return }
    props.setLoading(true)
    try {
      const auth = await Api.updateMailAddress(authId, mailAddress)
      props.setAuthId(auth.id)
      props.setMailAddress(auth.mail_address)
      setReSubmitDisable(true)
      setTimerId(window.setTimeout(() => {
        setReSubmitDisable(false)
      }, 30000))
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const submitPasscode = async () => {
    if (!code) {
      setError('認証コードを入力してください。')
      return
    } else if (!isUsableCharacters(code)) {
      setError('使用できない文字が含まれています。')
      return
    } else if (code.length > 12) {
      setError('最大文字長は12文字です。')
      return
    }

    props.setLoading(true)
    try {
      const res = await Api.authByPasscode(authId, code)
      props.setMailAddress(res.mail_address)
      BaseComponent.showRequestSuccessMessage(props, 'メールアドレスを変更しました')
      props.setScreen('/Home')
    } catch (error) {
      if (error.response.status === 401) {
        setError('認証コードが間違っています。')
      } else {
        BaseComponent.handleApiError(props, error)
      }
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <AppHeader title="認証コードの入力" backWhite hideBack {...props} />
      <div className={classes.container}>
        <Typography className={classes.text}>
          {mailAddress}に認証コードを送信しました。<br />
          以下にコードを入力してメールアドレスを認証してください。
        </Typography>
        <Typography className={classes.label}>認証コードを入力</Typography>
        <TextField
          className={classes.input}
          type='email'
          value={code}
          onChange={onChangeCode}
          InputProps={{ disableUnderline: true, inputProps: { 'aria-label': 'code' } }}
          error={!isEmpty(error)}
          helperText={error}
        />
        <Button
          variant="text"
          color="secondary"
          className={classes.re}
          onClick={reSubmitMailAddress}
          disabled={reSubmitDisable}
        >
          {reSubmitDisable ? '30秒後に再送信できます' : '認証コードを再送信する'}
        </Button>
      </div>
      <Grid
        className={classes.buttons}
        container
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button
          className={classes.save}
          variant="contained"
          color="secondary"
          onClick={submitPasscode}
          disabled={!code}
        >
          認証する
        </Button>
        <Button
          className={classes.cancel}
          variant="text"
          onClick={() => BaseComponent.goBack(props)}
        >
          キャンセル
        </Button>
      </Grid>
    </>
  )
}

AuthCode.propTypes = {
  mailAddress: PropTypes.string,
  authId: PropTypes.string,
  setAuthId: PropTypes.func,
  setMailAddress: PropTypes.func,
  setOrganizationId: PropTypes.func,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
