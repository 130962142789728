import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import TextField from '@mui/material/TextField'
import FriendInfo from 'components/template-dialog/FriendInfo'

const useStyles = makeStyles(theme => ({
  progressBar: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(3.375),
    height: '9px !important',
    borderRadius: 5,
    [`& .${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#DBDCFE'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#423BC7'
    },
  },
  left: {
    '& p': {
      textAlign: 'left'
    }
  },
  text: {
    fontSize: '14px !important',
    fontWeight: '300 !important',
    lineHeight: '23px',
  },
  box: {
    margin: theme.spacing(2),
  },
}))

export default function InputCategory (props) {
  const {
    type, index, selectedItems, wordType, onChange,
    onNext, onPrev, onClose, template, friend, master,
  } = props
  const classes = useStyles()
  const item = selectedItems[index]
  const [firstInputValue, setFirstInputValue] = useState('')
  const [secondInputValue, setSecondInputValue] = useState('')
  const firstInputRef = useRef(null)
  const secondInputRef = useRef(null)

  useEffect(() => {
    setFirstInputValue(item.valueAt(0))
    setSecondInputValue(item.valueAt(1))
  }, [index])

  const onChangeFirst = (event) => {
    const value = event.target.value
    setFirstInputValue(value)
    if (onChange) {
      onChange(index, [value, secondInputValue])
    }
  }

  const onChangeSecond = (event) => {
    const value = event.target.value
    setSecondInputValue(value)
    if (onChange) {
      onChange(index, [firstInputValue, value])
    }
  }

  const next = () => {
    if (template.zeroInputFieldContent.includes(item?.pattern || item.title)) {
      onNext()
      return
    }
    // 値が入ってなかったらエラー
    if (!firstInputRef.current.value) { return }
    // secondInputRefはinputが２つある項目だけチェック
    if (!template.oneInputFieldContent.includes(item?.pattern || item.title)) {
      if (!secondInputRef.current.value) { return }
    }
    onNext()
  }

  const sentenceValues = template?.sentence?.[item?.pattern || item.title][wordType] || []

  const nextDisabled = () => {
    if (template.zeroInputFieldContent.includes(item?.pattern || item.title)) { return false }
    if (!firstInputValue) { return true }
    if (sentenceValues.includes(2) && !secondInputValue) { return true }
    return false
  }

  return (
    <React.Fragment>
      <DialogTitle disableTypography>
        {type === 'self-introduction' ? '自己紹介文を作成' : '一言メッセージを作成'}
        <LinearProgress
          value={90 / (selectedItems.length + 1) * (index + 1)}
          variant="determinate"
          className={classes.progressBar}
        />
        <Typography>
          {selectedItems.length > 1 && `${index + 1}つ目の内容\n`}「{item.title}」について<br />
          {template.zeroInputFieldContent.includes(item?.pattern ||item.title) ? '文章を確認してください。' : '空欄を自由に記入してください。'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.left}>
          {
            sentenceValues.map((value, index) => {
              if (value !== 1 && value !== 2 && value !== '\n') {
                return (
                  <Typography key={`word-${index}`} className={classes.text}>
                    {value.replace('{friend.nick_name}', friend?.nick_name)}
                  </Typography>
                )
              }
              if (value === '\n') {
                return <Box key={`box-{${index}}`} className={classes.box} />
              }
              if (value === 1) {
                return (
                  <TextField
                    key={`first-input-${index}`}
                    inputProps={{ 'data-testid': `first-input-${index}` }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    inputRef={firstInputRef}
                    value={firstInputValue}
                    onChange={onChangeFirst}
                  />
                )
              }
              if (value === 2) {
                return (
                  <TextField
                    key={`second-input-${index}`}
                    inputProps={{ 'data-testid': `second-input-${index}` }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    inputRef={secondInputRef}
                    value={secondInputValue}
                    onChange={onChangeSecond}
                  />
                )
              }
            })
          }
        </div>
        <FriendInfo friend={friend} master={master} category={item.category} />
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          variant="contained"
          onClick={next}
          disabled={nextDisabled()}
        >
          次へ
        </Button>
        <Button onClick={onPrev}>戻る</Button>
        <Button onClick={onClose}>キャンセル</Button>
      </DialogActions>
    </React.Fragment>
  )
}
InputCategory.propTypes = {
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedItems: PropTypes.array.isRequired,
  wordType: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onClose: PropTypes.func,
  template: PropTypes.object,
  friend: PropTypes.object,
  master: PropTypes.object,
}
