import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import CheckIcon from 'images/special-offer/check_icon.png'
import Komainu from 'images/special-offer/komainu.png'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#1A1667',
  },
  content: {
    paddingLeft: theme.spacing(3.75),
    paddingRight: theme.spacing(3.75),
  },
  text: {
    width: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    letterSpacing: 0.7,
  },
  circle: {
    width: 120,
    height: 120,
    background: '#EFF0FF',
    borderRadius: '50%',
    cursor: 'pointer',
    marginTop: theme.spacing(6.75),
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: theme.spacing(4),
  },
  komainu: {
    marginBottom: theme.spacing(-1),
  },
  actions: {
    paddingTop: 0,
  },
  containedButton: {
    background: '#616AFA !important',
  },
}))

export default function PurchaseFinishDialog (props) {
  const { open, offer, num, onClose } = props
  const classes = useStyles()

  if (!offer) { return }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>購入完了</DialogTitle>
        <DialogContent className={classes.content}>
          <Typography className={classes.text} variant="body1">
            アプリ内の紹介画面にて<br />
            追加の紹介者{num}名を<br />
            ご確認ください。
          </Typography>
          <Grid className={classes.circle} container justifyContent="center" alignItems="center">
            <img src={CheckIcon} width={60} height={60} />
          </Grid>
          <img className={classes.komainu} src={Komainu} width={230} height={142} />
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button className={classes.containedButton} variant="contained" onClick={onClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

PurchaseFinishDialog.propTypes = {
  open: PropTypes.bool,
  offer: PropTypes.object,
  num: PropTypes.number,
  onClose: PropTypes.func,
}
