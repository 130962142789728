import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import PartnerPage from 'components/parts/PartnerPage'
import Api from '../commons/api'
import { isEmpty, isUsableCharacters } from '../commons/utility'

const styles = theme => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    ...theme.styles.signupTextField,
    marginBottom: theme.spacing(2),
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(10),
    border: '1px solid',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  message: theme.styles.signupDescription,
  messageContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
})

export class PartnerPassword extends BaseComponent {
  constructor (props) {
    super(props)

    this.submitPassword = this.submitPassword.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this)

    this._isMounted = false

    this.state = {
      isError: false,
      passwordError: null,
      passwordConfirmError: null,
    }
  }

  componentWillMount () {
    if (this.props.authId == null) {
      this.props.setScreen('/')
    }
    if (isEmpty(this.props.mailAddress)) {
      this.props.setScreen('/')
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onChangePassword () {
    this.setState({ passwordError: null })
  }

  onChangePasswordConfirm () {
    this.setState({ passwordConfirmError: null })
  }

  dialogClose () {
    this.setState({ isError: false })
  }

  resetError () {
    this.setState({ passwordError: null })
    this.setState({ passwordConfirmError: null })
  }

  async submitPassword () {
    this.resetError()

    // 入力のバリデーション
    let inputError = false

    let password = document.getElementById('password')
    let passwordConf = document.getElementById('passwordConf')

    // パスワードのチェック
    if (isEmpty(password.value)) {
      this.setState({ passwordError: 'パスワードを入力してください。' })
      inputError = true
    } else if (!isUsableCharacters(password.value)) {
      this.setState({ passwordError: '使用できない文字が含まれています。' })
      inputError = true
    } else if (password.value.length < 8) {
      this.setState({ passwordError: '最小文字長は８文字です。' })
      inputError = true
    } else if (password.value.length > 32) {
      this.setState({ passwordError: '最大文字長は３２文字です。' })
      inputError = true
    }

    // パスワード（確認用）のチェック
    if (isEmpty(passwordConf.value)) {
      this.setState({ passwordConfirmError: 'パスワード（確認）を入力してください。' })
      inputError = true
    } else if (!isUsableCharacters(passwordConf.value)) {
      this.setState({ passwordConfirmError: '使用できない文字が含まれています。' })
      inputError = true
    } else if (passwordConf.value.length < 8) {
      this.setState({ passwordConfirmError: '最小文字長は８文字です。' })
      inputError = true
    } else if (passwordConf.value.length > 32) {
      this.setState({ passwordConfirmError: '最大文字長は３２文字です。' })
      inputError = true
    }

    if (inputError) { return }

    // パスワード確認
    if (password.value !== passwordConf.value) {
      this.setState({ passwordConfirmError: 'パスワードが一致していません。' })
      return
    }

    // ローディング表示
    this.props.setLoading(true)

    // パスワード設定
    try {
      let auth = await Api.updatePassword(this.props.authId, password.value, false)
      this.props.setAuthId(auth.id)
      await this.login(this.props.mailAddress, password.value, 'partner')
      this.props.setIsLogin(true)
      this.props.setScreen('PartnerHome')
    } catch (error) {
      this.setStateIfMounted({ isError: true })
      this.handleApiError(error)
      this.clearSession()
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes"
          title="パスワード登録"
          message="パスワード登録できませんでした。"
        />
        <PartnerPage title="パスワード再設定" {...others}>
          <Typography className={classes.label}>
            ログインパスワード
          </Typography>
          <TextField
            id="password"
            type="password"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.passwordError)}
            placeholder="パスワード"
            helperText={isEmpty(this.state.passwordError) ? '' : this.state.passwordError}
            className={classes.textField}
            color="secondary"
            onChange={this.onChangePasscode}
          />
          <Typography className={classes.label}>
            ログインパスワード（確認）
          </Typography>
          <TextField
            id="passwordConf"
            type="password"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.passwordConfirmError)}
            placeholder="パスワード"
            helperText={isEmpty(this.state.passwordConfirmError) ? '' : this.state.passwordConfirmError}
            className={classes.textField}
            color="secondary"
            onChange={this.onChangePasswordConfirm}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.entry}
              onClick={this.submitPassword}
            >
              送信
            </Button>
          </div>
          <div className={classes.messageContainer}>
            <Typography className={classes.message}>
              ログイン時に使用するパスワードを入力してください。
            </Typography>
          </div>
        </PartnerPage>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(PartnerPassword)
