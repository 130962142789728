import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from '../../commons/api'
import BaseComponent from './BaseComponent'

const styles = theme => ({
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    paddingLeft: theme.spacing(2),
  },
  dateField: {
    width: 150,
  },
  reasonSelect: {
    width: 150,
  },
  textField: {
    width: '100%',
  },
  cardActions: {
    justifyContent: 'center'
  },
  card: {
    backgroundColor: theme.palette.background.default,
  }
})

const maxReasonInputLength = 100

const reasonValues = {
  work: '仕事',
  sickness: '体調不良',
  stayAbroad: '海外滞在',
  others: 'その他',
}

// トーク返信難しい日設定
class BusyDateCard extends BaseComponent {

  constructor (props) {
    super(props)

    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeReason = this.handleChangeReason.bind(this)
    this.handleChangeReasonInput = this.handleChangeReasonInput.bind(this)
    this.updateBusyDate = this.updateBusyDate.bind(this)
    this.handleCancel = this.handleCancel.bind(this)

    this.state = {
      startDate: '',
      endDate: '',
      reason: 'work',
      reasonInput: '',
      disabledReasonInput: true,
      showCancelButton: !!this.props.onCancel,

      errorStateDate: false,
      errorEndDate: false,
      errorReasonInput: false,
    }
  }

  componentWillMount () {
    this.initDates(this.props.params)
    this.initReason(this.props.params)
  }

  componentWillReceiveProps (nextProps) {
    if (!nextProps.params) return

    if (this.props.params) {
      if (this.props.params.endDate !== nextProps.params.endDate) {
        this.initDates(nextProps.params)
      }
      if (this.props.params.reason !== nextProps.params.reason) {
        this.initReason(nextProps.params)
      }
    } else {
      this.initDates(nextProps.params)
      this.initReason(nextProps.params)
    }
  }

  initDates (params) {
    let nextState = {}
    if (params.startDate) {
      nextState['startDate'] = params.startDate
    }
    if (params.endDate) {
      nextState['endDate'] = params.endDate
    }
    if (nextState) {
      this.setState(nextState)
    }
  }

  initReason (params) {
    if (params.reason) {
      let reasonKey = this.findReasonKey(params)
      if (!reasonKey || reasonKey === 'others') {
        this.setState({
          reason: 'others',
          reasonInput: params.reason,
          disabledReasonInput: false,
        })
      } else {
        this.setState({
          reason: reasonKey,
          reasonInput: '',
          disabledReasonInput: true,
        })
      }
    } else {
      this.setState({
        reason: 'work',
        disabledReasonInput: true,
      })
    }
  }

  findReasonKey (params) {
    for (let key in reasonValues) {
      if (reasonValues[key] === params.reason) {
        return key
      }
    }
    return null
  }

  handleChangeDate (name) {
    return (event) => {
      let newState = {}
      newState[name + 'Date'] = event.target.value
      this.setState(newState)
    }
  }

  handleChangeReason (event) {
    let value = event.target.value
    this.setState({
      reason: value,
      disabledReasonInput: (value !== 'others'),
    })
  }

  handleChangeReasonInput (event) {
    var txt = event.target.value
    if (maxReasonInputLength < txt.length) {
      txt = txt.slice(0, maxReasonInputLength)
    }
    this.setState({ reasonInput: txt })
  }

  async updateBusyDate () {
    let valid = this.validate()
    if (!valid) return

    let start = this.state.startDate
    let end = this.state.endDate
    let reason = this.getReasonValue()
    try {
      await Api.updateBusyDate(start, end, reason)
      this.handleUpdate()
    } catch (error) {
      this.handleApiError(error)
      this.handleClose()
    }
  }

  getReasonValue () {
    if (this.state.reason === 'others') {
      return this.state.reasonInput
    } else {
      for (let key in reasonValues) {
        if (this.state.reason === key) {
          return reasonValues[key]
        }
      }
      return ''
    }
  }

  validate () {
    let errorStartDate = (!this.state.startDate)
    let errorEndDate = (!this.state.endDate)
    let errorReasonInput = false
    if (this.state.reason === 'others') {
      errorReasonInput = (!this.state.reasonInput)
    }

    this.setState({
      errorStartDate,
      errorEndDate,
      errorReasonInput,
    })
    return !(errorStartDate || errorEndDate || errorReasonInput)
  }

  handleUpdate () {
    if (!this.props.onUpdate) return

    let params = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      reason: this.getReasonValue()
    }
    this.props.onUpdate(params)
  }

  handleCancel () {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  render () {
    let { classes } = this.props
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="subtitle1" className={classes.subTitle}>
            ■期間
          </Typography>
          <div className={classes.inputContainer}>
            <TextField
              label="開始日"
              type="date"
              value={this.state.startDate}
              onChange={this.handleChangeDate('start')}
              className={classes.dateField}
              InputLabelProps={{ shrink: true }}
              error={this.state.errorStateDate}
            />
            &nbsp;〜&nbsp;
            <TextField
              label="終了日"
              type="date"
              value={this.state.endDate}
              onChange={this.handleChangeDate('end')}
              className={classes.dateField}
              InputLabelProps={{ shrink: true }}
              error={this.state.errorEndDate}
            />
          </div>
          <Typography variant="subtitle1" className={classes.subTitle}>
            ■理由
          </Typography>
          <div className={classes.inputContainer}>
            <Select
              value={this.state.reason}
              onChange={this.handleChangeReason}
              className={classes.reasonSelect}
            >
              {
                Object.keys(reasonValues).map(key => {
                  return (
                    <MenuItem key={key} value={key}>
                      {reasonValues[key]}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </div>
          <Typography variant="subtitle1" className={classes.subTitle}>
            ■理由が「その他」の場合
          </Typography>
          <div className={classes.inputContainer}>
            <TextField
              value={this.state.reasonInput}
              onChange={this.handleChangeReasonInput}
              className={classes.textField}
              disabled={this.state.disabledReasonInput}
              error={this.state.errorReasonInput}
            />
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button color="secondary" variant='contained' onClick={this.updateBusyDate}>
            登録
          </Button>
          {
            this.state.showCancelButton ? (
              <Button onClick={this.handleCancel}>
                キャンセル
              </Button>
            ) : null
          }
        </CardActions>
      </Card>
    )
  }
}

export default withStyles(styles)(BusyDateCard)
