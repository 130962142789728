import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: sp => theme.spacing(sp ? 4 : 9),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  title: {
    fontSize: sp => sp ? 14 : 28,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : 1,
    letterSpacing: sp => sp ? '1.4px' : '2.8px',
    textAlign: 'center',
    marginBottom: sp => theme.spacing(sp ? 3.25 : 3.75),
  },
  text: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : '26px',
    letterSpacing: sp => sp ? '1.2px' : '1.4px',
    textAlign: sp => sp ? 'left' : 'center',
    marginBottom: sp => theme.spacing(sp ? 5.5 : 7.5),
  },
  email: {
    fontSize: sp => sp ? 14 : 20,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: 1,
    letterSpacing: sp => sp ? '1.4px' : '2.0px',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    display: 'block',
  },
}))

export default function Contact () {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  const createText = () => {
    if (window.location.pathname === '/SearchCompanyAccepted') {
      return (
        <>
          本サービスについてご不明な点がございましたら、<br />
          以下のメールアドレスまでご連絡をお願いいたします。
        </>
      )
    }
    return (
      <>
        対象企業でも、グループ企業などで登録されてない場合がございます。<br />
        その場合には、お手数おかけしますが下記リンクより一言{!spMatches && <br />}
        「○○(会社名)が登録できない」とご連絡いただけましたら幸いです。
      </>
    )
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>お問い合わせ</Typography>
      <Typography className={classes.text}>
        {createText()}
      </Typography>
      <a className={classes.email} href="mailto:support@aill.ai?subject=問い合わせ" target="_blank" rel="noreferrer">
        support@aill.ai
      </a>
    </div>
  )
}
