import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Img1 from 'images/img_remind_dialog_1.png'
import Img2 from 'images/img_remind_dialog_2.png'
import Img3 from 'images/img_remind_dialog_3.png'
import Img4 from 'images/img_remind_dialog_4.png'

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(1.5),
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}))

export default function RemindRequestDialog (props) {
  const { open, handleClose } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          お知らせ
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.message} variant="subtitle1">
            「いいね！」が届いています
          </Typography>
          <Grid>
            <Grid className={classes.flex}>
              <img src={Img1} alt="" />
              <Typography className={classes.text} variant="body1">
                早めのアクションが<br />好印象だよ！
              </Typography>
              <img src={Img2} alt="" />
            </Grid>
            <Grid className={classes.flex}>
              <img src={Img3} className={classes.icon} alt="" />
              <img src={Img4} className={classes.icon} alt="" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            確認する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

RemindRequestDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}
