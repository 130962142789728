import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Const from 'commons/constant'
import ReplySpeech from 'components/parts/ReplySpeech'
import CommonSpeech from './CommonSpeech'
import PhotoDialog from './PhotoDialog'

const speachStyle = theme => {
  return {
    // DEV-1357 記号が連続すると折り返されない
    // padding を変更する場合は maxWidth も変更する必要がある
    width: 'fit-content',
    maxWidth: (window.innerWidth || 375) - 117,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '25px 25px 5px 25px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2.5),
  }
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
  },
  speach: speachStyle(theme),
  speachText: {
    '-webkit-user-select': 'none',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
  },
  image: {
    margin: theme.spacing(1),
  },
  imageProgressContainer: {
    height: 246,
    textAlign: 'center',
  },
  imageContainer: {
    margin: theme.spacing(1),
    '& img': {
      maxWidth: 200,
      height: 'auto',
      marginRight: theme.spacing(1),
    },
  },
  textContainer: {
    textAlign: 'right',
    paddingRight: theme.spacing(3),
  },
  message: {
    ...theme.styles.fontSize(12),
    minHeight: theme.spacing(2.25),
  },
})

export class RightSpeech extends CommonSpeech {
  render () {
    const { user, friend, message, classes, selectedChatId } = this.props

    return (
      <div>
        <PhotoDialog
          contentId={this.props.message.id}
          open={this.state.openPhotoDialog}
          imageUrl={this.state.photoLargeUrl}
          onClose={this.closePhotoDialog}
          onDelete={this.deletePhoto}
        />
        <div
          id={`message-${this.props.message.id}`}
          className={classes.root}
        >
          <div className={classes.timeContainer}>
            <Typography variant="caption">
              { this.getPostedTimeString() }
            </Typography>
          </div>
          {this.props.message.message_type === 'image' ? (
            <div
              className={classes.imageContainer}
              onContextMenu={this.handleContextMenu}
              onTouchStart={this.handleTouchStart}
              onTouchMove={this.handleTouchMove}
              onTouchEnd={this.handleTouchEnd}
              style={{ filter: this.props.message.id === selectedChatId ? 'contrast(0.5)' : 'none' }}
            >
              {this.state.photoLoading && (
                <div className={classes.imageProgressContainer}>
                  <CircularProgress />
                </div>
              )}
              { /* CommonSpeech#loadImage()でここにCanvasが挿入される */ }
            </div>
          ) : (
            <div>
              <ReplySpeech
                user={user}
                friend={friend}
                message={message}
                speech="right"
              />
              <Grid container justifyContent="flex-end">
                <div
                  className={classes.speach}
                  onContextMenu={this.handleContextMenu}
                  onTouchStart={this.handleTouchStart}
                  onTouchMove={this.handleTouchMove}
                  onTouchEnd={this.handleTouchEnd}
                  style={{ filter: this.props.message.id === selectedChatId ? 'contrast(0.5)' : 'none' }}
                >
                  <span
                    id={`message-text-${this.props.message.id}`}
                    className={classes.speachText}
                  >
                    {this.createChatMessageElements(Const.colors.rightSpeach, classes.message)}
                  </span>
                </div>
              </Grid>
            </div>
          )}
        </div>
        {this.props.message.message_type === 'apply_lover' && (
          <div className={classes.textContainer}>
            <Typography variant="caption">
              お付き合いリクエスト送信済み
            </Typography>
          </div>
        )}
        {this.props.message.message_type === 'approve_lover' && (
          <div className={classes.textContainer}>
            <Typography variant="caption">
              お付き合いリクエスト承認
            </Typography>
          </div>
        )}
      </div>
    )
  }
}

RightSpeech.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  userGender: PropTypes.string.isRequired,
}

export default withStyles(styles)(RightSpeech)