import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import PartnerPage from 'components/parts/PartnerPage'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  subText: {
    fontSize: 12,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  textField: {
    ...theme.styles.signupTextField,
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(10),
  },
  errorMessage: {
    height: theme.spacing(3),
    marginBottom: theme.spacing(1.25),
  },
  linkText: {
    fontSize: 12,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  link: {
    textDecoration: 'underline',
  },
})

class PartnerLogin extends BaseComponent {
  constructor (props) {
    super(props)

    this.onClickFogotPassword = this.onClickFogotPassword.bind(this)
    this.onChangeMailAddress = this.onChangeMailAddress.bind(this)
    this.onChangePasswords = this.onChangePasswords.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.submitLogin = this.submitLogin.bind(this)

    this.state = {
      isLoginError: false,
      loginErrorMsg: '',
    }
  }

  onClickFogotPassword () {
    this.props.setScreen('PartnerPasswordForgot')
  }

  onChangeMailAddress () {
    this.setState({ mailAddressError: null })
  }

  onChangePasswords () {
    this.setState({ passwordError: null })
  }

  dialogClose () {
    this.setState({ isLoginError: false })
  }

  resetError () {
    this.setState({ mailAddressError: null })
    this.setState({ passwordError: null })
  }

  async submitLogin () {
    this.resetError()

    // 入力のバリデーション
    let inputError = false

    let mailAddress = document.getElementById('mailAddress')
    let password = document.getElementById('password')

    // 入力されているか？
    if (isEmpty(mailAddress.value) || !mailAddress.value.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/) || mailAddress.value.length > 255) {
      this.setState({ loginErrorMsg: 'メールアドレスまたはパスワードが正しくありません。' })
      inputError = true
    }

    if (isEmpty(password.value) || !isUsableCharacters(password.value) || password.value.length > 32) {
      this.setState({ loginErrorMsg: 'メールアドレスまたはパスワードが正しくありません。' })
      inputError = true
    }

    if (inputError) {
      return
    }

    // ローディング表示
    this.props.setLoading(true)

    // ログイン認証
    try {
      await this.login(mailAddress.value, password.value, 'partner')
      this.props.setIsLogin(true)
    } catch (error) {
      this.setState({ isLoginError: true })
      this.handleApiError(error)
      this.clearSession()
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isLoginError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes" title="ログイン" message="ログインできませんでした。"
        />
        <PartnerPage title="ログイン" {...others}>
          <Typography className={classes.subTitle}>
            Aill goen（エール ゴエン）パートナーサイト
          </Typography>
          <Typography className={classes.subText}>
            ※入会実績の確認やQRコードの発行ができます。
          </Typography>
          <TextField
            id="mailAddress"
            type="email"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.mailAddressError)}
            placeholder="メールアドレスを入力"
            className={classes.textField}
            color="secondary"
            onChange={this.onChangeMailAddress}
          />
          <TextField
            id="password"
            type="password"
            margin="normal"
            variant="outlined"
            placeholder="パスワードを入力"
            className={classes.textField}
            error={!isEmpty(this.state.passwordError)}
            color="secondary"
            onChange={this.onChangePassword}
          />
          <div className={classes.buttonContainer}>
            <Button variant="contained" color="secondary" className={classes.entry} onClick={this.submitLogin}>
              ログイン
            </Button>
          </div>
          <Typography color="error" style={{whiteSpace: 'pre-line'}} className={classes.errorMessage} variant="body1">
            {this.state.loginErrorMsg}
          </Typography>
          <Typography className={classes.linkText}>
            パスワードを忘れた方は
            <span className={classes.link} onClick={this.onClickFogotPassword}>
              こちら
            </span>
          </Typography>
        </PartnerPage>
      </React.Fragment>
    )
  }
}
export default withStyles(styles)(PartnerLogin)
