import React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import Storage from 'commons/storage'
import { entryFooterHight } from 'commons/theme'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import DisableCookieScreen from 'components/DisableCookieScreen'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'

const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${entryFooterHight}px)`,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  titleBar: {
    height: theme.spacing(0.625),
    width: theme.spacing(10),
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
  },
  container: {
    width: '50%',
    minWidth: theme.spacing(35),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  subTitle: {
    color: '#707070',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  subText: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  textField: {
    marginTop: 0,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  entry: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  linkText: {
    fontSize: 14,
    marginBottom: theme.spacing(1),
  },
  link: {
    color: '#707070',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
})

class MembersLogin extends BaseComponent {
  constructor (props) {
    super(props)

    this.onClickFogotPassword = this.onClickFogotPassword.bind(this)
    this.onClickSignup = this.onClickSignup.bind(this)
    this.onChangeMailAddress = this.onChangeMailAddress.bind(this)
    this.onChangePasswords = this.onChangePasswords.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.submitLogin = this.submitLogin.bind(this)

    this.state = {
      isLoginError: false,
      loginErrorMsg: '',
    }
  }

  componentDidMount () {
    Storage.pathAfterAppLaunch.clear()
  }

  onClickFogotPassword () {
    this.props.setScreen('PasswordForgot')
  }

  onClickSignup () {
    this.props.setScreen('Signup')
  }

  onChangeMailAddress () {
    this.setState({ mailAddressError: null })
  }

  onChangePasswords () {
    this.setState({ passwordError: null })
  }

  dialogClose () {
    this.setState({ isLoginError: false })
  }

  resetError () {
    this.setState({ mailAddressError: null })
    this.setState({ passwordError: null })
  }

  async submitLogin () {
    this.resetError()

    // 入力のバリデーション
    let mailAddress = document.getElementById('mailAddress')
    let password = document.getElementById('password')

    // 入力されているか？
    if (isEmpty(mailAddress.value) || !mailAddress.value.match(/^[a-zA-Z0-9_.+-]+[@][a-zA-Z0-9.-]+$/) || mailAddress.value.length > 255) {
      this.setState({
        isLoginError: true,
        loginErrorMsg: 'メールアドレスまたはパスワードが正しくありません。',
      })
      return
    }

    if (isEmpty(password.value) || !isUsableCharacters(password.value) || password.value.length > 32) {
      this.setState({
        isLoginError: true,
        loginErrorMsg: 'メールアドレスまたはパスワードが正しくありません。',
      })
      return
    }

    // ローディング表示
    this.props.setLoading(true)

    // ログイン認証
    try {
      const auth = await this.login(mailAddress.value, password.value, 'user', 'members')
      if (auth?.needSmsVerification) {
        this.props.setScreen('LoginAuthSmsCode')
        return
      }
      this.props.setIsLogin(true)
    } catch (error) {
      const res = await error.response?.json()
      this.setState({
        isLoginError: true,
        loginErrorMsg: res?.message || 'ログインできませんでした。',
      })
      this.clearSession()
    } finally {
      this.props.setLoading(false)
    }
  }

  render () {
    if (!Storage.enabled) {
      return <DisableCookieScreen />
    }

    const { classes } = this.props
    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isLoginError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes"
          title="ログイン"
          message={this.state.loginErrorMsg}
        />
        <div className={classes.root}>
          <EntryHeader/>
          <div className={classes.body}>
            <Grid container direction='column' alignItems="center">
              <Typography className={classes.title}>
                LOGIN
              </Typography>
              <div className={classes.titleBar}/>
              <Grid item className={classes.container}>
                <Typography className={classes.subTitle}>
                  AI縁結びナビゲーションアプリAill goen（エール ゴエン）<br />
                  メンバーズサイト
                </Typography>
                <Typography className={classes.subText}>
                  ＊ご利用プラン等をご確認・ご変更いただけます。
                </Typography>
                <TextField
                  id="mailAddress"
                  type="email"
                  margin="normal"
                  variant="outlined"
                  error={!isEmpty(this.state.mailAddressError)}
                  placeholder="メールアドレスを入力"
                  className={classes.textField}
                  color="primary"
                  onChange={this.onChangeMailAddress} />
                <TextField
                  id="password"
                  type="password"
                  margin="normal"
                  variant="outlined"
                  placeholder="パスワードを入力"
                  className={classes.textField}
                  error={!isEmpty(this.state.passwordError)}
                  color="primary"
                  onChange={this.onChangePassword}
                />
              </Grid>
              <Button variant="contained" color="primary" className={classes.entry} onClick={this.submitLogin}>
                ログイン
              </Button>
              <Typography className={classes.linkText}>
                パスワードを忘れた方は
                <span className={classes.link} onClick={this.onClickFogotPassword}>
                  こちら
                </span>
              </Typography>
              <Typography className={classes.linkText}>
                会員登録がお済みでない方は
                <a className={classes.link} href={Config.entrySiteUrl}>
                  こちら
                </a>
              </Typography>
            </Grid>
          </div>
          <EntryFooter/>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MembersLogin)
