const categoryAndItem = {
  '写真について': [
    'プロフィール写真',
    'サブ写真',
  ],
  '自己紹介文について': [
    '自己紹介文',
  ],
  'プロフィールについて': [
    '趣味',
    '出身',
    'お酒',
    '休日',
    '共通の項目',
    'いいなと思った項目',
  ],
}

// Inputが一つの項目リスト
const oneInputFieldContent = [
  'プロフィール写真 1',
  'サブ写真 2',
  '自己紹介文 1',
  '自己紹介文 2',
  '趣味 1',
  'お酒 2',
  '休日',
  '共通の項目',
  'いいなと思った項目',
]

// Inputが無い項目リスト
const zeroInputFieldContent = [
  '出身 1',
  'お酒 1',
  '休日 1',
]

// 文章のテンプレートオブジェクト
// 1: 1つ目のTextFieldに置き換え
// 2: 2つ目のTextFieldに置き換え
// \n : marginを持つboxに置き換え
// 文字列: Typoでそのまま出力
const sentence = {
  'プロフィール写真 1': {
    'casual': ['{friend.nick_name}さんの写真を見て笑顔が素敵だなと思っていいね！しました。', '\n', 1, 'のことなど色々お話できたら嬉しいです！'],
  },
  'プロフィール写真 2': {
    'casual': ['プロフィール写真、いいですね！', 1, 'の写真ですか？', '\n', '私も', 2, '好きです。{friend.nick_name}さんと色々お話しできると嬉しいです！'],
  },
  'プロフィール写真 3': {
    'casual': ['プロフィール写真、いいですね！', 1, '好きなのですか？', '\n', '私も', 2, '好きです。{friend.nick_name}さんと色々お話しできると嬉しいです！'],
  },
  'プロフィール写真 4': {
    'casual': ['プロフィール写真、いいですね！', 1, '好きなのですか？', '\n', '私も最近', 2, '気になってます！気軽にお話しできると嬉しいです。よろしくお願いします！'],
  },
  'プロフィール写真 5': {
    'casual': ['プロフィール写真、いいですね！', 1, '歴長いのですか？', '\n', '私も', 2, 'やってます！{friend.nick_name}さんと色々お話しできると嬉しいです。よろしくお願いします！'],
  },
  'サブ写真 1': {
    'casual': [1, 'の写真が素敵だなと思っていいねしました。', '\n', 2, 'のことなど色々お話できたら嬉しいです！'],
  },
  'サブ写真 2': {
    'casual': ['写真が素敵で、', 1, 'が好きなんですね！', '\n', '気軽にお話しできると嬉しいです。よろしくお願いします！'],
  },
  'サブ写真 3': {
    'casual': [1, 'が好きなのですか？私も最近', 2, '気になってます！', '\n', '気軽にお話しできると嬉しいです。よろしくお願いします！'],
  },
  '自己紹介文 1': {
    'casual': ['プロフィールをしっかり書かれていてステキだなと思い、いいね！しました。', 1, 'のことなど色々お話できたら嬉しいです！'],
  },
  '自己紹介文 2': {
    'casual': ['プロフィール拝見しました！', 1, 'など、価値観が合いそうだなと思っていいね！しました。', '\n', '気軽にお話しできると嬉しいです。よろしくお願いします！'],
  },
  '自己紹介文 3': {
    'casual': [1, 'が好きなんですね！', '\n', '私も', 2, '好きです。{friend.nick_name}さんと色々お話しできると嬉しいです。よろしくお願いします！'],
  },
  '自己紹介文 4': {
    'casual': [1, 'が好きなんですね！', '\n', '私も最近', 2, '気になってます！気軽にお話しできると嬉しいです。よろしくお願いします！'],
  },
  '趣味 1': {
    'casual': ['プロフィールを見て、趣味が合いそうだと思っていいね！しました。', '\n', 1, 'のことなど色々お話できたら嬉しいです！'],
  },
  '趣味 2': {
    'casual': [1, 'が趣味なんですね。', '\n', '私も', 2, 'が趣味なので色々お話しできると嬉しいです。よろしくお願いします！'],
  },
  '出身 1': {
    'casual': ['出身が同じで親近感が湧いたので、いいね！しました。', '\n', '地元のことなど色々お話できたら嬉しいです！'],
  },
  '出身 2': {
    'casual': [1, '出身なんですね。', '\n', '私も', 2, '出身なので色々お話できると嬉しいです！'],
  },
  'お酒 1': {
    'casual': ['お酒が好きということで親近感が湧いて、いいね！しました。', '\n', '普段どんなお酒を飲まれるんですか？'],
  },
  'お酒 2': {
    'casual': ['プロフィールを見て、お酒について語り合えたらなと思っていいね！しました。', '\n', 1, 'のことなど色々お話できたら嬉しいです！'],
  },
  '休日 1': {
    'casual': ['プロフィールを見て、休日が同じかなと思ったので、いいね！しました。', '\n', '普段お休みの日はどのように過ごしてますか？'],
  },
  '休日 2': {
    'casual': ['普段お休みの日はどのように過ごしてますか？私は', 1, 'をしていることが多いです！', '\n', 2, 'のことなど色々お話できたら嬉しいです！'],
  },
  '共通の項目 1': {
    'casual': ['プロフィールを見て、', 1, 'が近いと思っていいね！しました。', '\n', 2, 'のことなど色々お話できたら嬉しいです！'],
  },
  '共通の項目 2': {
    'casual': [1, 'が私と一緒かなと思っていいね！しました。', '\n', 2, 'のことなど色々お話できたら嬉しいです！'],
  },
  'いいなと思った項目 1': {
    'casual': ['プロフィールを見て、', 1, 'がステキだと思っていいね！しました。', '\n', 2, 'のことなど色々お話できたら嬉しいです！'],
  },
  'いいなと思った項目 2': {
    'casual': ['{nick_name}さんの', 1, 'がいいなと思ったのでいいね！しました。', '\n', 2, 'のことなど気軽にお話しできると嬉しいです。よろしくお願いします！'],
  },
}

export default {
  categoryAndItem,
  oneInputFieldContent,
  zeroInputFieldContent,
  sentence,
}