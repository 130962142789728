import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import AppHeader from 'components/parts/AppHeader'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.styles.header.height,
    position: 'relative',
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    color: '#707070',
    textAlign: 'center',
    letterSpacing: '0.35px',
    lineHeight: '19px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  note: {
    width: 219,
    fontSize: 11,
    fontWeight: 300,
    color: '#707070',
    letterSpacing: '0.22px',
    lineHeight: '15px',
    margin: 'auto',
  },
  expiredAt: {
    fontSize: 11,
    fontWeight: 300,
    color: '#F55083',
    letterSpacing: '0.22px',
    lineHeight: '15px',
    margin: 'auto',
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  button: {
    width: 268,
    height: 44,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    display: 'block',
    margin: 'auto',
    position: 'absolute',
    bottom: 43,
    left: '50%',
    transform: 'translate(-50%, -0%)',
  },
  connect: {
    '&:hover': {
      color: '#ffffff',
      background: '#423BC7',
    }
  },
  disconnect: {
    color: '#423BC7',
    background: '#ffffff',
    border: 'solid 1px #423BC7',
    '&:hover': {
      color: '#423BC7',
      background: '#ffffff',
      border: 'solid 1px #423BC7',
    }
  },
}))

export default function Facebook (props) {
  const classes = useStyles()
  const [token, setToken] = useState(null)
  const expired = token?.expired_at ? new Date(token.expired_at) < new Date() : false

  // リダイレクトURLに ? があったりなかったりするので、URLパラメーターとしては取得できないことがある。
  // パラメーター部分は必ず # で始まるので、それ以降の文字列から抽出する。
  const getFacebookParamsFromUrl = () => {
    const url = window.location.href
    const startIndex = url.indexOf('#')
    if (startIndex < 0) { return {} }

    const paramString = url.substring(startIndex + 1)
    const items = paramString.split('&')
    const fbParams = {}
    items.forEach(item => {
      const [key, val] = item.split('=')
      fbParams[key] = decodeURIComponent(val)
    })
    return fbParams
  }

  useEffect(() => {
    (async () => {
      const fbParams = getFacebookParamsFromUrl()
      const accessToken = (fbParams.long_lived_token || fbParams.access_token)
      if (accessToken) {
        const expTime = parseInt(fbParams.data_access_expiration_time)
        await connectFB(accessToken, expTime)
        return
      } else {
        props.setLoading(true)
        try {
          // validate: Facebook側で連携解除した場合対応 (取得時にトークン検証をかける)
          const res = await Api.getExtApiToken({
            api_type: 'facebook',
            validate: true,
          })
          setToken(res)
        } catch (error) {
          BaseComponent.handleApiError(props, error)
        } finally {
          props.setLoading(false)
        }
      }
    })()
  }, [])

  const connectFB = async (accessToken, expTime) => {
    props.setLoading(true)
    try {
      const response = await Api.createExtApiToken({
        value: accessToken,
        api_type: 'facebook',
        expired_at: expTime,
      })
      setToken(response)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  const disconnectFB = async () => {
    if (!token) { return }
    props.setLoading(true)
    try {
      await Api.updateExtApiToken(token.id, { status: 'disabled_by_user' })
      setToken(null)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <>
      <AppHeader title="Facebook連携" backWhite {...props} />
      <div className={classes.container}>
        <Typography className={classes.text}>
          Facebook上で友達の場合<br />
          双方がアカウント連携すると<br />
          自分にも相手にも紹介されません。
        </Typography>
        <Typography className={classes.note}>
          ＊2人ともFacebook連携している場合のみ<br />
          ＊連携した場合も投稿は行われません<br />
          ＊3ヶ月ごとに再連携が必要
        </Typography>
        {token && (
          <Typography className={classes.expiredAt}>
            {expired ? '有効期限切れのため、再連携を行なってください。' : `連携有効期限：${dateFormat(token.expired_at, 'yyyy/mm/dd')}`}
          </Typography>
        )}
      </div>
      {!token || expired ? (
        <FacebookLogin
          appId="187235711118817"
          scope="public_profile,user_friends"
          responseType="token"
          render={(p) => (
            <Button
              className={`${classes.button} ${classes.connect}`}
              variant="contained"
              color="secondary"
              onClick={p.onClick}
            >
              Facebookと連携する
            </Button>
          )}
          isMobile
          redirectUri={`${Config.appBaseUrl}/Facebook`}
          language="ja_JP"
        />
      ) : (
        <Button
          className={`${classes.button} ${classes.disconnect}`}
          variant="contained"
          color="secondary"
          onClick={disconnectFB}
        >
          連携を解除する
        </Button>
      )}
    </>
  )
}

Facebook.propTypes = {
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
