import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import { isEmpty, isUsableCharacters } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import ConfirmDialog from 'components/parts/ConfirmDialog.jsx'
import PartnerPage from 'components/parts/PartnerPage'

const styles = theme => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    ...theme.styles.signupTextField,
    marginBottom: theme.spacing(2),
  },
  entry: {
    width: theme.spacing(12),
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(10),
    border: '1px solid',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  message: theme.styles.signupDescription,
  messageContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
})

export class PartnerPasscode extends BaseComponent {
  constructor (props) {
    super(props)

    this.submitPasscode = this.submitPasscode.bind(this)
    this.dialogClose = this.dialogClose.bind(this)
    this.onChangePasscode = this.onChangePasscode.bind(this)

    this._isMounted = false

    this.state = {
      isError: false,
      passcodeError: null,
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  onChangePasscode () {
    this.setState({passcodeError: null})
  }

  dialogClose () {
    this.setState({isError: false})
  }

  resetError () {
    this.setState({ passcodeError: null })
  }

  async submitPasscode () {
    this.resetError()

    // 入力のバリデーション
    let inputError = false

    let passcode = document.getElementById('passcode')

    // 入力されているか？
    if (isEmpty(passcode.value)) {
      this.setState({passcodeError: '認証コードを入力してください。'})
      inputError = true
    } else if (!isUsableCharacters(passcode.value)) {
      this.setState({passcodeError: '使用できない文字が含まれています。'})
      inputError = true
    } else if (passcode.value.length > 12) {
      this.setState({passcodeError: '最大文字長は１２文字です。'})
      inputError = true
    }

    if (inputError) { return }

    // ローディング表示
    this.props.setLoading(true)

    // 認証コード認証
    try {
      let data = await Api.authByPasscode(this.props.authId, passcode.value)
      this.props.setAuthId(data.auth.id)
      this.props.setOrganizationId(data.auth.organization_id)
      await this.loadMasters(data.auth.id)
      this.props.setScreen('PartnerPassword')
    } catch (error) {
      if (error.response.status === 401) {
        this.setStateIfMounted({passcodeError: '認証コードが間違っています。'})
      } else {
        this.handleApiError(error)
      }
      this.setStateIfMounted({isError: true})
    } finally {
      this.props.setLoading(false)
    }
  }

  componentWillMount () {
    if (this.props.authId == null) {
      this.props.setScreen('/')
    }
    if (isEmpty(this.props.mailAddress)) {
      this.props.setScreen('/')
    }
  }

  render () {
    const { classes, ...others } = this.props
    return (
      <React.Fragment>
        <ConfirmDialog
          open={this.state.isError}
          onClose={this.dialogClose}
          onOk={this.dialogClose}
          onCancel={this.dialogClose}
          confirm="yes" title="認証コード" message="認証コードの認証ができませんでした。"
        />
        <PartnerPage title="パスワード再設定" {...others}>
          <Typography className={classes.label}>
            本人確認（認証コード入力）
          </Typography>
          <TextField
            id="passcode"
            type="tel"
            margin="normal"
            variant="outlined"
            error={!isEmpty(this.state.passcodeError)}
            placeholder="認証コード"
            helperText={isEmpty(this.state.passcodeError) ? '' : this.state.passcodeError}
            className={classes.textField}
            color="secondary"
            onChange={this.onChangePasscode}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.entry}
              onClick={this.submitPasscode}
            >
              送信
            </Button>
          </div>
          <div className={classes.messageContainer}>
            <Typography className={classes.message}>
              メールにてお送りした認証コードを入力ください。
            </Typography>
          </div>
        </PartnerPage>
      </React.Fragment>
    )
  }
}
export default withStyles(styles)(PartnerPasscode)
