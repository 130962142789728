import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import LogoImage from 'images/logo_aill_goen_V.png'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
      content: '""',
      display: 'block',
      height: '100%',
      width: '100%',
      background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, .5), transparent)',
      position: 'absolute',
      animation: '$splash-animation forwards infinite',
      animationDuration: '2s',
    }
  },
  '@keyframes splash-animation': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    }
  },
}))

export default function LoadingScreen (props) {
  const { appLoading } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root} style={{ display: appLoading ? '' : 'none' }}>
        <img src={LogoImage} />
      </div>
    </React.Fragment>
  )
}

LoadingScreen.propTypes = {
  appLoading: PropTypes.bool,
}
