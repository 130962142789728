import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import InstagramIcon from 'components/entry/InstagramIcon'
import LineAddFriendIcon from 'components/entry/LineAddFriendIcon'
import Kagura from 'images/Komainu/firstNaviKagura.png'
import Sakaki from 'images/Komainu/firstNaviSakaki.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#EFF0FF',
    paddingTop: sp => theme.spacing(sp ? 5.25 : 8.25),
    paddingBottom: sp => theme.spacing(sp ? 3.75 : 9.25),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  title: {
    fontSize: sp => sp ? 14 : 28,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : 1,
    letterSpacing: sp => sp ? '1.4px' : '2.8px',
    textAlign: 'center',
    marginBottom: sp => theme.spacing(sp ? 3.25 : 6.5),
  },
  text: {
    fontSize: sp => sp ? 12 : 14,
    fontWeight: 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : '26px',
    letterSpacing: sp => sp ? '1.2px' : '1.4px',
    textAlign: sp => sp ? 'left' : 'center',
    marginTop: sp => theme.spacing(sp ? 5 : 3.75),
  },
  serifA: {
    width: sp => sp ? 36 : 45,
    height: 2,
    background: '#616AFA',
    transform: 'rotate(50deg)',
    marginRight: theme.spacing(1.5),
  },
  serifB: {
    width: sp => sp ? 36 : 45,
    height: 2,
    background: '#616AFA',
    transform: 'rotate(-50deg)',
    marginLeft: theme.spacing(1.5),
  },
  serif: {
    fontSize: sp => sp ? 12 : 16,
    fontWeight: sp => sp ? 300 : 600,
    color: '#433CC7',
    lineHeight: sp => sp ? '20px' : '26px',
    letterSpacing: sp => sp ? '1.2px' : '1.6px',
  },
  komainu: {
    width: sp => sp ? 156 : 279,
    position: 'relative',
    margin: 'auto',
  },
  name: {
    fontSize: sp => sp ? 14 : 21,
    fontWeight: sp => sp ? 300 : 600,
    color: '#423BC7',
    lineHeight: 1,
    textAlign: 'center',
    position: 'absolute',
    top: sp => sp ? 104 : 188,
    '& span': {
      fontSize: sp => sp ? 12 : 15,
      display: 'block',
      marginTop: sp => theme.spacing(sp ? 0.75 : 1),
    },
  },
  images: {
    width: sp => sp ? 233 : 812,
    margin: 'auto',
    marginTop: sp => theme.spacing(sp ? 6 : 0),
  },
}))

export default function SnsInfo () {
  const spMatches = !useMediaQuery('(min-width:600px)')
  const classes = useStyles(spMatches)

  const createImages = () => {
    if (spMatches) {
      return (
        <>
          {createKomainu()}
          <Grid className={classes.images} container justifyContent="space-between" alignItems="center">
            <InstagramIcon />
            <LineAddFriendIcon />
          </Grid>
        </>
      )
    } else {
      return (
        <Grid className={classes.images} container justifyContent="space-between" alignItems="center">
          <InstagramIcon />
          {createKomainu()}
          <LineAddFriendIcon />
        </Grid>
      )
    }
  }

  const createKomainu = () => (
    <Grid className={classes.komainu} container wrap="nowrap" justifyContent="space-between" alignItems="center">
      <img src={Kagura} width={'50%'} alt='' />
      <img src={Sakaki} width={'50%'} alt='' />
      <Typography className={classes.name} style={{ left: spMatches ? 28 : 35 }}>
        神楽
        <span>かぐら</span>
      </Typography>
      <Typography className={classes.name} style={{ right: spMatches ? 16 : 22 }}>
        榊
        <span>さかき</span>
      </Typography>
    </Grid>
  )

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        公式SNS「Aill縁結び神社」{spMatches && <br />}でも情報発信中！
      </Typography>
      <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
        <div className={classes.serifA} />
        <Typography className={classes.serif}>
          耳より恋愛情報も<br />
          お届けしてるよ！
        </Typography>
        <div className={classes.serifB} />
      </Grid>
      {createImages()}
    </div>
  )
}
