import React from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Const from 'commons/constant'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'
import FootAImage from 'images/tutorial/img_footprint_a.png'
import FootUnImage from 'images/tutorial/img_footprint_un.png'
import BackgroundImage from 'images/tutorial/img_message_background.png'
import exIcon1 from 'images/tutorial/img_photo_ex1.jpg'
import exIcon10 from 'images/tutorial/img_photo_ex10.jpg'
import exIcon11 from 'images/tutorial/img_photo_ex11.jpg'
import exIcon12 from 'images/tutorial/img_photo_ex12.jpg'
import exIcon2 from 'images/tutorial/img_photo_ex2.jpg'
import exIcon3 from 'images/tutorial/img_photo_ex3.jpg'
import exIcon4 from 'images/tutorial/img_photo_ex4.jpg'
import exIcon5 from 'images/tutorial/img_photo_ex5.jpg'
import exIcon6 from 'images/tutorial/img_photo_ex6.jpg'
import exIcon7 from 'images/tutorial/img_photo_ex7.jpg'
import exIcon8 from 'images/tutorial/img_photo_ex8.jpg'
import exIcon9 from 'images/tutorial/img_photo_ex9.jpg'

const useStyles = makeStyles(theme => ({
  paper: {
    background: 'linear-gradient(180deg, #E4D9F4 0%, #E4D9F4 50%, #D4E0F9 50%, #D4E0F9 100%)',
  },
  content: {
    overflowX: 'hidden',
    padding: 0,
    paddingTop: '0 !important',
  },
  bar: {
    ...theme.styles.header,
  },
  toolbar: {
    backgroundColor: '#B0B4FC',
    minHeight: theme.styles.header.height,
    position: 'relative',
  },
  barTitle: {
    width: '75%',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  backButton: {
    padding: 0,
    position: 'absolute',
    left: 16,
    color: theme.palette.secondary.main,
  },
  imageFootA: {
    width: 22,
    marginRight: theme.spacing(1),
  },
  imageFootUn: {
    width: 22,
    marginLeft: theme.spacing(1),
  },
  titleText: {
    fontSize: 11,
    ...theme.styles.messageColor,
    fontWeight: 'bold',
  },
  goodContainer: {
    backgroundColor: '#E4D9F4',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  point1container: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: 80,
    marginTop: `calc(${theme.styles.header.height}px - 20px + ${theme.spacing(3)}px)`,
    textAlign: 'center',
    position: 'relative',
  },
  avatarContainer: {
    marginLeft: -theme.spacing(3.75),
    height: 32,
  },
  goodAvatar: {
    width: 36,
    height: 36,
    objectFit: 'contain',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  goodTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#8636D6',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: theme.spacing(2),
  },
  exampleTitle: {
    color: '#8636D6',
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 8,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  imageContainer: {
    marginTop: theme.spacing(1.5),
  },
  speachContainer: {
    paddingLeft: theme.spacing(2.375),
  },
  faceAvatar: {
    marginLeft: -theme.spacing(3.75),
    marginRight: theme.spacing(1.375),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(1),
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 256,
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  speachUn: {
    ...theme.styles.naviSpeach(Const.colors.devilBackground),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 256,
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  iconTutorial: {
    width: 140,
    height: 140,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textTutorial: {
    fontSize: 11,
    color: '#8636D6',
    textAlign: 'center',
  },
  point2container: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: 80,
    textAlign: 'center',
    position: 'relative',
  },
  badContainer: {
    backgroundColor: '#D4E0F9',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  badPoint1container: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: 80,
    textAlign: 'center',
    position: 'relative',
  },
  badAvatarContainer: {
    marginLeft: theme.spacing(3.75),
    height: 32,
  },
  badTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2F6CD8',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingRight: theme.spacing(2),
  },
  badAvatar: {
    width: 36,
    height: 36,
    objectFit: 'contain',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  badExampleTitle: {
    color: '#2F6CD8',
    fontSize: 12,
    fontWeight: 'bold',
    position: 'absolute',
    bottom: 8,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  badTextTutorial: {
    fontSize: 11,
    color: '#2F6CD8',
    textAlign: 'center',
  },
  speach: {
    fontSize: 12,
  },
  space: {
    width: '100%',
    height: 24,
  },
}))

export default function TutorialPage (props) {
  const { open, tutorialIsBad, clickBack } = props
  const classes = useStyles()

  const scrollToBad = () => {
    const scroll = $('#scroll-tutorial')
    const scrollGood = $('#scroll-good')
    scroll.scrollTop(scrollGood.prop('scrollHeight') + 25)
  }

  const scrollToGood = () => {
    const scroll = $('#scroll-tutorial')
    scroll.scrollTop(0)
  }

  const createChatMessageElements = (msg, color, fontSize) => {
    let allElems = []
    const lines = msg.split(/\r\n|\r|\n/)
    for (let line of lines) {
      let style = { wordBreak: 'break-all', color: color }
      let elems = [<Typography className={fontSize} style={style} key={line}>{line}</Typography>]
      for (let elem of elems) {
        allElems.push(elem)
      }
    }
    return allElems
  }

  const createGoodItem = (imgSrc, text) => {
    return (
      <Grid item>
        <img src={imgSrc} className={classes.iconTutorial} alt="" />
        <Typography className={classes.textTutorial}>{text}</Typography>
      </Grid>
    )
  }

  const createBadItem = (imgSrc, text) => {
    return (
      <Grid item>
        <img src={imgSrc} className={classes.iconTutorial} alt="" />
        <Typography className={classes.badTextTutorial}>{text}</Typography>
      </Grid>
    )
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullScreen
      open={open}
      TransitionProps={{
        onEnter: tutorialIsBad ? scrollToBad : scrollToGood
      }}
    >
      <DialogContent id='scroll-tutorial' className={classes.content}>
        <AppBar position="fixed" className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <IconButton aria-label="add" className={classes.backButton} onClick={clickBack}>
              <LeftIcon />
            </IconButton>
            <Grid container justifyContent="center" alignItems="center" className={classes.barTitle}>
              <img src={FootAImage} className={classes.imageFootA} alt="" />
              <Typography className={classes.titleText} onClick={scrollToBad}>
                カグラとサカキのちょっとアドバイス
              </Typography>
              <img src={FootUnImage} className={classes.imageFootUn} alt="" />
            </Grid>
          </Toolbar>
        </AppBar>
        <div id='scroll-good'>
          <div className={classes.goodContainer}>
            <div className={classes.point1container}>
              <Grid container justifyContent="center" alignItems="center" className={classes.avatarContainer}>
                <img src={AImage} className={classes.goodAvatar} alt="" />
                <Typography className={classes.goodTitle}>
                  好印象の写真の法則はこれ！
                </Typography>
              </Grid>
              <Typography className={classes.exampleTitle}>
                Point1.自然の光が感じられると好印象だよ！
              </Typography>
            </div>
            <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
              {createGoodItem(exIcon7, '外で撮ってみよう！')}
              {createGoodItem(exIcon8, '明るければ室内もOK')}
              <div className={classes.point2container}>
                <Typography className={classes.exampleTitle}>
                  Point2.表情や服装はとても大切！
                </Typography>
              </div>
              <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
                {createGoodItem(exIcon9, '自然な笑顔で撮ってみよう')}
                {createGoodItem(exIcon10, '清潔感のある服にしょう')}
              </Grid>
              <div className={classes.point2container}>
                <Typography className={classes.exampleTitle}>
                  Point3.自撮り顔アップより、程よい距離で。
                </Typography>
              </div>
              <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
                {createGoodItem(exIcon11, '友達に撮影してもらおう')}
                {createGoodItem(exIcon12, '顔だけでなく、腰上ぐらいで')}
              </Grid>
              <div className={classes.space} />
              <Grid container justifyContent="center" alignItems="flex-end" className={classes.speachContainer}>
                <img src={AImage} className={classes.faceAvatar} alt="" />
                <div className={classes.speachA}>
                  {createChatMessageElements('少し手間はかかるけど、\nなるべく第一印象はよくしたいよね！', Const.colors.angel, classes.speach)}
                </div>
              </Grid>
              <Grid container justifyContent="center" alignItems="flex-end" className={classes.speachContainer}>
                <img src={UnImage} className={classes.faceAvatar} alt="" />
                <div className={classes.speachUn}>
                  {createChatMessageElements('写真写りで損することってあるよね。\n印象が悪い例も見るとよりわかるかも。', Const.colors.devil, classes.speach)}
                </div>
              </Grid>
              <div className={classes.space} />
            </Grid>
          </div>
        </div>
        <div className={classes.badContainer}>
          <div className={classes.badPoint1container}>
            <Grid container justifyContent="center" alignItems="center" className={classes.badAvatarContainer}>
              <Typography className={classes.badTitle}>
                こんな写真は避けよう！
              </Typography>
              <img src={UnImage} className={classes.badAvatar} alt="" />
            </Grid>
            <Typography className={classes.badExampleTitle}>
              Point1.顔がはっきり見えないと印象に残らない…
            </Typography>
          </div>
          <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
            {createBadItem(exIcon1, 'マスク・サングラスは外そう')}
            {createBadItem(exIcon2, '逆光に気をつけよう')}
          </Grid>
          <div className={classes.point2container}>
            <Typography className={classes.badExampleTitle}>
              Point2.洗面所やジムで自撮りは少し不自然かも…
            </Typography>
          </div>
          <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
            {createBadItem(exIcon3, '清潔感のある場所を選ぼう')}
            {createBadItem(exIcon4, '鏡ごしの自撮りは微妙')}
          </Grid>
          <div className={classes.point2container}>
            <Typography className={classes.badExampleTitle}>
              Point3.解像度が低いと印象に残りにくい！
            </Typography>
          </div>
          <Grid container direction="row" justifyContent="center" className={classes.imageContainer}>
            {createBadItem(exIcon5, '集合写真アップは注意')}
            {createBadItem(exIcon6, '夜間撮影も低解像度になりがち')}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  )
}
TutorialPage.propTypes = {
  open: PropTypes.bool,
  tutorialIsBad: PropTypes.bool,
  clickBack: PropTypes.func,
}