import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { get2byteLength } from 'commons/utility'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import BackgroundImage from 'images/photo-advice/dialog_bg.png'
import Illustration from 'images/photo-advice/dialog_illustration.png'
import Title from 'images/photo-advice/dialog_title.png'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
  },
  title: {
    padding: theme.spacing(5.5),
  },
  content: {
    marginBottom: theme.spacing(-5.75),
    '& img:nth-of-type(1)': {
      maxWidth: '273px !important',
      position: 'relative',
      left: -18,
    },
  },
  buttonA: {
    color: '#423BC7',
    backgroundColor: '#ffffff !important',
    textTransform: 'none',
    boxShadow: '0px 1px 3px #00000029 !important',
  },
  buttonB: {
    height: 48,
    color: '#423BC7',
    backgroundColor: '#ffffff !important',
    textTransform: 'none',
    boxShadow: '0px 1px 3px #00000029 !important',
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      lineHeight: 1,
    },
    '& small': {
      fontSize: 8.5,
      marginBottom: theme.spacing(0.5),
    },
  },
  actions: {
    paddingTop: 0,
    paddingBottom: theme.spacing(8.5),
    '& img': {
      position: 'relative',
      top: 1,
    },
  },
}))

export default function AdvicePhotoDialog (props) {
  const { user, open, onClose } = props
  const classes = useStyles()

  if (!user) { return null }

  const toPhotoAdvice = () => {
    onClose()
    props.setScreen('PhotoAdvice')
  }

  const createButton = () => {
    if (get2byteLength(user.nick_name) < 6) {
      return (
        <Button className={classes.buttonA} variant="contained" onClick={toPhotoAdvice}>
          {user.nick_name}さんの秘訣を見る
        </Button>
      )
    } else {
      return (
        <Button className={classes.buttonB} variant="contained" onClick={toPhotoAdvice}>
          <small>{user.nick_name}さんの</small>
          秘訣を見る
        </Button>
      )
    }
  }

  return (
    <>
      <DialogThemeProvider color={'default'}>
        <Dialog open={open} PaperProps={{ className: classes.paper }}>
          <DialogCloseButton onClick={onClose} />
          <DialogTitle className={classes.title} disableTypography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <img src={Title} width={273} height={261} />
          </DialogContent>
          <DialogActions className={classes.actions} disableSpacing>
            <img src={Illustration} width={132} height={126} />
            {createButton()}
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>

    </>
  )
}

AdvicePhotoDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setScreen: PropTypes.func,
  values: PropTypes.bool,
}
