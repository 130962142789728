import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'

const useStyles = makeStyles(theme => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  subTitle: {
    fontSize: 12,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formText: {
    marginTop: theme.spacing(2),
    fontSize: 10,
    paddingLeft: theme.spacing(1.5),
    listStyle: '"＊"'
  },
  divider: {
    background: '#e0e0e0',
  },
  reasonSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  select: {
    padding: '0 !important',
  },
  chip: {
    fontSize: 10,
    fontWeight: 600,
    color: '#ffffff',
    background: '#616AFA',
    maxWidth: 120,
    marginBottom: theme.spacing(0.2),
  },
  menuItem: {
    fontSize: 12,
    fontWeight: 600,
    color: '#707070',
  },
  selectedItem: {
    background: '#F4F7FE !important'
  },
  input: {
    width: '100%',
    fontSize: 12,
    border: '1px solid #999',
    background: '#FFFFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2.66),
  },
  inputError: {
    borderColor: 'red',
  },
}))

const maxReasonInputLength = 500

const reasonValues = {
  busy: '忙しくて時間が取れない',
  friend_max: '自分のトーク開通数がいっぱい',
  photo: '相手のプロフィール写真',
  age: '相手の年齢',
  address: '相手の住まい',
  job: '相手の仕事',
  self_introduction: '相手の自己紹介文',
  to_message: '相手からのいいね！メッセージ',
  holiday: '相手の休日',
  hobby: '相手の趣味',
  marriage_intention: '相手の将来の結婚意思',
  ideal_housework_and_childcare: '相手の家事育児の割合',
  marital_status: '相手の結婚歴',
  current_children: '相手の現在の子供の有無・人数',
  children: '相手の将来の子供の有無・人数',
  sibling: '相手の兄弟姉妹',
  height: '相手の身長',
  annual_income: '相手の年収',
  birthplace: '相手の出身',
  blood_type: '相手の血液型',
  smoking_status: '相手の喫煙状況',
  view_of_love: '相手の恋愛観',
  others: 'その他',
}

export default function EntryRejectDialog (props) {
  const { open, friend, onClose } = props
  const classes = useStyles()
  const [reasons, setReasons] = useState([])
  const [reasonDetail, setReasonDetail] = useState('')
  // 「その他」を選択しているかどうか
  const reasonIsOthers = reasons.includes('others')

  useEffect(() => {
    if (!open) { return }
    BaseComponent.gaModalView('withdrawal')
  }, [open])

  const handleChangeReasons = (event) => {
    const value = event.target.value
    const last = value[value.length - 1]
    setReasons(last === 'others' ? ['others'] : value.filter(item => item !== 'others'))
  }

  const handleChangeReasonInput = (event) => {
    const txt = event.target.value
    setReasonDetail(txt.trim())
  }

  const renderValue = (selected) => (
    <Grid container>
      {selected.map(value => <Chip className={classes.chip} key={value} label={reasonValues[value]} />)}
    </Grid>
  )

  const reasonDetailError = () => (reasonIsOthers && !reasonDetail) || reasonDetail.length > maxReasonInputLength

  const validate = () => (reasonIsOthers && reasonDetailError())

  const onReject = async () => {
    onClose()
    props.setLoading(true)
    try {
      const params = { reject_reasons: reasons, reject_reason_detail: reasonDetail }
      await BaseComponent.postEntry(props, friend.id, 'reject_friend', params)
      props.setScreen('FriendRequest')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      props.setLoading(false)
      BaseComponent.showRequestSuccessMessage(props, 'お断りしました')
    }
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} data-testid="withdrawal-dialog">
        <DialogTitle disableTypography>
          お断り<br />
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            本当にお断りしますか？
          </DialogContentText>
          <div className={classes.subTitle}>
            お断り理由（任意・複数選択可）：
          </div>
          <Divider className={classes.divider} />
          <Select
            className={classes.reasonSelect}
            classes={{ root: classes.select }}
            multiple
            disableUnderline
            value={reasons}
            onChange={handleChangeReasons}
            input={<Input />}
            IconComponent={() => <KeyboardArrowDown />}
            renderValue={renderValue}
            MenuProps={{
              PaperProps: { style: { maxHeight: 300 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
              getContentAnchorEl: null,
              autoFocus: false,
            }}
          >
            {Object.keys(reasonValues).map(key => (
              <MenuItem
                className={classes.menuItem}
                classes={{ selected: classes.selectedItem }}
                key={key}
                value={key}
              >
                {reasonValues[key]}
              </MenuItem>
            ))}
          </Select>
          <Divider className={classes.divider} />
          <div className={classes.subTitle}>
            理由が「その他」の場合：
          </div>
          <InputBase
            className={classes.input}
            classes={{ error: classes.inputError }}
            value={reasonDetail}
            onChange={handleChangeReasonInput}
            disabled={!reasonIsOthers}
            error={reasonDetailError()}
            placeholder="理由を入力"
            multiline
            rows="4"
          />
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onReject} disabled={validate()}>
            OK
          </Button>
          <Button variant="text" onClick={onClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

EntryRejectDialog.propTypes = {
  open: PropTypes.bool,
  friend: PropTypes.object,
  onClose: PropTypes.func,
  setLoading: PropTypes.func,
  setScreen: PropTypes.func,
}
