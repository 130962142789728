import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import StarImage01 from 'images/effect_star_1.png'
import StarImage02 from 'images/effect_star_2.png'
import AImage from 'images/img_character_a.png'
import UnImage from 'images/img_character_un.png'

const styles = theme => ({
  content: {
    position: 'relative',
  },
  subTitle: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    ...theme.styles.messageColor,
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    ...theme.styles.messageColor,
  },
  red: {
    fontSize: 15,
    color: '#F55083',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  starA: {
    position: 'absolute',
    top: '40%',
    left: 29,
  },
  starB: {
    position: 'absolute',
    top: '60%',
    right: 30,
  },
  faceAvatarA: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    height: theme.spacing(3),
    objectFit: 'contain',
    transform: 'rotate(-15deg)',
  },
  faceAvatarUn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    height: theme.spacing(3),
    objectFit: 'contain',
    transform: 'rotate(15deg)',
  },
})

export class NewOrganizationsDialog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
    }
  }

  render () {
    const { classes, organizations } = this.props
    const flag1000 = organizations.includes('文部科学省')

    return(
      <DialogThemeProvider color="default">
        <Dialog open={this.props.open}>
          <DialogTitle>
            <Grid container justifyContent="center" alignItems="center">
              <img src={AImage} className={classes.faceAvatarA} alt=""/>
              <Typography className={classes.titleText}>
                参加企業更新
              </Typography>
              <img src={UnImage} className={classes.faceAvatarUn} alt=""/>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <img className={classes.starA} src={StarImage01} width={20} height={46} />
            <img className={classes.starB} src={StarImage02} width={24} height={42} />
            <Typography className={classes.subTitle}>
              NEW!
            </Typography>
            {flag1000 && (
              <Typography className={classes.red}>
                祝&nbsp;&nbsp;1000社
              </Typography>
            )}
            {organizations.map(org => (
              <Typography key={org} className={classes.text}>
                {org}
              </Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <Button variant="contained" onClick={() => this.props.onOk()}>
                企業一覧へ
              </Button>
              <Button variant="text" onClick={() => this.props.onClose()}>
                閉じる
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

NewOrganizationsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  open: PropTypes.bool,
}

export default withStyles(styles)(NewOrganizationsDialog)