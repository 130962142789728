import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: 152,
    border: '1px solid #707070',
    padding: theme.spacing(3),
  },
  item: {
    padding: theme.spacing(1),
  },
  date: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    marginRight: theme.spacing(3),
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: '#1A1667',
  },
  link: {
    textDecoration: 'underline',
    textDecorationColor: '#1A1667',
  }
}))

export default function MembersInfo (props) {
  const { info, scroll } = props
  const classes = useStyles()
  const spMatches = !useMediaQuery('(min-width:600px)')

  return (
    <Grid className={classes.root} container alignItems="center">
      {info.map((data, index) => (
        <Grid className={classes.item} key={index} container wrap={spMatches ? 'wrap' : 'nowrap'}>
          <Typography className={classes.date}>
            {data.date}
          </Typography>
          <Typography
            className={`${classes.text} ${data.target ? classes.link : ''}`}
            onClick={() => data.target && scroll('campaignRef')}
          >
            {data.text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

MembersInfo.propTypes = {
  info: PropTypes.array,
  scroll: PropTypes.func,
}
