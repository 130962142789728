import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class ProfileIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 29.801 29.8" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_852" data-name="グループ 852" transform="translate(-43.1 -32.1)">
            <path id="パス_452" data-name="パス 452" d="M64.481,55.551,61.007,47.48a5.016,5.016,0,1,0-6.014,0l-3.474,8.071" fill="none" stroke="#989898" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8"/>
            <circle id="楕円形_125" data-name="楕円形 125" cx="14" cy="14" r="14" transform="translate(44 33)" fill="none" stroke="#989898" strokeLinejoin="round" strokeWidth="1.8"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(ProfileIcon)