import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#9d9d9e',
    strokeMiterlimit: 10,
    strokeWidth: 4,
  },
  cls2: {
    fill: '#9d9d9e',
    stroke: '#9d9d9e',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2,
  },
  cls3: {
    strokeWidth: 1.8,
    stroke: '#9d9d9e',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  },
}

class CampaignOffIcon extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <SvgIcon viewBox="0 0 25.154 28.381">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="25.154" height="28.381" viewBox="0 0 25.154 28.381">
          <g id="グループ_7" data-name="グループ 7" transform="translate(-121.083 -96.898)">
            <line id="線_5" data-name="線 5" y2="20.284" transform="translate(133.66 103.436)" className={classes.cls1}/>
            <g id="グループ_5" data-name="グループ 5">
              <path id="パス_5" data-name="パス 5" d="M128.179,97.9l-1.74,2.866,6.327,1.88Z" className={classes.cls2}/>
              <path id="パス_6" data-name="パス 6" d="M139.141,97.9l1.74,2.866-6.327,1.88Z" className={classes.cls2}/>
            </g>
            <g id="グループ_6" data-name="グループ 6">
              <rect id="長方形_13" data-name="長方形 13" width="23.354" height="5.008" rx="1.692" transform="translate(121.983 104.095)" className={classes.cls3}/>
              <rect id="長方形_14" data-name="長方形 14" width="19.628" height="15.276" rx="1.753" transform="translate(123.876 109.103)" className={classes.cls3}/>
            </g>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

CampaignOffIcon.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(CampaignOffIcon)
