import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import * as Storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import { httpNotFound } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import HeartIcon from 'images/heart_shake.png'

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: 10,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
  },
  faceAvatar: {
    width: 56,
    height: 56,
  },
  favoIcon: {
    width: 48,
    height: 48,
  },
}))

export default function RatingUpDialog (props) {
  const { open, title, notifications, user, onClose } = props
  const classes = useStyles()

  // ユーザーIDの重複削除
  const unique = Array.from(new Map(notifications.map(n => [n.friend_user_id, n])).values())

  const onConfirm = async () => {
    await onClose()
    if (unique.length === 1) {
      await loadFriend(unique[0].friend_user_id)
      Storage.setProfileSummaryIndex(0)
      props.setScreen('IntroductionProfile')
    } else {
      props.setScreen('Friends')
    }
  }

  const loadFriend = async (friendId) => {
    try {
      const friend = await Api.getFriend(friendId)
      props.setFriend(friend)
    } catch (error) {
      if (httpNotFound(error)) {
        BaseComponent.showErrorMessage(props, '友達解消されています。')
      } else {
        BaseComponent.handleApiError(props, error)
      }
    }
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          {unique.map((n, index) => (
            <Grid key={index}>
              <Typography key={index} variant="body1">
                {n.friend_nick_name}さんからの<br />好感度がUPしました！
              </Typography>
              <Grid className={classes.iconContainer}>
                <Avatar
                  src={user.photo_icon}
                  className={classes.faceAvatar}
                />
                <img src={HeartIcon} className={classes.favoIcon} alt="" />
                <Avatar src={n.photo_icon} className={classes.faceAvatar} />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={onConfirm}>
            確認する
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

RatingUpDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  notifications: PropTypes.array,
  user: PropTypes.object,
  onClose: PropTypes.func,
  setFriend: PropTypes.func,
  setScreen: PropTypes.func,
}
