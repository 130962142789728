import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import * as storage from 'commons/storage'
import BaseComponent from 'components/parts/BaseComponent'
import CommonProfile from 'components/parts/CommonProfile'

const styles = theme => ({
  root: {
  },
  backButton: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  title: {
    ...theme.styles.title,
    ...theme.styles.messageColor,
    marginTop: theme.spacing(4.5),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    ...theme.styles.messageColor,
    fontSize: 12,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  remarks: {
    fontSize: 12,
    marginTop: theme.spacing(2),
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: 'left',
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  inputContainer: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  reasonSelect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider:{
    ...theme.styles.dialogDivider,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  input: {
    ...theme.styles.dialogInput,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2.66),
    borderColor: '#AFB7F8',
    borderRadius: 8,
  },
  error: {
    borderColor: 'red',
  },
  sendButton: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: theme.spacing(28.75),
    height: theme.spacing(5.5),
  },
  cancelButton: {
    ...theme.styles.dialogOkButton,
    fontSize: 12,
    textAlign: 'center',
    width: theme.spacing(20),
    height: theme.spacing(5.25),
  },
})

const maxReasonInputLength = 300

const reasonValues = {
  privateAddress: 'プライベートアドレス交換の催促',
  business: 'ビジネス目的',
  play: '遊び目的',
  impersonation: '第三者なりすまし',
  improperChat: 'メッセージが不適切',
  others: 'その他',
}

class ViolationReport extends BaseComponent {
  constructor (props) {
    super(props)

    this.handleChangeReason = this.handleChangeReason.bind(this)
    this.handleChangeReasonInput = this.handleChangeReasonInput.bind(this)
    this.sendReport = this.sendReport.bind(this)
    this.handleCancel = this.handleCancel.bind(this)

    this.state = {
      friend: props.friend || storage.getFriend(),
      reason: 'privateAddress',
      reasonInput: '',
      disabledReasonInput: true,
      errorReasonInput: false
    }
  }

  handleChangeReason (event) {
    let value = event.target.value
    this.setState({
      reason: value,
      disabledReasonInput: (value !== 'others'),
      errorReasonInput: false
    })
  }

  handleChangeReasonInput (event) {
    var txt = event.target.value
    if (maxReasonInputLength < txt.length) {
      txt = txt.slice(0, maxReasonInputLength)
    }
    this.setState({
      reasonInput: txt,
      errorReasonInput: false
    })
  }

  async sendReport () {
    let valid = this.validate()
    if (!valid) { return }

    if (!this.props.user) { return }
    if (!this.state.friend) { return }

    this.props.setLoading(true)
    await this.postRejection()
    try {
      await this.postViolationReport()
      this.props.setScreen('/Home')
    } catch (error) {
      this.handleApiError(error)
    } finally {
      this.props.setLoading(false)
    }
  }

  async postRejection () {
    let entryType = this.getRejectionType()
    if (!entryType) { return }

    try {
      await this.postEntry(this.state.friend.id, entryType)
    } catch (error) {
      //noop
    }
  }

  getRejectionType () {
    switch (this.state.friend.type) {
      case 'friend':
        return 'reject_friend'
      case 'lover_applying':
      case 'lover_receiving':
      case 'lover':
        return 'reject_lover'
      default:
        return ''
    }
  }

  async postViolationReport () {
    let msg = this.getReasonText()
    await this.postEntry(this.state.friend.id, 'report_violation', {
      to_message: msg
    })
  }

  getReasonText () {
    if (this.state.reason === 'others') {
      return this.state.reasonInput
    } else {
      return reasonValues[this.state.reason]
    }
  }

  validate () {
    if (this.state.reason === 'others' && !this.state.reasonInput.trim()) {
      this.setState({ errorReasonInput: true })
      return false
    } else {
      return true
    }
  }

  handleCancel () {
    BaseComponent.goBack(this.props)
  }

  render () {
    if (!this.state.friend) { return <React.Fragment /> }

    const { classes, ...other } = this.props
    return (
      <div className={classes.root}>
        <IconButton aria-label="add" className={classes.backButton} onClick={this.handleCancel} >
          <LeftIcon />
        </IconButton>
        <Typography className={classes.title}>違反者を報告する</Typography>
        <Typography className={classes.text}>
          不適切なやりとりがあった際には<br />
          Aillまでご連絡ください。
        </Typography>
        <CommonProfile
          user={this.props.user}
          component={this}
          simple={true}
          {...other}
          friend={this.state.friend}
        />
        <Typography className={classes.remarks}>
          ※報告後、{this.state.friend.nick_name}&nbsp;さんとは「友達解消」となります。<br />
          今後その異性とのコミュニケーションや情報を閲覧することができなくなりますのでご注意ください。
        </Typography>
        <Typography className={classes.subTitle}>
          理由：
        </Typography>
        <Divider className={classes.divider}/>
        <div className={classes.inputContainer}>
          <Select
            value={this.state.reason}
            onChange={this.handleChangeReason}
            className={classes.reasonSelect}
            IconComponent={() => (
              <KeyboardArrowDown />
            )}
            disableUnderline
          >
            {
              Object.keys(reasonValues).map(key => {
                return (
                  <MenuItem key={key} value={key}>
                    {reasonValues[key]}
                  </MenuItem>
                )
              })
            }
          </Select>
        </div>
        <Divider className={classes.divider}/>
        <Typography className={classes.subTitle}>
          理由が「その他」の場合：
        </Typography>
        <div className={classes.inputContainer}>
          <InputBase
            classes={{ error: classes.error }}
            value={this.state.reasonInput}
            onChange={this.handleChangeReasonInput}
            className={classes.input}
            disabled={this.state.disabledReasonInput}
            error={this.state.errorReasonInput}
            placeholder="メッセージを入力"
            multiline
            rows="6"
          />
        </div>
        <Grid container direction='column' alignItems="center" justifyContent="center">
          <Grid item>
            <Button variant="contained" color="secondary" className={classes.sendButton} onClick={this.sendReport}>
              送信する
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" color="secondary" className={classes.cancelButton} onClick={this.handleCancel}>
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

ViolationReport.propTypes = {
  user: PropTypes.object,
}

export default withStyles(styles)(ViolationReport)
