import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Img1 from 'images/tutorial/secret_question_room_suggest_dialog_img_1.png'
import Img2 from 'images/tutorial/secret_question_room_suggest_dialog_img_2.png'

const useStyles = makeStyles(theme => ({
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
  slideIcon: {
    fontSize: 8,
    color: '#ffffff',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slideIconsContainer: {
    width: '100%',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slide: {
    textAlign: 'center',
    position: 'relative',
  },
  actions: {
    paddingTop: theme.spacing(1.5),
  },
}))

export default function SecretQuestionRoomSuggestDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()
  const [slideIndex, setSlideIndex] = useState(0)

  useEffect(() => {
    if (!open) { return }
    setSlideIndex(0)
  }, [open])

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  const createPage1 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.subTitle} variant="subtitle1">
        将来の価値観を共有
      </Typography>
      <Typography className={classes.text} variant="body1">
        自分では聞きにくい相手の価値観を<br />
        狛犬がイベント形式で質問！
      </Typography>
      <img src={Img1} width={210} />
    </div>
  )

  const createPage2 = () => (
    <div style={Object.assign({})} className={classes.slide}>
      <Typography className={classes.subTitle} variant="subtitle1">
        将来の価値観を共有
      </Typography>
      <Typography className={classes.text} variant="body1">
        親密度が上がるほど<br />
        質問内容もより踏み込んだ内容に！
      </Typography>
      <img src={Img2} width={210} />
    </div>
  )

  const lastPage = () => {
    const lastPageNum = 1
    return slideIndex === lastPageNum
  }

  return (
    <DialogThemeProvider color="secondary">
      <Dialog open={open}>
        <DialogTitle disableTypography>
          秘密の質問部屋
        </DialogTitle>
        <DialogContent>
          <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
            {createPage1()}
            {createPage2()}
          </SwipeableViews>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container justifyContent="center">
            <div className={classes.slideIconsContainer}>
              {slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              {slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
            </div>
            <Button
              variant="contained"
              onClick={() => lastPage() ? onClose() : setSlideIndex(slideIndex + 1)}
            >
              {lastPage() ? '閉じる' : '次へ'}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SecretQuestionRoomSuggestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
