import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import Const from 'commons/constant'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import AImage from 'images/img_character_a.png'
import Image1 from 'images/tutorial/profile_public_dialog_img_1.png'
import Image2 from 'images/tutorial/profile_public_dialog_img_2.png'
import Image3 from 'images/tutorial/profile_public_dialog_img_3.png'

const styles = theme => ({
  slideIconsContainer: {
    width: '100%',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slideIcon: {
    fontSize: 8,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slide: {
    height: '100%',
  },
  text: {
    marginBottom: theme.spacing(1.5),
  },
  speachA: {
    ...theme.styles.naviSpeach(Const.colors.angelBackground3),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: 'calc(100% - 52px)',
    maxWidth: 300,
    marginTop: theme.spacing(1),
  },
  speachTextA: {
    fontSize: 12,
    fontWeight: 400,
    color: Const.colors.angel,
    wordBreak: 'break-all',
    textAlign: 'left',
  },
  faceAvatar: {
    marginRight: theme.spacing(1),
    width: 36,
    height: 36,
    objectFit: 'contain',
    marginBottom: theme.spacing(1),
  },
  faceAvatarA: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: 24,
    objectFit: 'contain',
    transform: 'rotate(-15deg)',
  },
  image1: {
    width: '100%',
    marginTop: theme.spacing(2),
    objectFit: 'contain',
  },
  image2: {
    width: '100%',
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
})

export class ProfilePublicTutorial extends BaseComponent {
  constructor (props) {
    super(props)

    this.state = {
      slideIndex: 0,
    }
  }

  async componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.open) !== JSON.stringify(prevProps.open)) {
      if (this.props.open) {
        this.setState({slideIndex: 0})
      }
    }
  }

  onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      this.setState({slideIndex: index})
    }
  }

  handleClose = () => {
    this.props.onClose()
  }

  render () {
    const { classes, ...other } = this.props
    return(
      <DialogThemeProvider color="secondary">
        <Dialog {...other}>
          <SwipeableViews
            index={this.state.slideIndex}
            onChangeIndex={this.onChangeSlideIndex}
          >
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>プロフィールを公開しよう！</DialogTitle>
              <DialogContent>
                <Grid container wrap="nowrap" justifyContent="center" alignItems="flex-end">
                  <img src={AImage} className={classes.faceAvatar} alt=""/>
                  <div className={classes.speachA}>
                    <Typography className={classes.speachTextA} variant="body1">
                      プロフィールを公開するほど親密度が上がるよ！
                    </Typography>
                  </div>
                </Grid>
                <Grid container justifyContent="center">
                  <img src={Image1} className={classes.image1} alt=""/>
                </Grid>
              </DialogContent>
            </div>
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>プロフィール編集の入り口</DialogTitle>
              <DialogContent>
                <Typography className={classes.text} variant="subtitle1">
                  ●共通設定
                </Typography>
                <Typography className={classes.text} variant="body1">
                  左上メニューより、みんなに公開するプロフィールを変更できます。
                </Typography>
                <Grid container justifyContent="center">
                  <img src={Image2} className={classes.image2} alt=""/>
                </Grid>
              </DialogContent>
            </div>
            <div style={Object.assign({})} className={classes.slide}>
              <DialogTitle disableTypography>プロフィール編集の入り口</DialogTitle>
              <DialogContent>
                <Typography className={classes.text} variant="subtitle1">
                  ●友達毎の設定
                </Typography>
                <Typography className={classes.text} variant="body1">
                  トークルームの右上メニューより、友達だけに公開するプロフィールを設定できます。
                </Typography>
                <Grid container justifyContent="center">
                  <img src={Image3} className={classes.image2} alt=""/>
                </Grid>
              </DialogContent>
            </div>
          </SwipeableViews>
          <DialogActions>
            <Grid container justifyContent="center">
              <div className={classes.slideIconsContainer}>
                {this.state.slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                {this.state.slideIndex === 2 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
              </div>
              <Button
                variant="contained"
                onClick={() => this.state.slideIndex === 2 ? this.handleClose() : this.setState({slideIndex: this.state.slideIndex + 1})}
              >
                {this.state.slideIndex === 2 ? '閉じる' : '次へ'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
    )
  }
}

ProfilePublicTutorial.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(ProfilePublicTutorial)
