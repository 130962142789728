import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
})

export default function DialogCloseButton (props) {
  const { onClick, style } = props
  const classes = useStyles()
  return (
    <IconButton
      className={classes.closeButton}
      style={style}
      onClick={() => onClick && onClick() }
    >
      <CloseIcon />
    </IconButton>
  )
}

DialogCloseButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
}