import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FreeTermExtensionChallengeAchievedDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeAchievedDialog'
import FreeTermExtensionChallengeDialog from 'components/free-term-extension-challenge/FreeTermExtensionChallengeDialog'
import FirstBoostImg from 'images/first_boost.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: 80,
    fontWeight: 600,
    color: '#ffffff',
    background: '#F55083',
    paddingTop: theme.spacing(2.5),
    position: 'relative',
    boxSizing: 'border-box',
  },
  img: {
    position: 'absolute',
    top: 6,
    left: 40,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
  },
}))

export default function FreeTermExtensionChallengeBanner (props) {
  const { missions, ...others } = props
  const classes = useStyles()
  const [openFreeTermExtensionChallengeDialog, setOpenFreeTermExtensionChallengeDialog] = useState(false)
  const [openFreeTermExtensionChallengeAchievedDialog, setOpenFreeTermExtensionChallengeAchievedDialog] = useState(false)

  // フレンド申請ミッション抽出
  const friendApplyMission = missions.find(mission => mission.mission_type === 'friend_apply')
  // トーク開通ミッション抽出
  const chatOpenMission = missions.find(mission => mission.mission_type === 'chat_open')

  const onClick = () => {
    // 達成したミッション抽出
    const achievedMissions = missions.filter(mission => mission.achieved)
    // 全てのミッションをクリアしている場合、達成済みダイアログ表示
    // 未達成のミッションがある場合、進捗ダイアログ表示
    const achievedAll = missions.length === achievedMissions.length
    achievedAll ? setOpenFreeTermExtensionChallengeAchievedDialog(true) : setOpenFreeTermExtensionChallengeDialog(true)
  }

  return (
    <>
      <div className={classes.root} onClick={onClick}>
        <img className={classes.img} src={FirstBoostImg} />
        <Typography className={classes.text}>
          ミッション達成で無料期間が延長！
        </Typography>
        <Typography className={classes.title}>
          無料トライアル期間 延長チャレンジ
        </Typography>
      </div>
      <FreeTermExtensionChallengeDialog
        open={openFreeTermExtensionChallengeDialog}
        onClose={() => setOpenFreeTermExtensionChallengeDialog(false)}
        friendApplyMission={friendApplyMission}
        chatOpenMission={chatOpenMission}
        {...others}
      />
      <FreeTermExtensionChallengeAchievedDialog
        open={openFreeTermExtensionChallengeAchievedDialog}
        onClose={() => setOpenFreeTermExtensionChallengeAchievedDialog(false)}
        {...others}
      />
    </>
  )
}

FreeTermExtensionChallengeBanner.propTypes = {
  missions: PropTypes.array,
}
