import AWS from 'aws-sdk'
import Api from './api'
import Const from './constant'

// 外部API呼出し
export default class ExternalApi {
  // -------- Amazon S3 --------

  static async uploadFile (file, s3Key) {
    let credentials = await Api.createFederationToken()
    let s3 = ExternalApi.createS3Client(credentials)
    let params = {
      Bucket: Const.s3Bucket,
      Key: s3Key,
      Body: file,
      ContentType: file.type,
    }
    return new Promise((resolve, reject) => {
      s3.putObject(params, (error, data) => {
        if (error) {
          reject(error)
        } else {
          resolve(data)
        }
      })
    })
  }

  static createS3Client (credentials) {
    return new AWS.S3({
      accessKeyId: credentials.access_key_id,
      secretAccessKey: credentials.secret_access_key,
      sessionToken: credentials.session_token,
      region: Const.region
    })
  }
}