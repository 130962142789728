import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LeftIcon from '@material-ui/icons/KeyboardBackspace'
import Api from 'commons/api'
import Const from 'commons/constant'
import { isEmpty } from 'commons/utility'
import BaseComponent from 'components/parts/BaseComponent'
import LeftSpeech from 'components/parts/LeftSpeech'
import NaviSpeech from 'components/parts/NaviSpeech'
import RightSpeech from 'components/parts/RightSpeech'
import ImageTop from 'images/img_demo_1.png'
import ImageGood from 'images/img_demo_2.png'
import ImageBad from 'images/img_demo_3.png'
import ImageFemale from 'images/img_demo_female.png'
import ImageMale from 'images/img_demo_male.png'
import logoTitle from 'images/logo_aill_2021_negative.png'

const demo_messages = {
  demo_female_1_1: [
    {
      id: 'female_1_1_1',
      message_type: 'message',
      message: '趣味は旅行なのですね！',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_1_2',
      message_type: 'message',
      message: 'はい！そうです〜(^^)',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_1_3',
      message_type: 'message',
      message: '最近どこか旅行に行きました？',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_1_4',
      message_type: 'message',
      message: 'なかなか休みが取れず、行けてないです。\n最近どこか行きました？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_1_5',
      message_type: 'message',
      message: '1年前ですが、トルコのイスタンブールに行きました。',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_1_6',
      message_type: 'message',
      message: '「イスタンブール」ここ詳しく聞きたいね。\nどんなところが良かったのかな？',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
      navi_to: 'user',
      answer_1: '聞いてみる',
      answer_2: '無視する',
      next_1: 'demo_female_1_2',
      next_2: 'demo_female_1_3',
    },
  ],
  demo_female_1_2: [
    {
      id: 'female_1_2_1',
      message_type: 'message',
      message: 'イスタンブールのどんなところが良かったのですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_2_2',
      message_type: 'message',
      message: '東洋と西洋の融合の、独特の文化や雰囲気が良かったです(^^)',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_2_3',
      message_type: 'message',
      message: '「東洋と西洋の融合の、独特の文化や雰囲気が良かったです」ここ詳しく聞きたいかも。\n好きになったきっかけとかあるの？',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
    },
    {
      id: 'female_1_2_4',
      message_type: null,
      message: '',
      created_at: '',
      target: 'navi',
      navi_to: 'user',
      answer_1: '男性の回答を待つ',
      answer_2: '無視する',
      next_1: 'demo_female_1_4',
      next_2: 'demo_female_1_5',
    },
  ],
  demo_female_1_3: [
    {
      id: 'female_1_3_1',
      message_type: 'message',
      message: 'そうなのですね！いいですね！\n最近、旅行に行けてないので羨ましいですw',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_3_2',
      message_type: 'message',
      message: '次の大型連休など行けるといいですね(^^)',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_3_3',
      message_type: 'message',
      message: 'ですねw',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_3_4',
      message_type: null,
      message: '会話が浅くなることが多いみたい。。。',
      created_at: '',
      target: 'finished',
      image: 'Bad',
    },
  ],
  demo_female_1_4: [
    {
      id: 'female_1_4_1',
      message_type: 'message',
      message: 'なんか犬が出てきたw\nもともと歴史が好きなんですよ〜。\n古の人物や事象って、勉強になりますし、そこから新たなことを発見したり進化させることが好きなので。。。',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_4_2',
      message_type: null,
      message: '相手の考えや思いを引き出す\nアシストをするよ！',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_female_1_5: [
    {
      id: 'female_1_5_1',
      message_type: 'message',
      message: 'そうなのですね！素敵ですね！\nトルコ行ったこと無いです〜。',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_5_2',
      message_type: 'message',
      message: 'とてもいいところですよ〜！\nぜひ行ってみてくださいw',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_5_3',
      message_type: 'message',
      message: '機会があれば！行く時はオススメを教えてください(^^)',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_1_5_4',
      message_type: 'message',
      message: 'もちろんです！',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_1_5_5',
      message_type: null,
      message: '一見、いい感じだけど・・・\n会話から相手の人となりは\n見えにくいね。。。',
      created_at: '',
      target: 'finished',
      image: 'Bad',
    },
  ],
  demo_female_2_1: [
    {
      id: 'female_2_1_1',
      message_type: 'message',
      message: 'おはようございます！',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_1_2',
      message_type: 'message',
      message: 'おはようございます！',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_1_3',
      message_type: 'message',
      message: '今日何しているのですか？',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_1_4',
      message_type: 'message',
      message: 'ドラマ見てます',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_1_5',
      message_type: 'message',
      message: '俺も家でまったり中です〜',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_1_6',
      message_type: 'message',
      message: 'そうなのですね！',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_1_7',
      message_type: 'message',
      message: '昨日はいつもより帰りが遅くなったので疲れました〜',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_1_8',
      message_type: 'message',
      message: 'そうなのですね。お疲れ様です！',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_1_9',
      message_type: 'message',
      message: '「いつもより帰りが遅く」ってどういうことだろうね？？\n聞いてみたいね！',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
      navi_to: 'user',
      answer_1: '聞いてみる',
      answer_2: '無視する',
      next_1: 'demo_female_2_2',
      next_2: 'demo_female_2_3',
    },
  ],
  demo_female_2_2: [
    {
      id: 'female_2_2_1',
      message_type: 'message',
      message: '「いつもより遅く」ってどうしたのですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_2_2',
      message_type: 'message',
      message: '実は、会社の新プロジェクトを任されることになって・・・それで遅くなりました',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_2_3',
      message_type: 'message',
      message: '「新プロジェクトを任される」ここ詳しく聞いてみたいね！\n具体的にどんな「仕事」かな？',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
      navi_to: 'user',
      answer_1: '聞いてみる',
      answer_2: '無視する',
      next_1: 'demo_female_2_4',
      next_2: 'demo_female_2_5',
    },
  ],
  demo_female_2_3: [
    {
      id: 'female_2_3_1',
      message_type: 'message',
      message: 'ありがとうございますw',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_3_2',
      message_type: 'message',
      message: '数日後',
      created_at: '',
      target: 'time',
      navi_type: null,
    },
    {
      id: 'female_2_3_3',
      message_type: 'message',
      message: '今日はいい天気ですね！\nせっかくの日曜日は何して過ごすのですかー？？',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_3_4',
      message_type: 'message',
      message: '数時間後（夕方以降）',
      created_at: '',
      target: 'time',
      navi_type: null,
    },
    {
      id: 'female_2_3_5',
      message_type: 'message',
      message: '今日はいい天気でしたね！\nせっかくなので、洗濯や家の掃除をしました〜(^^)',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_3_6',
      message_type: null,
      message: '世間話や社交辞令的な会話になることが\n多いみたい。',
      created_at: '',
      target: 'finished',
      image: 'Bad',
    },
  ],
  demo_female_2_4: [
    {
      id: 'female_2_4_1',
      message_type: 'message',
      message: '凄いですね!!新プロジェクト任されるなんて！\nどんなプロジェクトなのですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_4_2',
      message_type: 'message',
      message: '詳しくは言えないのですが、新エネルギーの可能性調査をすることになりました。',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_4_3',
      message_type: null,
      message: 'さらに、人となりが聞けるアシストも\n入るので乞うご期待！',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_female_2_5: [
    {
      id: 'female_2_5_1',
      message_type: 'message',
      message: '凄いですね！！仕事頑張ってくださいね！応援してます＼(^o^)／',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'female_2_5_2',
      message_type: 'message',
      message: 'ありがとうございます！',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'female_2_5_3',
      message_type: null,
      message: '今回は仕事を聞くチャンス、\n逃しちゃったかも。。。',
      created_at: '',
      target: 'finished',
      image: 'Bad',
    },
  ],
  demo_male_1_1: [
    {
      id: 'male_1_1_1',
      message_type: 'message',
      message: 'Netflix、自分も良く観ますよ〜！\n最近は何観ました？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_1_2',
      message_type: 'message',
      message: 'ワイルド・スピード観ました(^^)',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_1_1_3',
      message_type: 'message',
      message: 'アクション系の映画が好きなのですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_1_4',
      message_type: 'message',
      message: 'アクションも好きですし、ミステリーも好きです♪',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_1_1_5',
      message_type: 'message',
      message: 'いいですね！今度、今やっているアクション映画一緒にいきませんか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_1_6',
      message_type: 'message',
      message: 'まだ誘わない方がいいかも。本当にそのメール送信する？',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
      navi_to: 'user',
      answer_1: '送信する',
      answer_2: '送信しない',
      next_1: 'demo_male_1_2',
      next_2: 'demo_male_1_3',
    },
  ],
  demo_male_1_2: [
    {
      id: 'male_1_2_1',
      message_type: 'message',
      message: 'いいですね！今度、今やっているアクション映画一緒にいきませんか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_2_2',
      message_type: 'message',
      message: 'そうですね。日程が合えば(^^)',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_1_2_3',
      message_type: 'message',
      message: 'いつが空いてます？？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_2_4',
      message_type: 'message',
      message: '7日以上返信無し',
      created_at: '',
      target: 'time',
      navi_type: null,
    },
    {
      id: 'male_1_2_5',
      message_type: null,
      message: '無理に誘うと、ご縁が切れてしまうかも。\n僕たちからの「デート誘おう合図」を\n待つことオススメ。',
      created_at: '',
      target: 'finished',
      image: 'Bad',
    },
  ],
  demo_male_1_3: [
    {
      id: 'male_1_3_1',
      message_type: 'message',
      message: 'デートに行けるタイミングになったら、合図するね！！',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
      navi_to: 'user',
    },
    {
      id: 'male_1_3_2',
      message_type: 'message',
      message: '「アクション」ここ詳しく聞いてみたいね！\n特に好きなアクション映画は何かな？',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
      navi_to: 'user',
    },
    {
      id: 'male_1_3_3',
      message_type: 'message',
      message: '特に好きなアクション映画は何ですか？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_1_3_4',
      message_type: 'message',
      message: 'ワイルド・スピードのSKY MISSIONが好きです♪',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_1_3_5',
      message_type: null,
      message: 'ご縁が続くよ！\n段階を踏んで女性と\n距離を縮めていくことがポイント！\nボクたちが「デート誘おう合図する時\n＝ほぼデートOKもらえる時」だよ♪',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_male_2_1: [
    {
      id: 'male_2_1_1',
      message_type: 'message',
      message: '趣味は旅行なのですね！',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_1_2',
      message_type: 'message',
      message: 'はい！そうです〜(^^)',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_2_1_3',
      message_type: 'message',
      message: '最近どこか旅行に行きました？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_1_4',
      message_type: 'message',
      message: 'なかなか休みが取れず、行けてないです。\n最近どこか行きました？',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_2_1_5',
      message_type: 'message',
      message: '1年前ですが、トルコのイスタンブールに行きました。',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_1_6',
      message_type: 'message',
      message: 'イスタンブールのどんなところが良かったのですか？',
      created_at: '',
      target: 'friend',
      navi_type: null,
    },
    {
      id: 'male_2_1_7',
      message_type: 'message',
      message: '東洋と西洋の融合の、独特の文化や雰囲気が良かったです(^^)',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_1_8',
      message_type: 'message',
      message: '「東洋と西洋の融合の、独特の文化や雰囲気が良かったです」ここ詳しく聞きたいかも。\n好きになったきっかけとかあるの？',
      created_at: '',
      target: 'navi',
      navi_type: 'devil',
    },
    {
      id: 'male_2_1_9',
      message_type: 'message',
      message: 'ここしっり答えると好印象だよ！頑張って！！',
      created_at: '',
      target: 'navi',
      navi_type: 'angel',
      navi_to: 'user',
      answer_1: 'しっかり応える',
      answer_2: '無視する',
      next_1: 'demo_male_2_2',
      next_2: 'demo_male_2_3',
    },
  ],
  demo_male_2_2: [
    {
      id: 'male_2_2_1',
      message_type: 'message',
      message: 'なんか犬が出てきたw\nもともと歴史が好きなんですよ〜。\n古の人物や事象って、勉強になりますし、そこから新たなことを発見したり進化させることが好きなので。。。',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_2_2',
      message_type: null,
      message: 'ナイス回答！\n女性が好印象抱くポイントで\n「ここしっかり〜」が出現すること多いよ！',
      created_at: '',
      target: 'finished',
      image: 'Good',
    },
  ],
  demo_male_2_3: [
    {
      id: 'male_2_3_1',
      message_type: 'message',
      message: 'なんか犬が出てきたw\nもともと歴史が好きなのでw\nトルコ行ったことあります？',
      created_at: '',
      target: 'user',
      navi_type: null,
    },
    {
      id: 'male_2_3_2',
      message_type: null,
      message: '女性が好印象を抱くポイントを\n逃しちゃったかも。\n次の話題で挽回を！',
      created_at: '',
      target: 'finished',
      image: 'Bad',
    },
  ],
}

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
  },
  bar: {
    ...theme.styles.header,
  },
  toolbar: {
    minHeight: theme.styles.header.height,
    position: 'relative'
  },
  logo: {
    height: 40,
    margin: 'auto',
  },
  backButton: {
    padding: 0,
    position: 'absolute',
    left: 16,
    color: theme.palette.primary.main,
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.styles.header.height,
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
  },
  startButton: {
    ...theme.styles.entryButton,
    fontSize: 24,
    fontWeight: 'bold',
    width: 240,
    height: 48,
    marginTop: theme.spacing(5),
  },
  image: {
    width: 278,
    height: 128,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1),
    objectFit: 'contain',
  },
  message: {
    ...theme.styles.messageColor,
    fontSize: 16,
    lineHeight: 1.75,
    marginTop: theme.spacing(4),
  },
  messageTitle: {
    ...theme.styles.messageColor,
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  scroll: {
    width: '100%',
    textAlign: 'left',
  },
  messageAnimation: {
    animation: '$message-animation forwards',
    animationDuration: '1s',
  },
  '@keyframes message-animation': {
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1
    },
    '0%': {
      opacity: 0,
    }
  },
  timeAnimation: {
    animation: '$time-animation forwards',
    animationDuration: '4s',
  },
  '@keyframes time-animation': {
    '100%': {
      transform: 'translateX(0px)',
      opacity: 1
    },
    '20%': {
      transform: 'translateX(0px)',
      opacity: 1
    },
    '0%': {
      transform: `translateX(-${80}px)`,
      opacity: 0,
      visibility: 'hidden'
    }
  },
  timeContainer: {
    width: '80%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  finishContainer: {
    textAlign: 'center',
  },
  finishButton: {
    ...theme.styles.entryButton,
    fontSize: 16,
    fontWeight: 'bold',
    width: 200,
    height: 44,
  },
  finishText: {
    fontSize: 14,
    whiteSpece: 'pre-wrap',
    marginTop: theme.spacing(4),
  },
  finishImageGood: {
    width: 144,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  finishImageBad: {
    width: 144,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    objectFit: 'contain',
  },
  naviAngel: {
    color: Const.colors.angel,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
  naviAngelSecret: {
    color: Const.colors.angelSecret,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
  naviDevil: {
    color: Const.colors.devil,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
  naviDevilSecret: {
    color: Const.colors.devilSecret,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: theme.spacing(8.5),
    marginBottom: theme.spacing(1),
  },
}))

export default function DemoDialog (props) {
  const { open, type, onClose, sex, nickName, user, friend, ...other } = props
  const classes = useStyles()
  const [displayItems,setDisplayItems] = useState([])
  const demoPlaying = useRef(null)

  useEffect(() => {
    if (open) {
      BaseComponent.gaModalView('demo')
    } else {
      demoPlaying.current = false
      setDisplayItems([])
    }
  }, [open])

  const getTitle = (type, sex) => {
    if (type === 1) {
      return sex === 'female' ? '相手の人となりがわかる編' : 'デートお誘いタイミング編'
    } else {
      return sex === 'female' ? '仕事内容を自然に聞く編' : '女性が好印象を抱くトーク編'
    }
  }

  const handleStart = async () => {
    demoPlaying.current = true
    const target = demo_messages[`demo_${sex}_${type}_1`]
    for (const msg of target) {
      // 途中終了した場合break
      if (!demoPlaying.current) { break }
      await addDisplayItem(msg)
      demoDisplayed(msg)
    }
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const scrollToBottom = () => {
    const scroll = $('#scroll-dialog')
    scroll.scrollTop(scroll.prop('scrollHeight'))
  }

  // 画面にSpeachオブジェクトを追加
  const addDisplayItem = async (msg) => {
    await setDisplayItems((displayItems) => [...displayItems, msg])
    await scrollToBottom()
    await sleep(1000)
  }

  const isReadStateDemoDialogDisplayed = async (read_id) => {
    try {
      const read_ids = await Api.getReadIds('DemoDialogDisplayed')
      return read_ids.includes(read_id)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const createReadStateDemoDialogDisplayed = async (read_id) => {
    try {
      const params = {
        target: 'DemoDialogDisplayed',
        read_ids: [read_id],
      }
      await Api.createReadState(params)
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    }
  }

  const createNaviMessageElement = (msg, key) => {
    const question = !!msg?.answer_1 && !!msg?.answer_2
    const yes = { value: 'yes', label: msg?.answer_1 }
    const no = { value: 'no', label: msg?.answer_2 }
    const dummy = {
      fav_question_type: question,
      question_answer_choices: question ? [yes, no] : null
    }
    const message = Object.assign(msg, dummy)
    return (
      <NaviSpeech
        {...other}
        key={key}
        naviType={msg.navi_type}
        message={message}
        secret={!isEmpty(msg.navi_to)}
        friend={friend}
        user={user}
        demo
        onAnswerDemoQuestion={answer => handleAnswer(message, answer)}
      />
    )
  }

  const createMyMessageElement = (msg, key) => {
    return (
      <RightSpeech
        key={key}
        userGender={sex === 'female' ? 'female' : 'male'}
        message={msg}
      />
    )
  }

  const createFriendMessageElement = (msg, key) => {
    return (
      <LeftSpeech
        key={key}
        photo={sex === 'female' ? ImageMale : ImageFemale}
        userGender={sex === 'female' ? 'male' : 'female'}
        message={msg}
      />
    )
  }

  // 回答
  const handleAnswer = async (msg, ans) => {
    const ans_value = '_' + (ans === 'yes' ? '1' : '2')
    const target = demo_messages[msg['next' + ans_value]]
    for (const msg of target) {
      // 途中終了した場合break
      if (!demoPlaying.current) { break }
      await addDisplayItem(msg)
      demoDisplayed(msg)
    }
  }

  // デモ機能既読処理
  const demoDisplayed = async (msg) => {
    if (msg.target !== 'finished') { return }
    const displayed = await isReadStateDemoDialogDisplayed(msg.id)
    if (displayed) { return }
    await createReadStateDemoDialogDisplayed(msg.id)
  }

  const createTimeMessageElement = (msg) => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          className={classes.timeContainer}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.message}>
            ----- {msg.message} -----
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const toTopPage = () => {
    demoPlaying.current = false
    setDisplayItems([])
  }

  const createFinishedMessageElement = (msg) => {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          className={classes.finishContainer}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.finishText}>
            {BaseComponent.textConvertMultiline(msg.message)}
          </Typography>
          <img
            className={msg.image === 'Good' ? classes.finishImageGood : classes.finishImageBad}
            src={msg.image === 'Good' ? ImageGood : ImageBad}
            alt=""
          />
          <Button
            className={classes.finishButton}
            variant="contained"
            color="secondary"
            onClick={toTopPage}
          >
            はじめに戻る
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog open={open} fullScreen>
      <DialogContent id='scroll-dialog' className={classes.content}>
        <AppBar position="fixed" className={classes.bar}>
          <Toolbar className={classes.toolbar}>
            <img src={logoTitle} className={classes.logo} alt="" />
            <IconButton className={classes.backButton} onClick={onClose} >
              <LeftIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {demoPlaying.current ? (
          <Grid
            className={classes.container}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.scroll}>
              {displayItems.map((item, key) => (
                <div key={key} className={item.target === 'time' ? classes.timeAnimation : classes.messageAnimation}>
                  {item.target === 'navi' ? createNaviMessageElement(item, key)
                    : item.target === 'user' ? createMyMessageElement(item, key)
                      : item.target === 'friend' ? createFriendMessageElement(item, key)
                        : item.target === 'time' ? createTimeMessageElement(item, key)
                          : createFinishedMessageElement(item, key)
                  }
                </div>
              ))}
            </div>
          </Grid>
        ) : (
          <Grid
            className={classes.container}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.message}>
              {sex === 'male' ? '女性' : '男性'}から下記のメッセージが来た時<br />
              {nickName}さんならどう答える？？
            </Typography>
            <Typography className={classes.messageTitle}>
              {getTitle(type, sex)}
            </Typography>
            <Typography className={classes.message}>
              質問（選択肢）が出てきたら<br />
              自分の思う通りに回答してね！
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.startButton}
              onClick={() => handleStart(sex, type)}
            >
              スタート
            </Button>
            <img src={ImageTop} className={classes.image} alt="" />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

DemoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
  sex: PropTypes.string.isRequired,
  nickName: PropTypes.string.isRequired,
  user: PropTypes.object,
  friend: PropTypes.object,
}
