import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    margin: 'auto',
  },
  label: {
    fontSize: 34,
    color: '#423BC7',
    fontWeight: 700,
    lineHeight: 1,
    marginTop: theme.spacing(12),
  },
  titleBar: {
    height: 5,
    width: 212,
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}))

const ContentTitle = React.forwardRef(function ContentTitle (props, ref) {
  const { label } = props
  const classes = useStyles()

  return(
    <Grid container direction = "column" alignItems = "center" >
      <Grid className={classes.label} ref={ref || null}>
        {label}
      </Grid>
      <div className={classes.titleBar} />
    </Grid >
  )
})

ContentTitle.propTypes = {
  label: PropTypes.string.isRequired,
}

export default ContentTitle
