import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  filter: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: '#707070',
    opacity: 0.7,
    zIndex: 1,
  },
  filterText: {
    fontSize: 11,
    fontWeight: 300,
    lineHeight: 1,
    color: '#FFFFFF',
  },
}))

const PhotoStatus = (props) => {
  const { status } = props
  const statusLabel = (status => {
    switch (status) {
      case 'checking':
        return '審査中'
      case 'rejected':
        return '却下済'
      default:
        return null
    }
  })(status)
  if (!statusLabel) { return null }

  const classes = useStyles()
  return (
    <Grid className={classes.filter} container justifyContent="center" alignItems="center">
      <Typography className={classes.filterText}>
        {statusLabel}
      </Typography>
    </Grid>
  )
}

export default PhotoStatus

PhotoStatus.propTypes = {
  status: PropTypes.string
}
