import React, { useState, useEffect } from 'react'
import { isNullOrUndefined } from 'util'
import PropTypes from 'prop-types'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LoverIcon from '@material-ui/icons/FavoriteBorderOutlined'
import SummarizeIcon from '@mui/icons-material/SummarizeOutlined'
import Api from 'commons/api'
import FoodReportListDialog from 'components/foodreport/FoodReportListDialog'
import FavoriteIcon from 'components/icons/FavoriteIcon'
import FriendCancelIcon from 'components/icons/FriendCancelIcon'
import MemoIcon from 'components/icons/MemoIcon'
import ProfileIcon from 'components/icons/ProfileIcon'
import PublicIcon from 'components/icons/PublicIcon'
import PushIcon from 'components/icons/PushIcon'
import SecretIcon from 'components/icons/SecretIcon'
import SendReservationIcon from 'components/icons/SendReservationIcon'
import ShopIcon from 'components/icons/ShopIcon'
import ViolationIcon from 'components/icons/ViolationIcon'

const useStyles = makeStyles(theme => ({
  imageList: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    minWidth: 300,
    margin: '0 !important',
  },
  tile: {
    textAlign: 'center',
    height: '100px !important',
  },
  tileMemo: {
    textAlign: 'center',
    backgroundColor: 'rgba(162,105,218,0.2)',
  },
  button: {
    marginTop: theme.spacing(2),
    width: 30,
    height: 30,
    '& .MuiSvgIcon-root': {
      width: 30,
      height: 30,
      color: '#989898',
    }
  },
  buttonLabel: {
    fontSize: 12,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
  },
  badge: {
    top: 10.5,
    right: -16,
    bottom: 0,
  },
}))

export default function ChatMenu (props) {
  const {
    user,
    friend,
    chatFriend,
    friends,
    isLover,
    onlyMemo,
    isPushNotif,
    onClose,
    isSecretRooms,
    tutorialView,
    ...others
  } = props
  const classes = useStyles()
  const [foodReportsEnabled, setFoodReportsEnabled] = useState(false)
  const [openFoodReports, setOpenFoodReports] = useState(false)

  useEffect(() => {
    (async () => {
      const roomId = friend?.chat_room_id
      if (!roomId) { return }
      try {
        const foodReports = await Api.getFoodReports(roomId)
        const enabled = foodReports && (0 < foodReports.length)
        setFoodReportsEnabled(enabled)
      } catch (error) {
        // ignore
      }
    })()
  }, [])

  const handleSelectItem = (name) => {
    return () => {
      if (props.onSelectItem) {
        props.onSelectItem(name)
      }
    }
  }
  // お付き合い申請可能判定
  const canApplyLover = () => {
    const beFriend = (chatFriend.type === 'friend')
    const firstDated = chatFriend.first_dated
    const canApplying = (getLoverApplyingCount() === 0)
    return beFriend && firstDated && canApplying
  }

  // お付き合い申請中の数
  const getLoverApplyingCount = () => {
    return friends.filter(friend => {
      if (friend.type === 'lover_applying') { return true }
      return false
    }).length
  }

  // 秘密の質問部屋新着メッセージ件数
  const getNewSecretMessageCount = () => {
    let messageCount = 0
    if (!isNullOrUndefined(chatFriend.new_secret_message_count)) {
      messageCount = chatFriend.new_secret_message_count
    }
    return messageCount
  }

  const onClickFoodReports = () => {
    onClose && onClose()
    setOpenFoodReports(true)
  }

  const createMenu = (id, badge = false) => {
    const labelMap =  {
      AccountSummary: {
        label: 'プロフィール',
        icon: <ProfileIcon />,
      },
      PublicProfile: {
        label: '公開情報設定',
        icon: <PublicIcon />
      },
      Favorability: {
        label: '好感度登録',
        icon: <FavoriteIcon />,
      },
      Notification: {
        label: isPushNotif ? '通知オフ' : '通知オン',
        icon: <PushIcon />,
      },
      ViolationReport: {
        label: '違反者報告',
        icon: <ViolationIcon />,
      },
      ShopSearch: {
        label: 'レストラン検索',
        icon: <ShopIcon />,
      },
      SecretRooms: {
        label: '秘密の質問',
        icon: <SecretIcon />,
      },
      ChatMemo: {
        label: 'お相手メモ',
        icon: <MemoIcon />,
      },
      LoverEntry: {
        label: 'お付き合い\nリクエスト',
        icon: <LoverIcon />,
      },
      RejectFriend: {
        label: '友達解消',
        icon: <FriendCancelIcon />,
      },
      RejectLover: {
        label: 'お付き合い解消',
        icon: <FriendCancelIcon />,
      },
      SendReservation: {
        label: '送信予約',
        icon: <SendReservationIcon/>,
      },
    }
    return (
      <ImageListItem
        cols={1}
        className={classes.tile}
        style={{
          border: (id === 'Favorability' && tutorialView === 'fav-update-02') && '4px solid #6666FC',
          borderRadius: (id === 'Favorability' && tutorialView === 'fav-update-02') && '50%',
        }}
      >
        <IconButton className={classes.button} disabled={onlyMemo} onClick={handleSelectItem(id)}>
          {!badge ? (
            <>{labelMap[id].icon}</>
          ) : (
            <Badge
              classes={{ badge: classes.badge }}
              color="secondary"
              badgeContent={getNewSecretMessageCount()}
              invisible={getNewSecretMessageCount() === 0}
            >
              {labelMap[id].icon}
            </Badge>
          )}
        </IconButton>
        <Typography className={classes.buttonLabel}>
          {labelMap[id].label}
        </Typography>
      </ImageListItem>
    )
  }

  return (
    <>
      <ImageList cols={3} rowHeight={90} className={classes.imageList}>
        {createMenu('AccountSummary')}
        {!isLover && createMenu('PublicProfile')}
        {!isLover && createMenu('Favorability')}
        {createMenu('Notification')}
        {createMenu('ViolationReport')}
        {user.sex === 'male' && createMenu('ShopSearch')}
        {isSecretRooms && createMenu('SecretRooms', true)}
        {createMenu('ChatMemo')}
        {foodReportsEnabled && (
          <ImageListItem cols={1} className={classes.tile}>
            <IconButton className={classes.button} onClick={onClickFoodReports}>
              <SummarizeIcon />
            </IconButton>
            <Typography className={classes.buttonLabel}>
              食レポ
            </Typography>
          </ImageListItem>
        )}
        {canApplyLover() && createMenu('LoverEntry')}
        {!isLover && createMenu('RejectFriend')}
        {isLover && createMenu('RejectLover')}
        {createMenu('SendReservation')}
      </ImageList>
      {foodReportsEnabled && (
        <FoodReportListDialog
          open={openFoodReports}
          onClose={() => setOpenFoodReports(false)}
          roomId={friend.chat_room_id}
          {...props}
          {...others}
        />
      )}
    </>
  )
}

ChatMenu.propTypes = {
  chatFriend: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  isLover: PropTypes.bool,
  isSecretRooms: PropTypes.bool,
  isPushNotif: PropTypes.bool,
  onlyMemo: PropTypes.bool,
  onClose: PropTypes.func,
  friends: PropTypes.array,
  friend: PropTypes.object,
  user: PropTypes.object,
  tutorialView: PropTypes.string,
}
