import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Currency from 'commons/currency'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import GiftCardImg from 'images/gift_card_aill_monitor.png'
import MonitorUserImg from 'images/monitorUserImg.png'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  text: {
    textAlign: 'left',
  },
  notice: {
    paddingTop: theme.spacing(1),
  },
}))

export default function MonitorUserDialog (props) {
  const { open, params, onClose } = props
  const classes = useStyles()

  if (!params) { return null }

  const answerDate = params.answer_date
  const formUrl = params.form_url
  const incentiveAmount = params.incentive_amount
  const incentiveName = params.incentive_name
  const type = params.type

  const map = {
    'Ask.MonitorUser': {
      title: '3ヶ月間無料\nAill goen モニター募集',
      subTitle: 'モニターやAill goenの情報は\n所属会社に共有されません。',
      text01: '3ヶ月利用料無料でAill goenをご利用いただき、下記のフィードバックをお願いします。',
      text02: 'まずはモニター説明会で、詳細をご案内させてください！\n下記ボタンより、説明会に参加可能な日程をお送りください。',
      note: (
        <>
          ＊説明会の所要時間は30分程度です。<br />
          ＊オンラインにて行います（画面OFFで参加可能）<br />
          ＊モニターは限られた方にのみご案内をしております。<br />
          ＊お知らせ一覧からもご確認いただけます。
        </>
      ),
      formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSd_4Vyf4xwMotuP3MB9Vy7ljRq1y1V-CQolLSoRJnZ4dWt0zQ/viewform?usp=sf_link',
      img: MonitorUserImg,
      buttonLabel: '説明会の日程を確認する',
    },
    'Ask.MonitorUserHearing': {
      title: 'ヒアリングへのご招待',
      subTitle: `${incentiveName}\n${Currency.format(incentiveAmount)}円分をプレゼント！`,
      text01: 'モニターの3ヶ月に1回のヒアリングにご協力をお願いします。',
      text02: `ご協力いただいた方へはスターバックスギフトカード${Currency.format(incentiveAmount)}円分をプレゼントいたします！`,
      note: (
        <>
          ＊オンラインで実施、所要時間は40分程度です。<br />
          ＊ヒアリングの詳細は返信いただいた方にお送りいたします。<br />
          ＊本ヒアリング結果はサービス向上にのみ利用させていただきます。<br />
          ＊本ヒアリング結果は匿名化し公表する場合がございます。<br />
          ＊お知らせ一覧からもご確認いただけます。
        </>
      ),
      formUrl: formUrl,
      img: GiftCardImg,
      buttonLabel: '申し込む',
    },
  }

  const title = map[type].title
  const subTitle = map[type].subTitle
  const firstText = map[type].text01
  const secondText = map[type].text02
  const note = map[type].note
  const img = map[type].img
  const buttonLabel = map[type].buttonLabel

  const openGoogleForm = () => {
    const url = map[type].formUrl
    window.open(url)
    onClose()
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.subTitle} variant="subtitle1">
            {subTitle}
          </Typography>
          <Typography className={classes.text} variant="body1">
            {firstText}<br />
            <br />
            ■Aill goenアプリの良い点、改善点<br />
            ■AIの良い点、改善点<br />
            <br />
            {secondText}<br />
            {answerDate && `\n${dateFormat(new Date(answerDate), 'm月d日')}中に「申し込む」ボタンよりお申し込みください。\n\n`}
          </Typography>
          <img src={img} width="100%" alt="" />
          <Typography className={classes.notice} variant="body2">
            {note}
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button variant="contained" onClick={openGoogleForm}>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

MonitorUserDialog.propTypes = {
  open: PropTypes.bool,
  params: PropTypes.object,
  onClose: PropTypes.func,
}
