import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = {
}

class SecretIcon extends React.Component {
  render () {
    return (
      <SvgIcon>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 27.126 27.8" preserveAspectRatio="xMidYMid meet">
          <g id="グループ_857" data-name="グループ 857" transform="translate(-44.799 -127.1)">
            <circle id="楕円形_128" data-name="楕円形 128" cx="2.689" cy="2.689" r="2.689" transform="translate(50.357 148.622)" fill="none" stroke="#989898" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_464" data-name="パス 464" d="M50.666,143.7a2.341,2.341,0,0,0,4.681,0l.005.007a2.872,2.872,0,0,1,1.32-2.656,16.985,16.985,0,0,0,2.96-2.35,5.835,5.835,0,0,0,1.545-4.229,6.049,6.049,0,0,0-2.006-4.724A8.083,8.083,0,0,0,53.765,128a9.278,9.278,0,0,0-3.762.722,6.856,6.856,0,0,0-2.72,2.144,7.658,7.658,0,0,0-1.57,4.346l0-.01a2.665,2.665,0,0,0,5.3.581,3.945,3.945,0,0,1,.412-1.637,2.315,2.315,0,0,1,2.218-1.238c1.367,0,2.151.712,2.151,1.953,0,.886-.368,1.422-1.513,2.206a8.2,8.2,0,0,0-2.537,2.2,7.25,7.25,0,0,0-1.079,4.44Z" fill="none" stroke="#989898" strokeLinejoin="round" strokeWidth="1.8"/>
            <circle id="楕円形_129" data-name="楕円形 129" cx="2.689" cy="2.689" r="2.689" transform="translate(65.105 148.622)" fill="none" stroke="#989898" strokeMiterlimit="10" strokeWidth="1.8"/>
            <path id="パス_465" data-name="パス 465" d="M64.486,131.286a3.271,3.271,0,0,1,6.539,0l-.987,12.358a2.274,2.274,0,0,1-4.546,0h0c-.007-.254-.03-.19-.11-1.224l-.56-7.811s-.2-2.12-.336-3.327Z" fill="none" stroke="#989898" strokeMiterlimit="10" strokeWidth="1.8"/>
          </g>
        </svg>
      </SvgIcon>
    )
  }
}

export default withStyles(styles)(SecretIcon)