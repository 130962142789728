import { routerMiddleware } from 'react-router-redux'
import { createStore, applyMiddleware } from 'redux'
import history from '../history'
import reducer from '../reducers'

// ここでloggerやmiddlewareなどをかませる
export default function configureStore () {
  const store = createStore(
    reducer,
    applyMiddleware(routerMiddleware(history))
  )
  return store
}