import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SlideIconOpen from '@material-ui/icons/Brightness1'
import SlideIconClose from '@material-ui/icons/Brightness1Outlined'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DemoDialog from 'components/parts/DemoDialog'
import FirstTalkNaviImg01 from 'images/Komainu/firstTalkNaviImg01.png'
import FirstTalkNaviImg04 from 'images/Komainu/firstTalkNaviImg04.png'
import FirstTalkNaviImg05 from 'images/Komainu/firstTalkNaviImg05.png'

const useStyles = makeStyles(theme => ({
  subTitle: {
    marginBottom: theme.spacing(3),
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(1.75),
  },
  img: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  slideIcon: {
    fontSize: 8,
    color: '#ffffff',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  slideIconsContainer: {
    width: '100%',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  slide: {
    position: 'relative',
  },
  messageDemo: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    background: '#ffffff',
    minHeight: 14,
    width: '100%',
    maxWidth: 210,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  linkBox: {
    width: '100%',
    position: 'absolute',
    top: 120,
    left: '50%',
    transform: 'translate(-50%, 0%)'
  },
}))

export default function NaviIntroDialog (props) {
  const { open, onClose, sex, name, demoOnly, ...other } = props
  const classes = useStyles()
  const [slideIndex, setSlideIndex] = useState(0)
  const [openDemoDialog, setOpenDemoDialog] = useState(false)
  const [demoType, setDemoType] = useState(1)
  const [demoSex, setDemoSex] = useState('male')
  const [demoNickName, setDemoNickName] = useState('')

  useEffect(() => {
    if (open) {
      setSlideIndex(0)
    }
  }, [open])

  const onChangeSlideIndex = (index, latestIndex, meta) => {
    if (meta.reason === 'swipe') {
      setSlideIndex(index)
    }
  }

  const createPage1 = () => {
    return (
      <div style={Object.assign({})} className={classes.slide}>
        <Typography className={classes.subTitle} variant="subtitle1">
          AIキャラクター紹介
        </Typography>
        <Typography className={classes.text} variant="body1">
          AIキャラクターが<br />あなたの恋を応援！
        </Typography>
        <Typography className={classes.text} variant="body1">
          神楽（カグラ）と榊（サカキ）<br />狛犬をよろしくね！
        </Typography>
        <img className={classes.img} src={FirstTalkNaviImg04} />
      </div>
    )
  }

  const createPage2 = () => {
    return (
      <div style={Object.assign({})} className={classes.slide}>
        <Typography className={classes.subTitle} variant="subtitle1">
          狛犬アシスト
        </Typography>
        <Typography className={classes.text} variant="body1">
          1. ここぞ！というポイントで<br />「カグラ」と「サカキ」が出現！
        </Typography>
        <Typography className={classes.text} variant="body1">
          2. 2人同時に見えるメッセージと<br />1人（自分自身）のみ見えるメッセージの2パターンによって会話をアシスト
        </Typography>
        <img className={classes.img} src={FirstTalkNaviImg01} />
      </div>
    )
  }

  const handleClickDemo = (type) => {
    setDemoType(type)
    setDemoSex(sex)
    setDemoNickName(name)
    setOpenDemoDialog(true)
  }

  const createPage3 = () => {
    return (
      <div style={Object.assign({})} className={classes.slide}>
        <Typography className={classes.subTitle} variant="subtitle1">
          狛犬のトークアシスト確認
        </Typography>
        <Typography className={classes.text} variant="body1">
          どんなアシストか確認してみてね！<br />役に立てるように頑張るよ！！
        </Typography>
        <img className={classes.img} src={FirstTalkNaviImg05} />
        <div className={classes.linkBox}>
          <Button className={classes.messageDemo} onClick={() => handleClickDemo(1)}>
            <u>1. {sex === 'female' ? '相手の人となりがわかる編' : 'デートお誘いタイミング編'}</u>
          </Button>
          <Button className={classes.messageDemo} onClick={() => handleClickDemo(2)}>
            <u>2. {sex === 'female' ? '仕事内容を自然に聞く編' : '女性が好印象を抱くトーク編'}</u>
          </Button>
        </div>
      </div>
    )
  }

  const next = () => {
    if (slideIndex === 2 || demoOnly) {
      onClose()
    } else {
      setSlideIndex(slideIndex + 1)
    }
  }

  return (
    <>
      <DialogThemeProvider color="secondary">
        <Dialog open={open} {...other}>
          <DialogTitle disableTypography>
            {demoOnly ? '会話ナビ 狛犬アシスト' : '会話ナビについて'}
          </DialogTitle>
          <DialogContent>
            {demoOnly ? (
              <>
                {createPage3()}
              </>
            ) : (
              <SwipeableViews index={slideIndex} onChangeIndex={onChangeSlideIndex}>
                {createPage1()}
                {createPage2()}
                {createPage3()}
              </SwipeableViews>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center">
              <div className={classes.slideIconsContainer}>
                {demoOnly ? null : (
                  <>
                    {slideIndex === 0 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                    {slideIndex === 1 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                    {slideIndex === 2 ? <SlideIconOpen className={classes.slideIcon} /> : <SlideIconClose className={classes.slideIcon} />}
                  </>
                )}
              </div>
              <Button variant="contained" onClick={next}>
                OK
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </DialogThemeProvider>
      <DemoDialog
        {...other}
        open={openDemoDialog}
        onClose={() => setOpenDemoDialog(false)}
        type={demoType}
        sex={demoSex}
        user={props.user}
        nickName={demoNickName}
      />
    </>
  )
}

NaviIntroDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sex: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  user: PropTypes.object,
  demoOnly: PropTypes.bool,
}
