import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import EntryFooter from 'components/entry/EntryFooter'
import EntryHeader from 'components/entry/EntryHeader'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    minHeight: `calc(${window.innerHeight}px - ${theme.styles.entryFooter.height}px)`,
  },
  title: {
    fontSize: 34,
    fontWeight: 'bold',
    color: '#707070',
    marginTop: theme.spacing(2),
  },
  titleBar: {
    height: theme.spacing(0.625),
    width: theme.spacing(10),
    background: 'linear-gradient(to right, #87BCFF, #A655FB, #7800FB, #5B1CFD)',
  },
  container: {
    width: '50%',
    minWidth: theme.spacing(36),
    marginTop: theme.spacing(1),
  },
  sendMessageTitle: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: '#000000'
  },
  sendMessage: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    lineHeight: 2.0,
    color: '#000000'
  },
  footerContainer: {
    height: theme.styles.header.height,
  },
  space: {
    height: theme.spacing(2),
  },
}))

export default function SignupResult () {
  const classes = useStyles()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <EntryHeader />
        <Grid container direction='column' alignItems="center">
          <Typography className={classes.title}>
            RESULT
          </Typography>
          <div className={classes.titleBar} />
          <div className={classes.space} />
          <Grid item className={classes.container}>
            <Grid item>
              <Typography className={classes.sendMessageTitle}>
                大変申し訳ございません。
              </Typography>
              <Typography className={classes.sendMessage}>
                現在、関東圏を中心に先行スタートしています。<br />
                対象エリアの範囲が広がり次第<br />
                登録いただいたメールアドレスに、ご案内させてくださいませ。<br />
                引き続きAill goen（エール ゴエン）をなにとぞよろしくお願いいたします。
              </Typography>
              <div className={classes.footerContainer}></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <EntryFooter />
    </div>
  )
}
