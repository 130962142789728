import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles(theme => ({
  header: {
    paddingTop: `calc(${theme.styles.header.height}px + ${theme.spacing(2)}px)`,
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  headerSignup: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '50%',
    minWidth: theme.spacing(44),
  },
  avatar: {
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
    backgroundColor: theme.palette.secondary.main,
  },
  lightAvatar: {
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
    backgroundColor: '#cccccc',
  },
  avatarText: {
    fontSize: 10,
    textAlign: 'center',
  },
  arrowIcon: {
    color: theme.palette.secondary.main,
    width: theme.spacing(2.5),
    hight: theme.spacing(2.5),
    verticalAlign: 'middle'
  },
}))

export default function EntrySteps (props) {
  const { signup, step, titles } = props
  const classes = useStyles()

  const createAvatar = (index) => {
    let avatarClass = (index === step) ? classes.avatar : classes.lightAvatar
    return (
      <Avatar className={avatarClass}>
        <span className={classes.avatarText}>{titles[index]}</span>
      </Avatar>
    )
  }

  const createGridItems = () => {
    let items = []
    titles.map((title, index) => {
      if (index !== 0) {
        items.push(
          <Grid item key={`arrow-${index}`}>
            <KeyboardArrowRightIcon className={classes.arrowIcon} />
          </Grid>
        )
      }
      items.push(<Grid item key={title}>{createAvatar(index)}</Grid>)
    })
    return items
  }

  return (
    <Grid
      container
      direction='row'
      alignItems="center"
      justifyContent="space-between"
      className={signup ? classes.headerSignup : classes.header}
    >
      {createGridItems()}
    </Grid>
  )
}

EntrySteps.propTypes = {
  signup: PropTypes.bool,
  step: PropTypes.number.isRequired,
  titles: PropTypes.array.isRequired,
}
