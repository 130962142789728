import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import KomainuWhiteBord from 'images/Komainu/firstTalkNaviImg05.png'

const useStyles = makeStyles(theme => ({
  pinkBadge: {
    background: '#F55083',
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 11,
    marginTop: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
  whitebord: {
    position: 'relative',
  },
  whitebordText: {
    width: '100%',
    color: '#423BC7',
    margin: 0,
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
    lineHeight: 1.8,
  },
  largeText: {
    fontSize: 16,
  },
}))

export default function FreeTermExtensionChallengeNotifyDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()

  return (
    <DialogThemeProvider color="primary">
      <Dialog open={open}>
        <DialogCloseButton onClick={onClose} />
        <DialogTitle disableTypography>
          イベント発生！
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography className={classes.text} variant="body1">
            無料トライアル期間<br />
            延長チャレンジ
          </Typography>
          <div className={classes.whitebord}>
            <Typography className={classes.whitebordText} variant="body1">
              無料トライアル期間中に<br />
              チャレンジ達成で<br />
              <span className={classes.largeText}>最大14日間</span><br />
              無料期間が延長！
            </Typography>
            <img src={KomainuWhiteBord} width={216} />
          </div>
        </DialogContent>
      </Dialog>
    </DialogThemeProvider>
  )
}

FreeTermExtensionChallengeNotifyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
