import React, { useState, useMemo } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import ja from 'date-fns/locale/ja'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { baseThemeProps, defaultOverrides } from 'commons/theme'
import BaseComponent from 'components/parts/BaseComponent'

const DatePickerTheme =
  createTheme({
    ...baseThemeProps(),
    overrides: {
      ...defaultOverrides(),
      MuiPickersDay: {
        current: {
          color: 'inherit',
          fontWeight: 'inherit',
        },
      },
      MuiInput: {
        root: {
          fontSize: 12,
          padding: 12,
          '& input': {
            padding: 0,
          }
        },
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&:hover': {
            borderBottom: 'none',
          },
        },
      },
    }
  })

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
    },

    '& .MuiDialog-paper': {
      margin: 0,
      width: '100%',
      borderRadius: '25px 25px 0 0',
    }
  },
  dialogTitle: {
    padding: 0,
    textAlign: 'right',
  },
  closeIcon: {
    color: '#423BC7',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: '#1A1667',
  },
  localTime: {
    fontSize: 12,
    color: '#414141',
  },
  content: {
    padding: 0,
    marginBottom: theme.spacing(4),
  },
  list: {
    marginTop: theme.spacing(2),
  },
  listItem: {
    fontSize: 14,
    fontWeight: 600,
    color: '#414141',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    width: '100%',

    '&:active': {
      backgroundColor: '#F5F5F5',
    }
  },
  customTimeButton: {
    marginTop: theme.spacing(2),
    width: 268,
    height: 44,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: '#423BC7',
    color: '#ffffff',
    borderRadius: 22,
  },
  select: {
    '&:before': {
      content: 'none',
    },
    '&:after': {
      content: 'none',
    },
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingLeft: theme.spacing(1.5),
    },
    '& .MuiSelect-icon': {
      width: 20,
    },
    fontSize: 12,
    border: '1px solid #707070',
    borderRadius: 8
  },
  datePicker: {
    border: '1px solid #707070',
    borderRadius: 8,
    fontSize: 12,
  }
}))

export default function SendConfirmDialog (props) {
  const {
    open,
    onCancel,
    onSendNow,
    onSendScheduled,
  } = props
  const classes = useStyles()

  const [openCustomTimeForm, setOpenCustomTimeForm] = useState(false)
  const [openCustomDateForm, setOpenCustomDateForm] = useState(false)

  const today = new Date()
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 8, 0, 0, 0)
  const [customDate, setCustomDate] = useState(tomorrow)
  const [customTime, setCustomTime] = useState('8:00')

  const handleSendScheduled = (datetime) => {
    if (datetime) {
      onSendScheduled(datetime)
      return
    }
    onSendScheduled(tomorrow.toISOString())
  }

  const handleChangeCustomDate = (date) => {
    setCustomDate(date)
    setOpenCustomDateForm(false)
  }

  const customTimeOptions = useMemo(() => {
    return Array.from({ length: 24 }).map((_, i) => <MenuItem key={i} value={`${i}:00`}>{`${i}:00`}</MenuItem>)
  }, [])

  const handleSendCustomTime = () => {
    const datetime = new Date(customDate)
    const time = customTime.split(':')
    datetime.setHours(time[0])
    datetime.setMinutes(0)

    if (datetime < new Date()) {
      BaseComponent.showErrorMessage(props, '過去の日時は指定できません')
      return
    }
    handleSendScheduled(datetime.toISOString())
  }

  return (
    <Dialog className={classes.root} open={open} onClose={onCancel}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton className={classes.closeIcon} onClick={onCancel}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" alignItems="center">
          <Typography className={classes.title}>
            メッセージを送信予約する
          </Typography>
          <Typography className={classes.localTime}>
            日本標準時(JST)
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.list}>
          <Typography className={classes.listItem} onClick={onSendNow}>
            今すぐ送信する
          </Typography>
          <Typography className={classes.listItem} onClick={() => handleSendScheduled()}>
            明日 8:00
          </Typography>
          <Typography className={classes.listItem} onClick={() => setOpenCustomTimeForm(true)}>
            カスタム時間
          </Typography>
        </Grid>
        {openCustomTimeForm && (
          <Grid container direction="column" alignItems="center">
            <Grid container justifyContent="center" style={{ gap: 16 }}>
              <ThemeProvider theme={DatePickerTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                  <DatePicker
                    open={openCustomDateForm}
                    value={customDate}
                    onChange={handleChangeCustomDate}
                    disableToolbar
                    disablePast
                    variant={'inline'}
                    format="yyyy年MM月dd日"
                    onOpen={() => setOpenCustomDateForm(true)}
                    onClose={() => setOpenCustomDateForm(false)}
                    classes={{ root: classes.datePicker }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
              <Select
                value={customTime}
                onChange={(e) => setCustomTime(e.target.value)}
                className={classes.select}
                IconComponent={KeyboardArrowDownIcon}
              >
                {customTimeOptions}
              </Select>

            </Grid>
            <Button variant="contained" className={classes.customTimeButton} onClick={handleSendCustomTime}>
              送信日時を設定する
            </Button>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

SendConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSendNow: PropTypes.func.isRequired,
  onSendScheduled: PropTypes.func.isRequired,
}