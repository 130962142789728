import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import ShortCurtain from 'images/movie/theater_curtain_short.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'calc(100% - 24px)',
    maxWidth: 400,
    backgroundColor: '#1A1667',
    borderRadius: 22,
    textAlign: 'left',
    marginTop: theme.spacing(1.5),
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2.25),
    paddingLeft: theme.spacing(3.75),
    backgroundImage: `url(${ShortCurtain})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundPositionX: 'center',
    backgroundPositionY: '-25px',
    position: 'relative',
  },
  text: {
    minHeight: 15,
    fontSize: 8,
    fontWeight: 600,
    color: '#ffffff',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#ffffff',
    marginBottom: theme.spacing(1.5),
  },
  labels: {
    maxWidth: 240,
    minHeight: 18,
  },
  label: {
    width: 'fit-content',
    fontSize: 8,
    fontWeight: 400,
    color: '#ffffff',
    border: '1px solid #ffffff',
    borderRadius: 21,
    margin: theme.spacing(0.375),
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.75),
  },
  scoreCircle: {
    width: 60,
    height: 60,
    position: 'absolute',
    top: 43,
    right: 23,
  },
  scoreText: {
    fontSize: 18,
    fontWeight: 600,
    color: '#ffffff',
  },
  scoreTextSmall: {
    fontSize: 11,
    color: '#ffffff',
  },
  scoreTextBottom: {
    fontSize: 8,
    fontWeight: 600,
    color: '#EFCD3F',
    whiteSpace: 'pre',
  },
}))

export default function MovieEventProfile (props) {
  const { friend } = props
  const classes = useStyles()
  const [movieMatchings, setMovieMatchings] = useState(null)

  useEffect(() => {
    (async () => {
      if (!friend) { return }
      const res = await Api.getMovieMatchings(friend.id)
      // scoreが一番高い映画（index=0）
      setMovieMatchings(res[0])
    })()
  }, [friend])

  const createLabel = (label) => (
    <Typography className={classes.label}>{label}</Typography>
  )

  const createMatchingScore = (score) => (
    <Grid className={classes.scoreCircle} container justifyContent="center">
      <CircularProgressbarWithChildren
        value={score}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textColor: '#423BC7',
          pathColor: '#EFCD3F',
          trailColor: '#616AFA',
        })}
      >
        <Typography className={classes.scoreText}>
          {score}<span className={classes.scoreTextSmall}>%</span>
        </Typography>
      </CircularProgressbarWithChildren>
      <Typography className={classes.scoreTextBottom}>
        映画でマッチ度
      </Typography>
    </Grid>
  )

  if (!movieMatchings) { return null }

  const movieTitle = movieMatchings.movie_title
  const matchingScore = movieMatchings.score
  const matchedImpressions = movieMatchings.matched_impressions

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>Aill シアター　ベストマッチ映画</Typography>
      <Typography className={classes.title}>「{movieTitle}」</Typography>
      <Typography className={classes.text}>
        {matchedImpressions.length > 0 && '感想マッチ'}
      </Typography>
      <Grid container className={classes.labels}>
        {matchedImpressions.map(label => createLabel(label))}
      </Grid>
      {createMatchingScore(matchingScore)}
    </div>
  )
}

MovieEventProfile.propTypes = {
  friend: PropTypes.object,
}
