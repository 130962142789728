import React from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Config from 'commons/config'
import Const from 'commons/constant'
import { spacing } from 'commons/theme'
import { addDate } from 'commons/utility'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  notice: {
    fontSize: '0.8em',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: spacing(2),
  },
  button: {
    width: 240,
  }
}))

export default function PlanTermExpired (props) {
  const { subscription } = props
  if (!subscription) { return null }

  const planName = () => {
    switch (subscription.content) {
      case Const.planTypes.STANDARD_INITIAL:
        return '無料トライアル'
      case Const.planTypes.STANDARD:
        return 'スタンダードプラン'
      case Const.planTypes.PREMIUM:
        return 'プレミアムプラン'
      default:
        return 'ご利用中のプラン'
    }
  }

  const expDate = addDate(subscription.expired_at, -1)
  const expReason = subscription.expired_reason
  const classes = useStyles()

  const defaultText = () => (
    <>
      { planName() }の有効期間が<br />
      { dateFormat(expDate, 'yyyy年m月d日') }&nbsp;で終了しています。<br />
      <br />
      お手数ですが、メンバーズサイトよりプランの継続・変更のお手続きをお願いいたします。<br />
      <br />
      <span className={classes.notice}>
        ＊フリープランもご用意しております。
      </span>
    </>
  )

  const billingFailedText = () => (
    <>
      { planName() }の有効期間が<br />
      { dateFormat(expDate, 'yyyy年m月d日') }&nbsp;で終了しています。<br />
      <br />
      ご利用中のプランを自動更新できませんでした。<br />
      お手数ですが、メンバーズサイトより確認をお願いいたします。<br />
      <br />
      <span className={classes.notice}>
        ＊フリープランもご用意しております。
      </span>
    </>
  )

  const canNotChangeToTrialText = () => (
    <>
      { planName() }の有効期間が<br />
      { dateFormat(expDate, 'yyyy年m月d日') }&nbsp;で終了しています。<br />
      <br />
      複数の異性とトーク中のため、フリープランへ移行できませんでした。<br />
      お手数ですが、メンバーズサイトよりお手続きをお願いいたします。
    </>
  )

  const endOfMonitorTermText = () => (
    <>
      モニター期間が{ dateFormat(expDate, 'yyyy年m月d日') }で終了いたしました。<br />
      この度はご協力いただきまして、誠にありがとうございました！<br />
      <br />
      お手数で申し訳ありませんが、サービスのご利用を継続いただける場合はメンバーズサイトよりご利用プランを選択いただきますようお願いいたします。<br />
      <br />
      <span className={classes.notice}>
        ＊退会される場合につきましてもメンバーズサイトよりお手続きいただけます。
      </span>
    </>
  )

  return (
    <>
      <Typography className={classes.root} variant="body2">
        { expReason === 'billing_failed' && billingFailedText() }
        { expReason === 'can_not_change_to_trial' && canNotChangeToTrialText() }
        { expReason === 'end_of_monitor_term' && endOfMonitorTermText() }
        { !expReason && defaultText() }
      </Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => window.open(Config.membersSiteUrl)}
        >
          メンバーズサイトを開く
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => window.location.reload()}
        >
          再読込み
        </Button>
      </div>
    </>
  )
}

PlanTermExpired.propTypes = {
  subscription: PropTypes.object,
}
