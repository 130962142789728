import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import DialogCloseButton from 'components/parts/DialogCloseButton'
import * as Offer from 'components/special-offer/index'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 328,
    height: 570,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center',
  },
  bg: {
    position: 'absolute',
  },
  title: {
    fontSize: 13,
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 88,
    left: '50%',
  },
  marker: {
    background: 'linear-gradient(transparent 55%, #FFF24D 0%)',
    padding: '0 4px',
  },
  incentive: {
    letterSpacing: '1px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 164,
    left: '50%',
    '& span:nth-of-type(1)': {
      fontSize: 12,
      writingMode: 'vertical-rl',
      marginRight: theme.spacing(1),
    },
    '& span:nth-of-type(2)': {
      fontSize: 29,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
    '& span:nth-of-type(3)': {
      fontSize: 16,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        bottom: 4,
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
  term: {
    fontSize: 12,
    letterSpacing: '0.5px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 198,
    left: '50%',
  },
  price: {
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    top: 364,
    left: '70%',
    '& span:nth-of-type(1)': {
      fontSize: 32.623,
      position: 'relative',
      '&:before': {
        content: 'attr(data-text)',
        position: 'absolute',
        textShadow: `1px 1px 0 #ffffff, -1px -1px 0 #ffffff,
          -1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
          1px 0 0 #ffffff, -1px 0 0 #ffffff,
          0 1px 0 #ffffff, 0 -1px 0 #ffffff
        `,
      },
    },
  },
}))

export default function SpecialOfferDialog (props) {
  const { open, onClick, onClose, offerId } = props
  const classes = useStyles()
  const [offer, setOffer] = useState(null)

  useEffect(() => {
    if (!open) { return }
    if (!offerId) { return }
    (async () => {
      props.setLoading(true)
      try {
        const res = await Api.getSpecialOffer(offerId)
        props.setCampaign(res)
        setOffer(res)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        props.setLoading(false)
      }
    })()
  }, [open])

  if (!offer) { return null }

  const soTheme = Offer.theme(offer)
  const textShadow = `
    2px 2px 2px ${soTheme.textShadowColor}, -2px -2px 1px ${soTheme.textShadowColor},
    -2px 2px 1px ${soTheme.textShadowColor}, 2px -2px 1px ${soTheme.textShadowColor},
    2px 0 1px ${soTheme.textShadowColor}, -2px  0 1px ${soTheme.textShadowColor},
    0 2px 1px ${soTheme.textShadowColor}, 0 -2px 1px ${soTheme.textShadowColor}
  `

  return (
    <DialogThemeProvider color="special-offer-event">
      <Dialog open={open} classes={{ paper: classes.paper }}>
        <img className={classes.bg} src={soTheme.dialogImg} width={'100%'} height={'100%'} />
        <DialogCloseButton onClick={onClose} style={{ color: soTheme.mainColor }} />
        <DialogContent>
          <Typography className={`${classes.title} ${classes.marker}`} variant="body1" style={{ color: soTheme.mainColor }}>
            あなただけのスペシャルオファー
          </Typography>
          <Typography className={classes.incentive} variant="body1" style={{ color: soTheme.mainColor }}>
            <span>最大</span>
            <span data-text={3 * offer.max_count} style={{ textShadow: textShadow }}>{3 * offer.max_count}</span>
            <span data-text="人ご紹介" style={{ textShadow: textShadow }}>人ご紹介</span>
          </Typography>
          <Typography className={`${classes.term} ${classes.marker}`} variant="body1" style={{ color: soTheme.mainColor }}>
            {dateFormat(offer.end_at, 'yyyy/m/d')} まで
          </Typography>
          <Typography className={classes.price} variant="body1" style={{ color: soTheme.mainColor }}>
            <span data-text={offer.price} style={{ textShadow: textShadow }}>{offer.price}</span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ background: soTheme.mainColor }}
            variant="contained"
            fullWidth
            onClick={() => {
              onClose()
              onClick()
            }}
          >
            オファー詳細を見る
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

SpecialOfferDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  offerId: PropTypes.string,
  setLoading: PropTypes.func,
  setCampaign: PropTypes.func,
}