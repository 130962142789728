import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import Komainu from 'images/invitation-campaign/komainu_02.png'
import Serif from 'images/invitation-campaign/serif.png'
import SubmitIcon from 'images/invitation-campaign/submit_icon.png'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    color: '#1A1667',
  },
  text: {
    width: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
  },
  check: {
    width: 120,
    height: 120,
    background: '#F4F7FE',
    borderRadius: '50%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  actions: {
    paddingTop: 0,
  },
  button: {
    background: '#616AFA !important',
    marginTop: -1,
  },
}))

export default function CouponSubmittedDialog (props) {
  const classes = useStyles()
  const { open, onClose, data } = props

  const discountCoupon = data?.incentive_name.includes('plan_discount_')

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open}>
        <DialogTitle className={classes.title} disableTypography>
          クーポン内容確認
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" justyfyContent="center" alignItems="center">
            <Typography className={classes.text} variant="body1">
              {discountCoupon ? (
                <>
                  「登録済みクーポンの利用選択」<br />
                  欄より<br />
                  次回の月額ご利用料金決済時に利用するクーポンを<br />
                  ご選択ください。
                </>
              ) : (
                <>
                  「登録済みクーポンの利用選択」<br />
                  欄より<br />
                  適用状況をご確認ください。<br />
                  <span style={{ fontSize: 11 }}>＊適用まで最大60分程度かかります</span>
                </>
              )}
            </Typography>
            <Grid className={classes.check} container justifyContent="center" alignItems="center">
              <img src={SubmitIcon} width={74} height={74} />
            </Grid>
            <img src={Serif} width={171} height={23} />
            <img src={Komainu} width={230} height={115} />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button className={classes.button} variant="contained" onClick={onClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}

CouponSubmittedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
}
