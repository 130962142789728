import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ConfirmDialog from 'components/parts/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  cropper: {
    width: '100%',
    height: 350,
    maxWidth: 500,
  },
  container: {
    fontSize: 14,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textAlign: 'center',
  },
  buttons: {
    flexDirection: 'row',
    padding: theme.spacing(1),
    '& button': {
      margin: '0 !important',
    },
  },
  link: {
    color: '#423BC7',
  },
}))

export default function ExceedMaxFileSizeDialog (props) {
  const { open, onClose } = props
  const classes = useStyles()

  const windowsLink = 'https://support.microsoft.com/ja-jp/windows/windows-%E3%81%A7%E5%86%99%E7%9C%9F%E3%82%84%E3%83%93%E3%83%87%E3%82%AA%E3%82%92%E7%B7%A8%E9%9B%86%E3%81%99%E3%82%8B-a3a6e711-1b70-250a-93fa-ef99048a2c86'
  const macLink = 'https://support.apple.com/ja-jp/guide/preview/prvw2015/mac'
  const errorMessage = (
    <>
      画像が大きすぎます。<br />
      画像サイズを縮小するか、別の画像をご利用ください。<br />
      <br />
      PCによる画像の縮小方法は以下のサイトをご参照ください。<br />
      <a className={classes.link} href={windowsLink} target="_blank" rel="noreferrer">Windowsの場合</a><br />
      <a className={classes.link} href={macLink} target="_blank" rel="noreferrer">Macの場合</a>
    </>
  )

  return (
    <ConfirmDialog open={open}
      onClose={onClose}
      close="yes"
      title="エラー"
      message={errorMessage}
      messageLeft={true}
    />
  )
}

ExceedMaxFileSizeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
